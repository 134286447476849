import React, { useEffect, useState } from "react";
import {
  IconGoogle,
  IconProCheck,
  IconSettings
} from "../../../assets/images/icons/icons";
import { Language, LanguageLibraryTranslation } from "../../../types";
import { getSystemConfigLanguages } from "../../../utilities/config.util";
import "./translations.scss";

export type SingleTranslationProps = {
  translation: LanguageLibraryTranslation;
  handleEditTranslationClick: (translation: LanguageLibraryTranslation) => void;
};

const SingleTranslation: React.FC<SingleTranslationProps> = ({
  translation,
  handleEditTranslationClick
}) => {
  const [languages, setLanguages] = useState<Language[]>([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const jsonData = await getSystemConfigLanguages();
        if (!jsonData) {
          throw new Error("Error fetching languages");
        } else {
          setLanguages(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching languages data:",
          error
        );
      }
    };
    fetchLanguages();
  }, []);

  const findLanguageName = (iso: string) => {
    const language = languages.find((lang) => lang.iso_code === iso);
    return language ? language.name : iso; // Return language name if found, otherwise return iso code
  };

  // Helper function to determine the translation source tooltip text
  const getTranslationSourceTooltip = (translatedBy: string) => {
    if (translatedBy.toLowerCase() === "google") {
      return "Translated by Google";
    } else if (translatedBy) {
      return `Translated by ${translatedBy}`; // Assuming translatedBy contains the name of the translator
    }
    return "Translation source unknown";
  };

  return (
    <div
      key={translation.id}
      className={`single_translation_container ${translation.language_iso === "EN" ? "_default container_selected" : ""}`}
    >
      {/* Translation Icon for clarity on who translated */}
      <div
        className="translated_icon"
        title={getTranslationSourceTooltip(translation.translated_by)}
      >
        {translation.translated_by.toLowerCase() === "google" ? (
          <IconGoogle />
        ) : translation.translated_by != null ? (
          <IconProCheck />
        ) : null}
      </div>
      {/* Iso Language */}
      <div className={`translation_left_side col-2 col-sm-2 col-xl-1`}>
        <div className="lang_iso_container">
          {findLanguageName(translation.language_iso)}
        </div>
      </div>
      {/* Translation */}
      <div className={`translation_middle col-8 col-sm-8 col-xl-10`}>
        <div className="translation_middle_description">
          {translation.translation}
        </div>
      </div>
      {/* Settings */}
      <div className="translation_right_side col-2 col-sm-2 col-xl-1">
        <span
          onClick={() => handleEditTranslationClick(translation)}
          title="Edit Translation"
        >
          <IconSettings />
        </span>
      </div>
    </div>
  );
};

export default SingleTranslation;
