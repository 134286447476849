import React from "react";
import InformationTooltip from "../../InputInformation/InfoInputs.component";

interface FieldTextProps {
  onInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  inputValue: string;
  tooltip?: string;
  label: string;
  inputName: string;
  subLabel?: string;
  error?: string | null;
  tooltipPosition?: "left" | "right" | "bottom" | "top";
}

const FieldText: React.FC<FieldTextProps> = ({
  onInputChange,
  inputValue,
  tooltip,
  label,
  inputName,
  subLabel,
  error,
  tooltipPosition
}) => {
  return (
    <div className="mb-3 row">
      <div className="col-12 col-lg-4">
        <label htmlFor={inputName} className="form-label mb-1 mx-1">
          {tooltip && (
            <InformationTooltip
              marginTop="-12px"
              position={tooltipPosition ? tooltipPosition : "top"}
              tooltipText={tooltip}
            />
          )}
          {label}
          {subLabel && <span className="sub_label">{subLabel}</span>}
        </label>
      </div>
      <div className="col-12 col-lg-8">
        {error && <div className="wizard_input_error">{error}</div>}
        <input
          type="text"
          name={inputName}
          id={inputName}
          className="form-control"
          value={inputValue}
          onChange={(e) => onInputChange(inputName, e)}
        />
      </div>
    </div>
  );
};

export default FieldText;
