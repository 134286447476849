import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconAdd, IconDelete } from "../../../../assets/images/icons/icons";
import {
  Tag,
  addTag,
  deleteTag,
  fetchAllTags
} from "../../../../models/tag.model";
import Search from "../../../Tables/Filters/filterSearch.component";
import TagDetails from "../TagsDetails/tagDetails.component";

import { usePermissions } from "../../../../contexts/UserContext";

import "./tagsList.scss";

type TagsListProps = {
  clientID?: string;
};

function TagsList({ clientID }: TagsListProps) {
  //----Data
  const [tags, setTags] = useState<Tag[]>([]);
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const [checkedTags, setCheckedTags] = useState<Set<string>>(new Set());
  const [selectedTag, setSelectedTag] = useState<string>();
  const [searchTag, setSearchTag] = useState<string>("");
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  //----Handling
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { studyID } = useParams<{ studyID: string }>();

  const { hasPermission } = usePermissions();

  /* --------------FETCHING DATA----------------- */
  const getTagData = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAllTags(clientID as string);
      if (typeof data === "string") {
        setError(data);
        setSelectedTag(undefined);
        setTags([]);
      } else {
        // Sort tags updated_at in descending order
        data.sort((a, b) => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);
          return dateB.getTime() - dateA.getTime();
        });
        setTags(data);
        setFilteredTags(data);
        if (data.length > 0) {
          setSelectedTag(data[0].id);
        } else {
          setSelectedTag(undefined);
        }
      }

      setIsLoading(false);
    } catch (e) {
      setError("Error fetching data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTagData();
  }, [refresh]);
  /* --------------Filtering ----------------------*/
  useEffect(() => {
    let filteredData = tags;
    if (searchTag) {
      filteredData = tags.filter((tag) =>
        tag.name.toLowerCase().includes(searchTag.toLowerCase())
      );
    }

    setFilteredTags(filteredData);
  }, [searchTag]);

  /* --------------HandleCheckbox----------------- */
  const handleCheckboxChange = (tagId: string) => {
    const newCheckedTags = new Set(checkedTags);
    if (newCheckedTags.has(tagId)) {
      newCheckedTags.delete(tagId);
    } else {
      newCheckedTags.add(tagId);
    }
    setCheckedTags(newCheckedTags);
  };

  const handleSelectAllCheckboxChange = () => {
    if (checkedTags.size === filteredTags.length) {
      setCheckedTags(new Set()); // Clear all if all are already selected.
    } else {
      const allTagIds = filteredTags.map((tag) => tag.id);
      setCheckedTags(new Set(allTagIds)); // Add all tag IDs to checkedTags.
    }
  };
  /* --------------Functions----------------- */
  //Creating a new tag
  const createNewTag = async () => {
    // Regex for the tag name, no special characters except for "-" or "_", from 4 to 60 characters
    const tagRegex = /^[a-zA-Z0-9_-]{2,60}$/;
    const { value: tag } = await Swal.fire({
      title: "Create a new tag",
      input: "text",
      inputLabel: "Enter your new tag for this client",
      inputPlaceholder: "New Tag Name",
      confirmButtonColor: "#3085d6",
      preConfirm: (inputValue) => {
        //If the tag is not valid, we show an error
        if (!tagRegex.test(inputValue)) {
          Swal.showValidationMessage(
            "Please enter a valid tag name from 2 to 60 characters with no special characters except for '-' or '_'"
          );
        }
        //If the tag already exists, we show an error
        if (
          tags.find(
            (tag) => tag.name.toLowerCase() === inputValue.toLowerCase()
          )
        ) {
          Swal.showValidationMessage("This tag already exists");
        }
      }
    });
    //If the tag is valid, we can create it
    if (tag) {
      try {
        Swal.fire({
          title: "Creating new tag",
          html: "Please wait while we create your new tag",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        const result = await addTag(clientID as string, studyID as string, tag);
        if (result === "success") {
          Swal.fire({
            icon: "success",
            title: `Your tag ${tag} has been created`,
            confirmButtonColor: "#3085d6"
          });
          getTagData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again"
          });
        }
      } catch (error) {
        console.log("Error creating", tag);
      }
    }
  };
  //Deleting checked tags
  const deleteCheckedTags = async (checkedTags: Set<string>) => {
    if (checkedTags.size > 0) {
      let workerID = localStorage.getItem("workerID");
      if (workerID && workerID !== "null") {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
      } else {
        const { value: confirm } = await Swal.fire({
          title: `Are you sure you want to delete ${checkedTags.size} tags?`,
          html: `
          <div>         
            <label>Please enter "${checkedTags.size}" to confirm your action.</label>
            <input id="swal-input1" class="swal2-input" style="margin-left: 10px; height:2.5rem; width:10rem;" placeholder="Enter amount">
          </div>`,
          preConfirm: () => {
            const inputValue = (
              document.getElementById("swal-input1") as HTMLInputElement
            ).value;
            if (inputValue !== checkedTags.size.toString()) {
              Swal.showValidationMessage("Please type the correct number");
            }
          },
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33"
        });
        if (confirm) {
          try {
            Swal.fire({
              title: "Deleting tags",
              html: "Please wait while we delete your tags",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
            const result = await deleteTag(
              clientID as string,
              Array.from(checkedTags)
            );

            if (result === "success") {
              Swal.fire({
                icon: "success",
                title: "Your tags have been deleted",
                confirmButtonColor: "#3085d6"
              });
              getTagData();
              setCheckedTags(new Set());
              setSelectedTag(undefined);
            } else if (result === "successfulBulk") {
              Swal.fire({
                icon: "success",
                title: "Worker has been created",
                confirmButtonColor: "#3085d6"
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete tags",
                text: "Something went wrong, please try again",
                confirmButtonColor: "#3085d6"
              });
            }
          } catch (error) {
            console.log("Error deleting", checkedTags);
          }
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "No tags selected",
        text: "Please select at least one tag to delete",
        confirmButtonColor: "#3085d6"
      });
    }
  };
  /* --------------ShowingDetails----------------- */
  const showTagDetails = (tagID: string) => {
    setSelectedTag(tagID);
  };

  if (error) {
    <div className="error">{error}</div>;
  }

  return (
    <>
      {/* LEFT SIDE */}
      {/* Same scss as automation */}
      <div className={`tag_container_list col-2`}>
        <div className="tag_filters">
          <div className="tag_drop_search_container">
            <div className="checkbox_select d-flex align-center m-0">
              <input
                type="checkbox"
                name=""
                id=""
                className="m-0"
                onChange={handleSelectAllCheckboxChange}
                checked={checkedTags.size === filteredTags.length}
              />
            </div>
            {/* SEARCH */}
            <div className="tag_search_container">
              <Search
                onSearchChange={setSearchTag}
                filtersCleared={isFiltered}
              />
            </div>
          </div>
          <div className="d-flex align-center icons_container">
            <div className="col pl-1">
              {checkedTags && checkedTags.size ? checkedTags.size : 0} /{" "}
              {tags.length}
            </div>
            {(hasPermission("subject") ||
              hasPermission("tag", "all") ||
              hasPermission("tag", "write")) && (
              <div className="icon_add">
                <IconAdd onClick={(e) => createNewTag()} />
              </div>
            )}
            {(hasPermission("subject") ||
              hasPermission("tag", "all") ||
              hasPermission("tag", "delete")) && (
              <div className="icon_delete">
                <IconDelete onClick={(e) => deleteCheckedTags(checkedTags)} />
              </div>
            )}
          </div>
        </div>
        {/* Map all tags */}
        <div className="tag_list_container">
          {(() => {
            // Render the filtered tags
            if (filteredTags && filteredTags.length) {
              return filteredTags.map((tag) => (
                <div
                  key={tag.id}
                  className={`tag_list_item d-flex align-center justify-between ${
                    checkedTags.has(tag.id)
                      ? "tag_list_selected"
                      : selectedTag === tag.id
                        ? "tag_list_clicked"
                        : ""
                  }`}
                  onClick={() => showTagDetails(tag.id)}
                >
                  <div className="tag_list_checkbox_container">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="tag_list_checkbox m-0"
                      onChange={() => handleCheckboxChange(tag.id)}
                      checked={checkedTags.has(tag.id)}
                    />
                  </div>
                  <div className="tag_list_title">{tag.name}</div>
                </div>
              ));
            } else if (isLoading) {
              return (
                <div className="d-flex justify-content-center custom_spinner_container pt-2">
                  <div
                    className="spinner-border"
                    style={{ width: "20px", height: "20px" }}
                    role="status"
                  ></div>
                </div>
              );
            } else {
              return (
                <div className="tag_list_item d-flex align-center justify-between">
                  <p className="tag_list_title">No Tags found</p>
                </div>
              );
            }
          })()}
        </div>
      </div>
      {/* RIGHT SIDE */}
      <div className="tag_details col-10">
        {selectedTag && (
          <TagDetails
            currentTagID={selectedTag}
            tags={tags}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </div>
    </>
  );
}

export default TagsList;
