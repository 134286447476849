// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(75, 75, 75, 0.4941176471);
}
.modal .modal-title {
  color: #4b4b4b;
  padding: 10px;
}
.modal.import_phrase_modal .modal-dialog {
  max-width: 600px;
}
.modal.import_phrase_modal .modal-content .modal-body .container-fluid {
  border: 1px solid #ebebeb;
  padding-bottom: 15px;
}
.modal.import_phrase_modal .modal-header {
  padding-bottom: 0px;
  padding-top: 10px;
  border-radius: 2px;
}
.modal.import_phrase_modal .modal-footer,
.modal.import_phrase_modal .modal-header {
  border: none !important;
}
.modal.import_phrase_modal .modal-footer .container-fluid,
.modal.import_phrase_modal .modal-footer .container-fluid .col {
  padding: 0px;
}
.modal.import_phrase_modal .modal-title {
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  text-align: center;
}
.modal.import_phrase_modal .modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.modal.import_phrase_modal .drop_zone_upload {
  border: 1px dashed #ebebeb !important;
  border-radius: 5px;
  padding: 10px 20px 60px 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.modal.import_phrase_modal .clear_file {
  position: absolute;
  right: 35px;
  color: #1a2345;
  font-size: 30px;
  cursor: pointer;
  z-index: 5;
}`, "",{"version":3,"sources":["webpack://./src/components/Imports/ImportModal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,gDAAA;AACF;AACE;EACE,cAAA;EACA,aAAA;AACJ;AAII;EACE,gBAAA;AAFN;AAKI;EACE,yBAAA;EACA,oBAAA;AAHN;AAMI;EACE,mBAAA;EACA,iBAAA;EACA,kBAAA;AAJN;AAOI;;EAEE,uBAAA;AALN;AAQI;;EAEE,YAAA;AANN;AASI;EACE,yBAAA;EACA,yBAAA;EACA,kBAAA;AAPN;AAUI;EACE,gBAAA;EACA,mBAAA;AARN;AAWI;EACE,qCAAA;EACA,kBAAA;EACA,4BAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AATN;AAYI;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,UAAA;AAVN","sourcesContent":[".modal {\n  display: block;\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  background-color: #4b4b4b7e;\n\n  .modal-title {\n    color: #4b4b4b;\n    padding: 10px;\n    // margin-bottom: 0px;\n    // padding-bottom: 0px;\n  }\n  &.import_phrase_modal {\n    .modal-dialog {\n      max-width: 600px;\n    }\n\n    .modal-content .modal-body .container-fluid {\n      border: 1px solid #ebebeb;\n      padding-bottom: 15px;\n    }\n\n    .modal-header {\n      padding-bottom: 0px;\n      padding-top: 10px;\n      border-radius: 2px;\n    }\n\n    .modal-footer,\n    .modal-header {\n      border: none !important;\n    }\n\n    .modal-footer .container-fluid,\n    .modal-footer .container-fluid .col {\n      padding: 0px;\n    }\n\n    .modal-title {\n      background-color: #f5f5f5;\n      border: 1px solid #ebebeb;\n      text-align: center;\n    }\n\n    .modal-body {\n      padding-top: 0px;\n      padding-bottom: 0px;\n    }\n\n    .drop_zone_upload {\n      border: 1px dashed #ebebeb !important;\n      border-radius: 5px;\n      padding: 10px 20px 60px 20px;\n      text-align: center;\n      position: relative;\n      cursor: pointer;\n    }\n\n    .clear_file {\n      position: absolute;\n      right: 35px;\n      color: #1a2345;\n      font-size: 30px;\n      cursor: pointer;\n      z-index: 5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
