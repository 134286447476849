//Page to display the Studys in cards
//explicitly set the types for the Studys array

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconBack } from "../../assets/images/icons/arrow-left-solid.svg";
import { fetchAllStudiesByClientID } from "../../models/study.model.ts";
import ErrorPage from "../../pages/Error/error.page.tsx";
import { Client, Study } from "../../types.ts";
import "./displayStudies.scss";
import StudyCard from "./studyCard.component";
import StudyInfo from "./studyInfo.component";

import Swal from "sweetalert2";
import { usePermissions } from "../../contexts/UserContext.tsx";

interface DisplayStudiesProps {
  handleBackClick: () => void;
  searchFilter: string;
}

const DisplayStudies: React.FC<DisplayStudiesProps> = ({
  handleBackClick,
  searchFilter
}) => {
  const navigate = useNavigate();
  const [studies, setStudies] = useState<Study[]>([]);
  const { clientID, studyID } = useParams(); // Read values passed on link
  const [client, setClient] = useState<Client | null>(null); // Set initial state to null
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<
    "all" | "active" | "inactive"
  >("active");

  const { hasPermission } = usePermissions();

  useEffect(() => {
    const fetchStudiesData = async () => {
      try {
        const jsonData = await fetchAllStudiesByClientID(clientID as string);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "0344b47e1d8f2ae868f7c8a1fff9c677"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setStudies(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
      }
    };

    const promises = [fetchStudiesData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
      });
  }, [studyID, clientID]);
  const [pageTitle, setPageTitle] = useState<string>("All Studies");

  const [selectedStudy, setSelectedStudy] = useState<Study | null>(null);

  const handleStudyCardClick = (study: Study) => {
    // console.log(`You clicked on ${study.study_name}`);
    if (hasPermission("subject") || hasPermission("study")) {
      navigate(`${study.id}`);
    } else {
      Swal.fire({
        title: "You do not have permission to view the studies",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6"
      });
    }
  };

  //useEffect to filter the studies based on the status set
  useEffect(() => {
    if (statusFilter === "all") {
      setPageTitle("All Studies");
    } else if (statusFilter === "active") {
      setPageTitle("Active Studies");
    } else if (statusFilter === "inactive") {
      setPageTitle("Inactive Studies");
    }
  }, [statusFilter]);

  const [filter, setFilter] = useState<string>("");
  useEffect(() => {
    setFilter(searchFilter);
  }, [searchFilter]);

  return (
    <div className="container">
      <div className="top-container">
        <div id="studies_page_header_component" className="client_page_header">
          <span
            id="button_clients_back"
            className="button_clients_page_back clickable"
            onClick={() => handleBackClick()}
          >
            <IconBack className="icon" />
          </span>
          <h2>{pageTitle}</h2>
        </div>
        {/* At the flex end we will have three radio groups ssyled with bootstrap All / Active /Inactive */}
        <div className="btn-group group-container" role="group">
          <button
            type="button"
            className={`btn btn-outline-primary ${
              statusFilter === "all" ? "active" : ""
            }`}
            onClick={() => setStatusFilter("all")}
          >
            All
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              statusFilter === "active" ? "active" : ""
            }`}
            onClick={() => setStatusFilter("active")}
          >
            Active
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              statusFilter === "inactive" ? "active" : ""
            }`}
            onClick={() => setStatusFilter("inactive")}
          >
            Inactive
          </button>
        </div>
      </div>
      <div className="row">
        {/* order alphabetically by the  client.name */}
        {/* map the clients array to a ClientCard component */}
        {/* If a client is selected only show that cleint card */}
        {!loading && !loadingError ? (
          selectedStudy ? (
            <>
              <StudyCard study={selectedStudy} onClick={handleStudyCardClick} />
              <StudyInfo study={selectedStudy} />
            </>
          ) : studies.length < 1 ? (
            <h4>No studies</h4>
          ) : (
            <>
              {studies
                .sort((a: any, b: any) =>
                  b.created_at.localeCompare(a.created_at)
                )
                .filter((study) => {
                  // If the filter is "all", return all studies
                  if (statusFilter === "all") {
                    return true;
                  }
                  // If the filter is "active" or "inactive", return only the studies that match the filter
                  if (study.study_status.toLowerCase() === statusFilter) {
                    return true;
                  }
                  // If none of the above conditions are met, do not include the study in the filtered list
                  return false;
                })
                .filter((study) => {
                  if (
                    study.study_name
                      .toLowerCase()
                      .includes(filter.toLowerCase())
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((study) => (
                  <StudyCard
                    key={study.id}
                    study={study}
                    onClick={handleStudyCardClick}
                  />
                ))}
            </>
          )
        ) : loading ? (
          <div className="d-flex justify-content-center custom_spinner_container">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <ErrorPage
            errorCode="500"
            error="Error fetching data"
            debugCode={loadingErrorText}
          />
        )}
      </div>
    </div>
  );
};

export default DisplayStudies;
