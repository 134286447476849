import React, { useEffect, useState } from "react";
// import ParticipantsTable from "../"
import "./filtersContainer.scss";

import { IconArrowDown, IconArrowUp } from "../../../assets/images/icons/icons";

interface SortOrderByDateProps {
  filtersCleared: boolean;
  onSortOrderByDateChange: (sortOrder: string) => void;
}

const SortOrderByDate: React.FC<SortOrderByDateProps> = ({
  onSortOrderByDateChange,
  filtersCleared
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const toggleOrderByDate = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    onSortOrderByDateChange(newSortOrder);
  };
  useEffect(() => {
    // clear sort filters
    if (filtersCleared) {
      setSortOrder(null);
    }
  }, [filtersCleared]);

  return (
    <div
      className="dropdown dropdown_uid btn btn-primary clickable sortOrderBy"
      onClick={toggleOrderByDate}
      title="Sorts fields based on date joined study"
    >
      <span>
        Sort by Date{" "}
        {sortOrder === "asc" ? (
          <IconArrowUp className="icon icon_white" />
        ) : (
          <IconArrowDown className="icon icon_white" />
        )}
      </span>
    </div>
  );
};

export default SortOrderByDate;
