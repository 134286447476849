// TypesFilter.tsx

import React, { useEffect, useRef, useState } from "react";
import { LanguageLibraryCategoryType } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface FilterLangLibraryCategoryTypeProps {
  jsonTypesData: LanguageLibraryCategoryType[];
  onFilterChange: (types: string[]) => void;
  filtersCleared: boolean;
  location: string;
}

const FilterLangLibraryCategoryType: React.FC<
  FilterLangLibraryCategoryTypeProps
> = ({ jsonTypesData, onFilterChange, filtersCleared, location }) => {
  const [typesFilter, setTypesFilter] = useState<string>("");
  const [typesDropdown, setTypesDropdown] = useState(false);
  const [categoryTypesFilter, setCategoryTypesFilter] = useState<
    Record<string, boolean>
  >({});

  const handleTypesDropdown = (show: boolean) => {
    // show/hide dropdown
    setTypesDropdown(show);
  };

  const handleTypesSearchFilter = (search: string) => {
    // set search value
    setTypesFilter(search);
  };

  const handleTypesFilter = (type: string, checked: boolean) => {
    // set types filter
    setCategoryTypesFilter((prevTypes) => ({
      ...prevTypes,
      [type]: checked // set checked status for this type
    }));
  };

  useEffect(() => {
    // send types filter to parent component
    onFilterChange(
      Object.keys(categoryTypesFilter).filter(
        (type) => categoryTypesFilter[type] // filter types that are true
      )
    );
  }, [categoryTypesFilter, onFilterChange]);

  useEffect(() => {
    // clear types filter when filters are cleared
    if (filtersCleared) {
      setTypesFilter("");
      setCategoryTypesFilter({});
      handleTypesSearchFilter("");
      // handleTypesDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handleTypesDropdown(false);
  });

  return (
    <div className="dropdown dropdown_types" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleTypesDropdown(!typesDropdown)}
      >
        Types
      </button>
      <div className={`dropdown-menu ${typesDropdown ? "show" : "hide"}`}>
        <input
          type="text"
          placeholder="Search Types"
          value={typesFilter}
          onChange={(e) => handleTypesSearchFilter(e.target.value)}
        />
        {
          /* filter by tagsfilter set by input */
          /* loop through all tags */
          jsonTypesData.map(
            (categoryType: LanguageLibraryCategoryType, index: number) => {
              if (
                categoryType.name
                  .toLowerCase()
                  .includes(typesFilter.toLowerCase())
              ) {
                return (
                  <div
                    key={`searchTags_${categoryType}_${index}`}
                    className="dropdown_menu_item"
                  >
                    <label
                      htmlFor={`${location}_${categoryType.name}`}
                      className="form_control"
                    >
                      <input
                        type="checkbox"
                        id={`${location}_${categoryType.name}`}
                        name={categoryType.name}
                        value={categoryType.id}
                        checked={categoryTypesFilter[categoryType.id] || false} // use checked property here
                        onChange={(e) =>
                          handleTypesFilter(e.target.value, e.target.checked)
                        }
                      />
                      {categoryType.name}
                    </label>
                  </div>
                );
              } else {
                return null;
              }
            }
          )
        }
      </div>
    </div>
  );
};

export default FilterLangLibraryCategoryType;
