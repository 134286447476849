import React from "react";

interface ViewResearcherModalFooterProps {
  editing: boolean;
  handleSave: () => void;
  handleCancel: () => void;
  setEditing: (editing: boolean) => void;
  canBeSaved: boolean;
}

const ViewResearcherModalFooter: React.FC<ViewResearcherModalFooterProps> = ({
  editing,
  handleSave,
  handleCancel,
  setEditing,
  canBeSaved
}) => (
  <div className="modal-footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col d-flex justify-content-end">
          {
            <>
              {editing && (
                <>
                  <button
                    className={"btn btn-primary me-2 "}
                    onClick={() => handleSave()}
                    disabled={!canBeSaved}
                  >
                    Save
                  </button>
                </>
              )}
              {editing ? (
                <button
                  className="btn btn-secondary"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-primary"
                    onClick={() => setEditing(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </>
          }
        </div>
      </div>
    </div>
  </div>
);

export default ViewResearcherModalFooter;
