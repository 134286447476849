import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import {
  exportAllCategoryPhrase,
  exportAllStudyPhrases,
  exportAllSurveyPhrases
} from "../models/translations.model";

import {
  Language,
  LanguageLibraryPhrase,
  LanguageLibraryTranslation
} from "../types";

export function exportTranslations(
  phrase: LanguageLibraryPhrase,
  translations: LanguageLibraryTranslation[],
  languages: Language[]
): void {
  try {
    // Create a new worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
      ["Language", "ISO", "Translation"] // Headers
    ]);

    // Add the phrase details
    XLSX.utils.sheet_add_aoa(ws, [["Phrase (EN)", phrase.id, phrase.name]], {
      origin: -1
    });

    // Add translations
    translations.forEach((translation) => {
      const language = languages.find(
        (lang) => lang.iso_code === translation.language_iso
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            language?.name || "",
            translation.language_iso,
            translation.translation
          ]
        ],
        { origin: -1 }
      );
    });

    // Create a new workbook and add the worksheet to it
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Phrase ${phrase.id}`);

    // Set fixed column widths
    const wscols = [
      { wch: 37 }, // Column A width
      { wch: 9 }, // Column B width
      { wch: 143 } // Column C width
    ];
    ws["!cols"] = wscols;

    // Export the workbook as a binary string
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Create a link element for download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Phrase ${phrase.id} Export.xlsx`;

    // Append link to the body, click it, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    // Display an error message using sweetalert2
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed to export translations."
    });
    console.error(error);
  }
}

// Function to convert a binary string to an ArrayBuffer
function s2ab(s: string): ArrayBuffer {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

export const handleExportPhrase = async (
  existingPhrase: boolean,
  ID: string,
  source: string,
  clientID?: string
) => {
  const sendExportRequest = async () => {
    let response = "";

    switch (source) {
      case "Category":
        response = await exportAllCategoryPhrase(ID);
        break;
      case "Study":
        response = await exportAllStudyPhrases(ID, clientID as string);
        break;
      case "Survey":
        response = await exportAllSurveyPhrases(ID, clientID as string);
        break;
      default:
        break;
    }
    if (response === "success") {
      Swal.fire({
        title: "Phrase Export Successful",
        text: `Please note your export has been create and will be sent to your email address shortly.`,
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3085d6"
      });
    } else {
      Swal.fire({
        title: "Error During Phrase Export",
        text: `We regret to inform you that an error occurred while exporting all phrases and their respective translations for ${source.toLowerCase()}.We kindly request you to attempt the export again later`,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3085d6"
      });
    }
  };
  //Check for workerID first
  const workerID = localStorage.getItem("workerID");
  if (workerID && workerID !== null) {
    //Work already in progress
    Swal.fire({
      icon: "error",
      title: "Please wait for the current worker to finish",
      confirmButtonColor: "#3085d6"
    });
  }
  //Check if there are phrases to export
  else if (existingPhrase === true) {
    Swal.fire({
      title: `Export All Phrases in this ${source}`,
      text: "Kindly be aware that exporting all phrases and their respective translations may require some time. Rest assured, we will notify you by email once the export is complete. Are you sure you wish to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, export!",
      confirmButtonColor: "#3085d6",
      cancelButtonText: "No, cancel",
      showCloseButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        // have a loading swal and then send the request
        sendExportRequest();
      } else {
        return;
      }
    });
  } else {
    Swal.fire({
      title: "No phrases to export!",
      text: "There are no phrases to export for this category.",
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6"
    });
  }
};
