import React from "react";
import { ConfigMobileAppStyles } from "../../../../../types";
import InformationTooltip from "../../../../InputInformation/InfoInputs.component";
import ColorPicker from "./Fields/colorPicker.component";
import IconDropdown from "./Fields/iconDropdown.componet";
import ImageFileInput from "./Fields/imageFileInput.component";
import "./SingleAppData.scss";

type SingleAppDataProps = {
  data: ConfigMobileAppStyles;
  handleChange: (
    settingName: keyof ConfigMobileAppStyles,
    id: string,
    dbField: string,
    value: string
  ) => void;
};

const SingleAppData: React.FC<SingleAppDataProps> = ({
  data,
  handleChange
}) => {
  return (
    <div className="single_app_data">
      {/* <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.firebaseKeyIOS.key}`}
          />
          Firebase Key IOS
          {data.firebaseKeyIOS.screen && (
            <div className="opacity-75">
              Screen: {data.firebaseKeyIOS.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <FileInput
            settingName="firebaseKeyIOS"
            fieldID={data.firebaseKeyIOS._id.$oid}
            fieldKey={data.firebaseKeyIOS.key}
            fieldValue={data.firebaseKeyIOS.value}
            handleChange={handleChange}
          />
        </div>
      </div> */}
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.appLogo.key}`}
          />
          App Logo
        </div>
        <div className="col-12 col-lg-8">
          <ImageFileInput
            settingName="appLogo"
            fieldID={data.appLogo._id.$oid}
            fieldKey={data.appLogo.key}
            fieldValue={data.appLogo.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.appIcon.key}`}
          />
          App Icon
          {data.appIcon.screen && (
            <div className="opacity-75">Screen: {data.appIcon.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ImageFileInput
            settingName="appIcon"
            fieldID={data.appIcon._id.$oid}
            fieldKey={data.appIcon.key}
            fieldValue={data.appIcon.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgImg01.key}`}
          />
          Background Image 01
          {data.bgImg01.screen && (
            <div className="opacity-75">Screen: {data.bgImg01.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ImageFileInput
            settingName="bgImg01"
            fieldID={data.bgImg01._id.$oid}
            fieldKey={data.bgImg01.key}
            fieldValue={data.bgImg01.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.primaryColor01.key}`}
          />
          Primary Color 01
          {data.primaryColor01.screen && (
            <div className="opacity-75">
              Screen: {data.primaryColor01.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="primaryColor01"
            fieldValue={data.primaryColor01.value}
            handleChange={handleChange}
            fieldID={data.primaryColor01._id.$oid}
            fieldKey={data.primaryColor01.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.primaryColor02.key}`}
          />
          Primary Color 02
          {data.primaryColor02.screen && (
            <div className="opacity-75">
              Screen: {data.primaryColor02.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="primaryColor02"
            fieldValue={data.primaryColor02.value}
            handleChange={handleChange}
            fieldID={data.primaryColor02._id.$oid}
            fieldKey={data.primaryColor02.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.primaryColor03.key}`}
          />
          Primary Color 03
          {data.primaryColor03.screen && (
            <div className="opacity-75">
              Screen: {data.primaryColor03.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="primaryColor03"
            fieldValue={data.primaryColor03.value}
            handleChange={handleChange}
            fieldID={data.primaryColor03._id.$oid}
            fieldKey={data.primaryColor03.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.highlightColor.key}`}
          />
          Highlight Color
          {data.highlightColor.screen && (
            <div className="opacity-75">
              Screen: {data.highlightColor.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="highlightColor"
            fieldValue={data.highlightColor.value}
            handleChange={handleChange}
            fieldID={data.highlightColor._id.$oid}
            fieldKey={data.highlightColor.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.highlightTextColor.key}`}
          />
          Highlight Text Color
          {data.highlightTextColor.screen && (
            <div className="opacity-75">
              Screen: {data.highlightTextColor.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="highlightTextColor"
            fieldValue={data.highlightTextColor.value}
            handleChange={handleChange}
            fieldID={data.highlightTextColor._id.$oid}
            fieldKey={data.highlightTextColor.key}
          />
        </div>
      </div>
      {/* <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgImg02.key}`}
          />
          Background Image 02
          {data.bgImg02.screen && (
            <div className="opacity-75">Screen: {data.bgImg02.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <TextInput
            settingName="bgImg02"
            fieldID={data.bgImg02._id.$oid}
            fieldKey={data.bgImg02.key}
            fieldValue={data.bgImg02.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgImg03.key}`}
          />
          Background Image 03
          {data.bgImg03.screen && (
            <div className="opacity-75">Screen: {data.bgImg03.screen}</div>
          )}
        </div> 
        <div className="col-12 col-lg-8">
          <TextInput
            settingName="bgImg03"
            fieldID={data.bgImg03._id.$oid}
            fieldKey={data.bgImg03.key}
            fieldValue={data.bgImg03.value}
            handleChange={handleChange}
          />
        </div>
      </div>
       */}
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgColor01.key}`}
          />
          Background Color 01
          {data.bgColor01.screen && (
            <div className="opacity-75">Screen: {data.bgColor01.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="bgColor01"
            fieldValue={data.bgColor01.value}
            handleChange={handleChange}
            fieldID={data.bgColor01._id.$oid}
            fieldKey={data.bgColor01.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgColor02.key}`}
          />
          Background Color 02
          {data.bgColor02.screen && (
            <div className="opacity-75">Screen: {data.bgColor02.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="bgColor02"
            fieldValue={data.bgColor02.value}
            handleChange={handleChange}
            fieldID={data.bgColor02._id.$oid}
            fieldKey={data.bgColor02.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgColor03.key}`}
          />
          Background Color 03
          {data.bgColor03.screen && (
            <div className="opacity-75">Screen: {data.bgColor03.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="bgColor03"
            fieldValue={data.bgColor03.value}
            handleChange={handleChange}
            fieldID={data.bgColor03._id.$oid}
            fieldKey={data.bgColor03.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnColor01.key}`}
          />
          Button Color 01
          {data.btnColor01.screen && (
            <div className="opacity-75">Screen: {data.btnColor01.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnColor01"
            fieldValue={data.btnColor01.value}
            handleChange={handleChange}
            fieldID={data.btnColor01._id.$oid}
            fieldKey={data.btnColor01.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnText01.key}`}
          />
          Button Text 01
          {data.btnText01.screen && (
            <div className="opacity-75">Screen: {data.btnText01.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnText01"
            fieldValue={data.btnText01.value}
            handleChange={handleChange}
            fieldID={data.btnText01._id.$oid}
            fieldKey={data.btnText01.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnBorder01.key}`}
          />
          Button Border 01
          {data.btnBorder01.screen && (
            <div className="opacity-75">Screen: {data.btnBorder01.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnBorder01"
            fieldValue={data.btnBorder01.value}
            handleChange={handleChange}
            fieldID={data.btnBorder01._id.$oid}
            fieldKey={data.btnBorder01.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnColor02.key}`}
          />
          Button Color 02
          {data.btnColor02.screen && (
            <div className="opacity-75">Screen: {data.btnColor02.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnColor02"
            fieldValue={data.btnColor02.value}
            handleChange={handleChange}
            fieldID={data.btnColor02._id.$oid}
            fieldKey={data.btnColor02.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnText02.key}`}
          />
          Button Text 02
          {data.btnText02.screen && (
            <div className="opacity-75">Screen: {data.btnText02.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnText02"
            fieldValue={data.btnText02.value}
            handleChange={handleChange}
            fieldID={data.btnText02._id.$oid}
            fieldKey={data.btnText02.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnBorder02.key}`}
          />
          Button Border 02
          {data.btnBorder02.screen && (
            <div className="opacity-75">Screen: {data.btnBorder02.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnBorder02"
            fieldValue={data.btnBorder02.value}
            handleChange={handleChange}
            fieldID={data.btnBorder02._id.$oid}
            fieldKey={data.btnBorder02.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnColor03.key}`}
          />
          Button Color 03
          {data.btnColor03.screen && (
            <div className="opacity-75">Screen: {data.btnColor03.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnColor03"
            fieldValue={data.btnColor03.value}
            handleChange={handleChange}
            fieldID={data.btnColor03._id.$oid}
            fieldKey={data.btnColor03.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnText03.key}`}
          />
          Button Text 03
          {data.btnText03.screen && (
            <div className="opacity-75">Screen: {data.btnText03.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnText03"
            fieldValue={data.btnText03.value}
            handleChange={handleChange}
            fieldID={data.btnText03._id.$oid}
            fieldKey={data.btnText03.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.btnBorder03.key}`}
          />
          Button Border 03
          {data.btnBorder03.screen && (
            <div className="opacity-75">Screen: {data.btnBorder03.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="btnBorder03"
            fieldValue={data.btnBorder03.value}
            handleChange={handleChange}
            fieldID={data.btnBorder03._id.$oid}
            fieldKey={data.btnBorder03.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.inputColor01.key}`}
          />
          Input Color 01
          {data.inputColor01.screen && (
            <div className="opacity-75">Screen: {data.inputColor01.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="inputColor01"
            fieldValue={data.inputColor01.value}
            handleChange={handleChange}
            fieldID={data.inputColor01._id.$oid}
            fieldKey={data.inputColor01.key}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.inputBorder01.key}`}
          />
          Input Border 01
          {data.inputBorder01.screen && (
            <div className="opacity-75">
              Screen: {data.inputBorder01.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="inputBorder01"
            fieldValue={data.inputBorder01.value}
            handleChange={handleChange}
            fieldID={data.inputBorder01._id.$oid}
            fieldKey={data.inputBorder01.key}
          />
        </div>
      </div>
      {/* <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgImgBtnChat.key}`}
          />
          Chat Button Background Image
          {data.bgImgBtnChat.screen && (
            <div className="opacity-75">Screen: {data.bgImgBtnChat.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <TextInput
            settingName="bgImgBtnChat"
            fieldID={data.bgImgBtnChat._id.$oid}
            fieldKey={data.bgImgBtnChat.key}
            fieldValue={data.bgImgBtnChat.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgImgBtnInfo.key}`}
          />
          Info Button Background Image
          {data.bgImgBtnInfo.screen && (
            <div className="opacity-75">Screen: {data.bgImgBtnInfo.screen}</div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <TextInput
            settingName="bgImgBtnInfo"
            fieldID={data.bgImgBtnInfo._id.$oid}
            fieldKey={data.bgImgBtnInfo.key}
            fieldValue={data.bgImgBtnInfo.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.bgImgBtnRewards.key}`}
          />
          Rewards Button Background Image
          {data.bgImgBtnRewards.screen && (
            <div className="opacity-75">
              Screen: {data.bgImgBtnRewards.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <TextInput
            settingName="bgImgBtnRewards"
            fieldID={data.bgImgBtnRewards._id.$oid}
            fieldKey={data.bgImgBtnRewards.key}
            fieldValue={data.bgImgBtnRewards.value}
            handleChange={handleChange}
          />
        </div>
      </div> */}
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.inputTextColor.key}`}
          />
          Input Text Color
          {data.inputTextColor.screen && (
            <div className="opacity-75">
              Screen: {data.inputTextColor.screen}
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8">
          <ColorPicker
            settingName="inputTextColor"
            fieldValue={data.inputTextColor.value}
            handleChange={handleChange}
            fieldID={data.inputTextColor._id.$oid}
            fieldKey={data.inputTextColor.key}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.taskIcon.key}`}
          />
          Task Icon
        </div>
        <div className="col-12 col-lg-8">
          <IconDropdown
            settingName="taskIcon"
            fieldID={data.taskIcon._id.$oid}
            fieldKey={data.taskIcon.key}
            fieldValue={data.taskIcon.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.resourceIcon.key}`}
          />
          Resource Icon
        </div>
        <div className="col-12 col-lg-8">
          <IconDropdown
            settingName="resourceIcon"
            fieldID={data.resourceIcon._id.$oid}
            fieldKey={data.resourceIcon.key}
            fieldValue={data.resourceIcon.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.rewardIcon.key}`}
          />
          Reward Icon
        </div>
        <div className="col-12 col-lg-8">
          <IconDropdown
            settingName="rewardIcon"
            fieldID={data.rewardIcon._id.$oid}
            fieldKey={data.rewardIcon.key}
            fieldValue={data.rewardIcon.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.alertIcon.key}`}
          />
          Alert Icon
        </div>
        <div className="col-12 col-lg-8">
          <IconDropdown
            settingName="alertIcon"
            fieldID={data.alertIcon._id.$oid}
            fieldKey={data.alertIcon.key}
            fieldValue={data.alertIcon.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.chatIcon.key}`}
          />
          Chat Icon
        </div>
        <div className="col-12 col-lg-8">
          <IconDropdown
            settingName="chatIcon"
            fieldID={data.chatIcon._id.$oid}
            fieldKey={data.chatIcon.key}
            fieldValue={data.chatIcon.value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-4">
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`App Settings Key: ${data.infoIcon.key}`}
          />
          Info Icon
        </div>
        <div className="col-12 col-lg-8">
          <IconDropdown
            settingName="infoIcon"
            fieldID={data.infoIcon._id.$oid}
            fieldKey={data.infoIcon.key}
            fieldValue={data.infoIcon.value}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleAppData;
