import React, { useEffect, useRef, useState } from "react";
import timeJsonData from "../../../assets/data/times.json";
import { useOutsideClick } from "../../../utilities/utils";
import InformationTooltip from "../../InputInformation/InfoInputs.component";
import { Times } from "../../StudySurveyTabSingle/Automation/Timing/timing.component";
import "./fieldTime.scss";

interface FieldTimeProps {
  onInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  inputValue: string;
  tooltip?: string;
  label: string;
  inputName: string;
  subLabel?: string;
  error?: string | null;
  tooltipPosition?: "left" | "right" | "bottom" | "top";
}

const FieldTime: React.FC<FieldTimeProps> = ({
  onInputChange,
  inputValue,
  tooltip,
  label,
  inputName,
  subLabel,
  error,
  tooltipPosition
}) => {
  const [timingDropdown, setTimingDropdown] = useState(false);
  const [timeJsonDate, setTimeJsonDate] = useState<Times[]>([]);

  const [selectedTiming, setSelectedTiming] = useState<string>("");
  const [timeFilter, setTimeFilter] = useState<string>("");

  useEffect(() => {
    setTimeJsonDate(timeJsonData.time);
  }, []);

  useEffect(() => {
    console.log(inputValue);
  }, [inputValue]);

  const handleSelectTiming = (
    localTiming: string,
    e: React.ChangeEvent<any>
  ) => {
    setSelectedTiming(localTiming);
    setTimingDropdown(false);
    onInputChange(inputName, e);
  };

  // Filtering
  const handleTimeSearchFilter = (filter: string) => {
    setTimeFilter(filter);
    setTimeJsonDate(
      timeJsonData.time.filter((time) =>
        time.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
  };

  const handleTimingDropdown = (show: boolean) => {
    setTimingDropdown(show);
  };

  const localTimingRef = useRef<HTMLDivElement>(null);
  useOutsideClick(localTimingRef, () => {
    // Action to take on outside click
    handleTimingDropdown(false);
  });

  return (
    <div className="mb-3 row">
      <div className="col-12 col-lg-4">
        <label htmlFor={inputName} className="form-label mb-1 mx-1">
          {tooltip && (
            <InformationTooltip
              marginTop="-12px"
              position={tooltipPosition ? tooltipPosition : "top"}
              tooltipText={tooltip}
            />
          )}
          {label}
          {subLabel && <span className="sub_label">{subLabel}</span>}
        </label>
      </div>
      <div className="col-12 col-lg-8 field_time">
        {error && <div className="wizard_input_error">{error}</div>}
        {/* <input
          type="time"
          name={inputName}
          id={inputName}
          className="form-control"
          value={inputValue}
          onChange={(e) => {
            onInputChange(inputName, e);
            console.log(inputName, e);
          }}
        /> */}

        <div className="dropdown dropdown_timing" ref={localTimingRef}>
          <button
            className="btn btn-primary dropdown-toggle timing_dropdown_button form-select"
            onClick={() => handleTimingDropdown(!timingDropdown)}
          >
            {inputValue ? inputValue : "Select Time"}
          </button>

          <div className={`dropdown-menu ${timingDropdown ? "show" : "hide"}`}>
            <input
              type="text"
              placeholder="Search Time"
              value={timeFilter}
              onChange={(e) => handleTimeSearchFilter(e.target.value)}
              className="dropdown-timing-search"
            />
            <div className="dropdown-items">
              {timeJsonDate.length > 0 ? (
                timeJsonDate.map((timing) => (
                  <div key={timing.id} className="dropdown-item">
                    <button
                      value={timing.name.split(" ")[0]}
                      onClick={(e) => handleSelectTiming(timing.name, e)}
                    >
                      {timing.name}
                    </button>
                  </div>
                ))
              ) : (
                <div className="text-center w-100 p-1">
                  Please adjust your filter
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldTime;
