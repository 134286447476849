import React from "react";

export type NewPhraseSelectionProps = {
  type: string;
  newPhraseSelection: string;
  handleSetNewPhrase: (phrase: string) => void;
  actionID: string;
};

const NewPhraseSelection: React.FC<NewPhraseSelectionProps> = ({
  type,
  newPhraseSelection,
  handleSetNewPhrase,
  actionID
}) => {
  return (
    <div className="timing_radio_container new_existing_phrase_radio">
      {/* Existing Phrase */}

      <div className="form-check existing_radio">
        <input
          className="form-check-input"
          type="radio"
          name={`${actionID}_flexRadioExistingPhrase`}
          id={`${actionID}_flexRadioExistingPhrase`}
          checked={newPhraseSelection === "existing"}
          onChange={() => handleSetNewPhrase("existing")}
        ></input>
        <label
          className="form-check-label"
          htmlFor={`${actionID}_flexRadioExistingPhrase`}
        >
          Existing Phrases
        </label>
      </div>
      {/* New Phrase */}
      <div className="form-check new_radio">
        <input
          className="form-check-input"
          type="radio"
          name={`${actionID}flexRadioNewPhrase`}
          id={`${actionID}flexRadioNewPhrase`}
          checked={newPhraseSelection === "new"}
          onChange={() => handleSetNewPhrase("new")}
        ></input>
        <label
          className="form-check-label"
          htmlFor={`${actionID}flexRadioNewPhrase`}
        >
          New Phrase
        </label>
      </div>
    </div>
  );
};

export default NewPhraseSelection;
