import React, { useEffect, useState } from "react";
import { ConfigPhraseCategory } from "../../../types";
import { getSystemConfigPhraseCategories } from "../../../utilities/config.util";
import "./categories.scss";
import SingleCategory from "./singleCategory.component";

export type CategoryContainerProps = {
  selectedCategory: ConfigPhraseCategory | null;
  // setSelectedPhrase: (phrase: LanguageLibraryPhrase | null) => void;
  setSelectedCategory: (category: ConfigPhraseCategory | null) => void;
  loadingCategory: boolean;
  setLoadingCategory: (loading: boolean) => void;
  handleAddPhraseClick: () => void;
};

const CategoryContainer: React.FC<CategoryContainerProps> = ({
  selectedCategory,
  setSelectedCategory,
  loadingCategory,
  setLoadingCategory,
  handleAddPhraseClick
}) => {
  const [languageLibraryCategories, setLanguageLibraryCategories] = useState<
    ConfigPhraseCategory[] | null
  >(null); // Set initial state to null
  const [loadingError, setLoadingError] = useState<boolean>(true);
  /*------------------------------------------------------------------------------*/
  /*-----------------------------Functions for all--------------------------------*/
  /*------------------------------------------------------------------------------*/
  //---------Function to handle category click
  const handleCategoryClick = (categoryID: string) => {
    setSelectedCategory(
      languageLibraryCategories?.find(
        (category) => category.id === categoryID
      ) || null
    );
  };

  useEffect(() => {
    const fetchLanguageLibraryLibraryCategories = async () => {
      try {
        const jsonData: false | ConfigPhraseCategory[] =
          await getSystemConfigPhraseCategories();
        // if jsonData is a false, then there was an error
        if (jsonData === false) {
          console.log("jsonData is false");
          console.log("jsonData", jsonData);
          setLanguageLibraryCategories(null);
          setSelectedCategory(null);
          setLoadingError(true);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setLanguageLibraryCategories(jsonData);

          if (jsonData.length > 0) {
            // setSelectedCategoryID to first category
            setSelectedCategory(jsonData[0]);
          } else {
            setSelectedCategory(null);
          }
          setLoadingError(false);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLanguageLibraryCategories(null);
        setLoadingError(true);
      }
    };

    const promises = [fetchLanguageLibraryLibraryCategories()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingCategory(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingCategory(false);
        setLoadingError(true);
        setSelectedCategory(null);
      });
  }, []);

  useEffect(() => {
    // Check if newFilteredCategories is not null and has some categories

    setSelectedCategory(
      languageLibraryCategories?.find(
        (category) => category.id === selectedCategory?.id
      ) || null
    );
  }, [languageLibraryCategories]);

  //Export function for all

  return (
    <>
      <div className="category_container card">
        <div className="top_bar card-header">
          <div>
            Categories
            <button
              id="btn_phrases_import"
              className="btn btn-primary ms-2"
              onClick={handleAddPhraseClick}
            >
              Import
            </button>
            {/* Export button */}
          </div>
        </div>
        <div className="main_category_singles_container">
          {loadingCategory ? (
            <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : loadingError ? (
            <div className="error_container">
              <div className="error_text">
                There was an error loading the categories. Please try again
                later.
              </div>
            </div>
          ) : languageLibraryCategories &&
            languageLibraryCategories?.length > 0 ? (
            languageLibraryCategories.map((category) => (
              <SingleCategory
                key={category.id}
                categoryID={category.id}
                categoryName={category.phrase_category_name}
                isSelected={Boolean(
                  selectedCategory &&
                    selectedCategory.id &&
                    selectedCategory.id === category.id
                )}
                handleCategoryClick={handleCategoryClick}
              />
            ))
          ) : (
            <div className="no_categories_container">
              <div className="no_categories_text">
                No categories have been created yet.
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CategoryContainer;
