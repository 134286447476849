// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tfa_modal_container .show_modal {
  display: block !important;
  opacity: 1;
}
.tfa_modal_container .modal-header {
  align-items: center;
}
.tfa_modal_container .modal-header img {
  width: 20px;
  margin-right: 5px;
  margin-top: 1px;
}
.tfa_modal_container a {
  text-decoration: none;
}
.tfa_modal_container .enter_code {
  margin-top: 1rem;
}
.tfa_modal_container .mfa_code_input {
  outline: none !important;
  height: 100%;
  border: 1px solid #8a8a8a;
  border-radius: 4px;
  padding-left: 5px;
  margin-top: 2px;
}
.tfa_modal_container .p_under_list {
  padding: 0px 32px 0px 32px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/MFAModal/tfaModal.scss"],"names":[],"mappings":"AAGE;EACE,yBAAA;EACA,UAAA;AAFJ;AAIE;EACE,mBAAA;AAFJ;AAII;EACE,WAAA;EACA,iBAAA;EACA,eAAA;AAFN;AAME;EACE,qBAAA;AAJJ;AAOE;EACE,gBAAA;AALJ;AAQE;EACE,wBAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AANJ;AASE;EACE,0BAAA;EACA,kBAAA;AAPJ","sourcesContent":["@use \"../../global.scss\" as *;\n\n.tfa_modal_container {\n  .show_modal {\n    display: block !important;\n    opacity: 1;\n  }\n  .modal-header {\n    align-items: center;\n\n    img {\n      width: 20px;\n      margin-right: 5px;\n      margin-top: 1px;\n    }\n  }\n\n  a {\n    text-decoration: none;\n  }\n\n  .enter_code {\n    margin-top: 1rem;\n  }\n\n  .mfa_code_input {\n    outline: none !important;\n    height: 100%;\n    border: 1px solid #8a8a8a;\n    border-radius: 4px;\n    padding-left: 5px;\n    margin-top: 2px;\n  }\n\n  .p_under_list {\n    padding: 0px 32px 0px 32px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
