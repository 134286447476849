import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconBroom } from "../../../../assets/images/icons/icons";
import {
  MessagesHistory,
  fetchAllReceivedMessages
} from "../../../../models/messages.model";
import { ParamsSurveyPage } from "../../../../types";
import MultipleDropdown from "../../../Dropdown/multipleDropdown.component";
import LoadingPencil from "../../../Loaders/LoadingPencil";
import PaginationNavigation from "../../../Tables/PaginationNavigation/paginationNavigation";
import RecipientHistory from "../History/recipientHistory.component";
import "./history.scss";

interface historyProps {
  studyClicked: string;
}

const History: React.FC<historyProps> = () => {
  //Controls
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  //Data
  const [data, setData] = useState<MessagesHistory | undefined>();
  const [filterData, setFilterData] = useState<MessagesHistory | undefined>();
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const { clientID, studyID } = useParams<ParamsSurveyPage>();
  const [showRecipientHistory, setShowRecipientHistory] = useState<boolean>();
  const [selectedCommunicationID, setSelectedCommunicationID] =
    useState<string>();
  const [selectedPhraseLibraryID, setSelectedPhraseLibraryID] =
    useState<string>();
  //Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  /*----------------------------------------------------*/
  /*------------------Loading data----------------------*/
  const loadData = async () => {
    if (clientID && studyID) {
      setIsLoading(true);
      try {
        const response = await fetchAllReceivedMessages(clientID, studyID);
        if (typeof response !== "string") {
          setData(response);
        } else setError("No History Data");
      } catch (error) {
        setError("Error loading data");
      }
      setIsLoading(false);
    } else setError("No Client or Study ID");
  };
  useEffect(() => {
    loadData();
  }, []);
  //Filter Data for the date from and to , the input and the types dropdown
  useEffect(() => {
    if (data) {
      let filteredData = data;

      if (fromDate) {
        filteredData = filteredData.filter((item) => item.date >= fromDate);
      }
      if (toDate) {
        filteredData = filteredData.filter((item) => item.date <= toDate);
      }
      if (selectedType.length) {
        filteredData = filteredData.filter((item) =>
          selectedType.includes(item.type)
        );
      }
      if (searchInput) {
        filteredData = filteredData.filter((item) =>
          item.phraseLibrary.message.includes(searchInput)
        );
      }
      setFilterData(filteredData);
    }
  }, [fromDate, toDate, selectedType, searchInput, data]);
  const currentItems =
    filterData?.slice(indexOfFirstItem, indexOfLastItem) || [];

  /*----------------------------------------------------*/
  /*------------------Functions-------------------------*/
  const viewRecipientHistory = (
    communicationID: string,
    phraseLibraryID: string
  ) => {
    setSelectedCommunicationID(communicationID);
    setSelectedPhraseLibraryID(phraseLibraryID);
    setShowRecipientHistory(true);
  };

  const hideRecipientHistory = () => {
    setShowRecipientHistory(false);
  };

  const handleTypeChange = (option: string) => {
    setSelectedType((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };
  const handleClearFilters = () => {
    setFromDate("");
    setToDate("");
    setSelectedType([]);
    setSearchInput("");
  };
  /*----------------------------------------------------*/
  /*---------------------Renders------------------------*/
  const formatDateAndTime = (isoDate: string | Date) => {
    if (!isoDate) {
      return { date: "Scheduled for sending", time: "Scheduled for sending" };
    }

    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();

    return { date: formattedDate, time: formattedTime };
  };

  if (isLoading) {
    return <LoadingPencil isVisible={isLoading} title={"history"} />;
  }

  return (
    <div className="history-container">
      {showRecipientHistory &&
      selectedCommunicationID &&
      selectedPhraseLibraryID ? (
        <RecipientHistory
          communicationID={selectedCommunicationID}
          phraseLibraryID={selectedPhraseLibraryID}
          hideRecipientHistory={hideRecipientHistory}
        />
      ) : (
        <>
          <div className="history-title-container">
            <h5 className="history-title">Message History</h5>
          </div>
          <div className="history-info-container">
            <div className="history-filter">
              <div className="history-filter-date">
                <label className="date-header" htmlFor="fromDate">
                  From
                </label>
                <input
                  className="date-input"
                  type="date"
                  id="fromDate"
                  name="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="history-filter-date">
                <label className="date-header" htmlFor="toDate">
                  To{" "}
                </label>
                <input
                  className="date-input"
                  type="date"
                  id="toDate"
                  name="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="history-filter-dropdown">
                <MultipleDropdown
                  placeholder="Types"
                  options={["SMS", "Email", "Push Notification"]}
                  onOptionToggled={handleTypeChange}
                  selectedOptions={selectedType}
                />
              </div>
              <div className="history-filter-search-container">
                <input
                  className="search-input"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  className="btn btn-primary btn-clear"
                  disabled={false}
                  onClick={handleClearFilters}
                >
                  <IconBroom className="icon icon_white" />
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-container">
                <thead>
                  <tr>
                    <th className="medium-col">Date</th>
                    <th className="medium-col">Time</th>
                    <th className="small-col">Amount</th>
                    <th className="small-col">Sent</th>
                    <th className="small-col">Delivered</th>
                    <th className="large-col">Preview</th>
                    <th className="small-col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item, index) => {
                      const { date, time } = formatDateAndTime(item.date);

                      return (
                        <tr key={index}>
                          <td>{date}</td>
                          <td>{time}</td>
                          <td>{item.amount}</td>
                          <td>{item.sent}</td>
                          <td>{item.delivered}</td>
                          <td className="preview-col">
                            {item.phraseLibrary.message}
                          </td>
                          <td className="text-center">
                            <button
                              className="btn btn-primary btn-view"
                              onClick={() =>
                                viewRecipientHistory(
                                  item.id,
                                  item.phraseLibrary.id
                                )
                              }
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <PaginationNavigation
                currentPage={currentPage}
                totalResults={filterData?.length || 0}
                resultsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default History;
