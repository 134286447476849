import React from "react";
import * as Icons from "../../../assets/images/icons/icons.ts";
import {
  Client,
  Country,
  Language,
  LeadsParticipant,
  Timezone
} from "../../../types.ts";
import { convertTime, formatDate } from "../../../utilities/utils.ts";
import ParticipantActions from "../ParticipantActions/participantAction.tsx";

interface LeadsTableProps {
  currentParticipants: LeadsParticipant[];
  selectedParticipants: string[];
  filteredParticipantsLength: number;
  participantID: string;
  allTimezones: Timezone[];
  timezone: string;
  countries: Country[];
  languages: Language[];
  activeParticipantId: string | null;
  researcherTimezone: Timezone | null;
  allClients: Client[];
  handleSelectParticipant: (participantID: string) => void;
  handleSelectAllParticipants: () => void;
  handleViewParticipant: (participantID: string) => void;
  handleParticipantActionsDropdown: (participantID: string) => void;
}

const LeadsTable: React.FC<LeadsTableProps> = ({
  currentParticipants,
  selectedParticipants,
  filteredParticipantsLength,
  participantID,
  timezone,
  allTimezones,
  countries,
  languages,
  activeParticipantId,
  researcherTimezone,
  allClients,
  handleSelectParticipant,
  handleSelectAllParticipants,
  handleViewParticipant,
  handleParticipantActionsDropdown
}) => {
  const isAllFieldsNull = (participant: LeadsParticipant) => {
    const fieldsToCheck = [
      participant.first_name,
      participant.last_name,
      participant.email,
      participant.mobile_number,
      participant.country_iso,
      participant.age,
      participant.gender,
      participant.region
    ];
    return fieldsToCheck.every((field) => field === null);
  };

  return (
    <div className="table-responsive">
      <table className="cust_table table">
        <thead>
          <tr>
            <th className="column_sticky">
              <input
                type="checkbox"
                checked={
                  selectedParticipants.length === filteredParticipantsLength
                }
                className="clickable"
                onChange={handleSelectAllParticipants}
              />
            </th>
            <th className="column_sticky">Name</th>
            <th>ID</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Country</th>
            <th>Region</th>
            <th>Age</th>
            <th>Gender</th>

            <th>Current client</th>
            <th>Last Client</th>
            <th>Cheater Score</th>
            <th>Participation Score</th>
            <th>Source</th>
            <th>Language</th>
            <th>Created</th>
            <th>Last Used</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentParticipants.map(
            (participant: LeadsParticipant, index: number) => {
              // console.log(participant.first_name === null);
              const allFieldsNull = isAllFieldsNull(participant);

              const fullName = allFieldsNull
                ? "PII"
                : `${participant.first_name || ""} ${
                    participant.last_name || ""
                  }`.trim() || "N/A";

              // const name = fullName.trim() !== "" ? fullName : "N/A";
              const ID = participant.id.toString() || "N/A";
              const email = allFieldsNull ? "PII" : participant.email || "N/A";
              const mobile = allFieldsNull
                ? "PII"
                : participant.mobile_number || "N/A";
              const countryName = allFieldsNull
                ? "PII"
                : countries.find(
                    (country) =>
                      country.iso_code.toUpperCase() === participant.country_iso
                  )?.name || "N/A";
              const age = allFieldsNull ? "PII" : participant.age || "N/A";
              const gender = allFieldsNull
                ? "PII"
                : participant.gender || "N/A";
              const region = allFieldsNull
                ? "PII"
                : participant.region || "N/A";
              const cheaterScore = participant.cheater_score
                ? participant.cheater_score + "%"
                : "N/A";
              const participationScore = participant.participation_score
                ? participant.participation_score + "%"
                : "N/A";
              // const source = participant.source || "N/A";
              let source = "N/A";
              if (participant.source === "1") {
                source = "Lead";
              } else {
                source =
                  allClients.find((client) => client.id === participant.source)
                    ?.name || "N/A";
              }

              const languageName =
                languages.find(
                  (language) => language.iso_code === participant.language_iso
                )?.name || "N/A";
              let lastClient = "N/A";
              if (participant.last_client === "1") {
                lastClient = "Lead";
              } else {
                lastClient =
                  allClients.find(
                    (client) => client.id === participant.last_client
                  )?.name || "N/A";
              }
              let currentClient = "N/A";
              if (participant.current_client === "1") {
                currentClient = "Lead";
              } else {
                currentClient =
                  allClients.find(
                    (client) => client.id === participant.current_client
                  )?.name || "N/A";
              }

              let timezoneOffset = 0;
              // get participant's timezone offset based off of thier timezone, filter though allTimezones
              const participantOffset = allTimezones.find(
                (timezone) => timezone.name === participant.timezone
              )?.offset;
              if (timezone === "Participant's Timezone" && participantOffset) {
                timezoneOffset = Number(participantOffset);
              } else if (timezone === "UTC") {
                timezoneOffset = 0;
              } else if (timezone === "ResearcherTimezone") {
                timezoneOffset = researcherTimezone
                  ? researcherTimezone.offset
                  : 0;
              }

              const lastUsed = participant.last_used
                ? formatDate(convertTime(participant.last_used, timezoneOffset))
                : "N/A";

              const createdAt = participant.created_at
                ? formatDate(
                    convertTime(participant.created_at, timezoneOffset)
                  )
                : "N/A";

              return (
                <tr
                  key={participant.id.toString()}
                  className={`${
                    selectedParticipants.includes(participant.id.toString()) &&
                    "row_participant_selected"
                  } ${
                    participantID === participant.id.toString() &&
                    "row_participant_viewing"
                  }`}
                >
                  {allFieldsNull ? (
                    <td className="column_sticky"></td>
                  ) : (
                    <td className="column_sticky">
                      <input
                        type="checkbox"
                        className="checkbox clickable"
                        checked={selectedParticipants.includes(
                          participant.id.toString()
                        )}
                        onChange={() =>
                          handleSelectParticipant(participant.id.toString())
                        }
                      />
                    </td>
                  )}
                  {/* Empty cell for PII rows */}
                  <td className="column_sticky">
                    <span
                      className="participant_name clickable"
                      onClick={() =>
                        handleViewParticipant(participant.id.toString())
                      }
                    >
                      {fullName}
                    </span>
                  </td>
                  <td>{ID}</td>
                  <td>{email}</td>
                  <td>{mobile}</td>
                  <td>{countryName}</td>
                  <td>{region}</td>
                  <td>{age}</td>
                  <td>{gender}</td>
                  <td>{currentClient}</td>
                  <td>{lastClient}</td>
                  <td>{cheaterScore}</td>
                  <td>{participationScore}</td>
                  <td>{source}</td>
                  <td>{languageName}</td>
                  <td>{createdAt}</td>
                  <td>{lastUsed}</td>
                  <td>
                    <button
                      className="button btn btn_settings"
                      onClick={() =>
                        handleParticipantActionsDropdown(
                          participant.id.toString()
                        )
                      }
                      disabled={
                        activeParticipantId === participant.id.toString()
                      }
                    >
                      <Icons.IconSettings className="icon" />
                    </button>
                    {activeParticipantId === participant.id.toString() && (
                      <ParticipantActions
                        participantID={participant.id.toString()}
                        closeDropdown={() =>
                          handleParticipantActionsDropdown("close")
                        }
                        outsideDropdown={() =>
                          handleParticipantActionsDropdown("outside")
                        }
                        shown={
                          activeParticipantId === participant.id.toString()
                        }
                        tableType="leadsTable"
                      />
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default LeadsTable;
