// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_filter_options .date_picker_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table_filter_options .date_picker_container .date_filter_date_to_from {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.table_filter_options .date_picker_container .date_input {
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 5px;
  margin-left: 5px;
}
.table_filter_options .icon_white {
  height: 20px;
  padding-left: 5px;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/Filters/filtersContainer.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAAN;AAGI;EACE,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,gBAAA;AADN;AAKE;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;AAHJ","sourcesContent":[".table_filter_options {\n  .date_picker_container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .date_filter_date_to_from {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      margin-left: 5px;\n    }\n\n    .date_input {\n      border-radius: 5px;\n      border: 1px solid #cecece;\n      padding: 5px;\n      margin-left: 5px;\n    }\n  }\n\n  .icon_white {\n    height: 20px;\n    padding-left: 5px;\n    padding-bottom: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
