import React from "react";
import { Study } from "../../types";

interface StudyInfoProps {
  study: Study;
}

const StudyInfo: React.FC<StudyInfoProps> = ({ study }) => {
  return (
    <div className="col">
      <h2>
        {study.study_name} <span className="small_id">{study.study_name}</span>
      </h2>
      <p>{study.study_desc}</p>
      <button className="btn btn-primary">Settings</button>
    </div>
  );
};

export default StudyInfo;
