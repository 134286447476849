import React from "react";

interface ImageChangeParams {
  event: React.ChangeEvent<HTMLInputElement>;
  surveyThumbnailOldUrl: string;
  setImageUploadFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setSurveyThumbnailNewUrl: React.Dispatch<React.SetStateAction<string>>;
  setSurveyPreviewThumbnailUrl: React.Dispatch<React.SetStateAction<string>>;
}

const imageChange = ({
  event,
  surveyThumbnailOldUrl,
  setImageUploadFlag,
  setSurveyThumbnailNewUrl,
  setSurveyPreviewThumbnailUrl
}: ImageChangeParams) => {
  const e = event;
  if (e.target.files && e.target.files.length > 0) {
    const reader = new FileReader();
    console.log("running the imageChange function");
    reader.onload = (e) => {
      const newUrl = e.target?.result as string;
      setSurveyThumbnailNewUrl(newUrl);
      setSurveyPreviewThumbnailUrl(newUrl);
      setImageUploadFlag(true);
    };
    reader.readAsDataURL(e.target.files[0]);

    reader.onerror = () => {
      // If there's an error reading the file, indicate no new image will be uploaded
      setImageUploadFlag(false);
    };

    return () => {
      reader.abort();
    };
  } else {
    // No file is selected (image deselected)
    // Reset the thumbnail URL and set the flag to false
    setSurveyPreviewThumbnailUrl(surveyThumbnailOldUrl);
    setSurveyThumbnailNewUrl("");
    setImageUploadFlag(false);
  }
};

export default imageChange;
