const LoginLoading = () => {
  return (
    <div id="page_logging_in">
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div
          id="logging_in_card"
          className="card bg-light shadow-lg p-3 mb-5 bg-white rounded"
        >
          <div className="card-body">
            <h3 className="card-title text-center mb-5">
              Research Management System
            </h3>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="spinner-border mb-4" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
              <h1>Logging in</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLoading;
