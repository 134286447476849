// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
  width: 100%;
}
.search-input .options-list {
  position: absolute;
  width: 26rem;
  max-height: 250px;
  overflow-y: auto;
  margin-top: 5px;
  z-index: 10;
  /* This is to ensure the dropdown appears on top of other content */
  background-color: #fff;
  /* You might want a background to make sure the content is readable */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* Optional shadow for a dropdown feel */
  border-radius: 5px;
}
.search-input .options-list .options-list-item {
  padding: 5px;
  border-bottom: 1px solid #eee;
  margin: 0 5px;
}
.search-input .options-list .options-list-item:last-child {
  border-bottom: none;
}
.search-input .options-list .options-list-item:hover {
  background-color: #f1f1f1;
}

.add-option {
  cursor: pointer;
  margin-left: 5px;
  /* Optional space between the input and the add icon */
  margin-top: 5px;
}
.add-option .icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Inputs/search-input.styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,kBAAA;EAGA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,mEAAA;EACA,sBAAA;EACA,qEAAA;EAEA,+CAAA;EACA,wCAAA;EACA,kBAAA;AAFJ;AAII;EACE,YAAA;EACA,6BAAA;EACA,aAAA;AAFN;AAIM;EACE,mBAAA;AAFR;AAKM;EACE,yBAAA;AAHR;;AASA;EACE,eAAA;EAEA,gBAAA;EACA,sDAAA;EACA,eAAA;AAPF;AAWE;EACE,WAAA;EACA,YAAA;EACA,eAAA;AATJ","sourcesContent":[".search-input {\n  width: 100%;\n\n  .options-list {\n    position: absolute;\n    // top: 100%; /* This will position the options-list just below the input */\n    // left: 0;\n    width: 26rem;\n    max-height: 250px;\n    overflow-y: auto;\n    margin-top: 5px;\n    z-index: 10;\n    /* This is to ensure the dropdown appears on top of other content */\n    background-color: #fff;\n    /* You might want a background to make sure the content is readable */\n    // border: 1px solid #ccc; /* Optional border */\n    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n    /* Optional shadow for a dropdown feel */\n    border-radius: 5px;\n\n    .options-list-item {\n      padding: 5px;\n      border-bottom: 1px solid #eee;\n      margin: 0 5px;\n\n      &:last-child {\n        border-bottom: none;\n      }\n\n      &:hover {\n        background-color: #f1f1f1;\n      }\n    }\n  }\n}\n\n.add-option {\n  cursor: pointer;\n  // padding: 0 5px;\n  margin-left: 5px;\n  /* Optional space between the input and the add icon */\n  margin-top: 5px;\n  // background-color: green;\n  // height:auto;\n\n  .icon {\n    width: 25px;\n    height: 25px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
