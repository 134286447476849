import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchAllExistingSurveyNotifications,
  fetchSurveySettings
} from "../../../../models/survey.model";
import ErrorPage from "../../../../pages/Error/error.page";
import {
  ExistingSurveyNotifications,
  Language,
  SurveyDiary,
  // SurveyPill,
  SurveySingle
} from "../../../../types";
import DiarySurvey from "./SurveyDiary.component";
import SingleSurvey from "./surveySingle.component";

export type SurveySettingsProps = {
  studyClicked: string;
};

const SurveySettings: React.FC<SurveySettingsProps> = ({ studyClicked }) => {
  const { clientID, studyID, surveyID } = useParams(); // Get from URL
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [surveyData, setSurveyData] = useState<
    SurveyDiary | SurveySingle | null
  >(null); // Set initial state to null
  const [unfilteredNotificationData, setUnfilteredNotificationData] =
    useState<ExistingSurveyNotifications | null>(null); // Set initial state to null
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    console.log(studyClicked);
    console.log("clientID:", clientID);
    console.log("studyID:", studyID);
    console.log("surveyID:", surveyID);
    let surveyResult: SurveyDiary | SurveySingle | string;
    const fetchSurvey = async () => {
      try {
        surveyResult = await fetchSurveySettings(
          clientID as string,
          studyID as string,
          surveyID as string
        );
        // if jsonData is a string, then there was an error
        if (typeof surveyResult === "string") {
          console.log("jsonData is a string");
          console.log("surveyData: ", surveyResult);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "e33e31fbfd5aae8ad8764306c24f1630"
          ]);
        } else {
          if (surveyResult.survey_type === "diary") {
            setSurveyData(surveyResult as SurveyDiary);
            console.log("surveyData populated: ", surveyResult);
            fetchNotifications(surveyResult);
          } else if (surveyResult.survey_type === "single") {
            setSurveyData(surveyResult as SurveySingle);
            console.log("surveyData populated: ", surveyResult);
            fetchNotifications(surveyResult);
          } else {
            console.error("Error with survey settings");
            throw new Error("Error with survey settings");
          }
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching survey settings data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "e413dd5ed48a7cb964834d86abe62fb1"
        ]);
      }
    };

    // fetch notifications
    const fetchNotifications = async (
      surveyResult: SurveyDiary | SurveySingle
    ) => {
      try {
        const allNotifications: ExistingSurveyNotifications | string =
          await fetchAllExistingSurveyNotifications();
        // if allNotifications is a string, then there was an error
        if (typeof allNotifications === "string") {
          console.log("jsonData is a string");
          console.log("jsonData", allNotifications);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "f9e2ad8061a0c0a8454aad392e60a5da"
          ]);
        } else {
          // Set unfiltered notifications to state
          setUnfilteredNotificationData(
            allNotifications as ExistingSurveyNotifications
          );
          console.log("surveyResult: ", surveyResult);
          console.log("unfilteredNotificationData", unfilteredNotificationData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching survey notification settings data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "64ca5f5819030e6c70845cc6e6b867fd"
        ]);
      }
    };

    Promise.all([fetchSurvey()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "127688bee263552224b659e5f2f47ad2"
        ]);
      });
  }, [studyID, clientID, surveyID]);

  return loading ? (
    <div className="d-flex justify-content-center custom_spinner_container full_page">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : loadingError ? (
    <ErrorPage
      errorCode="404"
      error="Error loading survey"
      debugCode={loadingErrorText}
    />
  ) : !surveyData ? ( //check if survey is valid
    <ErrorPage
      errorCode="404"
      error="Survey not found"
      debugCode={loadingErrorText}
    />
  ) : surveyData.survey_type === "diary" ? (
    <DiarySurvey
      surveyData={surveyData as SurveyDiary}
      unfilteredNotificationData={
        unfilteredNotificationData as ExistingSurveyNotifications
      }
    />
  ) : surveyData.survey_type === "single" ? (
    <SingleSurvey
      surveyData={surveyData as SurveySingle}
      unfilteredNotificationData={
        unfilteredNotificationData as ExistingSurveyNotifications
      }
    />
  ) : (
    <ErrorPage
      errorCode="404"
      error="Survey not found"
      debugCode={loadingErrorText}
    />
  );
};

export default SurveySettings;
