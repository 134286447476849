import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { resetParticipantPassword } from "../../models/participant.model";
import { isTokenExpired } from "../../utilities/tokenHandler.util";
import "./participantResetPassword.scss";

type ParticipantResetPasswordProps = {
  token: string;
};

const ParticipantResetPassword: React.FC<ParticipantResetPasswordProps> = ({
  token
}) => {
  const [validToken, setValidToken] = useState<boolean | "loading">("loading");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  //   const [formError, setFormError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const minPasswordLength = 6;
  const maxPasswordLength = 100;
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isTokenExpired(token)) {
      setValidToken(false);
    } else {
      setValidToken(true);
    }
  }, [token]);

  async function validateForm(): Promise<boolean> {
    let isValid = true;
    if (password.length === 0) {
      setPasswordError("Password cannot be empty");
      setConfirmPasswordError(null);
      return false;
    }
    if (password.length < minPasswordLength) {
      setPasswordError(
        `Password must be at least ${minPasswordLength} characters long`
      );
      isValid = false;
    } else if (password.length > maxPasswordLength) {
      setPasswordError(
        `Password must be at most ${maxPasswordLength} characters long`
      );
      isValid = false;
    } else {
      setPasswordError(null);
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError("Confirm Password cannot be empty");
      return false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    } else {
      setConfirmPasswordError(null);
    }

    return isValid;
  }

  async function handleSubmit() {
    const isValid = await validateForm();
    if (!isValid) {
      return;
    }
    // Call API to reset password
    console.log("reset password");
    Swal.fire({
      title: "Resetting Password",
      html: "Please wait while we reset your password.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const apiReponse = await resetParticipantPassword(token, password);
    if (apiReponse.rStatus === "error") {
      console.log(apiReponse.rStatus);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: apiReponse.rMessage,
        confirmButtonColor: "#1a2345"
      });
      return false;
    }
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Password reset successfully",
      confirmButtonColor: "#1a2345"
    });
    setSubmitMessage(
      "Password reset successfully. You can now login into the app."
    );
  }

  return (
    <div className="container">
      {submitMessage ? (
        <div className="alert alert-success mt-2">{submitMessage}</div>
      ) : validToken === "loading" ? (
        <div className="d-flex justify-content-center custom_spinner_container full_page">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : !validToken ? (
        <h3 className="text-danger">
          The link has expired or is incorrect. Please check your email/SMS and
          try again
        </h3>
      ) : (
        <div className="formParticipantResetPassword card col-12 col-lg-5 m-auto mt-3 p-3">
          <h3>Reset Password</h3>
          <p>Fill in the fields below to reset your password</p>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="text-danger">{passwordError}</span>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span className="text-danger">{confirmPasswordError}</span>
          </div>
          <button
            className="btn btn-primary mt-3"
            onClick={() => handleSubmit()}
          >
            Reset Password
          </button>
        </div>
      )}
    </div>
  );
};

export default ParticipantResetPassword;
