import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  MessagesDetails,
  MessagesParticipants,
  MessagesPhrases,
  MessagesTags,
  fetchAllSendMessages
} from "../../../../models/messages.model";
import { Country, Language, ParamsSurveyPage } from "../../../../types";
import {
  getSystemConfigCountries,
  getSystemConfigLanguages
} from "../../../../utilities/config.util";
import LoadingPencil from "../../../Loaders/LoadingPencil";
import ParticipantList from "./Listing/participantList.component";
import RecipientList from "./Listing/recipientList.component";
import Messenger from "./Messenger/messenger.component";
import "./sendMessages.scss";

interface sendMessagesProps {
  studyClicked: string;
}

const SendMessages: React.FC<sendMessagesProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const { clientID, studyID } = useParams<ParamsSurveyPage>();
  //
  const [allData, setAllData] = useState<MessagesDetails>();
  const [participants, setParticipants] = useState<MessagesParticipants[]>();
  const [recipients, setRecipients] = useState<MessagesParticipants[]>();
  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [tags, setTags] = useState<MessagesTags[]>();
  const [phrases, setPhrases] = useState<MessagesPhrases[]>();
  const [attachments, setAttachments] = useState<any>([]);
  const [resources, setResources] = useState<any>([]);
  /*----------------------------------------------------*/
  /*------------------Loading data----------------------*/
  //getting the participants for the list
  const loadData = async () => {
    if (clientID && studyID) {
      try {
        setIsLoading(true);

        const data = await fetchAllSendMessages(clientID, studyID);
        if (typeof data === "string") {
          setError(data);
          clearData();
        } else {
          setData(data);
          console.log(data);
        }
        setIsLoading(false);
      } catch (error) {
        setError("An error occurred while fetching data.");
        console.error(error);
        clearData();
      }
    } else {
      setIsLoading(false);
      clearData();
      setError("Something went wrong no client or study ID found");
    }
  };
  //getting languages ,country which is saved in localStorage under systemConfig
  const getLocalConfigLanguages = async () => {
    try {
      const jsonData: Language[] | false = await getSystemConfigLanguages();
      // console.log("jsonData:", jsonData);
      if (jsonData === false) {
        setError("Error getting languages");
        setLanguages([]);
      } else {
        try {
          // console.log("jsonData:", jsonData);
          setLanguages(jsonData);
        } catch {
          console.error("Error setting languages");
          throw new Error("Error setting languages");
        }
      }
    } catch {
      console.error("Error getting languages");
      throw new Error("Error getting languages");
    }
  };
  const getLocalConfigCountries = async () => {
    try {
      const jsonData: Country[] | false = await getSystemConfigCountries();
      if (jsonData === false) {
        setError("Error getting countries");
        setCountries([]);
      } else {
        try {
          setCountries(jsonData);
        } catch {
          console.error("Error setting countries");
          throw new Error("Error setting countries");
        }
      }
    } catch {
      console.error("Error getting countries");
      throw new Error("Error getting countries");
    }
  };

  //useEffect to call the load data function
  useEffect(() => {
    loadData();
    getLocalConfigLanguages();
    getLocalConfigCountries();
  }, []);
  //Setter functions
  const clearData = () => {
    setAllData(undefined);
    setParticipants(undefined);
    setTags(undefined);
    setPhrases(undefined);
  };
  const setData = (data: MessagesDetails) => {
    setAllData(data);
    setParticipants(data.participants);
    setTags(data.tags);
    setPhrases(data.phrases);
  };
  /*----------------------------------------------------*/
  /*--------------------Function------------------------*/

  //--Loading
  if (isLoading)
    return <LoadingPencil title={"messages"} isVisible={isLoading} />;
  //--Error
  if (error) return <div>{error}</div>;
  //--Success
  return (
    <div className="send-message-container">
      <div className="participant-container">
        <ParticipantList
          participants={participants}
          recipients={recipients}
          setRecipients={setRecipients}
          tags={tags}
          languages={languages}
          countries={countries}
        />
      </div>
      <div className="recipient-container">
        <RecipientList
          recipients={recipients}
          setRecipients={setRecipients}
          tags={tags}
          languages={languages}
          countries={countries}
        />
      </div>
      <div className="message-container">
        <Messenger
          phrases={phrases}
          recipients={recipients}
          attachments={attachments}
          resources={resources}
        />
      </div>
    </div>
  );
};
export default SendMessages;
