import React from "react";
import { useParams } from "react-router-dom";
import DisplayParticipants from "../../../components/DisplayParticipants/displayParticipants.component";
import "../../../components/Tables/tableComponents.scss"; // shared styles for tables
import SidebarComponent from "../../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../../types";
import "./participants.scss";

interface ParticipantsPageProps extends SidebarProps {}

const ParticipantsPage: React.FC<ParticipantsPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  currentClientLogo,
  currentClientName
}) => {
  const { clientID } = useParams();

  return (
    <div className="main_container">
      <div className="container-fluid p-3">
        <DisplayParticipants
          tableType="ClientParticipants"
          tableDisplayName={
            currentClientName !== undefined && currentClientName !== null
              ? currentClientName
              : "Participants"
          }
          clientID={clientID !== undefined && clientID !== null ? clientID : ""}
        />
      </div>
      <SidebarComponent
        page="participants"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={clientID}
        currentClientLogo={currentClientLogo}
      />
    </div>
  );
};

export default ParticipantsPage;
