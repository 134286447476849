import React from "react";
import "./paginationNavigation.scss";

interface PaginationNavigationProps {
  currentPage: number;
  totalResults: number;
  resultsPerPage: number;
  setCurrentPage: (page: number) => void;
}

const PaginationNavigation: React.FC<PaginationNavigationProps> = ({
  currentPage,
  totalResults,
  resultsPerPage,
  setCurrentPage
}) => {
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const handleClick = (event: any) => {
    setCurrentPage(Number(event.target.id));
  };
  return (
    <div className="pagination_navigation">
      {/* To navigate to more table rows, limit to 10 pages displayed at a time, with first and last buttons */}
      {currentPage !== 1 && (
        <>
          <button
            // first page button
            onClick={handleClick}
            id={`${1}`}
            className={`button btn btn_page ${
              currentPage === 1 ? "disabled" : ""
            }`}
            disabled={currentPage === 1}
          >
            First
          </button>
          <button
            // prev page button
            onClick={handleClick}
            id={`${currentPage - 1}`}
            className={`button btn btn_page ${
              currentPage === 1 ? "disabled" : ""
            }`}
            disabled={currentPage === 1}
          >
            Prev
          </button>
        </>
      )}

      {Array(totalPages)
        .fill(0)
        .map((_, i) => {
          const displayPages = 4 - 1; // display 4 pages at a time
          if (
            i + 1 >= currentPage - displayPages &&
            i + 1 <= currentPage + displayPages
          ) {
            return (
              <button
                onClick={handleClick}
                key={`page-button-${i}`}
                id={`${i + 1}`}
                className={`button btn btn_page ${
                  currentPage === i + 1 ? "active" : ""
                }`}
              >
                {i + 1}
              </button>
            );
          } else {
            return null;
          }
        })}
      {/* last page */}
      {currentPage !== totalPages && (
        <>
          <button
            // next page button
            onClick={handleClick}
            id={`${currentPage + 1}`}
            className={`button btn btn_page ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
          <button
            // last page button
            onClick={handleClick}
            id={`${totalPages}`}
            className={`button btn btn_page ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            disabled={currentPage === totalPages}
          >
            Last
          </button>
        </>
      )}
    </div>
  );
};

export default PaginationNavigation;
