// Form to add study to the database

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fetchAllExistingStudyNotifications,
  fetchAllWSCSurveys,
  sendStudyData
} from "../../../models/study.model.ts";
import ErrorPage from "../../../pages/Error/error.page.tsx";
import {
  ExistingStudyNotification,
  ExistingStudyNotifications,
  FieldDropdown,
  Language
} from "../../../types";
import { getSystemConfigLanguages } from "../../../utilities/config.util";
import { resizeImage } from "../../../utilities/utils.ts";
import StepAddStudyInformation from "./Steps/stepAddStudyInformation.component";
import StepDiaryConfiguration from "./Steps/stepDiaryConfiguration.component";
import StepDiaryNotifications from "./Steps/stepDiaryNotifications.component";
import StepSingleConfiguration from "./Steps/stepSingleConfiguration.component";
import StepSingleNotifications from "./Steps/stepSingleNotifications.component";
import "./addStudyWizard.scss";
// kola image
import { IconLogout } from "../../../assets/images/icons/icons.ts";
import kolaImage from "../../../assets/images/kola_wizard.webp";

// This document will include all the fields required for the Wizard that will create a new study

// Page 1: Study Information
// Study Name: (text field)
// Study Description: (text field)
// Study Information: (text field)
// Study Image: (file upload)
// Study Status: (dropdown: Active, Closed)
// Study Languages: (dropdown: list of languages)
// Study Start Date: (date picker)
// Study Auto Start : (checkbox) //This will move all Participants in the Pending Bucket to Day 1
// Study Deadline: (date picker)
// Study Budget: (text field)
// Diary Included: (checkbox)
// Diary Name: (text field)
// Diary Description: (text field)
// Diary App Display Name: (text field)
// Diary Image: (file upload)
// Single Included: (checkbox)
// Single Name: (text field)
// Single Description: (text field)
// Single App Display Name: (text field)
// Single Image: (file upload)
// Resources Included: (checkbox)
// Resource Name: (text field)
// Resource Description: (text field)
// Resource File: (file upload), includes File Name
// Resource Thumbnail: (file upload)
// Resource Languages: (dropdown: list of languages)

// Page 2: Diary Configuration
// Active Days: (number field)
// Grace Days: (number field)
// Bonus Days: (number field)
// Minimum Daily Completes: (number field)
// Maximum Daily Completes: (number field)
// Survey System: (dropdown: WSC, NField, Custom)
// WSC Survey: (dropdown: list of WSC surveys)
// NField Survey: (text field)
// Custom Survey: (text field)

// Page 3: Diary Notifications
// Welcome Notification: (dropdown: list of notifications)
// Daily Reminder Notification: (dropdown: list of notifications)
// Daily Reminder Time: (time picker)
// Non-Compliance Notification: (dropdown: list of notifications)
// Non-Compliance Time: (time picker)

// Page 4: Single Configuration First
// Survey System: (dropdown: WSC, NField, Custom)
// WSC Survey: (dropdown: list of WSC surveys)
// NField Survey: (text field)
// Custom Survey: (text field)

// Page 5: Single Notifications First
// Welcome Notification: (dropdown: list of notifications)
// Non-Compliance Notification: (dropdown: list of notifications)

export type AddStudyWizardProps = {
  currentPage: number;
  totalPages: number;
  handleWizardBack: () => void;
  handleWizardForward: () => void;
  handleWizardSubmit: () => void;
  handleInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  // inputError object with key value pairs
  inputErrors?: {
    [key: string]: string | null;
  };
  pageValid: boolean;
};

export interface AddStudyWizardStepInformationProps
  extends AddStudyWizardProps {
  studyStatus: boolean;
  studyName: string | null;
  studyDescription: string | null;
  studyInformation: string | null;
  studyIcon: string | null;
  // studyStartDate: string | null;
  // studyAutoStart: boolean | null;
  // studyDeadline: string | null;
  // studyBudget: string | null;
  diaryIncluded: boolean | null;
  diaryName: string | null;
  diaryDescription: string | null;
  // diaryAppDisplayName: string | null;
  diaryIcon: string | null;
  diaryStartDate: string | null;
  diaryAutoStart: boolean;
  // Resources
  resourceIncluded: boolean | null;
  resourceIncludedSecond: boolean | null;

  resourceName: string | null;
  resourceDescription: string | null;
  resourceFileName: string | null;
  resourceFile: string | null;
  resourceThumbnail: string | null;
  resourceLanguages: string[];

  resourceNameSecond: string | null;
  resourceDescriptionSecond: string | null;
  resourceFileNameSecond: string | null;
  resourceFileSecond: string | null;
  resourceThumbnailSecond: string | null;
  resourceLanguagesSecond: string[];

  singleIncludedFirst: boolean | null;
  singleIncludedSecond: boolean | null;
  singleNameFirst: string | null;
  singleDescriptionFirst: string | null;
  // singleAppDisplayNameFirst: string | null;
  singleIconFirst: string | null;
  singleNameSecond: string | null;
  singleDescriptionSecond: string | null;
  // singleAppDisplayNameSecond: string | null;
  singleIconSecond: string | null;
  singleStartDateFirst: string | null;
  singleAutoStartFirst: boolean;
  singleStartDateSecond: string | null;
  singleAutoStartSecond: boolean;
  selectedLanguages: string[];
  allLanguages: Language[];
}
export interface AddStudyWizardStepDiaryConfigurationProps
  extends AddStudyWizardProps {
  diaryActiveDays: number;
  diaryGraceDays: number;
  diaryBonusDays: number;
  diaryMinimumDailyCompletes: number;
  diaryMaximumDailyCompletes: number;
  diarySurveySystem: string | null;
  diarySurveySystemDetails: string | null;
  wscSurveyOptionsData: FieldDropdown[];
  wscSurveysData: FieldDropdown[];
  diaryName: string | null;
}

export interface AddStudyWizardStepDiaryNotificationsProps
  extends AddStudyWizardProps {
  diaryIncludeNotifications: boolean;
  diaryWelcomeNotification: string;
  diaryDailyReminderNotification: string;
  diaryDailyReminderTime: string | null;
  diaryNonComplianceNotification: string;
  diaryNonComplianceTime: string | null;
  diaryWelcomeNotificationsData: ExistingStudyNotification[];
  diaryDailyReminderNotificationsData: ExistingStudyNotification[];
  diaryNonComplianceNotificationsData: ExistingStudyNotification[];
  diaryNewWelcomeNotification: string | null;
  diaryNewDailyReminderNotification: string | null;
  diaryNewNonComplianceNotification: string | null;
  diaryName: string | null;
}
export interface AddStudyWizardStepSingleConfigurationProps
  extends AddStudyWizardProps {
  singleSurveySystem: string;
  singleSurveySystemDetails: string;
  setSingleSurveySystemDetails: React.Dispatch<React.SetStateAction<string>>;
  wscSurveyOptionsData: FieldDropdown[];
  wscSurveysData: FieldDropdown[];
  // two singles can be created
  singleSurveyFirst: boolean;
  surveyName: string | null;
}

export interface AddStudyWizardStepSingleNotificationsProps
  extends AddStudyWizardProps {
  singleIncludeNotifications: boolean;
  singleWelcomeNotification: string;
  singleNonComplianceNotification: string;
  // singleDailyReminderNotification: string;
  singleWelcomeNotificationsData: ExistingStudyNotification[];
  singleNonComplianceNotificationsData: ExistingStudyNotification[];
  // singleDailyReminderNotificationsData: ExistingStudyNotification[];
  singleNewWelcomeNotification: string | null;
  singleNewNonComplianceNotification: string | null;
  singleNonComplianceTime: string | null;
  // singleNewDailyReminderNotification: string | null;
  singleNonComplianceNotificationStartDelay: number;
  singleNonComplianceNotificationInBetweenDelay: number;
  // two singles can be created
  singleSurveyFirst: boolean;
  surveyName: string | null;
}

const AddStudyWizard = () => {
  const { clientID } = useParams(); // Get from URL
  const navigate = useNavigate();
  // const totalPages = 1;
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const allPagesNames: string[] = [
    "Study Information",
    "Diary Configuration",
    "Diary Notifications",
    "Single Configuration First",
    "Single Notifications First"
  ];

  const imageSizes: number = 300;

  const [pagesToDisplay, setPagesToDisplay] = useState<string[]>([
    allPagesNames[0]
  ]);
  const [currentPageName, setCurrentPageName] = useState<string>(
    pagesToDisplay[0]
  );
  // set the current page to the index of the allPagesNames array
  useEffect(() => {
    setCurrentPage(pagesToDisplay.indexOf(currentPageName));
  }, [currentPageName, pagesToDisplay]);

  useEffect(() => {
    setTotalPages(pagesToDisplay.length);
  }, [pagesToDisplay]);

  const [allLanguages, setAllLanguages] = useState<
    AddStudyWizardStepInformationProps["allLanguages"]
  >([]);
  // selectedLanguages is an array of string iso codes
  const [selectedLanguages, setSelectedLanguages] = useState<
    AddStudyWizardStepInformationProps["selectedLanguages"]
  >(["EN"]);

  // Study Status - boolean (required)
  const [studyStatus, setStudyStatus] =
    useState<AddStudyWizardStepInformationProps["studyStatus"]>(true);

  const [studyInformation, setStudyInformation] =
    useState<AddStudyWizardStepInformationProps["studyInformation"]>(null);

  //   Study Name - box with translations and inputs per language (required). Will be an object with keys as language codes and values as strings
  const [studyName, setStudyName] =
    useState<AddStudyWizardStepInformationProps["studyName"]>(null);

  //   Study Description - box with translations and text boxes per language (required). Will be an object with keys as language codes and values as strings
  const [studyDescription, setStudyDescription] =
    useState<AddStudyWizardStepInformationProps["studyDescription"]>(null);

  const [studyIcon, setStudyIcon] =
    useState<AddStudyWizardStepInformationProps["studyIcon"]>(null);

  // const [studyDeadline, setStudyDeadline] =
  //   useState<AddStudyWizardStepInformationProps["studyDeadline"]>(null);

  // const [studyBudget, setStudyBudget] =
  //   useState<AddStudyWizardStepInformationProps["studyBudget"]>(null);

  const [diaryIncluded, setDiaryIncluded] =
    useState<AddStudyWizardStepInformationProps["diaryIncluded"]>(false);

  const [diaryName, setDiaryName] =
    useState<AddStudyWizardStepInformationProps["diaryName"]>(null);

  const [diaryDescription, setDiaryDescription] =
    useState<AddStudyWizardStepInformationProps["diaryDescription"]>(null);

  // const [diaryAppDisplayName, setDiaryAppDisplayName] =
  //   useState<AddStudyWizardStepInformationProps["diaryAppDisplayName"]>(null);

  const [diaryIcon, setDiaryIcon] =
    useState<AddStudyWizardStepInformationProps["diaryIcon"]>(null);

  const [diaryStartDate, setDiaryStartDate] =
    useState<AddStudyWizardStepInformationProps["diaryStartDate"]>(null);

  const [diaryAutoStart, setDiaryAutoStart] =
    useState<AddStudyWizardStepInformationProps["diaryAutoStart"]>(false);

  // Resources
  const [resourceIncluded, setResourceIncluded] =
    useState<AddStudyWizardStepInformationProps["resourceIncluded"]>(false);

  const [resourceName, setResourceName] =
    useState<AddStudyWizardStepInformationProps["resourceName"]>(null);

  const [resourceDescription, setResourceDescription] =
    useState<AddStudyWizardStepInformationProps["resourceDescription"]>(null);

  const [resourceFileName, setResourceFileName] =
    useState<AddStudyWizardStepInformationProps["resourceFileName"]>(null);

  const [resourceFile, setResourceFile] =
    useState<AddStudyWizardStepInformationProps["resourceFile"]>(null);

  const [resourceThumbnail, setResourceThumbnail] =
    useState<AddStudyWizardStepInformationProps["resourceThumbnail"]>(null);

  const [resourceLanguages, setResourceLanguages] = useState<
    AddStudyWizardStepInformationProps["resourceLanguages"]
  >(["EN"]);

  // 2nd Resource

  const [resourceIncludedSecond, setResourceIncludedSecond] =
    useState<AddStudyWizardStepInformationProps["resourceIncludedSecond"]>(
      false
    );

  const [resourceNameSecond, setResourceNameSecond] =
    useState<AddStudyWizardStepInformationProps["resourceNameSecond"]>(null);

  const [resourceDescriptionSecond, setResourceDescriptionSecond] =
    useState<AddStudyWizardStepInformationProps["resourceDescriptionSecond"]>(
      null
    );

  const [resourceFileNameSecond, setResourceFileNameSecond] =
    useState<AddStudyWizardStepInformationProps["resourceFileNameSecond"]>(
      null
    );

  const [resourceFileSecond, setResourceFileSecond] =
    useState<AddStudyWizardStepInformationProps["resourceFileSecond"]>(null);

  const [resourceThumbnailSecond, setResourceThumbnailSecond] =
    useState<AddStudyWizardStepInformationProps["resourceThumbnailSecond"]>(
      null
    );

  const [resourceLanguagesSecond, setResourceLanguagesSecond] = useState<
    AddStudyWizardStepInformationProps["resourceLanguagesSecond"]
  >(["EN"]);

  // End Resources

  const [singleIncludedFirst, setSingleIncludedFirst] =
    useState<AddStudyWizardStepInformationProps["singleIncludedFirst"]>(false);

  const [singleNameFirst, setSingleNameFirst] =
    useState<AddStudyWizardStepInformationProps["singleNameFirst"]>(null);

  const [singleDescriptionFirst, setSingleDescriptionFirst] =
    useState<AddStudyWizardStepInformationProps["singleDescriptionFirst"]>(
      null
    );

  // const [singleAppDisplayNameFirst, setSingleAppDisplayNameFirst] =
  //   useState<AddStudyWizardStepInformationProps["singleAppDisplayNameFirst"]>(
  //     null
  //   );

  const [singleIconFirst, setSingleIconFirst] =
    useState<AddStudyWizardStepInformationProps["singleIconFirst"]>(null);

  const [singleNameSecond, setSingleNameSecond] =
    useState<AddStudyWizardStepInformationProps["singleNameSecond"]>(null);

  const [singleDescriptionSecond, setSingleDescriptionSecond] =
    useState<AddStudyWizardStepInformationProps["singleDescriptionSecond"]>(
      null
    );

  // const [singleAppDisplayNameSecond, setSingleAppDisplayNameSecond] =
  //   useState<AddStudyWizardStepInformationProps["singleAppDisplayNameSecond"]>(
  //     null
  //   );

  const [singleIconSecond, setSingleIconSecond] =
    useState<AddStudyWizardStepInformationProps["singleIconSecond"]>(null);

  const [singleIncludedSecond, setSingleIncludedSecond] =
    useState<AddStudyWizardStepInformationProps["singleIncludedSecond"]>(false);

  const [singleStartDateFirst, setSingleStartDateFirst] =
    useState<AddStudyWizardStepInformationProps["singleStartDateFirst"]>(null);

  const [singleAutoStartFirst, setSingleAutoStartFirst] =
    useState<AddStudyWizardStepInformationProps["singleAutoStartFirst"]>(false);

  const [singleStartDateSecond, setSingleStartDateSecond] =
    useState<AddStudyWizardStepInformationProps["singleStartDateFirst"]>(null);

  const [singleAutoStartSecond, setSingleAutoStartSecond] =
    useState<AddStudyWizardStepInformationProps["singleAutoStartFirst"]>(false);

  // Page 2 - Diary Configuration
  const [diaryActiveDays, setDiaryActiveDays] =
    useState<AddStudyWizardStepDiaryConfigurationProps["diaryActiveDays"]>(1);

  const [diaryGraceDays, setDiaryGraceDays] =
    useState<AddStudyWizardStepDiaryConfigurationProps["diaryGraceDays"]>(0);

  const [diaryBonusDays, setDiaryBonusDays] =
    useState<AddStudyWizardStepDiaryConfigurationProps["diaryBonusDays"]>(0);

  const [diaryMinimumDailyCompletes, setDiaryMinimumDailyCompletes] =
    useState<
      AddStudyWizardStepDiaryConfigurationProps["diaryMinimumDailyCompletes"]
    >(1);

  const [diaryMaximumDailyCompletes, setDiaryMaximumDailyCompletes] =
    useState<
      AddStudyWizardStepDiaryConfigurationProps["diaryMaximumDailyCompletes"]
    >(1);

  const [diarySurveySystem, setDiarySurveySystem] =
    useState<AddStudyWizardStepDiaryConfigurationProps["diarySurveySystem"]>(
      "wsc"
    );

  const [diarySurveySystemDetails, setDiarySurveySystemDetails] =
    useState<
      AddStudyWizardStepDiaryConfigurationProps["diarySurveySystemDetails"]
    >("wsc");

  // Page 3: Diary Notifications
  const [diaryIncludeNotifications, setDiaryIncludeNotifications] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryIncludeNotifications"]
    >(false);

  const [diaryWelcomeNotification, setDiaryWelcomeNotification] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryWelcomeNotification"]
    >("");

  const [diaryDailyReminderNotification, setDiaryDailyReminderNotification] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryDailyReminderNotification"]
    >("");

  const [diaryDailyReminderTime, setDiaryDailyReminderTime] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryDailyReminderTime"]
    >(null);

  const [diaryNonComplianceNotification, setDiaryNonComplianceNotification] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryNonComplianceNotification"]
    >("");

  const [diaryNonComplianceTime, setDiaryNonComplianceTime] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryNonComplianceTime"]
    >(null);

  const [diaryNewWelcomeNotification, setDiaryNewWelcomeNotification] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryNewWelcomeNotification"]
    >(null);

  const [
    diaryNewDailyReminderNotification,
    setDiaryNewDailyReminderNotification
  ] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryNewDailyReminderNotification"]
    >(null);

  const [
    diaryNewNonComplianceNotification,
    setDiaryNewNonComplianceNotification
  ] =
    useState<
      AddStudyWizardStepDiaryNotificationsProps["diaryNewNonComplianceNotification"]
    >(null);

  const [singleFirstIncludeNotifications, setSingleFirstIncludeNotifications] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleIncludeNotifications"]
    >(false);

  const [
    singleFirstNewWelcomeNotification,
    setSingleFirstNewWelcomeNotification
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNewWelcomeNotification"]
    >(null);

  // const [
  //   singleFirstNewDailyReminderNotification,
  //   setSingleFirstNewDailyReminderNotification,
  // ] =
  //   useState<
  //     AddStudyWizardStepSingleNotificationsProps["singleNewDailyReminderNotification"]
  //   >(null);

  const [
    singleFirstNewNonComplianceNotification,
    setSingleFirstNewNonComplianceNotification
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNewNonComplianceNotification"]
    >(null);

  // Page 4: Single Configuration First
  const [singleSurveySystemFirst, setSingleSurveySystemFirst] =
    useState<AddStudyWizardStepSingleConfigurationProps["singleSurveySystem"]>(
      "wsc"
    );

  const [singleSurveySystemDetailsFirst, setSingleSurveySystemDetailsFirst] =
    useState<
      AddStudyWizardStepSingleConfigurationProps["singleSurveySystemDetails"]
    >("");

  const [singleSurveySystemSecond, setSingleSurveySystemSecond] =
    useState<AddStudyWizardStepSingleConfigurationProps["singleSurveySystem"]>(
      "wsc"
    );

  const [singleSurveySystemDetailsSecond, setSingleSurveySystemDetailsSecond] =
    useState<
      AddStudyWizardStepSingleConfigurationProps["singleSurveySystemDetails"]
    >("");

  // Page 5: Single Notifications First
  const [singleFirstWelcomeNotification, setSingleFirstWelcomeNotification] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleWelcomeNotification"]
    >("");

  const [singleFirstNonComplianceTime, setSingleFirstNonComplianceTime] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceTime"]
    >(null);

  const [
    singleFirstNonComplianceNotification,
    setSingleFirstNonComplianceNotification
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceNotification"]
    >("");

  // const [
  //   singleFirstDailyReminderNotification,
  //   setSingleFirstDailyReminderNotification,
  // ] =
  //   useState<
  //     AddStudyWizardStepSingleNotificationsProps["singleDailyReminderNotification"]
  //   >("");

  const [
    singleSecondIncludeNotifications,
    setSingleSecondIncludeNotifications
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleIncludeNotifications"]
    >(false);

  const [
    singleFirstNonComplianceNotificationStartDelay,
    setSingleFirstNonComplianceNotificationStartDelay
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceNotificationStartDelay"]
    >(0);

  const [
    singleFirstNonComplianceNotificationInBetweenDelay,
    setSingleFirstNonComplianceNotificationInBetweenDelay
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceNotificationInBetweenDelay"]
    >(0);

  const [singleSecondWelcomeNotification, setSingleSecondWelcomeNotification] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleWelcomeNotification"]
    >("");

  const [singleSecondNonComplianceTime, setSingleSecondNonComplianceTime] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceTime"]
    >(null);

  const [
    singleSecondNonComplianceNotification,
    setSingleSecondNonComplianceNotification
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceNotification"]
    >("");

  // const [
  //   singleSecondDailyReminderNotification,
  //   setSingleSecondDailyReminderNotification,
  // ] =
  //   useState<
  //     AddStudyWizardStepSingleNotificationsProps["singleDailyReminderNotification"]
  //   >("");

  const [
    singleSecondNewWelcomeNotification,
    setSingleSecondNewWelcomeNotification
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNewWelcomeNotification"]
    >(null);

  // const [
  //   singleSecondNewDailyReminderNotification,
  //   setSingleSecondNewDailyReminderNotification,
  // ] =
  //   useState<
  //     AddStudyWizardStepSingleNotificationsProps["singleNewDailyReminderNotification"]
  //   >(null);

  const [
    singleSecondNewNonComplianceNotification,
    setSingleSecondNewNonComplianceNotification
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNewNonComplianceNotification"]
    >(null);
  const [
    singleSecondNonComplianceNotificationStartDelay,
    setSingleSecondNonComplianceNotificationStartDelay
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceNotificationStartDelay"]
    >(0);

  const [
    singleSecondNonComplianceNotificationInBetweenDelay,
    setSingleSecondNonComplianceNotificationInBetweenDelay
  ] =
    useState<
      AddStudyWizardStepSingleNotificationsProps["singleNonComplianceNotificationInBetweenDelay"]
    >(0);

  const [wizardSubmitting, setWizardSubmitting] = useState<boolean>(false);
  const [wizardSubmitError, setWizardSubmitError] = useState<boolean>(false);

  // errors
  const [inputErrors, setInputErrors] = useState<
    AddStudyWizardProps["inputErrors"]
  >({});

  const [pageValid, setPageValid] = useState<boolean>(false);

  const displayAutoStartWarning = () => {
    Swal.fire({
      title: "Please Note",
      html: `This will create a tag called "Auto-Start" and an active automation that will move the participants at the designated date indicated below. <br><br>Please tag the participants with the "Auto-Start" tag, and activate the automation when needed. `,
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      width: "40rem"
    });
  };

  const handleInputChange = (inputName: string, e: React.ChangeEvent<any>) => {
    // console.log("e", e);
    // console.log("inputName", inputName);
    // console.log("e.target.value", e.target.value);
    if (inputName === "studyStatus") {
      if (e.target.value === "active") {
        setStudyStatus(true);
      } else {
        setStudyStatus(false);
      }
    } else if (inputName === "studyName") {
      let formattedValue = e.target.value;
      //special characters
      formattedValue = formattedValue.replace(
        /[<>!@#$%^&*()_+[\]{}|\\:;"'<>,.?/~`]/g,
        ""
      );
      setStudyName(formattedValue);
    } else if (inputName === "studyDescription") {
      setStudyDescription(e.target.value);
    } else if (inputName === "studyInformation") {
      setStudyInformation(e.target.value);
    } else if (inputName === "studyLanguages") {
      // check if checkbox is checked or unchecked, add or remove from selectedLanguages
      if (e.target.checked) {
        setSelectedLanguages([...selectedLanguages, e.target.value]);
      }
      // remove from selectedLanguages
      else {
        setSelectedLanguages(
          selectedLanguages.filter((language) => language !== e.target.value)
        );
      }
    } else if (inputName === "studyIcon") {
      const file = e.target.files?.[0];
      if (file) {
        resizeImage(file, imageSizes).then((resizedImage) => {
          if (typeof resizedImage === "string") {
            setStudyIcon(resizedImage);
          } else {
            setStudyIcon("Invalid");
          }
        });
      }
    } else if (inputName === "studyInformation") {
      setStudyInformation(e.target.value);
      // } else if (inputName === "studyStartDate") {
      //   setStudyStartDate(e.target.value);
      // } else if (inputName === "studyAutoStart") {
      //   setStudyAutoStart(e.target.checked);
    }
    //  else if (inputName === "studyDeadline") {
    //   setStudyDeadline(e.target.value);
    // } else if (inputName === "studyBudget") {
    //   // only allow numbers
    //   const regex = /^[0-9\b]+$/;
    //   if (e.target.value === "" || regex.test(e.target.value)) {
    //     setStudyBudget(e.target.value);
    //   }
    // }
    // Diary
    else if (inputName === "diaryIncluded") {
      setDiaryIncluded(e.target.checked);
      if (e.target.checked === true) {
        setPagesToDisplay([
          ...pagesToDisplay,
          "Diary Configuration",
          "Diary Notifications"
        ]);
      } else {
        setPagesToDisplay([
          ...pagesToDisplay.filter(
            (page) =>
              page !== "Diary Configuration" && page !== "Diary Notifications"
          )
        ]);
      }
    }
    // diaryName
    else if (inputName === "diaryName") {
      setDiaryName(e.target.value);
    }
    // diaryDescription
    else if (inputName === "diaryDescription") {
      setDiaryDescription(e.target.value);
    }
    // diaryAppDisplayName
    // else if (inputName === "diaryAppDisplayName") {
    //   setDiaryAppDisplayName(e.target.value);
    // }
    // diaryIcon
    else if (inputName === "diaryIcon") {
      const file = e.target.files?.[0];
      if (file) {
        resizeImage(file, imageSizes).then((resizedImage) => {
          if (typeof resizedImage === "string") {
            setDiaryIcon(resizedImage);
          } else {
            setDiaryIcon("Invalid");
          }
        });
      }
    } else if (inputName === "diaryStartDate") {
      setDiaryStartDate(e.target.value);
    } else if (inputName === "diaryAutoStart") {
      setDiaryAutoStart(e.target.checked);
      if (e.target.checked === true) {
        displayAutoStartWarning();
      }
    } else if (inputName === "singleIncludedFirst") {
      setSingleIncludedFirst(e.target.checked);
      if (e.target.checked === true) {
        setPagesToDisplay([
          ...pagesToDisplay,
          "Single Configuration First",
          "Single Notifications First"
        ]);
      } else {
        setPagesToDisplay([
          ...pagesToDisplay.filter(
            (page) =>
              page !== "Single Configuration First" &&
              page !== "Single Notifications First" &&
              page !== "Single Configuration Second" &&
              page !== "Single Notifications Second"
          )
        ]);
        setSingleIncludedSecond(false);
      }
    } else if (inputName === "singleIncludedSecond") {
      setSingleIncludedSecond(e.target.checked);
      if (e.target.checked === true) {
        setPagesToDisplay([
          ...pagesToDisplay,
          "Single Configuration Second",
          "Single Notifications Second"
        ]);
      } else {
        setPagesToDisplay([
          ...pagesToDisplay.filter(
            (page) =>
              page !== "Single Configuration Second" &&
              page !== "Single Notifications Second"
          )
        ]);
      }
    }

    // singleNameFirst
    else if (inputName === "singleNameFirst") {
      setSingleNameFirst(e.target.value);
    }
    // singleDescriptionFirst
    else if (inputName === "singleDescriptionFirst") {
      setSingleDescriptionFirst(e.target.value);
    }
    // singleAppDisplayNameFirst
    // else if (inputName === "singleAppDisplayNameFirst") {
    //   setSingleAppDisplayNameFirst(e.target.value);
    // }
    // singleIconFirst
    else if (inputName === "singleIconFirst") {
      const file = e.target.files?.[0];
      if (file) {
        resizeImage(file, imageSizes).then((resizedImage) => {
          if (typeof resizedImage === "string") {
            setSingleIconFirst(resizedImage);
          } else {
            setSingleIconFirst("Invalid");
          }
        });
      }
    } else if (inputName === "singleStartDateFirst") {
      setSingleStartDateFirst(e.target.value);
    }
    // singleAutoStartFirst
    else if (inputName === "singleAutoStartFirst") {
      setSingleAutoStartFirst(e.target.checked);
      if (e.target.checked === true) {
        displayAutoStartWarning();
      }
    } else if (inputName === "singleNameSecond") {
      setSingleNameSecond(e.target.value);
    }
    // singleDescriptionSecond
    else if (inputName === "singleDescriptionSecond") {
      setSingleDescriptionSecond(e.target.value);
    }

    // Resources
    else if (inputName === "resourceIncluded") {
      setResourceIncluded(e.target.checked);
    } else if (inputName === "resourceName") {
      setResourceName(e.target.value);
    }
    // resourceDescription
    else if (inputName === "resourceDescription") {
      setResourceDescription(e.target.value);
    }
    // resourceFile + resourceFileName
    else if (inputName === "resourceFile") {
      const file = e.target.files?.[0];
      console.log(file);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = function () {
          const result = reader.result;
          if (typeof result === "string") {
            setResourceFile(result);
            setResourceFileName(file.name);
          }
        };
        reader.onerror = function () {
          Swal.fire({
            title: "Error",
            html: `An error occurred while reading the file <br> ${reader.error}`,
            icon: "error"
          });
        };
        reader.readAsDataURL(file);
      }
    }

    // resourceLanguages
    else if (inputName === "resourceLanguages") {
      console.log("resourceLanguages", e.target.value);
      // check if checkbox is checked or unchecked, add or remove from selectedLanguages
      if (e.target.checked) {
        setResourceLanguages([...resourceLanguages, e.target.value]);
      }
      // remove from selectedLanguages
      else {
        setResourceLanguages(
          resourceLanguages.filter((language) => language !== e.target.value)
        );
      }
    }
    // resourceThumbnail
    else if (inputName === "resourceThumbnail") {
      const file = e.target.files?.[0];
      if (file) {
        resizeImage(file, imageSizes).then((resizedImage) => {
          if (typeof resizedImage === "string") {
            console.log(resizedImage);
            setResourceThumbnail(resizedImage);
          } else {
            setResourceThumbnail("Invalid");
          }
        });
      }
    }

    // 2nd Resources
    else if (inputName === "resourceIncludedSecond") {
      setResourceIncludedSecond(e.target.checked);
    } else if (inputName === "resourceNameSecond") {
      setResourceNameSecond(e.target.value);
    }
    // 2nd resourceName
    else if (inputName === "resourceNameSecond") {
      setResourceNameSecond(e.target.value);
    }
    // 2nd resourceDescription
    else if (inputName === "resourceDescriptionSecond") {
      setResourceDescriptionSecond(e.target.value);
    }
    // 2nd resourceFile + resourceFileName
    else if (inputName === "resourceFileSecond") {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = function () {
          const result = reader.result;
          if (typeof result === "string") {
            setResourceFileSecond(result);
            setResourceFileNameSecond(file.name);
          }
        };
        reader.onerror = function () {
          Swal.fire({
            title: "Error",
            html: `An error occurred while reading the file <br> ${reader.error}`,
            icon: "error"
          });
        };
        reader.readAsDataURL(file);
      }
    }
    // 2nd resourceLanguages
    else if (inputName === "resourceLanguagesSecond") {
      // check if checkbox is checked or unchecked, add or remove from selectedLanguages
      if (e.target.checked) {
        setResourceLanguagesSecond([
          ...resourceLanguagesSecond,
          e.target.value
        ]);
      }
      // remove from selectedLanguages
      else {
        setResourceLanguagesSecond(
          resourceLanguagesSecond.filter(
            (language) => language !== e.target.value
          )
        );
      }
    }

    // 2nd resourceThumbnail
    else if (inputName === "resourceThumbnailSecond") {
      const file = e.target.files?.[0];
      if (file) {
        resizeImage(file, imageSizes).then((resizedImage) => {
          if (typeof resizedImage === "string") {
            setResourceThumbnailSecond(resizedImage);
          } else {
            setResourceThumbnailSecond("Invalid");
          }
        });
      }
    }

    // singleAppDisplayNameSecond
    // else if (inputName === "singleAppDisplayNameSecond") {
    //   setSingleAppDisplayNameSecond(e.target.value);
    // }
    // singleIconSecond
    else if (inputName === "singleIconSecond") {
      const file = e.target.files?.[0];
      if (file) {
        resizeImage(file, imageSizes).then((resizedImage) => {
          if (typeof resizedImage === "string") {
            setSingleIconSecond(resizedImage);
          } else {
            setSingleIconSecond("Invalid");
          }
        });
      }
    } else if (inputName === "singleStartDateSecond") {
      setSingleStartDateSecond(e.target.value);
    } else if (inputName === "singleAutoStartSecond") {
      setSingleAutoStartSecond(e.target.checked);
      if (e.target.checked === true) {
        displayAutoStartWarning();
      }
    } else if (inputName === "diaryActiveDays") {
      setDiaryActiveDays(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1
      );
    } else if (inputName === "diaryGraceDays") {
      setDiaryGraceDays(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
    } else if (inputName === "diaryBonusDays") {
      setDiaryBonusDays(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
    } else if (inputName === "diaryMinimumDailyCompletes") {
      setDiaryMinimumDailyCompletes(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1
      );
    } else if (inputName === "diaryMaximumDailyCompletes") {
      setDiaryMaximumDailyCompletes(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1
      );
    } else if (inputName === "diarySurveySystem") {
      setDiarySurveySystem(e.target.value);
      if (e.target.value === "wsc" && wscSurveyOptions && wscSurveyOptions[0]) {
        setDiarySurveySystemDetails(wscSurveyOptions[0].id);
      } else if (e.target.value === "nfield") {
        setDiarySurveySystemDetails("https://interviewing.nfieldmr.com/");
      } else if (e.target.value === "custom") {
        setDiarySurveySystemDetails("https://");
      } else {
        setDiarySurveySystemDetails("");
      }
    } else if (inputName === "diarySurveySystemDetails") {
      if (diarySurveySystem === "wsc") {
        setDiarySurveySystemDetails(e.target.value);
      } else {
        // remove spaces
        setDiarySurveySystemDetails(
          e.target.value.replace(/\s/g, "").toLowerCase()
        );
      }
    } else if (inputName === "diaryWelcomeNotification") {
      if (e.target.value === "new") {
        setDiaryNewWelcomeNotification(null);
      }
      setDiaryWelcomeNotification(e.target.value);
    } else if (inputName === "diaryDailyReminderNotification") {
      if (e.target.value === "new") {
        setDiaryNewDailyReminderNotification(null);
      }
      setDiaryDailyReminderNotification(e.target.value);
    } else if (inputName === "diaryDailyReminderTime") {
      setDiaryDailyReminderTime(e.target.value);
    } else if (inputName === "diaryNonComplianceNotification") {
      if (e.target.value === "new") {
        setDiaryNewNonComplianceNotification(null);
      }
      setDiaryNonComplianceNotification(e.target.value);
    } else if (inputName === "diaryNonComplianceTime") {
      setDiaryNonComplianceTime(e.target.value);
    } else if (inputName === "diaryNewWelcomeNotificationPhrase") {
      if (diaryWelcomeNotification === "new") {
        setDiaryNewWelcomeNotification(e.target.value);
      } else {
        setDiaryNewWelcomeNotification(null);
      }
    } else if (inputName === "diaryNewDailyReminderNotificationPhrase") {
      if (diaryDailyReminderNotification === "new") {
        setDiaryNewDailyReminderNotification(e.target.value);
      } else {
        setDiaryNewDailyReminderNotification(null);
      }
    } else if (inputName === "diaryNewNonComplianceNotificationPhrase") {
      if (diaryNonComplianceNotification === "new") {
        setDiaryNewNonComplianceNotification(e.target.value);
      } else {
        setDiaryNewNonComplianceNotification(null);
      }
    } else if (inputName === "singleSurveySystemFirst") {
      setSingleSurveySystemFirst(e.target.value);
      if (e.target.value === "wsc" && wscSurveyOptions && wscSurveyOptions[0]) {
        setSingleSurveySystemDetailsFirst(wscSurveyOptions[0].id);
      } else if (e.target.value === "nfield") {
        setSingleSurveySystemDetailsFirst("https://interviewing.nfieldmr.com/");
      } else if (e.target.value === "custom") {
        setSingleSurveySystemDetailsFirst("https://");
      } else if (e.target.value === "viewEQ") {
        setSingleSurveySystemDetailsFirst("");
      } else {
        setSingleSurveySystemDetailsFirst("");
      }
    } else if (inputName === "singleSurveySystemDetailsFirst") {
      if (singleSurveySystemFirst === "wsc") {
        setSingleSurveySystemDetailsFirst(e.target.value);
      } else {
        // remove spaces
        setSingleSurveySystemDetailsFirst(
          e.target.value.replace(/\s/g, "").toLowerCase()
        );
      }
    } else if (inputName === "singleSurveySystemSecond") {
      setSingleSurveySystemSecond(e.target.value);
      if (e.target.value === "wsc" && wscSurveyOptions && wscSurveyOptions[0]) {
        setSingleSurveySystemDetailsSecond(wscSurveyOptions[0].id);
      } else if (e.target.value === "nfield") {
        setSingleSurveySystemDetailsSecond(
          "https://interviewing.nfieldmr.com/"
        );
      } else if (e.target.value === "custom") {
        setSingleSurveySystemDetailsSecond("https://");
      } else if (e.target.value === "viewEQ") {
        setSingleSurveySystemDetailsSecond("");
      } else {
        setSingleSurveySystemDetailsSecond("");
      }
    } else if (inputName === "singleSurveySystemDetailsSecond") {
      if (singleSurveySystemSecond === "wsc") {
        setSingleSurveySystemDetailsSecond(e.target.value);
      } else {
        // remove spaces
        setSingleSurveySystemDetailsSecond(
          e.target.value.replace(/\s/g, "").toLowerCase()
        );
      }
    } else if (inputName === "singleFirstWelcomeNotification") {
      if (e.target.value === "new") {
        setSingleFirstNewWelcomeNotification(null);
      }
      setSingleFirstWelcomeNotification(e.target.value);
    } else if (inputName === "singleFirstNonComplianceTime") {
      setSingleFirstNonComplianceTime(e.target.value);
    } else if (inputName === "singleFirstNonComplianceNotification") {
      if (e.target.value === "new") {
        setSingleFirstNewNonComplianceNotification(null);
      }
      setSingleFirstNonComplianceNotification(e.target.value);
      // } else if (inputName === "singleFirstDailyReminderNotification") {
      //   if (e.target.value === "new") {
      //     setSingleFirstNewDailyReminderNotification(null);
      //   }
      //   setSingleFirstDailyReminderNotification(e.target.value);
    } else if (inputName === "singleFirstNewNonComplianceNotification") {
      setSingleFirstNewNonComplianceNotification(e.target.value);
    } else if (inputName === "singleFirstNewWelcomeNotificationPhrase") {
      if (singleFirstWelcomeNotification === "new") {
        setSingleFirstNewWelcomeNotification(e.target.value);
      } else {
        setSingleFirstNewWelcomeNotification(null);
      }
      // } else if (inputName === "singleFirstNewDailyReminderNotificationPhrase") {
      //   if (singleFirstDailyReminderNotification === "new") {
      //     setSingleFirstNewDailyReminderNotification(e.target.value);
      //   } else {
      //     setSingleFirstNewDailyReminderNotification(null);
      //   }
    } else if (inputName === "singleFirstNewNonComplianceNotificationPhrase") {
      if (singleFirstNonComplianceNotification === "new") {
        setSingleFirstNewNonComplianceNotification(e.target.value);
      } else {
        setSingleFirstNewNonComplianceNotification(null);
      }
    } else if (inputName === "singleFirstNonComplianceNotificationStartDelay") {
      setSingleFirstNonComplianceNotificationStartDelay(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
    } else if (
      inputName === "singleFirstNonComplianceNotificationInBetweenDelay"
    ) {
      setSingleFirstNonComplianceNotificationInBetweenDelay(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
    } else if (inputName === "singleSecondWelcomeNotification") {
      if (e.target.value === "new") {
        setSingleSecondNewWelcomeNotification(null);
      }
      setSingleSecondWelcomeNotification(e.target.value);
    } else if (inputName === "singleSecondNonComplianceTime") {
      setSingleSecondNonComplianceTime(e.target.value);
    } else if (inputName === "singleSecondNonComplianceNotification") {
      if (e.target.value === "new") {
        setSingleSecondNewNonComplianceNotification(null);
      }
      setSingleSecondNonComplianceNotification(e.target.value);
      // } else if (inputName === "singleSecondDailyReminderNotification") {
      //   if (e.target.value === "new") {
      //     setSingleSecondNewDailyReminderNotification(null);
      //   }
      //   setSingleSecondDailyReminderNotification(e.target.value);
    } else if (inputName === "singleSecondNewNonComplianceNotification") {
      setSingleSecondNewNonComplianceNotification(e.target.value);
    } else if (inputName === "singleSecondNewWelcomeNotificationPhrase") {
      if (singleSecondWelcomeNotification === "new") {
        setSingleSecondNewWelcomeNotification(e.target.value);
      } else {
        setSingleSecondNewWelcomeNotification(null);
      }
      // } else if (inputName === "singleSecondNewDailyReminderNotificationPhrase") {
      //   if (singleSecondDailyReminderNotification === "new") {
      //     setSingleSecondNewDailyReminderNotification(e.target.value);
      //   } else {
      //     setSingleSecondNewDailyReminderNotification(null);
      //   }
    } else if (inputName === "singleSecondNewNonComplianceNotificationPhrase") {
      if (singleSecondNonComplianceNotification === "new") {
        setSingleSecondNewNonComplianceNotification(e.target.value);
      } else {
        setSingleSecondNewNonComplianceNotification(null);
      }
    } else if (
      inputName === "singleSecondNonComplianceNotificationStartDelay"
    ) {
      setSingleSecondNonComplianceNotificationStartDelay(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
    } else if (
      inputName === "singleSecondNonComplianceNotificationInBetweenDelay"
    ) {
      setSingleSecondNonComplianceNotificationInBetweenDelay(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
    } else if (inputName === "diaryIncludeNotifications") {
      setDiaryIncludeNotifications(e.target.checked);
    } else if (inputName === "singleFirstIncludeNotifications") {
      setSingleFirstIncludeNotifications(e.target.checked);
    } else if (inputName === "singleSecondIncludeNotifications") {
      setSingleSecondIncludeNotifications(e.target.checked);
    } else {
      console.log("Error: " + inputName + " not found");
    }
  };

  // validateWizard has no input parameters and returns a boolean
  const validateWizard = useCallback((): boolean => {
    let allValid = true;
    const stringShortMax = 255;
    const stringLongMax = 1500;
    const validNfieldLinks = ["interviewing.nfieldmr.com/"];
    let issues = {};
    const reservedNames = ["compliant"];
    if (currentPageName === "Study Information") {
      // Study Name
      if (studyName === "") {
        issues = {
          ...issues,
          studyName: "Please enter a study name"
        };
        allValid = false;
      } else if (studyName && studyName?.length < 4) {
        issues = {
          ...issues,
          studyName: "Study name must be at least 4 characters"
        };
        allValid = false;
      } else if (studyName && studyName?.length > stringShortMax) {
        issues = {
          ...issues,
          studyName: `Must be less than ${stringShortMax} characters`
        };
        allValid = false;
      } else if (studyName) {
        //  check if name contains any reserved words
        const reservedName = reservedNames.find((name) =>
          studyName.toLowerCase().includes(name)
        );

        if (reservedName) {
          issues = {
            ...issues,
            studyName: `Name cannot contain reserved word: ${reservedName}`
          };
          allValid = false;
        }
      } else {
        issues = {
          ...issues,
          studyName: null
        };
      }
      // Study Description
      if (studyDescription === "") {
        issues = {
          ...issues,
          studyDescription: "Please enter a study description"
        };
        allValid = false;
      } else if (studyDescription && studyDescription?.length > stringLongMax) {
        issues = {
          ...issues,
          studyDescription: `Must be less than ${stringLongMax} characters`
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          studyDescription: null
        };
      }
      // Study Information
      if (studyInformation === "") {
        issues = {
          ...issues,
          studyInformation: "Please enter study information"
        };
        allValid = false;
      } else if (studyInformation && studyInformation?.length > stringLongMax) {
        issues = {
          ...issues,
          studyInformation: `Must be less than ${stringLongMax} characters`
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          studyInformation: null
        };
      }
      // Study Icon
      if (studyIcon === "") {
        issues = {
          ...issues,
          studyIcon: "Please upload a study icon"
        };
        allValid = false;
      } else {
        if (studyIcon) {
          // Extract MIME type from the data URL
          const mimeTypeMatch = studyIcon.match(
            /^data:(image\/(png|jpg|jpeg|webp|svg|gif|tiff));base64,/
          );
          if (!mimeTypeMatch) {
            issues = {
              ...issues,
              studyIcon: "Please upload a valid image"
            };
            allValid = false;
          }
        } else {
          issues = {
            ...issues,
            studyIcon: null
          };
        }
      }
      // Study Start Date
      // if (
      //   studyAutoStart === true &&
      //   (studyStartDate === "" || studyStartDate === null)
      // ) {
      //   issues = {
      //     ...issues,
      //     studyStartDate: "Please select a date",
      //   };
      //   allValid = false;
      // } else {
      //   issues = {
      //     ...issues,
      //     studyStartDate: null,
      //   };
      // }

      // if (studyBudget && studyBudget.length > stringShortMax) {
      //   issues = {
      //     ...issues,
      //     studyBudget: `Must be less than ${stringShortMax} characters`,
      //   };
      //   allValid = false;
      // }

      // If Resource is included
      if (resourceIncluded === true) {
        console.log("resourceIncluded", resourceIncluded);
        // Resource Name
        console.log(resourceName);
        if (resourceName === "") {
          issues = {
            ...issues,
            resourceName: "Please enter a resource name"
          };
          allValid = false;
        } else if (resourceName && resourceName.length > stringShortMax) {
          issues = {
            ...issues,
            resourceName: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            resourceName: null
          };
        }
        // Resource Description
        if (resourceDescription === "") {
          issues = {
            ...issues,
            resourceDescription: "Please enter a resource description"
          };
          allValid = false;
        } else if (
          resourceDescription &&
          resourceDescription.length > stringShortMax
        ) {
          issues = {
            ...issues,
            resourceDescription: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            resourceDescription: null
          };
        }
        // Resource File + File Name
        if (resourceFile === "" || resourceFileName === "") {
          issues = {
            ...issues,
            resourceFile: "Please upload a resource file (pdf)"
          };
          allValid = false;
        } else {
          if (resourceFile && resourceFileName) {
            console.log(resourceFile);
            // Extract MIME type from the data URL
            const mimeTypeMatch = resourceFile.match(
              /^data:(application\/(pdf));base64,/
            );
            if (!mimeTypeMatch) {
              issues = {
                ...issues,
                resourceFile: "Please upload a pdf"
              };
              allValid = false;
            }
          } else if (
            resourceFileName &&
            resourceFileName.length > stringShortMax
          ) {
            issues = {
              ...issues,
              resourceFileName: `File Name Must be less than ${stringShortMax} characters`
            };
            allValid = false;
          } else {
            issues = {
              ...issues,
              resourceFile: null,
              resourceFileName: null
            };
          }
        }
        // Resource Languages
        if (resourceLanguages.length === 0) {
          issues = {
            ...issues,
            resourceLanguages: "Please select at least one language"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            resourceLanguages: null
          };
        }
        // Resource Thumbnail
        if (resourceThumbnail === "") {
          issues = {
            ...issues,
            resourceThumbnail: "Please upload a resource thumbnail"
          };
          allValid = false;
        } else {
          if (resourceThumbnail) {
            // Extract MIME type from the data URL
            const mimeTypeMatch = resourceThumbnail.match(
              /^data:(image\/(png|jpg|jpeg|webp));base64,/
            );
            if (!mimeTypeMatch) {
              issues = {
                ...issues,
                resourceThumbnail: "Please upload a valid image"
              };
              allValid = false;
            }
          }
        }
      }

      if (resourceIncludedSecond === true) {
        // Resource Name
        console.log(resourceNameSecond);
        if (resourceNameSecond === "") {
          issues = {
            ...issues,
            resourceNameSecond: "Please enter a resource name"
          };
          allValid = false;
        } else if (
          resourceNameSecond &&
          resourceNameSecond.length > stringShortMax
        ) {
          issues = {
            ...issues,
            resourceNameSecond: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            resourceNameSecond: null
          };
        }
        // Resource Description
        if (resourceDescriptionSecond === "") {
          issues = {
            ...issues,
            resourceDescriptionSecond: "Please enter a resource description"
          };
          allValid = false;
        } else if (
          resourceDescriptionSecond &&
          resourceDescriptionSecond.length > stringShortMax
        ) {
          issues = {
            ...issues,
            resourceDescriptionSecond: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            resourceDescriptionSecond: null
          };
        }
        // Resource File + File Name
        if (resourceFileSecond === "" || resourceFileNameSecond === "") {
          issues = {
            ...issues,
            resourceFileSecond: "Please upload a resource file (pdf)"
          };
          allValid = false;
        } else {
          if (resourceFileSecond && resourceFileNameSecond) {
            // Extract MIME type from the data URL
            const mimeTypeMatch = resourceFileSecond.match(
              /^data:(application\/(pdf));base64,/
            );
            if (!mimeTypeMatch) {
              issues = {
                ...issues,
                resourceFileSecond: "Please upload a pdf"
              };
              allValid = false;
            }
          } else if (
            resourceFileNameSecond &&
            resourceFileNameSecond.length > stringShortMax
          ) {
            issues = {
              ...issues,
              resourceFileNameSecond: `File Name Must be less than ${stringShortMax} characters`
            };
            allValid = false;
          } else {
            issues = {
              ...issues,
              resourceFileSecond: null,
              resourceFileNameSecond: null
            };
          }
        }

        // Resource Languages
        if (resourceLanguagesSecond.length === 0) {
          issues = {
            ...issues,
            resourceLanguagesSecond: "Please select at least one language"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            resourceLanguagesSecond: null
          };
        }

        // Resource Thumbnail
        if (resourceThumbnailSecond === "") {
          issues = {
            ...issues,
            resourceThumbnailSecond: "Please upload a resource thumbnail"
          };
          allValid = false;
        } else {
          if (resourceThumbnailSecond) {
            // Extract MIME type from the data URL
            const mimeTypeMatch = resourceThumbnailSecond.match(
              /^data:(image\/(png|jpg|jpeg|webp));base64,/
            );
            if (!mimeTypeMatch) {
              issues = {
                ...issues,
                resourceThumbnailSecond: "Please upload a valid image"
              };
              allValid = false;
            }
          }
        }
      }

      // If Diary is included
      if (diaryIncluded === true) {
        // Diary Name
        if (diaryName === "") {
          issues = {
            ...issues,
            diaryName: "Please enter a diary name"
          };
          allValid = false;
        } else if (diaryName && diaryName.length > stringShortMax) {
          issues = {
            ...issues,
            diaryName: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        } else if (diaryName) {
          //  check if name contains any reserved words
          const reservedName = reservedNames.find((name) =>
            diaryName.toLowerCase().includes(name)
          );

          if (reservedName) {
            issues = {
              ...issues,
              diaryName: `Name cannot contain reserved word: ${reservedName}`
            };
            allValid = false;
          }
        } else {
          issues = {
            ...issues,
            diaryName: null
          };
        }
        // Diary Description
        if (diaryDescription === "") {
          issues = {
            ...issues,
            diaryDescription: "Please enter a diary description"
          };
          allValid = false;
        } else if (
          diaryDescription &&
          diaryDescription.length > stringLongMax
        ) {
          issues = {
            ...issues,
            diaryDescription: `Must be less than ${stringLongMax} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            diaryDescription: null
          };
        }
        // // Diary App Display Name
        // if (diaryAppDisplayName === "") {
        //   issues = {
        //     ...issues,
        //     diaryAppDisplayName: "Please enter a diary app display name"
        //   };
        //   allValid = false;
        // } else if (
        //   diaryAppDisplayName &&
        //   diaryAppDisplayName.length > stringShortMax
        // ) {
        //   issues = {
        //     ...issues,
        //     diaryAppDisplayName: `Must be less than ${stringShortMax} characters`
        //   };
        //   allValid = false;
        // } else {
        //   issues = {
        //     ...issues,
        //     diaryAppDisplayName: null
        //   };
        // }
        // Diary Icon
        if (diaryIcon === "") {
          issues = {
            ...issues,
            diaryIcon: "Please upload a diary icon"
          };
          allValid = false;
        } else {
          if (diaryIcon) {
            // Extract MIME type from the data URL
            const mimeTypeMatch = diaryIcon.match(
              /^data:(image\/(png|jpg|jpeg|webp|svg|gif|tiff));base64,/
            );
            if (!mimeTypeMatch) {
              issues = {
                ...issues,
                diaryIcon: "Please upload a valid image"
              };
              allValid = false;
            }
          } else {
            issues = {
              ...issues,
              diaryIcon: null
            };
          }
        }

        // Diary Start Date
        if (
          diaryAutoStart === true &&
          (diaryStartDate === "" || diaryStartDate === null)
        ) {
          issues = {
            ...issues,
            diaryStartDate: "Please select a date"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            diaryStartDate: null
          };
        }
      }
      // If Single is included
      if (singleIncludedFirst === true) {
        // Single Name
        if (singleNameFirst === "") {
          issues = {
            ...issues,
            singleNameFirst: "Please enter an single name"
          };
          allValid = false;
        } else if (singleNameFirst && singleNameFirst.length > stringShortMax) {
          issues = {
            ...issues,
            singleNameFirst: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        } else if (singleNameFirst) {
          //  check if name contains any reserved words
          const reservedName = reservedNames.find((name) =>
            singleNameFirst.toLowerCase().includes(name)
          );

          if (reservedName) {
            issues = {
              ...issues,
              singleNameFirst: `Name cannot contain reserved word: ${reservedName}`
            };
            allValid = false;
          }
        } else {
          issues = {
            ...issues,
            singleNameFirst: null
          };
        }
        // Single Description
        if (singleDescriptionFirst === "") {
          issues = {
            ...issues,
            singleDescriptionFirst: "Please enter an single description"
          };
          allValid = false;
        } else if (
          singleDescriptionFirst &&
          singleDescriptionFirst.length > stringLongMax
        ) {
          issues = {
            ...issues,
            singleDescriptionFirst: `Must be less than ${stringLongMax} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            singleDescriptionFirst: null
          };
        }
        // // Single App Display Name
        // if (singleAppDisplayNameFirst === "") {
        //   issues = {
        //     ...issues,
        //     singleAppDisplayNameFirst: "Please enter an single app display name"
        //   };
        //   allValid = false;
        // } else if (
        //   singleAppDisplayNameFirst &&
        //   singleAppDisplayNameFirst.length > stringShortMax
        // ) {
        //   issues = {
        //     ...issues,
        //     singleAppDisplayNameFirst: `Must be less than ${stringShortMax} characters`
        //   };
        //   allValid = false;
        // } else {
        //   issues = {
        //     ...issues,
        //     singleAppDisplayNameFirst: null
        //   };
        // }
        // Single Icon
        if (singleIconFirst === "") {
          issues = {
            ...issues,
            singleIconFirst: "Please upload an single icon"
          };
          allValid = false;
        } else {
          if (singleIconFirst) {
            // Extract MIME type from the data URL
            const mimeTypeMatch = singleIconFirst.match(
              /^data:(image\/(png|jpg|jpeg|webp|svg|gif|tiff));base64,/
            );
            if (!mimeTypeMatch) {
              issues = {
                ...issues,
                singleIconFirst: "Please upload a valid image"
              };
              allValid = false;
            }
          } else {
            issues = {
              ...issues,
              singleIconFirst: null
            };
          }
        }

        // Single Start Date
        if (
          singleAutoStartFirst === true &&
          (singleStartDateFirst === "" || singleStartDateFirst === null)
        ) {
          issues = {
            ...issues,
            singleStartDateFirst: "Please select a date"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            singleStartDateFirst: null
          };
        }
      }
      if (singleIncludedSecond === true) {
        // Single Name
        if (singleNameSecond === "") {
          issues = {
            ...issues,
            singleNameSecond: "Please enter an single name"
          };
          allValid = false;
        } else if (
          singleNameSecond &&
          singleNameSecond.length > stringShortMax
        ) {
          issues = {
            ...issues,
            singleNameSecond: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        } else if (singleNameSecond) {
          //  check if name contains any reserved words
          const reservedName = reservedNames.find((name) =>
            singleNameSecond.toLowerCase().includes(name)
          );

          if (reservedName) {
            issues = {
              ...issues,
              singleNameSecond: `Name cannot contain reserved word: ${reservedName}`
            };
            allValid = false;
          }
        } else {
          issues = {
            ...issues,
            singleNameSecond: null
          };
        }
        // Single Description
        if (singleDescriptionSecond === "") {
          issues = {
            ...issues,
            singleDescriptionSecond: "Please enter an single description"
          };
          allValid = false;
        } else if (
          singleDescriptionSecond &&
          singleDescriptionSecond.length > stringLongMax
        ) {
          issues = {
            ...issues,
            singleDescriptionSecond: `Must be less than ${stringLongMax} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            singleDescriptionSecond: null
          };
        }
        // // Single App Display Name
        // if (singleAppDisplayNameSecond === "") {
        //   issues = {
        //     ...issues,
        //     singleAppDisplayNameSecond:
        //       "Please enter an single app display name"
        //   };
        //   allValid = false;
        // } else if (
        //   singleAppDisplayNameSecond &&
        //   singleAppDisplayNameSecond.length > stringShortMax
        // ) {
        //   issues = {
        //     ...issues,
        //     singleAppDisplayNameSecond: `Must be less than ${stringShortMax} characters`
        //   };
        //   allValid = false;
        // } else {
        //   issues = {
        //     ...issues,
        //     singleAppDisplayNameSecond: null
        //   };
        // }
        // Single Icon
        if (singleIconSecond === "") {
          issues = {
            ...issues,
            singleIconSecond: "Please upload an single icon"
          };
          allValid = false;
        } else {
          if (singleIconSecond) {
            // Extract MIME type from the data URL
            const mimeTypeMatch = singleIconSecond.match(
              /^data:(image\/(png|jpg|jpeg|webp|svg|gif|tiff));base64,/
            );
            if (!mimeTypeMatch) {
              issues = {
                ...issues,
                singleIconSecond: "Please upload a valid image"
              };
              allValid = false;
            }
          } else {
            issues = {
              ...issues,
              singleIconSecond: null
            };
          }
        }

        // Single Start Date
        if (
          singleAutoStartSecond === true &&
          (singleStartDateSecond === "" || singleStartDateSecond === null)
        ) {
          issues = {
            ...issues,
            singleStartDateSecond: "Please select a date"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            singleStartDateSecond: null
          };
        }
      }
      // console.log("selectedLanguages.length", selectedLanguages.length);
      if (selectedLanguages.length === 0) {
        issues = {
          ...issues,
          selectedLanguages: "Please select at least one language"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          selectedLanguages: null
        };
      }
    }
    if (currentPageName === "Diary Configuration") {
      // Diary Active Days
      if (diaryActiveDays === null || diaryActiveDays === undefined) {
        issues = {
          ...issues,
          diaryActiveDays: "Please enter a number"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          diaryActiveDays: null
        };
      }
      // Diary Grace Days
      if (diaryGraceDays === null || diaryGraceDays === undefined) {
        issues = {
          ...issues,
          diaryGraceDays: "Please enter a number"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          diaryGraceDays: null
        };
      }

      // Bonus Days
      if (diaryBonusDays === null) {
        issues = {
          ...issues,
          diaryBonusDays: "Please enter a number of bonus days"
        };
        allValid = false;
      } else if (diaryBonusDays && diaryBonusDays < 0) {
        issues = {
          ...issues,
          diaryBonusDays: "Bonus days must be at least 0"
        };
        allValid = false;
      } else if (diaryBonusDays && diaryBonusDays > 365) {
        issues = {
          ...issues,
          diaryBonusDays: "Bonus days must be less than 365"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          diaryBonusDays: null
        };
      }
      // Minimum Daily Completes
      if (diaryMinimumDailyCompletes === null) {
        issues = {
          ...issues,
          diaryMinimumDailyCompletes:
            "Please enter a number of minimum completes"
        };
        allValid = false;
      } else if (diaryMinimumDailyCompletes && diaryMinimumDailyCompletes < 1) {
        issues = {
          ...issues,
          diaryMinimumDailyCompletes: "Minimum completes must be at least 1"
        };
        allValid = false;
      } else if (
        diaryMaximumDailyCompletes &&
        diaryMinimumDailyCompletes > diaryMaximumDailyCompletes
      ) {
        issues = {
          ...issues,
          diaryMinimumDailyCompletes:
            "Minimum completes must be less than maximum completes"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          diaryMinimumDailyCompletes: null
        };
      }

      // diarySurveySystemDetails
      issues = {
        ...issues,
        diarySurveySystemDetails: null
      };
      if (
        diarySurveySystemDetails === null ||
        diarySurveySystemDetails === ""
      ) {
        if (diarySurveySystem === "wsc") {
          issues = {
            ...issues,
            diarySurveySystemDetails: "Please select a survey"
          };
        } else {
          issues = {
            ...issues,
            diarySurveySystemDetails: "Please enter a link"
          };
        }
        allValid = false;
      } else if (
        diarySurveySystem === "nfield" ||
        diarySurveySystem === "custom"
      ) {
        // diarySurveySystemDetails must start with https://
        if (diarySurveySystemDetails?.substring(0, 8) !== "https://") {
          issues = {
            ...issues,
            diarySurveySystemDetails:
              "Please enter a valid link starting with https://"
          };
          allValid = false;
        } else if (
          diarySurveySystem === "nfield" &&
          !validNfieldLinks.some((link) =>
            diarySurveySystemDetails.includes(link)
          )
        ) {
          issues = {
            ...issues,
            diarySurveySystemDetails: "Please enter a valid link for NField"
          };
          allValid = false;
        } else if (
          diarySurveySystemDetails &&
          diarySurveySystemDetails.length > stringShortMax
        ) {
          issues = {
            ...issues,
            diarySurveySystemDetails: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        }
      }
    }
    if (currentPageName === "Diary Notifications") {
      if (diaryIncludeNotifications === true) {
        // Daily Reminder Time
        if (diaryDailyReminderTime === "") {
          issues = {
            ...issues,
            diaryDailyReminderTime: "Please enter a time"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            diaryDailyReminderTime: null
          };
        }
        // diaryNonComplianceTime
        if (diaryNonComplianceTime === "") {
          issues = {
            ...issues,
            diaryNonComplianceTime: "Please enter a time"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            diaryNonComplianceTime: null
          };
        }

        if (
          diaryWelcomeNotification === "" ||
          diaryWelcomeNotification === null
        ) {
          issues = {
            ...issues,
            diaryWelcomeNotification: "Please enter a notification"
          };
          allValid = false;
        }

        if (
          diaryDailyReminderNotification === "" ||
          diaryDailyReminderNotification === null
        ) {
          issues = {
            ...issues,
            diaryDailyReminderNotification: "Please enter a notification"
          };
          allValid = false;
        }
        if (
          diaryNonComplianceNotification === "" ||
          diaryNonComplianceNotification === null
        ) {
          issues = {
            ...issues,
            diaryNonComplianceNotification: "Please enter a notification"
          };
          allValid = false;
        }

        if (diaryWelcomeNotification === "new") {
          if (
            !diaryNewWelcomeNotification ||
            diaryNewWelcomeNotification === null ||
            diaryNewWelcomeNotification === undefined
          ) {
            issues = {
              ...issues,
              diaryNewWelcomeNotificationPhrase: "Please enter a notification",
              diaryNewWelcomeNotificationTranslations:
                "Please enter a notification"
            };
            allValid = false;
          } else if (
            diaryNewWelcomeNotification &&
            diaryNewWelcomeNotification.length > stringLongMax
          ) {
            issues = {
              ...issues,
              diaryNewWelcomeNotificationPhrase: `Must be less than ${stringLongMax} characters`
            };
            allValid = false;
          }
        }

        if (diaryDailyReminderNotification === "new") {
          if (
            !diaryNewDailyReminderNotification ||
            diaryNewDailyReminderNotification === null ||
            diaryNewDailyReminderNotification === undefined
          ) {
            issues = {
              ...issues,
              diaryNewDailyReminderNotificationPhrase:
                "Please enter a notification",
              diaryNewDailyReminderNotificationTranslations:
                "Please enter a notification"
            };
            allValid = false;
          } else if (
            diaryNewDailyReminderNotification &&
            diaryNewDailyReminderNotification.length > stringLongMax
          ) {
            issues = {
              ...issues,
              diaryNewDailyReminderNotificationPhrase: `Must be less than ${stringLongMax} characters`
            };
            allValid = false;
          }
        }

        if (diaryNonComplianceNotification === "new") {
          if (
            !diaryNewNonComplianceNotification ||
            diaryNewNonComplianceNotification === null ||
            diaryNewNonComplianceNotification === undefined
          ) {
            issues = {
              ...issues,
              diaryNewNonComplianceNotificationPhrase:
                "Please enter a notification",
              diaryNewNonComplianceNotificationTranslations:
                "Please enter a notification"
            };
            allValid = false;
          } else if (
            diaryNewNonComplianceNotification &&
            diaryNewNonComplianceNotification.length > stringLongMax
          ) {
            issues = {
              ...issues,
              diaryNewNonComplianceNotificationPhrase: `Must be less than ${stringLongMax} characters`
            };
            allValid = false;
          }
        }
      }
    }

    if (currentPageName === "Single Configuration First") {
      if (
        singleSurveySystemDetailsFirst === null ||
        singleSurveySystemDetailsFirst === ""
      ) {
        if (singleSurveySystemFirst === "wsc") {
          issues = {
            ...issues,
            singleSurveySystemFirst: "Please select a survey"
          };
        } else {
          issues = {
            ...issues,
            singleSurveySystemFirst: "Please enter a link"
          };
        }
        allValid = false;
      } else if (singleSurveySystemFirst === "wsc") {
      } else if (
        singleSurveySystemFirst === "nfield" ||
        singleSurveySystemFirst === "custom"
      ) {
        // singleSurveySystemDetails must start with https://
        if (singleSurveySystemDetailsFirst?.substring(0, 8) !== "https://") {
          issues = {
            ...issues,
            singleSurveySystemDetailsFirst:
              "Please enter a valid link starting with https://"
          };
          allValid = false;
        } else if (
          singleSurveySystemFirst === "nfield" &&
          !validNfieldLinks.some((link) =>
            singleSurveySystemDetailsFirst.includes(link)
          )
        ) {
          issues = {
            ...issues,
            singleSurveySystemDetailsFirst:
              "Please enter a valid link for NField"
          };
          allValid = false;
        } else if (
          singleSurveySystemDetailsFirst &&
          singleSurveySystemDetailsFirst.length > stringShortMax
        ) {
          issues = {
            ...issues,
            singleSurveySystemDetailsFirst: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        }
      }
    }
    if (
      currentPageName === "Single Notifications First" &&
      singleFirstIncludeNotifications === true
    ) {
      if (
        singleFirstWelcomeNotification === "" ||
        singleFirstWelcomeNotification === null
      ) {
        issues = {
          ...issues,
          singleFirstWelcomeNotification: "Please enter a notification"
        };
        allValid = false;
      }

      if (
        singleFirstNonComplianceNotification === "" ||
        singleFirstNonComplianceNotification === null
      ) {
        issues = {
          ...issues,
          singleFirstNonComplianceNotification: "Please enter a notification"
        };
        allValid = false;
      }

      // if (
      //   singleFirstDailyReminderNotification === "" ||
      //   singleFirstDailyReminderNotification === null
      // ) {
      //   issues = {
      //     ...issues,
      //     singleFirstDailyReminderNotification: "Please enter a notification",
      //   };
      //   allValid = false;
      // }

      if (singleFirstWelcomeNotification === "new") {
        if (
          !singleFirstNewWelcomeNotification ||
          singleFirstNewWelcomeNotification === null ||
          singleFirstNewWelcomeNotification === undefined
        ) {
          issues = {
            ...issues,
            singleFirstNewWelcomeNotificationPhrase:
              "Please enter a notification",
            singleFirstNewWelcomeNotificationTranslations:
              "Please enter a notification"
          };
          allValid = false;
        } else if (
          singleFirstNewWelcomeNotification &&
          singleFirstNewWelcomeNotification.length > stringLongMax
        ) {
          issues = {
            ...issues,
            singleFirstNewWelcomeNotificationPhrase: `Must be less than ${stringLongMax} characters`
          };
          allValid = false;
        }
      }

      // if (singleFirstDailyReminderNotification === "new") {
      //   if (
      //     !singleFirstNewDailyReminderNotification ||
      //     singleFirstNewDailyReminderNotification === null ||
      //     singleFirstNewDailyReminderNotification === undefined
      //   ) {
      //     issues = {
      //       ...issues,
      //       singleFirstNewDailyReminderNotificationPhrase:
      //         "Please enter a phrase",
      //       singleFirstNewDailyReminderNotificationTranslations:
      //         "Please enter a notification",
      //     };
      //     allValid = false;
      //   } else if (
      //     singleFirstNewDailyReminderNotification &&
      //     singleFirstNewDailyReminderNotification.length > stringLongMax
      //   ) {
      //     issues = {
      //       ...issues,
      //       singleFirstNewDailyReminderNotificationPhrase: `Must be less than ${stringLongMax} characters`,
      //     };
      //     allValid = false;
      //   }
      // }

      if (singleFirstNonComplianceNotification === "new") {
        if (
          !singleFirstNewNonComplianceNotification ||
          singleFirstNewNonComplianceNotification === null ||
          singleFirstNewNonComplianceNotification === undefined
        ) {
          issues = {
            ...issues,
            singleFirstNewNonComplianceNotificationPhrase:
              "Please enter a notification",
            singleFirstNewNonComplianceNotificationTranslations:
              "Please enter a notification"
          };
          allValid = false;
        } else if (
          singleFirstNewNonComplianceNotification &&
          singleFirstNewNonComplianceNotification.length > stringLongMax
        ) {
          issues = {
            ...issues,
            singleFirstNewNonComplianceNotificationPhrase: `Must be less than ${stringLongMax} characters`
          };
          allValid = false;
        }
      }

      // singleFirstNonComplianceTime
      if (singleFirstNonComplianceTime === "") {
        issues = {
          ...issues,
          singleFirstNonComplianceTime: "Please enter a time"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          singleFirstNonComplianceTime: null
        };
      }
    }

    if (currentPageName === "Single Configuration Second") {
      if (
        singleSurveySystemDetailsSecond === null ||
        singleSurveySystemDetailsSecond === ""
      ) {
        if (singleSurveySystemSecond === "wsc") {
          issues = {
            ...issues,
            singleSurveySystemSecond: "Please select a survey"
          };
        } else {
          issues = {
            ...issues,
            singleSurveySystemSecond: "Please enter a link"
          };
        }
        allValid = false;
      } else if (singleSurveySystemSecond === "wsc") {
      } else if (
        singleSurveySystemSecond === "nfield" ||
        singleSurveySystemSecond === "custom"
      ) {
        // singleSurveySystemDetails must start with https://
        if (singleSurveySystemDetailsSecond?.substring(0, 8) !== "https://") {
          issues = {
            ...issues,
            singleSurveySystemDetailsSecond:
              "Please enter a valid link starting with https://"
          };
          allValid = false;
        } else if (
          singleSurveySystemSecond === "nfield" &&
          !validNfieldLinks.some((link) =>
            singleSurveySystemDetailsSecond.includes(link)
          )
        ) {
          issues = {
            ...issues,
            singleSurveySystemDetailsSecond:
              "Please enter a valid link for NField"
          };
          allValid = false;
        } else if (
          singleSurveySystemDetailsSecond &&
          singleSurveySystemDetailsSecond.length > stringShortMax
        ) {
          issues = {
            ...issues,
            singleSurveySystemDetailsSecond: `Must be less than ${stringShortMax} characters`
          };
          allValid = false;
        }
      }
    }

    if (
      currentPageName === "Single Notifications Second" &&
      singleSecondIncludeNotifications === true
    ) {
      if (
        singleSecondWelcomeNotification === "" ||
        singleSecondWelcomeNotification === null
      ) {
        issues = {
          ...issues,
          singleSecondWelcomeNotification: "Please enter a notification"
        };
        allValid = false;
      }

      if (
        singleSecondNonComplianceNotification === "" ||
        singleSecondNonComplianceNotification === null
      ) {
        issues = {
          ...issues,
          singleSecondNonComplianceNotification: "Please enter a notification"
        };
        allValid = false;
      }

      // if (
      //   singleSecondDailyReminderNotification === "" ||
      //   singleSecondDailyReminderNotification === null
      // ) {
      //   issues = {
      //     ...issues,
      //     singleSecondDailyReminderNotification: "Please enter a notification",
      //   };
      //   allValid = false;
      // }

      if (singleSecondWelcomeNotification === "new") {
        if (
          !singleSecondNewWelcomeNotification ||
          singleSecondNewWelcomeNotification === null ||
          singleSecondNewWelcomeNotification === undefined
        ) {
          issues = {
            ...issues,
            singleSecondNewWelcomeNotificationPhrase:
              "Please enter a notification",
            singleSecondNewWelcomeNotificationTranslations:
              "Please enter a notification"
          };
          allValid = false;
        } else if (
          singleSecondNewWelcomeNotification &&
          singleSecondNewWelcomeNotification.length > stringLongMax
        ) {
          issues = {
            ...issues,
            singleSecondNewWelcomeNotificationPhrase: `Must be less than ${stringLongMax} characters`
          };
          allValid = false;
        }
      }

      // if (singleSecondDailyReminderNotification === "new") {
      //   if (
      //     !singleSecondNewDailyReminderNotification ||
      //     singleSecondNewDailyReminderNotification === null ||
      //     singleSecondNewDailyReminderNotification === undefined
      //   ) {
      //     issues = {
      //       ...issues,
      //       singleSecondNewDailyReminderNotificationPhrase:
      //         "Please enter a phrase",
      //       singleSecondNewDailyReminderNotificationTranslations:
      //         "Please enter a notification",
      //     };
      //     allValid = false;
      //   } else if (
      //     singleSecondNewDailyReminderNotification &&
      //     singleSecondNewDailyReminderNotification.length > stringLongMax
      //   ) {
      //     issues = {
      //       ...issues,
      //       singleSecondNewDailyReminderNotificationPhrase: `Must be less than ${stringLongMax} characters`,
      //     };
      //     allValid = false;
      //   }
      // }

      if (singleSecondNonComplianceNotification === "new") {
        if (
          !singleSecondNewNonComplianceNotification ||
          singleSecondNewNonComplianceNotification === null ||
          singleSecondNewNonComplianceNotification === undefined
        ) {
          issues = {
            ...issues,
            singleSecondNewNonComplianceNotificationPhrase:
              "Please enter a notification",
            singleSecondNewNonComplianceNotificationTranslations:
              "Please enter a notification"
          };
          allValid = false;
        } else if (
          singleSecondNewNonComplianceNotification &&
          singleSecondNewNonComplianceNotification.length > stringLongMax
        ) {
          issues = {
            ...issues,
            singleSecondNewNonComplianceNotificationPhrase: `Must be less than ${stringLongMax} characters`
          };
          allValid = false;
        }
      }

      // singleSecondNonComplianceTime
      if (singleSecondNonComplianceTime === "") {
        issues = {
          ...issues,
          singleSecondNonComplianceTime: "Please enter a time"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          singleSecondNonComplianceTime: null
        };
      }
    }

    // console.log("validateWizard");
    // console.log("currentPageName", currentPageName);
    // console.log(currentPageName === allPagesNames[2]);
    // console.log("allValidWizard", allValid);
    // console.log("issues", issues);
    // console.log("inputErrors.studyName", issues?.studyName);
    setInputErrors(issues);
    // allValid is true if inputErrors is empty

    return allValid;
  }, [
    currentPageName,
    studyName,
    studyDescription,
    studyInformation,
    studyIcon,
    // studyDeadline,
    // studyBudget,
    resourceIncluded,
    resourceName,
    resourceDescription,
    resourceFile,
    resourceFileName,
    resourceThumbnail,
    resourceLanguages,
    // SECOND RESOURCE
    resourceIncludedSecond,
    resourceNameSecond,
    resourceDescriptionSecond,
    resourceFileSecond,
    resourceFileNameSecond,
    resourceThumbnailSecond,
    resourceLanguagesSecond,

    diaryIncluded,
    diaryName,
    diaryDescription,
    // diaryAppDisplayName,
    diaryIcon,
    diaryAutoStart,
    diaryStartDate,
    singleIncludedFirst,
    singleNameFirst,
    singleDescriptionFirst,
    // singleAppDisplayNameFirst,
    singleIconFirst,
    singleIncludedSecond,
    singleNameSecond,
    singleDescriptionSecond,
    // singleAppDisplayNameSecond,
    singleIconSecond,
    selectedLanguages,
    diaryActiveDays,
    diaryGraceDays,
    diaryBonusDays,
    diaryMinimumDailyCompletes,
    diaryMaximumDailyCompletes,
    diarySurveySystem,
    diarySurveySystemDetails,
    diaryIncludeNotifications,
    diaryWelcomeNotification,
    diaryDailyReminderNotification,
    diaryNonComplianceNotification,
    diaryDailyReminderTime,
    diaryNonComplianceTime,
    diaryNewWelcomeNotification,
    diaryNewDailyReminderNotification,
    diaryNewNonComplianceNotification,
    singleSurveySystemFirst,
    singleAutoStartFirst,
    singleStartDateFirst,
    singleAutoStartSecond,
    singleStartDateSecond,
    singleSurveySystemDetailsFirst,
    singleSurveySystemSecond,
    singleSurveySystemDetailsSecond,
    singleFirstIncludeNotifications,
    singleFirstWelcomeNotification,
    // singleFirstDailyReminderNotification,
    singleFirstNonComplianceNotification,
    singleFirstNewWelcomeNotification,
    // singleFirstNewDailyReminderNotification,
    singleFirstNewNonComplianceNotification,
    singleSecondIncludeNotifications,
    singleSecondWelcomeNotification,
    // singleSecondDailyReminderNotification,
    singleSecondNonComplianceNotification,
    singleSecondNewWelcomeNotification,
    // singleSecondNewDailyReminderNotification,
    singleSecondNewNonComplianceNotification,
    singleFirstNonComplianceTime,
    singleSecondNonComplianceTime
  ]);

  // validate useEffect, dependent on all fields
  useEffect(() => {
    setPageValid(validateWizard());
  }, [validateWizard]);

  // checkEmptyFieldsBeforeSubmit return boolean
  function checkEmptyFieldsBeforeSubmit(): boolean {
    let allValid = true;
    // console.log(currentPageName);
    if (currentPageName === "Study Information") {
      if (studyName === null) {
        setStudyName("");
        // console.log("studyName", studyName);
        allValid = false;
      }
      if (studyDescription === null) {
        setStudyDescription("");
        // console.log("studyDescription", studyDescription);
        allValid = false;
      }
      if (studyInformation === null) {
        setStudyInformation("");
        // console.log("studyInformation", studyInformation);
        allValid = false;
      }
      if (studyIcon === null) {
        setStudyIcon("");
        // console.log("studyIcon", studyIcon);
        allValid = false;
      }

      console.log(resourceIncluded);
      if (resourceIncluded) {
        if (resourceName === null) {
          setResourceName("");
          // console.log("resourceName", resourceName);
          allValid = false;
        }
        if (resourceDescription === null) {
          setResourceDescription("");
          // console.log("resourceDescription", resourceDescription);
          allValid = false;
        }
        if (resourceThumbnail === null) {
          setResourceThumbnail("");
          allValid = false;
        }
        if (resourceFile === null) {
          setResourceFile("");
          allValid = false;
        }
        if (resourceFileName === null) {
          setResourceFileName("");
          allValid = false;
        }
        if (resourceLanguages === null || resourceLanguages.length === 0) {
          setResourceLanguages([]);
          allValid = false;
        }
      }

      // SECOND RESOURCE
      if (resourceIncludedSecond) {
        if (resourceNameSecond === null) {
          setResourceNameSecond("");
          allValid = false;
        }
        if (resourceDescriptionSecond === null) {
          setResourceDescriptionSecond("");
          allValid = false;
        }
        if (resourceThumbnailSecond === null) {
          setResourceThumbnailSecond("");
          allValid = false;
        }
        if (resourceFileSecond === null) {
          setResourceFileSecond("");
          allValid = false;
        }
        if (resourceFileNameSecond === null) {
          setResourceFileNameSecond("");
          allValid = false;
        }
        if (
          resourceLanguagesSecond === null ||
          resourceLanguagesSecond.length === 0
        ) {
          setResourceLanguagesSecond([]);
          allValid = false;
        }
      }

      if (diaryIncluded) {
        if (diaryName === null) {
          setDiaryName("");
          // console.log("diaryName", diaryName);
          allValid = false;
        }
        if (diaryDescription === null) {
          setDiaryDescription("");
          // console.log("diaryDescription", diaryDescription);
          allValid = false;
        }
        // if (diaryAppDisplayName === null) {
        //   setDiaryAppDisplayName("");
        //   // console.log("diaryAppDisplayName", diaryAppDisplayName);
        //   allValid = false;
        // }
        if (diaryIcon === null) {
          setDiaryIcon("");
          // console.log("diaryIcon", diaryIcon);
          allValid = false;
        }
      }
      if (singleIncludedFirst) {
        if (singleNameFirst === null) {
          setSingleNameFirst("");
          console.log("singleNameFirst", singleNameFirst);
          allValid = false;
        }
        if (singleDescriptionFirst === null) {
          setSingleDescriptionFirst("");
          console.log("singleDescriptionFirst", singleDescriptionFirst);
          allValid = false;
        }
        // if (singleAppDisplayNameFirst === null) {
        //   setSingleAppDisplayNameFirst("");
        //   console.log("singleAppDisplayNameFirst", singleAppDisplayNameFirst);
        //   allValid = false;
        // }
        if (singleIconFirst === null) {
          setSingleIconFirst("");
          console.log("singleIconFirst", singleIconFirst);
          allValid = false;
        }
      }

      if (singleIncludedSecond) {
        if (singleNameSecond === null) {
          setSingleNameSecond("");
          // console.log("singleNameSecond", singleNameSecond);
          allValid = false;
        }
        if (singleDescriptionSecond === null) {
          setSingleDescriptionSecond("");
          // console.log("singleDescriptionSecond", singleDescriptionSecond);
          allValid = false;
        }
        // if (singleAppDisplayNameSecond === null) {
        //   setSingleAppDisplayNameSecond("");
        //   // console.log("singleAppDisplayNameSecond", singleAppDisplayNameSecond);
        //   allValid = false;
        // }
        if (singleIconSecond === null) {
          setSingleIconSecond("");
          // console.log("singleIconSecond", singleIconSecond);
          allValid = false;
        }
      }
    }
    if (currentPageName === "Diary Configuration") {
      // diarySurveySystemDetails
      if (diarySurveySystemDetails === null) {
        setDiarySurveySystemDetails("");
        // console.log("diarySurveySystemDetails", diarySurveySystemDetails);
        allValid = false;
      }
    }
    if (
      currentPageName === "Diary Notifications" &&
      diaryIncludeNotifications
    ) {
      // diaryNotificationTime
      if (diaryDailyReminderTime === null) {
        setDiaryDailyReminderTime("");
        // console.log("diaryNotificationTime", diaryNotificationTime);
        allValid = false;
      }
      // diaryNonComplianceTime
      if (diaryNonComplianceTime === null) {
        setDiaryNonComplianceTime("");
        // console.log("diaryNonComplianceTime", diaryNonComplianceTime);
        allValid = false;
      }

      if (
        diaryWelcomeNotification === null ||
        diaryWelcomeNotification === ""
      ) {
        allValid = false;
      }
      if (
        diaryDailyReminderNotification === null ||
        diaryDailyReminderNotification === ""
      ) {
        allValid = false;
      }
      if (
        diaryNonComplianceNotification === null ||
        diaryNonComplianceNotification === ""
      ) {
        allValid = false;
      }

      if (diaryWelcomeNotification === "new") {
        // console.log("diaryNewWelcomeNotification", diaryNewWelcomeNotification);
        if (
          !diaryNewWelcomeNotification ||
          diaryNewWelcomeNotification === null ||
          diaryNewWelcomeNotification === undefined
        ) {
          allValid = false;
        } else {
          if (
            diaryNewWelcomeNotification === null ||
            diaryNewWelcomeNotification === ""
          ) {
            allValid = false;
          }
        }
      }

      if (diaryDailyReminderNotification === "new") {
        if (
          !diaryNewDailyReminderNotification ||
          diaryNewDailyReminderNotification === null ||
          diaryNewDailyReminderNotification === undefined
        ) {
          allValid = false;
        } else {
          if (
            diaryNewDailyReminderNotification === null ||
            diaryNewDailyReminderNotification === ""
          ) {
            allValid = false;
          }
        }
      }

      if (diaryNonComplianceNotification === "new") {
        if (
          !diaryNewNonComplianceNotification ||
          diaryNewNonComplianceNotification === null ||
          diaryNewNonComplianceNotification === undefined
        ) {
          allValid = false;
        } else {
          if (
            diaryNewNonComplianceNotification === null ||
            diaryNewNonComplianceNotification === ""
          ) {
            allValid = false;
          }
        }
      }
    }
    if (currentPageName === "Single Configuration First") {
    }
    if (
      currentPageName === "Single Notifications First" &&
      singleFirstIncludeNotifications
    ) {
      if (
        singleFirstWelcomeNotification === null ||
        singleFirstWelcomeNotification === ""
      ) {
        allValid = false;
      }
      if (
        singleFirstNonComplianceNotification === null ||
        singleFirstNonComplianceNotification === ""
      ) {
        allValid = false;
      }
      // if (
      //   singleFirstDailyReminderNotification === null ||
      //   singleFirstDailyReminderNotification === ""
      // ) {
      //   allValid = false;
      // }

      if (singleFirstWelcomeNotification === "new") {
        if (
          !singleFirstNewWelcomeNotification ||
          singleFirstNewWelcomeNotification === null ||
          singleFirstNewWelcomeNotification === undefined
        ) {
          allValid = false;
        } else {
          if (
            singleFirstNewWelcomeNotification === null ||
            singleFirstNewWelcomeNotification === ""
          ) {
            allValid = false;
          }
        }
      }

      // if (singleFirstDailyReminderNotification === "new") {
      //   if (
      //     !singleFirstNewDailyReminderNotification ||
      //     singleFirstNewDailyReminderNotification === null ||
      //     singleFirstNewDailyReminderNotification === undefined
      //   ) {
      //     allValid = false;
      //   } else {
      //     if (
      //       singleFirstNewDailyReminderNotification === null ||
      //       singleFirstNewDailyReminderNotification === ""
      //     ) {
      //       allValid = false;
      //     }
      //   }
      // }

      // singleFirstNonComplianceTime
      if (singleFirstNonComplianceTime === null) {
        setSingleFirstNonComplianceTime("");
        allValid = false;
      }

      if (singleFirstNonComplianceNotification === "new") {
        if (
          !singleFirstNewNonComplianceNotification ||
          singleFirstNewNonComplianceNotification === null ||
          singleFirstNewNonComplianceNotification === undefined
        ) {
          allValid = false;
        } else {
          if (
            singleFirstNewNonComplianceNotification === null ||
            singleFirstNewNonComplianceNotification === ""
          ) {
            allValid = false;
          }
        }
      }
    }

    if (currentPageName === "Single Configuration Second") {
    }
    if (
      currentPageName === "Single Notifications Second" &&
      singleSecondIncludeNotifications
    ) {
      if (
        singleSecondWelcomeNotification === null ||
        singleSecondWelcomeNotification === ""
      ) {
        allValid = false;
      }
      if (
        singleSecondNonComplianceNotification === null ||
        singleSecondNonComplianceNotification === ""
      ) {
        allValid = false;
      }
      // if (
      //   singleSecondDailyReminderNotification === null ||
      //   singleSecondDailyReminderNotification === ""
      // ) {
      //   allValid = false;
      // }

      if (singleSecondWelcomeNotification === "new") {
        if (
          !singleSecondNewWelcomeNotification ||
          singleSecondNewWelcomeNotification === null ||
          singleSecondNewWelcomeNotification === undefined
        ) {
          allValid = false;
        } else {
          if (
            singleSecondNewWelcomeNotification === null ||
            singleSecondNewWelcomeNotification === ""
          ) {
            allValid = false;
          }
        }
      }

      // if (singleSecondDailyReminderNotification === "new") {
      //   if (
      //     !singleSecondNewDailyReminderNotification ||
      //     singleSecondNewDailyReminderNotification === null ||
      //     singleSecondNewDailyReminderNotification === undefined
      //   ) {
      //     allValid = false;
      //   } else {
      //     if (
      //       singleSecondNewDailyReminderNotification === null ||
      //       singleSecondNewDailyReminderNotification === ""
      //     ) {
      //       allValid = false;
      //     }
      //   }
      // }

      if (singleSecondNonComplianceTime === null) {
        setSingleSecondNonComplianceTime("");
        allValid = false;
      }

      if (singleSecondNonComplianceNotification === "new") {
        if (
          !singleSecondNewNonComplianceNotification ||
          singleSecondNewNonComplianceNotification === null ||
          singleSecondNewNonComplianceNotification === undefined
        ) {
          allValid = false;
        } else {
          if (
            singleSecondNewNonComplianceNotification === null ||
            singleSecondNewNonComplianceNotification === ""
          ) {
            allValid = false;
          }
        }
      }
    }
    // console.log("allValidCheckEmptyFieldsBeforeSubmit", allValid);
    return allValid;
  }

  const handleWizardForward = () => {
    if (checkEmptyFieldsBeforeSubmit()) {
      setCurrentPageName(pagesToDisplay[currentPage + 1]);
    }
  };

  const handleWizardBack = () => {
    setCurrentPageName(pagesToDisplay[currentPage - 1]);
  };

  const handleWizardSubmit = () => {
    console.log("handleWizardSubmit", checkEmptyFieldsBeforeSubmit());
    if (checkEmptyFieldsBeforeSubmit()) {
      setWizardSubmitting(true);
      // console.log("Submitting data...");
      // send data to server and redirect to client page on success
      if (clientID) {
        sendStudyData({
          clientID: clientID,
          studyStatus: studyStatus,
          studyName: studyName,
          studyDescription: studyDescription,
          studyInformation: studyInformation,
          studyIcon: studyIcon,
          // studyStartDate: studyStartDate,
          // studyAutoStart: studyAutoStart,
          // studyDeadline: studyDeadline,
          // studyBudget: studyBudget,
          selectedLanguages: selectedLanguages,
          // Resources
          resourceIncluded: resourceIncluded,
          resourceName: resourceName,
          resourceDescription: resourceDescription,
          resourceFile: resourceFile,
          resourceFileName: resourceFileName,
          resourceLanguages: resourceLanguages,
          resourceThumbnail: resourceThumbnail,

          // SECOND RESOURCE
          resourceIncludedSecond: resourceIncludedSecond,
          resourceNameSecond: resourceNameSecond,
          resourceDescriptionSecond: resourceDescriptionSecond,
          resourceFileSecond: resourceFileSecond,
          resourceFileNameSecond: resourceFileNameSecond,
          resourceLanguagesSecond: resourceLanguagesSecond,
          resourceThumbnailSecond: resourceThumbnailSecond,

          diaryIncluded: diaryIncluded,
          singleFirstIncluded: singleIncludedFirst,
          singleSecondIncluded: singleIncludedSecond,
          // Diary optional
          diaryName: diaryName,
          diaryDescription: diaryDescription,
          // diaryAppDisplayName: diaryAppDisplayName,
          diaryIcon: diaryIcon,
          dairyStartDate: diaryStartDate,
          diaryAutoStart: diaryAutoStart,
          diaryActiveDays: diaryActiveDays,
          diaryGraceDays: diaryGraceDays,
          diaryBonusDays: diaryBonusDays,
          diaryMinimumDailyCompletes: diaryMinimumDailyCompletes,
          diaryMaximumDailyCompletes: diaryMaximumDailyCompletes,
          diarySurveySystem: diarySurveySystem,
          diarySurveySystemDetails: diarySurveySystemDetails,
          diaryIncludeNotifications: diaryIncludeNotifications,
          diaryWelcomeNotification: diaryWelcomeNotification,
          diaryDailyReminderNotification: diaryDailyReminderNotification,
          diaryDailyReminderTime: diaryDailyReminderTime,
          diaryNonComplianceNotification: diaryNonComplianceNotification,
          diaryNonComplianceTime: diaryNonComplianceTime,
          diaryNewWelcomeNotification: diaryNewWelcomeNotification,
          diaryNewDailyReminderNotification: diaryNewDailyReminderNotification,
          diaryNewNonComplianceNotification: diaryNewNonComplianceNotification,
          // Single first optional
          singleFirstName: singleNameFirst,
          singleFirstDescription: singleDescriptionFirst,
          // singleFirstAppDisplayName: singleAppDisplayNameFirst,
          singleFirstIcon: singleIconFirst,
          singleFirstStartDate: singleStartDateFirst,
          singleFirstAutoStart: singleAutoStartFirst,
          singleFirstSurveySystem: singleSurveySystemFirst,
          singleFirstSurveySystemDetails: singleSurveySystemDetailsFirst,
          singleFirstIncludeNotifications: singleFirstIncludeNotifications,
          singleFirstWelcomeNotification: singleFirstWelcomeNotification,
          // singleFirstDailyReminderNotification:
          //   singleFirstDailyReminderNotification,
          singleFirstNonComplianceNotification:
            singleFirstNonComplianceNotification,
          singleFirstNewWelcomeNotification: singleFirstNewWelcomeNotification,
          // singleFirstNewDailyReminderNotification:
          //   singleFirstNewDailyReminderNotification,
          singleFirstNewNonComplianceNotification:
            singleFirstNewNonComplianceNotification,
          singleFirstNonComplianceNotificationStartDelay:
            singleFirstNonComplianceNotificationStartDelay,
          singleFirstNonComplianceNotificationInterval:
            singleFirstNonComplianceNotificationInBetweenDelay,
          // Single second optional
          singleSecondName: singleNameSecond,
          singleSecondDescription: singleDescriptionSecond,
          // singleSecondAppDisplayName: singleAppDisplayNameSecond,
          singleSecondIcon: singleIconSecond,
          singleSecondStartDate: singleStartDateSecond,
          singleSecondAutoStart: singleAutoStartSecond,
          singleSecondSurveySystem: singleSurveySystemSecond,
          singleSecondSurveySystemDetails: singleSurveySystemDetailsSecond,
          singleSecondIncludeNotifications: singleSecondIncludeNotifications,
          singleSecondWelcomeNotification: singleSecondWelcomeNotification,
          singleFirstNonComplianceTime: singleFirstNonComplianceTime,
          // singleSecondDailyReminderNotification:
          //   singleSecondDailyReminderNotification,
          singleSecondNonComplianceNotification:
            singleSecondNonComplianceNotification,
          singleSecondNewWelcomeNotification:
            singleSecondNewWelcomeNotification,
          // singleSecondNewDailyReminderNotification:
          //   singleSecondNewDailyReminderNotification,
          singleSecondNewNonComplianceNotification:
            singleSecondNewNonComplianceNotification,
          singleSecondNonComplianceNotificationStartDelay:
            singleSecondNonComplianceNotificationStartDelay,
          singleSecondNonComplianceNotificationInterval:
            singleSecondNonComplianceNotificationInBetweenDelay,
          singleSecondNonComplianceTime: singleSecondNonComplianceTime
        })
          .then((response) => {
            // console.log("response", response);
            if (response === "success") {
              // clear local storage for sidebarData
              localStorage.removeItem("sidebarData");
              navigate(`/clients/${clientID}`);
            } else {
              console.log("Error sending data");
              throw new Error("Error sending data");
            }
          })
          .catch((error) => {
            console.log("Error sending data:", error);
            setWizardSubmitError(true);
          });
      } else {
        console.log("Error sending data, No clientID");
        setWizardSubmitError(true);
      }
    }
  };

  const [wscSurveyOptions, setWscSurveyOptions] = useState<FieldDropdown[]>();
  const [wscSurveys, setWscSurveys] = useState<FieldDropdown[]>();

  const [diaryWelcomeNotificationsData, setDiaryWelcomeNotificationsData] =
    useState<ExistingStudyNotification[]>([]);
  const [
    diaryDailyReminderNotificationsData,
    setDiaryDailyReminderNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);
  const [
    diaryNonComplianceNotificationsData,
    setDiaryNonComplianceNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);

  const [singleWelcomeNotificationsData, setSingleWelcomeNotificationsData] =
    useState<ExistingStudyNotification[]>([]);
  const [
    singleNonComplianceNotificationsData,
    setSingleNonComplianceNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);

  const [
    singleDailyReminderNotificationsData,
    setSingleDailyReminderNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);

  useEffect(() => {
    // set notifications based on surveyType
    const getNotificationValue = (
      data: ExistingStudyNotification[] | undefined
    ) => {
      return data && data[0] && data[0].phraseID ? data[0].phraseID : "new";
    };

    setDiaryWelcomeNotification(
      getNotificationValue(diaryWelcomeNotificationsData)
    );
    setDiaryDailyReminderNotification(
      getNotificationValue(diaryDailyReminderNotificationsData)
    );
    setDiaryNonComplianceNotification(
      getNotificationValue(diaryNonComplianceNotificationsData)
    );
    setSingleFirstWelcomeNotification(
      getNotificationValue(singleWelcomeNotificationsData)
    );
    // setSingleFirstDailyReminderNotification(
    //   getNotificationValue(singleDailyReminderNotificationsData)
    // );
    setSingleFirstNonComplianceNotification(
      getNotificationValue(singleNonComplianceNotificationsData)
    );
    setSingleSecondWelcomeNotification(
      getNotificationValue(singleWelcomeNotificationsData)
    );
    // setSingleSecondDailyReminderNotification(
    //   getNotificationValue(singleDailyReminderNotificationsData)
    // );
    setSingleSecondNonComplianceNotification(
      getNotificationValue(singleNonComplianceNotificationsData)
    );
  }, [
    diaryWelcomeNotificationsData,
    diaryDailyReminderNotificationsData,
    diaryNonComplianceNotificationsData,
    singleWelcomeNotificationsData,
    singleDailyReminderNotificationsData,
    singleNonComplianceNotificationsData
  ]);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(true);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [loadingMessageID, setLoadingMessageID] = useState<number>(1);
  const allLoadingMessages = [
    {
      id: 1,
      message: "Loading Phrases"
    },
    {
      id: 2,
      message: "Loading Notifications"
    },
    {
      id: 3,
      message: "Loading WSC surveys"
    }
  ];

  useEffect(() => {
    if (loading && loadingMessageID) {
      // cycle though loading messages every 2 seconds
      const interval = setInterval(() => {
        // look at current messageID and set next messageID
        const currentMessageID = loadingMessageID;
        const nextMessageID = currentMessageID + 1;
        // if nextMessageID is greater than the length of allLoadingMessages, then set to 1
        const newMessageID =
          nextMessageID > allLoadingMessages.length ? 1 : nextMessageID;
        setLoadingMessageID(newMessageID);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [loading, loadingMessageID, allLoadingMessages.length]);

  const [isImageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = kolaImage;
    image.onload = () => setImageLoaded(true);
    image.onerror = () => setImageLoaded(false);
  }, []);

  const [ellipsis, setEllipsis] = useState<string>(".");

  useEffect(() => {
    if (loading || wizardSubmitting) {
      // cycle through ellipsis, adding a dot every second and then resetting
      const interval = setInterval(() => {
        if (ellipsis.length < 3) {
          setEllipsis(ellipsis + ".");
        } else {
          setEllipsis(".");
        }
      }, 800);

      return () => clearInterval(interval);
    }
  }, [loading, wizardSubmitting, ellipsis]);

  // load data
  useEffect(() => {
    setWscSurveys([
      {
        id: "wsc",
        name: "WSC"
      },
      {
        id: "nfield",
        name: "nField"
      },
      {
        id: "viewEQ",
        name: "View EQ"
      },
      {
        id: "custom",
        name: "Custom"
      }
    ]);

    async function fetchWSCSurveys() {
      try {
        const jsonData = await fetchAllWSCSurveys();
        // console.log("jsonData", jsonData);
        // console.log("typeof jsonData", typeof jsonData);
        // Check if jsonData is a string (indicating an error)
        if (typeof jsonData === "string") {
          // console.error("Error fetching data:", jsonData);
          throw new Error("Error fetching data");
        } else {
          setWscSurveyOptions(jsonData);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e3d2642c9940a2cda9e3dad7"
        ]);
        throw error;
      }
    }

    // get languages from local storage using getConfigLanguages
    async function fetchAllConfigLanguages() {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e3dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setAllLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cds9e5dad7"
        ]);
      }
    }

    async function getAllCreateStudyNotifications() {
      try {
        type JsonData = string | ExistingStudyNotifications;

        const jsonData: JsonData = await fetchAllExistingStudyNotifications();
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "a931888cce26c74ac03bbeb81d5fe69f"
          ]);
          throw new Error("Error fetching data");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setDiaryWelcomeNotificationsData([
            ...jsonData.diaryWelcomeNotifications
          ]);
          setDiaryDailyReminderNotificationsData([
            ...jsonData.diaryDailyReminderNotifications
          ]);
          setDiaryNonComplianceNotificationsData([
            ...jsonData.diaryNonComplianceNotifications
          ]);
          setSingleWelcomeNotificationsData([
            ...jsonData.singleWelcomeNotifications
          ]);
          setSingleNonComplianceNotificationsData([
            ...jsonData.singleNonComplianceNotifications
          ]);
          setSingleDailyReminderNotificationsData([
            ...jsonData.singleDailyReminderNotifications
          ]);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching notifications data:",
          error
        );
        throw error;
      }
    }

    // Create an array of promises for each handleData call
    const promises = [
      fetchWSCSurveys(),
      fetchAllConfigLanguages(),
      getAllCreateStudyNotifications()
    ];

    // Use Promise.all to wait for all promises to complete
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
        setLoadingError(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingError(true); // An error occurred
      });
  }, [clientID]);

  useEffect(() => {
    setDiarySurveySystem(wscSurveys ? wscSurveys[0].id : "");
    setSingleSurveySystemFirst(wscSurveys ? wscSurveys[0].id : "");
    setSingleSurveySystemSecond(wscSurveys ? wscSurveys[0].id : "");
  }, [wscSurveys]);

  useEffect(() => {
    setDiarySurveySystemDetails(wscSurveyOptions ? wscSurveyOptions[0].id : "");
    setSingleSurveySystemDetailsFirst(
      wscSurveyOptions ? wscSurveyOptions[0].id : ""
    );
    setSingleSurveySystemDetailsSecond(
      wscSurveyOptions ? wscSurveyOptions[0].id : ""
    );
  }, [wscSurveyOptions]);

  function backCancel() {
    const link = "/clients/" + clientID;
    navigate(link);
  }

  return (
    <div id="add_study_form" className="m-auto col-12 col-lg-8">
      <div className="cancel-button-container">
        <button onClick={() => backCancel()} className="btn btn-primary-cancel">
          <IconLogout className="btnCancel-icon" />
          Cancel
        </button>
      </div>
      {!loading ? (
        !loadingError ? (
          <div>
            {wizardSubmitting ? (
              !wizardSubmitError ? (
                <div className="card card-default add_survey_form_page p-4 d-flex align-items-center justify-content-center">
                  {/* kola image */}
                  <img
                    className="mb-3 mt-5"
                    src={kolaImage}
                    alt="Koala wizard"
                    width="100%"
                    style={{
                      maxWidth: "300px"
                    }}
                  />
                  <h3 className="mb-5">Creating study{ellipsis}</h3>
                </div>
              ) : (
                <h3 className="text-danger">
                  Error submitting study. Please try again.
                </h3>
              )
            ) : currentPageName === "Study Information" ? (
              <StepAddStudyInformation
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                studyStatus={studyStatus}
                studyName={studyName}
                studyDescription={studyDescription}
                studyInformation={studyInformation}
                studyIcon={studyIcon}
                // studyStartDate={studyStartDate}
                // studyAutoStart={studyAutoStart}
                // studyDeadline={studyDeadline}
                // studyBudget={studyBudget}
                diaryIncluded={diaryIncluded}
                diaryName={diaryName}
                diaryDescription={diaryDescription}
                // diaryAppDisplayName={diaryAppDisplayName}
                diaryIcon={diaryIcon}
                diaryAutoStart={diaryAutoStart}
                diaryStartDate={diaryStartDate}
                singleIncludedFirst={singleIncludedFirst}
                singleIncludedSecond={singleIncludedSecond}
                singleNameFirst={singleNameFirst}
                singleDescriptionFirst={singleDescriptionFirst}
                // singleAppDisplayNameFirst={singleAppDisplayNameFirst}
                singleIconFirst={singleIconFirst}
                singleAutoStartFirst={singleAutoStartFirst}
                singleStartDateFirst={singleStartDateFirst}
                singleNameSecond={singleNameSecond}
                singleDescriptionSecond={singleDescriptionSecond}
                // singleAppDisplayNameSecond={singleAppDisplayNameSecond}
                singleAutoStartSecond={singleAutoStartSecond}
                singleStartDateSecond={singleStartDateSecond}
                singleIconSecond={singleIconSecond}
                // Resources
                resourceIncluded={resourceIncluded}
                resourceName={resourceName}
                resourceDescription={resourceDescription}
                resourceThumbnail={resourceThumbnail}
                resourceFile={resourceFile}
                resourceFileName={resourceFileName}
                resourceLanguages={resourceLanguages}
                // Second
                resourceIncludedSecond={resourceIncludedSecond}
                resourceNameSecond={resourceNameSecond}
                resourceDescriptionSecond={resourceDescriptionSecond}
                resourceThumbnailSecond={resourceThumbnailSecond}
                resourceFileSecond={resourceFileSecond}
                resourceFileNameSecond={resourceFileNameSecond}
                resourceLanguagesSecond={resourceLanguagesSecond}
                // Languages
                selectedLanguages={selectedLanguages}
                // Data
                allLanguages={allLanguages}
                // errors
                inputErrors={inputErrors}
              />
            ) : currentPageName === "Diary Configuration" ? (
              <StepDiaryConfiguration
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                diaryName={diaryName}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                diaryActiveDays={diaryActiveDays}
                diaryGraceDays={diaryGraceDays}
                diaryBonusDays={diaryBonusDays}
                diaryMinimumDailyCompletes={diaryMinimumDailyCompletes}
                diaryMaximumDailyCompletes={diaryMaximumDailyCompletes}
                diarySurveySystem={diarySurveySystem}
                diarySurveySystemDetails={diarySurveySystemDetails}
                // Data
                wscSurveyOptionsData={wscSurveyOptions || []}
                wscSurveysData={wscSurveys ? wscSurveys : []}
                // Errors
                inputErrors={inputErrors}
              />
            ) : currentPageName === "Diary Notifications" ? (
              <StepDiaryNotifications
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                diaryName={diaryName}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                diaryIncludeNotifications={diaryIncludeNotifications}
                diaryWelcomeNotification={diaryWelcomeNotification}
                diaryDailyReminderNotification={diaryDailyReminderNotification}
                diaryDailyReminderTime={diaryDailyReminderTime}
                diaryNonComplianceNotification={diaryNonComplianceNotification}
                diaryNonComplianceTime={diaryNonComplianceTime}
                diaryNewWelcomeNotification={
                  diaryNewWelcomeNotification || null
                }
                diaryNewDailyReminderNotification={
                  diaryNewDailyReminderNotification || null
                }
                diaryNewNonComplianceNotification={
                  diaryNewNonComplianceNotification || null
                }
                // Data
                diaryWelcomeNotificationsData={
                  diaryWelcomeNotificationsData
                    ? diaryWelcomeNotificationsData
                    : []
                }
                diaryDailyReminderNotificationsData={
                  diaryDailyReminderNotificationsData
                    ? diaryDailyReminderNotificationsData
                    : []
                }
                diaryNonComplianceNotificationsData={
                  diaryNonComplianceNotificationsData
                    ? diaryNonComplianceNotificationsData
                    : []
                }
                // Errors
                inputErrors={inputErrors}
              />
            ) : currentPageName === "Single Configuration First" ? (
              <StepSingleConfiguration
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                singleSurveySystem={singleSurveySystemFirst}
                singleSurveySystemDetails={singleSurveySystemDetailsFirst}
                setSingleSurveySystemDetails={setSingleSurveySystemDetailsFirst}
                // Data
                wscSurveyOptionsData={wscSurveyOptions ? wscSurveyOptions : []}
                wscSurveysData={wscSurveys ? wscSurveys : []}
                // Errors
                inputErrors={inputErrors}
                singleSurveyFirst={true}
                surveyName={singleNameFirst}
              />
            ) : currentPageName === "Single Notifications First" ? (
              <StepSingleNotifications
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                singleIncludeNotifications={singleFirstIncludeNotifications}
                singleWelcomeNotification={singleFirstWelcomeNotification}
                singleNonComplianceNotification={
                  singleFirstNonComplianceNotification
                }
                // singleDailyReminderNotification={
                //   singleFirstDailyReminderNotification
                // }
                singleNewWelcomeNotification={
                  singleFirstNewWelcomeNotification || null
                }
                singleNonComplianceTime={singleFirstNonComplianceTime}
                singleNewNonComplianceNotification={
                  singleFirstNewNonComplianceNotification || null
                }
                // singleNewDailyReminderNotification={
                //   singleFirstNewDailyReminderNotification || null
                // }
                singleNonComplianceNotificationStartDelay={
                  singleFirstNonComplianceNotificationStartDelay
                }
                singleNonComplianceNotificationInBetweenDelay={
                  singleFirstNonComplianceNotificationInBetweenDelay
                }
                singleSurveyFirst={true}
                // Data
                singleWelcomeNotificationsData={
                  singleWelcomeNotificationsData
                    ? singleWelcomeNotificationsData
                    : []
                }
                singleNonComplianceNotificationsData={
                  singleNonComplianceNotificationsData
                    ? singleNonComplianceNotificationsData
                    : []
                }
                surveyName={singleNameFirst}
                // singleDailyReminderNotificationsData={
                //   singleDailyReminderNotificationsData
                //     ? singleDailyReminderNotificationsData
                //     : []
                // }
                // Errors
                inputErrors={inputErrors}
              />
            ) : currentPageName === "Single Configuration Second" ? (
              <StepSingleConfiguration
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                singleSurveySystem={singleSurveySystemSecond}
                singleSurveySystemDetails={singleSurveySystemDetailsSecond}
                setSingleSurveySystemDetails={
                  setSingleSurveySystemDetailsSecond
                }
                // Data
                wscSurveyOptionsData={wscSurveyOptions ? wscSurveyOptions : []}
                wscSurveysData={wscSurveys ? wscSurveys : []}
                // Errors
                inputErrors={inputErrors}
                singleSurveyFirst={false}
                surveyName={singleNameSecond}
              />
            ) : currentPageName === "Single Notifications Second" ? (
              <StepSingleNotifications
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                singleIncludeNotifications={singleSecondIncludeNotifications}
                singleWelcomeNotification={singleSecondWelcomeNotification}
                singleNonComplianceNotification={
                  singleSecondNonComplianceNotification
                }
                // singleDailyReminderNotification={
                //   singleSecondDailyReminderNotification
                // }
                singleNewWelcomeNotification={
                  singleSecondNewWelcomeNotification || null
                }
                singleNonComplianceTime={singleSecondNonComplianceTime}
                singleNewNonComplianceNotification={
                  singleSecondNewNonComplianceNotification || null
                }
                // singleNewDailyReminderNotification={
                //   singleSecondNewDailyReminderNotification || null
                // }
                singleNonComplianceNotificationStartDelay={
                  singleSecondNonComplianceNotificationStartDelay
                }
                singleNonComplianceNotificationInBetweenDelay={
                  singleSecondNonComplianceNotificationInBetweenDelay
                }
                singleSurveyFirst={false}
                // Data
                singleWelcomeNotificationsData={
                  singleWelcomeNotificationsData
                    ? singleWelcomeNotificationsData
                    : []
                }
                singleNonComplianceNotificationsData={
                  singleNonComplianceNotificationsData
                    ? singleNonComplianceNotificationsData
                    : []
                }
                surveyName={singleNameSecond}
                // singleDailyReminderNotificationsData={
                //   singleDailyReminderNotificationsData
                //     ? singleDailyReminderNotificationsData
                //     : []
                // }
                // Errors
                inputErrors={inputErrors}
              />
            ) : currentPage > totalPages ? (
              <h1>Missing page</h1>
            ) : (
              <h1>Error</h1>
            )}
          </div>
        ) : (
          <ErrorPage
            errorCode="500"
            error="Error loading data"
            debugCode={loadingErrorText}
          />
        )
      ) : (
        <>
          {/* loading */}
          {!isImageLoaded ? (
            <div className="d-flex justify-content-center custom_spinner_container full_page">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div className="card card-default add_survey_form_page p-4 d-flex align-items-center justify-content-center">
              {/* kola image */}
              <img
                className="mb-3 mt-5"
                src={kolaImage}
                alt="Kola wizard"
                width="100%"
                style={{
                  maxWidth: "300px"
                }}
              />
              <h3 className="mb-5">
                {allLoadingMessages[loadingMessageID - 1].message}
                {ellipsis}
              </h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddStudyWizard;
