// Create a modal component that will be used to display details of anything that is passed to it.
import React from "react";

import InformationTooltip from "../InputInformation/InfoInputs.component";
import "./custom-modal.styles.scss";

export type CustomModalProps = React.PropsWithChildren<{
  closeModal: () => void;
  shown: boolean;
  title: string;
  modalSize: string;
  tooltipText?: string;
}>;

export const CustomModal: React.FC<CustomModalProps> = ({
  closeModal,
  shown,
  title,
  modalSize,
  tooltipText,
  children // Children prop will contain the content passed in
}) => {
  return (
    <>
      {shown && (
        <div className={`modal custom-modal ${shown ? "show" : "hide"}`}>
          <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <div className="modal-header">
                {tooltipText && (
                  <InformationTooltip
                    tooltipText={tooltipText}
                    position="right"
                  />
                )}
                <h4 className="modal-title">{title}</h4>
              </div>
              <div className="modal-body">{children}</div>
              {/* <div className="modal-footer">
								<button
									type="button"
									className="btn btn-danger"
									onClick={closeModal}
								>
									Close
								</button>
							</div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
