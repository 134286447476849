import React from "react";
import {
  IconKeyboard,
  IconResources,
  IconUploadAlt
} from "../../../../assets/images/icons/icons";
import "./chatTabs.scss";

export type ChatTabsProps = {
  tabClicked: string;
  setTabClicked: (tab: string) => void;
};

const ChatTabs: React.FC<ChatTabsProps> = ({ tabClicked, setTabClicked }) => {
  return (
    <div className="tabs_container">
      <ul className="nav nav-underline">
        {/* Keyboard Tab */}
        <li className="nav-item" onClick={() => setTabClicked("keyboard")}>
          <span
            className={`nav-link chat_tab ${
              tabClicked === "keyboard" ? "is_active" : ""
            }`}
          >
            <div className="tooltip_container">
              <IconKeyboard className="icon icon_black" />
              <span className="tooltip_texts">Type message</span>
            </div>
          </span>
        </li>

        {/* Resources Tab */}
        <li className="nav-item" onClick={() => setTabClicked("resources")}>
          <span
            className={`nav-link chat_tab ${
              tabClicked === "resources" ? "is_active" : ""
            }`}
          >
            <div className="tooltip_container">
              <IconResources />
              <span className="tooltip_texts">Send Resource</span>
            </div>
          </span>
        </li>

        {/* Upload Tab */}
        <li className="nav-item" onClick={() => setTabClicked("upload")}>
          <span
            className={`nav-link chat_tab ${
              tabClicked === "upload" ? "is_active" : ""
            }`}
          >
            <div className="tooltip_container">
              <IconUploadAlt className="icon upload_icon" />
              <span className="tooltip_texts">Send Image/Video</span>
            </div>
          </span>
        </li>

        {/* Emoji Tab */}
        {/* <li className="nav-item" onClick={() => setTabClicked("emoji")}>
          <span
            className={`nav-link chat_tab ${
              tabClicked === "emoji" ? "is_active" : ""
            }`}
          >
            <IconEmoji className="icon icon_black emoji_icon" />
          </span>
        </li> */}
      </ul>
    </div>
  );
};

export default ChatTabs;
