import React from "react";
import Swal from "sweetalert2";
import { Country, Researcher } from "../../types";

interface ViewResearcherModalHeaderProps {
  researcher: Researcher;
  jsonCountriesData: Country[];
}

const ViewResearcherModalHeader: React.FC<ViewResearcherModalHeaderProps> = ({
  researcher,
  jsonCountriesData
}) => {
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      Swal.fire({
        text: "Copied to clipboard!",
        showConfirmButton: false,
        timer: 800
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="modal-header">
      <div className="container-fluid">
        <div className="row">
          <h2>
            {researcher.first_name} {researcher.surname}
          </h2>
          <h5>{researcher.language_iso}</h5>
        </div>
        <div className="row">
          <p>
            <strong>
              {/* click to send email */}
              <span
                onClick={() => copyToClipboard(researcher.email)}
                style={{ cursor: "pointer" }}
              >
                {researcher.email}
              </span>
              <br />
              {/* click to call */}
              <span
                onClick={() => copyToClipboard(researcher.full_mobile)}
                style={{ cursor: "pointer" }}
              >
                {researcher.full_mobile}
              </span>{" "}
              |{" "}
              {
                // get the country name from the jsonCountriesData
                jsonCountriesData.find(
                  (country) => country.iso_code === researcher.country_iso
                )?.name
              }
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewResearcherModalHeader;
