import React from "react";
import { Timezone } from "../../../types";
import "./timezoneSwitcher.scss";

interface TimezoneSwitcherProps {
  timezone: string;
  researcherTimezone: Timezone | null;
  handleChangeTimezone: (timezone: string) => void;
  tableType: string;
}

const TimezoneSwitcher: React.FC<TimezoneSwitcherProps> = ({
  timezone,
  handleChangeTimezone,
  researcherTimezone,
  tableType
}) => {
  return (
    <div id="timezone_switcher" className="compact_buttons_switcher">
      <button
        className={`btn btn-primary ${
          timezone === "ResearcherTimezone" ? "active" : "inactive"
        }`}
        onClick={() => handleChangeTimezone("ResearcherTimezone")}
      >
        {researcherTimezone ? researcherTimezone.name : "NO TIMEZONE"}
      </button>
      <button
        className={`btn btn-primary ${
          timezone === "UTC" ? "active" : "inactive"
        }`}
        onClick={() => handleChangeTimezone("UTC")}
      >
        UTC
      </button>
      <button
        className={`btn btn-primary ${
          timezone === "Participant's Timezone" ? "active" : "inactive"
        }`}
        onClick={() => handleChangeTimezone("Participant's Timezone")}
      >
        {tableType && tableType === "leadsTable"
          ? "Lead"
          : tableType === "researchersTable"
            ? "Researcher"
            : "Participant"}
      </button>
    </div>
  );
};

export default TimezoneSwitcher;
