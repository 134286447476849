import React from "react";
import { usePermissions } from "../../contexts/UserContext";

interface ViewParticipantModalFooterProps {
  editing: boolean;
  viewingVariables: boolean;
  viewingHistory: boolean;
  handleSave: () => void;
  handleCancel: () => void;
  setEditing: (editing: boolean) => void;
  setViewParticipantVariables: (viewingVariables: boolean) => void;
  setViewParticipantHistory: (viewingHistory: boolean) => void;
}

const ViewParticipantModalFooter: React.FC<ViewParticipantModalFooterProps> = ({
  editing,
  viewingVariables,
  viewingHistory,
  handleSave,
  handleCancel,
  setEditing,
  setViewParticipantVariables,
  setViewParticipantHistory
}) => {
  const { hasPermission } = usePermissions();

  return (
    <div className="modal-footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex justify-content-end">
            {editing && (
              <button
                className="btn btn-primary me-2"
                onClick={() => handleSave()}
              >
                Save
              </button>
            )}
            {editing ? (
              <button
                className="btn btn-secondary"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            ) : (
              <>
                {viewingVariables || viewingHistory ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setViewParticipantVariables(false);
                      setViewParticipantHistory(false);
                    }}
                  >
                    Back
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => setViewParticipantHistory(true)}
                    >
                      View History
                    </button>
                    <button
                      className="btn btn-primary ms-2"
                      onClick={() => setViewParticipantVariables(true)}
                    >
                      View Variables
                    </button>
                    {(hasPermission("subject") ||
                      hasPermission("participant", "all") ||
                      hasPermission("participant", "write") ||
                      hasPermission("chat", "all") ||
                      hasPermission("chat", "write")) && (
                      <button
                        className="btn btn-primary ms-2"
                        onClick={() => setEditing(true)}
                      >
                        Edit
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewParticipantModalFooter;
