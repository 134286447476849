import React from "react";
import ConfirmDetailsModal from "../ConfirmDetailsModal/confirmDetailsModal.component";
import TfaModal from "../MFAModal/tfaModal.component";

type TfaModalHandler = {};

interface TfaModalHandlerProps {
  mfaEnabled: boolean;
  setMfaToggled: React.Dispatch<React.SetStateAction<boolean>>;
  setShowQrModal: React.Dispatch<React.SetStateAction<boolean>>;
  showQrModal: boolean;
  showConfirmationModal: boolean;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TfaModalHandler: React.FC<TfaModalHandlerProps> = ({
  mfaEnabled,
  setMfaToggled,
  setShowQrModal,
  showQrModal,
  showConfirmationModal,
  setShowConfirmationModal
}) => {
  return (
    <>
      {!showQrModal ? (
        <>
          <ConfirmDetailsModal
            mfaEnabled={mfaEnabled}
            setMfaToggled={setMfaToggled}
            setShowQrModal={setShowQrModal}
            showQrModal={showQrModal}
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        </>
      ) : (
        <>
          <TfaModal
            setShowQrModal={setShowQrModal}
            showQrModal={showQrModal}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        </>
      )}
    </>
  );
};

export default TfaModalHandler;
