import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  IconFlag,
  IconSettings
} from "../../../../../src/assets/images/icons/icons";
import {
  flagChannelParticipant,
  removeParticipantFromChannel
} from "../../../../models/chat.model";
import { fetchSingleParticipantByID } from "../../../../models/participant.model";
import { Country, Language, Timezone } from "../../../../types";
import { useOutsideClick } from "../../../../utilities/utils";
import ViewParticipantModal from "../../../ViewParticipantModal/viewParticipantModal";

import { usePermissions } from "../../../../contexts/UserContext";

export type SingleParticipantProps = {
  participantName: string;
  participantLanguage: string;
  participantId: string;
  participantFlagged: boolean;
  participantUnread: number;
  channelID: string;
  selectedChannelType: string;
  handleSelectParticipant: (participantId: string) => void;
  onSelectParticipants?: (participantId: string) => void;
  checkboxSelectedParticipants?: string[];
  isSelected?: boolean;
  onParticipantRemove?: (participantId: string) => void;
  onToggleFlag?: (participantId: string) => void;
  countries: Country[];
  languages: Language[];
  timezones: Timezone[];
  // dataRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const SingleParticipant: React.FC<SingleParticipantProps> = ({
  participantName,
  participantLanguage,
  participantId,
  participantFlagged,
  participantUnread,
  channelID,
  selectedChannelType,
  handleSelectParticipant,
  onSelectParticipants,
  checkboxSelectedParticipants,
  isSelected,
  onParticipantRemove,
  onToggleFlag,
  countries,
  languages,
  timezones
  // dataRefresh,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [viewParticipantModal, setViewParticipantModal] =
    useState<boolean>(false);

  const { clientID, studyID } = useParams(); // Get from URL

  const { hasPermission } = usePermissions();
  const dropdownRef = useRef(null);

  const handleParticipantRemove = async () => {
    if (clientID && channelID && participantId && studyID) {
      // Assuming the function expects an array of participant IDs
      const result = await removeParticipantFromChannel(
        clientID,
        channelID,
        [participantId], // Wrapping participantId in an array
        studyID
      );

      // You might want to check the result of the operation and handle it accordingly
      if (result) {
        console.log("Participant removed successfully");
        onParticipantRemove && onParticipantRemove(participantId);
        Swal.fire({
          icon: "success",
          title: "Participant removed successfully",
          text: "Participant removed from channel",
          showConfirmButton: false,
          confirmButtonColor: "#3085d6",
          timer: 1500
        });
        // Perform any state updates or UI notifications here
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to remove Participant",
          text: "Something went wrong!",
          showConfirmButton: false,
          timer: 1500
        });
        console.error("Error removing participant from channel");
        // Handle the error case here
      }
    } else {
      console.error("Missing required data for removing participant");
      // Handle the case where some IDs are not provided
    }
  };

  const handleParticipantFlag = async () => {
    try {
      if (clientID && channelID && participantId && studyID) {
        const flagged: any = await flagChannelParticipant(
          clientID,
          channelID,
          studyID,
          participantId
        );

        if (flagged.status === 202) {
          Swal.fire({
            icon: "success",
            title: `Participant ${
              participantFlagged ? "un-flagged" : "flagged"
            } successfully`,
            showConfirmButton: false,
            toast: true,
            position: "top-end",
            timer: 1500
          });

          setDropdownOpen(false);
          onToggleFlag && onToggleFlag(participantId);
          // dataRefresh(true);
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to flag Participant",
            showConfirmButton: false,
            toast: true,
            position: "top-end",
            timer: 1500
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useOutsideClick(dropdownRef, () => {
    if (dropdownOpen) setDropdownOpen(false);
  });

  const toggleCheckboxSelection = () => {
    onSelectParticipants && onSelectParticipants(participantId);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleViewParticipant = () => {
    setViewParticipantModal(true);
    fetchParticipantDetails();
  };

  const fetchParticipantDetails = async () => {
    try {
      if (clientID) {
        const results = await fetchSingleParticipantByID(
          clientID,
          participantId
        );

        console.log(results);
      }
    } catch (error: any) {
      console.log("There was a problem fetching the participant details");
    }
  };

  const handleCloseModal = () => {
    setViewParticipantModal(false);
    // delay of 0.5 seconds
  };

  return (
    <>
      <div
        className={`participant_single ${
          isSelected ? "container_selected" : ""
        } ${participantUnread > 0 ? "unread-container" : ""}`}
      >
        <div className="participant_left">
          {(hasPermission("subject") ||
            hasPermission("chat", "all") ||
            hasPermission("chat", "delete")) && (
            <input
              className="unread_checkbox"
              type="checkbox"
              placeholder="Unread"
              id={`participant_${participantId}`}
              onChange={() => {
                onSelectParticipants && onSelectParticipants(participantId);
              }}
              checked={
                checkboxSelectedParticipants &&
                checkboxSelectedParticipants.includes(participantId)
              }
            ></input>
          )}
        </div>
        <div
          className="participant_middle"
          onClick={() => {
            handleSelectParticipant(participantId);
          }}
        >
          <div className="participant_details">
            {participantUnread > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {participantUnread}
                <span className="visually-hidden">unread messages</span>
              </span>
              // <div className="participant_unread">
              //   <span className="cus-badge">{participantUnread}</span>
              // </div>
            )}
            <label
            // htmlFor={`participant_${participantName}`}
            >
              {participantName}
            </label>

            <label
              className="participant_language"
              // htmlFor={`participant_${participantName}`}
            >
              {participantLanguage}
            </label>
          </div>
        </div>

        <div className="participant_right">
          <div className="dropdown" ref={dropdownRef}>
            <div className="participant_icons">
              {participantFlagged && <IconFlag className="icon" />}
              <IconSettings
                className="icon clickable"
                onClick={() => {
                  toggleDropdown();
                }}
              />
            </div>

            <ul
              className={`dropdown-menu channel-participant ${
                dropdownOpen ? "show" : ""
              }`}
            >
              <li>
                {(hasPermission("subject") ||
                  hasPermission("chat", "all") ||
                  hasPermission("chat", "delete")) && (
                  <a
                    className="dropdown-item"
                    onClick={handleParticipantRemove}
                  >
                    Remove Participant
                  </a>
                )}
                <a className="dropdown-item" onClick={handleViewParticipant}>
                  View Participant
                </a>
                {(hasPermission("subject") ||
                  hasPermission("chat", "all") ||
                  hasPermission("chat", "write")) &&
                  selectedChannelType !== "Focus Group" && (
                    <a
                      className="dropdown-item"
                      onClick={handleParticipantFlag}
                    >
                      {participantFlagged
                        ? "Un-flag Participant"
                        : "Flag Participant"}
                    </a>
                  )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {clientID && viewParticipantModal && (
        <ViewParticipantModal
          participantID={participantId}
          closeParticipantModal={() => handleCloseModal()}
          shown={viewParticipantModal}
          jsonLanguagesData={languages}
          jsonCountriesData={countries}
          jsonTimezonesData={timezones}
          tableType={"channelParticipants"}
          clientID={clientID}
          surveyID={undefined}
          studyID={studyID}
        />
      )}
    </>
  );
};

export default SingleParticipant;
