import React, { useEffect, useState } from "react";
import googleAuthLogo from "../../assets/images/logo/google-auth.png";
import { getKeyUriLocalStorage } from "../../utilities/mfaHandler.util";
import "./tfaModal.scss";
var QRCode = require("qrcode");

type TfaModal = {};

interface TfaModalProps {
  setShowQrModal: React.Dispatch<React.SetStateAction<boolean>>;
  showQrModal: boolean;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TfaModal: React.FC<TfaModalProps> = ({
  setShowQrModal,
  showQrModal,
  setShowConfirmationModal
}) => {
  // QR CODE
  const [QR_CODE, setQR_CODE] = useState("");

  const handleClose = () => {
    setShowQrModal(false);
    setShowConfirmationModal(false);
  };

  const generateQR = async (text: any): Promise<string> => {
    try {
      return await QRCode.toDataURL(text);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  useEffect(() => {
    const generateAndSetQRCode = async () => {
      try {
        const uriKey = await getKeyUriLocalStorage();
        const generatedQRCode = await generateQR(uriKey);
        setQR_CODE(generatedQRCode);
      } catch (error) {
        // Handle any errors that may occur during QR code generation
        console.error("Error generating QR code:", error);
      }
    };

    generateAndSetQRCode();
  }, []);

  return (
    <div className="tfa_modal_container">
      {/* MODAL FOR MFA */}

      <div className={`modal show_modal modal_container`} tabIndex={-1}>
        <div className={`modal-dialog modal-dialog-centered `}>
          <div className="modal-content">
            <div className="modal-header">
              <img src={googleAuthLogo} alt="123" />
              <h1 className="modal-title fs-5" id="2faLabel">
                {/* Multi-Factor Authentication */}
                Google Authenticator
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <ol>
                  <li>Install Google Authenticator on your phone</li>
                  <li>Open the Google Authenticator app</li>
                  <li>Tap the "+" button in the bottom right corner.</li>
                  <li>Choose the option “Scan a QR code”</li>
                  <li>
                    Point your phone's camera at the QR code provided below
                  </li>

                  <div className="d-flex justify-content-center">
                    {/* add QR_CODE as a the img src */}
                    <img src={QR_CODE} alt="QR Code" />
                  </div>

                  <li>
                    After scanning, your account, labeled{" "}
                    <span
                      style={{
                        color: "#0d6efd"
                      }}
                    >
                      MDI-RMS: [your email]
                    </span>
                    , will appear in the app with an authentication code.{" "}
                  </li>
                </ol>
                <p className="p_under_list">
                  You can now use Multi-factor Authentication on the RMS login
                  with the generated code on your app.{" "}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn_cancel"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TfaModal;
