import React from "react";
import "./categories.scss";

export type SingleCategoryProps = {
  isSelected?: boolean;
  categoryID: string;
  handleCategoryClick: (categoryID: string) => void;
  categoryName: string;
};

const SingleCategory: React.FC<SingleCategoryProps> = ({
  isSelected,
  categoryID,
  handleCategoryClick,
  categoryName
}) => {
  return (
    <>
      <div
        key={categoryID}
        className={`single_category_container ${
          isSelected ? "container_selected" : ""
        }`}
        // on hover display right side
        onClick={() => {
          handleCategoryClick(categoryID);
        }}
      >
        <div className={`category_right_side hovered`}>
          <div className="category_right_side_title">{categoryName}</div>
        </div>
      </div>
    </>
  );
};

export default SingleCategory;
