import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  ConfigAppSetting,
  ConfigMobileApp,
  ConfigMobileAppAPIType,
  ConfigMobileAppStyles,
  ConfigMobileAppType
} from "../types";

type S3AppKeyResponse = {
  signedURL: string;
};

// #region typeCheckers

// #region isConfigMobileApp
function isConfigMobileApp(obj: unknown): obj is ConfigMobileApp {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }
  // const validID: boolean = typeof obj.id === "string";
  const validUAI: boolean =
    "unique_app_id" in obj && typeof obj.unique_app_id === "string";
  const validName: boolean =
    "app_name" in obj && typeof obj.app_name === "string";
  const validClientID = "client_id" in obj && typeof obj.client_id === "string";
  const validStatus: boolean =
    "status" in obj && typeof obj.status === "string";

  const AllValid: boolean =
    validUAI && validName && validClientID && validStatus;

  if (!AllValid) {
    console.log(obj);
    let errors = [];

    // if (!validID) {
    //   if (!obj.hasOwnProperty("id")) {
    //     errors.push("missing id");
    //     let displayMessage = `<strong>Please contact support</strong><br><br>`;

    //     Swal.fire({
    //       icon: "error",
    //       title: `Invalid Mobile App`,
    //       html: displayMessage + errors.join("<br>"),
    //       showConfirmButton: true,
    //       confirmButtonColor: "#3085d6"
    //     });
    //     return false;
    //   } else {
    //     errors.push(`Invalid type for id`);
    //   }
    // }

    if (!validName) {
      if (!obj.hasOwnProperty("app_name")) {
        errors.push("missing app_name");
      } else {
        errors.push(`Invalid type for app_name`);
      }
    }

    if (!validUAI) {
      if (!obj.hasOwnProperty("unique_app_id")) {
        errors.push("missing unique_app_id");
      } else {
        errors.push(`Invalid type for unique_app_id`);
      }
    }

    if (!validClientID) {
      if (!obj.hasOwnProperty("client_id")) {
        errors.push("missing client_id");
      } else {
        errors.push(`Invalid type for client_id`);
      }
    }

    if (!validStatus) {
      if (!obj.hasOwnProperty("status")) {
        errors.push("missing status");
      } else {
        errors.push(`Invalid type for status`);
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Mobile App`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isConfigMobileApp

// #region isConfigAppSetting
function isConfigAppSetting(obj: unknown): obj is ConfigAppSetting {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const validID =
    "_id" in obj &&
    typeof obj._id === "object" &&
    obj._id !== null &&
    "$oid" in obj._id &&
    typeof obj._id.$oid === "string";

  const validKey = "key" in obj && typeof obj.key === "string";
  const validName = "name" in obj && typeof obj.name === "string";
  const validValue = "value" in obj && typeof obj.value === "string";
  const validScreen = "screen" in obj && typeof obj.screen === "string";
  const validFieldType =
    "_field_type" in obj && typeof obj._field_type === "string";

  const AllValid =
    validID &&
    validKey &&
    validName &&
    validValue &&
    validScreen &&
    validFieldType;

  if (!AllValid) {
    console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("_id")) {
        errors.push("missing _id");
      } else {
        errors.push(`Invalid type for _id`);
      }
    }

    if (!validKey) {
      if (!obj.hasOwnProperty("key")) {
        errors.push("missing key");
      } else {
        errors.push(`Invalid type for key`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Invalid type for name`);
      }
    }

    if (!validValue) {
      if (!obj.hasOwnProperty("value")) {
        errors.push("missing value");
      } else {
        errors.push(`Invalid type for value`);
      }
    }

    if (!validScreen) {
      if (!obj.hasOwnProperty("screen")) {
        errors.push("missing screen");
      } else {
        errors.push(`Invalid type for screen`);
      }
    }

    if (!validFieldType) {
      if (!obj.hasOwnProperty("_field_type")) {
        errors.push("missing _field_type");
      } else {
        errors.push(`Invalid type for _field_type`);
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Data Item`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isConfigAppSetting

// #region isConfigMobileAppAPIType
function isConfigMobileAppAPIType(obj: unknown): obj is ConfigMobileAppAPIType {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const validUAI =
    "unique_app_id" in obj && typeof obj.unique_app_id === "string";
  const validName = "app_name" in obj && typeof obj.app_name === "string";
  const validClientID = "client_id" in obj && typeof obj.client_id === "string";
  const validStatus = "status" in obj && typeof obj.status === "string";
  const validSettings =
    "settings" in obj &&
    Array.isArray(obj.settings) &&
    obj.settings.every((item) => isConfigAppSetting(item));
  const validIOSLink = "ios_link" in obj && typeof obj.ios_link === "string";
  const validAndroidLink =
    "android_link" in obj && typeof obj.android_link === "string";

  const AllValid =
    validUAI &&
    validName &&
    validClientID &&
    validStatus &&
    validSettings &&
    validIOSLink &&
    validAndroidLink;

  if (!AllValid) {
    console.log(obj);
    let errors = [];

    if (!validUAI) {
      if (!obj.hasOwnProperty("unique_app_id")) {
        errors.push("missing unique_app_id");
      } else {
        errors.push(`Invalid type for unique_app_id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("app_name")) {
        errors.push("missing app_name");
      } else {
        errors.push(`Invalid type for app_name`);
      }
    }

    if (!validClientID) {
      if (!obj.hasOwnProperty("client_id")) {
        errors.push("missing client_id");
      } else {
        errors.push(`Invalid type for client_id`);
      }
    }

    if (!validStatus) {
      if (!obj.hasOwnProperty("status")) {
        errors.push("missing status");
      } else {
        errors.push(`Invalid type for status`);
      }
    }

    if (!validSettings) {
      if (!obj.hasOwnProperty("settings")) {
        errors.push("missing settings");
      } else {
        // errors.push(`Invalid type for settings`);
      }
    }

    if (!validIOSLink) {
      if (!obj.hasOwnProperty("ios_link")) {
        errors.push("missing ios_link");
      } else {
        errors.push(`Invalid type for ios_link`);
      }
    }

    if (!validAndroidLink) {
      if (!obj.hasOwnProperty("android_link")) {
        errors.push("missing android_link");
      } else {
        errors.push(`Invalid type for android_link`);
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Mobile App`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });

    return false;
  }

  return AllValid;
}
// #endregion isConfigMobileAppAPIType

// #region isS3KeyResponse
function isS3KeyResponse(obj: unknown): obj is S3AppKeyResponse {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const validSignedURL =
    "signedURL" in obj && typeof obj.signedURL === "string";

  return validSignedURL;
}
// #endregion isS3KeyResponse

// #endregion typeCheckers

// #region data transformation functions

// #region convertConfigAppSettingToConfigMobileAppStyles
function convertConfigAppSettingToConfigMobileAppStyles(
  data: ConfigAppSetting[]
): ConfigMobileAppStyles | undefined {
  const appLogo = data.find((item) => item.key === "app_logo");
  const appIcon = data.find((item) => item.key === "app_icon");
  const inputTextColor = data.find((item) => item.key === "input_text_color");
  const primaryColor01 = data.find((item) => item.key === "primary_color_01");
  const primaryColor02 = data.find((item) => item.key === "primary_color_02");
  const primaryColor03 = data.find((item) => item.key === "primary_color_03");
  const highlightColor = data.find((item) => item.key === "highlight_color");
  const highlightTextColor = data.find(
    (item) => item.key === "highlight_text_color"
  );
  const bgImg01 = data.find((item) => item.key === "bg_img_01");
  // const bgImg02 = data.find((item) => item.key === "bg_img_02");
  // const bgImg03 = data.find((item) => item.key === "bg_img_03");
  const bgColor01 = data.find((item) => item.key === "bg_color_01");
  const bgColor02 = data.find((item) => item.key === "bg_color_02");
  const bgColor03 = data.find((item) => item.key === "bg_color_03");
  const btnColor01 = data.find((item) => item.key === "btn_color_01");
  const btnText01 = data.find((item) => item.key === "btn_text_01");
  const btnBorder01 = data.find((item) => item.key === "btn_border_01");
  const btnColor02 = data.find((item) => item.key === "btn_color_02");
  const btnText02 = data.find((item) => item.key === "btn_text_02");
  const btnBorder02 = data.find((item) => item.key === "btn_border_02");
  const btnColor03 = data.find((item) => item.key === "btn_color_03");
  const btnText03 = data.find((item) => item.key === "btn_text_03");
  const btnBorder03 = data.find((item) => item.key === "btn_border_03");
  const inputColor01 = data.find((item) => item.key === "input_color_01");
  const inputBorder01 = data.find((item) => item.key === "input_border_01");
  // const bgImgBtnChat = data.find((item) => item.key === "bg_img_btn_chat");
  // const bgImgBtnInfo = data.find((item) => item.key === "bg_img_btn_info");
  // const bgImgBtnRewards = data.find(
  //   (item) => item.key === "bg_img_btn_rewards"
  // );
  const firebaseKeyIOS = data.find((item) => item.key === "firebase_key_ios");
  const taskIcon = data.find((item) => item.key === "task_icon");
  const resourceIcon = data.find((item) => item.key === "resources_icon");
  const rewardIcon = data.find((item) => item.key === "rewards_icon");
  const alertIcon = data.find((item) => item.key === "alert_icon");
  const chatIcon = data.find((item) => item.key === "chat_icon");
  const infoIcon = data.find((item) => item.key === "info_icon");

  if (
    appLogo &&
    primaryColor01 &&
    primaryColor02 &&
    primaryColor03 &&
    highlightColor &&
    highlightTextColor &&
    bgImg01 &&
    // bgImg02 &&
    // bgImg03 &&
    bgColor01 &&
    bgColor02 &&
    bgColor03 &&
    btnColor01 &&
    btnText01 &&
    btnBorder01 &&
    btnColor02 &&
    btnText02 &&
    btnBorder02 &&
    btnColor03 &&
    btnText03 &&
    btnBorder03 &&
    inputColor01 &&
    inputBorder01 &&
    // bgImgBtnChat &&
    // bgImgBtnInfo &&
    // bgImgBtnRewards &&
    firebaseKeyIOS &&
    taskIcon &&
    resourceIcon &&
    rewardIcon &&
    alertIcon &&
    chatIcon &&
    infoIcon &&
    appIcon &&
    inputTextColor
  ) {
    return {
      appLogo,
      primaryColor01,
      primaryColor02,
      primaryColor03,
      highlightColor,
      highlightTextColor,
      bgImg01,
      // bgImg02,
      // bgImg03,
      bgColor01,
      bgColor02,
      bgColor03,
      btnColor01,
      btnText01,
      btnBorder01,
      btnColor02,
      btnText02,
      btnBorder02,
      btnColor03,
      btnText03,
      btnBorder03,
      inputColor01,
      inputBorder01,
      // bgImgBtnChat,
      // bgImgBtnInfo,
      // bgImgBtnRewards,
      firebaseKeyIOS,
      taskIcon,
      resourceIcon,
      rewardIcon,
      alertIcon,
      chatIcon,
      infoIcon,
      appIcon,
      inputTextColor
    };
  } else {
    let missingFields = [];
    if (!appLogo) {
      missingFields.push("app_logo");
    }
    if (!appIcon) {
      missingFields.push("app_icon");
    }
    if (!primaryColor01) {
      missingFields.push("primary_color_01");
    }
    if (!primaryColor02) {
      missingFields.push("primary_color_02");
    }
    if (!primaryColor03) {
      missingFields.push("primary_color_03");
    }
    if (!highlightColor) {
      missingFields.push("highlight_color");
    }
    if (!highlightTextColor) {
      missingFields.push("highlight_text_color");
    }
    if (!bgImg01) {
      missingFields.push("bg_img_01");
    }
    // if (!bgImg02) {
    //   missingFields.push("bg_img_02");
    // }
    // if (!bgImg03) {
    //   missingFields.push("bg_img_03");
    // }
    if (!bgColor01) {
      missingFields.push("bg_color_01");
    }
    if (!bgColor02) {
      missingFields.push("bg_color_02");
    }
    if (!bgColor03) {
      missingFields.push("bg_color_03");
    }
    if (!btnColor01) {
      missingFields.push("btn_color_01");
    }
    if (!btnText01) {
      missingFields.push("btn_text_01");
    }
    if (!btnBorder01) {
      missingFields.push("btn_border_01");
    }
    if (!btnColor02) {
      missingFields.push("btn_color_02");
    }
    if (!btnText02) {
      missingFields.push("btn_text_02");
    }
    if (!btnBorder02) {
      missingFields.push("btn_border_02");
    }
    if (!btnColor03) {
      missingFields.push("btn_color_03");
    }
    if (!btnText03) {
      missingFields.push("btn_text_03");
    }
    if (!btnBorder03) {
      missingFields.push("btn_border_03");
    }
    if (!inputColor01) {
      missingFields.push("input_color_01");
    }
    if (!inputBorder01) {
      missingFields.push("input_border_01");
    }
    // if (!bgImgBtnChat) {
    //   missingFields.push("bg_img_btn_chat");
    // }
    // if (!bgImgBtnInfo) {
    //   missingFields.push("bg_img_btn_info");
    // }
    // if (!bgImgBtnRewards) {
    //   missingFields.push("bg_img_btn_rewards");
    // }
    if (!firebaseKeyIOS) {
      missingFields.push("firebase_key_ios");
    }
    if (!taskIcon) {
      missingFields.push("task_icon");
    }
    if (!resourceIcon) {
      missingFields.push("resources_icon");
    }
    if (!rewardIcon) {
      missingFields.push("rewards_icon");
    }
    if (!alertIcon) {
      missingFields.push("alert_icon");
    }
    if (!chatIcon) {
      missingFields.push("chat_icon");
    }
    if (!infoIcon) {
      missingFields.push("info_icon");
    }
    if (!inputTextColor) {
      missingFields.push("input_text_color");
    }

    console.log("Missing fields:", missingFields);

    return undefined;
  }
}
// #endregion convertConfigAppSettingToConfigMobileAppStyles

// #region getTransformedSettingsWithImages
async function getTransformedSettingsWithImages(
  clientID: string,
  settings: ConfigAppSetting[]
): Promise<
  | {
      rStatus: "success";
      rData: ConfigAppSetting[];
    }
  | {
      rStatus: "error";
      rMessage: string;
    }
> {
  const indexOfAppLogo = settings.findIndex((item) => item.key === "app_logo");
  const appLogo = settings[indexOfAppLogo].value;
  console.log(appLogo);
  if (checkIfBase64(appLogo)) {
    const response = await uploadFile(appLogo, clientID);
    if (response.rStatus === "error") {
      return {
        rStatus: "error",
        rMessage: response.rMessage
      };
    }
    const appLogoURL = response.rMessage;
    console.log(appLogoURL);
    settings[indexOfAppLogo].value = appLogoURL;
  }

  const indexOfAppIcon = settings.findIndex((item) => item.key === "app_icon");
  const appIcon = settings[indexOfAppIcon].value;
  console.log(appIcon);
  if (checkIfBase64(appIcon)) {
    const response = await uploadFile(appIcon, clientID);
    if (response.rStatus === "error") {
      return {
        rStatus: "error",
        rMessage: response.rMessage
      };
    }
    const appIconURL = response.rMessage;
    console.log(appIconURL);
    settings[indexOfAppIcon].value = appIconURL;
  }

  const indexOfBackgroundImage01 = settings.findIndex(
    (item) => item.key === "bg_img_01"
  );
  const bgImg01 = settings[indexOfBackgroundImage01].value;
  console.log(bgImg01);
  if (checkIfBase64(bgImg01)) {
    const response = await uploadFile(bgImg01, clientID);
    if (response.rStatus === "error") {
      return {
        rStatus: "error",
        rMessage: response.rMessage
      };
    }
    const bgImg01URL = response.rMessage;
    console.log(bgImg01URL);
    settings[indexOfBackgroundImage01].value = bgImg01URL;
  }

  return {
    rStatus: "success",
    rData: settings
  };
}
// #endregion getTransformedSettingsWithImages

// #endregion data transformation functions

// #region fetchAllMobileApps
// A post request to fetch all mobile apps
export async function fetchAllMobileApps(): Promise<
  ConfigMobileApp[] | string
> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/getClientApps/get",
      "POST",
      {
        body: {}
      }
    );

    console.log(response);

    if (response.status === 200 && response.data) {
      console.log(Array.isArray(response.data));
      console.log(response.data);
      if (
        Array.isArray(response.data) &&
        response.data.every((item) => isConfigMobileApp(item))
      ) {
        return response.data;
      } else {
        return "Invalid data";
      }
    }

    if (response.status === 204) {
      return [];
    }

    if (response.status === 403) {
      return "You are unauthorized to access this resource";
    }

    return "Failed to fetch mobile apps";
  } catch (error) {
    console.log(error);
    return error as string;
  }
}
// #endregion fetchAllMobileApps

// #region fetchMobileAppByID
// A post request to fetch single mobile app by id
export async function fetchMobileAppByID(ID: string): Promise<
  | {
      rStatus: "success";
      rData: ConfigMobileAppType;
    }
  | {
      rStatus: "error";
      rMessage: string;
    }
> {
  try {
    const response = await apiFetcher<unknown>("/app/settings/get", "POST", {
      body: {
        unique_app_id: ID
      }
    });
    console.log(response);

    if (response.status === 200 && response.data) {
      if (isConfigMobileAppAPIType(response.data)) {
        const transformedData = convertConfigAppSettingToConfigMobileAppStyles(
          response.data.settings
        );

        console.log(transformedData);

        if (!transformedData) {
          return {
            rStatus: "error",
            rMessage: "Data is missing required fields"
          };
        }

        return {
          rStatus: "success",
          rData: {
            unique_app_id: response.data.unique_app_id,
            app_name: response.data.app_name,
            client_id: response.data.client_id,
            status: response.data.status,
            ios_link: response.data.ios_link,
            android_link: response.data.android_link,
            settings: transformedData
          }
        };
      } else {
        return {
          rStatus: "error",
          rMessage: "Invalid data"
        };
      }
    }

    if (response.status === 204) {
      return {
        rStatus: "error",
        rMessage: "Mobile app not found"
      };
    }

    if (response.status === 403) {
      return {
        rStatus: "error",
        rMessage: "You are unauthorized to access this resource"
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        rMessage: "500: Server error, failed to fetch mobile app"
      };
    }

    return {
      rStatus: "error",
      rMessage: "Failed to fetch mobile apps"
    };
  } catch (error) {
    console.log(error);
    return {
      rStatus: "error",
      rMessage: "Failed to fetch mobile apps"
    };
  }
}
// #endregion fetchMobileAppByID

// #region sendDeleteAppByID
export async function sendDeleteAppByID(appID: string): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher<unknown>("/app/settings/delete", "POST", {
      body: {
        unique_app_id: appID
      }
    });

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Successfully deleted mobile app"
      };
    }

    if (response.status === 204) {
      return {
        rStatus: "error",
        rMessage: "Mobile app not found"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br />400: Server expecting different data"
      };
    }

    if (response.status === 403) {
      return {
        rStatus: "error",
        rMessage: "You are unauthorized to delete this mobile app"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br />404: Route not found"
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        rMessage: "500: Server error"
      };
    }

    return {
      rStatus: "error",
      rMessage: `${response.status}: Failed to delete mobile app`
    };
  } catch (error) {
    console.log(error);
    return {
      rStatus: "error",
      rMessage: "Unknown error. Failed to delete mobile app"
    };
  }
}
// #endregion sendDeleteAppByID

// #region sendUpdatedAppConfig
export async function sendUpdatedAppConfig(
  appConfig: ConfigMobileAppAPIType
): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      rMessage: string;
    }
> {
  try {
    console.log(appConfig);
    // if (appConfig) {
    //   return true;
    // }

    const transformedResponse = await getTransformedSettingsWithImages(
      appConfig.client_id,
      appConfig.settings
    );

    if (transformedResponse.rStatus === "error") {
      return {
        rStatus: "error",
        rMessage: transformedResponse.rMessage
      };
    }

    const response = await apiFetcher<unknown>("/app/settings/edit", "POST", {
      body: {
        unique_app_id: appConfig.unique_app_id,
        app_name: appConfig.app_name,
        client_id: appConfig.client_id,
        status: appConfig.status,
        ios_link: appConfig.ios_link,
        android_link: appConfig.android_link,
        settings: transformedResponse.rData
      }
    });

    console.log(response);

    if (response.status === 202) {
      return {
        rStatus: "success"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br />400: Server expecting different data"
      };
    }

    if (response.status === 403) {
      return {
        rStatus: "error",
        rMessage: "You are unauthorized to update this app config"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br />404: Route not found"
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        rMessage: "500: Server error"
      };
    }

    return {
      rStatus: "error",
      rMessage: `${response.status}: Failed to update the app config`
    };
  } catch (error) {
    console.error("An error occurred while updating the app config:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while updating the app config"
    };
  }
}
// #endregion sendUpdatedAppConfig

// #region sendNewAppConfig
export async function sendNewAppConfig(
  appConfig: ConfigMobileAppAPIType
): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      rMessage: string;
    }
> {
  try {
    console.log(appConfig);

    const transformedResponse = await getTransformedSettingsWithImages(
      appConfig.client_id,
      appConfig.settings
    );

    if (transformedResponse.rStatus === "error") {
      return {
        rStatus: "error",
        rMessage: transformedResponse.rMessage
      };
    }

    const response = await apiFetcher<unknown>("/app/settings/create", "POST", {
      body: {
        app_name: appConfig.app_name,
        client_id: appConfig.client_id,
        status: appConfig.status,
        ios_link: appConfig.ios_link,
        android_link: appConfig.android_link,
        settings: transformedResponse.rData
      }
    });

    console.log(response);

    if (response.status === 202) {
      return {
        rStatus: "success"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br />400: Server expecting different data"
      };
    }

    if (response.status === 403) {
      return {
        rStatus: "error",
        rMessage: "You are unauthorized to create an app."
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br />404: Route not found"
      };
    }

    if (response.status === 409) {
      return {
        rStatus: "error",
        rMessage: "App already exists or client already has an app"
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        rMessage: "500: Server error"
      };
    }

    return {
      rStatus: "error",
      rMessage: `${response.status}: Failed to add the app config`
    };
  } catch (error) {
    console.error("An error occurred while creating the app config:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while creating the app config"
    };
  }
}
// #endregion sendNewAppConfig

// #region checkIfBase64
function checkIfBase64(base64: string): boolean {
  return base64.startsWith("data:");
}
// #endregion checkIfBase64

// #region uploadFile
async function uploadFile(
  base64: string,
  clientID: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const blobFile: Blob = await fetch(base64).then((r) => r.blob());
    const fileType = blobFile.type.split("/")[1];
    console.log(fileType);

    const s3URLResponse = await fetchAppsS3URL(fileType, clientID);
    console.log(s3URLResponse);
    if (!s3URLResponse) {
      return {
        rStatus: "error",
        rMessage: "An error occurred while fetching the S3 key"
      };
    }

    const uploadedURL = await sendToS3(blobFile, s3URLResponse.signedURL);
    console.log(uploadedURL);

    if (!uploadedURL) {
      return {
        rStatus: "error",
        rMessage: "An error occurred while uploading the file"
      };
    }

    return {
      rStatus: "success",
      rMessage: uploadedURL
    };
  } catch (error) {
    console.error("An error occurred while uploading the file:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while uploading the file"
    };
  }
}
// #endregion uploadFile

// #region fetchAppsS3URL
async function fetchAppsS3URL(
  fileType: string,
  clientID: string
): Promise<S3AppKeyResponse | false> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/getPresignedAppsUrl/get",
      "POST",
      {
        body: {
          client_id: clientID,
          file_type: fileType
        }
      }
    );
    console.log(response);

    if (
      response.status === 200 &&
      response.data &&
      isS3KeyResponse(response.data)
    ) {
      return {
        signedURL: response.data.signedURL
      };
    }

    return false;
  } catch {
    console.error("An error occurred while fetching the S3 key");
    return false;
  }
}
// #endregion fetchAppsS3URL

// #region sendToS3
async function sendToS3(
  blobFile: Blob,
  signedURL: string
): Promise<false | string> {
  try {
    console.log(signedURL);
    const response = await fetch(signedURL, {
      method: "PUT",
      body: blobFile,
      headers: {
        "Content-Type": blobFile.type
      }
    });

    console.log(response);

    if (response.status === 200) {
      return response.url.split("?")[0];
    }

    return false;
  } catch {
    return false;
  }
}
// #endregion sendToS3
