import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  AutoTranslateTranslation,
  editTranslation
} from "../../../models/translations.model";
import { Language, LanguageLibraryTranslation } from "../../../types";

interface EditTranslationModalProps {
  show: boolean;
  handleClose: () => void;
  selectedTranslation: LanguageLibraryTranslation;
  allLanguages: Language[];
  setTranslationEdited: (edited: boolean) => void;
}

const EditTranslationModal: FC<EditTranslationModalProps> = ({
  show,
  handleClose,
  selectedTranslation,
  allLanguages,
  setTranslationEdited
}) => {
  const [translationLanguage, setTranslationLanguage] =
    useState<Language | null>(null); // Set initial state to null

  const [editedTranslationText, setEditedTranslationText] = useState<string>(
    selectedTranslation.translation
  );

  const [busySubmitting, setBusySubmitting] = useState<boolean>(false);

  useEffect(() => {
    console.log("selectedTranslation", selectedTranslation);
    // find the language object for the selected translation in allLanguages
    const language = allLanguages.find(
      (language) =>
        language.iso_code.toUpperCase() ===
        selectedTranslation.language_iso.toUpperCase()
    );

    console.log("language", language);

    if (language) {
      console.log("language", language);
      setTranslationLanguage(language);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please contact Support<br/>Error finding language",
        confirmButtonColor: "#3085d6"
      });
    }
  }, [selectedTranslation, allLanguages]);

  async function handleSubmit() {
    // update the translation EditTranslation
    try {
      // Set busy submitting
      setBusySubmitting(true);
      const response = await editTranslation(
        selectedTranslation.id,
        editedTranslationText
      );

      // Set busy submitting
      setBusySubmitting(false);

      console.log(response, "response");

      if (response.rStatus !== "success") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.rMessage,
          confirmButtonColor: "#3085d6"
        });
      } else {
        // close the modal
        // Fetch the translations again, flag
        setTranslationEdited(true);
        handleClose();
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error editing translation",
        confirmButtonColor: "#3085d6"
      });
    }
  }

  async function handleSubmitTranslate() {
    // update the translation EditTranslation
    try {
      // Set busy submitting
      setBusySubmitting(true);
      const response = await AutoTranslateTranslation(
        selectedTranslation.id,
        editedTranslationText
      );

      // Set busy submitting
      setBusySubmitting(false);

      console.log(response, "response");

      if (response.rStatus !== "success") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.rMessage,
          confirmButtonColor: "#3085d6"
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.rMessage,
          confirmButtonColor: "#3085d6"
        });
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error editing translation",
        confirmButtonColor: "#3085d6"
      });
    }
  }

  return (
    <>
      <div className={`modal edit_translation_modal ${show ? "show" : "hide"}`}>
        <div className="modal-dialog">
          {!selectedTranslation || !translationLanguage ? (
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="modal-title">Edit Translation</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      {translationLanguage.name} ({translationLanguage.iso_code}
                      )
                    </div>
                    <div className="col-8">
                      <textarea
                        className="form-control"
                        id="translation"
                        rows={3}
                        value={editedTranslationText}
                        onChange={(e) => {
                          setEditedTranslationText(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row w-100">
                  {/* <div className="col-auto d-flex justify-content-start">
                    <button
                      type="submit"
                      className="btn btn-success me-2"
                      onClick={() => {
                        handleSubmitTranslate();
                      }}
                    >
                      Auto Translate
                    </button>
                  </div> */}
                  <div className="col d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary me-2"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Update
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditTranslationModal;
