import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import StudyNavbarSingle from "../../components/StudyNavSingle/studyNavSingle.component";
import { usePermissions } from "../../contexts/UserContext";
import { Study } from "../../types";
import "./studyNavbar.scss";

export type StudyNavbarProps = {
  studyClicked: "study" | "survey" | "diary";
  setStudyClicked: React.Dispatch<
    React.SetStateAction<"study" | "survey" | "diary">
  >;
  setTabClicked: React.Dispatch<React.SetStateAction<string>>;
  clientID: string;
  studyID: string;
  surveys: any | null;
  study?: Study;
};

const StudyNavbar: React.FC<StudyNavbarProps> = ({
  studyClicked,
  setStudyClicked,
  setTabClicked,
  clientID,
  studyID,
  surveys,
  study
}) => {
  const [route, setRoute] = useState<string>("");
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const { hasPermission } = usePermissions();
  // Logic for active tab
  // const [activeNavItem, setActiveNavItem] = useState<string | null>(null);

  // Toggle the state of showDropdown
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // This effect watches for changes in the `route` state.
  // Whenever `route` changes and it's not an empty string,
  // it triggers navigation to the new route.
  useEffect(() => {
    if (route !== "") {
      navigate(route);
    }
  }, [route, navigate]);

  const handleSetActiveNavItem = (
    id: string,
    type: "study" | "survey" | "diary"
  ) => {
    setStudyClicked(type);
    // setActiveNavItem(id);
    // Set tab to overview on study/survey/diary change
    if (type === "survey" || type === "diary") {
      setTabClicked("participants");
    } else {
      setTabClicked("overview");
    }
  };

  const { surveyID } = useParams();

  // Useeffect that sets id 1 to active on first render
  // useEffect(() => {
  //   // if survey id in use params and is a number, set active nav item
  //   // console.log("surveyID", surveyID);
  //   if (surveyID) {
  //     setActiveNavItem(surveyID);
  //   } else {
  //     setActiveNavItem("1");
  //   }
  // }, [surveyID]);

  const [studyNavItems, setStudyNavItems] = useState<any[]>([]);

  // studyNavItems - First item is always the study, and then the surveys
  useEffect(() => {
    // const foundStudy = jsonStudyData.studies.find(
    //   (study) => study.id === studyID
    // );
    // if (foundStudy) {
    //   // get the first 4 surveys from jsonSurveysData and set them to studyNavItems
    //   // foundSurveys is an array of 4 surveys regardless of study
    //   // const foundSurveys = jsonSurveysData.surveys.filter((survey) => survey);
    //   const foundSurveys: Survey[] = jsonSurveysData.surveys.filter(
    //     (survey) => survey
    //   );
    // console.log("foundSurveys", foundSurveys);
    // setStudyNavItems;
    setStudyNavItems([
      { id: studyID, name: study?.study_name, type: "study" },
      //map through surveys
      ...(surveys
        ? surveys.map((survey: any) => ({
            id: String(survey.id),
            name: survey.survey_name,
            type: survey.survey_type === "diary" ? "diary" : "survey"
          }))
        : [])
    ]);
  }, [clientID, studyID, study?.study_name, surveys, surveyID]);

  const handleClickNav = (id: string, type: "study" | "survey" | "diary") => {
    // Determine and set the tabClicked state based on the type
    if (type === "survey" || type === "diary") {
      setTabClicked("participants");
    } else {
      setTabClicked("overview");
    }

    // Instead of navigating directly, we compute the route and set it in state
    let computedRoute = "";
    if (type === "study") {
      computedRoute = `/clients/${clientID}/${id}`;
    } else if (type === "survey" || type === "diary") {
      computedRoute = `/clients/${clientID}/${studyID}/${id}`;
    } else {
      computedRoute = "/404";
    }

    setRoute(computedRoute);
  };

  const handleClickAddSurvey = () => {
    navigate(`/clients/${clientID}/${studyID}/create-survey`);
  };

  // console.log("surveyID: ", surveyID);

  return (
    <div className="study_nav_container">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid p-0">
          {/* Render StudyNavbarSingle for "study" type */}
          {studyNavItems.map((item) => {
            // console.log("item.id: ", item.id);
            if (item.type === "study") {
              // console.log(item.id);
              // console.log(typeof item.id, typeof surveyID);
              return (
                // STUDY NAVBAR SINGLE
                <span
                  key={item.id}
                  className={`navbar-brand d-flex justify-content-center m-0 pb-2 pt-0`}
                >
                  <StudyNavbarSingle
                    id={item.id}
                    name={item.name}
                    type={item.type}
                    isActive={item.id === studyID && surveyID === undefined}
                    onClick={() => handleClickNav(item.id, "study")}
                  />
                  <div className="ms-2">/</div>
                </span>
              );
            }
            return null;
          })}

          {/* Nav toggler after breakpoint */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleDropdown}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Render StudyNavbarSingle for "study" or "diary" type */}
          <div
            className={`collapse navbar-collapse ${showDropdown ? "show" : ""}`}
            id="navbarNavAltMarkup"
          >
            <div className="navbar-nav">
              {/* ALL SURVEYS AND DIARYS */}
              {studyNavItems.map((item) => {
                if (item.type === "survey" || item.type === "diary") {
                  // console.log(item.id, "ITEM ID");
                  // console.log(surveyID, "SURVEY ID");
                  // console.log(typeof item.id, typeof surveyID);

                  return (
                    <span
                      key={item.id}
                      className="nav-link"
                      aria-current="page"
                    >
                      <StudyNavbarSingle
                        id={item.id}
                        name={item.name}
                        type={item.type}
                        isActive={item.id === surveyID}
                        onClick={() => {
                          if (
                            hasPermission("subject") ||
                            hasPermission("survey")
                          ) {
                            handleClickNav(item.id, item.type);
                          } else {
                            Swal.fire({
                              icon: "warning",
                              title: "No Permission",
                              text: "You do not have permission to view surveys",
                              confirmButtonColor: "#3085d6"
                            });
                          }
                        }}
                      />
                    </span>
                  );
                }
                return null;
              })}
              {/* ADD SURVEY */}
              {(hasPermission("subject") ||
                hasPermission("survey", "all") ||
                hasPermission("survey", "write")) && (
                <span className="nav-link" aria-current="page">
                  <StudyNavbarSingle
                    name={"Survey"}
                    type={"add"}
                    onClick={handleClickAddSurvey}
                  />
                </span>
              )}
            </div>
          </div>
          {/* End of surveys and diaries */}
        </div>
      </nav>
    </div>
  );
};

export default StudyNavbar;
