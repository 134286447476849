//Page to display the clients in cards

import DisplayLeads from "../../components/DisplayLeads/displayLeads.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";
import "./leads.scss";
interface LeadsPageProps extends SidebarProps {}

const LeadsPage: React.FC<LeadsPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  return (
    <div className="main_container">
      <SidebarComponent
        page="leads"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            {/* <NavbarComponent /> */}
            <div className="container-fluid" id="leads_page">
              <DisplayLeads />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsPage;
