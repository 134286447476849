import React from "react";
import Swal from "sweetalert2";
import { IconDelete } from "../../../assets/images/icons/icons";
import { deletePhraseRequest } from "../../../models/translations.model";
import { isWorkerBusy } from "../../../utilities/utils";
import "./phrases.scss";

export type SinglePhraseProps = {
  isSelected?: boolean;
  phraseID: string;
  handlePhraseClick: (phraseID: string) => void;
  phraseName: string;
};

const SinglePhrase: React.FC<SinglePhraseProps> = ({
  isSelected,
  phraseID,
  handlePhraseClick,
  phraseName
}) => {
  //-----------------Functions -------------------
  const sendDeleteRequest = (phraseID: string) => {
    //Check for worker
    const checkStatus = isWorkerBusy();
    if (checkStatus === true) {
      return;
    }
    Swal.fire({
      title: `Confirm Phrase Deletion`,
      text: `We will conduct a through inspection to determine if the phrase is in current use. An email will be sent to you with details of its usage locations, allowing you to take further action if necessary..`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, check it",
      confirmButtonColor: "#3085d6",
      cancelButtonText: "Cancel"
    }).then((result) => {
      if (result.isConfirmed) {
        const deletePhrase = async () => {
          const response = await deletePhraseRequest(phraseID);
          if (response === "success") {
            Swal.fire({
              title: `Please action the email that was sent to you to confirm deletion`,
              icon: "success",
              confirmButtonColor: "#3085d6"
            });
          } else {
            Swal.fire({
              title: `Error sending request`,
              text: `Error: ${response}`,
              icon: "error",
              confirmButtonColor: "#3085d6"
            });
          }
        };
        deletePhrase();
      }
    });
  };

  return (
    <>
      <div
        key={phraseID}
        className={`single_phrase_container ${
          isSelected ? "container_selected" : ""
        }`}
        // on hover display right side
        onClick={() => {
          handlePhraseClick(phraseID);
        }}
      >
        <div className={`phrase_right_side hovered`}>
          <div className="phrase_right_side_title">{phraseName}</div>
        </div>
        {/* Delete function */}
        <div className="phrase_delete_button">
          <button
            className="remove-btn"
            onClick={() => sendDeleteRequest(phraseID)}
          >
            <span className="icon_delete">
              <IconDelete className="icon icon_white" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SinglePhrase;
