// Form page 2 (Web Survey Creator) to add client to the database
/* Fields {
  Username (required)
  Password (required)
  Developer Key (required)
  WSC API Url (required)
  API Version (required)
  Device URL (required)
}
*/

import React, { useState } from "react";
import AddClientWizardFooter from "./addClientWizardFooter.component";

interface addClientWizardWSCProps {
  validateWizard: () => void;
  currentPage: number;
  totalPages: number;
  handleWizardBack: () => void;
}

const AddClientWizardWSC: React.FC<addClientWizardWSCProps> = ({
  validateWizard,
  currentPage,
  totalPages,
  handleWizardBack
}) => {
  // const [clientName, setClientName] = useState("");
  const [
    wscUsername
    // setWSCUsername
  ] = useState("");
  const [
    wscPassword
    // setWSCPassword
  ] = useState("");
  const [
    wscDeveloperKey
    // setWSCDeveloperKey
  ] = useState("");
  const [
    wscAPIUrl
    // setWSCAPIUrl
  ] = useState("");
  const [
    wscAPIVersion
    // setWSCAPIVersion
  ] = useState("");
  const [
    wscDeviceURL
    //  setWSCDeviceURL
  ] = useState("");

  function ValidateForm() {}

  const handleInputChange = (
    inputName: string,
    e: React.ChangeEvent<any>
  ) => {};

  const handleSubmit = () => {
    // This function will submit the form
    // console.log("Form Submitted");
    validateWizard();
  };

  const handleBack = () => {
    handleWizardBack();
  };

  return (
    <div className="card card-default add_client_form_page p-3">
      <h1>Web Survey Creator</h1>
      <div className="add_client_form_page_inputs">
        <div className="mb-3">
          <label htmlFor="wscUsername" className="form-label mb-1 mx-1">
            Username
          </label>
          <input
            type="text"
            name="wscUsername"
            id="wscUsername"
            className="form-control"
            value={wscUsername}
            onChange={(e) => handleInputChange("wscUsername", e)}
            onBlur={() => ValidateForm()}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="wscPassword" className="form-label mb-1 mx-1">
            Password
          </label>
          <input
            type="text"
            name="wscPassword"
            id="wscPassword"
            className="form-control"
            value={wscPassword}
            onChange={(e) => handleInputChange("wscPassword", e)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="wscDeveloperKey" className="form-label mb-1 mx-1">
            Developer Key
          </label>
          <input
            type="text"
            name="wscDeveloperKey"
            id="wscDeveloperKey"
            className="form-control"
            value={wscDeveloperKey}
            onChange={(e) => handleInputChange("wscDeveloperKey", e)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="wscAPIUrl" className="form-label mb-1 mx-1">
            API URL
          </label>
          <input
            type="text"
            name="wscAPIUrl"
            id="wscAPIUrl"
            className="form-control"
            value={wscAPIUrl}
            onChange={(e) => handleInputChange("wscAPIUrl", e)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="wscAPIVersion" className="form-label mb-1 mx-1">
            API Version
          </label>
          <input
            type="text"
            name="wscAPIVersion"
            id="wscAPIVersion"
            className="form-control"
            value={wscAPIVersion}
            onChange={(e) => handleInputChange("wscAPIVersion", e)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="wscDeviceURL" className="form-label mb-1 mx-1">
            Device URL
          </label>
          <input
            type="text"
            name="wscDeviceURL"
            id="wscDeviceURL"
            className="form-control"
            value={wscDeviceURL}
            onChange={(e) => handleInputChange("wscDeviceURL", e)}
          />
        </div>
        <AddClientWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddClientWizardWSC;
