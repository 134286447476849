//Page to display the clients in cards
import ConfigMobileApps from "../../components/Config/MobileApps/MobileApps.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";

interface ConfigPageProps extends SidebarProps {}

const ConfigPage: React.FC<ConfigPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid" id="config_page">
          <ConfigMobileApps />
        </div>
      </div>
      <SidebarComponent
        page="config"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
    </div>
  );
};

export default ConfigPage;
