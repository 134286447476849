// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  flex: 1 1;
  gap: 10px;
}
.filters-container .eq-filter-date {
  font-weight: 500;
}
.filters-container .eq-filter-date .date-header {
  font-weight: 600;
  font-size: 14px;
  margin: 5px;
}
.filters-container .eq-filter-date .date-input {
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 5px;
}
.filters-container .eq-filter-search-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.filters-container .eq-filter-search-container .search-input {
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 5px;
  width: 40%;
}
.filters-container .eq-filter-search-container .btn-clear {
  width: 50px;
}
.filters-container .eq-filter-search-container .btn-sort {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.filters-container .eq-filter-search-container .btn-sort .icon {
  width: 20px;
  height: 20px;
}
.filters-container .row-selector {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.follow-link {
  cursor: pointer;
  color: #1a2345;
  text-decoration: underline;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/StudySurveyTabSingle/ViewEQ/ViewEQ.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,SAAA;AACF;AACE;EACE,gBAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,WAAA;AACN;AAEI;EACE,kBAAA;EACA,yBAAA;EACA,YAAA;AAAN;AAIE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;AAFJ;AAII;EACE,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,UAAA;AAFN;AAKI;EACE,WAAA;AAHN;AAMI;EAEE,aAAA;EACA,mBAAA;EACA,QAAA;AALN;AAOM;EACE,WAAA;EACA,YAAA;AALR;AASE;EACE,aAAA;EACA,yBAAA;EACA,WAAA;AAPJ;;AAUA;EACE,eAAA;EACA,cAAA;EACA,0BAAA;EACA,gBAAA;AAPF","sourcesContent":[".filters-container {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  align-items: center;\n  margin-bottom: 10px;\n  flex-wrap: wrap;\n  flex: 1;\n  gap: 10px;\n\n  .eq-filter-date {\n    font-weight: 500;\n\n    .date-header {\n      font-weight: 600;\n      font-size: 14px;\n      margin: 5px;\n    }\n\n    .date-input {\n      border-radius: 5px;\n      border: 1px solid #cecece;\n      padding: 5px;\n    }\n  }\n\n  .eq-filter-search-container {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    align-items: center;\n    flex-wrap: wrap;\n    width: 100%;\n\n    .search-input {\n      border-radius: 5px;\n      border: 1px solid #cecece;\n      padding: 5px;\n      width: 40%;\n    }\n\n    .btn-clear {\n      width: 50px;\n    }\n\n    .btn-sort {\n      // width: 15%;\n      display: flex;\n      flex-direction: row;\n      gap: 5px;\n\n      .icon {\n        width: 20px;\n        height: 20px;\n      }\n    }\n  }\n  .row-selector {\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n  }\n}\n.follow-link {\n  cursor: pointer;\n  color: #1a2345;\n  text-decoration: underline;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
