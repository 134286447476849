// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.study_nav_details_container {
  margin-top: 20px;
}
.study_nav_details_container .nav-item .icon_container {
  display: flex;
  align-items: center;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 5px;
  padding-top: 1px;
  cursor: pointer;
}
.study_nav_details_container .nav-item .icon_container svg {
  /* If the SVG is larger than 1rem by 1rem, it will be centered inside the container */
  max-width: 100%;
  max-height: 100%;
  height: 1rem;
}
.study_nav_details_container .nav-item .nav-link {
  font-size: 14px;
  color: #7c7c7c;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.study_nav_details_container .nav-underline .nav-link:hover,
.study_nav_details_container .nav-underline .nav-link:focus {
  border-bottom-color: #1f8ec7 !important;
}
.study_nav_details_container .is_active {
  border-bottom-color: #1f8ec7 !important;
}`, "",{"version":3,"sources":["webpack://./src/infrastructure/StudyDetailsTabs/studyDetailsTabs.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAII;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AAFN;AAIM;EACE,qFAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AAFR;AAMI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAJN;AAQE;;EAEE,uCAAA;AANJ;AASE;EACE,uCAAA;AAPJ","sourcesContent":["@use \"../../global.scss\" as *;\n\n.study_nav_details_container {\n  margin-top: 20px;\n\n  .nav-item {\n    .icon_container {\n      display: flex;\n      align-items: center;\n      width: 0.75rem;\n      height: 0.75rem;\n      margin-right: 5px;\n      padding-top: 1px;\n      cursor: pointer;\n\n      svg {\n        /* If the SVG is larger than 1rem by 1rem, it will be centered inside the container */\n        max-width: 100%;\n        max-height: 100%;\n        height: 1rem;\n      }\n    }\n\n    .nav-link {\n      font-size: 14px;\n      color: #7c7c7c;\n      font-weight: 600;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      cursor: pointer;\n    }\n  }\n\n  .nav-underline .nav-link:hover,\n  .nav-underline .nav-link:focus {\n    border-bottom-color: #1f8ec7 !important;\n  }\n\n  .is_active {\n    border-bottom-color: #1f8ec7 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
