//Page to display the Study gets the study name from the url

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { fetchSingleClientByID } from "../../models/client.model";
import { fetchStudyByClientID } from "../../models/study.model";
import { fetchAllSurveys } from "../../models/survey.model";
import { Client, SidebarProps, Study, SurveyPill } from "../../types";
import ErrorPage from "../Error/error.page";

import { usePermissions } from "../../contexts/UserContext";

import StudyDashboard from "../StudyDashboard/studyDashboard.component";

interface StudyPageProps extends SidebarProps {}

const StudyPage: React.FC<StudyPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  const { clientID, studyID } = useParams(); // Read values passed on link
  const [study, setStudy] = useState<Study | null>(null); // Set initial state to null
  const [client, setClient] = useState<Client | null>(null); // Set initial state to null
  const [allSurveys, setAllSurveys] = useState<SurveyPill[] | null>(null); // Set initial state to null
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  const { hasPermission } = usePermissions();

  useEffect(() => {
    setLoading(true);
    //fetch the client and it's studies
    const fetchClientsData = async () => {
      try {
        const jsonData = await fetchSingleClientByID(clientID as string);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "2e7380fe56cabacc5060280a8ea6d998"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setClient(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "78e2221401e748b0ce8ca4db19093d89"
        ]);
      }
    };

    const fetchStudy = async () => {
      try {
        const jsonData = await fetchStudyByClientID(
          clientID as string,
          studyID as string
        );
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          console.log("jsonData is a string");
          console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "7e1d203e7c3a19b080f3d9851c937fb2"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          // console.log(jsonData.id);
          // console.log(typeof studyID); // before your jsonData loop
          // console.log(typeof jsonData.id); // before your jsonData loop
          // console.log(jsonData);

          if (jsonData.id === studyID) {
            setStudy(jsonData);
          }
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "41e398c3e62485b76859a4fc653c934b"
        ]);
      }
    };

    const fetchSurveys = async () => {
      if (hasPermission("subject") || hasPermission("survey")) {
        try {
          const jsonData = await fetchAllSurveys(
            clientID as string,
            studyID as string
          );

          console.log(jsonData);
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            console.log("jsonData is a string");
            console.log("jsonData", jsonData);
            setLoadingError(true); // Set loadingError to true in case of an error
            setLoadingErrorText((prevErrors) => [
              ...prevErrors,
              "c8808d06c9cd60ffbe665f857270835e"
            ]);
          } else {
            // console.log("jsonData is an object");
            // console.log("jsonData", jsonData);
            // console.log(jsonData);
            // console.log(typeof studyID); // before your jsonData loop
            // console.log(typeof jsonData); // before your jsonData loop
            // console.log(jsonData);
            // console.log(allSurveys);
            setAllSurveys(jsonData);
          }
        } catch (error) {
          console.error(
            "An error occurred while fetching participant data:",
            error
          );
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "01f4d837bcf267873da34832ba64c4ee"
          ]);
        }
      }
    };

    const promises = [fetchClientsData(), fetchStudy(), fetchSurveys()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "cfa6ba859d52c2a2d0da6c015f18fbbe"
        ]);
      });
  }, [studyID, clientID]);

  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        {/* <NavbarComponent /> */}
        <div className="container-fluid" id="study_page">
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container full_page">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : loadingError ? (
            <ErrorPage
              errorCode="500"
              error="Error fetching data"
              debugCode={loadingErrorText}
            />
          ) : !client ? (
            <ErrorPage
              errorCode="404"
              error="Client not found"
              debugCode={loadingErrorText}
            />
          ) : !study ? (
            <ErrorPage
              errorCode="404"
              error="Study not found"
              debugCode={loadingErrorText}
            />
          ) : (
            // ) : !allSurveys ? (
            //   <ErrorPage
            //     errorCode="404"
            //     error="Can't find surveys"
            //     debugCode={loadingErrorText}
            //   />
            <StudyDashboard
              study={study}
              clientID={client.id}
              surveys={allSurveys}
            />
          )}
        </div>
      </div>
      <SidebarComponent
        page="study"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={client?.id}
        currentStudy={study ? study.id : null}
        currentClientLogo={client?.icon_url}
        currentStudyPhoto={study?.thumbnail_url}
      />
    </div>
  );
};

export default StudyPage;
