// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wsc_study_survey_container .wscSurvey .wscSurvey_header * {
  display: inline-block;
  vertical-align: middle;
}
.wsc_study_survey_container .wscSurvey_body_question_header_title {
  max-height: 3lh;
  overflow-y: auto;
}
.wsc_study_survey_container .wscSurvey_body_question {
  margin-bottom: 20px;
}
.wsc_study_survey_container .btn-group.survey_wsc_container_switcher {
  border: 1px solid #6c757d;
}
.wsc_study_survey_container .survey_wsc_container .card {
  border: 1px solid #d4dce4;
  box-shadow: none;
}
.wsc_study_survey_container .mapping_header * {
  display: inline-block;
  vertical-align: middle;
}
.wsc_study_survey_container .remove_variable_button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/StudySurveyTabSingle/WSC/studySurveyWSCHandler.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,sBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAOI;EACE,yBAAA;EACA,gBAAA;AALN;AASE;EACE,qBAAA;EACA,sBAAA;AAPJ;AAUE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,iBAAA;EACA,mBAAA;AARJ","sourcesContent":[".wsc_study_survey_container {\n  .wscSurvey .wscSurvey_header * {\n    display: inline-block;\n    vertical-align: middle;\n  }\n\n  .wscSurvey_body_question_header_title {\n    max-height: 3lh;\n    overflow-y: auto;\n  }\n\n  .wscSurvey_body_question {\n    margin-bottom: 20px;\n  }\n\n  .btn-group.survey_wsc_container_switcher {\n    border: 1px solid #6c757d;\n  }\n\n  .survey_wsc_container {\n    .card {\n      border: 1px solid #d4dce4;\n      box-shadow: none;\n    }\n  }\n\n  .mapping_header * {\n    display: inline-block;\n    vertical-align: middle;\n  }\n\n  .remove_variable_button {\n    position: absolute;\n    top: 0px;\n    right: 0px;\n    font-size: 1.5rem;\n    line-height: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
