import { enableMfa } from "../models/auth.model";

export async function requestEnableMfa(
  encodedMFAEnableString: string
): Promise<true | string> {
  try {
    const res = await enableMfa(encodedMFAEnableString);
    if (!res.error) {
      console.log(res);
      if (
        res.status === 200 &&
        res.data?.success === true &&
        typeof res.data.keyUri === "string"
      ) {
        setKeyUriLocalStorage(res.data.keyUri);
        console.log(res.data);
        return true;
      } else {
        return "No data returned";
      }
    } else {
      if (res.status === 409) {
        return "MFA already enabled";
      }

      console.log(res.error);
      return "Error enabling MFA";
    }
  } catch (error) {
    console.error(error);
    return "Error enabling MFA";
  }
}

// Set uriKey in local storage, from response
export async function setKeyUriLocalStorage(keyUri: string) {
  try {
    if (keyUri) {
      localStorage.setItem("key-uri", keyUri);
    } else {
      console.warn("setKeyUriLocalStorage: Invalid response object");
    }
  } catch (error) {
    console.error(`setKeyUriLocalStorage: ${error}`);
  }
}

// get uriKey from local storage
export async function getKeyUriLocalStorage() {
  const keyUri = localStorage.getItem("key-uri");
  return keyUri;
}
