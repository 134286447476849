import React from "react";
import { useParams } from "react-router-dom";
import Reports from "../../components/Reports/reports.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";

interface ReportsPageProps extends SidebarProps {}

const ReportsPage: React.FC<ReportsPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page,
  currentClientLogo,
  currentClientName
}) => {
  const { clientID } = useParams<string>();

  // if (!currentClientName) {
  //   return (
  //     <div className="main_container">
  //       <div className="col-sm p-3 min-vh-100">
  //         <div className="container-fluid">
  //           <div className="row">
  //             <div id="reports_page" className="container-fluid">
  //               <h1>No Client Found</h1>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <SidebarComponent
  //         page="reports"
  //         sidebarPinned={sidebarPinned}
  //         onSidebarPinChange={onSidebarPinChange}
  //         currentClient={clientID}
  //         currentClientLogo={currentClientLogo}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="main_container">
      <SidebarComponent
        page={clientID ? "reports" : "global_reports"}
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={clientID}
        currentClientLogo={currentClientLogo}
      />

      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <Reports
                currentClientName={
                  clientID && currentClientName ? currentClientName : null
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
