// timezonesFilter.tsx

import React, { useEffect, useRef, useState } from "react";
import { Timezone } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface TimezonesFilterProps {
  jsonTimezonesData: Timezone[];
  onTimezoneFilterChange: (timezones: string[]) => void;
  filtersCleared: boolean;
  location: string;
}

const TimezonesFilter: React.FC<TimezonesFilterProps> = ({
  jsonTimezonesData,
  onTimezoneFilterChange,
  filtersCleared,
  location
}) => {
  const [timezonesFilter, settimezonesFilter] = useState<string>("");
  const [timezonesDropdown, settimezonesDropdown] = useState(false);
  const [participantstimezonesFilter, setParticipantstimezonesFilter] =
    useState<Record<string, boolean>>({});

  const handletimezonesDropdown = (show: boolean) => {
    // show/hide dropdown
    settimezonesDropdown(show);
  };

  const handletimezonesSearchFilter = (search: string) => {
    // set search value
    settimezonesFilter(search);
  };

  const handletimezonesFilter = (timezone: string, checked: boolean) => {
    // set timezones filter
    setParticipantstimezonesFilter((prevtimezones) => ({
      ...prevtimezones,
      [timezone]: checked // set checked status for this timezone
    }));
  };

  useEffect(() => {
    // send timezones filter to parent component
    onTimezoneFilterChange(
      Object.keys(participantstimezonesFilter).filter(
        (timezone) => participantstimezonesFilter[timezone] // filter timezones that are true
      )
    );
  }, [participantstimezonesFilter, onTimezoneFilterChange]);

  useEffect(() => {
    // clear timezones filter when filters are cleared
    if (filtersCleared) {
      settimezonesFilter("");
      setParticipantstimezonesFilter({});
      handletimezonesSearchFilter("");
      // handletimezonesDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handletimezonesDropdown(false);
  });

  return (
    <div className="dropdown dropdown_timezones ms-2" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handletimezonesDropdown(!timezonesDropdown)}
      >
        Timezones
      </button>
      <div className={`dropdown-menu ${timezonesDropdown ? "show" : "hide"}`}>
        {jsonTimezonesData && jsonTimezonesData.length === 0 ? (
          <div className="dropdown_menu_item">
            <span>No timezones</span>
          </div>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search timezones"
              value={timezonesFilter}
              onChange={(e) => handletimezonesSearchFilter(e.target.value)}
            />
            <div className="dropdown_menu_items">
              {
                /* filter by timezonesfilter set by input */
                /* loop through all timezones */
                jsonTimezonesData.map((timezone: Timezone, index: number) => {
                  if (
                    timezone.name
                      .toLowerCase()
                      .includes(timezonesFilter.toLowerCase())
                  ) {
                    return (
                      <div key={index} className="dropdown_menu_item">
                        <label
                          htmlFor={`${location}_timezone_${timezone.name}`}
                          className="form_control"
                        >
                          <input
                            type="checkbox"
                            id={`${location}_timezone_${timezone.name}`}
                            name={timezone.name}
                            value={timezone.name}
                            checked={
                              participantstimezonesFilter[timezone.name] ||
                              false
                            } // use checked property here
                            onChange={(e) =>
                              handletimezonesFilter(
                                e.target.value,
                                e.target.checked
                              )
                            }
                          />
                          {`
                           ${timezone.name}`}
                        </label>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TimezonesFilter;
