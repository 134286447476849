import React, { useEffect, useState } from "react";
import { ExistingStudyNotification } from "../../../types";
import InformationTooltip from "../../InputInformation/InfoInputs.component";

interface FieldNotificationProps {
  onInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  inputValue: string;
  tooltip?: string;
  label: string;
  inputName: string;
  inputOptions: ExistingStudyNotification[];
  subLabel?: string;
  error?: string | null;
  tooltipPosition?: "left" | "right" | "bottom" | "top";
}

const FieldNotification: React.FC<FieldNotificationProps> = ({
  onInputChange,
  inputValue,
  tooltip,
  label,
  inputName,
  inputOptions,
  subLabel,
  error,
  tooltipPosition
}) => {
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    inputValue
  );
  const [filteredOptions, setFilteredOptions] =
    useState<ExistingStudyNotification[]>(inputOptions);

  useEffect(() => {
    // filter the options based on the search term
    const filtered = inputOptions.filter((option) => {
      return option.phrase.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredOptions(filtered);
  }, [search, inputOptions]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearch(searchText);

    // Check if something is found in the search
    const filtered = inputOptions.filter((option) => {
      return option.phrase.toLowerCase().includes(searchText.toLowerCase());
    });

    if (filtered.length > 0) {
      setSelectedOption(filtered[0].phraseID);
      onInputChange(inputName, {
        target: {
          value: filtered[0].phraseID
        }
      } as React.ChangeEvent<any>);
    } else {
      // If nothing is found, clear the selected option
      setSelectedOption("");
      onInputChange(inputName, {
        target: {
          value: ""
        }
      } as React.ChangeEvent<any>);
    }
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onInputChange(inputName, {
      target: {
        value: selectedValue
      }
    } as React.ChangeEvent<any>);
  };

  return (
    <div className="mb-3 row">
      <div className="col-12 col-lg-4">
        <label htmlFor={inputName} className="form-label mb-1 mx-1">
          {tooltip && (
            <InformationTooltip
              marginTop="-12px"
              position={tooltipPosition ? tooltipPosition : "top"}
              tooltipText={tooltip}
            />
          )}
          {label}
          {subLabel && <span className="sub_label">{subLabel}</span>}
        </label>
      </div>
      <div className="col-12 col-lg-8">
        {error && <div className="wizard_input_error">{error}</div>}
        {/* radio buttons for new and existing */}
        <div
          className="form-check radio_buttons"
          style={{
            display: "inline-block",
            marginRight: "1rem"
          }}
        >
          <label className="form-check-label" htmlFor="new">
            <input
              className="form-check-input"
              type="radio"
              name={inputName}
              id={`new_${inputName}`}
              value="new"
              checked={inputValue === "new"}
              onChange={(e) => onInputChange(inputName, e)}
            />
            New
          </label>
        </div>{" "}
        {filteredOptions.length > 0 && (
          <div
            className="form-check radio_buttons"
            style={{
              display: "inline-block",
              marginRight: "1rem"
            }}
          >
            <label className="form-check-label" htmlFor="existing">
              <input
                className="form-check-input"
                type="radio"
                name={inputName}
                id={`existing_${inputName}`}
                value={
                  filteredOptions[0].phraseID ? filteredOptions[0].phraseID : ""
                }
                checked={inputValue !== "new"}
                onChange={(e) => onInputChange(inputName, e)}
              />
              Existing
            </label>
          </div>
        )}
        {inputValue !== "new" && (
          <>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
            <select
              name={inputName}
              id={inputName}
              className="form-select mt-2"
              value={selectedOption || ""}
              onChange={(e) => {
                handleOptionChange(e);
              }}
            >
              <option value="" disabled>
                Select an option
              </option>
              {filteredOptions.map((option) => {
                return (
                  <option key={option.phraseID} value={option.phraseID}>
                    {/* Apply ellipsis to long option text */}
                    {option.phrase.length > 50
                      ? `${option.phrase.substring(0, 50)}...`
                      : option.phrase}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </div>
    </div>
  );
};

export default FieldNotification;
