import Swal from "sweetalert2";
import * as XLSX from "xlsx";

import {
  Client,
  LeadsParticipant,
  Participant,
  Researcher,
  ResearcherRole,
  Reward,
  SurveyCompletion
} from "../types";

function getDaysInCurrentMonth() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the last day of the current month

  return daysInMonth;
}

export function exportParticipants(
  participants: Participant[],
  tableType: string,
  levelName: string,
  surveyCompletionData?: SurveyCompletion[]
): void {
  //   console.log(tableType);
  //   console.log(participants);
  let headers: string[] = [];
  let fields: string[] = [];
  const date = new Date();
  console.log(date);
  // format the date to be used in the file name dd-mm-yyyy hh-mm
  const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}_${date.getMinutes()}`;
  console.log(formattedDate);
  let fileName = "";

  const daysInMonth = getDaysInCurrentMonth();
  console.log("Days in month: ", daysInMonth);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const displayMonth = month < 10 ? `0${month}` : month;

  switch (tableType.toLowerCase()) {
    case "clientparticipants":
      headers = [
        "participant_id",
        "name",
        "surname",
        "mobile",
        "email",
        "country",
        "language",
        "referrer_id",
        "tags",
        "timezone",
        "apps",
        "udid",
        "last_login",
        "status"
      ];
      fields = [
        "id",
        "participant_name",
        "participant_surname",
        "participant_mobile_number",
        "participant_email",
        "participant_country_iso",
        "participant_lang_iso",
        "participant_ref_code",
        "participant_tags",
        "participant_timezone",
        "participant_app_code",
        "participant_uid",
        "participant_last_login",
        "participant_status"
      ];
      fileName = `${formattedDate} ${levelName} Client Participants.xlsx`;
      break;
    case "studyparticipants":
      headers = [
        "participant_id",
        "name",
        "surname",
        "mobile",
        "email",
        "country",
        "language",
        "referrer_id",
        "tags",
        "timezone",
        "day_in_study",
        "joined_study",
        "last_login",
        "bucket",
        "app_code",
        "app_status",
        "study_progress"
      ];

      fields = [
        "id",
        "participant_name",
        "participant_surname",
        "participant_mobile_number",
        "participant_email",
        "participant_country_iso",
        "participant_lang_iso",
        "participant_ref_code",
        "participant_tags",
        "participant_timezone",
        "study_days",
        "study_joined_date",
        "participant_last_login",
        "bucket",
        "participant_app_code",
        "participant_app_status",
        "study_status"
      ];
      fileName = `${formattedDate} ${levelName} Study Participants.xlsx`;
      break;
    case "surveyparticipants":
      headers = [
        "participant_id",
        "name",
        "surname",
        "mobile",
        "email",
        "country",
        "language",
        "referrer_id",
        "tags",
        "timezone",
        "day_in_survey",
        "completes",
        "joined_survey",
        "survey_status"
      ];

      fields = [
        "id",
        "participant_name",
        "participant_surname",
        "participant_mobile_number",
        "participant_email",
        "participant_country_iso",
        "participant_lang_iso",
        "participant_ref_code",
        "participant_tags",
        "participant_timezone",
        "survey_days",
        "completes",
        "survey_joined_date",
        "survey_status"
      ];

      for (let i = 1; i <= daysInMonth; i++) {
        const displayDay = i < 10 ? `0${i}` : i;
        const headerString = `${year}-${displayMonth}-${displayDay}`;
        headers.push(headerString);
        fields.push(`day_${i}`);
      }

      console.log("Headers: ", headers);
      console.log("Fields: ", fields);
      fileName = `${formattedDate} ${levelName} Survey Participants.xlsx`;
      break;
    case "diaryparticipants":
      console.log("Diary Participants");
      headers = [
        "participant_id",
        "name",
        "surname",
        "mobile",
        "email",
        "country",
        "language",
        "referrer_id",
        "tags",
        "timezone",
        "day_in_survey",
        "completes",
        "joined_survey",
        "survey_status"
      ];

      fields = [
        "id",
        "participant_name",
        "participant_surname",
        "participant_mobile_number",
        "participant_email",
        "participant_country_iso",
        "participant_lang_iso",
        "participant_ref_code",
        "participant_tags",
        "participant_timezone",
        "survey_days",
        "completes",
        "survey_joined_date",
        "survey_status"
      ];

      for (let i = 1; i <= daysInMonth; i++) {
        const displayDay = i < 10 ? `0${i}` : i;
        const headerString = `${year}-${displayMonth}-${displayDay}`;
        headers.push(headerString);
        fields.push(`day_${i}`);
      }

      console.log("Headers: ", headers);
      console.log("Fields: ", fields);

      console.log(levelName);
      fileName = `${formattedDate} ${levelName} Diary Participants.xlsx`;
      break;
    default:
      headers = [];
      console.log("Unknown table type: ", tableType);
      break;
  }

  if (headers.length === 0 || fields.length === 0) {
    Swal.fire({
      title: "Error",
      text: `Unknown table type: ${tableType}`,
      icon: "error"
    });
    return;
  }

  if (headers.length !== fields.length) {
    console.log(headers.length, fields.length);
    Swal.fire({
      title: "Error",
      text: "Headers and fields length mismatch",
      icon: "error"
    });
    return;
  }
  try {
    const data = participants.map((participant: Participant) => {
      const row: { [key: string]: any } = {};
      fields.forEach((field, index) => {
        const header = headers[index];
        const value = participant[field];

        // Check if the value is an array and handle it accordingly
        if (Array.isArray(value)) {
          row[header] = value.join("#");
        } else {
          console.log(header, value);
          row[header] = value;
        }
      });

      // Populate completes values for each date if available
      if (!surveyCompletionData) {
        return row;
      }
      // Populates all dates with 0's
      headers.forEach((header) => {
        if (!row[header]) {
          row[header] = 0;
        }
      });
      surveyCompletionData.forEach((surveyCompletion) => {
        surveyCompletion.participants.forEach((surveyParticipant) => {
          if (surveyParticipant.participant_id === participant.id) {
            // If this surveyCompletion record belongs to the current participant
            const completes = surveyParticipant.completes;
            row[surveyCompletion.date] = completes; // Set actual completes
          } else {
            // If this surveyCompletion record does not belong to the current participant
            if (!row[surveyCompletion.date]) {
              row[surveyCompletion.date] = 0; // Set completes to 0
            }
          }
        });
      });

      return row;
    });

    const ws = XLSX.utils.json_to_sheet(data, {
      header: headers
      //   skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Participants");
    console.log(fileName);
    XLSX.writeFile(wb, fileName);
  } catch (error) {
    console.error(error);
  }
}

export function exportLeads(
  participants: LeadsParticipant[],
  allClients: Client[]
): void {
  // console.log(participants);
  // console.log(allClients);
  const headers: string[] = [
    "participant_id",
    "name",
    "surname",
    "mobile",
    "email",
    "country",
    "language",
    "referrer_id",
    "tags",
    "timezone",
    "region",
    "age",
    "gender",
    "current_client",
    "last_client",
    "cheater_score",
    "participant_score",
    "source",
    "last_used"
  ];
  const fields: string[] = [
    "id",
    "first_name",
    "last_name",
    "mobile_number",
    "email",
    "country_iso",
    "language_iso",
    "ref_id",
    "tags",
    "timezone",
    "region",
    "age",
    "gender",
    "current_client",
    "last_client",
    "cheater_score",
    "participation_score",
    "source",
    "last_used"
  ];

  if (headers.length !== fields.length) {
    console.log(headers.length, fields.length);
    Swal.fire({
      title: "Error",
      text: "Headers and fields length mismatch",
      icon: "error"
    });
    return;
  }
  try {
    const data = participants.map((participant: LeadsParticipant) => {
      const row: { [key: string]: any } = {};
      fields.forEach((field, index) => {
        const header = headers[index];
        const value = participant[field];

        // Check if the value is an array and handle it accordingly
        if (Array.isArray(value)) {
          row[header] = value.join("#");
        } else {
          row[header] = value;
        }

        // if current_client, last_client, source then get the name of the client
        if (field === "current_client" || field === "last_client") {
          if (value === "1") {
            row[header] = "Lead";
          } else {
            const client = allClients.find((c) => c.id === value);
            if (client) {
              row[header] = client?.name;
            } else {
              row[header] = "";
            }
          }
        }
      });
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(data, {
      header: headers
      //   skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leads");
    XLSX.writeFile(wb, "leads.xlsx");
  } catch (error) {
    console.error(error);
  }
}

export function exportResearchers(
  researchers: Researcher[],
  allRoles: ResearcherRole[]
): void {
  const headers: string[] = [
    "researcher_id",
    "name",
    "surname",
    "email",
    "mobile",
    "country",
    "language",
    "timezone",
    "role",
    "last_login",
    "created_at",
    "blocked"
  ];
  const fields: string[] = [
    "id",
    "first_name",
    "surname",
    "email",
    "full_mobile",
    "country_iso",
    "language_iso",
    "timezone",
    "role_id",
    "last_login",
    "created_at",
    "blocked"
  ];

  if (headers.length !== fields.length) {
    console.log(headers.length, fields.length);
    Swal.fire({
      title: "Error",
      text: "Headers and fields length mismatch",
      icon: "error"
    });
    return;
  }
  try {
    const data = researchers.map((researcher: Researcher) => {
      const row: { [key: string]: any } = {};
      fields.forEach((field, index) => {
        const header = headers[index];
        const value = researcher[field];

        // Check if the value is an array and handle it accordingly
        if (Array.isArray(value)) {
          row[header] = value.join("#");
        } else {
          row[header] = value;
        }

        // if role then get the name of the role
        if (field === "role_id") {
          const role = allRoles.find((r) => r.id === value);
          if (role) {
            row[header] = role?.name;
          } else {
            row[header] = "";
          }
        }
      });

      return row;
    });

    const ws = XLSX.utils.json_to_sheet(data, {
      header: headers
      //   skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Researchers");
    XLSX.writeFile(wb, "researchers.xlsx");
  } catch (error) {
    console.error(error);
  }
}

export function exportRewards(rewards: Reward[], source: string): void {
  const headers: string[] = [
    "reward_id",
    "status",
    "points",
    "participant_id",
    "earned_at",
    "participant_email",
    "participant_mobile",
    "country",
    "username",
    source === "client" ? "study_name" : ""
  ];
  const fields: (keyof Reward)[] = [
    "id",
    "status",
    "points",
    "participant_id",
    "earned_at",
    "participant_email",
    "participant_mobile",
    "country",
    "username",
    ...(source === "client" ? (["study_name"] as (keyof Reward)[]) : [])
  ];

  if (headers.length !== fields.length) {
    console.log(headers.length, fields.length);
    Swal.fire({
      title: "Error",
      text: "Headers and fields length mismatch",
      icon: "error"
    });
    return;
  }
  try {
    const data = rewards.map((reward: Reward) => {
      const row: { [key: string]: string | number | undefined } = {};
      fields.forEach((field, index) => {
        const header = headers[index];
        const value = reward[field];

        // Check if the value is an array and handle it accordingly
        if (Array.isArray(value)) {
          row[header] = value.join("#");
        } else {
          row[header] = value;
        }
      });
      // Populate dynamic completes values for each date

      return row;
    });

    const ws = XLSX.utils.json_to_sheet(data, {
      header: headers
      //   skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rewards");
    XLSX.writeFile(wb, "rewards.xlsx");
  } catch (error) {
    console.error(error);
  }
}
