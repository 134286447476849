import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconAdd, IconDelete } from "../../../../assets/images/icons/icons";
import {
  deleteAutomations,
  fetchAllAutomationData,
  getAllAutomations,
  getAutomation
} from "../../../../models/automations.model";
import {
  Actions,
  AutomationDataDB,
  ChildConditionGroup,
  Country,
  Language,
  Rules,
  Timings
} from "../../../../types";
import {
  getSystemConfigCountries,
  getSystemConfigLanguages
} from "../../../../utilities/config.util";
import Search from "../../../Tables/Filters/filterSearch.component";
import AutomationsPreview from "../AutomationsPreview/automationsPreview.component";
import CreateRule from "../CreateRule/createRule.component";
import AutomationRuleSingle from "./automationRuleSingle.component";

import { usePermissions } from "../../../../contexts/UserContext";

const AutomationRulesContainer: React.FC = () => {
  const { clientID, studyID, surveyID } = useParams();
  const [selectedRuleID, setSelectedRuleID] = useState<string>("");
  const [automationsSearch, setAutomationsSearch] = useState<string>("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [ruleSelection, setRuleSelection] = useState("");
  const [studiesDropdown, handleStudiesDropdown] = useState(false);
  const [studyFilter, setStudyFilter] = useState("");
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [filteredSelectedRules, setFilteredSelectedRules] = useState<string[]>(
    []
  );

  // ==================================== VALIDATION ====================================

  const [validActionData, setValidActionData] = useState(false);
  const [validConditionData, setValidConditionData] = useState(false);
  const [validTimingData, setValidTimingData] = useState(false);

  const [allAutomations, setAllAutomations] = useState<Rules[]>([]);

  const [viewCreateRule, setViewCreateRule] = useState(false);
  const [viewEditRule, setViewEditRule] = useState(false);
  const [ruleAtBottom, setRuleAtBottom] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);

  const [saveConfirmed, setSaveConfirmed] = useState(false);

  // ==================================== DATA ====================================
  // DB data
  const [automationConditionDataDB, setAutomationConditionDataDB] =
    useState<AutomationDataDB>({
      tags: [],
      buckets: [],
      surveys: []
    });

  const [fetchedDB, setFetchedDB] = useState(false);

  // local storage data
  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  const [loadingLanguages, setLoadingLanguages] = useState(true);
  const [loadingCountries, setLoadingCountries] = useState(true);

  const [fetchedLocal, setFetchedLocal] = useState(false);

  const [foundAutomation, setFoundAutomation] = useState(false);

  const [workflowRuleName, setWorkflowRuleName] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [loadingSingleAutomation, setLoadingSingleAutomation] =
    useState<boolean>(true);
  // For Validation

  const [childConditionGroups, setChildConditionGroups] =
    useState<ChildConditionGroup>({
      "0": {
        operator: "and",
        childRules: [
          {
            id: "0",
            type: "",
            variable: "",
            selection: "",
            options: "",
            value: ""
          }
        ], // This is now an array
        childConditionGroups: {}
      }
    });

  const [actions, setActions] = useState<Actions[]>([]);
  const [timing, setTiming] = useState<Timings>({
    type: "noSchedule",
    actionMultipleTimes: false,
    timing: {
      participantLocalTime: "",
      date: ""
    }
  });

  const { hasPermission } = usePermissions();

  // Fetching data for automation conditions (Surveys, Buckets, Tags, etc.)
  useEffect(() => {
    console.log("HIT ============================");

    // Only makes the call if the data hasn't been fetched yet
    if (
      automationConditionDataDB.surveys === undefined ||
      automationConditionDataDB.surveys.length === 0 ||
      automationConditionDataDB.surveys === null
    ) {
      const fetchAutomationsData = async () => {
        try {
          console.log("HIT");
          const jsonData = await fetchAllAutomationData(
            clientID as string,
            studyID as string
          );
          if (typeof jsonData === "string") {
            //Swal
            Swal.fire({
              icon: "error",
              title: "Error fetching data",
              text: jsonData,
              confirmButtonColor: "#3085d6"
            });
          } else {
            console.log("jsonData", jsonData);
            setAutomationConditionDataDB(jsonData);
          }
        } catch (error) {
          console.error(
            "An error occurred while fetching participant data:",
            error
          );
          // setLoadingError(true); // Set loadingError to true in case of an error
        }
      };
      console.log("HIT");

      const promises = [fetchAutomationsData()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
          setLoading(false);
          setFetchedDB(true);
        })
        .catch(() => {
          // At least one promise failed
          setLoading(false);
          setFetchedDB(false);
        });
    } else {
      console.log("already set");
      setLoading(false);
    }
  }, []);

  // Fetching languages from local storage using getConfigLanguages
  useEffect(() => {
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          console.log("jsonData is false");
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        console.log("jsonData is false");
      }
    };

    const fetchAllConfigCountries = async () => {
      try {
        const jsonData: Country[] | false = await getSystemConfigCountries();
        if (jsonData === false) {
          console.log("jsonData is false");
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setCountries(jsonData);
          } catch {
            console.error("Error setting countries");
            throw new Error("Error setting countries");
          }

          console.log("jsonData is false");
        }
      } catch {
        console.log("jsonData is false");
      }
    };

    const promises = [fetchAllConfigLanguages(), fetchAllConfigCountries()];

    Promise.all(promises)
      .then(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setFetchedLocal(true);
      })
      .catch(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setFetchedLocal(false);
      });
  }, []);

  const dropdownRuleData = useMemo(() => {
    if (!fetchedDB) {
      console.log("NOT BOTH FETCHED!");
      console.log(fetchedDB, " fetchedDB status");
      console.log(fetchedLocal, " fetchedLocal status");

      return {
        languages: [],
        countries: [],
        automationConditionDataDB: {
          tags: [],
          buckets: [],
          surveys: []
        }
      };
    }

    return {
      languages: languages,
      countries: countries,
      automationConditionDataDB: automationConditionDataDB
    };
  }, [
    fetchedDB,
    fetchedLocal,
    languages,
    countries,
    automationConditionDataDB
  ]);

  // get all automations
  useEffect(() => {
    // clearValidation();
    if (
      allAutomations.length === 0 ||
      allAutomations === null ||
      allAutomations === undefined ||
      saveConfirmed === true
    ) {
      if (clientID && studyID) {
        getAllAutomations(clientID, studyID).then((data) => {
          console.log(viewCreateRule);
          if (viewCreateRule) {
            // If we are creating a rule, don't set the selected rule id
            setSelectedRuleID("");
            handleSelectSingleAutomation("");
            console.log(3);

            return;
          }

          if (typeof data !== "string") {
            setAllAutomations(data);

            // Finished editing a rule, fetch the same rule
            if (saveConfirmed === true) {
              // select the last rule in the array
              handleSelectSingleAutomation(
                ruleAtBottom
                  ? (data[data.length - 1].id as string) || ""
                  : selectedRuleID
              );
              setSaveConfirmed(false);
              console.log(2);
            } else {
              setSaveConfirmed(false);
              setSelectedRuleID(data[0].id);
              handleSelectSingleAutomation(data[0].id);
              console.log(1);
            }
          } else {
            setSelectedRuleID("");
            setLoadingSingleAutomation(false);
          }
        });
      }
    }

    // setAllAutomations(automationData.rules.map((rule) => rule));
  }, [allAutomations, saveConfirmed, viewEditRule, viewCreateRule]);

  // Clear selection and validation when switching between rules
  const clearValidation = async () => {
    setValidActionData(false);
    setValidConditionData(false);
    setValidTimingData(false);

    // clear childConditionGroups, actions and timings

    setChildConditionGroups({
      "0": {
        operator: "and",
        childRules: [
          {
            id: "0",
            type: "",
            variable: "",
            selection: "",
            options: "",
            value: ""
          }
        ], // This is now an array
        childConditionGroups: {}
      }
    });

    setActions([]);
    setTiming({
      type: "noSchedule",
      actionMultipleTimes: false,
      timing: {
        participantLocalTime: "",
        date: ""
      }
    });
  };

  // get single automation
  const handleSelectSingleAutomation = async (ruleID: string) => {
    setLoadingSingleAutomation(true);

    // clear childConditionGroups and actions
    await clearValidation();
    setSelectedRuleID(ruleID);

    if (ruleID && clientID && studyID) {
      console.log("FOUND");
      await getAutomation(clientID, studyID, ruleID).then((data) => {
        if (typeof data !== "string") {
          // Set single automation data
          setChildConditionGroups(data.conditions);
          setActions(data.actions);
          setTiming(data.timing);
          setSelectedStatus(data.status);
          setWorkflowRuleName(data.name);

          // Set validation
          setRuleSelection(data.name);
          setFoundAutomation(true);
        }
        setLoadingSingleAutomation(false);
      });
    } else {
      setLoadingSingleAutomation(false);
    }
  };

  // useEffect that selects the rule after cancel was pressed on the edit rule view
  useEffect(() => {
    // Going to edit a rule, fetch the same rule on cancel
    if (viewEditRule === false && viewCreateRule === false && selectedRuleID) {
      console.log("FIRING");
      try {
        if (selectedRuleID && selectedRuleID !== "") {
          handleSelectSingleAutomation(selectedRuleID);
        }
      } catch {
        console.log("No automations");
      }
    }
  }, [viewEditRule, viewCreateRule]);

  // Select Single rule
  const handleCheckboxChange = (ruleID: string) => {
    setSelectedRules((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(ruleID);

      if (isAlreadySelected) {
        // If the ruleID is already selected, remove it from the array
        return prevSelected.filter((id) => id !== ruleID);
      } else {
        // If the ruleID is not selected, add it to the array
        return [...prevSelected, ruleID];
      }
    });
  };

  const handleSelectAllCheckboxChange = () => {
    const allRulesSelected = selectedRules.length === allAutomations.length;
    if (allRulesSelected) {
      // If all rules are already selected, remove them from the array
      setSelectedRules([]);
    } else {
      // If not all rules are selected, add them to the array
      setSelectedRules(allAutomations.map((rule) => rule.id));
    }
  };

  const handleStudyFilter = (study: string) => {
    setStudyFilter(study);
    if (study) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  };

  // Switch to create rule
  const handleCreateRuleView = () => {
    console.log("clicked");
    console.log(viewCreateRule);
    console.log(viewEditRule);
    if (viewCreateRule || viewEditRule) {
      setViewCreateRule(false);
      setViewEditRule(false);
    } else {
      setViewCreateRule(!viewCreateRule);
      // clear selected rules and selected rule id
      setSelectedRules([]);
      setSelectedRuleID("");
      setRuleAtBottom(true);
    }
  };

  const handleEditRuleView = (ruleID: string) => {
    console.log("clicked");

    if (ruleID === "") {
      Swal.fire({
        icon: "error",
        title: "No automations selected",
        text: "Please select automations to edit"
      });
      return;
    }
    setViewEditRule(!viewEditRule);
    setViewCreateRule(false);
    setRuleAtBottom(false);

    console.log(viewEditRule);
  };
  // Delete automations
  const handleDeleteAutomations = async (ruleIDs: string[]) => {
    try {
      if (ruleIDs.length === 0 || ruleIDs === undefined || ruleIDs[0] === "") {
        Swal.fire({
          icon: "error",
          title: "No automations selected",
          text: "Please select automations to delete"
        });
        return;
      }
      const { value: confirm } = await (ruleIDs.length === 1
        ? Swal.fire({
            title: "Are you sure?",
            text: "You are about to delete an automation",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          })
        : Swal.fire({
            title: `Are you sure you want to delete ${ruleIDs.length} automations?`,
            html: `
            <div>         
            <label>Please enter "${ruleIDs.length}" to confirm your action.</label>
            <input id="swal-input1" class="swal2-input" style="margin-left: 10px; height:2.5rem; width:10rem;" placeholder="Enter amount">
            </div>`,
            preConfirm: () => {
              const inputValue = (
                document.getElementById("swal-input1") as HTMLInputElement
              ).value;
              if (inputValue !== ruleIDs.length.toString()) {
                Swal.showValidationMessage("Please type the correct number");
              }
            },
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
          }));
      if (confirm) {
        if (clientID && studyID) {
          deleteAutomations(clientID, studyID, ruleIDs).then((data) => {
            if (data === "202" || data === "successfulBulk") {
              // If the rules were deleted successfully, reset the selected rules
              if (data === "202") {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: `Automations has been deleted successfully`,
                  confirmButtonColor: "#3085d6"
                });
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: `Job has been scheduled to delete automations`,
                  confirmButtonColor: "#3085d6"
                });
              }
              setSelectedRules([]);
              getAllAutomations(clientID, studyID).then((data) => {
                if (typeof data !== "string") {
                  setAllAutomations(data);
                  setSelectedRuleID("");
                  setRuleSelection("");
                  clearValidation();
                  handleSelectSingleAutomation(data[0].id);
                } else {
                  setAllAutomations([]);
                  setSelectedRuleID("");
                  setRuleSelection("");
                  clearValidation();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete automation",
                text: data
              });
            }
          });
        } else {
          console.log("ERROR, invalid clientID or studyID");
        }
      }
    } catch (error) {
      console.log(error, "Error deleting tag");
    }
  };

  useEffect(() => {
    if (dropdownRuleData && dropdownRuleData.automationConditionDataDB) {
      console.log("selectedRules: " + selectedRules);
      console.log(dropdownRuleData.automationConditionDataDB.surveys);
    }
  }, [selectedRules]);

  // log timing
  useEffect(() => {
    console.log(timing);
  }, [timing]);

  return (
    <>
      {loading || loadingLanguages || loadingCountries || !fetchedDB ? (
        <div className="d-flex justify-content-center custom_spinner_container">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : viewCreateRule || viewEditRule ? (
        <>
          {/* CREATE RULE */}
          <div className="automations_create_rule_container col-12">
            {dropdownRuleData &&
              dropdownRuleData.automationConditionDataDB &&
              (loadingSingleAutomation ? (
                <div className="d-flex justify-content-center custom_spinner_container">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : (
                <CreateRule
                  handleCreateRuleView={handleCreateRuleView}
                  dropdownRuleData={dropdownRuleData}
                  setSaveConfirmed={setSaveConfirmed}
                  allAutomations={allAutomations}
                  viewEditRule={viewEditRule}
                  timing={timing}
                  actions={actions}
                  childConditionGroups={childConditionGroups}
                  setTiming={setTiming}
                  setActions={setActions}
                  setChildConditionGroups={setChildConditionGroups}
                  workflowRuleName={workflowRuleName}
                  setWorkflowRuleName={setWorkflowRuleName}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  originalRuleName={ruleSelection}
                  selectedRuleID={selectedRuleID}
                />
              ))}
          </div>
        </>
      ) : (
        <>
          {/* LEFT SIDE */}
          <div className={`automation_rules_container col-3`}>
            <div className="automation_rules_filters">
              <div className="checkbox_drop_search_container">
                {/* CHECKBOX */}
                <div className="checkbox_select d-flex align-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    className="select_all_checkbox"
                    onChange={handleSelectAllCheckboxChange}
                    checked={
                      selectedRules.length === allAutomations.length &&
                      allAutomations.length !== 0
                    }
                  />
                </div>
                {/* DROPDOWN */}
                {/* <div className="dropdown dropdown_studies">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    onClick={() => handleStudiesDropdown(!studiesDropdown)}
                  >
                    {studyFilter ? studyFilter : "Copy to"}
                  </button>
                  <div
                    className={`dropdown-menu ${
                      studiesDropdown ? "show" : "hide"
                    }`}
                  >
                    <input
                      type="text"
                      // placeholder="Search Tags" x
                      value={studyFilter}
                      onChange={(e) => handleStudyFilter(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* SEARCH */}
                <div className="search_container">
                  <Search
                    onSearchChange={setAutomationsSearch}
                    filtersCleared={!filterApplied}
                  />
                </div>
              </div>

              <div className="d-flex align-center icons_container">
                {(hasPermission("subject") ||
                  hasPermission("automation", "all") ||
                  hasPermission("automation", "write")) &&
                  !loadingSingleAutomation && (
                    <div className="icon_add" onClick={handleCreateRuleView}>
                      <IconAdd />
                    </div>
                  )}
                {(hasPermission("subject") ||
                  hasPermission("automation", "all") ||
                  hasPermission("automation", "delete")) && (
                  <div
                    className="icon_delete"
                    onClick={() => handleDeleteAutomations(selectedRules)}
                  >
                    <IconDelete />
                  </div>
                )}
              </div>
            </div>
            {/* Map automations singles based on automationData */}
            <div className="all_automations_container">
              {allAutomations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((automation, index) => {
                  if (
                    automation.name
                      .toLowerCase()
                      .includes(automationsSearch.toLowerCase())
                  ) {
                    return (
                      <AutomationRuleSingle
                        key={`automation_rule_${index}`}
                        isSelected={selectedRuleID === automation.id}
                        ruleID={automation.id}
                        handleSelectSingleAutomation={
                          handleSelectSingleAutomation
                        }
                        ruleSelection={automation.name}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedRules={selectedRules}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              {allAutomations.filter((automation) =>
                automation.name
                  .toLowerCase()
                  .includes(automationsSearch.toLowerCase())
              ).length === 0 && (
                <div className="text-center">No results found</div>
              )}
            </div>
          </div>

          {/* RIGHT SIDE */}
          <div className="automations_previews_container col-9">
            {loadingSingleAutomation ? (
              <div className="d-flex justify-content-center custom_spinner_container">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <AutomationsPreview
                selectedRuleID={selectedRuleID}
                ruleSelection={ruleSelection}
                dropdownRuleData={dropdownRuleData}
                childConditionGroups={childConditionGroups}
                setChildConditionGroups={setChildConditionGroups}
                actions={actions}
                timing={timing}
                foundAutomation={foundAutomation}
                status={selectedStatus}
                validActionData={validActionData}
                validConditionData={validConditionData}
                validTimingData={validTimingData}
                setValidActionData={setValidActionData}
                setValidConditionData={setValidConditionData}
                setValidTimingData={setValidTimingData}
                allAutomations={allAutomations}
                handleEditRuleView={handleEditRuleView}
                handleSelectSingleAutomation={handleSelectSingleAutomation}
                handleDeleteAutomations={handleDeleteAutomations}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AutomationRulesContainer;
