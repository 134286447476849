import { useNavigate } from "react-router-dom";
import "./error.scss";

interface ErrorPageProps {
  error?: string;
  errorCode?: string;
  debugCode?: string[];
  contactSupport?: boolean;
  goHome?: boolean;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  error,
  errorCode,
  debugCode,
  contactSupport,
  goHome
}) => {
  const navigate = useNavigate();

  const errorCodeText = errorCode ? errorCode : "404";
  const errorText = error ? error : "Page Not Found";

  // console.log("ErrorPage", error, errorCode, debugCode);

  return (
    <div
      id="error_page"
      className={`container-fluid ${error ? "custom" : "default"}`}
    >
      <div id="error_card" className="">
        <h1>{errorCodeText}</h1>
        <p>
          {errorText}
          {contactSupport && (
            <>
              <br />
              Please contact support
            </>
          )}
        </p>
        {goHome !== false && (
          <button
            className="btn btn-primary"
            onClick={() => navigate("/clients")}
          >
            Go home
          </button>
        )}
        {debugCode && (
          <p id="debug_code">
            {debugCode.map((code, index) => (
              <span key={index + code}>ID: {code}</span>
            ))}
          </p>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
