import { useState } from "react";
import { useParams } from "react-router-dom";
import TagsList from "./TagsList/tagsList.component";
import "./tags.scss";

export type TagsProps = {
  studyClicked: string;
};

type RouteParams = {
  clientID: string;
  studyID?: string; // Optional if you think it might not always be present
};

const Tags: React.FC<TagsProps> = ({}) => {
  const { clientID, studyID } = useParams<RouteParams>(); // Read values passed on state
  const [loading, setLoading] = useState(true); // Create state variable for loading status
  const [selectedTagID, setSelectedTagID] = useState("1");

  return (
    <div className="tags_header_container">
      {/* HEADER */}
      <div className="tags_header">
        <div className="headers_group">
          <div className="header_item tags_header_item">
            <h5 className="header_title underline">Tags</h5>
          </div>
          <div className="header_item col-8">
            <h5 className="header_title underline">Tag Details</h5>
          </div>
        </div>
      </div>

      {/* List */}
      <div className="content_container">
        <div className="content_row">
          <TagsList clientID={clientID} />
          {/* <AutomationRulesContainer
            setSelectedRuleID={setSelectedTagID}
            selectedRuleID={selectedTagID}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Tags;
