import { Client } from "../../types.ts";
import "./displayClients.scss";

// client card component, props will be the client object
const ClientCard = ({
  client,
  index,
  onClick
}: {
  client: Client;
  index: number;
  onClick: (client: Client) => void;
}) => {
  const handleClientClick = () => {
    // Call the onClick callback and pass the client object
    onClick(client);
  };

  return (
    <div className="col-6 col-sm-4 col-md-3 col-lg-2">
      <div
        className="card client_card custom_card clickable mb-4 card_background"
        key={index}
        onClick={() => handleClientClick()}
        style={{
          backgroundImage: `url(${
            client.img_url
              ? client.img_url
              : `/images/logos/logos_clients/default.webp`
          })`
        }}
      >
        <div className="card-body">
          <h5 className="card-title">{client.name}</h5>
          {/* <p className="card-text">{client.id}</p> */}
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
