import React, { useEffect, useRef, useState } from "react";
import timeJsonData from "../../../../assets/data/times.json";
import "./timing.scss";
// import ruleJsonData from "../../../../assets/data/rules.json";
import Swal from "sweetalert2";
import { Rules, Timings } from "../../../../types";
import { convertIDToName } from "../../../../utilities/automation.util";
import { capitalize, useOutsideClick } from "../../../../utilities/utils";

export type TimingProps = {
  setTiming: React.Dispatch<React.SetStateAction<Timings>>;
  allAutomations: Rules[];
  viewEditRule: boolean;
  timing: Timings;
  selectedRuleID: string;
};

export type Times = {
  id: string;
  name: string;
};

const Timing: React.FC<TimingProps> = ({
  // timingType, timingValue
  // timingID,
  setTiming,
  timing,
  allAutomations,
  viewEditRule,
  // For filtering out the current rule from the dropdown
  selectedRuleID
}) => {
  // From existing automation (prereq rule)
  const [automationName, setAutomationName] = useState<string>("");

  // Action Multiple Times
  const [actionMultipleTimes, setActionMultipleTimes] =
    useState<boolean>(false);

  // Select participant's local time.
  const [selectedTiming, setSelectedTiming] = useState<string>("");
  const [timingDropdown, setTimingDropdown] = useState(false);

  // For selecting Time/Prerequisite Rule (radio buttons)
  const [timingOption, setTimingOption] = useState<string>("");

  const [inputDate, setInputDate] = useState<string>("");
  const [inputTimeAfter, setInputTimeAfter] = useState<string>("");

  const [timeJsonDate, setTimeJsonDate] = useState<Times[]>([]);
  const [timeFilter, setTimeFilter] = useState("");

  const [arrayRules, setArrayRules] = useState<Rules[]>([]);
  const [selectedPrerequisiteRule, setSelectedPrerequisiteRule] =
    useState<string>("");
  const [selectedPrerequisiteRuleDisplay, setSelectedPrerequisiteRuleDisplay] =
    useState<string>("");
  const [prerequisiteRuleDropdown, setPrerequisiteRuleDropdown] =
    useState(false);
  const [ruleFilter, setRuleFilter] = useState("");

  const [selectedTimeFiring, setSelectedTimeFiring] = useState<string>("");
  const [timeFiringDropdown, setTimeFiringDropdown] = useState(false);

  // Event handlers
  // RADIO BUTTON SELECT
  const handleSetTimingOption = (option: string) => {
    console.log("FIRING");
    // reset options
    setSelectedTiming("");
    setInputDate("");

    setInputTimeAfter("");
    setSelectedPrerequisiteRule("");
    setSelectedPrerequisiteRuleDisplay("");
    setSelectedTimeFiring("");

    setTimingOption(option);
    console.log(option);
    if (option === "dateTime" || option === "") {
      setTiming({
        type: option,
        actionMultipleTimes: actionMultipleTimes,
        timing: {
          participantLocalTime: "",
          date: "daily"
        }
      });
    } else if (option === "prerequisite") {
      setTiming({
        type: option,
        actionMultipleTimes: actionMultipleTimes,
        timing: {
          automationID: "",
          fireType: "",
          value: ""
        }
      });
    } else if (option === "noSchedule") {
      setTiming({
        type: option ? option : "noSchedule",
        actionMultipleTimes: actionMultipleTimes,
        timing: {
          participantLocalTime: "",
          date: ""
        }
      });
    } else {
      setTiming({
        type: option ? option : "noSchedule",
        actionMultipleTimes: actionMultipleTimes,
        timing: {
          participantLocalTime: "",
          date: ""
        }
      });
    }
  };

  //================== Select participant's local time. ==================
  const handleTimingDropdown = (show: boolean) => {
    setTimingDropdown(show);
  };

  const localTimingRef = useRef<HTMLDivElement>(null);
  useOutsideClick(localTimingRef, () => {
    // Action to take on outside click
    handleTimingDropdown(false);
  });

  // Selection
  const handleSelectTiming = (localTiming: string) => {
    setSelectedTiming(localTiming);
    setTiming({
      type: "dateTime",
      actionMultipleTimes: actionMultipleTimes,
      timing: {
        participantLocalTime: localTiming,
        date: inputDate
      }
    });
    setTimingDropdown(false);
  };

  // Filtering
  const handleTimeSearchFilter = (filter: string) => {
    setTimeFilter(filter);
    setTimeJsonDate(
      timeJsonData.time.filter((time) =>
        time.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
  };

  // Schedule Daily?
  const onDateChange = (e: any) => {
    setInputDate(e.target.value);
    setTiming({
      type: "dateTime",
      actionMultipleTimes: actionMultipleTimes,
      timing: {
        participantLocalTime: selectedTiming,
        date: e.target.value
      }
    });
    setTimingDropdown(false);
  };

  // ================== Set timer from prerequisite rule firing. ==================
  const handleRuleFiringTimeDropdown = (show: boolean) => {
    setTimeFiringDropdown(show);
  };

  const fireAfterRef = useRef<HTMLDivElement>(null);
  useOutsideClick(fireAfterRef, () => {
    // Action to take on outside click
    handleRuleFiringTimeDropdown(false);
  });

  const handleSelectRuleFiringTime = (time: string) => {
    setSelectedTimeFiring(time);
    setTiming({
      type: "prerequisite",
      actionMultipleTimes: actionMultipleTimes,
      timing: {
        automationID: selectedPrerequisiteRule,
        fireType: time.toLowerCase(),
        value: inputTimeAfter
      }
    });
    setTimeFiringDropdown(false);
  };

  const onTimeAfterChange = (value: any) => {
    setInputTimeAfter(value);
    if (viewEditRule === false) {
      setTiming({
        type: "prerequisite",
        actionMultipleTimes: actionMultipleTimes,
        timing: {
          automationID: selectedPrerequisiteRule,
          fireType: selectedTimeFiring,
          value: value
        }
      });
    } else {
      // Because of onchange, only want this to set when viewEditRule is true
      if ("automationID" in timing.timing) {
        setTiming({
          type: "prerequisite",
          actionMultipleTimes: actionMultipleTimes,
          timing: {
            automationID: timing.timing.automationID,
            fireType: timing.timing.fireType,
            value: value
          }
        });
      }
    }
    console.log(value);
    console.log(inputTimeAfter);
  };

  // ================== Prerequisite Rules ==================
  const handlePrerequisiteRuleDropdown = (show: boolean) => {
    setPrerequisiteRuleDropdown(show);
  };

  const preReqRef = useRef<HTMLDivElement>(null);
  useOutsideClick(preReqRef, () => {
    // Action to take on outside click
    handlePrerequisiteRuleDropdown(false);
  });

  const handleSelectPrerequisiteRule = (ruleID: string, ruleName: string) => {
    setSelectedPrerequisiteRule(ruleID);
    setSelectedPrerequisiteRuleDisplay(ruleName);
    setTiming({
      type: "prerequisite",
      actionMultipleTimes: actionMultipleTimes,
      timing: {
        automationID: ruleID,
        fireType: selectedTimeFiring,
        value: inputTimeAfter
      }
    });
    setPrerequisiteRuleDropdown(false);
  };

  const handleRuleSearchFilter = (filter: string) => {
    setRuleFilter(filter);
    setArrayRules(
      allAutomations.filter((rule) =>
        rule.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
  };

  // No Schedule is selected by default
  useEffect(() => {
    if (viewEditRule === false) {
      handleSetTimingOption("noSchedule");
      setTiming({
        type: "noSchedule",
        actionMultipleTimes: false,
        timing: {
          participantLocalTime: "",
          date: ""
        }
      });
    }
  }, []);

  // ================== Action Multiple Times ==================
  const handleSetActionMultipleTimes = async (actionMultipleTimes: boolean) => {
    let actionMultipleTimesValue = actionMultipleTimes;
    if (actionMultipleTimes === true) {
      await Swal.fire({
        title: "Are you sure?",
        html: "This is at risk to put users in <strong>infinite loops</strong> where automation will continuously run for users.<br />Please ensure your automation structure will not allow loops<br /><br /><strong>Are you sure you want to continue?</strong>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          actionMultipleTimesValue = true;
        } else {
          actionMultipleTimesValue = false;
        }
      });
    } else {
      actionMultipleTimesValue = false;
    }

    setActionMultipleTimes(actionMultipleTimesValue);

    setTiming({
      type: timingOption,
      actionMultipleTimes: actionMultipleTimesValue,
      timing: {
        participantLocalTime: selectedTiming,
        date: inputDate
      }
    });
  };

  // Editing Rule

  // Setting existing rule data
  useEffect(() => {
    console.log(timing);
    if (viewEditRule === true) {
      // Action Multiple Times
      setActionMultipleTimes(timing.actionMultipleTimes);
      // ========================= DATE/TIME =========================
      if (
        timing.type === "dateTime" &&
        "participantLocalTime" in timing.timing
      ) {
        setTimingOption("dateTime");
        setSelectedTiming(timing.timing.participantLocalTime);
        setInputDate(timing.timing.date);
      }
      // ========================= PREREQUISITE RULE =========================
      else if (
        timing.type === "prerequisite" &&
        "automationID" in timing.timing
      ) {
        console.log("HIT");
        setTimingOption("prerequisite");
        // get automation name with id
        const autoName = convertIDToName(
          "automation",
          timing.timing.automationID,
          allAutomations
        );

        if (!autoName) {
          console.log("NO NAME");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Automation name not found. Please select another automation."
          });
          setTimingOption("noSchedule");
          return;
        }

        setAutomationName(autoName);
        console.log(autoName);

        console.log(timing.timing);
        console.log(timing.timing.automationID);
        setSelectedPrerequisiteRuleDisplay(autoName);
        setSelectedPrerequisiteRule(timing.timing.automationID);
        setSelectedTimeFiring(capitalize(timing.timing.fireType));
        setInputTimeAfter(timing.timing.value);

        // handleSelectPrerequisiteRule(
        //   timing.timing.automationID,
        //   automationName
        // );
        onTimeAfterChange(timing.timing.value);
        // handleSelectRuleFiringTime(timing.timing.fireType);
      } // ========================= NO SCHEDULE =========================
      else {
        setTimingOption("noSchedule");
      }
    }
  }, [viewEditRule]);

  // Set jsonDataDate and arrayRules to the data from the json files
  useEffect(() => {
    console.log("TRIGGERED");
    if (timeJsonDate.length === 0) {
      setTimeJsonDate(timeJsonData.time);
    }

    console.log(selectedRuleID);

    console.log(allAutomations);

    // Only filter out the rule if editing the automation
    if (viewEditRule === true) {
      const filteredRules = allAutomations.filter(
        (rule) => rule.id !== selectedRuleID
      ); // filter out the rule with the originalRuleID
      setArrayRules(filteredRules);
      console.log(filteredRules);
    } else {
      setArrayRules(allAutomations);
    }
  }, [allAutomations]);

  // log timing
  useEffect(() => {
    console.log(timing);
  }, [timing]);

  return (
    <div className="d-flex single_timing_container">
      {/* ================ SELECTING DATE/TIME OR PREREQUISITE ================  */}
      <div className="timing_radio_container">
        {/* DATE/TIME */}
        <div className="form-check date_time_radio">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDateTime"
            checked={timingOption === "dateTime"}
            onChange={() => handleSetTimingOption("dateTime")}

            // if input is clicked, set timingOption to dateTime
          ></input>
          <label className="form-check-label" htmlFor="flexRadioDateTime">
            Date/Time
          </label>
        </div>
        {/* PREREQUISITE RULE */}
        <div className="form-check prereq_radio">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioPre"
            checked={timingOption === "prerequisite"}
            onChange={() => handleSetTimingOption("prerequisite")}

            // if
          ></input>
          <label className="form-check-label" htmlFor="flexRadioPre">
            Prerequisite Rule
          </label>
        </div>
        {/* No SCHEDULE */}
        <div className="form-check none_radio">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioNone"
            checked={timingOption === "noSchedule"}
            onChange={() => handleSetTimingOption("noSchedule")}
            // if
          ></input>
          <label className="form-check-label" htmlFor="flexRadioNone">
            No Schedule
          </label>
        </div>
      </div>

      {/* ================ DATE TIME SELECTED ================*/}
      {timingOption === "dateTime" ? (
        <div className="date_time_container pre_req">
          <p className="date_time_p first_p">
            Fill this if you want to schedule your automation. At the selected
            time, system will check the rules.
          </p>
          <p className="date_time_p">Select participant's local time.</p>

          {/* SELECT PARTICIPANT'S LOCAL TIME */}
          <div className="dropdown dropdown_timing" ref={localTimingRef}>
            <button
              className="btn btn-primary dropdown-toggle timing_dropdown_button"
              onClick={() => handleTimingDropdown(!timingDropdown)}
            >
              {selectedTiming ? selectedTiming : "Select Time"}
            </button>

            <div
              className={`dropdown-menu ${timingDropdown ? "show" : "hide"}`}
            >
              <input
                type="text"
                placeholder="Search Time"
                value={timeFilter}
                onChange={(e) => handleTimeSearchFilter(e.target.value)}
                className="dropdown-timing-search"
              />
              <div className="dropdown-items">
                {timeJsonDate.map((timing) => (
                  <div key={timing.id} className="dropdown-item">
                    <li onClick={() => handleSelectTiming(timing.name)}>
                      <a>{timing.name}</a>
                    </li>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <p className="date_time_p">
            If date is not selected, the schedule will be daily.
          </p>
          <input
            type="date"
            name="datePicker"
            id="datePicker"
            className="form-control"
            value={inputDate}
            onChange={(e) => onDateChange(e)}
          />
        </div>
      ) : // ================ PREREQUISITE RULE SELECTED ================
      timingOption === "prerequisite" ? (
        <div className="date_time_container">
          <p className="date_time_p first_p">
            Fill this if you want to fire automation within a time period of
            another automation firing.
          </p>
          <p className="date_time_p">
            Select a rule which this rule will be fire after.
          </p>

          {/* RULE DROPDOWN */}
          <div className="dropdown dropdown_timing" ref={preReqRef}>
            <button
              className="btn btn-primary dropdown-toggle timing_dropdown_button"
              onClick={() =>
                handlePrerequisiteRuleDropdown(!prerequisiteRuleDropdown)
              }
            >
              {viewEditRule ? (
                <>
                  {selectedPrerequisiteRuleDisplay
                    ? selectedPrerequisiteRuleDisplay
                    : "Select Prerequisite Rule"}
                </>
              ) : (
                <>
                  {selectedPrerequisiteRuleDisplay
                    ? selectedPrerequisiteRuleDisplay
                    : "Select Prerequisite Rule"}
                </>
              )}
            </button>

            <div
              className={`dropdown-menu ${
                prerequisiteRuleDropdown ? "show" : "hide"
              }`}
            >
              {/* RULE FILTER */}
              <input
                type="text"
                placeholder="Search Rule"
                value={ruleFilter}
                onChange={(e) => handleRuleSearchFilter(e.target.value)}
                className="dropdown-timing-search"
              />
              {/* RULE DROPDOWN ITEMS */}
              <div className="dropdown-items">
                {arrayRules.length > 0 ? (
                  (console.log(allAutomations),
                  (console.log(arrayRules),
                  arrayRules.map((rule) => (
                    <div key={rule.id} className="dropdown-item">
                      <li
                        onClick={() =>
                          handleSelectPrerequisiteRule(rule.id, rule.name)
                        }
                      >
                        <a>{rule.name}</a>
                      </li>
                    </div>
                  ))))
                ) : (
                  <div className="no-results-found text-center">
                    No results found
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* RULE TIMER */}
          <p className="date_time_p">
            Set timer from prerequisite rule firing.
          </p>

          {/* FIRE RULING DROPDOWN */}
          <div className="rule_fire_container">
            {/* NUMBER INPUT */}
            <input
              type="text"
              name="TimeAfterPicker"
              id="TimeAfterPicker"
              className="form-control"
              value={inputTimeAfter}
              onChange={(e) => {
                if (e.target.value.match(/^\d*$/))
                  onTimeAfterChange(e.target.value);
              }}
            />

            {/* SELECT WHEN RULE FIRES */}
            <div
              className="dropdown dropdown_timing pre_req"
              ref={fireAfterRef}
            >
              <button
                className="btn btn-primary dropdown-toggle timing_dropdown_button"
                onClick={() =>
                  handleRuleFiringTimeDropdown(!timeFiringDropdown)
                }
              >
                {selectedTimeFiring
                  ? capitalize(selectedTimeFiring)
                  : "Select Time"}
              </button>
              <div
                className={`dropdown-menu ${
                  timeFiringDropdown ? "show" : "hide"
                }`}
              >
                <div className="dropdown-items">
                  <div className="dropdown-item">
                    <li onClick={() => handleSelectRuleFiringTime("Hour")}>
                      <a>Hour</a>
                    </li>
                  </div>
                  <div className="dropdown-item">
                    <li onClick={() => handleSelectRuleFiringTime("Day")}>
                      <a>Day</a>
                    </li>
                  </div>
                  <div className="dropdown-item">
                    <li onClick={() => handleSelectRuleFiringTime("Minute")}>
                      <a>Minute</a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : // ================ NO SCHEDULE SELECTED ================
      timingOption === "noSchedule" ? (
        <div className="date_time_container">
          <p className="date_time_p">
            This automation will not be scheduled. It will be triggered once the
            conditions have been met.
          </p>
        </div>
      ) : null}

      <div className="form-check action_multiple_radio">
        <input
          className="form-check-input"
          type="checkbox"
          name="flexRadioDefault"
          id="flexRadioActionMultiple"
          checked={actionMultipleTimes}
          onChange={() => handleSetActionMultipleTimes(!actionMultipleTimes)}

          // if input is clicked, set timingOption to dateTime
        ></input>
        <label className="form-check-label" htmlFor="flexRadioActionMultiple">
          Action this multiple times for each user
        </label>
      </div>
    </div>
  );
};

export default Timing;
