import React from "react";
import { useParams } from "react-router-dom";
import ParticipantResetPassword from "../../components/ParticipantResetPassword/participantResetPassword.component";

const ParticipantResetPasswordPage: React.FC = () => {
  const { token } = useParams();
  return !token || token === "" ? (
    <div className="container">
      <h3 className="text-danger">
        No token provided in link. Please check your email/SMS and try again.
      </h3>
    </div>
  ) : (
    <ParticipantResetPassword token={token} />
  );
};

export default ParticipantResetPasswordPage;
