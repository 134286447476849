// CountryFilter.tsx

import React, { useEffect, useRef, useState } from "react";
import { Country } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface CountryFilterProps {
  jsonCountriesData: Country[];
  onCountryFilterChange: (Country: string[]) => void;
  filtersCleared: boolean;
  location: string;
}

const CountryFilter: React.FC<CountryFilterProps> = ({
  jsonCountriesData,
  onCountryFilterChange,
  filtersCleared,
  location
}) => {
  const [countryFilter, setCountryFilter] = useState<string>("");
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [participantsCountryFilter, setParticipantsCountryFilter] = useState<
    Record<string, boolean>
  >({});

  const handleCountryDropdown = (show: boolean) => {
    // show/hide dropdown
    setCountryDropdown(show);
  };

  const handleCountrySearchFilter = (search: string) => {
    // set search value
    setCountryFilter(search);
  };

  const handleCountryFilter = (country: string, checked: boolean) => {
    // set Country filter
    setParticipantsCountryFilter((prevCountry) => ({
      ...prevCountry,
      [country]: checked // set checked status for this Country
    }));
  };

  useEffect(() => {
    // send Country filter to parent component
    onCountryFilterChange(
      Object.keys(participantsCountryFilter).filter(
        (country) => participantsCountryFilter[country] // filter Country that are true
      )
    );
  }, [participantsCountryFilter, onCountryFilterChange]);

  useEffect(() => {
    // clear Country filter when filters are cleared
    if (filtersCleared) {
      setCountryFilter("");
      setParticipantsCountryFilter({});
      handleCountrySearchFilter("");
      // handleCountryDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handleCountryDropdown(false);
  });

  return (
    <div className="dropdown dropdown_Country" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleCountryDropdown(!countryDropdown)}
      >
        Country
      </button>
      <div className={`dropdown-menu ${countryDropdown ? "show" : "hide"}`}>
        {jsonCountriesData && jsonCountriesData.length === 0 ? (
          <div className="dropdown_menu_item">
            <span>No Countries</span>
          </div>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search Country"
              value={countryFilter}
              onChange={(e) => handleCountrySearchFilter(e.target.value)}
            />
            <div className="dropdown_menu_items">
              {
                /* filter by Countryfilter set by input */
                /* loop through all countries */
                jsonCountriesData.map((country: Country, index: number) => {
                  if (
                    country.name
                      .toLowerCase()
                      .includes(countryFilter.toLowerCase())
                  ) {
                    return (
                      <div key={index} className="dropdown_menu_item">
                        <label
                          htmlFor={`${location}_country_${country.name}`}
                          className="form_control"
                        >
                          <input
                            type="checkbox"
                            id={`${location}_country_${country.name}`}
                            name={country.name}
                            value={country.iso_code}
                            checked={
                              participantsCountryFilter[country.iso_code] ||
                              false
                            } // use checked property here
                            onChange={(e) =>
                              handleCountryFilter(
                                e.target.value,
                                e.target.checked
                              )
                            }
                          />
                          {country.name}
                        </label>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CountryFilter;
