import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { FetchResponseError, Researcher } from "../types";

function isResearcher(obj: any): obj is Researcher {
  const validID = typeof obj.id === "string";
  const validFirstName = typeof obj.first_name === "string";
  const validSurname = typeof obj.surname === "string";
  const validEmail = typeof obj.email === "string";
  const validFullMobile = typeof obj.full_mobile === "string";
  const validMobileCode = typeof obj.mobile_code === "string";
  const validLanguageIso = typeof obj.language_iso === "string";
  const validCountryIso = typeof obj.country_iso === "string";
  const validTimezone = typeof obj.timezone === "string";
  const validRoleId = typeof obj.role_id === "string";
  // const validMultiFactorAuth = typeof obj.multi_factor_auth === "boolean";
  const validLastLogin =
    typeof obj.last_login === "string" || obj.last_login === null;
  const validCreatedAt = typeof obj.created_at === "string";
  const validProfilePicture =
    typeof obj.profile_picture === "string" ||
    obj.profile_picture === undefined ||
    obj.profile_picture === null;
  // blocked
  const validBlocked = typeof obj.blocked === "boolean";

  const AllValid =
    validID &&
    validFirstName &&
    validSurname &&
    validEmail &&
    validFullMobile &&
    validMobileCode &&
    validLanguageIso &&
    validCountryIso &&
    validTimezone &&
    validRoleId &&
    // validMultiFactorAuth &&
    validLastLogin &&
    validCreatedAt &&
    validProfilePicture &&
    validBlocked;

  if (!AllValid) {
    console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Researcher`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validFirstName) {
      if (!obj.hasOwnProperty("researcher_name")) {
        errors.push("missing researcher_name");
      } else {
        errors.push(`Invalid type for researcher_name`);
      }
    }

    if (!validSurname) {
      if (!obj.hasOwnProperty("surname")) {
        errors.push("missing surname");
      } else {
        errors.push(`Invalid type for surname`);
      }
    }

    if (!validEmail) {
      if (!obj.hasOwnProperty("email")) {
        errors.push("missing email");
      } else {
        errors.push(`Invalid type for email`);
      }
    }

    if (!validFullMobile) {
      if (!obj.hasOwnProperty("full_mobile")) {
        errors.push("missing full_mobile");
      } else {
        errors.push(`Invalid type for full_mobile`);
      }
    }

    if (!validMobileCode) {
      if (!obj.hasOwnProperty("mobile_code")) {
        errors.push("missing mobile_code");
      } else {
        errors.push(`Invalid type for mobile_code`);
      }
    }

    if (!validLanguageIso) {
      if (!obj.hasOwnProperty("language_iso")) {
        errors.push("missing language_iso");
      } else {
        errors.push(`Invalid type for language_iso`);
      }
    }

    if (!validCountryIso) {
      if (!obj.hasOwnProperty("country_iso")) {
        errors.push("missing country_iso");
      } else {
        errors.push(`Invalid type for country_iso`);
      }
    }

    if (!validTimezone) {
      if (!obj.hasOwnProperty("timezone")) {
        errors.push("missing timezone");
      } else {
        errors.push(`Invalid type for timezone`);
      }
    }

    if (!validRoleId) {
      if (!obj.hasOwnProperty("role_id")) {
        errors.push("missing role_id");
      } else {
        errors.push(`Invalid type for role_id`);
      }
    }

    // if (!validMultiFactorAuth) {
    //   if (!obj.hasOwnProperty("multi_factor_auth")) {
    //     errors.push("missing multi_factor_auth");
    //   } else {
    //     errors.push(`Invalid type for multi_factor_auth`);
    //   }
    // }

    if (!validLastLogin) {
      if (!obj.hasOwnProperty("last_login")) {
        errors.push("missing last_login");
      } else {
        errors.push(`Invalid type for last_login`);
      }
    }

    if (!validCreatedAt) {
      if (!obj.hasOwnProperty("created_at")) {
        errors.push("missing created_at");
      } else {
        errors.push(`Invalid type for created_at`);
      }
    }

    if (!validProfilePicture) {
      if (!obj.hasOwnProperty("profile_picture")) {
        errors.push("missing profile_picture");
      } else {
        errors.push(`Invalid type for profile_picture`);
      }
    }

    if (!validBlocked) {
      if (!obj.hasOwnProperty("blocked")) {
        errors.push("missing blocked");
      } else {
        errors.push(`Invalid type for blocked`);
      }
    }

    console.log(`Invalid researcherID: ${obj.id}`);
    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `ResearcherID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Researcher`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}

export async function getAllResearchers(): Promise<
  Researcher[] | FetchResponseError
> {
  try {
    const response = await apiFetcher<any>("/researchers/get/all", "POST", {
      body: {}
    });

    if (response.status === 200 && response.data !== null) {
      // console.log(response.data);

      // THERE ARE RESEARCHERS
      if (
        Array.isArray(response.data) &&
        response.data.every((obj) => isResearcher(obj))
      ) {
        return response.data as Researcher[];
      } else {
        console.log("Invalid researcher data received");
        return {
          errorCode: response.status.toString(),
          errorMessage: "Invalid researcher data received"
        } as FetchResponseError;
      }
      // NO RESEARCHERS
    } else if (response.status === 404) {
      console.log("No researchers found");
      return [];
    } else {
      console.log("Failed to fetch all researchers");
      return {
        errorCode: "500",
        errorMessage: "Failed to fetch all researchers"
      } as FetchResponseError;
    }
  } catch (error) {
    console.error("An error occurred while fetching researchers:", error);
    return {
      errorCode: "500",
      errorMessage: "An error occurred while fetching researchers"
    } as FetchResponseError;
  }
}

export async function getResearcherByID(
  id: string
): Promise<Researcher | string> {
  try {
    const response = await apiFetcher<any>("/researchers/get/id", "POST", {
      body: {
        researcherID: id
      }
    });

    if (response.status === 200 && response.data !== null) {
      // console.log(response.data);

      // THERE ARE RESEARCHERS
      if (isResearcher(response.data)) {
        return response.data as Researcher;
      } else {
        console.log("Invalid researcher data received");
        return "Invalid researcher data received";
      }
      // NO RESEARCHERS
    } else if (response.status === 404) {
      console.log("No researchers found");
      return "No researchers found";
    } else {
      console.log("Failed to fetch all researchers");
      return "Failed to fetch all researchers";
    }
  } catch (error) {
    console.error("An error occurred while fetching researchers:", error);
    return "An error occurred while fetching researchers";
  }
}

export async function createResearcher(
  firstName: string,
  surname: string,
  email: string,
  fullMobile: string,
  countryISO: string,
  timezoneISO: string,
  languageISO: string,
  roleID: string
): Promise<string> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {
    const researcher = {
      first_name: firstName,
      last_name: surname,
      email: email,
      full_mobile: fullMobile,
      country_iso: countryISO,
      language_iso: languageISO,
      timezone_iso: timezoneISO,
      role_id: roleID
    };

    console.log(researcher);

    const response = await apiFetcher<any>("/researchers/create", "POST", {
      body: {
        researcher
      }
    });

    if (response.status === 202) {
      return "success";
    }

    if (response.status === 409) {
      return "Researcher already exists";
    }

    if (response.status === 500) {
      return "Server Error";
    }

    return "Unknown Error";
  } catch (error) {
    console.error("An error occurred while creating researcher:", error);
    return "An error occurred while creating researcher";
  }
}

export async function sendEditedResearcher(
  researcherID: string,
  researcherName: string,
  researcherSurName: string,
  researcherMobile: string,
  researcherCountryID: string,
  researcherLanguageID: string,
  researcherTimezone: string,
  researcherPassword: string,
  researcherRoleID: string
): Promise<string> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {
    const researcher = {
      id: researcherID,
      first_name: researcherName,
      last_name: researcherSurName,
      full_mobile: researcherMobile,
      country_iso: researcherCountryID,
      language_iso: researcherLanguageID,
      timezone_iso: researcherTimezone,
      role_id: researcherRoleID
      // password: researcherPassword,
    };

    const response = await apiFetcher<any>("/researchers/update", "POST", {
      body: {
        researcher
      }
    });

    if (response.status === 202) {
      return "success";
    }

    if (response.status === 409) {
      return "Researcher already exists";
    }

    if (response.status === 500) {
      return "Server Error";
    }

    return "Unknown Error";
  } catch (error) {
    console.error("An error occurred while creating researcher:", error);
    return "An error occurred while creating researcher";
  }
}

export async function sendSingleResearcherActions(
  researcherAction: string,
  researcherID: string
): Promise<string> {
  let route = null;
  let sendValue: any = null;

  if (!researcherID || researcherID === "") {
    return "Missing Researcher ID";
  }

  if (!researcherAction || researcherAction === "") {
    return "Missing Action";
  }

  sendValue = {
    researchers: [researcherID] as string[],
    bulk: false
  };

  if (researcherAction === "block" || researcherAction === "unblock") {
    route = "/researchers/block";
    sendValue = {
      ...sendValue,
      action: researcherAction
    };
  }

  if (!route) {
    return "Invalid Route";
  }

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: sendValue
    });

    if (response.status === 202) {
      return "Success";
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 404) {
      return "Researcher not found";
    }

    if (
      response.status === 200 ||
      response.status === 206 ||
      response.status === 418
    ) {
      return "Incorrect response received";
    }

    return "Failed to send all researcher actions";
  } catch (error) {
    console.error("An error occurred while sending researcher actions:", error);
    return "An error occurred while sending researcher actions";
  }
}

export async function sendBulkResearcherActions(
  researcherAction: string,
  researcherIDs: string[]
): Promise<string> {
  let route = null;
  let sendValue: any = null;

  if (!researcherIDs || researcherIDs.length === 0) {
    return "Missing Researcher ID";
  }

  if (!researcherAction || researcherAction === "") {
    return "Missing Action";
  }

  sendValue = {
    researchers: researcherIDs,
    bulk: true
  };

  if (researcherAction === "block" || researcherAction === "unblock") {
    route = "/researchers/block";
    sendValue = {
      ...sendValue,
      action: researcherAction
    };
  }

  if (!route) {
    return "Invalid Route";
  }

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: sendValue
    });

    if (response.status === 200 && response.data !== null) {
      if (
        !response.data ||
        !response.data.jobID ||
        typeof response.data.jobID !== "number"
      ) {
        console.log("Invalid worker data received");
        return "Invalid worker data received";
      }
      localStorage.setItem("workerID", response.data.jobID);
      return "Success";
    }

    if (response.status === 202) {
      return "Incorrect response received";
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 404) {
      return "Researcher not found";
    }

    if (
      response.status === 200 ||
      response.status === 206 ||
      response.status === 418
    ) {
      return "Incorrect response received";
    }

    return "Failed to send all researcher actions";
  } catch (error) {
    console.error("An error occurred while sending researcher actions:", error);
    return "An error occurred while sending researcher actions";
  }
}
