// Search.tsx

import React, { useEffect, useState } from "react";

interface SearchProps {
  onSearchChange: (value: string) => void;
  filtersCleared: boolean;
}

const Search: React.FC<SearchProps> = ({ onSearchChange, filtersCleared }) => {
  const [search, setSearch] = useState<string>("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    // set search value
    let value = e.target.value;
    if (value[value.length - 1] === "\t") {
      value = value.slice(0, -1); // remove tab character
    }
    setSearch(value);
  };

  useEffect(() => {
    // send search value to parent component
    onSearchChange(search);
  }, [search, onSearchChange]);

  useEffect(() => {
    // clear search when filters are cleared
    if (filtersCleared) {
      setSearch("");
    }
  }, [filtersCleared]);

  return (
    <div>
      <input
        className="text_search"
        type="text"
        placeholder="Search"
        value={search}
        onChange={(e) => handleSearch(e)}
      />
    </div>
  );
};

export default Search;
