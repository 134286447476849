import React, { useEffect, useState } from "react";
import {
  Country,
  Language,
  Researcher,
  ResearcherRole,
  Timezone
} from "../../types";

interface ViewResearcherModalTableProps {
  researcher: Researcher;
  jsonLanguagesData: Language[];
  jsonCountriesData: Country[];
  jsonTimezonesData: Timezone[];
  allRoles: ResearcherRole[];
}

const ViewResearcherModalTable: React.FC<ViewResearcherModalTableProps> = ({
  researcher,
  jsonLanguagesData,
  jsonCountriesData,
  jsonTimezonesData,
  allRoles
}) => {
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone>();
  useEffect(() => {
    // get the timezone name from the jsonTimezonesData
    setResearcherTimezone(
      jsonTimezonesData.find(
        (timezone) => timezone.name === researcher.timezone
      )
    );
  }, [researcher, jsonTimezonesData]);

  return (
    <>
      <div className="modal-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">ID</div>
            <div className="col-8">{researcher.id.toString()}</div>
          </div>
          <div className="row">
            <div className="col-4">Role</div>
            <div className="col-8">
              {allRoles.find((role) => role.id === researcher.role_id)?.name}
            </div>
          </div>
          <div className="row">
            <div className="col-4">Email</div>
            <div className="col-8">{researcher.email}</div>
          </div>
          <div className="row">
            <div className="col-4">Mobile</div>
            <div className="col-8">{researcher.full_mobile}</div>
          </div>
          <div className="row">
            <div className="col-4">Country</div>
            <div className="col-8">
              {
                // get the country name from the jsonCountriesData
                jsonCountriesData.find(
                  (country) => country.iso_code === researcher.country_iso
                )?.name
              }
            </div>
          </div>
          <div className="row">
            <div className="col-4">Language</div>
            <div className="col-8">
              {
                // get the language name from the jsonLanguagesData
                jsonLanguagesData.find(
                  (language) => language.iso_code === researcher.language_iso
                )?.name
              }
            </div>
          </div>
          <div className="row">
            <div className="col-4">Timezone</div>
            <div className="col-8">
              {researcherTimezone
                ? `(${
                    researcherTimezone.offset > 0
                      ? "+" + researcherTimezone.offset
                      : researcherTimezone.offset
                  }) ${
                    // replace _ with space
                    researcherTimezone.name.replace(/_/g, " ")
                  }`
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewResearcherModalTable;
