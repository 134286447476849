// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#error_page {
  overflow: hidden;
}
#error_page.default {
  width: 100vw !important;
  height: 100vh !important;
  background: #273361;
  background: linear-gradient(180deg, #24305f 0%, #161e3f 100%);
}
#error_page.custom {
  width: 100%;
  height: 100%;
  background: transparent;
}
#error_page.default h1,
#error_page.default p {
  color: white !important;
}
#error_page.custom h1,
#error_page.custom p {
  color: #273361 !important;
}
#error_page #error_card {
  height: auto;
  margin: auto;
  margin-top: 0px;
  text-align: center;
  padding: 50px;
  border-radius: 10px;
}
#error_page #error_card h1,
#error_page #error_card p {
  font-weight: 900;
}
#error_page #error_card h1 {
  font-size: 10em;
}
#error_page #error_card p {
  font-size: 2em;
}
#error_page #error_card #debug_code {
  margin-top: 15px;
  font-size: 1em !important;
  font-weight: 400 !important;
}
#error_page #error_card #debug_code span {
  display: block;
}
#error_page #error_card button {
  margin-bottom: 0px !important;
  padding: 6px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin-top: 0px !important;
  background-color: #8cc1e8;
  color: #161e3f;
  border: none !important;
}
#error_page #error_card button:hover {
  background-color: #5897c4;
}`, "",{"version":3,"sources":["webpack://./src/pages/Error/error.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,uBAAA;EACA,wBAAA;EAEA,mBAAA;EACA,6DAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,uBAAA;AAAJ;AAII;;EAEE,uBAAA;AAFN;AAOI;;EAEE,yBAAA;AALN;AASE;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AAPJ;AASI;;EAEE,gBAAA;AAPN;AAUI;EACE,eAAA;AARN;AAWI;EACE,cAAA;AATN;AAYI;EACE,gBAAA;EACA,yBAAA;EACA,2BAAA;AAVN;AAWM;EACE,cAAA;AATR;AAaI;EACE,6BAAA;EACA,uBAAA;EACA,4BAAA;EACA,6BAAA;EACA,0BAAA;EACA,yBAAA;EACA,cAAA;EACA,uBAAA;AAXN;AAaM;EACE,yBAAA;AAXR","sourcesContent":["#error_page {\n  overflow: hidden;\n  &.default {\n    width: 100vw !important;\n    height: 100vh !important;\n\n    background: #273361;\n    background: linear-gradient(180deg, #24305f 0%, #161e3f 100%);\n  }\n\n  &.custom {\n    width: 100%;\n    height: 100%;\n    background: transparent;\n  }\n\n  &.default {\n    h1,\n    p {\n      color: white !important;\n    }\n  }\n\n  &.custom {\n    h1,\n    p {\n      color: #273361 !important;\n    }\n  }\n\n  #error_card {\n    height: auto;\n    margin: auto;\n    margin-top: 0px;\n    text-align: center;\n    padding: 50px;\n    border-radius: 10px;\n\n    h1,\n    p {\n      font-weight: 900;\n    }\n\n    h1 {\n      font-size: 10em;\n    }\n\n    p {\n      font-size: 2em;\n    }\n\n    #debug_code {\n      margin-top: 15px;\n      font-size: 1em !important;\n      font-weight: 400 !important;\n      span {\n        display: block;\n      }\n    }\n\n    button {\n      margin-bottom: 0px !important;\n      padding: 6px !important;\n      padding-left: 8px !important;\n      padding-right: 8px !important;\n      margin-top: 0px !important;\n      background-color: #8cc1e8;\n      color: #161e3f;\n      border: none !important;\n\n      &:hover {\n        background-color: #5897c4;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
