import React, { useEffect, useState } from "react";
import { fetchSingleLeadHistory } from "../../models/leads.model";
import { Client, LeadsParticipantEvent } from "../../types";
import { convertTime, formatDate } from "../../utilities/utils";

interface ViewLeadModalEventsProps {
  participantID: string;
  allClients: Client[];
}

const ViewLeadModalEvents: React.FC<ViewLeadModalEventsProps> = ({
  participantID,
  allClients
}) => {
  const [leadEvents, setLeadsEvents] = useState<
    LeadsParticipantEvent[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  useEffect(() => {
    // Fetch participant data from JSON
    // console.log("participantID", participantID);
    const fetchLeadData = async () => {
      // setLoading(true);
      try {
        const jsonData = await fetchSingleLeadHistory(participantID);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "26d9e6a03c23f8cc007020e03cb49d8b"
          ]);
        } else {
          setLeadsEvents(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "9df296ff529ec382fcdbacb04120a62f"
        ]);
      }
    };

    const promises = [fetchLeadData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLeadsEvents(undefined);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "2587b00d55584bd284d17a4fce630ab3"
        ]);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center custom_spinner_container mt-4 mb-4">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : loadingError || !leadEvents ? (
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">Leads History</div>
              <p>
                There was an error loading the data. Please try again later.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-body">
          <div className="table-responsive">
            <table className="cust_table table">
              <thead>
                <tr>
                  <th>Event ID</th>
                  <th>Event Action</th>
                  <th>Client Name</th>
                  <th>Event Time</th>
                </tr>
              </thead>
              <tbody>
                {leadEvents.length === 0 && (
                  <tr className="text-center w-100">
                    <td colSpan={4}>No events found</td>
                  </tr>
                )}
                {leadEvents.map(
                  (ldEvent: LeadsParticipantEvent, index: number) => {
                    const eventTime =
                      formatDate(convertTime(ldEvent.event_time, 0)) + " UTC";
                    const clientName =
                      allClients.find(
                        (client: Client) => client.id === ldEvent.client_id
                      )?.name || "N/A";
                    return (
                      <tr key={index}>
                        <td>{ldEvent.id}</td>
                        <td>{ldEvent.event_action || "N/A"}</td>
                        <td>{clientName}</td>
                        <td>{eventTime}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewLeadModalEvents;
