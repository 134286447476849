import React, { useState } from "react";
import History from "./History/history.component";
import SendMessages from "./SendMessages/sendMessages.component";
import Templates from "./Templates/templates.component";
import "./messages.scss";

interface MessagesProps {
  studyClicked: string;
}
const Messages: React.FC<MessagesProps> = ({ studyClicked }) => {
  const [tab, setTab] = useState<string>("sendMessages");

  const handleTab = (tab: string) => {
    setTab(tab);
  };

  const renderTab = () => {
    switch (tab) {
      case "history":
        return <History studyClicked={studyClicked} />;
      case "sendMessages":
        return <SendMessages studyClicked={studyClicked} />;
      case "templates":
        return <Templates studyClicked={studyClicked} />;
      default:
        return <SendMessages studyClicked={studyClicked} />;
    }
  };

  return (
    <div className="tab-container">
      <div className="tab-header">
        <div
          className={`tab-header-item ${
            tab === "sendMessages" ? "tab-header-active" : ""
          }`}
          onClick={() => handleTab("sendMessages")}
        >
          <h5 className="tab-title">Send Messages</h5>
        </div>
        <div
          className={`tab-header-item ${
            tab === "history" ? "tab-header-active" : ""
          }`}
          onClick={() => handleTab("history")}
        >
          <h5 className="tab-title">History</h5>
        </div>
        {/* <div
          className={`tab-header-item ${tab === "templates" ? "tab-header-active" : ""
            }`}
          onClick={() => handleTab("templates")}
        >
          <h5 className="tab-title">Templates</h5>
        </div> */}
      </div>
      <div className="tab-content">{renderTab()}</div>
    </div>
  );
};

export default Messages;
