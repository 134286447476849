// Custom search input that will filter the options based on the search input and return the selected option as a value
// the options will only be shown if the search input is not empty and if the options exist on the database
// the options will be filtered based on the search input and the user can select the option from the dropdown
// If what is typed does not exist in the database a plus will appear next to the input and the user can add the option to the database through a api call

import React, { useEffect, useRef, useState } from "react";

import { IconAdd } from "../../assets/images/icons/icons";

import Swal from "sweetalert2";

import {
  addNotificationPhrase,
  isBucketNotification
} from "../../models/bucket.model";
import { BucketNotification } from "../../types";
import "./search-input.styles.scss";

interface SearchInputProps {
  label: string;
  initialOptions: string | null;
  category: string;
  allOptions: BucketNotification[];
  onSelectOption: (phraseID: string | null, phrase: string) => void;
  onAddIconDisplay: (display: boolean, inputID: string) => void;
  searchInputId: string; // Unique ID for this SearchInput
  placeholder: string;
  setValueChanged?: (change: boolean) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  label,
  initialOptions,
  category,
  allOptions,
  onSelectOption,
  onAddIconDisplay,
  searchInputId, // Unique ID for this SearchInput
  placeholder,
  setValueChanged
}) => {
  const [options, setOptions] = useState<BucketNotification[]>([]);
  const [searchInput, setSearchInput] = useState<string | null>(initialOptions);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [tempError, setTempError] = useState<string>("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [showAdd, setShowAdd] = useState<boolean>(false);

  // This function handles adding the new option
  const handleAddOption = async () => {
    if (searchInput) {
      const results = await addNotificationPhrase(category, searchInput);

      // console.log(results)
      if (results) {
        Swal.fire({
          icon: "success",
          title: "Phrase successfully added",
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 1500
        });

        // Set the searchInput to the current value of the input
        setSearchInput(searchInput);

        // Call the onSelectOption callback with the newly created notificationID
        onSelectOption(results.newNotificationId, searchInput);
        setShowAdd(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "There was a problem inserting this phrase",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please enter a phrase",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    // Initialize searchInput with initialOptions when the component mounts
    setSearchInput(initialOptions || "");
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (setValueChanged) {
      setValueChanged(true);
    }
    // console.log(inputValue, 'inputValue')

    // Check if the input value is safe
    const regex = /^[\p{L}\p{N} .\-_@$!&#+]+$/u;
    if (
      (searchInput?.length ?? 0) < inputValue.length &&
      !regex.test(inputValue.slice(-1))
    ) {
      setTempError("You can not enter that character");
      return;
    } else {
      setTempError("");
    }

    // Set the input value
    setSearchInput(inputValue);

    if (inputValue) {
      if (inputValue.length > 1500) {
        setTempError("The phrase must be less than 1500 characters");
        setShowAdd(false);
      } else {
        if (allOptions.some((option) => !isBucketNotification(option))) {
          console.error("All options are not bucket notifications");
        } else {
          console.log("All options are bucket notifications");
        }

        // Filter options based on the input value
        const filteredOptions = allOptions
          .filter((opt: BucketNotification) => typeof opt.phrase === "string")
          .filter((opt: BucketNotification) =>
            opt.phrase.toLowerCase().includes(inputValue.toLowerCase())
          );
        setOptions(filteredOptions);
        setShowOptions(true);

        // Determine whether to show the '+' icon
        const isPhraseExisting = filteredOptions.some(
          (opt: BucketNotification) =>
            opt.phrase.toLowerCase() === inputValue.toLowerCase()
        );
        setShowAdd(!isPhraseExisting); // Show '+' only if the phrase does not exist
      }
    } else {
      setShowOptions(false);
      setShowAdd(false); // Hide '+' icon if input is empty
    }

    // Reset selection if input is empty
    if (inputValue === "") {
      onSelectOption(null, "");
    }
  };

  const handleOptionSelect = (option: BucketNotification) => {
    setSearchInput(option.phrase); // Update input with phrase
    setShowOptions(false);

    // Notify parent component of the selected option's phrase

    // Call the onSelectOption callback with the option's notification_id
    onSelectOption(option.phraseID.toString(), option.phrase);
  };

  useEffect(() => {
    // Determine if the '+' icon should be shown
    const newShowAdd = Boolean(
      searchInput &&
        searchInput.trim() !== "" &&
        !allOptions.some(
          (option: BucketNotification) =>
            option.phrase.toLowerCase() === searchInput.toLowerCase()
        )
    );

    // Update the state only if there's a change
    if (newShowAdd !== showAdd) {
      setShowAdd(newShowAdd);
    }
  }, [searchInput, allOptions]); // Removed showAdd from dependencies

  // Separate useEffect for notifying the parent component
  useEffect(() => {
    // Notify the parent about the '+' icon status
    onAddIconDisplay(showAdd, searchInputId);
  }, [showAdd, searchInputId]); // Dependencies include only showAdd and searchInputId

  return (
    <div className="relative searchWrapper" ref={wrapperRef}>
      <label>{label}</label>
      <div className="d-flex">
        <div className="search-input">
          <input
            type="text"
            value={searchInput || ""}
            onChange={handleInputChange}
            placeholder={placeholder}
            onFocus={() => setShowOptions(true)}
            className="form-control text-center" // Add some margin to the right
          />
          {tempError && <small className="text-danger">{tempError}</small>}
          {searchInput && showOptions && options && (
            <div className="options-list">
              {options.map((option: BucketNotification, idx) => (
                <div
                  key={idx}
                  className="options-list-item"
                  onClick={() => handleOptionSelect(option)} // Pass the entire option object
                >
                  {option.phrase}
                </div>
              ))}
            </div>
          )}
        </div>
        {searchInput &&
          showAdd &&
          options &&
          !options.some(
            (opt: BucketNotification) =>
              opt.phrase.toLowerCase() === searchInput.toLowerCase()
          ) && (
            <div onClick={handleAddOption} className="add-option">
              <IconAdd className="icon" />
            </div>
          )}
      </div>
    </div>
  );
};

export default SearchInput;
