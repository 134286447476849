import React, { useEffect, useState } from "react";
import { IconClock } from "../../../../assets/images/icons/icons";
import {
  DateTimeTiming,
  PrerequisiteRule,
  Rules,
  Timings
} from "../../../../types";
import "./timing.scss";

export type TimingDisplaySimpleProps = {
  timing: Timings;
  allAutomations: Rules[];
};

const TimingDisplaySimple: React.FC<TimingDisplaySimpleProps> = ({
  timing,
  allAutomations
}) => {
  // For selecting Time/Prerequisite Rule (radio buttons)
  const [timingType, setTimingType] = useState<string>("");

  const [selectedPrerequisiteRule, setSelectedPrerequisiteRule] =
    useState<PrerequisiteRule>();

  const [selectedDateTime, setSelectedDateTime] = useState<DateTimeTiming>();

  useEffect(() => {
    //
    setTimingType(timing.type);

    // Set timing type prerequisite rule
    if (timing.type === "prerequisite" && "automationID" in timing.timing) {
      setSelectedPrerequisiteRule(timing.timing); // or some other property
    }

    // set timing type date time
    if (timing.type === "dateTime" && "participantLocalTime" in timing.timing) {
      setSelectedDateTime(timing.timing);
    }

    // Set timing type
    console.log(timing);
  }, [timing, selectedDateTime, selectedPrerequisiteRule, timingType]);

  useEffect(() => {
    console.log(allAutomations);
  }, [allAutomations]);

  useEffect(() => {
    // timing.type === "dateTime" && setInputDate(timing.dateTime);
    console.log(selectedPrerequisiteRule);
  }, [timingType]);

  return (
    <div className="simple_timing_container">
      {/* ================ SELECTING DATE/TIME OR PREREQUISITE ================  */}
      <div className="clock">
        <IconClock />
      </div>
      <div className="timing_message">
        {timing.type === "dateTime" &&
        "participantLocalTime" in timing.timing ? (
          <>
            {timing.timing.date === "daily" || timing.timing?.date === "" ? (
              <>Daily</>
            ) : (
              <>{timing.timing.date}</>
            )}{" "}
            at {timing.timing.participantLocalTime}
            {" - "} User Local Time
          </>
        ) : timingType === "prerequisite" ? (
          <div>
            Prerequisite Rule "
            {
              // compare the timing's automationID to the selectedPrerequisiteRule' id, return name
              allAutomations.map((automation) => {
                if (automation.id === selectedPrerequisiteRule?.automationID) {
                  return automation.name;
                }
              })
            }
            " in {selectedPrerequisiteRule?.value}{" "}
            {selectedPrerequisiteRule?.fireType}(s)
          </div>
        ) : timingType === "noSchedule" || timingType === "" ? (
          <>No Schedule</>
        ) : null}
      </div>
    </div>
  );
};

export default TimingDisplaySimple;
