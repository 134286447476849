import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  IconRobot,
  IconSettings,
  IconSideArrow
} from "../../../../assets/images/icons/icons";
import { ChannelMessages } from "../../../../types";

import "react-quill/dist/quill.snow.css";
import { usePermissions } from "../../../../contexts/UserContext";
import { useOutsideClick } from "../../../../utilities/utils";

export type ChatBubbleProps = {
  messageInfo: ChannelMessages;
  getParentMessage: (messageID: string) => ChannelMessages | undefined;
  selectedChannel: string;
  selectedChannelType: string;
  onDeleteMessage: (messageID: string) => void;
  onEditMessage: (messageID: string, updatedContent: string) => void;
  onReplyMessage: (
    messageID: string,
    messageContent: string,
    messageAttachment: string,
    SenderName: string,
    messageType: string
  ) => void;
  onPinMessage: (messageID: string) => void;
};

const ChatBubble: React.FC<ChatBubbleProps> = ({
  messageInfo,
  getParentMessage,
  selectedChannel,
  selectedChannelType,
  onDeleteMessage,
  onEditMessage,
  onReplyMessage,
  onPinMessage
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const { clientID, studyID } = useParams(); // Get from URL
  const [isHovering, setIsHovering] = useState(false);
  //simple format date function to change the format of the date from 2023-11-06T10:36:10.000Z to yyyy-mm-dd hh:mm:ss
  const settingsRef = useRef(null);
  const {
    sender,
    createdAt,
    message,
    senderName,
    attachmentUrl,
    id,
    parentID,
    pinned,
    type,
    flagged,
    seen,
    deleted,
    edited
  } = messageInfo;

  const parentMessage =
    parentID && parentID !== null ? getParentMessage(parentID) : "";

  const { hasPermission } = usePermissions();

  const truncateString = (str: string, num: number) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    }
    return str;
  };

  const truncatedParentMessageContent = parentMessage
    ? truncateString(parentMessage?.message, 50) // Adjust 50 to your desired length
    : "";

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();

    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const showImageModal = (imageUrl: string) => {
    Swal.fire({
      imageUrl: imageUrl,
      // imageWidth: 600, // Maximum width
      imageAlt: "Attachment Image",
      // showCloseButton: true,
      focusConfirm: false,
      confirmButtonColor: "#3085d6",
      backdrop: `rgba(0,0,0,0.85)`, // Black background with opacity
      customClass: {
        image: "responsive-swal-image" // Custom class for the image
      }
    });
  };

  const handleShowSettings = () => {
    setShowSettings(!showSettings);
  };

  useOutsideClick(settingsRef, () => {
    if (showSettings) setShowSettings(false);
  });

  const handleEditMessage = async (messageID: string) => {
    // Implement your editing logic here
    // For example, prompt the user to enter new message content
    const { value: updatedContent } = await Swal.fire({
      title: "Edit Message",
      input: "textarea",
      inputValue: message,
      showCancelButton: true,
      confirmButtonColor: "#3085d6"
      // other Swal configuration
    });

    if (updatedContent) {
      try {
        onEditMessage(messageID, updatedContent);
        //One sec load with
        Swal.fire({
          title: "Editing Message",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        setShowSettings(false);
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Failed to edit message",
          text: "An error occurred while editing the message",
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
      }
    }
  };

  const handleDeleteMessage = async (messageID: string) => {
    const result: any = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this message!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "#3085d6"
    });

    if (result.isConfirmed) {
      try {
        // Make the API call to delete the message
        if (clientID && studyID && selectedChannel && id) {
          onDeleteMessage(id);
          Swal.fire({
            title: "Deleting Message",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          setShowSettings(false);
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Failed to delete message",
          text: "An error occurred while deleting the message",
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
      }
    }
  };
  //Render different chat bubbles
  // Render functions for different attachment types
  const renderImage = (
    url: string,
    isDeleted: boolean,
    isParent: boolean = false
  ) =>
    isDeleted === false ? (
      isParent ? (
        <div className="parent-media">
          <p className="parent-type">Image</p>
          <img
            src={url}
            alt="parent_attachment"
            className="parent-attachment-image"
            onClick={() => showImageModal(url)}
          />
        </div>
      ) : (
        <img
          src={url}
          alt="attachment"
          className="attachment-image"
          onClick={() => showImageModal(url)}
        />
      )
    ) : (
      <div className="message-content">
        <p className="text-status">This message has been deleted</p>
      </div>
    );

  const renderVideo = (
    url: string,
    isDeleted: boolean,
    isParent: boolean = false
  ) => {
    // Set the poster by replacing ".mp4" with ".jpg" - this assumes that a corresponding jpg image exists
    return isDeleted === false ? (
      isParent ? (
        // For parent messages, display video thumbnail using a video tag without controls and not autoplaying
        <div className="parent-media">
          <p className="parent-type">Video</p>
          <IconSideArrow className="parent-media" />
        </div>
      ) : (
        // For non-parent messages, provide video controls for playback
        <video className="attachment-video" preload="metadata" controls>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    ) : (
      // Display this when the message is deleted
      <div className="message-content">
        <p className="text-status">This message has been deleted</p>
      </div>
    );
  };

  const renderAudio = (
    url: string,
    isDeleted: boolean,
    isParent: boolean = false
  ) =>
    isDeleted === false ? (
      isParent ? (
        <div className="parent-media">
          <p className="parent-type">Audio</p>
          <IconSideArrow className="parent-media" />
        </div>
      ) : (
        <audio controls>
          <source src={url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )
    ) : (
      <div className="message-content">
        <p className="text-status">This message has been deleted</p>
      </div>
    );

  const renderFile = (url: string, isDeleted: boolean, isParent: boolean) =>
    isDeleted === false ? (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="attachment-file"
      >
        Download File
      </a>
    ) : (
      <div className="message-content">
        <p className="text-status">This message has been deleted</p>
      </div>
    );

  // Choose the right renderer based on the message type
  const renderAttachment = (type: string, url: string, isDeleted: boolean) => {
    switch (type) {
      case "image":
        return renderImage(url, isDeleted);
      case "video":
        return renderVideo(url, isDeleted);
      case "audio":
        return renderAudio(url, isDeleted);
      default:
        return null; // Or handle other types as needed
    }
  };

  // Function to render parent message based on its type and deletion status
  const renderParentMessageAttachment = (parentMessage: ChannelMessages) => {
    const { message, attachmentUrl, type, deleted } = parentMessage;

    if (deleted) {
      return <p className="text-status">This message has been deleted</p>;
    } else if (message && message !== "") {
      return (
        <ReactQuill
          value={truncateString(message, 50)} // Assuming truncation is desired
          readOnly={true}
          theme="bubble"
          className="replyto-message-quill"
        />
      );
    } else if (attachmentUrl && attachmentUrl !== "") {
      switch (type) {
        case "image":
          return renderImage(attachmentUrl, deleted, true);
        case "video":
          return renderVideo(attachmentUrl, deleted, true);
        case "audio":
          return renderAudio(attachmentUrl, deleted, true);
        case "file":
          return renderFile(attachmentUrl, deleted, true);
        default:
          console.log("are wer here");
          return null;
      }
    }
    return null; // Return null if there's no content to render
  };

  // const downloadFile = async (url: any) => {
  //   try {
  //    window.open(url, "_blank")
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //Handle the hover state of the message
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    //three seconds delay to remove the hover state
    setIsHovering(false);
  };

  const onRightClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target instanceof HTMLElement) {
      // Right click
      e.preventDefault(); // Prevent the default context menu
      // Assuming you have access to the variables used in onReplyMessage
      if (messageInfo?.deleted === true) {
        return;
      } else {
        onReplyMessage(
          id,
          message,
          attachmentUrl,
          senderName ? senderName : "",
          messageInfo?.type || ""
        );
      }
    }
  };

  return (
    <>
      <div className={`message_container ${sender}_sender_type`}>
        <div
          className={`date_message_container ${sender}_date_messenger`}
          onMouseLeave={handleMouseLeave}
        >
          <div className="date_sent">
            {formatDate(createdAt)} {senderName}
          </div>
          <div className="d-flex align-items-center">
            {/* ========================================================================== */}
            {/* This is the setting for researcher and ai , for both text and attachments*/}
            {/* ========================================================================== */}
            {(sender === "researcher" || sender === "ai") &&
              showSettings &&
              messageInfo?.deleted !== true && (
                <div className="dropdown setting_options" ref={settingsRef}>
                  <div className="message-options">
                    {(hasPermission("subject") ||
                      hasPermission("chat", "all") ||
                      hasPermission("chat", "delete")) && (
                      <div
                        className="option"
                        onClick={() => {
                          handleDeleteMessage(id);
                          setShowSettings(false);
                        }}
                      >
                        Delete Message
                      </div>
                    )}
                    {(hasPermission("subject") ||
                      hasPermission("chat", "all") ||
                      hasPermission("chat", "write")) &&
                      !attachmentUrl && (
                        <div
                          className="option"
                          onClick={() => {
                            handleEditMessage(id);
                            setShowSettings(false);
                          }}
                        >
                          Edit Message
                        </div>
                      )}
                    {/* {selectedChannelType !==
											"Focus Group" && ( */}
                    {(hasPermission("subject") ||
                      hasPermission("chat", "all") ||
                      hasPermission("chat", "write")) && (
                      <div
                        className="option"
                        onClick={() => {
                          onPinMessage(id);
                          setShowSettings(false);
                        }}
                      >
                        Pin Message
                      </div>
                    )}
                    {/* )} */}
                    {/* {selectedChannelType === "Focus Group" && ( */}
                    <div
                      className="option"
                      onClick={() => {
                        onReplyMessage(
                          id,
                          message,
                          attachmentUrl,
                          senderName ? senderName : "",
                          messageInfo?.type || ""
                        );
                        setShowSettings(false);
                      }}
                    >
                      Reply
                    </div>
                    {/* )} */}
                  </div>
                </div>
              )}
            <div
              className={`individual_message ${sender}_sender ${
                pinned ? "pinned-message-bubble" : ""
              }`}
            >
              {/* Settings icon on the left for AI and researcher */}
              {sender === "ai" && (
                <span className="ai-robot">
                  <IconRobot />
                </span>
              )}
              {/* ============================================== */}
              {/* =================Message render=============== */}
              {/* =======This is for the setting icon on researchers and ai ===== */}
              {/* ============================================== */}
              {(sender === "ai" || sender === "researcher") &&
                attachmentUrl &&
                isHovering &&
                messageInfo?.deleted !== true && (
                  <div
                    className="dropdown nav-media"
                    //ref={settingsRef}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div>
                      <a
                        className="settings-cog"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={handleShowSettings}
                      >
                        <IconSettings />
                      </a>
                    </div>
                    {/* <div>
                      <a
                        className="settings-cog"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => downloadFile(attachmentUrl)}
                      >
                        <IconDownload />
                      </a>
                    </div> */}
                  </div>
                )}
              <div>
                {/* =============================================================== */}
                {/* =======This is the different output renders for messages======= */}
                {/* =============================================================== */}
                {parentMessage ? (
                  <div>
                    <div
                      className="parent-message-preview"
                      onContextMenu={(e) => onRightClick(e)}
                    >
                      <div className="parent-message-sender">
                        <p className="reply-header">
                          {parentMessage?.senderName
                            ? parentMessage.senderName
                            : "User not found"}
                        </p>
                        <p className="reply-header">
                          {parentMessage?.createdAt
                            ? formatDate(parentMessage.createdAt)
                            : ""}
                        </p>
                      </div>
                      {/* Display the content of the parent message  */}
                      {renderParentMessageAttachment(parentMessage)}
                    </div>
                    {/* ==========This is for text messages on parent messages==========  */}
                    {messageInfo?.type === "text" ? (
                      <div
                        className="message"
                        onClick={handleShowSettings}
                        onContextMenu={(e) => onRightClick(e)}
                      >
                        <div className="message-content">
                          {/* If the message is edited or deleted */}
                          <span className="status">
                            {edited ? "Edited" : null}
                          </span>
                          <ReactQuill
                            value={
                              deleted
                                ? "This message has been deleted"
                                : message
                            }
                            readOnly={true}
                            theme={"bubble"}
                            className="my-custom-quill-container" // Apply custom class
                          />
                        </div>
                      </div>
                    ) : attachmentUrl ? (
                      // ==========This is for attachment messages on parent messages==========
                      <div
                        onMouseEnter={handleMouseEnter}
                        onContextMenu={(e) => onRightClick(e)}
                      >
                        {renderAttachment(type, attachmentUrl, deleted)}
                      </div>
                    ) : null}
                  </div>
                ) : messageInfo?.type === "text" ? (
                  // ==========This is for text messages==========
                  <div
                    className="message"
                    onClick={handleShowSettings}
                    onContextMenu={(e) => onRightClick(e)}
                  >
                    <div className="message-content">
                      {/* If the message is edited or deleted */}
                      <span className="status">{edited ? "Edited" : null}</span>
                      <ReactQuill
                        value={
                          deleted ? "This message has been deleted" : message
                        }
                        readOnly={true}
                        theme={"bubble"}
                        className="my-custom-quill-container"
                      />
                    </div>
                  </div>
                ) : attachmentUrl ? (
                  <div
                    onMouseEnter={handleMouseEnter}
                    onContextMenu={(e) => onRightClick(e)}
                  >
                    {renderAttachment(type, attachmentUrl, deleted)}
                  </div>
                ) : null}
                {/* =============================================================== */}
                {/* =======This is the different output renders for messages======= */}
                {/* =============================================================== */}
              </div>
              {/* ===================================================================== */}
              {/* This is the setting for participants , for both text and attachments*/}
              {/* ===================================================================== */}
              {sender === "participant" &&
                attachmentUrl &&
                messageInfo?.deleted !== true &&
                isHovering && (
                  <div
                    className="dropdown nav-media"
                    onMouseLeave={handleMouseLeave}
                  >
                    <div>
                      <a
                        className="settings-cog"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={handleShowSettings}
                      >
                        <IconSettings />
                      </a>
                    </div>
                    {/* Download button */}
                    {/* <div>
                      <a
                        className="settings-cog"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => downloadFile(attachmentUrl)}
                      >
                        <IconDownload />
                      </a>
                    </div> */}
                  </div>
                )}
              {/* Robot icon for AI */}
            </div>
            {/* Options for participants */}
            {sender === "participant" &&
              showSettings &&
              messageInfo?.deleted !== true && (
                <div className="dropdown setting_options" ref={settingsRef}>
                  <div className="message-options">
                    {(hasPermission("subject") ||
                      hasPermission("chat", "all") ||
                      hasPermission("chat", "delete")) && (
                      <div
                        className="option"
                        onClick={() => {
                          handleDeleteMessage(id);
                          setShowSettings(false);
                        }}
                      >
                        Delete Message
                      </div>
                    )}
                    {/* {(hasPermission("subject") ||
                    hasPermission("chat", "all") ||
                    hasPermission("chat", "write")) &&
                    !attachmentUrl && (
                      <div
                        className="option"
                        onClick={() => {
                          handleEditMessage(id);
                          setShowSettings(false);
                        }}
                      >
                        Edit Message
                      </div>
                    )} */}
                    {/* {selectedChannelType !== "Focus Group" && ( */}
                    {(hasPermission("subject") ||
                      hasPermission("chat", "all") ||
                      hasPermission("chat", "write")) && (
                      <div
                        className="option"
                        onClick={() => {
                          onPinMessage(id);
                          setShowSettings(false);
                        }}
                      >
                        Pin Message
                      </div>
                    )}
                    {/* )} */}
                    {/* {selectedChannelType === "Focus Group" && ( */}
                    <div
                      className="option"
                      onClick={() => {
                        onReplyMessage(
                          id,
                          message,
                          attachmentUrl,
                          senderName ? senderName : "",
                          messageInfo?.type || ""
                        );
                        setShowSettings(false);
                      }}
                    >
                      Reply
                    </div>
                    {/* )} */}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBubble;
