//Page to display the clients in cards
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddStudyWizard from "../../components/Wizards/AddStudyWizard/addStudyWizard.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { fetchSingleClientByID } from "../../models/client.model";
import { Client, SidebarProps } from "../../types";
import ErrorPage from "../Error/error.page";
import "./addStudy.scss";

interface AddStudyPageProps extends SidebarProps {}

const AddStudyPage: React.FC<AddStudyPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  const { clientID } = useParams(); // Read values passed on link
  const [client, setClient] = useState<Client | null>(null); // Set initial state to null
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  useEffect(() => {
    const fetchClientsData = async () => {
      try {
        const jsonData = await fetchSingleClientByID(clientID as string);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          // setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "165fd2d3f5cbfaab964b12d2a509b39e"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setClient(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        // setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "b98d1e652a7ecea384ebac7f7943c688"
        ]);
      }
      setLoading(false);
    };

    const promises = [fetchClientsData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "df34e63e937bd04cb33b2a44f506db5d"
        ]);
      });
  }, [clientID]);

  return (
    <div className="main_container custom_blue_background">
      <div className="col-sm p-3 min-vh-100">
        <div className="container" id="add_study_page">
          {/* <AddSurveyForm /> */}
          {loading || client ? (
            // loading is handled in the child component to stop double loading
            <AddStudyWizard />
          ) : (
            <ErrorPage
              errorCode="404"
              error="Client not found"
              debugCode={loadingErrorText}
            />
          )}
        </div>
      </div>
      <SidebarComponent
        page="studies"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={client?.id}
        currentClientLogo={client?.icon_url}
      />
    </div>
  );
};

export default AddStudyPage;
