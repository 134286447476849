import React, { useState } from "react";
import Swal from "sweetalert2";
import { ConfigMobileAppStyles } from "../../../../../../types";
import { resizeImage } from "../../../../../../utilities/utils";

type ImageFileInputProps = {
  settingName: keyof ConfigMobileAppStyles;
  fieldID: string;
  fieldKey: string;
  fieldValue: string;
  handleChange: (
    settingName: keyof ConfigMobileAppStyles,
    id: string,
    dbField: string,
    value: string
  ) => void;
};

const ImageFileInput: React.FC<ImageFileInputProps> = ({
  settingName,
  fieldID,
  fieldKey,
  fieldValue,
  handleChange
}) => {
  const [viewFile, setViewFile] = useState(false);

  async function uploadFile() {
    // upload file
    // open file dialog
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = false;
    input.accept = "image/*";

    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null; // Get the first file
      if (file) {
        if (!file.type.startsWith("image/")) {
          Swal.fire({
            title: "Invalid Image File",
            text: `You have selected a non-image file. Only image files are allowed.`,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#3085d6"
          });
          return;
        }

        try {
          Swal.fire({
            title: "Processing Image",
            text: "Please wait while the image is being processed...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCloseButton: false,
            showCancelButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          const fullImage = await resizeImage(file, 1000); // Create a thumbnail from the image
          Swal.close();

          const GPTAllowedExtensions = ["png", "jpeg", "gif", "webp"];
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          if (
            !fileExtension ||
            (!fullImage && GPTAllowedExtensions.includes(fileExtension))
          ) {
            Swal.fire({
              title: "Error",
              text: "Error processing image. Please try a different image.",
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#3085d6"
            });
            return;
          }

          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target) {
              // assert that e.target.result is a string
              const base64 = e.target.result as string;
              handleChange(
                settingName,
                fieldID,
                fieldKey,
                fullImage ? fullImage : base64
              );
            }
          };
          reader.readAsDataURL(file); // convert file to base64 string
        } catch (error) {
          console.error("Error processing the image file:", error);
        }
      } else {
        // no file selected
        Swal.fire({
          title: "No Image Selected",
          text: `Please select an image to upload.`,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6"
        });
      }
    };
    input.click();
  }

  return (
    <>
      <div className="">
        {fieldValue && (
          <img
            src={fieldValue}
            alt="file"
            className="object-fit-contain m-auto me-2 app_field_image"
            onClick={() => setViewFile(true)}
          />
        )}
        <button className="btn btn-primary" onClick={() => uploadFile()}>
          Upload
        </button>
        {/* <button className="btn btn-primary" onClick={() => setViewFile(true)}>
          View
        </button> */}
      </div>
      {viewFile && (
        <div className="modal show">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title">{fieldKey}</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setViewFile(false)}
                ></button>
              </div>
              <div className="modal-body">
                <img
                  src={fieldValue}
                  alt="file"
                  className="object-fit-contain m-auto w-100"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageFileInput;
