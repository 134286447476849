import React, { useEffect, useRef, useState } from "react";
import "../Messenger/messenger.scss";

interface PhraseDropdownProps {
  phrases: { phrase: string }[];
  onPhraseSelected: (phrase: string) => void;
}

const LoadPhrase: React.FC<PhraseDropdownProps> = ({
  phrases,
  onPhraseSelected
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePhraseSelect = (phrase: string) => {
    onPhraseSelected(phrase);
    setShowDropdown(false);
  };

  const filteredPhrases = phrases.filter((p) =>
    p.phrase.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button
        className="btn-message"
        onClick={() => setShowDropdown((prev) => !prev)}
      >
        Load Phrase
      </button>
      {showDropdown && (
        <div className="generic-dropdown-menu">
          <input
            type="text"
            placeholder="Search"
            className="dropdown-search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="generic-dropdown-menu-items">
            {filteredPhrases && filteredPhrases.length > 0 ? (
              filteredPhrases.map((phrase, index) => (
                <div
                  key={index}
                  onClick={() => handlePhraseSelect(phrase.phrase)}
                >
                  {phrase.phrase}
                </div>
              ))
            ) : (
              <div>No phrases found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadPhrase;
