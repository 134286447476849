import React, { useEffect, useState } from "react";
import { getPermissionsByRoleID } from "../../models/roles.model";
import { ResearcherPermission, ResearcherRole } from "../../types";
import "./viewResearcherRoleModal.scss";

type ViewResearcherRoleModalProps = {
  roleID: string;
  closeResearcherRoleModal: () => void;
  shown: boolean;
  allRoles: ResearcherRole[];
};

const ViewResearcherRoleModal: React.FC<ViewResearcherRoleModalProps> = ({
  roleID,
  closeResearcherRoleModal,
  shown,
  allRoles
}) => {
  const [selectedRole, setSelectedRole] = useState<ResearcherRole | undefined>(
    undefined
  );
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(true);
  const [loadingPermissionsError, setLoadingPermissionsError] = useState<
    string | null
  >(null);

  const [permissions, setPermissions] = useState<ResearcherPermission[]>([]);

  useEffect(() => {
    console.log("roleID", roleID);
    setSelectedRole(allRoles.find((role) => role.id === roleID));
  }, [roleID, allRoles]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoadingPermissions(true);
      setLoadingPermissionsError(null);
      try {
        const jsonData = await getPermissionsByRoleID(roleID);
        if (typeof jsonData === "string") {
          setLoadingPermissionsError(jsonData);
        } else {
          setPermissions(jsonData);
        }
      } catch (error) {
        console.error("An error occurred while fetching permissions:", error);
        setLoadingPermissionsError(
          "An error occurred while fetching permissions"
        );
      }
      setLoadingPermissions(false);
    };

    if (!roleID || roleID === "") {
      setLoadingPermissions(false);
      setLoadingPermissionsError("Role ID is required");
      return;
    }

    const promises = [fetchPermissions()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingPermissions(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingPermissions(false);
        setLoadingPermissionsError(
          "An error occurred while fetching permissions"
        );
      });
  }, [roleID]);

  return (
    <div className="modal view_researcher_role_modal show">
      <div className="modal-dialog">
        <div className="modal-content">
          {!selectedRole ? (
            <p>Role not found</p>
          ) : (
            <>
              <span
                className="close"
                onClick={() => {
                  closeResearcherRoleModal();
                }}
              >
                &times;
              </span>
              <div className="container-fluid">
                <h5 className="modal_page_header">Role Permissions</h5>
              </div>
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <h2>{selectedRole.name}</h2>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-4">ID</div>
                    <div className="col-8">1</div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <strong>Permissions</strong>
                    </div>
                  </div>
                  {loadingPermissions ? (
                    <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
                      <div className="spinner-border" role="status"></div>
                    </div>
                  ) : loadingPermissionsError ? (
                    <div className="alert alert-danger" role="alert">
                      {loadingPermissionsError}
                    </div>
                  ) : !permissions || permissions.length === 0 ? (
                    <div className="alert alert-danger" role="alert">
                      No permissions found
                    </div>
                  ) : (
                    permissions.map((permission) => (
                      <div key={permission.id} className="row">
                        <div className="col-12">
                          {permission.permission_description}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewResearcherRoleModal;
