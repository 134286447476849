//Page to display the Study gets the study name from the url

import { useEffect, useState } from "react";
// import "./study.scss";
import { useParams } from "react-router-dom";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { fetchSingleClientByID } from "../../models/client.model";
import { fetchStudyByClientID } from "../../models/study.model";
import { fetchAllSurveys } from "../../models/survey.model";
import { Client, SidebarProps, Study, SurveyPill } from "../../types";
import ErrorPage from "../Error/error.page";
import StudyDashboard from "../StudyDashboard/studyDashboard.component";

interface SurveyPageProps extends SidebarProps {}

const StudyPage: React.FC<SurveyPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  const { clientID, studyID, surveyID } = useParams(); // Read values passed on link
  const [study, setStudy] = useState<Study | null>(null); // Set initial state to null
  const [client, setClient] = useState<Client | null>(null); // Set initial state to null
  const [allSurveys, setAllSurveys] = useState<SurveyPill[] | null>(null); // Set initial state to null
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyPill | null>(null); // Set initial state
  const [loading, setLoading] = useState<boolean>(true);
  // const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);
    //fetch the client and it's studies
    const fetchClientsData = async () => {
      try {
        const jsonData = await fetchSingleClientByID(clientID as string);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          // setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "690414e073ccf236b43daa64d7ac7044"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setClient(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        // setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "c3232cc30fef4347c90168e41d78d1a2"
        ]);
      }
    };

    const fetchStudy = async () => {
      try {
        const jsonData = await fetchStudyByClientID(
          clientID as string,
          studyID as string
        );
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          console.log("jsonData is a string");
          console.log("jsonData", jsonData);
          // setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "0469bf59aa0d263c3a641f36dcb1591c"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          // console.log(jsonData.id);
          // console.log(typeof studyID); // before your jsonData loop
          // console.log(typeof jsonData.id); // before your jsonData loop
          // console.log(jsonData);

          if (jsonData.id === studyID) {
            setStudy(jsonData);
          }
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        // setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "a9495ebbfdbf61d7f4ca59692fbaa432"
        ]);
      }
    };

    const fetchSurveys = async () => {
      try {
        const jsonData = await fetchAllSurveys(
          clientID as string,
          studyID as string
        );
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          console.log("jsonData is a string");
          console.log("jsonData", jsonData);
          // setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "0109a50b4cc5cb997c931eb29fc2a171"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          // console.log(jsonData);
          // console.log(typeof studyID); // before your jsonData loop
          // console.log(typeof jsonData); // before your jsonData loop
          // console.log(jsonData);
          setAllSurveys(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        // setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "790242d21b5a333257ff1ec847d2903d"
        ]);
      }
    };

    const promises = [fetchClientsData(), fetchStudy(), fetchSurveys()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "0c7cf47eaf82d726e62b9d6ece7987d3"
        ]);
      });
  }, [studyID, clientID]);

  useEffect(() => {
    // moved into a separate useEffect to avoid refetching surveys on every render
    const foundSurvey: SurveyPill | undefined = allSurveys?.find(
      (survey: SurveyPill) => survey.id === surveyID
    );
    // console.log("foundSurvey", foundSurvey);
    if (foundSurvey) {
      setSelectedSurvey(foundSurvey);
    }
  }, [surveyID, allSurveys]);

  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        {/* <NavbarComponent /> */}
        {loading ? (
          <div className="d-flex justify-content-center custom_spinner_container full_page">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="container-fluid" id="study_page">
            {!client ? ( //check if client is valid
              <ErrorPage
                errorCode="404"
                error="Client not found"
                debugCode={loadingErrorText}
              />
            ) : !study ? ( //check if study is valid
              <ErrorPage
                errorCode="404"
                error="Study not found"
                debugCode={loadingErrorText}
              />
            ) : !allSurveys ? (
              <ErrorPage
                errorCode="404"
                error="Can't find surveys"
                debugCode={loadingErrorText}
              />
            ) : !selectedSurvey ? (
              <ErrorPage
                errorCode="404"
                error="Survey not found1"
                debugCode={loadingErrorText}
              />
            ) : (
              <StudyDashboard
                study={study}
                clientID={client.id}
                surveys={allSurveys}
              />
            )}
          </div>
        )}
      </div>
      <SidebarComponent
        page="study"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={client?.id}
        currentStudy={study ? study.id : null}
        currentClientLogo={client?.icon_url}
        currentStudyPhoto={study?.thumbnail_url}
      />
    </div>
  );
};

export default StudyPage;
