import React from "react";
import { useNavigate } from "react-router-dom";
import { SidebarDataStudy } from "../../types.ts";

interface SidebarLevelThreeProps {
  sidebarDisplayData: SidebarDataStudy[];
  hoveredClientID: string;
  showLevel: boolean;
  handleShowLevelLowerLevel: (ID: string) => void;
  currentStudyID: string;
}

const SidebarLevelThree: React.FC<SidebarLevelThreeProps> = ({
  sidebarDisplayData,
  showLevel,
  hoveredClientID,
  handleShowLevelLowerLevel,
  currentStudyID
}) => {
  const navigate = useNavigate();

  function handleClientIconClick(ID: string) {
    console.log("handleClientIconClick");
    navigate(`/clients/${hoveredClientID}/${ID}`);
  }

  return (
    <div
      id="sidebarLevelThree"
      className={`sidebarLevel ${showLevel ? "show" : "hide"}`}
    >
      {sidebarDisplayData.length === 0 ? (
        <p>No studies</p>
      ) : (
        /* map though all sidebarDisplayData and display all clientNames */
        sidebarDisplayData.map((study: SidebarDataStudy) => (
          <button
            key={study.studyID}
            className={`sidebar_button clickable button_client_logo${
              currentStudyID === study.studyID ? " selected" : ""
            }`}
            onClick={() =>
              handleClientIconClick(study.studyID ? study.studyID : "")
            }
            //   hover handleShowLevelLowerLevel(ID)
            onMouseEnter={() =>
              handleShowLevelLowerLevel(study.studyID ? study.studyID : "")
            }
            data-toggle="tooltip"
            title={study.studyName ? study.studyName : ""}
            style={{
              backgroundImage: `url(${
                study.studyPicture
                  ? study.studyPicture
                  : "/images/placeholders/no-image.png"
              })`
            }}
          ></button>
        ))
      )}
    </div>
  );
};

export default SidebarLevelThree;
