import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePermissions } from "../../contexts/UserContext";
import "./studyDetailsTabs.scss";

// Icons
import {
  IconAutomation,
  IconMessages as IconChat,
  IconBullhorn as IconMessages,
  IconChartLine as IconOverview,
  IconParticipants,
  IconReceipt,
  IconResources,
  IconRewards,
  IconSettings,
  IconTags
} from "../../assets/images/icons/icons";
import { SurveyPill } from "../../types";
export type StudyDetailsTabsProps = {
  setTabClicked: React.Dispatch<React.SetStateAction<string>>;
  tabClicked: string;
  studyClicked: string;
  surveySystem?: SurveyPill["survey_system"];
};

const StudyDetailsTabs: React.FC<StudyDetailsTabsProps> = ({
  setTabClicked,
  tabClicked,
  studyClicked,
  surveySystem
}) => {
  const { studyID, surveyID } = useParams<{
    studyID: string;
    surveyID: string;
  }>();

  const { hasPermission } = usePermissions();

  const handleSetTabClicked = async (tabName: string) => {
    // store the tab name for the specific study/survey (based on id) in session storage
    setTabClicked(tabName);
    if (studyClicked === "study" && studyID) {
      sessionStorage.setItem(
        "studyTabComponentLocation",
        JSON.stringify({
          id: studyID,
          tabName: tabName
        })
      );
    } else if (
      (studyClicked === "survey" || studyClicked === "diary") &&
      surveyID
    ) {
      sessionStorage.setItem(
        "surveyTabComponentLocation",
        JSON.stringify({
          id: surveyID,
          tabName: tabName
        })
      );
    }
  };

  useEffect(() => {
    // console.log(studyClicked, "studyClicked");
    // console.log("tabClicked:", tabClicked);
  }, [tabClicked, studyClicked]);

  return (
    <>
      <div className="study_nav_details_container">
        <ul className="nav nav-underline">
          {(hasPermission("subject") || hasPermission("bucket")) &&
            studyClicked === "study" && (
              /* Overview */
              <li
                className="nav-item"
                onClick={() => handleSetTabClicked("overview")}
              >
                <span
                  className={`nav-link ${
                    tabClicked === "overview" ? "is_active" : ""
                  }`}
                >
                  <span className="icon_container">
                    <IconOverview className="icon icon_black" />
                  </span>
                  Overview
                </span>
              </li>
            )}
          {/* Participants */}
          {(hasPermission("subject") || hasPermission("participant")) && (
            <li
              className="nav-item"
              onClick={() => handleSetTabClicked("participants")}
            >
              <span
                className={`nav-link ${
                  tabClicked === "participants" ? "is_active" : ""
                }`}
              >
                <span className="icon_container">
                  <IconParticipants className="icon icon_black" />
                </span>
                Participants
              </span>
            </li>
          )}
          {/* These only show when a study is selected */}
          {studyClicked === "study" && (
            <>
              {(hasPermission("subject") || hasPermission("tag")) && (
                <li
                  className="nav-item"
                  onClick={() => handleSetTabClicked("tags")}
                >
                  <span
                    className={`nav-link ${
                      tabClicked === "tags" ? "is_active" : ""
                    }`}
                  >
                    <span className="icon_container">
                      <IconTags className="icon icon_black" />
                    </span>
                    Tags
                  </span>
                </li>
              )}
              {/* Automation */}
              {(hasPermission("subject") || hasPermission("automation")) && (
                <li
                  className="nav-item"
                  onClick={() => handleSetTabClicked("automation")}
                >
                  <span
                    className={`nav-link ${
                      tabClicked === "automation" ? "is_active" : ""
                    }`}
                  >
                    <span className="icon_container">
                      <IconAutomation className="icon icon_black" />
                    </span>
                    Automations
                  </span>
                </li>
              )}
              {(hasPermission("subject") || hasPermission("chat")) && (
                <li
                  className="nav-item"
                  onClick={() => handleSetTabClicked("chat")}
                >
                  <span
                    className={`nav-link ${
                      tabClicked === "chat" ? "is_active" : ""
                    }`}
                  >
                    <span className="icon_container">
                      <IconChat className="icon icon_black" />
                    </span>
                    Chat
                  </span>
                </li>
              )}
              {(hasPermission("subject") || hasPermission("messages")) && (
                <li
                  className="nav-item"
                  onClick={() => handleSetTabClicked("messages")}
                >
                  <span
                    className={`nav-link ${
                      tabClicked === "messages" ? "is_active" : ""
                    }`}
                  >
                    <span className="icon_container">
                      <IconMessages className="icon icon_black" />
                    </span>
                    Messages
                  </span>
                </li>
              )}
              {/* Rewards */}
              {(hasPermission("rewards") || hasPermission("subject")) && (
                <li
                  className="nav-item"
                  onClick={() => handleSetTabClicked("rewards")}
                >
                  <span
                    className={`nav-link ${
                      tabClicked === "rewards" ? "is_active" : ""
                    }`}
                  >
                    <span className="icon_container">
                      <IconRewards className="icon icon_black" />
                    </span>
                    Rewards
                  </span>
                </li>
              )}
              {/* Resources  */}
              {(hasPermission("resources") || hasPermission("subject")) && (
                <li
                  className="nav-item"
                  onClick={() => handleSetTabClicked("resources")}
                >
                  <span
                    className={`nav-link ${
                      tabClicked === "resources" ? "is_active" : ""
                    }`}
                  >
                    <span className="icon_container">
                      <IconResources className="icon icon_black" />
                    </span>
                    Resources
                  </span>
                </li>
              )}
              {/* Geo-Fences */}
              {/* <li
                className="nav-item"
                onClick={() => handleSetTabClicked("geo-fences")}
              >
                <span
                  className={`nav-link ${
                    tabClicked === "geo-fences" ? "is_active" : ""
                  }`}
                >
                  <span className="icon_container">
                    <IconLocation className="icon icon_black" />
                  </span>
                  Geo-Fences
                </span>
              </li> */}
            </>
          )}
          {(hasPermission("subject") || hasPermission("survey")) &&
            (studyClicked === "survey" || studyClicked === "diary") && (
              //  WSC
              <li
                className="nav-item"
                onClick={() => handleSetTabClicked("wsc")}
              >
                <span
                  className={`nav-link ${
                    tabClicked === "wsc" ? "is_active" : ""
                  }`}
                >
                  <span className="icon_container">
                    <IconReceipt className="icon icon_black" />
                  </span>
                  Imports/Mapping
                </span>
              </li>
            )}
          {(hasPermission("subject") || hasPermission("study")) && (
            <li
              className="nav-item"
              onClick={() => handleSetTabClicked("settings")}
            >
              <span
                className={`nav-link ${
                  tabClicked === "settings" ? "is_active" : ""
                }`}
              >
                <span className="icon_container">
                  <IconSettings className="icon icon_black" />
                </span>
                Settings
              </span>
            </li>
          )}
          {(hasPermission("subject") || hasPermission("study")) &&
            surveySystem === "viewEQ" &&
            studyClicked === "survey" && (
              <li
                className="nav-item"
                onClick={() => handleSetTabClicked("viewEQ")}
              >
                <span
                  className={`nav-link ${
                    tabClicked === "viewEQ" ? "is_active" : ""
                  }`}
                >
                  <span className="icon_container">
                    <IconResources className="icon icon_black" />
                  </span>
                  View EQ
                </span>
              </li>
            )}
        </ul>
      </div>
      <hr />
    </>
  );
};

export default StudyDetailsTabs;
