//Page to display the clients in cards
import AddClientWizard from "../../components/Wizards/AddClientWizard/addClientWizard.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";
import "./addClient.scss";

interface AddClientPageProps extends SidebarProps {}

const AddClientPage: React.FC<AddClientPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        {/* <NavbarComponent /> */}
        <div className="container" id="view_all_clients_page">
          <AddClientWizard />
        </div>
      </div>
      <SidebarComponent
        page="clients"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
    </div>
  );
};

export default AddClientPage;
