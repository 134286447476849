import React, { useEffect } from "react";
import { IconAdd } from "../../../../assets/images/icons/icons";
import {
  AutomationRuleData,
  ChildConditionGroup,
  ConditionRuleSingle
} from "../../../../types";
import ConditionRule from "./conditionRule.component";
import "./conditions.scss";

export type ConditionGroupProps = {
  conditionGroupID: string;
  childConditionGroups: ChildConditionGroup;
  deleteGroup: (groupId: string) => void;
  addGroupToId: (groupId: string) => void;
  childRules: ConditionRuleSingle[];
  addRule: (groupId: string) => void;
  removeConditionRule: (groupId: string, ruleId: string) => void;

  setChildConditionGroups: React.Dispatch<
    React.SetStateAction<ChildConditionGroup>
  >;
  handleConditionOperator: (groupId: string, operator: string) => void;
  operator: string;
  disableOperator?: boolean;

  // Rule Handlers
  addRuleSelection: (
    groupId: string,
    ruleId: string,
    ruleSelection: string
  ) => void;
  addRuleOption: (groupId: string, ruleId: string, ruleOption: string) => void;
  addRuleValue: (groupId: string, ruleId: string, ruleValue: string) => void;
  addRuleType: (groupId: string, ruleId: string, ruleType: string) => void;
  addRuleVariable: (
    groupId: string,
    ruleId: string,
    ruleVariable: string
  ) => void;

  // data for dropdowns memoized in conditions.container.tsx
  dropdownRuleData: AutomationRuleData;
  editable: boolean;
  viewEditRule: boolean;
};

const ConditionGroup: React.FC<ConditionGroupProps> = ({
  conditionGroupID,
  childConditionGroups,
  setChildConditionGroups,
  deleteGroup,
  addGroupToId,
  childRules,
  addRule,
  removeConditionRule,
  handleConditionOperator,
  operator,
  disableOperator,
  addRuleSelection,
  addRuleOption,
  addRuleValue,
  addRuleType,
  addRuleVariable,
  dropdownRuleData,
  editable,
  viewEditRule
}) => {
  useEffect(() => {
    console.log(childRules);
  }, [childRules]);

  useEffect(() => {
    // log the length of childConditionGroups
    console.log(
      `Length of childConditionGroups: ${
        Object.keys(childConditionGroups).length
      }`
    );
    console.log(conditionGroupID, "ID");
    console.log(childConditionGroups);
    console.log(childConditionGroups.hasOwnProperty(conditionGroupID));
    console.log(childConditionGroups[conditionGroupID]?.operator);
  }, [childConditionGroups, childRules]);

  useEffect(() => {
    console.log("HIT");
    console.log(childRules);
    console.log(dropdownRuleData);
  }, [childRules]);

  // When disableOperator becomes true, set the operator to "and"
  useEffect(() => {
    if (disableOperator === true && editable === true) {
      console.log("hit");
      handleConditionOperator(conditionGroupID, "and");
    } else {
      console.log("hit");
      // If operator is or, and disable is false, set to and (defaulting)
      if (operator == "or" && editable === true && disableOperator === false) {
        console.log("hit");
        handleConditionOperator(conditionGroupID, "and");
      } else {
        handleConditionOperator(conditionGroupID, operator);
      }
    }
  }, [disableOperator]);

  useEffect(() => {
    console.log(dropdownRuleData);
  }, []);

  return (
    <div
      className={`d-flex condition_group_container container_${conditionGroupID} ${
        conditionGroupID === "0" ? "" : "not_first"
      }`}
    >
      {/* TOP GROUP BUTTONS, AND/OR ADD GROUP/RULE */}
      <div
        className={`top_group_buttons${conditionGroupID === "0" ? "_0" : ""}`}
      >
        <div className="toggle_condition_type">
          {/* Render the "AND" radio button */}
          {(editable || (operator === "and" && !editable)) && (
            <>
              <input
                type="radio"
                className={`btn-check conditional_radio`}
                name={`conditionGroupOption_${conditionGroupID}`}
                id={`${conditionGroupID}_and`}
                value={operator}
                checked={operator === "and"}
                onChange={() =>
                  handleConditionOperator(conditionGroupID, "and")
                }
                disabled={!disableOperator}
              />
              <label
                className={`btn btn-secondary and_radio ${
                  editable ? "" : "edit_disabled"
                }`}
                htmlFor={`${conditionGroupID}_and`}
              >
                AND
              </label>
            </>
          )}

          {/* Render the "OR" radio button */}
          {(editable || (operator === "or" && !editable)) && (
            <>
              <input
                type="radio"
                className={`btn-check conditional_radio ${
                  editable === false ? "" : "edit_disabled"
                }`}
                name={`conditionGroupOption_${conditionGroupID}`}
                id={`${conditionGroupID}_or`}
                value={operator}
                checked={operator === "or"}
                onChange={() => handleConditionOperator(conditionGroupID, "or")}
                disabled={!disableOperator}
              />
              <label
                className={`btn btn-secondary or_radio ${
                  editable ? "" : "edit_disabled"
                }`}
                htmlFor={`${conditionGroupID}_or`}
              >
                OR
              </label>
            </>
          )}

          {/* <div className="ms-1">{conditionGroupID}</div> */}
        </div>
        {editable && (
          <div className="add_buttons">
            <button
              className="btn btn-primary add_button"
              onClick={() => addRule(conditionGroupID)}
            >
              <span>
                <IconAdd />
              </span>
              Add Rule
            </button>
            {conditionGroupID === "0" && (
              <button
                className="btn btn-primary add_button"
                onClick={() => {
                  console.log("Directly within onClick:", conditionGroupID);
                  addGroupToId(conditionGroupID);
                }}
              >
                <span>
                  <IconAdd />
                </span>
                Add Group
              </button>
            )}
            {/* {conditionGroupID !== "0" && (
              <button
                className="condition_group_delete_button btn btn-danger"
                onClick={() => deleteGroup(conditionGroupID)}
              >
                <IconDelete />
              </button>
            )} */}
          </div>
        )}
      </div>

      {/* Render the child rules */}
      <div className="rules_list">
        {childRules.map((rule) => (
          <ConditionRule
            key={rule.id}
            id={rule.id}
            selection={rule.selection}
            options={rule.options}
            values={rule.value}
            variable={rule.variable}
            type={rule.type}
            conditionGroupID={conditionGroupID}
            removeConditionRule={removeConditionRule}
            // Rule handlers
            addRuleSelection={addRuleSelection}
            addRuleOption={addRuleOption}
            addRuleValue={addRuleValue}
            addRuleType={addRuleType}
            addRuleVariable={addRuleVariable}
            dropdownRuleData={dropdownRuleData}
            editable={editable}
            viewEditRule={viewEditRule}
          />
        ))}

        {Object.keys(childConditionGroups).map(
          (id) => (
            console.log(id),
            (
              <ConditionGroup
                key={id}
                conditionGroupID={id}
                childConditionGroups={
                  childConditionGroups[id].childConditionGroups
                }
                setChildConditionGroups={setChildConditionGroups}
                deleteGroup={deleteGroup}
                addGroupToId={addGroupToId}
                childRules={childConditionGroups[id].childRules}
                operator={childConditionGroups[id].operator}
                // setChildRules={setChildRules}
                addRule={addRule}
                removeConditionRule={removeConditionRule}
                handleConditionOperator={handleConditionOperator}
                disableOperator={
                  // childRules length greater than 1
                  Object.keys(childConditionGroups[id].childRules).length > 1
                    ? true
                    : false || // or childConditionGroups length greater than 1
                      Object.keys(childConditionGroups[id].childConditionGroups)
                        .length > 1 || // child rules + child conditions less than 2
                      Object.keys(childConditionGroups[id].childRules).length +
                        Object.keys(
                          childConditionGroups[id].childConditionGroups
                        ).length >
                        1 ||
                      editable === false
                }
                // Rule handlers
                addRuleSelection={addRuleSelection}
                addRuleOption={addRuleOption}
                addRuleValue={addRuleValue}
                addRuleType={addRuleType}
                addRuleVariable={addRuleVariable}
                dropdownRuleData={dropdownRuleData}
                editable={editable}
                viewEditRule={viewEditRule}
              />
            )
          )
        )}
      </div>
    </div>
  );
};

export default ConditionGroup;
