// Form to add client to the database

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddClientWizardClientInformation from "./addClientWiazrdClientInformation.component";
import AddClientWizardWSC from "./addClientWiazrdWSC.component";
import "./addClientWizard.scss";

const AddClientWizard = () => {
  const navigate = useNavigate();
  const totalPages = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const validateWizard = () => {
    if (true) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleWizardBack = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (currentPage > totalPages) {
      navigate("/clients");
    }
  }, [currentPage]);

  return (
    <div id="add_client_form">
      <h1>Add Client</h1>
      <div className="">
        {currentPage === 1 ? (
          <AddClientWizardClientInformation
            validateWizard={validateWizard}
            currentPage={currentPage}
            totalPages={totalPages}
            handleWizardBack={handleWizardBack}
          />
        ) : currentPage === 2 ? (
          <AddClientWizardWSC
            validateWizard={validateWizard}
            currentPage={currentPage}
            totalPages={totalPages}
            handleWizardBack={handleWizardBack}
          />
        ) : (
          <h1>Error</h1>
        )}
      </div>
    </div>
  );
};

export default AddClientWizard;
