import React, { useEffect, useState } from "react";
import * as Icons from "../../../assets/images/icons/icons.ts";
import "../../../components/Tables/tableComponents.scss"; // shared styles for tables
import { getAllResearchers } from "../../../models/researcher.model";
import ErrorPage from "../../../pages/Error/error.page";
import {
  Client,
  Country,
  FetchResponseError,
  Language,
  Researcher,
  ResearcherRole,
  Tags,
  Timezone
} from "../../../types";
import {
  getResearcherConfigTimezone,
  getSystemConfigCountries,
  getSystemConfigLanguages,
  getSystemConfigTimezones
} from "../../../utilities/config.util";
import { isFetchResponseError } from "../../../utilities/utils";
// import ImportResearchersModal from "../../ImportResearchersModal/importResearchersModal.component.tsx";
import { exportResearchers } from "../../../utilities/exportTable.util.ts";
import BulkActions from "../../Tables/BulkActions/bulkActions.tsx";
import FiltersContainer from "../../Tables/Filters/filtersContainer.component.tsx";
import PaginationNavigation from "../../Tables/PaginationNavigation/paginationNavigation.tsx";
import ResearchersTable from "../../Tables/TableResearchers/researchersTable.component.tsx";
import TableRowsSelector from "../../Tables/TableRowsSelector/tableRowsSelector.tsx";
import TimezoneSwitcher from "../../Tables/TimeZoneSwitcher/timezoneSwitcher.tsx";
import ViewResearcherModal from "../../ViewResearcherModal/viewResearcherModal.tsx";
import ViewResearcherRoleModal from "../../ViewResearcherRoleModal/viewResearcherRoleModal.component.tsx";
// import ViewResearcherModal from "../../ViewResearcherModal/viewResearcherModal.tsx";
// import "./researchers.scss";

interface DisplayResearchersProps {
  allRoles: ResearcherRole[];
}

const DisplayResearchers: React.FC<DisplayResearchersProps> = ({
  allRoles
}) => {
  const tableType = "researchersTable";
  const tableDisplayName = "Researchers Table";
  const [currentPage, setCurrentPage] = useState(1);
  // const researchersPerPage = 20;
  const [researchersPerPage, setResearchersPerPage] = useState(20);

  const [researchersTagsFilter, setResearchersTagsFilter] = useState<string[]>(
    []
  );

  const [researchersLanguagesFilter, setResearchersLanguagesFilter] = useState<
    string[]
  >([]);

  const [researchersCountryFilter, setResearchersCountryFilter] = useState<
    string[]
  >([]);

  const [researchersStatusFilter, setResearchersStatusFilter] = useState<
    string[]
  >([]);

  const [researchersTimezonesFilter, setResearchersTimezonesFilter] = useState<
    string[]
  >([]);

  const [researchersRolesFilter, setResearchersRolesFilter] = useState<
    string[]
  >([]);

  const [researchersUDIDFilter, setResearchersUDIDFilter] =
    useState<boolean>(false);

  const [researchersSearch, setResearchersSearch] = useState<string>("");

  // State variable to hold the original researchers
  const [originalResearchers, setOriginalResearchers] = useState<Researcher[]>(
    []
  );
  // State variable to hold the filtered researchers
  const [filteredResearchers, setFilteredResearchers] = useState<Researcher[]>(
    []
  );

  const [loadingResearchers, setLoadingResearchers] = useState<boolean>(true);

  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);
  const [allClients, setAllClients] = useState<Client[]>([]);
  const [displayedDataDateOffet, setDisplayedDataDateOffet] =
    useState<string>("");
  const [refreshButtonEnabled, setRefreshButtonEnabled] =
    useState<boolean>(false);
  const [errorFetchingData, setErrorFetchingData] =
    useState<FetchResponseError | null>(null);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  // State to track if a user has been added, for fetching latest data
  const [userAdded, setUserAdded] = useState<boolean>(false);

  // bulk actions
  const [selectedResearchers, setSelectedResearchers] = useState<string[]>([]);

  const [currentResearchers, setCurrentResearchers] = useState<Researcher[]>(
    []
  );

  // const timezones = JSON.parse(localStorage.getItem("timezones") || "[]");
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone | null>(
    null
  );

  const [timezones, setTimezones] = useState<Timezone[]>([]);

  // User can switch between UTC, User timezone, and researcher's timezone
  const [timezone, setTimezone] = useState<string>("ResearcherTimezone");

  // User can view researcher details
  const [showModal, setShowModal] = useState(false);
  const [selectedResearcher, setSelectedResearcher] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const [showImportModal, setShowImportModal] = useState(false);

  const [showAddResearcherModal, setShowAddResearcherModal] = useState(false);

  const [activeResearcherId, setActiveResearcherId] = useState<string | null>(
    null
  );

  // store current state of filters
  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  const [researchersStatuses, setResearchersStatuses] = useState<string[]>([]);
  const [researchersLanguages, setResearchersLanguages] = useState<Language[]>(
    []
  );
  const [researchersCountries, setResearchersCountries] = useState<Country[]>(
    []
  );
  const [researchersTimezones, setResearchersTimezones] = useState<Timezone[]>(
    []
  );

  const [researchersRoles, setResearchersRoles] = useState<ResearcherRole[]>(
    []
  );

  const [researchersTags, setResearchersTags] = useState<Tags[]>([]);
  const [searchedMultiResearchersIDs, setSearchedMultiResearchersIDs] =
    useState<string[]>([]);

  const fetchData = async (isRealData: boolean = false) => {
    // Fetch researchers data from database
    setLoadingResearchers(true);

    // clear values
    setSelectedResearcher("");
    setSelectedResearchers([]);
    setFilterApplied(false);
    handleClearFilters();
    // setOriginalResearchers([]);
    // setFilteredResearchers([]);

    try {
      const jsonData = await getAllResearchers();

      if (isFetchResponseError(jsonData)) {
        console.log(
          isRealData ? "error Status: " : "error Code: " + jsonData.errorCode
        );
        console.log("error Message: " + jsonData.errorMessage);
        setErrorFetchingData(jsonData);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          isRealData
            ? "067f178a46aa376bf83069d315b626a3"
            : "4813616a8c447c2d9b694607a0649e70"
        ]);
      } else {
        setErrorFetchingData(null);
        setOriginalResearchers(jsonData);
        setFilteredResearchers(jsonData);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching researchers data:",
        error
      );
      setErrorFetchingData({
        errorCode: "500",
        errorMessage: "Error fetching data"
      });
      setLoadingErrorText((prevErrors) => [
        ...prevErrors,
        isRealData
          ? "54b60f7a5dad6daf1510357ec608a0fb"
          : "20dd3c192b851920d1a40f196fed5cde"
      ]);
    }

    setLoadingResearchers(false);
  };

  useEffect(() => {
    // initial fetch of data
    fetchData();
  }, []);

  useEffect(() => {
    // get langauges from local storage using getConfigLanguages
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigCountries = async () => {
      try {
        const jsonData: Country[] | false = await getSystemConfigCountries();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setCountries(jsonData);
          } catch {
            console.error("Error setting countries");
            throw new Error("Error setting countries");
          }

          // setCountries([{ iso_code: "en", name: "English" }]);
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigTimezones = async () => {
      try {
        const jsonData: Timezone[] | false = await getSystemConfigTimezones();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setTimezones(jsonData);
          } catch {
            console.error("Error setting timezones");
            throw new Error("Error setting timezones");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const promises = [
      fetchAllConfigLanguages(),
      fetchAllConfigCountries(),
      fetchAllConfigTimezones()
    ];

    Promise.all(promises)
      .then(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      })
      .catch(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      });
  }, []);

  // Update the filtered researchers list
  useEffect(() => {
    if (errorFetchingData || !originalResearchers) {
      return;
    }
    // Step 1: Apply all filters except UID filter
    let preliminaryFiltered = originalResearchers.filter(
      (researcher: Researcher) => {
        // Apply search filter if researchersSearch is not empty
        if (
          researchersSearch !== "" &&
          !(
            researcher.first_name
              ?.toLowerCase()
              .includes(researchersSearch.toLowerCase()) ||
            researcher.surname
              ?.toLowerCase()
              .includes(researchersSearch.toLowerCase()) ||
            researcher.email
              ?.toLowerCase()
              .includes(researchersSearch.toLowerCase()) ||
            // Check name + surname
            (researcher.first_name + " " + researcher.surname)
              .toLowerCase()
              .includes(researchersSearch.toLowerCase()) ||
            // mobile
            researcher.full_mobile
              ?.toLowerCase()
              .includes(researchersSearch.toLowerCase()) ||
            // User ID
            researcher.id.toString().includes(researchersSearch.toLowerCase())
          )
        ) {
          return false;
        }

        // Apply tags filter if researchersTagsFilter is not empty
        // if (
        //   researchersTagsFilter.length > 0 &&
        //   researcher.researcher_tags !== undefined &&
        //   !researchersTagsFilter.some((tag) =>
        //     researcher.researcher_tags?.includes(tag)
        //   )
        // ) {
        //   return false;
        // }

        // Apply languages filter if researchersLanguagesFilter is not empty
        if (
          researchersLanguagesFilter.length > 0 &&
          researcher.language_iso &&
          !researchersLanguagesFilter.includes(researcher.language_iso)
        ) {
          return false;
        }

        // Apply country filter if researchersCountryFilter is not empty
        if (
          researchersCountryFilter.length > 0 &&
          researcher.country_iso &&
          !researchersCountryFilter.includes(researcher.country_iso)
        ) {
          return false;
        }

        // Apply timezone filter if researchersTimezonesFilter is not empty
        if (
          researchersTimezonesFilter.length > 0 &&
          researcher.timezone &&
          !researchersTimezonesFilter.includes(researcher.timezone)
        ) {
          return false;
        }

        // Apply roles filter if researchersRolesFilter is not empty
        if (
          researchersRolesFilter.length > 0 &&
          researcher.role_id &&
          !researchersRolesFilter.includes(researcher.role_id)
        ) {
          return false;
        }

        // Apply status filter if researchersStatusFilter is not empty
        // if (
        //   researchersStatusFilter.length > 0 &&
        //   researcher.researcher_status &&
        //   !researchersStatusFilter.includes(researcher.researcher_status)
        // ) {
        //   return false;
        // }

        return true;
      }
    );

    // searchedMultiResearchersIDs, filter by researcherIDs
    if (searchedMultiResearchersIDs.length > 0) {
      preliminaryFiltered = preliminaryFiltered.filter((researcher) => {
        return searchedMultiResearchersIDs.includes(researcher.id.toString());
      });
    }

    setFilteredResearchers(preliminaryFiltered);
    setCurrentPage(1);
  }, [
    originalResearchers,
    researchersSearch,
    researchersTagsFilter,
    researchersStatusFilter,
    researchersUDIDFilter,
    researchersLanguagesFilter,
    researchersCountryFilter,
    researchersRolesFilter,
    researchersTimezonesFilter,
    searchedMultiResearchersIDs,
    errorFetchingData
  ]);

  useEffect(() => {
    // console.log(currentResearchers, filteredResearchers);
    if (!errorFetchingData && filteredResearchers) {
      setCurrentResearchers(
        filteredResearchers.slice(
          (currentPage - 1) * researchersPerPage,
          currentPage * researchersPerPage
        )
      );
    }
  }, [currentPage, researchersPerPage, filteredResearchers, errorFetchingData]);

  const totalResearchers = !errorFetchingData
    ? filteredResearchers?.length ?? 0
    : 0;

  useEffect(() => {
    getResearcherConfigTimezone().then((result) => {
      // console.log("result", result);
      if (result) {
        setResearcherTimezone(result);
      } else {
        setResearcherTimezone(null);
      }
    });
  }, []);

  const handleChangeTimezone = (timezone: string) => {
    setTimezone(timezone);
  };

  const handleViewResearcher = (researcherID: string) => {
    setSelectedResearcher(researcherID);
    // console.log("View researcher: " + researcherID);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // delay of 0.5 seconds
    setTimeout(() => {
      setSelectedResearcher("");
    }, 500);
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
    // delay of 0.5 seconds
  };

  const handleCloseAddResearcherModal = () => {
    setShowAddResearcherModal(false);
    // delay of 0.5 seconds
  };

  const handleChangeRows = (rows: number) => {
    setResearchersPerPage(rows);
  };

  const handleResearcherActionsDropdown = (researcherId: string | null) => {
    // TODO: handle outside click
    // if (researcherId === "outside") {
    //   // close all dropdowns
    //   // console.log("outside");
    //   setActiveResearcherId(null);
    //   return;
    // }

    if (researcherId === "close") {
      // reload the page
      // window.location.reload();
      setActiveResearcherId(null);
      // refresh data
      fetchData(true);
    } else {
      if (researcherId === activeResearcherId) {
        setActiveResearcherId(null);
      } else {
        setActiveResearcherId(researcherId);
      }
    }
  };

  useEffect(() => {
    if (
      researchersSearch !== "" ||
      researchersTagsFilter.length > 0 ||
      researchersStatusFilter.length > 0 ||
      researchersUDIDFilter ||
      researchersLanguagesFilter.length > 0 ||
      researchersCountryFilter.length > 0 ||
      searchedMultiResearchersIDs.length > 0 ||
      researchersTimezonesFilter.length > 0 ||
      researchersRolesFilter.length > 0
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [
    researchersSearch,
    researchersTagsFilter,
    researchersStatusFilter,
    researchersUDIDFilter,
    researchersLanguagesFilter,
    researchersCountryFilter,
    searchedMultiResearchersIDs,
    researchersTimezonesFilter,
    researchersRolesFilter
  ]);

  const handleClearFilters = () => {
    // Clear the filter state
    setResearchersSearch("");
    setResearchersTagsFilter([]);
    setResearchersStatusFilter([]);
    setResearchersUDIDFilter(false);
    setResearchersLanguagesFilter([]);
    setResearchersCountryFilter([]);
    setSearchedMultiResearchersIDs([]);
    setResearchersTimezonesFilter([]);
    setResearchersRolesFilter([]);
  };

  const handleSelectResearcher = (researcherId: string) => {
    // add researcherId to selectedResearchers, if already present, remove it
    if (selectedResearchers.includes(researcherId)) {
      setSelectedResearchers(
        selectedResearchers.filter((id) => id !== researcherId)
      );
    } else {
      setSelectedResearchers([...selectedResearchers, researcherId]);
    }
  };

  const handleSelectAllResearchers = () => {
    // if all researchers are already selected, deselect all
    if (selectedResearchers.length === (filteredResearchers?.length ?? 0)) {
      setSelectedResearchers([]);
    } else {
      setSelectedResearchers(
        filteredResearchers.map((p: Researcher) => p.id.toString())
      );
    }
  };

  const handleRefreshData = () => {
    // does a fetch of the data again
    // console.log("handleRefreshData");
    setRefreshButtonEnabled(false);
    setDisplayedDataDateOffet("00:00:00");
    // Force pull real data
    fetchData(true);
  };

  // After closing add researchers modal, fetch real data
  useEffect(() => {
    // console.log(showAddResearcherModal);
    // console.log(userAdded);
    if (!showAddResearcherModal && userAdded) {
      const fetchDataInUse = async () => {
        if (!showAddResearcherModal && userAdded) {
          // console.log("REDNDEDE");
          await fetchData();
        }
      };

      fetchDataInUse();
    }
  }, [showAddResearcherModal, userAdded]);

  // useEffect(() => {
  //   // get all unique researchers statuses
  //   // const statuses = [
  //   //   ...new Set(
  //   //     originalResearchers
  //   //       .map((p) => p.researcher_status)
  //   //       .filter((status): status is string => Boolean(status))
  //   //   ),
  //   // ].sort();

  //   // setResearchersStatuses(statuses);

  //   setResearchersStatuses(["active", "inactive", "blocked"]);

  //   // get all unique researchers Tags
  //   const pTags: string[] = [
  //     ...new Set(
  //       originalResearchers
  //         .map((p) => p.researcher_tags)
  //         .filter((tags): tags is string[] => Boolean(tags))
  //         .flat()
  //     ),
  //   ].sort();
  //   setResearchersTags(
  //     pTags.map((tag) => {
  //       return { id: tag, name: tag };
  //     })
  //   );
  // }, [originalResearchers]);

  useEffect(() => {
    // get all unique researchers languages
    const pLanguages = [
      ...new Set(
        originalResearchers
          .map((p) => p.language_iso)
          .filter((lang): lang is string => Boolean(lang))
      )
    ].sort();
    setResearchersLanguages(
      pLanguages.map((lang) => {
        const pLanguage = languages.find((l: Language) => l.iso_code === lang);
        if (pLanguage) {
          return pLanguage;
        }
        return { iso_code: lang, name: lang };
      })
    );
  }, [originalResearchers, languages]);

  useEffect(() => {
    // get all unique researchers countries
    const pCountries = [
      ...new Set(
        originalResearchers
          .map((p) => p.country_iso)
          .filter((country): country is string => Boolean(country))
      )
    ].sort();
    setResearchersCountries(
      pCountries.map((country) => {
        const pCountry = countries.find((c: Country) => c.iso_code === country);
        if (pCountry) {
          return pCountry;
        }
        return { iso_code: country, name: country, country_code: country };
      })
    );
  }, [originalResearchers, countries]);

  useEffect(() => {
    // get all unique researchers timezones
    const pTimezones = [
      ...new Set(
        originalResearchers
          .map((p) => p.timezone)
          .filter((timezone): timezone is string => Boolean(timezone))
      )
    ].sort();

    setResearchersTimezones(
      pTimezones.map((timezone) => {
        const pTimezone = timezones.find((t: Timezone) => t.name === timezone);
        if (pTimezone) {
          return pTimezone;
        }
        return { name: timezone, offset: 0 };
      })
    );
  }, [originalResearchers, timezones]);

  useEffect(() => {
    if (allRoles.length > 0) {
      // get all unique researchers Roles
      const pRoles = [
        ...new Set(
          originalResearchers
            .map((p) => p.role_id)
            .filter((role): role is string => Boolean(role))
        )
      ].sort();
      if (pRoles.length > 0) {
        setResearchersRoles(
          pRoles.map((role) => {
            const pRole = allRoles.find((r: ResearcherRole) => r.id === role);
            if (pRole) {
              return pRole;
            }
            return {
              name: role,
              id: role,
              permissions_id: []
            };
          })
        );
      } else {
        setResearchersRoles([]);
      }
    }
  }, [originalResearchers, allRoles]);

  function handleExportResearchers() {
    console.log("handleExportParticipants");
    exportResearchers(filteredResearchers, allRoles);
  }

  return (
    <div>
      {/* loading */}
      {!loadingLanguages &&
      !loadingCountries &&
      !loadingTimezones &&
      !loadingResearchers &&
      !errorFetchingData?.errorCode ? (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="participants_page" className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h1 className="table_name">{tableDisplayName}</h1>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-end">
                    <div className="participants_page_heading_right p-0">
                      <h5>{totalResearchers} Researchers</h5>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => handleExportResearchers()}
                      >
                        <Icons.IconUpload
                          className="icon icon_white"
                          style={{
                            transform: "rotate(180deg)"
                          }}
                        />
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-end participants_page_header_second">
                    {/* timezone buttons */}
                    <TimezoneSwitcher
                      timezone={timezone}
                      handleChangeTimezone={handleChangeTimezone}
                      researcherTimezone={
                        researcherTimezone ? researcherTimezone : null
                      }
                      tableType="researchersTable"
                    />
                    {/* select rows buttons */}
                    <TableRowsSelector
                      handleChangeRows={handleChangeRows}
                      rowsPerPage={researchersPerPage}
                      tableName="Researchers"
                      arrayRowSelector={[10, 20, 50, 100]}
                    />
                  </div>
                </div>
                <div className="row">
                  {/* FiltersContainer */}
                  <FiltersContainer
                    key="FiltersContainer"
                    tableType={tableType}
                    filterApplied={filterApplied}
                    setFilterApplied={setFilterApplied}
                    handleClearFilters={handleClearFilters}
                    jsonTagsData={researchersTags}
                    languages={researchersLanguages}
                    countries={researchersCountries}
                    statuses={researchersStatuses}
                    timezones={researchersTimezones}
                    roles={researchersRoles}
                    // searchedResearchersIDs
                    participantIDs={searchedMultiResearchersIDs}
                    setSearch={setResearchersSearch}
                    setTagsFilter={setResearchersTagsFilter}
                    setStatusFilter={setResearchersStatusFilter}
                    setLanguagesFilter={setResearchersLanguagesFilter}
                    setCountryFilter={setResearchersCountryFilter}
                    setUDIDFilter={setResearchersUDIDFilter}
                    setParticipantIDs={setSearchedMultiResearchersIDs}
                    setTimezoneFilter={setResearchersTimezonesFilter}
                    setRolesFilter={setResearchersRolesFilter}
                  />
                  <div className="col-auto">
                    {/* show if there are selected Researchers */}
                    {selectedResearchers && selectedResearchers.length > 0 && (
                      <BulkActions
                        selectedParticipants={selectedResearchers}
                        tableType={tableType}
                        allSelected={
                          selectedResearchers.length === totalResearchers
                        }
                      />
                    )}
                  </div>
                </div>
                {!originalResearchers || originalResearchers.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No researchers found</h3>
                      </div>
                    </div>
                  </div>
                ) : !filteredResearchers ||
                  (filteredResearchers && filteredResearchers.length === 0) ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No results found</h3>
                        <p>Try adjusting your search or filters</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ResearchersTable
                    currentResearchers={currentResearchers}
                    selectedResearchers={selectedResearchers}
                    filteredResearchersLength={
                      filteredResearchers ? filteredResearchers.length : 0
                    }
                    researcherID={selectedResearcher}
                    allTimezones={timezones}
                    timezone={timezone}
                    countries={countries}
                    languages={languages}
                    activeResearcherId={activeResearcherId}
                    researcherTimezone={researcherTimezone}
                    allClients={allClients}
                    allRoles={allRoles}
                    handleSelectResearcher={handleSelectResearcher}
                    handleSelectAllResearchers={handleSelectAllResearchers}
                    handleViewResearcher={handleViewResearcher}
                    handleResearcherActionsDropdown={
                      handleResearcherActionsDropdown
                    }
                    setSelectedRole={setSelectedRole}
                  />
                )}
                <div className="row">
                  <div className="col-auto">
                    <PaginationNavigation
                      currentPage={currentPage}
                      totalResults={
                        filteredResearchers ? filteredResearchers.length : 0
                      }
                      resultsPerPage={researchersPerPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                  {/* <div className="col-auto ms-auto">
                    {displayedDataDateOffet}
                    <button
                      id="refresh_button"
                      className="btn btn-primary ms-2"
                      onClick={() => handleRefreshData()}
                      disabled={!refreshButtonEnabled}
                    >
                      Refresh
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="participants_page" className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="error_container">
                      {loadingLanguages ||
                      loadingCountries ||
                      loadingTimezones ||
                      loadingResearchers ? (
                        <div className="d-flex justify-content-center custom_spinner_container">
                          <div className="spinner-border" role="status"></div>
                        </div>
                      ) : (
                        <ErrorPage
                          errorCode={"500"}
                          error={
                            errorFetchingData
                              ? errorFetchingData.errorMessage
                              : "Error fetching data"
                          }
                          debugCode={loadingErrorText}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedResearcher !== "" && (
        <ViewResearcherModal
          researcherID={selectedResearcher}
          closeResearcherModal={() => handleCloseModal()}
          shown={showModal}
          jsonLanguagesData={languages}
          jsonCountriesData={countries}
          jsonTimezonesData={timezones}
          tableType={tableType}
          allRoles={allRoles}
        />
      )}
      {selectedRole !== "" && (
        <ViewResearcherRoleModal
          roleID={selectedRole}
          closeResearcherRoleModal={() => setSelectedRole("")}
          shown={selectedRole !== ""}
          allRoles={allRoles}
        />
      )}
    </div>
  );
};

export default DisplayResearchers;
