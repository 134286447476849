// RulesFilter.tsx

import React, { useEffect, useRef, useState } from "react";
// import { Rules } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface RulesFilterProps {
  jsonRulesData: string[];
  onRulesFilterChange: (rules: string[]) => void;
  filtersCleared: boolean;
  location: string;
}

const RulesFilter: React.FC<RulesFilterProps> = ({
  jsonRulesData,
  onRulesFilterChange,
  filtersCleared,
  location
}) => {
  const [rulesFilter, setRulesFilter] = useState<string>("");
  const [rulesDropdown, setRulesDropdown] = useState(false);
  const [participantsRulesFilter, setParticipantsRulesFilter] = useState<
    Record<string, boolean>
  >({});

  const handleRulesDropdown = (show: boolean) => {
    // show/hide dropdown
    setRulesDropdown(show);
  };

  const handleRulesSearchFilter = (search: string) => {
    // set search value
    setRulesFilter(search);
  };

  const handleRulesFilter = (rule: string, checked: boolean) => {
    // set rules filter
    setParticipantsRulesFilter((prevRules) => ({
      ...prevRules,
      [rule]: checked // set checked status for this rule
    }));
  };

  useEffect(() => {
    // send rules filter to parent component
    onRulesFilterChange(
      Object.keys(participantsRulesFilter).filter(
        (rule) => participantsRulesFilter[rule] // filter rules that are true
      )
    );
  }, [participantsRulesFilter, onRulesFilterChange]);

  useEffect(() => {
    // clear rules filter when filters are cleared
    if (filtersCleared) {
      setRulesFilter("");
      setParticipantsRulesFilter({});
      handleRulesSearchFilter("");
      // handleRulesDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handleRulesDropdown(false);
  });

  return (
    <div className="dropdown dropdown_rules ms-2" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleRulesDropdown(!rulesDropdown)}
      >
        Rules
      </button>
      <div className={`dropdown-menu ${rulesDropdown ? "show" : "hide"}`}>
        <input
          type="text"
          placeholder="Search Rules"
          value={rulesFilter}
          onChange={(e) => handleRulesSearchFilter(e.target.value)}
        />
        {
          /* filter by rulesfilter set by input */
          /* loop through all rules */
          jsonRulesData.map((rule: string, index: number) => {
            if (rule.toLowerCase().includes(rulesFilter.toLowerCase())) {
              return (
                <div
                  key={`searchRules_${rule}_${index}`}
                  className="dropdown_menu_item"
                >
                  <label
                    htmlFor={`${location}_${rule}`}
                    className="form_control"
                  >
                    <input
                      type="checkbox"
                      id={`${location}_${rule}`}
                      name={rule}
                      value={rule}
                      checked={participantsRulesFilter[rule] || false} // use checked property here
                      onChange={(e) =>
                        handleRulesFilter(e.target.value, e.target.checked)
                      }
                    />
                    {rule}
                  </label>
                </div>
              );
            } else {
              return null;
            }
          })
        }
      </div>
    </div>
  );
};

export default RulesFilter;
