import { useEffect, useState } from "react";
import { Language } from "../../types";
import { getSystemConfigLanguages } from "../../utilities/config.util";
import "./languagesDisplay.scss";

const LanguagesDisplayPage = () => {
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  useEffect(() => {
    // fetch languages data on component mount

    const fetchTLCData = async () => {
      try {
        const jsonData = await getSystemConfigLanguages();
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching languages");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);

          setLanguages(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };

    const promises = [fetchTLCData()];
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingLanguages(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingLanguages(false);
        setLoadingError(true);
      });
  }, []);

  return (
    // if not loading
    !loadingLanguages ? (
      loadingError ? (
        <div id="language_table_page" className="container">
          <h1>Error loading data</h1>
        </div>
      ) : (
        <>
          <div id="language_table_page" className="container">
            <h1>Languages</h1>

            <div className="row">
              {languages.length === 0 ? (
                <p>No languages found in config. Please contact support</p>
              ) : (
                <>
                  {languages.map((language: Language, index: number) => (
                    <div
                      className="col-12 col-md-6 col-xl-4 row_item"
                      key={index}
                    >
                      <div className="row">
                        <div className="col-3 text-end">
                          {language.iso_code}
                        </div>
                        <div className="col-9">{language.name}</div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <br />
          </div>
        </>
      )
    ) : (
      // if loading
      <div className="d-flex justify-content-center custom_spinner_container full_page">
        <div className="spinner-border" role="status"></div>
      </div>
    )
  );
};

export default LanguagesDisplayPage;
