import React from "react";
import SurveyWSC from "./SurveyWSC/SurveyWSC.component";
// sccs
import "./studySurveyWSCHandler.scss";
export type StudySurveyWSCHandlerProps = {
  studyClicked: string;
};

const StudySurveyWSCHandler: React.FC<StudySurveyWSCHandlerProps> = ({
  studyClicked
}) => {
  return (
    <div className="wsc_study_survey_container">
      {studyClicked === "study" ? (
        <p>WSC not available on Study</p>
      ) : (
        <SurveyWSC />
      )}
    </div>
  );
};

export default StudySurveyWSCHandler;
