import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { read } from "xlsx";
import { createLeadImport } from "../../models/leads.model";
import { createParticipantImport } from "../../models/participant.model";
import { AcceptableHeaders, CreateParticipantSingle } from "../../types";
import { isEmpty } from "../../utilities/InputTesting.util";
import "./importParticipantsModal.scss";

interface ImportParticipantsModalProps {
  closeModal: () => void;
  shown: boolean;
  tableType: string;
}

type ValidationResult = {
  isValid: boolean;
  errors: string[];
  rowsWhichFailed: string[];
};

const ImportParticipantsModal: React.FC<ImportParticipantsModalProps> = ({
  closeModal,
  shown,
  tableType
}) => {
  //

  const { clientID, studyID } = useParams();
  const XLSX = require("xlsx");

  // File from the user
  const [importFileData, setImportFileData] = useState<any[]>([]);
  const [invalidRows, setInvalidRows] = useState<any[]>([]); // [row, row, row]
  const [error, setError] = useState("");
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  // File type
  const [fileType, setFileType] = useState<string>("");

  // Check if the file has been uploaded
  const [submitAllowed, setSubmitAllowed] = useState(false);

  // Errors states, used to display errors to the user
  const [errorsExist, setErrorsExist] = useState(false);
  const [displayErrors, setDisplayErrors] = useState<any[]>([]);
  const [hasUploaded, setHasUploaded] = useState(false);

  const [fileName, setFileName] = useState<string>("");

  // Temporary arrays to store valid and invalid rows
  const [validRows, setValidRows] = useState<any[]>([]); // Initialize validRows state
  const [newInvalidRows, setNewInvalidRows] = useState<any[]>([]);

  // Array of errors for invalid headers
  const [invalidHeaders, setInvalidHeaders] = useState<string[]>([]);

  // For loading spinner
  const [busySubmitting, setBusySubmitting] = useState(false);
  const [dots, setDots] = useState(".");

  useEffect(() => {
    // Update the dots every 500 milliseconds
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : "."));
    }, 500);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  // Received response from BE
  const [failureResponseFromBE, setFailureResponseFromBE] = useState(false);
  const [responseFromBE, setResponseFromBE] = useState(false);
  const [allParticipantsSentBE, setAllParticipantsSentBE] = useState(false);

  // States for storing the data from BE response
  const [invalidParticipantsBE, setInvalidParticipantsBE] = useState<any[]>([]);
  const [eventMessageBE, setEventMessageBE] = useState<string>("");

  useEffect(() => {
    // console.log(importFileData, "importFileData");
    // Other logic dependent on importFileData
    // Clear display errors
    // console.log(errorsExist, hasUploaded, error, invalidHeaders);
    // console.log(validRows, "validRows");
    handleValidateRow(); // Call your validation logic here or any other dependent logic
  }, [importFileData]);

  // Initialize on header type
  const acceptableHeaders: AcceptableHeaders = [
    "name",
    "surname",
    "mobile",
    "email",
    "country",
    "language",
    "referrer_id",
    "tags",
    "timezone",
    "errors"
  ];

  const obj = {
    name: "John",
    surname: "Doe",
    mobile: "123456789",
    email: "John@email.com",
    country: "ZA",
    language: "EN",
    referrer_id: "123456789",
    tags: "tag1, tag2",
    timezone: "UTC",
    errors: "error1, error2"
  };

  const clearFile = async () => {
    // console.log("clearFile");
    setDisplayErrors([]);
    setErrorsExist(false);
    setHasUploaded(false);
    setFileName("");
    setImportFileData([]);
    setInvalidRows([]);
    setSubmitAllowed(false);
    setError("");
    setFailureResponseFromBE(false);
    setResponseFromBE(false);
    setInvalidParticipantsBE([]);
    setEventMessageBE("");
    setFileType("");
    setValidRows([]);
    setAllParticipantsSentBE(false);
    setNewInvalidRows([]);
    setBusySubmitting(false);
  };

  const handleClose = () => {
    clearFile();
    closeModal();
  };

  const handleSetImportData = async (data: any[]) => {
    setImportFileData(data);
  };

  // Handles the file upload
  const handleOnDrop = async (acceptedFiles: File[]) => {
    await clearFile();
    const file = acceptedFiles[0];
    if (file) {
      // console.log(file, "file");
      const reader = new FileReader();
      // console.log("fileType", file.name);

      // Only allow csvs and xlsx files
      if (
        file.name.endsWith(".csv") ||
        file.name.endsWith(".xlsx") ||
        file.name.endsWith(".xls")
      ) {
        reader.onload = async (e) => {
          // ===================== CSV =====================
          if (file.name.endsWith(".csv")) {
            setFileType("csv");
            // console.log("CSV FILE==================");
            const csvText = e.target?.result as string;
            // console.log(csvText, "csvText");
            // Parse the CSV data using Papaparse
            setFileName(file.name);
            setHasUploaded(true);
            Papa.parse(csvText, {
              header: true, // Assumes the first row contains headers
              skipEmptyLines: true, // Skip empty lines in the CSV
              delimiter: ",",
              complete: async (result) => {
                // Access the parsed data here
                const data = result.data;
                // console.log(data, "data");
                await handleSetImportData(data);
                // console.log(importFileData, "importFileData");

                const headers = result.meta.fields;
                // console.log(headers, "headers");
                // if headers are missing, throw error, return
                if (headers) {
                  const regex = /_[1-9]|10/g;
                  const replacedHeaders = headers.map((item) =>
                    item.replace(regex, "")
                  );
                  // console.log(replacedHeaders);
                  if (!(await checkHeaders(replacedHeaders))) {
                    console.log("FAILED");
                    return false;
                  }
                }
              },
              error: (error: { message: string }) => {
                setError("Error parsing CSV: " + error.message);
              }
            });

            // ===================== XLSX || XLS =====================
          } else if (
            file.name.endsWith(".xlsx") ||
            file.name.endsWith(".xls")
          ) {
            if (file.name.endsWith(".xlsx")) {
              setFileType("xlsx");
            } else if (file.name.endsWith(".xls")) {
              setFileType("xls");
            }

            console.log("XLSX FILE==================");
            const fileContent = e.target?.result as ArrayBuffer;

            const workbook = read(fileContent, { type: "buffer" });
            setFileName(file.name);
            setHasUploaded(true);

            // Assuming you want the first sheet and you expect it to be named as 'Sheet1'
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const xlsxHeaders = XLSX.utils.sheet_to_json(worksheet, {
              header: 1
            })[0];

            if (xlsxHeaders) {
              if (!(await checkHeaders(xlsxHeaders))) {
                // setError("Invalid headers present: ");
                return false;
              }
            } else {
              setError("Headers are missing: ");
              return false;
            }

            // Parse the xlsx file to JSON
            let data = XLSX.utils.sheet_to_json(worksheet);

            // Fill in missing keys with empty strings
            data = data.map((row: { [key: string]: any }) => {
              xlsxHeaders.forEach((header: string) => {
                if (row[header] === undefined) {
                  row[header] = "";
                }
              });
              return row;
            });

            setImportFileData(data);
            // } else if (file.name.endsWith(".xls")) {}
          } else {
            console.log("???? FILE==================");
            setError("Invalid file type");
            console.log("Invalid file type");
          }
        };

        if (file.name.endsWith(".csv")) {
          reader.readAsText(file);
        } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
          reader.readAsArrayBuffer(file);
        }
      } else {
        console.log("???? FILE==================");
        setError("Invalid file type: Please upload a CSV or XLSX file");
      }
    }
  };

  // Checks for the correct headers
  const checkHeaders = async (headers: string[]): Promise<boolean> => {
    // console.log(headers, "headers");
    // console.log(AcceptableHeaders, "AcceptableHeaders");

    const headersLength = headers.length;
    const acceptableHeadersLength = acceptableHeaders.length;
    const acceptableHeadersLengthMinusErrors = acceptableHeaders.length - 1;
    console.log(headersLength !== acceptableHeadersLength);
    console.log(headersLength + 1 !== acceptableHeadersLength);
    if (
      headersLength !== acceptableHeadersLength &&
      headersLength + 1 !== acceptableHeadersLength
    ) {
      console.log("headersLength:", headersLength);
      console.log("acceptableHeadersLength:", acceptableHeadersLength);
      console.log(
        "acceptableHeadersLength:",
        acceptableHeadersLengthMinusErrors
      );
      console.log("Invalid number of headers present");
      console.log(fileType, "fileType");
      setError(`Invalid file: Please see the samples above`);
      return false;
    }

    // Check if headers are in the correct order
    const checkingHeaders = async () => {
      let newInvalidHeaders = [];
      for (let i = 0; i < headers.length; i++) {
        // console.log(headers.length);
        // console.log(i);
        // console.log("comparing: " + headers[i] + " and " + AcceptableHeaders[i]);
        // if header is not equal to acceptable header
        if (headers[i] !== acceptableHeaders[i]) {
          // if header is empty add to missing headers
          if (headers[i] === "") {
            console.log(
              "EMPTY HEADER FOUND",
              headers[i],
              "Comparing against: ",
              acceptableHeaders[i]
            );
            newInvalidHeaders.push(`empty header: ${acceptableHeaders[i]}`);
            console.log("INVALID HEADER ADDED: ", headers[i]);
          } else {
            // add the invalid header to the array
            console.log(
              "INVALID HEADER FOUND",
              headers[i],
              "Comparing against: ",
              acceptableHeaders[i]
            );
            newInvalidHeaders.push(`Invalid header: ${headers[i]}`);
          }
        }
      }
      // after checking all headers, set invalid headers
      setInvalidHeaders(newInvalidHeaders);

      // console.log("INVALID HEADERS: ", invalidHeaders);
      // if invalid headers are present, return false
      if (newInvalidHeaders.length > 0) {
        console.log("HIT");
        setError("Invalid headers present: ");
        return false;
      }
    };

    // Ensure check is done before proceeding
    await checkingHeaders();

    // No errors, return true
    return true;
  };

  // Checking all required fields are present and are in the correct format
  const isValid = (
    row: { [key: string]: any },
    rowTesting?: number
  ): ValidationResult => {
    const errors: string[] = [];
    const rowsWhichFailed: string[] = [];

    // if row has the correct amount of headers (10)
    if (
      Object.keys(row).length !== acceptableHeaders.length &&
      Object.keys(row).length + 1 !== acceptableHeaders.length
    ) {
      errors.push(`Invalid number of headers`);
      if (rowTesting) {
        rowsWhichFailed.push(rowTesting.toString());
      }
      return {
        isValid: false,
        errors,
        rowsWhichFailed: rowsWhichFailed
      };
    }

    for (const [key, value] of Object.entries(row)) {
      switch (key) {
        case "name":
        case "surname":
        case "timezone":
        case "country":
        case "language":
          // check if value is empty
          if (isEmpty(value)) {
            errors.push(`${key} is empty`);
            if (rowTesting) {
              rowsWhichFailed.push(rowTesting.toString());
            }
          }
          break;
        case "mobile":
          if (isEmpty(value)) {
            errors.push(`${key} is empty`);
            if (rowTesting) {
              rowsWhichFailed.push(rowTesting.toString());
            }
          }
          const mobilePattern = /^[1-9][0-9]{8,13}$/;
          if (!mobilePattern.test(value)) {
            errors.push(`${key} not a valid mobile number`);
            if (rowTesting) {
              rowsWhichFailed.push(rowTesting.toString());
            }
          }
          break;

        case "email":
          const emailPattern =
            /^(?!.*\.{2})[\p{L}0-9._-]+@[\p{L}0-9._-]+\.[a-zA-Z]{2,}$/u;
          if (!emailPattern.test(value)) {
            errors.push(`${key} not a valid email address`);
            if (rowTesting) {
              rowsWhichFailed.push(rowTesting.toString());
            }
          }
          break;
        default:
          break;
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
      rowsWhichFailed: rowsWhichFailed
    };
  };

  // Write invalid rows to a file and trigger download
  const writeInvalidRowsDownload = (invalidRows: any[]) => {
    const AcceptableHeaders: string[] = [
      "name",
      "surname",
      "mobile",
      "email",
      "country",
      "language",
      "referrer_id",
      "tags",
      "timezone",
      "errors"
    ];

    // Map the invalidRows array to conform to the AcceptableHeaders structure
    const formattedRows = invalidRows.map((row) => ({
      name: row.participant_name,
      surname: row.participant_surname,
      mobile: row.participant_mobile_number,
      email: row.participant_email,
      country: row.participant_country_iso,
      language: row.participant_lang_iso,
      referrer_id: row.participant_ref_code,
      tags: row.participant_tags,
      timezone: row.participant_timezone,
      errors: row.messages.join(", ")
    }));

    // if file type is csv
    if (fileType === "csv") {
      // Unparse the formattedRows array with the specified headers
      const csv = Papa.unparse({
        fields: AcceptableHeaders,
        data: formattedRows
      });

      // Create a blob from the CSV string
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      // Create an anchor tag to trigger the download
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "invalid_rows.csv");
      link.click();

      // if file type is xlsx
    } else if (fileType === "xlsx" || fileType === "xls") {
      // Create a workbook
      const wb = XLSX.utils.book_new();
      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(formattedRows, {
        header: AcceptableHeaders
      });
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Write the workbook to a file and trigger download
      XLSX.writeFile(wb, `invalid_rows.${fileType}`);
    }
  };

  // Handle submit, call to BE
  const handleSubmitParticipant = async () => {
    const currentWorkerID = localStorage.getItem("workerID");
    if (
      currentWorkerID !== null &&
      currentWorkerID !== undefined &&
      currentWorkerID !== ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    // console.log(validRows, "validRows");

    if (!clientID) {
      Swal.fire({
        icon: "error",
        title: "Client ID not found",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    const participantsToSend: CreateParticipantSingle[] = [];
    // Push valid rows to participantsToSend
    validRows.forEach((row) => {
      const createParticipant: CreateParticipantSingle = {
        participant_name: row.name,
        participant_surname: row.surname,
        participant_mobile_number: row.mobile,
        participant_email: row.email,
        participant_country_iso: row.country,
        participant_lang_iso: row.language,
        participant_timezone: row.timezone,
        participant_ref_code: row.referrer_id,
        participant_tags: row.tags
      };

      participantsToSend.push(createParticipant);
    });

    // console.log(createParticipantSend, "createParticipantSend");
    try {
      // Set busy submitting
      setBusySubmitting(true);
      let createParticipantResponseData;
      if (tableType === "ClientParticipants") {
        createParticipantResponseData = await createParticipantImport(
          participantsToSend,
          "client",
          clientID
        );
      } else if (tableType === "StudyParticipants") {
        createParticipantResponseData = await createParticipantImport(
          participantsToSend,
          "study",
          clientID,
          studyID
        );
      } else {
        console.log("Unknown tableType: ", tableType);
        Swal.fire({
          icon: "error",
          title: "Unknown tableType",
          confirmButtonColor: "#3085d6"
        });
        return;
      }

      // Set busy submitting
      setBusySubmitting(false);

      // console.log(createParticipantResponseData,"createParticipantResponseData");

      setEventMessageBE(createParticipantResponseData.rMessage);
      if (createParticipantResponseData.rStatus !== "success") {
        setFailureResponseFromBE(true);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: createParticipantResponseData.rMessage,
          confirmButtonColor: "#3085d6"
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: createParticipantResponseData.rMessage,
          confirmButtonColor: "#3085d6"
        });
        setFailureResponseFromBE(false);
      }

      // Check if response is valid
      // if (createParticipantResponseData) {
      setResponseFromBE(true);

      // export async function createParticipantImport(
      //   createParticipant: CreateParticipantSend
      // ): Promise<{
      //   rStatus: string;
      //   rMessage: string;
      // }> {

      // if (
      //   createParticipantResponseData.
      // )

      // Check if invalid participants exist
      // if (createParticipantResponseData.invalidParticipant.length > 0) {
      //   setFailureResponseFromBE(true);
      //   console.log(
      //     createParticipantResponseData.invalidParticipant.length,
      //     "createParticipantResponseData.invalidParticipants"
      //   );

      //   // Set invalid participants for displaying to user
      //   setInvalidParticipantsBE(
      //     createParticipantResponseData.invalidParticipant
      //   );
      //   console.log(invalidParticipantsBE, "invalidParticipantsBE");

      //   // Write invalid rows to a file and trigger download
      //   writeInvalidRowsDownload(
      //     createParticipantResponseData.invalidParticipant
      //   );
      //   console.log(invalidParticipantsBE, "invalidParticipantsBE");
      // }

      // // Set error message
      // if (createParticipantResponseData.message) {
      //   setEventMessageBE(createParticipantResponseData.message);
      //   console.log(eventMessageBE, "eventMessageBE");
      // }

      // // Set all participants sent to BE
      // if (createParticipantResponseData.success) {
      //   setAllParticipantsSentBE(true);
      // }

      // You can now use the createParticipantResponseData directly in your component
    } catch (error) {
      setBusySubmitting(false);
      setError("Error occurred: " + error);
      console.error("Error occurred in handleSubmit", error);
    }
  };

  const handleSubmitLead = async () => {
    const currentWorkerID = localStorage.getItem("workerID");
    if (
      currentWorkerID !== null &&
      currentWorkerID !== undefined &&
      currentWorkerID !== ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    const participants: CreateParticipantSingle[] = [];

    // Push valid rows to createParticipantSend
    validRows.forEach((row) => {
      const createParticipant: CreateParticipantSingle = {
        participant_name: row.name,
        participant_surname: row.surname,
        participant_mobile_number: row.mobile,
        participant_email: row.email,
        participant_country_iso: row.country,
        participant_lang_iso: row.language,
        participant_timezone: row.timezone,
        participant_ref_code: row.referrer_id,
        participant_tags: row.tags
      };

      participants.push(createParticipant);
    });

    // console.log(createParticipantSend, "createParticipantSend");
    try {
      // Set busy submitting
      setBusySubmitting(true);
      const createParticipantResponseData =
        await createLeadImport(participants);
      // Set busy submitting
      setBusySubmitting(false);

      // console.log(createParticipantResponseData,"createParticipantResponseData");

      setEventMessageBE(createParticipantResponseData.rMessage);
      if (createParticipantResponseData.rStatus !== "success") {
        setFailureResponseFromBE(true);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: createParticipantResponseData.rMessage,
          confirmButtonColor: "#3085d6"
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: createParticipantResponseData.rMessage,
          confirmButtonColor: "#3085d6"
        });
        setFailureResponseFromBE(false);
      }

      // Check if response is valid
      // if (createParticipantResponseData) {
      setResponseFromBE(true);
    } catch (error) {
      setBusySubmitting(false);
      setError("Error occurred: " + error);
      console.error("Error occurred in handleSubmit", error);
    }
  };

  // Validate each row, display errors
  const handleValidateRow = () => {
    // console.log("CALLED");
    let newValidRows = [...validRows]; // Create a copy of the current validRows state
    const emailSet = new Set(); // Use a Set to store unique emails and help in detecting duplicates
    importFileData.forEach((rowData, i) => {
      const { email } = rowData;
      const validation = isValid(rowData, i);

      // Check if email is duplicate
      if (emailSet.has(email)) {
        console.log("Duplicate email");
        validation.isValid = false;
        validation.errors.push(`Duplicate email: ${email}`);
      } else {
        emailSet.add(email); // Add the email to the Set if it’s not a duplicate
      }

      //Validation for tags, they are optional  so if they are defined, they must meet a regex and and character limitation
      if (tableType === "leadsTable") {
        //if leads table we ignore tags so we wont do validation and will show a pop up saying we are ignoring tags
        // Swal.fire({
        //   icon: "warning",
        //   title: "Tags detected in leads",
        //   text: "Tags will not be imported for leads. If you want tags to be imported with your participant, please import them in the client table",
        //   confirmButtonColor: "#3085d6",
        // });
      } else {
        let tagsRegex =
          /^[^(?!.*[\/\:\;'",\.<>@\!\$\%\^\&\`\*\)\(\}\{\]\[).+$]{4,60}$/;
        //This regex is for the tags, it allows for a comma separated list of tags, each tag must be between 4 and 60 characters long and can contain any character except for the following: / : ; ' " , . < > @ ! $ % ^ & ` * ) ( } { ] [
        if (rowData?.tags) {
          if (!tagsRegex.test(rowData.tags)) {
            validation.isValid = false;
            validation.errors.push(
              `Invalid tag: ${rowData.tags} - tags must be between 4 and 60 characters long and should not contain any special characters except for hashes and underscores`
            );
            console.log("Invalid tag", validation.errors);
          }
        }
      }

      if (validation.isValid) {
        if (rowData.language) {
          rowData.language = rowData.language.toUpperCase();
        }
        if (rowData.country) {
          rowData.country = rowData.country.toUpperCase();
        }
        newValidRows.push(rowData); // Push to the newValidRows array
      } else {
        newInvalidRows.push({ row: rowData, errors: validation.errors, i });
        console.log(newInvalidRows);
      }
    });

    if (tableType === "leadsTable") {
      // if there are any tags then fire a message to the user
      if (newValidRows.some((row) => row.tags !== "")) {
        Swal.fire({
          icon: "warning",
          title: "Tags detected",
          html: "We noticed you had tags in your import, please note they will be ignored.<br /> If you want tags to be imported with your participant,<br /> please import them in the client table",
          confirmButtonColor: "#3085d6"
        });
      }
    }

    setValidRows(newValidRows); // Update the state with the newValidRows array

    if (newInvalidRows.length > 0) {
      setErrorsExist(true);
      // Set the invalid rows (assuming setInvalidRows is defined)
      setInvalidRows(newInvalidRows);

      // Log the invalid rows and their errors
      newInvalidRows.forEach((invalidRow, i) => {
        // append to displayErrors
        setDisplayErrors((prevDisplayErrors) => [
          ...prevDisplayErrors,
          {
            row: invalidRow.i + 2,
            errors: invalidRow.errors.join(", ")
          }
        ]);
      });
    }
  };

  const handleCSVDownload = () => {
    const url = "/assets/data/sample_csv.csv";
    window.open(url, "_blank");
  };

  const handleExcelDownload = () => {
    const url = "/assets/data/sample_excel.xlsx";
    window.open(url, "_blank");
  };

  const handleOpenTimezonesTable = () => {
    const url = "/timezones";
    window.open(url, "_blank");
  };

  const handleOpenLanguagesTable = () => {
    const url = "/languages";
    window.open(url, "_blank");
  };

  const handleOpenCountriesTable = () => {
    const url = "/countries";
    window.open(url, "_blank");
  };

  return (
    <div
      className={`modal import_participants_modal ${shown ? "show" : "hide"}`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <h3 className="modal_page_header">Import Participant File</h3>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {/* TOP DETAILS */}
              <div className="row">
                <p>
                  This action will overwrite the current list. Existing
                  participants will be ignored. Please confirm that you are
                  uploading the correct file. Please see sample{" "}
                  <span
                    className="link clickable"
                    onClick={() => handleCSVDownload()}
                  >
                    CSV file
                  </span>{" "}
                  or sample{" "}
                  <span
                    className="link clickable"
                    onClick={() => handleExcelDownload()}
                  >
                    Excel file
                  </span>
                </p>
                <p>
                  Reference valid timezones can be found in this link{" "}
                  <span
                    className="link clickable"
                    onClick={() => handleOpenTimezonesTable()}
                  >
                    VALID TIMEZONES
                  </span>
                  <br />
                  Reference valid languages can be found in this link{" "}
                  <span
                    className="link clickable"
                    onClick={() => handleOpenLanguagesTable()}
                  >
                    VALID LANGUAGES
                  </span>
                  <br />
                  Reference valid counries can be found in this link{" "}
                  <span
                    className="link clickable"
                    onClick={() => handleOpenCountriesTable()}
                  >
                    VALID COUNTRIES
                  </span>
                </p>
                <p className="warning_text">
                  {tableType &&
                    tableType === "leadsTable" &&
                    "Please note tags will not be imported for leads. If you want tags to be imported with your participant, please import them in the client table"}
                </p>
              </div>

              {/* INPUT FOR FILES, SPINNER FOR SUBMITTING */}
              {busySubmitting ? (
                <>
                  <div className="spinner_holder">
                    <h5 className="mb-2">Uploading{dots}</h5>
                    {/* <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <Dropzone onDrop={handleOnDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <div>
                          {hasUploaded && (
                            <span
                              className="clear_file"
                              onClick={() => {
                                clearFile();
                              }}
                            >
                              ×
                            </span>
                          )}
                          <div className="drop_zone_upload" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {hasUploaded ? (
                              <p>{fileName}</p>
                            ) : (
                              <p>Drop file or click here to upload...</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </>
              )}

              {/* DISPLAYS SUCCESS AND FAILURE CASES */}
              {responseFromBE && (
                <>
                  {allParticipantsSentBE && (
                    <>
                      <h6>All participants imported successfully!</h6>
                    </>
                  )}
                  <span id="eventMessageBE">
                    <h4
                      className="error"
                      style={{
                        color: failureResponseFromBE ? "red" : "green"
                      }}
                    >
                      {eventMessageBE}
                    </h4>
                  </span>
                </>
              )}

              {/* DISPLAYS ERRORS */}
              {error && (
                <p className="error_red">
                  <>
                    <h5>
                      {error}
                      <span className="error_red">*</span>
                    </h5>
                    <span> {invalidHeaders.join(", ")}</span>
                  </>
                </p>
              )}

              {/* ERRORS CONTAINER */}
              <div className="row">
                {errorsExist && (
                  <div className="errors_container">
                    <div className="error_message_container">
                      <h4 className="row_number">
                        Required Fields<span className="error_red">*</span>
                      </h4>
                      <p>Please resolve the error(s) before submitting</p>
                    </div>

                    <div className="errors_display_container">
                      {displayErrors.map((error, i) => (
                        <div key={i}>
                          <p className="row_number">Row: {error.row}</p>
                          <ul>
                            {error.errors
                              .split(", ")
                              .map(
                                (
                                  errorMsg: string,
                                  j: React.Key | null | undefined
                                ) => (
                                  <li key={j}>{errorMsg}</li>
                                )
                              )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* RESPONSE FROM BE */}
              <div className="row">
                {failureResponseFromBE && (
                  <div className="errors_container">
                    {/* <div className="error_message_container">
                      <h4 className="row_number">
                        Errors<span className="error_red">*</span>
                      </h4>
                      <p>Please resolve the error(s) before submitting</p>
                    </div> */}

                    {/* <div className="errors_display_container">
                      {invalidParticipantsBE.map((participant, i) => (
                        <div key={i}>
                          <p className="row_number">
                            Row {i + 1}:{" "}
                            <span className="error_red">
                              {participant.participant_email}
                            </span>
                          </p>
                          <ul>
                            {participant.messages.map(
                              (errorMsg: string, j: number) => (
                                <li key={j}>{errorMsg}</li>
                              )
                            )}
                          </ul>
                        </div>
                      ))}
                    </div> */}
                  </div>
                )}
              </div>

              {/* BOTTOM BUTTONS */}
              <div className="row">
                <div className="buttons">
                  {tableType === "leadsTable" ? (
                    <button
                      className={`btn btn-primary ${
                        errorsExist ||
                        !hasUploaded ||
                        error ||
                        responseFromBE ||
                        busySubmitting
                          ? "disabled"
                          : ""
                      }`}
                      id={`import_${tableType}`}
                      onClick={handleSubmitLead}
                    >
                      Upload Leads
                    </button>
                  ) : tableType === "ClientParticipants" ? (
                    <button
                      className={`btn btn-primary ${
                        errorsExist ||
                        !hasUploaded ||
                        error ||
                        responseFromBE ||
                        busySubmitting
                          ? "disabled"
                          : ""
                      }`}
                      id={`import_${tableType}`}
                      onClick={handleSubmitParticipant}
                    >
                      Upload Participants
                    </button>
                  ) : tableType === "StudyParticipants" ? (
                    <button
                      className={`btn btn-primary ${
                        errorsExist ||
                        !hasUploaded ||
                        error ||
                        responseFromBE ||
                        busySubmitting
                          ? "disabled"
                          : ""
                      }`}
                      id={`import_${tableType}`}
                      onClick={handleSubmitParticipant}
                    >
                      Upload Participants
                    </button>
                  ) : (
                    <p>Unknown Table type: {tableType}</p>
                  )}

                  <button
                    className="btn btn-primary btn_cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportParticipantsModal;
