// rolesFilter.tsx

import React, { useEffect, useRef, useState } from "react";
import { ResearcherRole } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface RolesFilterProps {
  jsonRolesData: ResearcherRole[];
  onRoleFilterChange: (roles: string[]) => void;
  filtersCleared: boolean;
  location: string;
}

const RolesFilter: React.FC<RolesFilterProps> = ({
  jsonRolesData,
  onRoleFilterChange,
  filtersCleared,
  location
}) => {
  const [rolesFilter, setrolesFilter] = useState<string>("");
  const [rolesDropdown, setrolesDropdown] = useState(false);
  const [participantsrolesFilter, setParticipantsrolesFilter] = useState<
    Record<string, boolean>
  >({});

  const handlerolesDropdown = (show: boolean) => {
    // show/hide dropdown
    setrolesDropdown(show);
  };

  const handlerolesSearchFilter = (search: string) => {
    // set search value
    setrolesFilter(search);
  };

  const handlerolesFilter = (role: string, checked: boolean) => {
    // set roles filter
    setParticipantsrolesFilter((prevroles) => ({
      ...prevroles,
      [role]: checked // set checked status for this role
    }));
  };

  useEffect(() => {
    // send roles filter to parent component
    onRoleFilterChange(
      Object.keys(participantsrolesFilter).filter(
        (role) => participantsrolesFilter[role] // filter roles that are true
      )
    );
  }, [participantsrolesFilter, onRoleFilterChange]);

  useEffect(() => {
    // clear roles filter when filters are cleared
    if (filtersCleared) {
      setrolesFilter("");
      setParticipantsrolesFilter({});
      handlerolesSearchFilter("");
      // handlerolesDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handlerolesDropdown(false);
  });

  return (
    <div className="dropdown dropdown_roles ms-2" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handlerolesDropdown(!rolesDropdown)}
      >
        Roles
      </button>
      <div className={`dropdown-menu ${rolesDropdown ? "show" : "hide"}`}>
        {jsonRolesData && jsonRolesData.length === 0 ? (
          <div className="dropdown_menu_item">
            <span>No roles</span>
          </div>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search roles"
              value={rolesFilter}
              onChange={(e) => handlerolesSearchFilter(e.target.value)}
            />
            <div className="dropdown_menu_items">
              {
                /* filter by rolesfilter set by input */
                /* loop through all roles */
                jsonRolesData.map((role: ResearcherRole, index: number) => {
                  if (
                    role.name.toLowerCase().includes(rolesFilter.toLowerCase())
                  ) {
                    return (
                      <div key={index} className="dropdown_menu_item">
                        <label
                          htmlFor={`${location}_role_${role.id}`}
                          className="form_control"
                        >
                          <input
                            type="checkbox"
                            id={`${location}_role_${role.id}`}
                            name={role.name}
                            value={role.id}
                            checked={participantsrolesFilter[role.id] || false} // use checked property here
                            onChange={(e) =>
                              handlerolesFilter(
                                e.target.value,
                                e.target.checked
                              )
                            }
                          />
                          {`
                           ${role.name}`}
                        </label>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RolesFilter;
