import React from "react";
import { Earned } from "../../Rewards/Earned/earned.component";

export type RewardsProps = {
  studyClicked: string;
};

const Rewards: React.FC<RewardsProps> = () => {
  const [tab, setTab] = React.useState<string>("rewards");

  const handleTab = (tab: string) => {
    setTab(tab);
  };

  const renderTab = () => {
    switch (tab) {
      case "rewards":
        return <Earned source={"study"} />;
      case "redeem":
        return <div>redeem</div>;
      case "history":
        return <div>history</div>;
      default:
        return null;
    }
  };

  return (
    <div className="tab-container">
      <div className="tab-header">
        <div
          className={`tab-header-item ${tab === "rewards" ? "tab-header-active" : ""}`}
          onClick={() => handleTab("rewards")}
        >
          <h5 className="tab-title">Earned</h5>
        </div>
        {/* TODO Uncomment these when we finish */}
        {/* <div className={`tab-header-item ${tab === "edit" ? "tab-header-active" : ""}`} onClick={() => handleTab("edit")}>
          <h5 className="tab-title">Bulk Edit</h5>
        </div>
        <div className={`tab-header-item ${tab === "voucher" ? "tab-header-active" : ""}`} onClick={() => handleTab("voucher")}>
          <h5 className="tab-title">History</h5>
        </div> */}
      </div>
      <div className="tab-content">{renderTab()}</div>
    </div>
  );
};

export default Rewards;
