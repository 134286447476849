// StatusFilter.tsx

import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../utilities/utils";

interface BucketsComplianceFilterProps {
  onComplianceFilterChange: (bucketCompliance: string[]) => void;
  filtersCleared: boolean;
  bucketCompliance: string[];
}

const BucketsComplianceFilter: React.FC<BucketsComplianceFilterProps> = ({
  onComplianceFilterChange,
  filtersCleared,
  bucketCompliance
}) => {
  const [complianceDropdown, setComplianceDropdown] = useState(false);
  const [
    participantsBucketComplianceFilter,
    setParticipantsBucketComplianceFilter
  ] = useState<Record<string, boolean>>({});

  const handleComplianceDropdown = () => {
    // show/hide dropdown
    setComplianceDropdown(!complianceDropdown);
  };

  const handleBucketComplianceFilter = (
    compliance: string,
    checked: boolean
  ) => {
    // set status filter
    setParticipantsBucketComplianceFilter((prevStatus) => ({
      ...prevStatus,
      [compliance]: checked // set checked status for this tag
    }));
  };

  useEffect(() => {
    // send status filter to parent component
    onComplianceFilterChange(
      Object.keys(participantsBucketComplianceFilter).filter(
        (compliance) => participantsBucketComplianceFilter[compliance] // filter tags that are true
      )
    );
  }, [participantsBucketComplianceFilter, onComplianceFilterChange]);

  useEffect(() => {
    // clear status filter when filters are cleared
    if (filtersCleared) {
      setParticipantsBucketComplianceFilter({});
      // setStatusDropdown(false);
    }
  }, [filtersCleared]);

  useEffect(() => {
    const storedData = sessionStorage.getItem("filterData");
    if (storedData) {
      const filterData = JSON.parse(storedData);

      setParticipantsBucketComplianceFilter((prevBuckets) => ({
        ...prevBuckets,
        [filterData.complianceStatus]: true
      }));
    }
  }, []);

  // const ref = useRef<HTMLDivElement>(null);

  // useOutsideClick(ref, () => {
  //   // Action to take on outside click
  //   handleTagsDropdown(false);
  // });

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    setComplianceDropdown(false);
  });

  return (
    <div className="dropdown dropdown_bucket_compliance" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleComplianceDropdown()}
      >
        Bucket Compliance
      </button>
      <div className={`dropdown-menu ${complianceDropdown ? "show" : "hide"}`}>
        <div className="dropdown_menu_items">
          <div className="dropdown_menu_item">
            {bucketCompliance && bucketCompliance.length > 0 ? (
              bucketCompliance.map((compliance) => (
                <label className="form_control" key={compliance}>
                  <input
                    type="checkbox"
                    value={compliance}
                    checked={
                      participantsBucketComplianceFilter[compliance] || false
                    } // use checked property here
                    onChange={(e) =>
                      handleBucketComplianceFilter(
                        e.target.value,
                        e.target.checked
                      )
                    }
                  />
                  {compliance}
                </label>
              ))
            ) : (
              <span>No Bucket Compliance</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BucketsComplianceFilter;
