// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single_app_data .color-preview {
  border-radius: 25%;
  border: 1px solid #e5e5e5;
  align-content: center;
  cursor: pointer;
}
.single_app_data .color-preview svg {
  opacity: 0.1;
  width: 30px;
  height: 30px;
  padding: 6px 8px;
  fill: rgba(255, 255, 255, 0.8352941176);
  margin: auto;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.111));
  transition: opacity 0.2s;
}
.single_app_data .color-preview:hover svg {
  opacity: 1;
}
.single_app_data .app_field_image {
  width: 150px;
  height: 150px;
  cursor: zoom-in;
}`, "",{"version":3,"sources":["webpack://./src/components/Config/MobileApps/SingleAppConfig/SingleAppData/SingleAppData.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;AAAJ;AACI;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,uCAAA;EACA,YAAA;EACA,qDAAA;EACA,wBAAA;AACN;AAGM;EACE,UAAA;AADR;AAME;EACE,YAAA;EACA,aAAA;EACA,eAAA;AAJJ","sourcesContent":[".single_app_data {\n  .color-preview {\n    border-radius: 25%;\n    border: 1px solid #e5e5e5;\n    align-content: center;\n    cursor: pointer;\n    svg {\n      opacity: 0.1;\n      width: 30px;\n      height: 30px;\n      padding: 6px 8px;\n      fill: #ffffffd5;\n      margin: auto;\n      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.111));\n      transition: opacity 0.2s;\n    }\n\n    &:hover {\n      svg {\n        opacity: 1;\n      }\n    }\n  }\n\n  .app_field_image {\n    width: 150px;\n    height: 150px;\n    cursor: zoom-in;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
