import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconInvisible, IconVisible } from "../../assets/images/icons/icons";
import { resetMFA } from "../../models/auth.model";
import {
  arePasswordsValid,
  isEmailValid
} from "../../utilities/InputTesting.util";
import { encodeEnableMFA } from "../../utilities/encoding.util";
import { requestEnableMfa } from "../../utilities/mfaHandler.util";
import "./confirmDetailsModal.scss";

type ConfirmDetailsModal = {};

interface ConfirmDetailsModalProps {
  mfaEnabled: boolean;
  setMfaToggled: React.Dispatch<React.SetStateAction<boolean>>;
  setShowQrModal: React.Dispatch<React.SetStateAction<boolean>>;
  showQrModal: boolean;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmationModal: boolean;
}

const ConfirmDetailsModal: React.FC<ConfirmDetailsModalProps> = ({
  mfaEnabled,
  setMfaToggled,
  setShowQrModal,
  showQrModal,
  setShowConfirmationModal,
  showConfirmationModal
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [loading, setLoading] = useState(false);

  const handleClose = () => setShowConfirmationModal(false);

  const handleShow = () => {
    console.log("handleShow");
    setShowConfirmationModal(true);
  };

  const handleQrModal = async () => {
    setShowQrModal(true);
  };

  const setError = (message: string) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: message,
      confirmButtonColor: "#3085d6"
    });
  };

  const clearError = () => {
    setErrorMessage("");
  };

  // if inputs, clear error message
  useEffect(() => {
    clearError();
  }, [email]);

  // Checking inputs, if valid, encode, send to API
  const handleConfirmation = async () => {
    setLoading(true);
    // if email is different than regex, set error message and return
    try {
      const isEmailValidCheck = await isEmailValid(email);

      if (isEmailValidCheck === false) {
        setError("Please enter a valid email address.");
        return;
      }

      // Not matching passwords
      if (password !== passwordConfirmation) {
        setError("Passwords do not match.");
        return;
      }

      // if password is different than regex, set error message and return
      const arePasswordsValidCheck = await arePasswordsValid(
        password,
        passwordConfirmation
      );

      if (arePasswordsValidCheck === false) {
        setError("Please enter a valid password.");
        return;
      }

      // if all inputs are valid, encode and send to API
      const encodedMFAEnableString = await encodeEnableMFA(
        email,
        passwordConfirmation
      );

      console.log(encodedMFAEnableString, "encodedEP");

      // Checks + Encoding passed, check if researcher is enabling or resetting MFA
      if (encodedMFAEnableString) {
        // Researcher is enabling MFA
        if (mfaEnabled === false) {
          const resetMFAResponse = await requestEnableMfa(
            encodedMFAEnableString
          );
          console.log(resetMFAResponse);

          if (typeof resetMFAResponse === "string") {
            setError(resetMFAResponse.toString());
          } else {
            setMfaToggled(true);
            console.log("MFA Enabled");
            await handleQrModal();
          }
        }
        // Researcher is resetting MFA
        else if (mfaEnabled === true) {
          const requestingEnableMfa = await resetMFA(encodedMFAEnableString);
          console.log(requestingEnableMfa, "requestingEnableMfa");
          if (requestingEnableMfa === "success") {
            Swal.fire({
              icon: "success",
              title: "Success",
              html: "MFA has been disabled. <br> You will now only be able to use Magic Link to log in.",
              confirmButtonColor: "#3085d6"
            });
            setMfaToggled(true);
            handleClose();
            return;
          } else if (requestingEnableMfa === "invalid") {
            Swal.fire({
              icon: "error",
              title: "Invalid Credentials",
              text: "Please ensure that you have entered the correct details",
              confirmButtonColor: "#3085d6"
            });
          } else if (requestingEnableMfa === "error") {
            Swal.fire({
              icon: "error",
              title: "Error Disabling MFA",
              text: "Please log out and log back in to try again",
              confirmButtonColor: "#3085d6"
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error Fetching Researcher Data",
            text: "Please log out and log back in to try again",
            confirmButtonColor: "#3085d6"
          });
        }
      }
    } finally {
      setLoading(false);
    }

    clearError();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(e.target.value);
  };

  return (
    <>
      <div className="confirm_modal_container">
        {/* MODAL FOR Confirm MFA */}
        {showConfirmationModal && (
          <div
            className={`modal ${
              showConfirmationModal ? "show_modal" : ""
            } modal_container`}
            tabIndex={-1}
          >
            <div className={`modal-dialog modal-dialog-centered `}>
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="2faLabel">
                    Please confirm your details
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* EMAIL */}
                  <div className="mb-3">
                    <label
                      className="form-label mb-1 mb-1 mx-1"
                      htmlFor="form-stacked-text"
                    >
                      Email:
                    </label>
                    <input
                      className="form-control login_input"
                      type="text"
                      // defaultValue={user.email}
                      onChange={(e) => handleEmailChange(e)}
                      placeholder="Email"
                    />
                  </div>
                  {/* Password */}
                  <div className="mb-3 password_container">
                    <label
                      className="form-label mb-1 mb-1 mx-1"
                      htmlFor="form-stacked-text"
                    >
                      Password:
                    </label>
                    <div className="password_container">
                      <input
                        className="form-control login_input"
                        type={showPassword ? "text" : "password"}
                        // defaultValue={user.encrypted_password}
                        onChange={(e) => handlePasswordChange(e)}
                        placeholder="Password"
                      />
                      <button
                        type="button"
                        className="btn_password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <IconVisible className="icon icon_black" />
                        ) : (
                          <IconInvisible className="icon icon_black" />
                        )}
                      </button>
                    </div>
                  </div>
                  {/* Password Confirmation*/}
                  <div className="mb-3">
                    <label
                      className="form-label mb-1 mb-1 mx-1"
                      htmlFor="form-stacked-text"
                    >
                      Password Confirmation:
                    </label>
                    <div className="password_container">
                      <input
                        className="form-control login_input"
                        type={showPasswordConfirm ? "text" : "password"}
                        // value={password}
                        onChange={(e) => handlePasswordConfirmationChange(e)}
                        placeholder="Password Confirmation"
                      />
                      <button
                        type="button"
                        className="btn_password"
                        onClick={() =>
                          setShowPasswordConfirm(!showPasswordConfirm)
                        }
                      >
                        {showPasswordConfirm ? (
                          <IconVisible className="icon icon_black" />
                        ) : (
                          <IconInvisible className="icon icon_black" />
                        )}
                      </button>
                    </div>
                  </div>
                  <span
                    className={`ms-2 text-danger ${
                      errorMessage ? "d-inline" : "d-none"
                    }`}
                  >
                    {errorMessage}
                  </span>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn_cancel"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn_save"
                    onClick={() => handleConfirmation()}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Confirm"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConfirmDetailsModal;
