import React, { useEffect, useRef, useState } from "react";
import { Language } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";
import InformationTooltip from "../../InputInformation/InfoInputs.component";

interface FieldDropdownLanguagesProps {
  onInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  tooltip?: string;
  label: string;
  inputName: string;
  subLabel?: string;
  selectedLanguages: string[];
  allLanguages: Language[];
  error?: string | null;
  tooltipPosition?: "left" | "right" | "bottom" | "top";
}

// selectedLanguages is an array of language iso codes

const FieldDropdownLanguages: React.FC<FieldDropdownLanguagesProps> = ({
  onInputChange,
  label,
  tooltip,
  inputName,
  subLabel,
  selectedLanguages,
  allLanguages,
  error,
  tooltipPosition
}) => {
  const [languagesFilter, setlanguagesFilter] = useState<string>("");
  const [languagesDropdown, setlanguagesDropdown] = useState(false);
  const [numOfSelectedLanguages, setNumOfSelectedLanguages] =
    useState<number>(0);

  const handlelanguagesSearchFilter = (search: string) => {
    // set search value
    setlanguagesFilter(search);
  };

  // console.log("selectedLanguages", selectedLanguages);
  // console.log("allLanguages", allLanguages);
  useEffect(() => {
    // get number of selected languages
    setNumOfSelectedLanguages(selectedLanguages ? selectedLanguages.length : 0);
  }, [selectedLanguages]);

  const handlelanguagesDropdown = (show: boolean) => {
    // show/hide dropdown
    setlanguagesDropdown(show);
  };
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handlelanguagesDropdown(false);
  });

  return (
    <>
      <div className="mb-3 row">
        <div className="col-12 col-lg-4">
          <label htmlFor={inputName} className="form-label mb-1 mx-1">
            {tooltip && (
              <InformationTooltip
                marginTop="-12px"
                position={tooltipPosition ? tooltipPosition : "top"}
                tooltipText={tooltip}
              />
            )}
            {label}
            {subLabel && <span className="sub_label">{subLabel}</span>}
          </label>
        </div>
        <div className="col-12 col-lg-8">
          <div className="languages_selector_container" ref={ref}>
            <div className="languages_selector_selected">
              {error && <div className="wizard_input_error">{error}</div>}
              {numOfSelectedLanguages} selected
            </div>
            <button
              className="btn btn-primary dropdown-toggle"
              onClick={() => handlelanguagesDropdown(!languagesDropdown)}
            >
              Add Language
            </button>
            <div
              className={`dropdown-menu ${languagesDropdown ? "show" : "hide"}`}
            >
              <input
                type="text"
                className="form-control"
                id="Language Search"
                placeholder="Search languages"
                value={languagesFilter}
                onChange={(e) => handlelanguagesSearchFilter(e.target.value)}
              />

              {/* map though filterd languages and display a checkbox for each */}
              <div className="checkbox-list">
                {allLanguages
                  .filter(
                    (language) =>
                      language.name
                        .toLowerCase()
                        .includes(languagesFilter.toLowerCase()) ||
                      language.iso_code
                        .toLowerCase()
                        .includes(languagesFilter.toLowerCase())
                  )
                  .map((language) => (
                    <label
                      key={`${language.iso_code}_${inputName}`}
                      htmlFor={`${language.iso_code}_${inputName}`}
                      className="form_control"
                    >
                      <input
                        type="checkbox"
                        id={`${language.iso_code}_${inputName}`}
                        name={language.iso_code}
                        value={language.iso_code}
                        checked={selectedLanguages.includes(language.iso_code)}
                        onChange={(e) => {
                          onInputChange(inputName, e);

                          console.log(inputName, e.target.value);
                        }}
                      />
                      {language.iso_code} - {language.name}
                    </label>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FieldDropdownLanguages;
