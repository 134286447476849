import React, { useEffect, useRef, useState } from "react";
import "./dropdown.scss";

interface MultipleDropdownProps {
  placeholder: string;
  options: string[];
  selectedOptions: string[];
  onOptionToggled: (option: string) => void;
}

const MultipleDropdown: React.FC<MultipleDropdownProps> = ({
  placeholder,
  options,
  selectedOptions,
  onOptionToggled
}) => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="multi-dropdown-btn-container" ref={dropdownRef}>
      <button
        className="btn btn_filter dropdown-toggle"
        onClick={() => setShow((prev) => !prev)}
      >
        {placeholder}
      </button>
      {show && (
        <div className="generic-dropdown-menu">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="dropdown-search-input"
          />
          <div className="generic-dropdown-menu-items">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className={`generic-dropdown-menu-item ${
                    selectedOptions.includes(option) ? "selected" : ""
                  }`}
                  onClick={() => {
                    onOptionToggled(option);
                    //setSearchTerm(''); // Optionally clear the search field after an option is toggled
                  }}
                >
                  <span className="tick-box">
                    {selectedOptions.includes(option) && (
                      <span className="tick">✔</span>
                    )}
                  </span>
                  <span className="option-text">{option}</span>
                </div>
              ))
            ) : (
              <div className="generic-dropdown-menu-item">
                No options available
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleDropdown;
