import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAllMobileApps } from "../../../models/appsConfig.model";
import { fetchAllClientsNames } from "../../../models/client.model";
import { Client, ConfigMobileApp } from "../../../types";
import { capitalize } from "../../../utilities/utils";
import "./mobileApps.scss";

const ConfigMobileApps: React.FC = () => {
  const [mobileApps, setMobileApps] = useState<ConfigMobileApp[]>([]);
  const [loadingApps, setLoadingApps] = useState<boolean>(true);
  const [errorsFetchingData, setErrorsFetchingData] = useState<string[]>([]);
  const [allClients, setAllClients] = useState<Client[]>([]);
  const [totalApps, setTotalApps] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigate = useNavigate();

  const [filteredMobileApps, setFilteredMobileApps] = useState<
    ConfigMobileApp[]
  >([]); // Set initial state to null

  // fetch all mobile apps
  useEffect(() => {
    setLoadingApps(true);
    setErrorsFetchingData([]);
    const fetchMobileApps = async () => {
      try {
        const jsonData = await fetchAllMobileApps();
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          console.error("jsonData is a string");
          console.error("jsonData", jsonData);
          setErrorsFetchingData((prevErrors) => [...prevErrors, jsonData]);
        } else {
          //   console.log("jsonData is an object");
          //   console.log("jsonData", jsonData);
          setMobileApps(jsonData);
          //   setFilteredMobileApps(jsonData);
          setTotalApps(jsonData.length);
        }
      } catch (error) {
        console.error("An error occurred while fetching mobile apps:", error);
        setErrorsFetchingData((prevErrors) => [...prevErrors, error as string]);
      }
    };

    const fetchAllClientNames = async () => {
      try {
        const jsonData = await fetchAllClientsNames();
        if (!jsonData) {
          setErrorsFetchingData((prevErrors) => [
            ...prevErrors,
            "Failed to fetch all clients"
          ]);
        } else {
          setAllClients(jsonData);
        }
      } catch {
        console.error("Error fetching clients");
        setErrorsFetchingData((prevErrors) => [
          ...prevErrors,
          "Failed to fetch all clients"
        ]);
      }
    };

    const promises = [fetchMobileApps(), fetchAllClientNames()];

    Promise.all(promises).then(() => {
      // All promises completed successfully
      setLoadingApps(false);
    });
  }, []);

  function handleSearchTermChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  useEffect(() => {
    console.log(mobileApps);
    if (searchTerm !== "") {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const results = mobileApps.filter((mobileApp) => {
        // Check by name and UAI
        if (
          mobileApp.app_name.toLowerCase().includes(lowerCaseSearchTerm) ||
          mobileApp.unique_app_id.toLowerCase().includes(lowerCaseSearchTerm)
        ) {
          return true;
        }

        // Check by client name
        const client = allClients.find(
          (client) => client.id === mobileApp.client_id
        );

        if (client && client.name.toLowerCase().includes(lowerCaseSearchTerm)) {
          return true;
        }

        return false;
      });

      console.log("results", results);

      setFilteredMobileApps(results);
    } else {
      setFilteredMobileApps(mobileApps);
    }
  }, [searchTerm, mobileApps, allClients]);

  function handleViewAppClick(appID: string) {
    navigate("/config/" + appID);
  }

  function handleCopyFromClick(appID: string) {
    console.log("handleCopyFromClick");
    navigate("/config/new/" + appID);
  }

  function handleCreateNewAppClick() {
    navigate("/config/new");
  }

  return (
    <div>
      {/* loading */}
      {loadingApps ? (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="mobile_apps_page" className="container-fluid">
                <div className="d-flex justify-content-center custom_spinner_container full_page">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : // errors fetching data
      errorsFetchingData.length > 0 ? (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="mobile_apps_page" className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h1 className="table_name">Errors fetching data</h1>
                    {errorsFetchingData.map((error) => (
                      <p key={error}>{error}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // no errors
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="mobile_apps_page" className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h1 className="table_name">Mobile Apps</h1>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-end">
                    <div className="mobile_apps_page_heading_right p-0">
                      <h5>{totalApps} Apps</h5>
                      {/* search */}
                      <input
                        type="text"
                        className="form-control search_box"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                      />
                      <button
                        className="btn btn-primary ms-2"
                        onClick={handleCreateNewAppClick}
                      >
                        Add App
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* table */}
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="cust_table table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Unique App ID</th>
                            <th scope="col">Client</th>
                            <th scope="col">Status</th>
                            <th scope="col">Copy From</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredMobileApps.map((mobileApp) => {
                            // get all clientName from the mobileApp.clientID

                            const client = allClients.find(
                              (client) => client.id === mobileApp.client_id
                            );
                            const clientName = client
                              ? client.name
                              : "No client";

                            return (
                              <tr key={mobileApp.unique_app_id}>
                                <td>{mobileApp.app_name}</td>
                                <td>
                                  <span
                                    className="view_app_link"
                                    onClick={() =>
                                      handleViewAppClick(
                                        mobileApp.unique_app_id
                                      )
                                    }
                                  >
                                    {mobileApp.unique_app_id}
                                  </span>
                                </td>
                                <td>{clientName}</td>
                                <td>{capitalize(mobileApp.status)}</td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      handleCopyFromClick(
                                        mobileApp.unique_app_id
                                      )
                                    }
                                  >
                                    Copy from
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigMobileApps;
