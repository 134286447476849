import React, { useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { IconEyeDropper } from "../../../../../../assets/images/icons/icons";
import { ConfigMobileAppStyles } from "../../../../../../types";

type ColorPickerProps = {
  settingName: keyof ConfigMobileAppStyles;
  fieldID: string;
  fieldKey: string;
  fieldValue: string;
  handleChange: (
    settingName: keyof ConfigMobileAppStyles,
    id: string,
    dbField: string,
    value: string
  ) => void;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  settingName,
  fieldID,
  fieldKey,
  fieldValue,
  handleChange
}) => {
  const [color, setColor] = useState(fieldValue);
  const [showColorPicker, setShowColorPicker] = useState(false);

  function onUnFocus() {
    setShowColorPicker(false);
    handleChange(settingName, fieldID, fieldKey, color);
  }

  function cancel() {
    setColor(fieldValue);
    setShowColorPicker(false);
  }

  return (
    <div className="position-relative">
      <div className="d-flex flex-row align-content-center">
        <div
          className="color-preview me-2"
          style={{
            backgroundColor: fieldValue
          }}
          onClick={() =>
            showColorPicker ? cancel() : setShowColorPicker(true)
          }
        >
          <IconEyeDropper />
        </div>
      </div>
      {/* popup for color input */}
      {showColorPicker && (
        <div className="color-picker position-absolute z-3 card p-2">
          <HexColorPicker color={color} onChange={setColor} />
          <HexColorInput color={color} onChange={setColor} />
          <div className="d-flex mt-2 justify-content-between">
            <button className="btn btn-primary" onClick={cancel}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={onUnFocus}>
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
