import { useEffect, useState } from "react";
import * as Icons from "../../../assets/images/icons/icons.ts";
import {
  Country,
  Language,
  Participant,
  Timezone,
  ParticipantBucket
} from "../../../types.ts";
import { convertTime, formatDate } from "../../../utilities/utils.ts";
import ParticipantActions from "../ParticipantActions/participantAction.tsx";

import { usePermissions } from "../../../contexts/UserContext.tsx";
import Participants from "../../StudySurveyTabSingle/Participants/participants.component.tsx";
import { debug } from "console";

interface ParticipantsTableProps {
  tableType: string;
  currentParticipants: Participant[];
  selectedParticipants: string[];
  filteredParticipants: Participant[];
  participantID: string;
  allTimezones: Timezone[];
  timezone: string;
  countries: Country[];
  languages: Language[];
  activeParticipantId: string | null;
  researcherTimezone: Timezone | null;
  complianceStatus: string[];
  handleSelectParticipant: (participantID: string) => void;
  handleSelectAllParticipants: () => void;
  handleViewParticipant: (participantID: string) => void;
  handleParticipantActionsDropdown: (participantID: string) => void;
  surveyID: string | undefined;
}

export const ParticipantsTable = ({
  tableType,
  currentParticipants,
  selectedParticipants,
  filteredParticipants,
  participantID,
  timezone,
  allTimezones,
  countries,
  languages,
  activeParticipantId,
  researcherTimezone,
  complianceStatus = [],
  handleSelectParticipant,
  handleSelectAllParticipants,
  handleViewParticipant,
  handleParticipantActionsDropdown,
  surveyID
}: ParticipantsTableProps) => {
  // Store fields to be displayed in the table
  const [fields, setFields] = useState<string[]>([]);

  const { hasPermission } = usePermissions();

  useEffect(() => {
    // based on the passed though tableType, set the fields to be displayed
    switch (tableType) {
      case "ClientParticipants":
        setFields([
          "Checkbox",
          "Name",
          "User ID",
          "Mobile",
          "Email",
          "Country",
          "Language",
          "Referrer Code",
          // Disabled 'Apps' Column
          // "Apps",
          "UDID",
          "Last Login",
          "Status",
          "Flag",
          "Settings"
        ]);
        break;
      case "StudyParticipants":
        setFields([
          "Checkbox",
          "User ID",
          "Name",
          "Mobile",
          "Email",
          "Country",
          "Language",
          "Days in Study",
          "Joined Study",
          "Last Login",
          "Bucket",
          ...(complianceStatus.length > 0 ? ["Bucket Compliance"] : []),
          // Disabled App Code
          // "App Code",
          "App Status",
          "Study Status",
          "Flag",
          "Settings"
        ]);
        break;
      case "SurveyParticipants":
        setFields([
          "Checkbox",
          "User ID",
          "Name",
          "Mobile",
          "Email",
          "Country",
          "Language",
          "Bucket",
          "Day in Survey",
          "Completes",
          "Joined Survey",
          "Survey Status",
          "Flag",
          "Settings"
        ]);
        break;
      case "DiaryParticipants":
        setFields([
          "Checkbox",
          "User ID",
          "Name",
          "Mobile",
          "Email",
          "Country",
          "Language",
          "Bucket",
          "Day in Survey",
          "Completes",
          "Joined Survey",
          "Survey Status",
          "Flag",
          "Settings"
        ]);
        break;
      default:
        setFields(["Unknown Table Type"]);
    }
  }, [tableType, complianceStatus]);

  // Store the row ID of the participant whose buckets are expanded
  const [expandedBucketRowID, setExpandedBucketRowID] = useState<string | null>(
    null
  );
  // Store the row ID of the participant whose buckets compliance statuses are expanded
  const [expandedComplianceRowID, setExpandedComplianceRowID] = useState<
    string | null
  >(null);

  const toggleBucketView = (participantID: string) => {
    if (expandedBucketRowID === participantID) {
      setExpandedBucketRowID(null);
    } else {
      setExpandedBucketRowID(participantID);
    }
  };

  const toggleComplianceView = (id: string) => {
    setExpandedComplianceRowID(expandedComplianceRowID === id ? null : id);
  };

  return (
    <div className="table-responsive">
      <table className="cust_table table">
        <thead>
          <tr>
            {fields.map((field: string, index: number) => {
              switch (field) {
                case "Checkbox":
                  return (
                    //Added extraClassTest to the className
                    <th key={index} className="column_sticky extraClassTest">
                      <input
                        type="checkbox"
                        checked={
                          selectedParticipants.length ===
                          filteredParticipants.length
                        }
                        className="clickable"
                        onChange={handleSelectAllParticipants}
                      />
                    </th>
                  );
                case "Flag":
                  return (
                    <th key={index} className="td_icon">
                      <Icons.IconFlag className="icon icon_flag" />
                    </th>
                  );
                case "Name":
                  return (
                    <th key={index} className="column_sticky">
                      {field}
                    </th>
                  );
                case "Settings":
                  if (
                    hasPermission("subject") ||
                    hasPermission("participant", "all") ||
                    hasPermission("participant", "write") ||
                    hasPermission("participant", "delete")
                  ) {
                    return <th key={index}> </th>;
                  }
                  break;

                default:
                  return <th key={index}>{field}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          {currentParticipants.map(
            (participant: Participant, index: number) => {
              // console.log("participant", participant);
              let timezoneOffset = 0;
              // get participant's timezone offset based off of their timezone, filter though allTimezones
              const participant_offset = allTimezones.find(
                (timezone) => timezone.name === participant.participant_timezone
              )?.offset;
              if (timezone === "Participant's Timezone" && participant_offset) {
                timezoneOffset = Number(participant_offset);
              } else if (timezone === "UTC") {
                timezoneOffset = 0;
              } else if (timezone === "ResearcherTimezone") {
                timezoneOffset = researcherTimezone
                  ? researcherTimezone.offset
                  : 0;
              }
              return (
                <tr
                  key={participant.id.toString()}
                  className={`${
                    selectedParticipants.includes(participant.id.toString()) &&
                    "row_participant_selected"
                  }${
                    participantID === participant.id.toString() &&
                    " row_participant_viewing"
                  }`}
                >
                  {fields.map((field) => {
                    let filteredBuckets: ParticipantBucket[] = [];

                    if (fields.includes("Bucket")) {
                      const buckets = participant.participant_buckets;

                      // START OF OLD - Kept incase below change needs to be undone / modified later
                      // CHANGE Made - keep participants even if they have empty buckets array
                      // // Handle case with no buckets
                      // if (!buckets || buckets.length === 0) {
                      //   return <td key={field}>N/A</td>;
                      // }

                      // // Determine if tableType requires filtering buckets by survey_id
                      // const shouldFilterBuckets =
                      //   tableType === "SurveyParticipants" ||
                      //   tableType === "DiaryParticipants";

                      // // Filter buckets based on survey_id if necessary
                      // filteredBuckets = shouldFilterBuckets
                      //   ? buckets.filter(
                      //       (bucket) =>
                      //         bucket.survey_id === surveyID || !bucket.survey_id
                      //     )
                      //   : buckets;
                      // END OF OLD CODE

                      // START OF NEW FOR ABOVE
                      if (buckets) {
                        // Determine if tableType requires filtering buckets by survey_id
                        const shouldFilterBuckets =
                          tableType === "SurveyParticipants" ||
                          tableType === "DiaryParticipants";

                        // Filter buckets based on survey_id if necessary
                        filteredBuckets = shouldFilterBuckets
                          ? buckets.filter(
                              (bucket) =>
                                bucket.survey_id === surveyID ||
                                !bucket.survey_id
                            )
                          : buckets;
                      }
                      // END OF NEW
                    }

                    switch (field) {
                      case "Checkbox":
                        return (
                          <td
                            key={field}
                            className="column_sticky extraClassTest"
                          >
                            <input
                              type="checkbox"
                              className="checkbox clickable"
                              checked={selectedParticipants.includes(
                                participant.id.toString()
                              )}
                              onChange={() =>
                                handleSelectParticipant(
                                  participant.id.toString()
                                )
                              }
                            />
                          </td>
                        );
                      case "Name":
                        const fullName =
                          participant.participant_name +
                          " " +
                          participant.participant_surname;
                        const name = fullName.trim() !== "" ? fullName : "N/A";
                        return (
                          <td key={field} className="column_sticky">
                            <span
                              className="participant_name clickable"
                              onClick={() =>
                                handleViewParticipant(participant.id.toString())
                              }
                            >
                              {name}
                            </span>
                          </td>
                        );
                      case "User ID":
                        const participantID =
                          participant.id.toString() || "N/A";
                        return <td key={field}>{participantID}</td>;
                      case "Mobile":
                        const mobile =
                          participant.participant_mobile_number || "N/A";
                        return <td key={field}>{mobile}</td>;
                      case "Email":
                        const email = participant.participant_email || "N/A";
                        return <td key={field}>{email}</td>;
                      case "Day in Survey":
                        const dayInSurvey = participant.survey_days || "N/A";

                        return <td key={field}>{dayInSurvey}</td>;
                      case "Completes":
                        const completes: number | string =
                          participant.completes || "N/A";
                        return <td key={field}>{completes}</td>;
                      case "Joined Survey":
                        const joinedSurvey = participant.survey_joined_date
                          ? formatDate(
                              convertTime(
                                participant.survey_joined_date,
                                timezoneOffset
                              )
                            )
                          : "N/A";
                        return <td key={field}>{joinedSurvey}</td>;
                      case "Survey Status":
                        const surveyStatus = participant.survey_status || "N/A";
                        return <td key={field}>{surveyStatus}</td>;
                      case "Referrer Code":
                        const referrerCode =
                          participant.participant_ref_code || "N/A";
                        return <td key={field}>{referrerCode}</td>;
                      // Disabled 'Apps' Field
                      // case "Apps":
                      //   const apps = participant.participant_app_code || "N/A";
                      //   return <td key={field}>{apps}</td>;
                      case "UDID":
                        const uid = participant.participant_uid || "N/A";
                        return <td key={field}>{uid}</td>;
                      case "Last Login":
                        const lastLogin = participant.participant_last_login
                          ? formatDate(
                              convertTime(
                                participant.participant_last_login,
                                timezoneOffset
                              )
                            )
                          : "N/A";
                        return <td key={field}>{lastLogin}</td>;
                      case "Status":
                        const status = participant.participant_status || "N/A";
                        return (
                          <td key={field}>
                            {status === "Active" ? (
                              <span className="status_active">{status}</span>
                            ) : (
                              <span className="status_inactive">{status}</span>
                            )}
                          </td>
                        );
                      case "Country":
                        const country =
                          countries.find(
                            (country) =>
                              country.iso_code ===
                              participant.participant_country_iso
                          )?.name || "N/A";
                        return <td key={field}>{country}</td>;
                      case "Language":
                        const language =
                          languages.find(
                            (language) =>
                              language.iso_code ===
                              participant.participant_lang_iso
                          )?.name || "N/A";
                        return <td key={field}>{language}</td>;
                      case "Flag":
                        return (
                          <td key={field} className="td_icon">
                            {participant.participant_flagged ? (
                              <Icons.IconFlag className="icon icon_flag" />
                            ) : null}
                          </td>
                        );
                      case "Settings":
                        if (
                          hasPermission("subject") ||
                          hasPermission("participant", "all") ||
                          hasPermission("participant", "write") ||
                          hasPermission("participant", "delete")
                        ) {
                          return (
                            <td key={field}>
                              <button
                                className="button btn btn_settings"
                                onClick={() =>
                                  handleParticipantActionsDropdown(
                                    participant.id.toString()
                                  )
                                }
                                disabled={
                                  activeParticipantId ===
                                  participant.id.toString()
                                }
                              >
                                <Icons.IconSettings className="icon" />
                              </button>
                              {activeParticipantId ===
                                participant.id.toString() && (
                                <ParticipantActions
                                  participantID={participant.id.toString()}
                                  closeDropdown={() =>
                                    handleParticipantActionsDropdown("close")
                                  }
                                  outsideDropdown={() =>
                                    handleParticipantActionsDropdown("outside")
                                  }
                                  shown={
                                    activeParticipantId ===
                                    participant.id.toString()
                                  }
                                  tableType={tableType}
                                  participantStatus={
                                    tableType === "ClientParticipants"
                                      ? participant.participant_status
                                      : tableType === "StudyParticipants"
                                        ? participant.study_status
                                        : tableType === "SurveyParticipants"
                                          ? participant.survey_status
                                          : tableType === "DiaryParticipants"
                                            ? participant.survey_status
                                            : "N/A"
                                  }
                                  participantFlagged={
                                    participant.participant_flagged
                                  }
                                />
                              )}
                            </td>
                          );
                        }
                        break;
                      case "Days in Study":
                        const daysInStudy = participant.study_days || "N/A";
                        return <td key={field}>{daysInStudy}</td>;

                      case "Joined Study":
                        const joinedStudy = participant.study_joined_date
                          ? formatDate(
                              convertTime(
                                participant.study_joined_date,
                                timezoneOffset
                              )
                            )
                          : "N/A";
                        return <td key={field}>{joinedStudy}</td>;
                      case "Bucket": {
                        // console.log("filteredBuckets", filteredBuckets);

                        // Render single bucket name directly if only one matches criteria
                        if (filteredBuckets && filteredBuckets.length === 1) {
                          return (
                            <td key={field}>
                              {`${
                                filteredBuckets[0].survey_name
                                  ? filteredBuckets[0].survey_name + " - "
                                  : ""
                              }
                                ${filteredBuckets[0].name}
                              `}
                            </td>
                          );
                        }

                        if (filteredBuckets.length === 0) {
                          return <td key={field}>None</td>;
                        }

                        // Render expand button or list of buckets based on filtered count
                        return (
                          <td key={field}>
                            <button
                              className="btn_show_buckets clickable"
                              onClick={() => toggleBucketView(participant.id)}
                            >
                              {expandedBucketRowID === participant.id
                                ? "Hide Buckets"
                                : "Show Buckets"}
                            </button>

                            {expandedBucketRowID === participant.id && (
                              <div>
                                {filteredBuckets.map((bucket, index) => (
                                  <div key={index} className="bucket">
                                    {`${
                                      bucket.survey_name
                                        ? bucket.survey_name +
                                          " - " +
                                          bucket.name
                                        : bucket.name
                                    }`}
                                  </div>
                                ))}
                              </div>
                            )}
                          </td>
                        );
                      }

                      case "Bucket Compliance": {
                        if (complianceStatus.length > 0) {
                          // Render compliance status directly if only one matches criteria
                          if (filteredBuckets && filteredBuckets.length === 1) {
                            return (
                              <td key={field}>
                                {`${
                                  filteredBuckets[0].compliance_status &&
                                  filteredBuckets[0].name
                                    ? [
                                        "non compliant",
                                        "compliant",
                                        "completes"
                                      ].includes(filteredBuckets[0].name)
                                      ? "N/A"
                                      : filteredBuckets[0].compliance_status
                                    : ""
                                }
                                `}
                              </td>
                            );
                          }

                          if (filteredBuckets.length === 0) {
                            return <td key={field}>N/A</td>;
                          }

                          // Render expand button or list of compliances based on filtered count
                          return (
                            <td key={field}>
                              <button
                                className="btn_show_buckets clickable"
                                onClick={() =>
                                  toggleComplianceView(participant.id)
                                }
                              >
                                {expandedComplianceRowID === participant.id
                                  ? "Hide Compliance"
                                  : "Show Compliance"}
                              </button>

                              {expandedComplianceRowID === participant.id && (
                                <div>
                                  {filteredBuckets.map((bucket, index) => (
                                    <div key={index} className="bucket">
                                      {bucket.compliance_status && bucket.name
                                        ? [
                                            "compliant",
                                            "non compliant",
                                            "completes"
                                          ].includes(bucket.name.toLowerCase())
                                          ? "N/A"
                                          : bucket.compliance_status
                                        : ""}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </td>
                          );
                        } else {
                          return null;
                        }
                      }
                      // Disabled App Code
                      // case "App Code":
                      //   const appCode =
                      //     participant.participant_app_code || "N/A";
                      //   return <td key={field}>{appCode}</td>;
                      case "App Status":
                        const appStatus =
                          participant.participant_app_status || "N/A";
                        return <td key={field}>{appStatus}</td>;
                      case "Study Status":
                        const studyStatus = participant.study_status || "N/A";
                        return <td key={field}>{studyStatus}</td>;

                      default:
                        return (
                          // If field is not found, display error
                          <td key={field}>
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold"
                              }}
                            >
                              Error
                            </span>
                          </td>
                        );
                    }
                    return null;
                  })}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};
