import React, { ChangeEvent, useEffect, useState } from "react";
import { IconEmail } from "../../assets/images/icons/icons";
import { requestEmail } from "../../models/auth.model";
import { isEmailValid } from "../../utilities/InputTesting.util";
import { encodeEmailMl } from "../../utilities/encoding.util";

export type MagicLinkProps = {
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  magicLinkSent: boolean;
  setMagicLinkSent: React.Dispatch<React.SetStateAction<boolean>>;
};

const MagicLink: React.FC<MagicLinkProps> = ({
  handleEmailChange,
  email,
  setEmail,
  magicLinkSent,
  setMagicLinkSent
}) => {
  // const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // typescript boolean usestate
  const [busySubmitting, setBusySubmitting] = useState(false);

  const [loginCount, setLoginCount] = useState(0);
  const [loginCoolDown, setLoginCoolDown] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const setError = (message: string) => {
    setErrorMessage(message);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (loginCount === 3) {
      // Start with 10 seconds countdown
      setCountdown(10);
      setLoginCoolDown(true);
    } else if (loginCount > 3) {
      // Start with 60 seconds countdown
      setCountdown(60);
      setLoginCoolDown(true);
    } else {
      setLoginCoolDown(false);
    }
  }, [loginCount]);

  useEffect(() => {
    let timer: any;
    if (countdown > 0) {
      // Decrement countdown every second
      timer = setInterval(() => {
        setCountdown((c) => c - 1);
      }, 1000);
    } else {
      setLoginCoolDown(false);
    }

    // Cleanup function to clear the interval
    return () => clearInterval(timer);
  }, [countdown]);

  const handleLogin = async () => {
    // console.log("handleLogin");
    if (busySubmitting) return;
    // if email is different than regex, set error message and return
    const isEmailValidCheck = await isEmailValid(email);

    if (isEmailValidCheck === false) {
      setError("Please enter a valid email address.");
      return;
    }

    setBusySubmitting(true);

    // Encode the email and make the request
    const encodedEmail = await encodeEmailMl(email);
    if (encodedEmail) {
      const emailResponse = await requestEmail(encodedEmail);

      if (emailResponse === 202) {
        setMagicLinkSent(true);
        clearError();
      } else if (emailResponse === 400) {
        console.log("bad request");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("400 please contact support.");
      } else if (emailResponse === 404) {
        console.log("login not found");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("Invalid email.");
      } else if (emailResponse === 0) {
        console.log("network error");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("Network error. Please try again.");
      } else {
        console.log("login failed");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("Server error. Please try again.");
      }

      setLoginCount((prevCount) => prevCount + 1);
    }

    if ("caches" in window) {
      // console.log(caches)
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    // Unregister the service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }
  };

  // const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
  //     setEmail(e.target.value);
  // };

  // Submit if enter key is pressed
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !loginCoolDown) {
      handleLogin();
    }
  };

  // This will handle the back button click after magicLink submit button
  function handleBack() {
    setBusySubmitting(false);
    setMagicLinkSent(false);
  }

  return (
    <>
      {!magicLinkSent ? (
        <>
          {" "}
          <div className="mb-3 d-flex align-items-center justify-content-center">
            {/* <label className="form-label mb-0" htmlFor="form-stacked-text">
                Email:
                </label> */}
            <input
              className="form-control login_input"
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-3"></div>
          <div className="mb-3 text-center">
            <button
              className="btn login_btn"
              onClick={handleLogin}
              disabled={busySubmitting || loginCoolDown}
              style={loginCoolDown ? { fontSize: "0.8rem" } : {}}
            >
              {busySubmitting
                ? "Sending..."
                : loginCoolDown
                  ? `${countdown}s`
                  : "Submit"}
            </button>
            <span
              className={`ms-2 text-danger ${
                errorMessage ? "d-inline" : "d-none"
              }`}
            >
              {errorMessage}
            </span>
          </div>
        </>
      ) : (
        <div className="email_sent_message">
          <div className="d-flex justify-content-center login-input-section container_labels">
            <h4 className="font-size-sent ">Your link has been sent!</h4>
            <label className="font-size-check ">Please check your email.</label>
            {/* new line element */}
          </div>
          <div className=" d-flex justify-content-center icon_email">
            <IconEmail />
          </div>

          <div className=" d-flex justify-content-center flex-column">
            <button
              className="btn btn-primary login_btn back_button"
              onClick={handleBack}
            >
              Back
            </button>

            <div className="d-flex justify-content-center login-input-section container_labels">
              <label className="mt-2 subtext">
                If you did not receive the email, please try again.
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MagicLink;
