import React, { useEffect, useState } from "react";
import "./automationRules.scss";

export type AutomationRulesSingleProps = {
  isSelected?: boolean;
  ruleID: string;
  handleSelectSingleAutomation: (ruleID: string) => void;
  ruleSelection: string;
  handleCheckboxChange: (ruleId: string) => void;
  selectedRules: string[];
};

const AutomationRuleSingle: React.FC<AutomationRulesSingleProps> = ({
  isSelected,
  ruleID,
  handleSelectSingleAutomation,
  ruleSelection,
  handleCheckboxChange,
  selectedRules
}) => {
  useEffect(() => {
    console.log("selectedRules:", ruleSelection);
    console.log("selectedRules:", ruleID);
  }, [selectedRules, ruleID]);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        key={ruleID}
        className={`single_role_container ${
          isSelected || isHovered ? "container_selected" : ""
        }`}
        // on hover display right side
        onClick={() => {
          handleSelectSingleAutomation(ruleID);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={`roles hovered`}>
          <div className="role_single_checkbox">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={() => {
                handleCheckboxChange(ruleID);
                console.log(ruleID);
              }}
              checked={selectedRules.includes(ruleID)}
            />
          </div>
          <div className="role_title">{ruleSelection}</div>
        </div>
      </div>
    </>
  );
};

export default AutomationRuleSingle;
