import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconBroom, IconLogout } from "../../../../assets/images/icons/icons";
import {
  RecipientsList,
  ResendCommunication,
  fetchRecipients
} from "../../../../models/messages.model";
import { ParamsSurveyPage } from "../../../../types";
import MultipleDropdown from "../../../Dropdown/multipleDropdown.component";
import PaginationNavigation from "../../../Tables/PaginationNavigation/paginationNavigation";
import "./history.scss";

interface RecipientHistoryProps {
  communicationID: string;
  phraseLibraryID: string;
  hideRecipientHistory: () => void;
}
const RecipientHistory: React.FC<RecipientHistoryProps> = ({
  communicationID,
  phraseLibraryID,
  hideRecipientHistory
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<RecipientsList | undefined>();
  const [filterData, setFilterData] = useState<RecipientsList | undefined>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const options = ["Pending", "Sent", "Failed", "Delivered"];
  const { clientID, studyID } = useParams<ParamsSurveyPage>();
  //Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  /*----------------------------------------------------*/
  /*------------------Loading data----------------------*/
  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchRecipients(
        clientID!,
        studyID!,
        communicationID!
      );
      if (typeof response !== "string") {
        setData(response);
      } else setError("No History Data");
    } catch (error) {
      setError("Error loading data");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    loadData();
  }, [communicationID]);
  //Filter data
  useEffect(() => {
    if (data) {
      let filteredData = data;
      //Filter by status
      if (selectedType.length > 0) {
        filteredData = filteredData.filter((item) =>
          selectedType.some((type) =>
            item.status.toLowerCase().includes(type.toLowerCase())
          )
        );
      }
      //Search by name
      if (searchInput.length > 0) {
        filteredData = filteredData.filter((item) =>
          item.recipientsFullName
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      }
      //Search by Country
      // if (searchInput.length > 0) {
      //   filteredData = filteredData.filter(item => item.country.toLowerCase().includes(searchInput.toLowerCase()));
      // }

      setFilterData(filteredData);
    }
  }, [data, selectedType, searchInput]);

  const currentItems = filterData?.slice(indexOfFirstItem, indexOfLastItem);
  /*----------------------------------------------------*/
  /*------------------Functions-------------------------*/
  const handleTypeChange = (option: string) => {
    setSelectedType((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };
  const handleClearFilters = () => {
    setSearchInput("");
    setSelectedType([]);
  };
  const ResendMessage = async (participantId: string) => {
    try {
      const workerID = localStorage.getItem("workerID");
      console.log(workerID);
      if (workerID && typeof workerID === "string") {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
      } else {
        const response = await ResendCommunication(
          clientID!,
          studyID!,
          communicationID!,
          [participantId]
        );
        if (typeof response !== "string") {
          Swal.fire({
            icon: "success",
            title: "Communication has been queued",
            text: `The communication has been queued and will be sent shortly.`,
            confirmButtonColor: "#3085d6"
          });
        } else {
          setError(response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `An error occurred while sending the communication.${response}`,
            confirmButtonColor: "#3085d6"
          });
        }
      }
    } catch (error) {
      setError("Error loading data");
    }
  };
  /*----------------------------------------------------*/

  return (
    <>
      <div className="history-title-container">
        <button
          className="btn btn-primary btn-exit"
          onClick={hideRecipientHistory}
        >
          <IconLogout className="icon icon_white" />
        </button>
        <h5 className="history-title">Recipient History</h5>
      </div>
      <div className="history-info-container">
        <div className="history-filter">
          <div className="history-filter-dropdown">
            <MultipleDropdown
              placeholder="Status"
              options={options}
              onOptionToggled={handleTypeChange}
              selectedOptions={selectedType}
            />
          </div>
          <div className="history-filter-search-container">
            <input
              className="search-input"
              type="text"
              id="search"
              name="search"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary btn-clear"
              disabled={false}
              onClick={handleClearFilters}
            >
              <IconBroom className="icon icon_white" />
            </button>
          </div>
        </div>
        <div className="table-responsive ">
          <table className="table table-container">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Recipient Name</th>
                <th scope="col">Country</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.userID}</td>
                    <td>{item.recipientsFullName}</td>
                    <td>{item.country}</td>
                    <td>{item.status.toUpperCase()}</td>
                    <td className="text-end">
                      <button
                        className="btn btn-primary btn-view"
                        onClick={() => ResendMessage(item.userID)}
                      >
                        Resend
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>No Recipients Found</td>
                </tr>
              )}
            </tbody>
          </table>
          <PaginationNavigation
            currentPage={currentPage}
            totalResults={filterData?.length || 0}
            resultsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default RecipientHistory;
