import React from "react";
import { Client } from "../../types.ts";
import "./displayClients.scss";
interface ClientInfoProps {
  client: Client;
}

const ClientInfo: React.FC<ClientInfoProps> = ({ client }) => {
  // console.log("client", client);
  return (
    <div id="client_info_container" className="col">
      <h2>{client.name}</h2>
      <p className="mb-2">{client.description}</p>
      {/*
      // TODO: add buttons back in when we have the pages
      <button className="btn btn-primary" disabled={true}>
        <Icons.IconSettings className="icon icon_white" />
        Settings
      </button>
      <button className="btn btn-primary ms-2" disabled={true}>
        <Icons.IconMobile className="icon icon_white" /> App Settings
      </button> */}
    </div>
  );
};

export default ClientInfo;
