// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags_header_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: inherit;
  outline: 0.5px solid #cecece;
  padding: 0;
  overflow-y: hidden;
}
.tags_header_container .tags_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #7c7c7c;
  font-weight: 600;
  border-bottom: 2px solid rgba(31, 143, 199, 0.4431372549);
  padding: 5px 10px;
}
.tags_header_container .tags_header .headers_group {
  display: flex;
  width: 100%;
  justify-content: start;
}
.tags_header_container .tags_header .headers_group .underline::before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #1f8ec7;
}
.tags_header_container .tags_header .headers_group .header_item {
  flex-basis: 17%;
  position: relative;
}
.tags_header_container .tags_header .headers_group .header_item .header_title {
  margin-bottom: 0;
  text-align: left;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}
.tags_header_container .content_container {
  width: 100%;
}
.tags_header_container .content_container .content_row {
  width: 100%;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/StudySurveyTabSingle/Tags/tags.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,4BAAA;EACA,UAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,yDAAA;EACA,iBAAA;AACJ;AACI;EACE,aAAA;EACA,WAAA;EACA,sBAAA;AACN;AACM;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,yBAAA;AACR;AAEM;EACE,eAAA;EACA,kBAAA;AAAR;AAEQ;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;EAAA,kBAAA;AAAV;AAME;EACE,WAAA;AAJJ;AAMI;EACE,WAAA;EACA,aAAA;AAJN","sourcesContent":[".tags_header_container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  height: 100%;\n  min-height: inherit;\n  outline: 0.5px solid #cecece;\n  padding: 0;\n  overflow-y: hidden;\n\n  .tags_header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    font-size: 14px;\n    color: #7c7c7c;\n    font-weight: 600;\n    border-bottom: 2px solid rgba(31, 143, 199, 0.4431372549);\n    padding: 5px 10px;\n\n    .headers_group {\n      display: flex;\n      width: 100%;\n      justify-content: start;\n\n      .underline::before {\n        content: \"\";\n        position: absolute;\n        bottom: -6px;\n        left: 0;\n        right: 0;\n        height: 2px;\n        background-color: #1f8ec7;\n      }\n\n      .header_item {\n        flex-basis: 17%;\n        position: relative;\n\n        .header_title {\n          margin-bottom: 0;\n          text-align: left;\n          position: relative;\n          width: fit-content;\n        }\n      }\n    }\n  }\n\n  .content_container {\n    width: 100%;\n\n    .content_row {\n      width: 100%;\n      display: flex;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
