import React from "react";
import { useNavigate } from "react-router-dom";
import { SidebarData } from "../../types.ts";

interface SidebarLevelTwoProps {
  sidebarDisplayData: SidebarData[];
  currentClientID: string;
  showLevel: boolean;
  handleShowLevelLowerLevel: (ID: string) => void;
}

const SidebarLevelTwo: React.FC<SidebarLevelTwoProps> = ({
  sidebarDisplayData,
  showLevel,
  currentClientID,
  handleShowLevelLowerLevel
}) => {
  const navigate = useNavigate();

  function handleClientIconClick(ID: string) {
    console.log("handleClientIconClick");
    navigate(`/clients/${ID}`);
  }

  return (
    <div
      id="sidebarLevelTwo"
      className={`sidebarLevel ${showLevel ? "show" : "hide"}`}
    >
      <div className="sidebar_buttons">
        {/* map though all sidebarDisplayData and display all clientNames */}
        {sidebarDisplayData.map((client: SidebarData) => (
          <button
            key={client.clientID}
            className={`sidebar_button clickable button_client_logo${
              currentClientID === client.clientID ? " selected" : ""
            }`}
            onClick={() =>
              handleClientIconClick(client.clientID ? client.clientID : "")
            }
            //   hover handleShowLevelLowerLevel(ID)
            onMouseEnter={() =>
              handleShowLevelLowerLevel(client.clientID ? client.clientID : "")
            }
            data-toggle="tooltip"
            title={client.clientName ? client.clientName : ""}
            style={{
              backgroundImage: `url(${
                client.clientPicture
                  ? client.clientPicture
                  : "/images/placeholders/no-image.png"
              })`
            }}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default SidebarLevelTwo;
