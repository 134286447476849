import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { ReportData } from "../types";

function isReport(obj: any): boolean {
  const validID = typeof obj.id === "string";
  const validFileName = typeof obj.fileName === "string";
  const validClient = typeof obj.client === "string";
  const validType = typeof obj.type === "string";
  const validCreated = typeof obj.created === "string";
  const validModified = typeof obj.modified === "string";
  const validURL = typeof obj.url === "string";
  const validDownloadURL = typeof obj.downloadUrl === "string";

  const AllValid =
    validID &&
    validFileName &&
    validClient &&
    validType &&
    validCreated &&
    validModified &&
    validURL;

  if (!AllValid) {
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("Missing ID");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Report ID`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push("Invalid Type for ID");
      }
    }

    if (!validFileName) {
      if (!obj.hasOwnProperty("fileName")) {
        errors.push("Missing File Name");
      } else {
        errors.push("Invalid Type for File Name");
      }
    }

    if (!validClient) {
      if (!obj.hasOwnProperty("client")) {
        errors.push("Missing Client");
      } else {
        errors.push("Invalid Type for Client");
      }
    }

    if (!validType) {
      if (!obj.hasOwnProperty("type")) {
        errors.push("Missing Type");
      } else {
        errors.push("Invalid Type for Type");
      }
    }

    if (!validCreated) {
      console.log(typeof obj.created);
      console.log(obj.created);
      if (!obj.hasOwnProperty("created")) {
        errors.push("Missing Created");
      } else {
        errors.push("Invalid Type for Created");
      }
    }

    if (!validModified) {
      if (!obj.hasOwnProperty("modified")) {
        errors.push("Missing Modified");
      } else {
        errors.push("Invalid Type for Modified");
      }
    }

    if (!validURL) {
      if (!obj.hasOwnProperty("url")) {
        errors.push("Missing URL");
      } else {
        errors.push("Invalid Type for URL");
      }
    }

    if (!validDownloadURL) {
      if (!obj.hasOwnProperty("downloadUrl")) {
        errors.push("Missing Download URL");
      } else {
        errors.push("Invalid Type for Download URL");
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `Report Name: ${obj.fileName}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Report`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}

export async function getAllReports(
  fetchAll: boolean
): Promise<ReportData[] | string> {
  const response = await apiFetcher<any>("/getAllReports", "POST", {
    body: { monthly: fetchAll ? undefined : "true" }
  });
  console.log("Reports: ", response);
  if (response.status === 200 && response.data) {
    // There are reports
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isReport)
    ) {
      console.log(response.data);
      return response.data;
    } else {
      console.log("Invalid Data Received");
      return "Invalid Data Received";
    }
  } else if (response.status === 404) {
    console.log("No reports found");
    return [];
  } else if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Server Error`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Server Error";
  } else {
    return "Server Error";
  }
}

export async function getClientReports(
  clientID: string,
  fetchAll: boolean
): Promise<ReportData[] | string> {
  const response = await apiFetcher<any>("/getClientReports", "POST", {
    body: {
      clientID: clientID,
      monthly: fetchAll ? undefined : "true"
    }
  });
  console.log(response);
  if (response.status == 200 && response.data) {
    // There are reports
    console.log(response.data);
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isReport)
    ) {
      return response.data;
    } else {
      return "Invalid Data Received";
    }
  } else if (response.status === 404) {
    console.log("No reports found");
    return [];
  } else if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Server Error`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Server Error";
  } else {
    return "Server Error";
  }
}
