import React, { useEffect, useState } from "react";
import { IconDownload } from "../../assets/images/icons/icons";
import "./reports.scss";

import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchAllClientsNames } from "../../models/client.model";
import { getAllReports, getClientReports } from "../../models/report.model";
import { Client, ReportData } from "../../types";
import { convertIDToName } from "../../utilities/automation.util";
import { formatDate } from "../../utilities/utils";
import FiltersContainer from "../Tables/Filters/filtersContainer.component";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation";
import TableRowsSelector from "../Tables/TableRowsSelector/tableRowsSelector";

export type SimpleData = {
  id: string;
  name: string;
};

type ReportsProps = {
  currentClientName: string | null; // TODO: add props here
};

const Reports: React.FC<ReportsProps> = ({ currentClientName }) => {
  const [originalReports, setOriginalReports] = useState<ReportData[]>([]);
  const [filteredReports, setFilteredReports] = useState<ReportData[]>([]);
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const [message, setMessage] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [reportsSearch, setReportsSearch] = useState("");

  const { clientID } = useParams<string>();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [reportsPerPage, setReportsPerPage] = useState(20);
  const [currentReports, setCurrentReports] = useState<ReportData[]>([]);

  const [clients, setClients] = useState<string[]>([]);
  const [reportTypes, setReportTypes] = useState<string[]>([]);
  const [filterClients, setFilterClients] = useState<string[]>([]);
  const [filterReportTypes, setFilterReportTypes] = useState<string[]>([]);

  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");

  // Fetching data
  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [allClients, setAllClients] = useState<SimpleData[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showFetchAllReports, setShowFetchAllReports] =
    useState<boolean>(false);

  const handleChangeRows = (rows: number) => {
    setReportsPerPage(rows);
  };

  // Checkbox change
  const handleCheckboxChange = (id: string) => {
    if (selectedReports.includes(id)) {
      setSelectedReports(selectedReports.filter((item) => item !== id));
    } else {
      const report = filteredReports.find((report) => report.id === id);
      if (report) {
        setSelectedReports([...selectedReports, id]);
      }
    }
  };

  // Select all checkbox
  const handleSelectAllCheckboxChange = () => {
    const allReportsSelected =
      selectedReports.length === filteredReports.length;
    if (allReportsSelected) {
      setSelectedReports([]);
    } else {
      const allReportIds = filteredReports.map((report) => report.id);
      setSelectedReports(allReportIds);
    }
  };

  // Fetching data
  useEffect(() => {
    console.log("HIY");
    setLoadingData(true);
    setLoadingErrorText([]);
    // Only fetches if clientID is not null
    // ==================== CLIENT REPORTS ====================
    if (clientID) {
      console.log("HIY");
      const fetchAllReports = async () => {
        try {
          const jsonData: ReportData[] | string = await getClientReports(
            clientID,
            showFetchAllReports
          );
          if (!jsonData) {
            setLoadingErrorText((prevErrors) => [
              ...prevErrors,
              "8425c691234g44zb9940a2cda7d89h0"
            ]);
          } else {
            // Check that it is not a string
            try {
              if (typeof jsonData === "string") {
                console.log("running");
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!"
                });
              }
              // Is an object
              else {
                console.log(jsonData);
                const reportData: ReportData[] = jsonData.map((report) => ({
                  ...report,
                  created: new Date(report.created),
                  modified: new Date(report.modified)
                }));

                // Convert client ID to client name
                const reportsWithClientNames = reportData.map((report) => ({
                  ...report,
                  client: report.client
                    ? convertIDToName("client", report.client, allClients)
                    : ""
                }));

                // Find all report types and add uniques to reportTypes, using setReportTypes
                setReportTypes(
                  Array.from(
                    new Set(reportsWithClientNames.map((report) => report.type))
                  )
                );

                setOriginalReports(reportsWithClientNames);
                setFilteredReports(reportsWithClientNames);
              }
            } catch {
              console.error("Error setting reports");
              console.log("running");

              throw new Error("Error setting reports");
            }
          }
        } catch (e) {
          console.error("Error fetching reports", e);
        }
      };

      const promises = [fetchAllReports()];

      Promise.all(promises)
        .then(() => {
          setLoadingClients(false);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingClients(false);
          setLoadingData(false);
        });
    }
    // Fetch all reports
    // ==================== ALL REPORTS ====================
    else {
      const fetchAllReports = async () => {
        try {
          //  If clientID is not null, fetch client reports
          // ==================== ALL REPORTS ====================
          const jsonData: ReportData[] | string =
            await getAllReports(showFetchAllReports);
          if (!jsonData) {
            setLoadingErrorText((prevErrors) => [
              ...prevErrors,
              "8425c691234g44zb9940a2cda7d89h0"
            ]);
          } else {
            try {
              // Check that it is not a string
              if (typeof jsonData === "string") {
                console.log("running");
                throw new Error("Error fetching reports");
              } else {
                // Fetch all client names
                const fetchAllClientNames = async () => {
                  try {
                    const jsonData: Client[] | false =
                      await fetchAllClientsNames();
                    if (!jsonData) {
                      setLoadingErrorText((prevErrors) => [
                        ...prevErrors,
                        "8425c69t38k0z1a2b9940a2cda9e5dad7"
                      ]);
                    } else {
                      try {
                        return jsonData as SimpleData[];
                      } catch {
                        console.error("Error setting clients");
                        throw new Error("Error setting clients");
                      }
                    }
                  } catch (e) {
                    console.error("Error fetching clients", e);
                  }
                };

                // Fetch all client names, set dropdown and for displaying client name
                const clientNames = await fetchAllClientNames();

                let staticClients = ["Kelloggs", "URC"];
                if (clientNames) {
                  setAllClients(clientNames);
                  setClients(() => [
                    ...staticClients,
                    ...clientNames.map((client) => client.name)
                  ]);
                }

                // Set Dates to acceptable format
                const reportData: ReportData[] = jsonData.map((report) => ({
                  ...report,
                  created: new Date(report.created),
                  modified: new Date(report.modified)
                }));

                // Set Client Names instead of IDs
                const reportsWithClientNames = reportData.map((report) => ({
                  ...report,
                  client: report.client
                    ? convertIDToName("client", report.client, clientNames)
                    : ""
                }));

                // Set Type Dropdown
                setReportTypes(
                  Array.from(
                    new Set(reportsWithClientNames.map((report) => report.type))
                  )
                );

                setOriginalReports(reportsWithClientNames);
                setFilteredReports(reportsWithClientNames);
              }
            } catch (e) {
              console.error("Error setting reports");
              console.log(e);
              console.log("running");

              throw new Error("Error setting reports");
            }
          }
        } catch (e) {
          console.error("Error fetching reports", e);
        }
      };

      const promises = [fetchAllReports()];

      Promise.all(promises)
        .then(() => {
          setLoadingClients(false);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingClients(false);
          setLoadingData(false);
        });
    }
  }, [showFetchAllReports]);

  // Determine which page we are on: for global reports or client reports
  const [isGlobalReports, setIsGlobalReports] = useState(false);
  useEffect(() => {
    if (clientID) {
      setIsGlobalReports(false);
    } else {
      setIsGlobalReports(true);
    }
  }, [clientID]);

  // Use effect to filter reports
  const [latestReportsFiltered, setLatestReportsFiltered] =
    useState<boolean>(false);

  const handleSetLatestReportsFiltered = (filter: boolean) => {
    setLatestReportsFiltered(filter);
  };

  const handleClearFilters = () => {
    setReportsSearch("");
    setLatestReportsFiltered(false);
    setToDate("");
    setFromDate("");
    setFilterClients([]);
    setFilterReportTypes([]);
  };

  // Applying filters
  useEffect(() => {
    let filteredReports = originalReports;
    console.log("Triggered");

    if (latestReportsFiltered === true) {
      filteredReports = getLastReportEachMonth(originalReports);
    }

    // Filter search report name
    if (reportsSearch !== "") {
      filteredReports = filteredReports.filter((report) =>
        report.fileName.toLowerCase().includes(reportsSearch.toLowerCase())
      );
    }

    // Filter by report type
    if (filterReportTypes.length > 0) {
      filteredReports = filteredReports.filter((report) =>
        filterReportTypes.includes(report.type)
      );
    }

    // Filter by client
    console.log(filterClients);
    if (filterClients.length > 0) {
      console.log(filterClients);
      filteredReports = filteredReports.filter((report) =>
        filterClients.includes(report.client ? report.client : "")
      );
    }

    // Filter by date by range of from and to dates
    if (fromDate !== "" && toDate !== "") {
      filteredReports = filteredReports.filter((report) => {
        const reportDate = new Date(report.created);
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);

        // Create new Date objects with year, month, and day parts only
        const reportDateFormatted = new Date(
          reportDate.getFullYear(),
          reportDate.getMonth(),
          reportDate.getDate()
        );

        const fromDateFormatted = new Date(
          fromDateObj.getFullYear(),
          fromDateObj.getMonth(),
          fromDateObj.getDate()
        );

        const toDateFormatted = new Date(
          toDateObj.getFullYear(),
          toDateObj.getMonth(),
          toDateObj.getDate()
        );

        return (
          reportDateFormatted.getTime() >= fromDateFormatted.getTime() &&
          reportDateFormatted.getTime() <= toDateFormatted.getTime()
        );
      });
    }

    setCurrentPage(1);
    setFilteredReports(
      filteredReports.sort((a, b) => b.created.getTime() - a.created.getTime())
    );
  }, [
    originalReports,
    latestReportsFiltered,
    reportsSearch,
    filterApplied,
    toDate,
    fromDate,
    filterClients,
    filterReportTypes
  ]);

  // What is actually displayed
  useEffect(() => {
    setCurrentReports(
      filteredReports.slice(
        (currentPage - 1) * reportsPerPage,
        currentPage * reportsPerPage
      )
    );
  }, [filteredReports, currentPage, reportsPerPage]);

  // Store the current state of filters
  useEffect(() => {
    if (
      reportsSearch !== "" ||
      latestReportsFiltered === true ||
      fromDate !== "" ||
      toDate !== "" ||
      filterClients.length > 0 ||
      filterReportTypes.length > 0
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [
    reportsSearch,
    latestReportsFiltered,
    fromDate,
    toDate,
    filterClients,
    filterReportTypes
  ]);

  // Get the last report of every month
  function getLastReportEachMonth(reports: ReportData[]) {
    // Sort reports by createdAt
    const sortedReports = reports.sort(
      (a, b) => a.created.getTime() - b.created.getTime()
    );

    // Group reports by year and month

    const groupedReports = sortedReports.reduce(
      (groups: { [key: string]: ReportData[] }, report: ReportData) => {
        const date = new Date(report.created);

        const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

        if (!groups[yearMonth]) {
          groups[yearMonth] = [];
        }

        groups[yearMonth].push(report);

        return groups;
      },
      {}
    );

    // Select the last report of each month
    const lastReports = Object.values(groupedReports).map(
      (reports) => reports[reports.length - 1]
    );

    return lastReports;
  }

  // Bulk download handler
  const handleBulkDownloadReports = async () => {
    // Alert them that they are about to perform a bulk download
    Swal.fire({
      title: "Are you sure?",
      html: `You are about to download multiple reports. <br /> ${selectedReports.length} reports will be downloaded.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#3085d6"
    }).then((result) => {
      if (result.isConfirmed) {
        // Download all reports
        // downloadAllReports();
        console.log(selectedReports);
      }
    });
  };

  return loadingData || loadingClients ? (
    <div className="d-flex justify-content-center custom_spinner_container full_page">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : (
    <div className="reports_container">
      <div className="row reports_top_heading">
        <div className="heading_left col-7">
          <h1 className="">
            {isGlobalReports ? "All" : currentClientName + ":"} Reports
          </h1>
        </div>

        <div className="col-3 total_reports_count">
          {/* {selectedReports.length > 0 ? (
            <button
              className="btn btn-primary"
              onClick={handleBulkDownloadReports}
            >
              <IconDownload />
              {selectedReports.length} / {originalReports.length}
            </button>
          ) : null} */}
          <h5>
            {selectedReports.length > 0 ? selectedReports.length + " /" : ""}{" "}
            {originalReports.length} Total Reports
          </h5>
        </div>
        <button
          className={`btn btn-primary monthly_reports_button ${
            latestReportsFiltered ? "active" : ""
          }`}
          onClick={() => {
            handleSetLatestReportsFiltered(!latestReportsFiltered);
          }}
        >
          Last Report of the Month
        </button>
      </div>
      <div className="row">
        <div className="col d-flex reports_per_page">
          <TableRowsSelector
            handleChangeRows={handleChangeRows}
            rowsPerPage={reportsPerPage}
            tableName="Reports"
            arrayRowSelector={[10, 20, 50, 100]}
          />
        </div>
      </div>
      <div className="row d-flex card-header reports_card_heading justify-content-between">
        {/* <div className="col-1 d-flex align-items-center p-0 reports_h3_container"></div> */}
        <div className="col-12 p-0">
          <div className="reports_filter_container">
            {/*==================== ALL REPORTS ====================*/}
            {isGlobalReports ? (
              <div className="filter_component">
                <FiltersContainer
                  tableType="allReports"
                  setSearch={setReportsSearch}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  clients={clients}
                  setClientsFilter={setFilterClients}
                  date="1"
                  reportTypes={reportTypes}
                  setFilterReportTypes={setFilterReportTypes}
                  setFilterApplied={setFilterApplied}
                  filterApplied={filterApplied}
                  handleClearFilters={handleClearFilters}
                />
              </div>
            ) : (
              /*==================== CLIENT REPORTS ====================*/
              <div className="filter_component">
                <FiltersContainer
                  tableType="clientReports"
                  setSearch={setReportsSearch}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  date="1"
                  clients={clients}
                  setClientsFilter={setFilterClients}
                  reportTypes={reportTypes}
                  setFilterReportTypes={setFilterReportTypes}
                  setFilterApplied={setFilterApplied}
                  filterApplied={filterApplied}
                  handleClearFilters={handleClearFilters}
                />
              </div>
            )}
          </div>
          {/* <div className="message">{message ? message : <></>}</div> */}
        </div>
      </div>
      {/* REPORTS CONTAINER */}
      {originalReports.length === 0 || currentReports.length === 0 ? (
        <div className="row d-flex justify-content-center align-items-center py-1">
          No reports found{" "}
          {currentReports.length === 0 &&
          filterApplied &&
          originalReports.length > 0
            ? "Please try adjusting your filters."
            : ""}
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <table className="report_rows_container table">
              {/* TABLE CELL HEADINGS */}
              <thead>
                <tr className="report_single_headings">
                  <th className="report_header_name checkbox_select">
                    {/* SELECT ALL */}
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      onChange={handleSelectAllCheckboxChange}
                      checked={
                        selectedReports.length === filteredReports.length &&
                        filteredReports.length > 0
                      }
                    />
                  </th>
                  {/*==================== ALL REPORTS ====================*/}

                  {isGlobalReports ? (
                    <>
                      <th className="report_header_name">Name</th>
                      <th className="report_header_description">Client</th>
                      <th className="report_header_description">Type</th>
                      <th className="report_header_date">Created At</th>
                      <th className="report_header_date">Updated At</th>
                      <th className="report_header_date">Download</th>
                    </>
                  ) : (
                    /*==================== CLIENT REPORTS ====================*/

                    <>
                      <th className="report_header_name">Name</th>
                      <th className="report_header_description">Type</th>
                      <th className="report_header_date">Created At</th>
                      <th className="report_header_date">Updated At</th>
                      <th className="report_header_date">Download</th>
                    </>
                  )}
                </tr>
              </thead>
              {/* Iterate through current Reports, displaying names */}
              <>
                {/* Table Body */}
                <tbody>
                  {currentReports.map((report) => (
                    <tr
                      key={report.id}
                      className="align-items-stretch report_single"
                    >
                      {/* Checkbox  */}
                      <td className="report_checkbox">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          onChange={() => handleCheckboxChange(report.id)}
                          checked={selectedReports.includes(report.id)}
                        />
                      </td>
                      {/*==================== ALL REPORTS ====================*/}

                      {isGlobalReports ? (
                        <>
                          <td className="report_name">
                            <a
                              href={`${report.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {report.fileName}
                            </a>
                          </td>
                          <td className="report_description">
                            {report.client ? report.client : ""}
                          </td>
                          <td className="report_name">{report.type}</td>
                          <td className="report_date">
                            {formatDate(report.created)}
                          </td>
                          <td className="report_date">
                            {formatDate(report.modified)}
                          </td>
                          <td className="report_actions">
                            <a
                              href={`${report.downloadUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconDownload />
                            </a>
                          </td>
                        </>
                      ) : (
                        /*==================== CLIENT REPORTS ====================*/

                        <>
                          <td className="report_name">
                            <a
                              href={`${report.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {report.fileName}
                            </a>
                          </td>
                          <td className="report_description">{report.type}</td>
                          <td className="report_date">
                            {formatDate(report.created)}
                          </td>
                          <td className="report_date">
                            {formatDate(report.modified)}
                          </td>
                          <td className="report_actions">
                            <a
                              href={`${report.downloadUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconDownload />
                            </a>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </>
            </table>
          </div>
          {/* // Pagination */}
          <div className="row py-1">
            <div className="col-auto">
              <PaginationNavigation
                currentPage={currentPage}
                totalResults={filteredReports ? filteredReports.length : 0}
                resultsPerPage={reportsPerPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className="col-auto ms-auto">
              {/* {displayedDataDateOffet}
                <button
                  id="refresh_button"
                  className="btn btn-primary ms-2"
                  onClick={() => handleRefreshData()}
                  disabled={!refreshButtonEnabled}
                >
                  Refresh
                </button> */}
            </div>
          </div>
          {!showFetchAllReports && (
            <div>
              <button
                className="btn btn-primary"
                onClick={() => setShowFetchAllReports(true)}
              >
                Fetch All Reports
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Reports;
