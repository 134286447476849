// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#table_rows_selector {
  display: inline-block;
  margin-left: 20px;
}
@media screen and (max-width: 915px) {
  #table_rows_selector {
    display: block;
    margin-left: 0px;
    font-size: 0.9rem !important;
    margin-top: 10px;
  }
  #table_rows_selector .btn {
    font-size: 0.9rem !important;
    padding: 0.2rem 0.5rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/TableRowsSelector/tableRowsSelector.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,iBAAA;AADF;AAGE;EAJF;IAKI,cAAA;IACA,gBAAA;IACA,4BAAA;IACA,gBAAA;EAAF;EACE;IACE,4BAAA;IACA,iCAAA;EACJ;AACF","sourcesContent":["@use \"../../../global.scss\" as *;\n\n#table_rows_selector {\n  display: inline-block;\n  margin-left: 20px;\n\n  @media screen and (max-width: 915px) {\n    display: block;\n    margin-left: 0px;\n    font-size: 0.9rem !important;\n    margin-top: 10px;\n    .btn {\n      font-size: 0.9rem !important;\n      padding: 0.2rem 0.5rem !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
