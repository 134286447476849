import React from "react";
import Admin from "../../components/Admin/admin.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";

interface AdminPageProps extends SidebarProps {}

const AdminPage: React.FC<AdminPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page
}) => {
  return (
    <div className="main_container">
      {/* <div className="container-fluid" id="admin_page"> */}
      <div id="admin_page">
        <Admin />
      </div>
      <SidebarComponent
        page="admin"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
    </div>
  );
};

export default AdminPage;
