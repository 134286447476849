import React from "react";

export type GeoFencesProps = {
  studyClicked: string;
};

const GeoFences: React.FC<GeoFencesProps> = ({ studyClicked }) => {
  return (
    <>
      <div>GeoFences</div>
      <div>{studyClicked}</div>
    </>
  );
};

export default GeoFences;
