import Swal from "sweetalert2";
import {
  Actions,
  AutomationDataDB,
  AutomationLog,
  ChildConditionGroup,
  ConditionRuleSingle,
  PrerequisiteRule,
  Rules,
  SimpleDataAutomation,
  Timings
} from "../types";

interface FailedItem {
  key: string;
  failedChecks: string[];
}

type SimpleIDNameData = {
  id: string;
  name: string;
};

//==================================== CONDITIONS ====================================
export function getEmptyFields(rule: ConditionRuleSingle): string[] {
  const emptyFields: string[] = [];

  for (const key in rule) {
    // if (!rule[key]) {
    //   emptyFields.push(key);

    // }
    console.log(key);

    switch (key) {
      case "selection":
        if (!rule["selection"]) {
          emptyFields.push("Rule");
        }
        break;

      case "options":
        if (!rule["options"]) {
          emptyFields.push("Options");
        }
        break;

      case "value":
        if (!rule["value"]) {
          emptyFields.push("Value");
        }
        break;

      case "type":
        console.log(rule["type"]);
        console.log(rule["selection"]);
        if (rule["type"] === "survey") {
          if (!rule["variable"]) {
            emptyFields.push("Please Select a Survey");
          }
        } else if (rule["type"] === "buckets") {
          if (!rule["variable"]) {
            emptyFields.push("Please Select a Bucket");
          }
        }
        break;

      case "variable":
        break;

      case "id":
        break;

      default:
        console.log("No match: ", key, " : ", rule[key]);
        console.log(rule);
        emptyFields.push(
          `<br /><br />Error for: ${key} Invalid Rule Field. Please contact support.<br />`
        );
        break;
    }
  }

  return emptyFields;
}

export function isChildConditionGroupNotEmpty(
  group: ChildConditionGroup
): boolean {
  for (const key in group) {
    const item = group[key];

    if (!item.operator) return false;
    if (
      Object.keys(item.childConditionGroups).length === 0 &&
      item.childRules.length === 0
    )
      return false;
    for (const rule of item.childRules) {
      if (!getEmptyFields(rule)) return false;
    }
    if (!isChildConditionGroupNotEmpty(item.childConditionGroups)) return false;
  }
  return true;
}

export function getFailedChildConditionGroups(
  group: ChildConditionGroup
): FailedItem[] {
  const failedItems: FailedItem[] = [];

  for (const key in group) {
    const item = group[key];
    const failedChecks: string[] = [];

    // if (!item.operator) {
    //   failedChecks.push("operator");
    // }
    if (
      Object.keys(item.childConditionGroups).length === 0 &&
      item.childRules.length === 0
    ) {
      failedChecks.push(
        "You cannot have an incomplete group",
        "You cannot have incomplete rules"
      );
    } else {
      for (const rule of item.childRules) {
        let emptyFields = getEmptyFields(rule);
        if (emptyFields.length > 0) {
          failedChecks.push(`Missing Rule Fields: ${emptyFields.join(", ")}`);
        }
      }
      const failedChildGroups = getFailedChildConditionGroups(
        item.childConditionGroups
      );
      if (failedChildGroups.length > 0) {
        failedChecks.push("You cannot have an empty child group");
        failedItems.push(...failedChildGroups);
      }
    }

    if (failedChecks.length > 0) {
      failedItems.push({ key, failedChecks });
    }
  }

  return failedItems;
}

//==================================== ACTIONS ====================================

export function getActionFailures(actions: Actions[]): string[] {
  // if actions less than 1 return array failure stating that actions must be greater than 1

  var failures: string[] = [];
  if (actions.length < 1) {
    failures.push("Please add at least one action");
  }

  let addedActions: string[] = [];
  let addedSurveys: string[] = [];

  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];

    if (
      typeof action.type !== "string" ||
      !action.type ||
      typeof action.selection !== "string" ||
      !action.selection ||
      typeof action.value !== "string" ||
      !action.value
    ) {
      if (typeof action.selection !== "string" || !action.selection) {
        failures.push("Please select an Action Type");
      }
      if (typeof action.value !== "string" || !action.value) {
        failures.push("Please select an Action Value");
      }
    }

    // Survey must have a variable
    if (action.type === "survey" && !action.variable) {
      failures.push("Please select a Survey");
    }

    // Action value cannot be larger than 1500 characters
    if (action.value.length > 1500) {
      failures.push("Action value cannot be larger than 1500 characters");
    }

    // Check if the action has been added already
    // allow multiple of action.type "survey" but not multiple of the same variable
    if (addedActions.includes(action.type) && action.type !== "survey") {
      failures.push("Automation cannot have multiple of the same action type");
    } else {
      if (action.type === "survey") {
        if (addedSurveys.includes(action.variable)) {
          failures.push(
            "Automation action cannot have multiple of the same survey"
          );
        }
        addedSurveys.push(action.variable);
      }
      addedActions.push(action.type);
    }
  }

  return failures;
}

//==================================== STATUS + NAME ====================================

export function getFailedStatus(status: string): string {
  var failedChecks: string = "";

  if (!status) {
    failedChecks = "Please select a status";
  }

  return failedChecks;
}

export function getFailedName(
  name: string,
  allAutomations: Rules[],
  edit: boolean,
  originalRuleName: string
): string {
  var failedChecks: string = "";

  if (!name) {
    failedChecks = "Please enter a workflow rule name";
  }

  // Name cannot be larger than 255 characters
  if (name.length > 255) {
    failedChecks = "Name cannot be larger than 255 characters";
  }

  // check if the name has changed and if edit is false
  if (edit === false && name !== originalRuleName) {
    allAutomations.forEach((automation) => {
      if (automation.name === name) {
        failedChecks = "Please enter a unique workflow rule name";
      }
    });
  }
  return failedChecks;
}

//==================================== TIMING ====================================

export function getFailedTiming(timing: Timings): string[] {
  var failedChecks: string[] = [];

  // No check needed for noSchedule
  if (timing.type === "noSchedule") {
    // failedChecks.push("");
    return [];
  }

  // dateTime only needs a time, if no date is selected, default to daily
  if (timing.type === "dateTime") {
    console.log("RUNNING");
    var dateTimeCheck: string = "";
    if (timing.type === "dateTime" && "participantLocalTime" in timing.timing) {
      dateTimeCheck = timing.timing.participantLocalTime;
      console.log(dateTimeCheck);
      if (!dateTimeCheck && timing.timing.participantLocalTime === "") {
        failedChecks.push("Please select a time");
      }
    }
  }

  // Prerequisite rule
  if (timing.type === "prerequisite") {
    var prerequisiteCheck: PrerequisiteRule = {
      automationID: "",
      fireType: "",
      value: ""
    };

    if (timing.type === "prerequisite" && "automationID" in timing.timing) {
      prerequisiteCheck = timing.timing;
      if (!prerequisiteCheck) {
        failedChecks.push("Please select a prerequisite rule");
      }
      if (prerequisiteCheck.automationID === "") {
        failedChecks.push("Please select a prerequisite rule");
      }
      if (prerequisiteCheck.fireType === "") {
        failedChecks.push("Please select a unit of time");
      }
      // must be greater than 0
      if (prerequisiteCheck.value === "" || prerequisiteCheck.value === "0") {
        failedChecks.push("Please select a value for the unit of time");
      }
    }
  }

  if (!timing) {
    failedChecks.push("Please select a timing");
  }

  return failedChecks;
}

//==================================== GENERAL ====================================
export function convertIDToName(
  actionType: string,
  ID: string,
  dataSource: SimpleIDNameData[] | undefined
): string | undefined {
  if (!dataSource) {
    return undefined;
  }
  switch (actionType) {
    case "survey":
      console.log(dataSource);
      const foundSurvey = dataSource.find(
        (survey: SimpleIDNameData) => survey.id === ID
      );
      if (foundSurvey) {
        console.log(foundSurvey.name);
        return foundSurvey.name;
      } else {
        break;
      }

    case "bucket":
      console.log(dataSource);
      const foundBucket = dataSource.find(
        (bucket: SimpleIDNameData) => bucket.id === ID
      );
      if (foundBucket) {
        console.log(foundBucket.name);
        return foundBucket.name;
      } else {
        break;
      }

    case "automation":
      console.log(dataSource);
      const foundAutomation = dataSource.find(
        (automation: SimpleIDNameData) => automation.id === ID
      );
      if (foundAutomation) {
        console.log(foundAutomation.name);
        return foundAutomation.name;
      } else {
        break;
      }

    case "client":
      // console.log(dataSource);
      const foundClient = dataSource.find(
        (client: SimpleIDNameData) => client.id === ID
      );
      // TODO: Remove this once we have a proper client list
      if (ID === "7") {
        // console.log("FOUND");
        return "Kelloggs";
      } else if (ID === "8") {
        // console.log("FOUND");
        return "URC";
      } else {
        if (foundClient) {
          // console.log(foundClient.name);
          return foundClient.name;
        } else {
          break;
        }
      }

    default:
      return ID;
  }
}

//==================================== VALIDATIONS BE ====================================
function isSimpleDataAutomation(obj: any): obj is SimpleDataAutomation {
  const validID = typeof obj.id === "string";
  const validName = typeof obj.name === "string";

  const AllValid = validID && validName;

  if (!AllValid) {
    console.log("Invalid SimpleDataAutomation object");
    console.log(obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Incorrect type for id`);
      }
    }
    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Incorrect type for name`);
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `ID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Automation Data`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });

    console.log(errors);
  }

  return AllValid;
}

// Data used to populate dropdowns (tags, buckets, surveys)
export function validateAutomationData(obj: any): obj is AutomationDataDB {
  const validTags =
    Array.isArray(obj.tags) && obj.tags.every(isSimpleDataAutomation);
  const validBuckets =
    Array.isArray(obj.buckets) && obj.buckets.every(isSimpleDataAutomation);
  const validSurveys =
    Array.isArray(obj.surveys) && obj.surveys.every(isSimpleDataAutomation);

  const AllValid = validTags && validBuckets && validSurveys;

  if (!AllValid) {
    console.log("Invalid AutomationDataDB object");
    console.log(obj);
    let errors: string[] = [];
    if (!validTags) {
      if (!obj.hasOwnProperty("tags")) {
        errors.push("missing tags");
      } else {
        errors.push(`Incorrect type for tags`);
      }
    }
    if (!validBuckets) {
      if (!obj.hasOwnProperty("buckets")) {
        errors.push("missing buckets");
      } else {
        errors.push(`Incorrect type for buckets`);
      }
    }
    if (!validSurveys) {
      if (!obj.hasOwnProperty("surveys")) {
        errors.push("missing surveys");
      } else {
        errors.push(`Incorrect type for surveys`);
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Automation Data`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    console.log(errors);
  }

  console.log(AllValid);
  return AllValid;
}

// ==================================== VALIDATIONS MONITOR ====================================
export function isAutomationLog(obj: any): obj is AutomationLog {
  const validID = typeof obj.id === "string";
  const validAutomationID = typeof obj.automation_id === "string";
  const validAutomationName = typeof obj.automation_name === "string";
  // const validAutomationActions = typeof obj.automation_actions === "string";
  const validAutomationScheduled = typeof obj.automation_scheduled === "string";
  const validAutomationScheduledTime =
    typeof obj.automation_run_time === "string";
  const validAutomationStatus = typeof obj.automation_status === "string";
  const validParticipantID = typeof obj.participant_id === "string";
  // const validParticipantName = typeof obj.participant_name === "string";
  // const validParticipantSurname = typeof obj.participant_surname === "string";
  const validParticipantEmail = typeof obj.participant_email === "string";
  const validParticipantMobileNumber =
    typeof obj.participant_mobile_number === "string";
  // const validParticipant_Timezone =
  //   typeof obj.participant_timezone === "string"
  //validation for condition type
  //Validation for actions and condition
  const validAction =
    Array.isArray(obj.automation_actions) &&
    obj.automation_actions.every(getActionFailures);

  const validCondition =
    typeof obj.automation_conditions === "object" &&
    isChildConditionGroupNotEmpty(obj.automation_conditions);
  const AllValid =
    validID &&
    validAutomationID &&
    validAutomationName &&
    // validAutomationActions &&
    validAutomationScheduled &&
    validAutomationScheduledTime &&
    validAutomationStatus &&
    validParticipantID &&
    // validParticipantName &&
    // validParticipantSurname &&
    validParticipantEmail &&
    validParticipantMobileNumber &&
    validAction &&
    validCondition;
  // validParticipant_Timezone;
  // console.log("AllValid", AllValid);
  if (!AllValid) {
    console.log("Invalid automationLog object");
    console.log(obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`ID: ${obj.id}`);
      }
    }
    if (!validAutomationID) {
      if (!obj.hasOwnProperty("automation_id")) {
        errors.push("missing automation_id");
      } else {
        errors.push(`Incorrect type for automation_id`);
      }
    }
    if (!validAutomationName) {
      if (!obj.hasOwnProperty("automation_name")) {
        errors.push("missing automation_name");
      } else {
        errors.push(`Incorrect type for automation_name`);
      }
    }

    if (!validAction) {
      if (!obj.hasOwnProperty("automation_actions")) {
        errors.push("missing automation_actions");
      } else {
        errors.push(`Incorrect type for automation_actions`);
      }
    }

    if (!validCondition) {
      if (!obj.hasOwnProperty("automation_condition")) {
        errors.push("missing automation_condition");
      } else {
        errors.push(`Incorrect type for automation_condition`);
      }
    }
    // if (!validAutomationActions) {
    //   if (!obj.hasOwnProperty("automation_actions")) {
    //     errors.push("missing automation_actions");
    //   } else {
    //     errors.push(`Incorrect type for automation_actions`);
    //   }
    // }
    if (!validAutomationScheduled) {
      if (!obj.hasOwnProperty("automation_scheduled")) {
        errors.push("missing automation_scheduled");
      } else {
        errors.push(`Incorrect type for automation_scheduled`);
      }
    }
    if (!validAutomationScheduledTime) {
      if (!obj.hasOwnProperty("automation_scheduled_time")) {
        errors.push("missing automation_scheduled_time");
      } else {
        errors.push(`Incorrect type for automation_scheduled_time`);
      }
    }
    if (!validAutomationStatus) {
      if (!obj.hasOwnProperty("automation_status")) {
        errors.push("missing automation_status");
      } else {
        errors.push(`Incorrect type for automation_status`);
      }
    }
    if (!validParticipantID) {
      if (!obj.hasOwnProperty("participant_id")) {
        errors.push("missing participant_id");
      } else {
        errors.push(`Incorrect type for participant_id`);
      }
    }
    // if (!validParticipantName) {
    //   if (!obj.hasOwnProperty("participant_name")) {
    //     errors.push("missing participant_name");
    //   } else {
    //     errors.push(`Incorrect type for participant_name`);
    //   }
    // }
    // if (!validParticipantSurname) {
    //   if (!obj.hasOwnProperty("participant_surname")) {
    //     errors.push("missing participant_surname");
    //   } else {
    //     errors.push(`Incorrect type for participant_surname`);
    //   }
    // }
    if (!validParticipantEmail) {
      if (!obj.hasOwnProperty("participant_email")) {
        errors.push("missing participant_email");
      } else {
        errors.push(`Incorrect type for participant_email`);
      }
    }
    if (!validParticipantMobileNumber) {
      if (!obj.hasOwnProperty("participant_mobile_number")) {
        errors.push("missing participant_mobile_number");
      } else {
        errors.push(`Incorrect type for participant_mobile_number`);
      }
    }
    // if (!validParticipant_Timezone) {
    //   if (!obj.hasOwnProperty("participant_timezone")) {
    //     errors.push("missing participant_timezone");
    //   } else {
    //     errors.push(`Incorrect type for participant_timezone`);
    //   }
    // }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `AutomationID: ${obj.automationID}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid automationLog`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
