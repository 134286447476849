import React, { useCallback, useEffect, useState } from "react";
// import { fetchAllTLC } from "../../models/tlc.model";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ResearcherConfigData,
  updateResearcherData
} from "../../models/config.model";
import { Timezone } from "../../types";
import {
  getResearcherConfig,
  getSystemConfigTimezones,
  setResearcherConfig
} from "../../utilities/config.util";
import {
  fetchResearcherConfigAndSetImage,
  validImageChecker
} from "../../utilities/imageUploadHandler.util";
import InformationTooltip from "../InputInformation/InfoInputs.component";
import TfaModalHandler from "../MFAEnableHandler/tfaModalHandler.component";
import "./profileSettings.scss";

const ProfileSettings = () => {
  // const [user, setUser] = useState<ResearcherConfigData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [showQrModal, setShowQrModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [saveFailed, setSaveFailed] = useState(false); // State to indicate failed login attempt
  const [errorMessage, setErrorMessage] = useState("");
  const [dataChange, setDataChange] = useState(false);

  // Keeps track of the state of mfa_enabled as received from the BE
  const [mfaEnabled, setMfaEnabled] = useState(false);

  // Used to trigger getting latest researcher config data
  const [mfaToggled, setMfaToggled] = useState(false);

  // This will be used to save the data from database
  const [receivedFormDataObject, setReceivedFormDataObject] = useState({
    first_name: "",
    surname: "",
    alias: "",
    timezone: "",
    email: "",
    profile_picture: ""
    // newPassword: "",
    // confirmPassword: "",
  });

  // This will be used to save the new data that is entered
  const [sendFormDataObject, setSendFormDataObject] = useState({
    // PLEASE LEAVE THEM AS EMPTY STRINGS
    first_name: "",
    surname: "",
    alias: "",
    timezone: "",
    profile_picture: ""
    // newPassword: "",
    // confirmPassword: "",
  });

  useEffect(() => {
    // fetch timezones data on component mount
    const fetchTLCData = async () => {
      try {
        const jsonData = await getSystemConfigTimezones();
        // if jsonData is a string, then there was an error
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching languages");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);

          setTimezones(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        // setLoadingLanguages(true); // Set loadingError to true in case of an error
      }
      // setLoadingLanguages(false);
    };

    // Create an array of promises for each handleData call
    const promises = [fetchTLCData()];

    // Use Promise.all to wait for all promises to complete
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
        setLoadingTimezones(false);
        // setLoadingError(false); // No errors occurred
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingTimezones(false);
        // setLoadingError(true); // An error occurred
      });
  }, []);

  // fetch research data
  const fetchResearcherData = useCallback(async () => {
    try {
      const responseData: ResearcherConfigData | false =
        await getResearcherConfig();
      if (responseData) {
        console.log(
          responseData,
          "Logging response data in fetchResearcherData"
        );

        // Set the state of mfa_enabled
        if (responseData.multi_factor_auth === true) {
          setMfaEnabled(true);
        } else {
          setMfaEnabled(false);
        }

        // before we set the useStates we need to make sure the image backend provides can work.
        // Wait for the image URL to be resolved
        const imageUrl = await fetchResearcherConfigAndSetImage();
        console.log("the imageUrl ran with value:", imageUrl);
        // Save the data in a useState
        const updatedReceivedData = {
          first_name: responseData?.first_name || "",
          surname: responseData?.surname || "",
          alias: responseData?.alias || "",
          email: responseData?.email || "",
          timezone: responseData?.timezone_name || "",
          profile_picture: imageUrl
        };

        const updatedSendData = {
          first_name: responseData?.first_name || "",
          surname: responseData?.surname || "",
          alias: responseData?.alias || "",
          timezone: responseData?.timezone_name || "",
          profile_picture: imageUrl
        };
        // We do this to ensure that the states have updated before using them.
        setReceivedFormDataObject(updatedReceivedData);
        setSendFormDataObject(updatedSendData);
      } else {
        console.log("no researcher config");
        Swal.fire({
          title: "Error",
          html: "Please contact Support<br/>Unable to fetch researcher config",
          icon: "error",
          confirmButtonText: "Ok"
        });
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching researcher config:",
        error
      );
      Swal.fire({
        title: "Error",
        html: "Please contact Support<br/>Unable to fetch researcher config",
        icon: "error",
        confirmButtonText: "Ok"
      });
    }
  }, []);

  // Fetches researcher config if mfa has toggled. Looking for multi_factor_auth
  useEffect(() => {
    const fetchLatest = async () => {
      if (mfaToggled) {
        await setResearcherConfig();
        await fetchResearcherData();
        setMfaToggled(false);
      }
    };

    fetchLatest();
  }, [mfaToggled, fetchResearcherData]);

  useEffect(() => {
    fetchResearcherData();
  }, [fetchResearcherData]);

  // Show password fields
  const toggleInput = () => {
    setIsVisible(!isVisible);
  };

  const setError = (message: string) => {
    setErrorMessage(message);
  };

  // This will handle the back back action after cancel is clicked
  function handleCancel() {
    navigate("/clients");
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (
      e.target.name === "profile_picture" &&
      e.target instanceof HTMLInputElement
    ) {
      // Now TypeScript knows that e.target is definitely an HTMLInputElement
      const file = e.target.files?.[0]; // Use optional chaining for safety
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          if (typeof result === "string") {
            setSendFormDataObject((prevState) => ({
              ...prevState,
              profile_picture: result
            }));
          }
        };
        reader.readAsDataURL(file); // Read the file as a Data URL (base64 string)
        setDataChange(true);
      }
    } else {
      // Handle other input/select types
      setSendFormDataObject((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value.trim()
      }));
      setDataChange(true);
    }
  };

  // Handle the save action
  async function handleSave() {
    let firstNameValue = sendFormDataObject?.first_name;
    let surnameValue = sendFormDataObject?.surname;
    let aliasValue = sendFormDataObject?.alias;
    let timezoneValue = sendFormDataObject?.timezone;
    let profilePictureValue = sendFormDataObject?.profile_picture;

    if (dataChange === true) {
      // Check if the label data is the same as database data and check for empty fields etc.
      // first_name
      if (firstNameValue !== receivedFormDataObject.first_name) {
        if (
          firstNameValue === null ||
          firstNameValue === undefined ||
          firstNameValue === ""
        ) {
          setSaveFailed(true);
          setError("Please enter data in all fields");
          return;
        }
      } else {
        console.log("first name is the same");
        // We do this because we want to send the backend empty strings if an input was unchanged
        if (firstNameValue === "") {
          sendFormDataObject.first_name = "";
        }
      }

      // surname
      if (surnameValue !== receivedFormDataObject.surname) {
        if (
          surnameValue === null ||
          surnameValue === undefined ||
          surnameValue === ""
        ) {
          setSaveFailed(true);
          setError("Please enter data in all fields");
          return;
        }
      } else {
        console.log("surname is the same");
        // We do this because we want to send the backend empty strings if an input was unchanged
        if (surnameValue === "") {
          sendFormDataObject.surname = "";
        }
      }

      //alias
      if (aliasValue !== receivedFormDataObject.alias) {
        if (
          aliasValue === null ||
          aliasValue === undefined ||
          aliasValue === ""
        ) {
          setSaveFailed(true);
          setError("Please enter data in all fields");
          return;
        }
      } else {
        console.log("alias is the same");
        // We do this because we want to send the backend empty strings if an input was unchanged
        if (aliasValue === "") {
          sendFormDataObject.alias = "";
        }
      }

      //validate string fields that cannot have special characters but can contain arabic characters
      const stringRegex = /^[^\/\:\;'",\.<>@\!\$\%\^\&\`\*\)\(\}\{\]\[]{0,60}$/;

      // validate first name
      if (!stringRegex.test(firstNameValue)) {
        setSaveFailed(true);
        setError("First name cannot have special characters");
        return;
      }

      // validate surname
      if (!stringRegex.test(surnameValue)) {
        setSaveFailed(true);
        setError("Surname cannot have special characters");
        return;
      }

      // validate alias
      if (!stringRegex.test(aliasValue)) {
        setSaveFailed(true);
        setError("Chat alias cannot have special characters");
        return;
      }

      // timezone
      if (timezoneValue !== receivedFormDataObject.timezone) {
        if (
          timezoneValue === null ||
          timezoneValue === undefined ||
          timezoneValue === ""
        ) {
          setSaveFailed(true);
          setError("Please enter data in all fields");
          return;
        }
      } else {
        console.log("timezone is the same");
        // We do this because we want to send the backend empty strings if an input was unchanged
        if (timezoneValue === "") {
          sendFormDataObject.timezone = "";
        }
      }

      // profile_picture
      if (profilePictureValue === receivedFormDataObject.profile_picture) {
        console.log("profile_picture is the same", profilePictureValue);
        // We do this because we want to send the backend empty strings if an input was unchanged
        sendFormDataObject.profile_picture = "";
      } else {
        // Here we do an extra step, we need to send a base64 image to backend.
        // This is done on onChange handler when an image is chosen.
        // But we need to make sure its in base64 format:
        console.log("profile_picture is not the same", profilePictureValue);
        try {
          const validImage = await validImageChecker(
            sendFormDataObject.profile_picture
          );
          if (validImage !== true) {
            Swal.fire({
              icon: "error",
              title: "Error",
              html: "Something is wrong with your image, please check that it is png/jpg or jpeg",
              confirmButtonText: "Ok"
            });
            return;
          }
        } catch {
          Swal.fire({
            title: "Error",
            html: "Please contact Support<br />Unable to fetch data",
            icon: "error",
            confirmButtonText: "Ok"
          });
          return;
        }
      }
      // should never hit this but if it does somehow.
    } else {
      Swal.fire({
        title: "Error",
        html: "Please contact Support<br/>Unable to fetch data",
        icon: "error",
        confirmButtonText: "Ok"
      });
    }

    // If none of the required fields are empty, proceed to compare the data to database values
    console.log("First Name:", firstNameValue);
    console.log("Surname:", surnameValue);
    console.log("Alias:", aliasValue);
    console.log("Timezone:", timezoneValue);
    console.log("Profile Picture:", profilePictureValue);

    // Send data to backend if it didn't fall out in previous if's
    updateData(sendFormDataObject);

    return sendFormDataObject;
  }

  // update data and return status code for error handling
  const updateData = async (formData: {
    first_name: string;
    surname: string;
    alias: string;
    timezone: string;
    profile_picture: string;
    // newPassword: string;
    // confirmPassword: string;
  }) => {
    try {
      // Wait for the update API call to resolve and get the response message
      const response: boolean = await updateResearcherData(formData);
      // Display a success/error message to the user based on the response]
      console.log(response, "the state of response");
      if (response) {
        // showing them that data updated successfully
        Swal.fire({
          title: "Success",
          html: "Data was updated successfully!<br>Logged out!",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6"
        });
        navigate("/login");
        return;
      }
      //even though the data didn't change we make them believe it did.

      // For errors or other messages, adjust as needed
      Swal.fire("Oops!", "Data was not updated, error occurred", "error");
      return;
    } catch (error) {
      // Handle any errors that occur during the update
      console.error("An error occurred while updating the data:", error);
      Swal.fire("Error", "Failed to update the data.", "error");
      return;
    }
  };

  // Confirmation Modal

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleShow = () => {
    console.log("handleShow");
    setShowConfirmationModal(true);
  };

  return (
    // display the user data if loading is false
    !loading && !loadingTimezones ? (
      // double check if user is not undefined, this should never happen as it is checked above
      receivedFormDataObject ? (
        <div className="container profile_settings">
          <div className="row">
            <div className="col-12">
              <h1>Profile Settings</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 col-lg-8">
              <div className="card">
                <div className="card-body row">
                  {/* Left Side, Inputs */}
                  <div className="mb-3 login_input_section col-12 col-sm-12 col-md-12 col-lg-6 profile_input">
                    {/* Name */}
                    <div className="mb-3">
                      <label
                        className="form-label mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        Name:
                      </label>
                      <input
                        className="form-control login_input"
                        type="text"
                        defaultValue={receivedFormDataObject.first_name}
                        name="first_name"
                        onChange={
                          (e) => handleInputChange(e)
                          // handleInputChange(e.target.value, e.target.name)
                        }
                        placeholder="Name"
                      />
                    </div>
                    {/* Surname */}
                    <div className="mb-3">
                      <label
                        className="form-label mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        Surname:
                      </label>
                      <input
                        className="form-control login_input"
                        type="text"
                        defaultValue={receivedFormDataObject.surname}
                        name="surname"
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Surname"
                      />
                    </div>
                    {/* Alias */}
                    <div className="mb-3">
                      <label
                        className="form-label mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        Chat alias:
                      </label>
                      <input
                        className="form-control login_input"
                        type="text"
                        defaultValue={receivedFormDataObject.alias}
                        name="alias"
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Chat alias"
                      />
                    </div>
                    {/* EMAIL */}
                    <div className="mb-3">
                      <label
                        className="form-label mb-1 mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        Email:
                      </label>
                      <input
                        className="form-control login_input"
                        type="text"
                        defaultValue={receivedFormDataObject.email}
                        disabled={true}
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    {/* Timezone */}
                    <div className="mb-3">
                      <InformationTooltip
                        marginTop="-12px"
                        position="right"
                        tooltipText="Your local timezone is set here. This configuration determines the display of time-related information across the system, ensuring that it aligns with your local time."
                      />
                      <label
                        className="form-label mb-1 mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        Timezone:
                      </label>
                      <select
                        className="form-select login_input"
                        aria-label="Default select example"
                        defaultValue={receivedFormDataObject.timezone}
                        name="timezone"
                        onChange={(e) => handleInputChange(e)}
                      >
                        {timezones.map((timezone) => (
                          <option key={timezone.name} value={timezone.name}>
                            {/* add + to positive timezones */}
                            {/* {timezone.offset} - {timezone.name} */}
                            {timezone.offset > 0
                              ? `(+${timezone.offset}) - ${timezone.name}`
                              : `(${timezone.offset}) - ${timezone.name}`}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* Password
                    // TODO: Add back in
                    */}
                    {/* <div className="mb-3">
                      <label
                        className="form-label mb-1 mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        Password:
                      </label>
                      <input
                        className="form-control login_input"
                        type="password"
                        defaultValue={receivedFormDataObject.encrypted_password}
                        // onChange={(e) => handlePasswordChange(e)}
                        placeholder="Password"
                      />
                    </div> */}
                    <div className="mb-3 mt-4">
                      {/* <label
                        className="reset-password form-label mb-1 mb-1 mx-1"
                        htmlFor="form-stacked-text"
                        onClick={toggleInput}
                      >
                        Click to Reset Password
                      </label> */}
                    </div>
                    {/* New Password*/}
                    <div className={`mb-3 ${isVisible ? "visible" : "hidden"}`}>
                      <label
                        className="form-label mb-1 mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        New Password:
                      </label>
                      <input
                        className="form-control login_input"
                        type="password"
                        // value={password}
                        name="newPassword"
                        // onChange={(e) =>
                        //   handleInputChange(e.target.value, e.target.name)
                        // }
                        placeholder="New Password"
                      />
                    </div>
                    {/* Password Confirmation*/}
                    <div className={`mb-4 ${isVisible ? "visible" : "hidden"}`}>
                      <label
                        className="form-label mb-1 mb-1 mx-1"
                        htmlFor="form-stacked-text"
                      >
                        Password Confirmation:
                      </label>
                      <input
                        className="form-control login_input"
                        type="password"
                        // value={password}
                        // onChange={(e) => handlePasswordChange(e)}
                        name="confirmPassword"
                        // onChange={(e) =>
                        //   handleInputChange(e.target.value, e.target.name)
                        // }
                        placeholder="Password Confirmation"
                      />
                    </div>
                    {/* Save button, invalid errors */}
                    <div className="mt- mb-3 text-center">
                      <span
                        className={`ms-2 text-danger ${
                          saveFailed ? "d-inline" : "d-none"
                        }`}
                      >
                        {errorMessage}
                      </span>
                    </div>
                    {/* Buttons */}
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn_cancel col-3"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`btn btn_save col-3 ${
                          !dataChange && "disabled"
                        }`}
                        onClick={handleSave}
                        // The button will be disabled if dataChange is false
                        disabled={!dataChange}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  {/* GUTTER */}
                  <div className="col-0 col-lg-1"></div>
                  {/* Right Side, Profile Pic */}
                  <div className="mb-3 profile_pic_section col-5 profile_picture">
                    <div className="card upload_profile mb-4">
                      <div
                        className="image-container card-img-top img-fluid"
                        style={{
                          backgroundImage: `url(${
                            sendFormDataObject.profile_picture &&
                            sendFormDataObject.profile_picture.length !== 0 &&
                            sendFormDataObject.profile_picture !== "" &&
                            sendFormDataObject.profile_picture !== null &&
                            sendFormDataObject.profile_picture !== undefined
                              ? sendFormDataObject.profile_picture
                              : `../../../../images/placeholders/default_profile.jpg`
                          })`
                        }}
                      ></div>
                      {/* File Input */}
                      <div className="card-body">
                        <div className="">
                          <input
                            className="form-control"
                            type="file"
                            accept="image/*"
                            id="formFile"
                            name="profile_picture"
                            onChange={(e) => handleInputChange(e)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {/* <TfaModal /> */}
                    {/* MFA ENABLE/RESET BUTTON*/}
                    {/* Button for MFA */}
                    <div className="card">
                      <button
                        type="button"
                        className="btn btn_save"
                        onClick={handleShow}
                      >
                        {mfaEnabled ? "Reset " : "Enable "} Multi Factor
                        Authentication
                      </button>
                    </div>
                    <InformationTooltip
                      position="left"
                      tooltipText="Initiate or reset your multi-factor authentication (MFA) in this section. Please follow the provided instructions closely for a secure setup"
                    />
                    <TfaModalHandler
                      mfaEnabled={mfaEnabled}
                      setMfaToggled={setMfaToggled}
                      showQrModal={showQrModal}
                      setShowQrModal={setShowQrModal}
                      showConfirmationModal={showConfirmationModal}
                      setShowConfirmationModal={setShowConfirmationModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
    ) : (
      // display a loading indicator if loading is true
      <div className="d-flex justify-content-center custom_spinner_container full_page">
        <div className="spinner-border" role="status"></div>
      </div>
    )
  );
};

export default ProfileSettings;
