import React, { useEffect, useRef, useState } from "react";
import "./dropdown.scss";

interface DropdownProps {
  placeholder: string;
  options: string[];
  selected: string;
  onOptionSelected: (option: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  options,
  selected,
  onOptionSelected
}) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If the ref is available and the target is not within the dropdown, hide the dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array means this useEffect runs once after initial render

  return (
    <div className="btn-container" ref={dropdownRef}>
      <button
        className="btn btn_filter dropdown-toggle"
        onClick={() => setShow((prev) => !prev)}
      >
        {selected || placeholder}
      </button>
      {show && (
        <div className="generic-dropdown-menu">
          <div className="generic-dropdown-menu-items">
            {options.map((option, index) => (
              <div
                key={index}
                className="generic-dropdown-menu-item"
                onClick={() => {
                  onOptionSelected(option);
                  setShow(false);
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
