import React, { ChangeEvent } from "react";
import "./toggleSwitch.scss";

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

interface ToggleSwitchProps {
  id: string;
  name?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  optionLabels?: [string, string];
  small?: boolean;
  disabled?: boolean;
  authCheck?: boolean;
  afterPadding?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  name,
  checked,
  onChange,
  optionLabels = ["", ""],
  small = false,
  disabled = true,
  afterPadding
  //   authCheck,
}) => {
  return (
    <div className={`toggle-switch ${small ? " small-switch" : ""} `}>
      <input
        type="checkbox"
        name={name}
        className={`toggle-switch-checkbox `}
        id={id}
        checked={checked}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.checked)
        }
        disabled={disabled}
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : `toggle-switch-inner ${afterPadding ? "after-padding" : ""}`
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
          />
        </label>
      ) : null}
    </div>
  );
};

export default ToggleSwitch;
