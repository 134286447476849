import { FC, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import {
  createNewPhrase,
  fetchAllLanguageLibraryPhrasesByCatID
} from "../../../models/translations.model";
import {
  Language,
  LanguageLibraryPhrase,
  NewTranslation
} from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface AddPhraseModalProps {
  show: boolean;
  handleClose: () => void;
  selectedCategoryID: string;
  allLanguages: Language[];
}

const AddPhraseModal: FC<AddPhraseModalProps> = ({
  show,
  handleClose,
  selectedCategoryID,
  allLanguages
}) => {
  const [usedPhrases, setUsedPhrases] = useState<string[]>([]); // Set initial state to null
  const [loading, setLoading] = useState<boolean>(true); // Set initial state to true
  const [loadingError, setLoadingError] = useState<string>("");
  const [additionalAddedTranslations, setAdditionalAddedTranslations] =
    useState<NewTranslation[]>([]);
  const [curentSelectedLanguage, setCurentSelectedLanguage] =
    useState<string>("");

  const [searchQuery, setSearchQuery] = useState("");
  const [showAddLanguage, setShowAddLanguage] = useState(false);
  const changeLanguage = (languageCode: string) => {
    // This function will change the language
    setCurentSelectedLanguage(languageCode);
  };

  const [phrase, setPhrase] = useState<string>("");
  const [busySubmitting, setBusySubmitting] = useState<boolean>(false);

  function handleAddLanguage(iso_code: string) {
    // This function will add the language to the additionalAddedTranslations
    // console.log("iso_code", iso_code);

    // find language in all languages
    const language = allLanguages.find(
      (language) => language.iso_code === iso_code
    );

    // console.log("language", language);

    //  add language to selected languages
    if (language) {
      setAdditionalAddedTranslations([
        ...additionalAddedTranslations,
        {
          iso_code: language.iso_code,
          translation: ""
        }
      ]);

      setCurentSelectedLanguage(language.iso_code);
    }
  }

  function handleRemoveLanguage(iso_code: string) {
    // console.log("iso_code", iso_code);
    // This function will remove the language from the selected languages

    // find language in all languages
    const language = allLanguages.find(
      (language) => language.iso_code === iso_code
    );

    //  remove language from selected languages
    if (language) {
      // setSelectedLanguages(
      //   selectedLanguages?.filter(
      //     (selectedLanguage) => selectedLanguage.iso_code !== iso_code
      //   )
      // );
      setAdditionalAddedTranslations(
        additionalAddedTranslations.filter(
          (translation) => translation.iso_code !== iso_code
        )
      );
      // set the current selected language to the last language in the list
      setCurentSelectedLanguage(
        additionalAddedTranslations[additionalAddedTranslations.length - 1]
          .iso_code || ""
      );
    }
  }

  // useEffect(() => {
  //   console.log("curentSelectedLanguage", curentSelectedLanguage);
  // }, [curentSelectedLanguage]);

  useEffect(() => {
    setLoading(true);
    if (selectedCategoryID && selectedCategoryID !== "") {
      const fetchAllLanguageLibraryPhrases = async () => {
        try {
          const jsonData: string | LanguageLibraryPhrase[] =
            await fetchAllLanguageLibraryPhrasesByCatID(selectedCategoryID);
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            // console.log("jsonData is a string");
            // console.log("jsonData", jsonData);
            setUsedPhrases([]);
            setLoadingError("Error fetching phrases");
          } else {
            if (jsonData && jsonData.length > 0) {
              const allUsedPhrases = jsonData.map((phrase) => phrase.name);
              setUsedPhrases(allUsedPhrases);
              setLoadingError("");
            } else {
              // setLoadingError("No phrases found for category");
              setLoadingError("");
            }
          }
        } catch (error) {
          console.error(
            "An error occurred while fetching participant data:",
            error
          );
          setUsedPhrases([]);
          setLoadingError("Error fetching phrases");
        }
      };

      const promises = [fetchAllLanguageLibraryPhrases()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
          setLoading(false);
        })
        .catch(() => {
          // At least one promise failed
          setLoading(false);
          setLoadingError("Error fetching phrases");
        });
    } else {
      setUsedPhrases([]);
      setLoading(false);
      setLoadingError("No category selected");
    }
  }, [selectedCategoryID]);

  async function handleSubmit() {
    // This function will submit the form
    if (phrase === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a phrase",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    // check if phrase is already used
    if (
      usedPhrases.find(
        (usedPhrase) => usedPhrase.toLowerCase() === phrase.toLowerCase()
      )
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phrase already exists",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // send to createNewPhrase
    try {
      // Set busy submitting
      setBusySubmitting(true);
      const response = await createNewPhrase(
        selectedCategoryID,
        phrase,
        additionalAddedTranslations
      );

      // Set busy submitting
      setBusySubmitting(false);

      // console.log(response, "response");

      if (response.rStatus !== "success") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.rMessage,
          confirmButtonColor: "#3085d6"
        });
        // close
        handleClose();
      } else {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.rMessage,
          confirmButtonColor: "#3085d6"
        });
        handleClose();
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error creating phrase",
        confirmButtonColor: "#3085d6"
      });
    }
  }

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    setShowAddLanguage(false);
    // clear search query
    setSearchQuery("");
  });

  return (
    <>
      <div className={`modal add_phrase_modal ${show ? "show" : "hide"}`}>
        <div className="modal-dialog">
          {loading ? (
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            </div>
          ) : loadingError !== "" ? (
            <div className="modal-content">
              <div className="modal-body">
                <p>{loadingError}</p>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="modal-title">Add Phrase</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-3 col-md-4">
                      <div className="form-group">
                        <label htmlFor="phraseName">English Phrase</label>
                      </div>
                    </div>
                    <div className="col-9 col-md-8">
                      <textarea
                        className="form-control"
                        id="englishTranslation"
                        placeholder="Enter english phrase"
                        onChange={(e) => setPhrase(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <p className="mb-2">Translations</p>
                      <>
                        <div
                          id="phrase_languages"
                          className="languages_selector_container"
                        >
                          <div id="add_laguage">
                            <div id="current_added_languages">
                              {/* map though added langues */}
                              {additionalAddedTranslations &&
                                additionalAddedTranslations.length > 0 &&
                                additionalAddedTranslations.map((language) => {
                                  const languageCode = language.iso_code;
                                  return (
                                    <span
                                      key={languageCode}
                                      className={`btn_survey_language_iso ${
                                        languageCode === curentSelectedLanguage
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={(e) => {
                                        changeLanguage(languageCode);
                                      }}
                                    >
                                      {languageCode.toUpperCase()}
                                    </span>
                                  );
                                })}
                            </div>
                            {/* button opens dropdown with all laguages */}
                            <div ref={ref}>
                              <button
                                id="add_language_button"
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  setShowAddLanguage(!showAddLanguage)
                                }
                              >
                                Add Language
                              </button>
                              <div
                                className={`dropdown-menu ${
                                  showAddLanguage ? "show" : "hide"
                                }`}
                              >
                                <input
                                  type="text"
                                  placeholder="Search languages"
                                  value={searchQuery}
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  }
                                />
                                <div className="checkbox-list">
                                  {allLanguages
                                    .filter((language) => {
                                      // Ensure the language is not English (EN)
                                      // if (
                                      //   language.iso_code.toLowerCase() === "en"
                                      // ) {
                                      //   return false;
                                      // }

                                      // Include languages that match the search query
                                      return (
                                        language.name
                                          .toLowerCase()
                                          .includes(
                                            searchQuery.toLowerCase()
                                          ) ||
                                        language.iso_code
                                          .toLowerCase()
                                          .includes(searchQuery.toLowerCase())
                                      );
                                    })
                                    .map((language) => {
                                      return (
                                        <div
                                          className="dropdown_menu_item"
                                          key={language.iso_code}
                                        >
                                          <label className="form_control">
                                            <input
                                              type="checkbox"
                                              id={language.iso_code}
                                              name={language.name}
                                              value={language.iso_code}
                                              checked={
                                                additionalAddedTranslations.find(
                                                  (translation) =>
                                                    translation.iso_code ===
                                                    language.iso_code
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  handleAddLanguage(
                                                    language.iso_code
                                                  );
                                                } else {
                                                  handleRemoveLanguage(
                                                    language.iso_code
                                                  );
                                                }
                                              }}
                                            />
                                            {language.iso_code} -{" "}
                                            {language.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {allLanguages.length > 0 &&
                                    allLanguages.filter((language) => {
                                      if (
                                        language.iso_code.toLowerCase() === "en"
                                      ) {
                                        return false;
                                      }
                                      return (
                                        language.name
                                          .toLowerCase()
                                          .includes(
                                            searchQuery.toLowerCase()
                                          ) ||
                                        language.iso_code
                                          .toLowerCase()
                                          .includes(searchQuery.toLowerCase())
                                      );
                                    }).length === 0 && (
                                      <div>No languages found.</div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          {curentSelectedLanguage &&
                            additionalAddedTranslations.length > 0 && (
                              <textarea
                                name="translation"
                                id="translation"
                                className="form-control"
                                value={
                                  additionalAddedTranslations.find(
                                    (translation) =>
                                      translation.iso_code ===
                                      curentSelectedLanguage
                                  )?.translation || ""
                                }
                                onChange={(e) =>
                                  setAdditionalAddedTranslations(
                                    additionalAddedTranslations.map(
                                      (translation) => {
                                        if (
                                          translation.iso_code ===
                                          curentSelectedLanguage
                                        ) {
                                          return {
                                            ...translation,
                                            translation: e.target.value
                                          };
                                        } else {
                                          return translation;
                                        }
                                      }
                                    )
                                  )
                                }
                              ></textarea>
                            )}
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col">
                    *Note: all other translations will be added by Google
                    Translate
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary me-2"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Add Phrase
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPhraseModal;
