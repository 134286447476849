// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.histogram-container .bar-hover-buttons {
  position: absolute;
  width: 50px;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  margin-top: -10px;
  cursor: pointer;
}
.histogram-container .bar-hover-buttons svg {
  fill: #1f8ec7;
  width: 20px;
}
@media (max-width: 1600px) {
  .histogram-container .bar-hover-buttons {
    margin-right: 100px;
  }
}
@media (max-width: 1500px) {
  .histogram-container .bar-hover-buttons {
    margin-right: 50px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Buckets/Histogram/histogram.styles.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,MAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;AADJ;AAGI;EACE,aAAA;EACA,WAAA;AADN;AAQI;EAtBF;IAuBI,mBAAA;EALJ;AACF;AAOI;EA1BF;IA2BI,kBAAA;EAJJ;AACF","sourcesContent":[".histogram-container {\n  // position: relative;\n  .bar-hover-buttons {\n    position: absolute;\n    width: 50px;\n    right: 0; // Adjust based on the new buttonX calculation\n    top: 0; // Will be set dynamically\n    display: flex;\n    flex-direction: column;\n    align-items: center; // Center buttons vertically\n    justify-content: center;\n    z-index: 1000;\n    margin-top: -10px;\n    cursor: pointer;\n\n    svg {\n      fill: #1f8ec7;\n      width: 20px;\n    }\n\n    // &:hover {\n    //   opacity: 1;\n    // }\n\n    @media (max-width: 1600px) {\n      margin-right: 100px;\n    }\n\n    @media (max-width: 1500px) {\n      margin-right: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
