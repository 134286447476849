import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  AutomationDataDB,
  AutomationLog,
  Rules,
  SingleAutomationData
} from "../types";
import {
  isAutomationLog,
  validateAutomationData
} from "../utilities/automation.util";

export type AutomationList = {
  id: string;
  name: string;
};

// =====================================================  MONITOR =====================================================

export async function fetchAllAutomationLogs(
  clientID: string,
  studyID: string
): Promise<AutomationLog[] | string> {
  const response = await apiFetcher<any>(
    "/automation/monitoring/get/all",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID
      }
    }
  );

  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isAutomationLog)
    ) {
      // console.log("Successfully fetched all automationLogs");

      return response.data;
    } else {
      console.log("Invalid data received");

      return "Invalid data received";
    }
  } else {
    console.log("Failed to fetch all automationLogs");
    return "Failed to fetch all automationLogs";
  }
}

// =====================================================  RULES =====================================================

// Data used to populate dropdowns (tags, buckets, suryeys)
export async function fetchAllAutomationData(
  clientID: string,
  studyID: string
): Promise<AutomationDataDB | string> {
  const response = await apiFetcher<any>("/automation/workflow/data/", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID
    }
  });

  // console.log(response, "response");
  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    if (response.data && validateAutomationData(response.data)) {
      return response.data;
    } else {
      // console.log(response.data.every(validateAutomationData), "validate");
      // They sent something we aren't expecting
      console.log("Invalid data received");

      return "Invalid data received";
    }
    // 500?
  } else {
    console.log("Failed to fetch all automationData");
    return "Failed to fetch all automationData";
  }
}

// Get all automations
export async function getAllAutomations(
  clientID: string,
  studyID: string
): Promise<Rules[] | string> {
  const response = await apiFetcher<any>(
    "/automation/workflow/get/all",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID
      }
    }
  );

  console.log(response, "response");
  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    if (
      response.data
      // &&
      // Array.isArray(response.data) &&
      // response.data.every(isAutomationData)
    ) {
      // console.log("Successfully fetched all automationData");
      return response.data as Rules[];
    } else {
      console.log("Invalid data received");

      return "Invalid data received";
    }
  } else {
    console.log("Failed to fetch all automationData");
    return "Failed to fetch all automationData";
  }
}

// Get single automation
export async function getAutomation(
  clientID: string,
  studyID: string,
  automationID: string
): Promise<SingleAutomationData | string> {
  const response = await apiFetcher<any>(
    "/automation/workflow/get/id",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID,
        id: automationID
      }
    }
  );

  // console.log(response, "response");
  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    if (
      response.data
      // &&
      // Array.isArray(response.data) &&
      // response.data.every(isAutomationData)
    ) {
      // console.log("Successfully fetched all automationData");
      return response.data as SingleAutomationData;
    } else {
      console.log("Invalid data received");

      return "Invalid data received";
    }
  } else {
    console.log("Failed to fetch all automationData");
    return "Failed to fetch all automationData";
  }
}

// Save automation
export async function saveAutomation(
  clientID: string,
  studyID: string,
  automation: SingleAutomationData
): Promise<string> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const response = await apiFetcher<any>(
    "/automation/workflow/create",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID,
        // studyID: studyID,
        automation: automation
      }
    }
  );

  console.log(response, "response");
  if (response.status === 202) {
    console.log("Successfully saved automation");
    return response.status.toString();
  } else {
    console.log("Failed to save automation");
    return "Failed to save automation";
  }
}

// Update automation
export async function updateAutomation(
  clientID: string,
  studyID: string,
  automation: SingleAutomationData,
  automationID: string
): Promise<string> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const response = await apiFetcher<any>(
    "/automation/workflow/update/id",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID,
        automation: automation,
        automationID: automationID
      }
    }
  );

  console.log(response, "response");
  if (response.status === 202) {
    console.log("Successfully updated automation");
    return response.status.toString();
  } else {
    console.log("Failed to update automation");
    return "Failed to update automation";
  }
}

// Update automation status
export async function updateAutomationStatus(
  clientID: string,
  studyID: string,
  automationID: string,
  status: string
): Promise<string> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  const response = await apiFetcher<any>(
    "/automation/workflow/update/status",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID,
        automationID: automationID,
        status: status
      }
    }
  );
  // Log what is sent
  console.log(clientID, studyID, automationID, status);

  console.log(response, "response");
  if (response.status === 202) {
    console.log("Successfully updated automation status");
    return response.status.toString();
  } else {
    console.log("Failed to update automation status");
    return "Failed to update automation status";
  }
}

// Delete automation
export async function deleteAutomations(
  clientID: string,
  studyID: string,
  automationIDs: string[]
): Promise<string> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {
    let isBulk = automationIDs.length > 1;
    const response = await apiFetcher<any>(
      "/automation/workflow/delete/id",
      "POST",
      {
        body: {
          clientID: clientID,
          studyID: studyID,
          automationIDs: automationIDs,
          isBulk: isBulk
        }
      }
    );

    console.log(response, "response");
    if (isBulk && response.status === 200) {
      if (
        response.data.jobID &&
        response.data.jobID !== "" &&
        response.data.jobID !== null
      ) {
        localStorage.setItem("workerID", response.data.jobID);
        return "successfulBulk";
      }
      return "Failed to create bulk job";
    } else if (response.status === 202) {
      console.log("Successfully deleted automations");
      return response.status.toString();
    } else {
      console.log("Failed to delete automations");
      return "Failed to delete automations";
    }
  } catch {
    return "Failed to delete automations";
  }
}

export async function checkAutomation(
  clientID: string,
  studyID: string,
  automation: SingleAutomationData
): Promise<string> {
  // function send the conditions to the server and return the number of participants that match the conditions

  const response = await apiFetcher<any>("/automation/workflow/check", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID,
      automation: automation
    }
  });

  console.log(response, "response");

  if (response.status === 200 || response.status === 202) {
    console.log(response.data, typeof response.data);
    return "success";
  }

  if (response.status === 404) {
    return "Route not found";
  }

  if (response.status === 500) {
    return "Internal server error";
  }

  return "Unknown error";
}

export async function runAutomation(
  clientID: string,
  studyID: string,
  automationID: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  // function send the conditions to the server and return the number of participants that match the conditions

  Swal.fire({
    title: "Sending data",
    html: "Please wait while the automation is run",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const response = await apiFetcher<any>("/automation/workflow/run", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID,
      automationID: automationID,
      runNow: true
    }
  });

  console.log(response, "response");

  if (response.status === 202 || response.status === 200) {
    console.log("Successfully ran automation");
    return {
      rStatus: "success",
      rMessage: "Successfully ran automation"
    };
  } else {
    console.log("Failed to run automation");
    return {
      rStatus: "error",
      rMessage: "Failed to run automation"
    };
  }
}
