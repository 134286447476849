import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Swal from "sweetalert2";
import "../global.scss";
import { ResearcherConfigData } from "../models/config.model";
import {
  getTokensLocalStorage,
  isTokenAboutToExpire,
  isTokenExpired,
  requestAndSetTokens
} from "./tokenHandler.util";

import ErrorPage from "../pages/Error/error.page";

import { PermissionsProvider } from "../contexts/UserContext";
import { SystemConfigData } from "../models/config.model";
import { checkAndFetchWorkerStatus } from "../models/worker.model";
import {
  getResearcherConfig,
  getSystemConfig,
  setResearcherConfig,
  setSystemConfig
} from "./config.util";
import { logStorage } from "./utils";

interface ProtectedRouteProps {
  // children: ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckComplete, setIsCheckComplete] = useState(false);
  const [isSystemLoadingConfigComplete, setIsSystemLoadingConfigComplete] =
    useState(false);
  const [
    isResearcherLoadingConfigComplete,
    setIsResearcherLoadingConfigComplete
  ] = useState(false);
  const [systemConfigDataExists, setSystemConfigDataExists] =
    useState<boolean>(false);
  const [researcherConfigDataExists, setResearcherConfigDataExists] =
    useState<boolean>(false);

  async function checkUserTokens() {
    // Get tokens from local storage
    const { authToken, refreshToken } = await getTokensLocalStorage();

    // if auth-token exists and is about to expire, requestandsettokens
    if (authToken && refreshToken && isTokenAboutToExpire(authToken)) {
      await requestAndSetTokens({ refreshToken: refreshToken });
      // console.log("refreshed tokens!!!!");
    }

    // If the refresh token is expired or invalid, then log the user out
    if (
      !refreshToken ||
      !authToken ||
      refreshToken === "undefined" ||
      isTokenExpired(refreshToken)
    ) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      isTokenExpired(authToken);
    }

    // checkUserTokens has completed
    setIsCheckComplete(true);
  }
  //Toast Message
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    width: "100%", // Adjust width to 'auto' or any specific value
    padding: "1rem", // Optional: Adjust padding if needed
    confirmButtonColor: "#3085d6",
    customClass: {
      popup: "custom-swal"
    }
    // timer: 2000,
  });
  async function refreshCheckOnWorker() {
    // console.log("refreshCheckOnWorker");
    const workerStatus = await checkAndFetchWorkerStatus();
    // console.log("workerStatus:", workerStatus);
    if (workerStatus === "noWorkerFoundInLocal") {
      return;
    }

    if (workerStatus === "completed") {
      Toast.fire({
        icon: "success",
        title: "Worker has completed",
        text: "You can continue with more bulk actions",
        width: "auto", // Adjust width to 'auto' or any specific value
        padding: "1rem", // Optional: Adjust padding if needed
        confirmButtonColor: "#3085d6",
        //showConfirmButton: false,
        confirmButtonText: "Refresh Page",
        showDenyButton: true,
        denyButtonColor: "#3085d6",
        denyButtonText: "Cancel",
        showCloseButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      localStorage.removeItem("workerID");
      return;
    }

    const workerID: string = localStorage.getItem("workerID") || "missing ID";

    if (workerStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Worker has errored",
        text: `Worker ID: ${workerID}`,
        confirmButtonColor: "#3085d6"
      });
      localStorage.removeItem("workerID");
      return;
    }
    if (workerStatus === "noWorkerFoundInDatabase") {
      Swal.fire({
        icon: "error",
        title: "Worker not found",
        text: `Worker ID: ${workerID}`,
        confirmButtonColor: "#3085d6"
      });
      localStorage.removeItem("workerID");
      return;
    }
    if (workerStatus === "contact") {
      Swal.fire({
        icon: "error",
        title: "Please contact support",
        text: `Worker ${workerID} has errored and cannot be restarted`,
        confirmButtonColor: "#3085d6"
      });
      localStorage.removeItem("workerID");
      return;
    }
  }

  useEffect(() => {
    // check if user is valid in every 5 seconds
    checkUserTokens();
    const interval = setInterval(() => {
      checkUserTokens();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // console.log("isCheckComplete:", isCheckComplete);
    if (isCheckComplete) {
      const interval = setInterval(() => {
        refreshCheckOnWorker();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isCheckComplete]);

  useEffect(() => {
    if (isCheckComplete) {
      const fetchSystemConfig = async () => {
        const jsonData: false | SystemConfigData = await getSystemConfig();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          // console.log("Fetching config data from server");
          const jsonNewData: boolean = await setSystemConfig();
          // console.log("jsonNewData:", jsonNewData);
          if (jsonNewData !== false) {
            setSystemConfigDataExists(true);
          }
        } else {
          setSystemConfigDataExists(true);
        }
      };

      fetchSystemConfig()
        .then(() => {
          setIsSystemLoadingConfigComplete(true);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          setIsSystemLoadingConfigComplete(true);
          setSystemConfigDataExists(false);
        });
    }
  }, [isCheckComplete]);

  useEffect(() => {
    if (isCheckComplete) {
      const fetchResearcherConfig = async () => {
        const jsonData: false | ResearcherConfigData =
          await getResearcherConfig();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          // console.log("Fetching config data from server");
          const jsonNewData: boolean = await setResearcherConfig();
          // console.log("jsonNewData:", jsonNewData);
          if (jsonNewData !== false) {
            setResearcherConfigDataExists(true);
            if (process.env.REACT_APP_PRODUCTION === "true") {
              const researcherConfigData =
                localStorage.getItem("researcherConfig");
              if (researcherConfigData) {
                const researcherJSON = JSON.parse(researcherConfigData);
                const researcherEmail = researcherJSON.email;
                const researcherNameFirst = researcherJSON.first_name;
                const researcherNameLast = researcherJSON.surname;
                LogRocket.identify(researcherConfigData, {
                  name: researcherNameFirst + " " + researcherNameLast,
                  email: researcherEmail
                });
              }
            }
          }
        } else {
          setResearcherConfigDataExists(true);
        }
      };

      fetchResearcherConfig()
        .then(() => {
          setIsResearcherLoadingConfigComplete(true);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          setIsResearcherLoadingConfigComplete(true);
          setResearcherConfigDataExists(false);
        });
    }
  }, [isCheckComplete]);

  useEffect(() => {
    if (isCheckComplete) {
      logStorage();
    }
  }, [isCheckComplete]);

  // Can combine isSystemLoadingConfigComplete and isResearcherLoadingConfigComplete as one useState
  // If the check is not complete, show a loading spinner
  if (
    !isCheckComplete ||
    !isSystemLoadingConfigComplete ||
    !isResearcherLoadingConfigComplete
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="d-flex justify-content-center custom_spinner_container full_page">
          <div className="spinner-border" role="status"></div>
        </div>
      </div>
    );
  }

  return isLoggedIn ? (
    <PermissionsProvider>
      {researcherConfigDataExists && systemConfigDataExists ? (
        <Outlet />
      ) : (
        <>
          {!systemConfigDataExists && (
            <ErrorPage
              errorCode={"500"}
              error="Error fetching system config data"
              contactSupport={true}
              goHome={false}
            />
          )}
          {!researcherConfigDataExists && (
            <ErrorPage
              errorCode={"500"}
              error="Error fetching researcher config data"
              contactSupport={true}
              goHome={false}
            />
          )}
        </>
      )}
    </PermissionsProvider>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
