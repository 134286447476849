import React from "react";
import StudySettings from "../StudySurveySettings/StudySettings.component";
import SurveySettings from "../StudySurveySettings/SurveySettings.component";

export type StudySurveySettingsHandlerProps = {
  studyClicked: string;
};

const StudySurveySettingsHandler: React.FC<StudySurveySettingsHandlerProps> = ({
  studyClicked
}) => {
  return (
    <div className="settingsStudySurvey">
      {studyClicked === "study" ? (
        <StudySettings studyClicked={studyClicked} />
      ) : (
        <SurveySettings studyClicked={studyClicked} />
      )}
    </div>
  );
};

export default StudySurveySettingsHandler;
