//Page to display the clients in cards

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientCard from "../../components/DisplayClients/clientCard.component";
import ClientInfo from "../../components/DisplayClients/clientInfo.component";
import DisplayStudies from "../../components/DisplayStudies/displayStudies.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { fetchSingleClientByID } from "../../models/client.model.ts";
import ErrorPage from "../Error/error.page.tsx";
import "./viewAllStudies.scss";

import InformationTooltip from "../../components/InputInformation/InfoInputs.component.tsx";
import { usePermissions } from "../../contexts/UserContext.tsx";
import { Client, SidebarProps } from "../../types.ts";

interface ViewAllStudiesPageProps extends SidebarProps {}

const ViewAllStudiesPage: React.FC<ViewAllStudiesPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  const { clientID } = useParams();
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  //selectedClient is set based on the clientID in the url

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  const { hasPermission } = usePermissions();

  useEffect(() => {
    setLoading(true);
    const fetchClientsData = async () => {
      try {
        const jsonData = await fetchSingleClientByID(clientID as string);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText(["29f39e797ed3b6fa47732dacc7a5bf71"]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setSelectedClient(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
      }
      setLoading(false);
    };

    const promises = [fetchClientsData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
      });
  }, [clientID]);

  const navigate = useNavigate();

  const handleClientCardClick = (client: Client) => {
    // console.log(`You clicked on ${client.name} (${client.id})`);
    // navigate("/clients/" + client.id);
  };

  const handleBackClick = () => {
    navigate("/clients");
  };

  const [searchFilter, setSearchFilter] = useState<string>("");

  // console.log("selectedClient", selectedClient);

  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid" id="view_all_studies_page">
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container full_page">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : loadingError ? (
            <ErrorPage
              errorCode="500"
              error="Error fetching data"
              debugCode={loadingErrorText}
            />
          ) : selectedClient ? (
            /*
            If client is not null then show ClientCard, ClientInfo, DisplayStudies
            Else display message saying client not found
        */
            <>
              <div className="row">
                <div
                  id="studies_page_header_component"
                  className="col-12 client_page_header"
                >
                  <div className="d-flex">
                    <div>
                      <h1>Client</h1>
                    </div>
                    {/* Search filter */}
                    <div className="col d-flex justify-content-end search_box">
                      <div>
                        {(hasPermission("subject") ||
                          hasPermission("study", "write")) && (
                          <InformationTooltip
                            position="bottom"
                            widthSize="20px"
                            marginTop="5px"
                            tooltipText="You can create a new study for this client from here. This will open a wizard to allow custom setting for a new study."
                          />
                        )}
                      </div>

                      <div>
                        {(hasPermission("subject") ||
                          hasPermission("study", "write")) && (
                          <button
                            className="btn btn-primary"
                            onClick={() => navigate(`create-study`)}
                          >
                            Add Study
                          </button>
                        )}

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={searchFilter}
                          onChange={(e) => setSearchFilter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <ClientCard
                  client={selectedClient}
                  index={0}
                  onClick={handleClientCardClick}
                />
                <ClientInfo client={selectedClient} />
              </div>
              <div className="row">
                <DisplayStudies
                  handleBackClick={handleBackClick}
                  searchFilter={searchFilter}
                />
              </div>
            </>
          ) : (
            <div className="row">
              <ErrorPage
                errorCode="404"
                error="Client not found"
                debugCode={loadingErrorText}
              />
            </div>
          )}
        </div>
      </div>
      <SidebarComponent
        page="client"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={selectedClient ? selectedClient.id : null}
        currentClientLogo={selectedClient ? selectedClient.icon_url : null}
        currentClientName={selectedClient ? selectedClient.name : null}
      />
    </div>
  );
};

export default ViewAllStudiesPage;
