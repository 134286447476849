// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.study_nav_container {
  /* Ensure each item takes up an equal width to display them side by side */
}
.study_nav_container .navbar-nav {
  display: flex;
  flex-flow: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  overflow: hidden; /* Hide any items that exceed the max-height */
}
.study_nav_container .navbar-nav .nav-link {
  text-align: center;
}
.study_nav_container .nav-link {
  padding-right: 8px !important;
  padding-top: 0px !important;
}
.study_nav_container .show {
  display: flex !important;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  overflow: hidden; /* Hide any items that exceed the max-height */
}
.study_nav_container .is_active {
  background-color: #8cc2e9;
}
@media screen and (max-width: 991px) {
  .study_nav_container .nav-link {
    padding-right: 5px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/infrastructure/StudyNavbar/studyNavbar.scss"],"names":[],"mappings":"AAEA;EAUE,0EAAA;AAVF;AACE;EACE,aAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA,EAAA,8CAAA;AACJ;AAGE;EAEE,kBAAA;AAFJ;AAKE;EACE,6BAAA;EACA,2BAAA;AAHJ;AAME;EACE,wBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA,EAAA,8CAAA;AAJJ;AAOE;EACE,yBAAA;AALJ;AAQE;EACE;IACE,6BAAA;EANJ;AACF","sourcesContent":["@use \"../../global.scss\" as *;\n\n.study_nav_container {\n  .navbar-nav {\n    display: flex;\n    flex-flow: wrap;\n    padding-left: 0;\n    margin-bottom: 0;\n    list-style: none;\n    overflow: hidden; /* Hide any items that exceed the max-height */\n  }\n\n  /* Ensure each item takes up an equal width to display them side by side */\n  .navbar-nav .nav-link {\n    // max-width: 50%;\n    text-align: center;\n  }\n\n  .nav-link {\n    padding-right: 8px !important;\n    padding-top: 0px !important;\n  }\n\n  .show {\n    display: flex !important;\n    flex-wrap: wrap;\n    padding-left: 0;\n    margin-bottom: 0;\n    list-style: none;\n    overflow: hidden; /* Hide any items that exceed the max-height */\n  }\n\n  .is_active {\n    background-color: #8cc2e9;\n  }\n\n  @media screen and (max-width: 991px) {\n    .nav-link {\n      padding-right: 5px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
