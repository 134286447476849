//This is the same as rewards
import React from "react";
import { useParams } from "react-router-dom";
import { Earned } from "../../../components/Rewards/Earned/earned.component";
import SidebarComponent from "../../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../../types";
import "./rewards.scss";

interface RewardsPageProps extends SidebarProps {}

const RewardsPage: React.FC<RewardsPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page,
  currentClientName,
  currentClientLogo
}) => {
  const { clientID } = useParams();
  const [tab, setTab] = React.useState<string>("rewards");

  const handleTab = (tab: string) => {
    setTab(tab);
  };

  const renderTab = () => {
    switch (tab) {
      case "rewards":
        return <Earned source={"client"} />;
      case "redeem":
        return <div>redeem</div>;
      case "history":
        return <div>history</div>;
      default:
        return null;
    }
  };

  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            <div id="tags_page" className="container-fluid">
              <h1>
                {currentClientName ? currentClientName : "No Client Found"}:
                Rewards
              </h1>
              <div className="tab-container">
                <div className="tab-header">
                  <div
                    className={`tab-header-item ${tab === "rewards" ? "tab-header-active" : ""}`}
                    onClick={() => handleTab("rewards")}
                  >
                    <h5 className="tab-title">Earned</h5>
                  </div>
                  {/* TODO Uncomment these when we finish */}
                  {/* <div className={`tab-header-item ${tab === "edit" ? "tab-header-active" : ""}`} onClick={() => handleTab("edit")}>
          <h5 className="tab-title">Bulk Edit</h5>
        </div>
        <div className={`tab-header-item ${tab === "voucher" ? "tab-header-active" : ""}`} onClick={() => handleTab("voucher")}>
          <h5 className="tab-title">History</h5>
        </div> */}
                </div>
                <div className="tab-content">{renderTab()}</div>
              </div>
            </div>
          </div>
        </div>
        <SidebarComponent
          page="rewards"
          sidebarPinned={sidebarPinned}
          onSidebarPinChange={onSidebarPinChange}
          currentClient={clientID}
          currentClientLogo={currentClientLogo}
        />
      </div>
    </div>
  );
};

export default RewardsPage;
