import React, { useEffect, useRef, useState } from "react";
import { capitalize, useOutsideClick } from "../../../utilities/utils";

interface BasicStringFilterProps {
  jsonData: string[];
  onFilterChange: (vals: string[]) => void;
  filtersCleared: boolean;
  location: string;
  name: string;
  includeSearch?: boolean;
}

const BasicStringFilter: React.FC<BasicStringFilterProps> = ({
  jsonData,
  onFilterChange,
  filtersCleared,
  location,
  name,
  includeSearch
}) => {
  const [filterSearch, setFilterSearch] = useState<string>("");
  const [dropdown, setDropdown] = useState(false);
  const [filterValues, setFilterValues] = useState<Record<string, boolean>>({});

  const handleDropdown = (show: boolean) => {
    // show/hide dropdown
    setDropdown(show);
  };

  const handleSearchFilter = (search: string) => {
    // set search value
    setFilterSearch(search);
  };

  const handleFilter = (val: string, checked: boolean) => {
    // set vals filter
    setFilterValues((prevValues) => ({
      ...prevValues,
      [val]: checked // set checked status for this val
    }));
  };

  useEffect(() => {
    // send vals filter to parent component
    onFilterChange(
      Object.keys(filterValues).filter(
        (val) => filterValues[val] // filter vals that are true
      )
    );
  }, [filterValues, onFilterChange]);

  useEffect(() => {
    // clear vals filter when filters are cleared
    if (filtersCleared) {
      setFilterSearch("");
      setFilterValues({});
      handleSearchFilter("");
      // handleDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handleDropdown(false);
  });

  return (
    <div className="dropdown dropdown_string_vals " ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleDropdown(!dropdown)}
      >
        {name}
      </button>
      <div className={`dropdown-menu ${dropdown ? "show" : "hide"}`}>
        {jsonData && jsonData.length === 0 ? (
          <span>No {name.toLowerCase()}s found</span>
        ) : (
          <>
            {includeSearch && ( // if includeSearch is true, render search input
              <input
                type="text"
                placeholder={`Search ${name}`}
                value={filterSearch}
                onChange={(e) => handleSearchFilter(e.target.value)}
              />
            )}

            {
              /* filter by valsfilter set by input */
              /* loop through all vals */
              jsonData.map((val: string, index: number) => {
                if (val.toLowerCase().includes(filterSearch.toLowerCase())) {
                  return (
                    <div
                      key={`search_${val}_${index}`}
                      className="dropdown_menu_item"
                    >
                      <label
                        htmlFor={`${location}_${val}`}
                        className="form_control"
                      >
                        <input
                          type="checkbox"
                          id={`${location}_${val}`}
                          name={val}
                          value={val}
                          checked={filterValues[val] || false} // use checked property here
                          onChange={(e) =>
                            handleFilter(e.target.value, e.target.checked)
                          }
                        />
                        {capitalize(val)}
                      </label>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            }
          </>
        )}
      </div>
    </div>
  );
};

export default BasicStringFilter;
