import { useEffect, useState } from "react";
import { Country } from "../../types";
import { getSystemConfigCountries } from "../../utilities/config.util";
import "./countriesDisplay.scss";

const CountriesDisplayPage = () => {
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  useEffect(() => {
    // fetch countries data on component mount

    const fetchTLCData = async () => {
      try {
        const jsonData = await getSystemConfigCountries();
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching countries");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);

          setCountries(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };

    const promises = [fetchTLCData()];
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingCountries(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingCountries(false);
        setLoadingError(true);
      });
  }, []);

  return (
    // if not loading
    !loadingCountries ? (
      loadingError ? (
        <div id="language_table_page" className="container">
          <h1>Error loading data</h1>
        </div>
      ) : (
        <>
          <div id="language_table_page" className="container">
            <h1>Countries</h1>

            <div className="row">
              {countries.length === 0 ? (
                <p>No countries found in config. Please contact support</p>
              ) : (
                <>
                  {countries.map((language: Country, index: number) => (
                    <div
                      className="col-12 col-md-6 col-xl-4 row_item"
                      key={index}
                    >
                      <div className="row">
                        <div className="col-3 text-end">
                          {language.iso_code}
                        </div>
                        <div className="col-9">{language.name}</div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <br />
          </div>
        </>
      )
    ) : (
      // if loading
      <div className="d-flex justify-content-center custom_spinner_container full_page">
        <div className="spinner-border" role="status"></div>
      </div>
    )
  );
};

export default CountriesDisplayPage;
