import React, { useEffect, useState } from "react";
import { ParticipantHistory, Timezone } from "../../types";
import { getResearcherConfigTimezone } from "../../utilities/config.util";
import { convertTime, formatDate } from "../../utilities/utils";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation";
import TimezoneSwitcher from "../Tables/TimeZoneSwitcher/timezoneSwitcher";

type ViewParticipantModalHistoryProps = {
  history: ParticipantHistory;
  participantTimeZone: string;
  allTimezones: Timezone[];
};

const ViewParticipantModalHistory: React.FC<
  ViewParticipantModalHistoryProps
> = ({ history, participantTimeZone, allTimezones }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [historyPerPage, setHistoryPerPage] = useState(10);
  const [currentHistory, setCurrentHistory] = useState<ParticipantHistory>([]);

  // Timezone Switcher
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone | null>(
    null
  );

  // User can switch between UTC, User timezone, and participant's timezone
  const [timezone, setTimezone] = useState<string>("ResearcherTimezone");

  const handleChangeTimezone = (timezone: string) => {
    setTimezone(timezone);
  };

  // Get researcher timezone
  useEffect(() => {
    getResearcherConfigTimezone().then((result) => {
      if (result) {
        setResearcherTimezone(result);
      } else {
        setResearcherTimezone(null);
      }
    });
  }, []);

  useEffect(() => {
    // console.log(currentParticipants, filteredParticipants);
    if (history && history.length > 0) {
      setCurrentHistory(
        history.slice(
          (currentPage - 1) * historyPerPage,
          currentPage * historyPerPage
        )
      );
    }
  }, [currentPage, historyPerPage, history]);

  return (
    <>
      <div className="modal-body">
        {!history ||
        history.length === 0 ||
        history === undefined ||
        history === null ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <p className="text-center">No history found</p>
              </div>
            </div>
          </div>
        ) : (
          // table with two columns
          <>
            <TimezoneSwitcher
              timezone={timezone}
              researcherTimezone={researcherTimezone}
              handleChangeTimezone={handleChangeTimezone}
              tableType={"ParticipantHistory"}
            />
            <div className="table-responsive mb-2">
              <table className="cust_table table">
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentHistory?.map((history, index) => {
                    let timezoneOffset = 0;
                    // get participant's timezone offset based off of their timezone, filter though allTimezones
                    const participant_offset = allTimezones.find(
                      (timezone) => timezone.name === participantTimeZone
                    )?.offset;

                    if (
                      timezone === "Participant's Timezone" &&
                      participant_offset
                    ) {
                      timezoneOffset = Number(participant_offset);
                    } else if (timezone === "UTC") {
                      timezoneOffset = 0;
                    } else if (timezone === "ResearcherTimezone") {
                      timezoneOffset = researcherTimezone
                        ? researcherTimezone.offset
                        : 0;
                    }

                    return (
                      <tr key={index}>
                        <td>
                          {history.dateAndTime
                            ? formatDate(
                                convertTime(history.dateAndTime, timezoneOffset)
                              )
                            : "N/A"}
                        </td>
                        <td>{history.Action}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
        <PaginationNavigation
          currentPage={currentPage}
          totalResults={history ? history.length : 0}
          resultsPerPage={historyPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default ViewParticipantModalHistory;
