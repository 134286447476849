import React from "react";
import "./channels.scss";

export type SingleChannelProps = {
  // studyClicked: string;
  isHovered: boolean;
  isSelected?: boolean;
  channelId: string;
  handleChannelClick: (channelId: string, channelType: string) => void;
  channelName: string;
  channelType: string;
  channelUnread: number | 0;
  imageSrc: string;
};

const SingleChannel: React.FC<SingleChannelProps> = ({
  isHovered,
  isSelected,
  channelId,
  handleChannelClick,
  channelName,
  channelType,
  channelUnread,
  imageSrc
}) => {
  // replace channelType spaces with underscore
  const channelTypeCSS = channelType?.split(" ").join("_").toLowerCase();
  return (
    <>
      <div
        key={channelId}
        className={`single_channel_container ${
          isSelected ? "container_selected" : ""
        }`}
        // on hover display right side
        onClick={() => {
          handleChannelClick(channelId, channelType);
        }}
      >
        {/* {channelUnread > 0 && (
						<span className="position-absolute badge rounded-pill bg-danger m-2 channel-unread">
							{channelUnread}
							<span className="visually-hidden">
								unread messages
							</span>
						</span>
					)}{" "} */}
        <div
          className={`channel_left_side ${isSelected ? "selected" : ""}`}
          style={{
            backgroundImage: `url(${imageSrc})`
          }}
        ></div>
        <div className={`channel_right_side ${isHovered ? "hovered" : ""}`}>
          {/* TODO: get study name from db */}
          <div className="channel_right_side_title">{channelName}</div>

          {/* TODO: check for type from db */}
          <div className={`channel_right_side_chat_type ${channelTypeCSS}`}>
            {channelType}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleChannel;
