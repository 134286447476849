import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  addParticipantToStudy,
  addParticipantToSurvey,
  fetchAllParticipantsByID
} from "../../models/participant.model";
import {
  Country,
  FetchResponseError,
  Language,
  Participant,
  Tags
} from "../../types";
import "./addParticipantsModal.scss";

import Swal from "sweetalert2";
import { IconBroom } from "../../assets/images/icons/icons";
import { isFetchResponseError } from "../../utilities/utils";
import CountryFilter from "../Tables/Filters/filterCountries.component";
import LanguagesFilter from "../Tables/Filters/filterLanguages.component";
import MultipleParticipantIDsFilter from "../Tables/Filters/filterMultipleParticipantIDs.component";
import Search from "../Tables/Filters/filterSearch.component";
import TagsFilter from "../Tables/Filters/filterTags.component";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation.tsx";

interface AddParticipantsModalProps {
  closeModal: () => void;
  shown: boolean;
  tableType: string;
  languages: Language[];
  countries: Country[];
  setUserAdded: (userAdded: boolean) => void;
}

const AddParticipantsModal: React.FC<AddParticipantsModalProps> = ({
  closeModal,
  shown,
  tableType,
  languages,
  countries,
  setUserAdded
}) => {
  const { clientID, studyID, surveyID } = useParams(); // Get from URL

  const [error, setError] = useState<FetchResponseError | null>({
    errorCode: "false",
    errorMessage: ""
  });

  // For loading spinner
  const [busySubmitting, setBusySubmitting] = useState(false);
  const [dots, setDots] = useState(".");
  const [loadingParticipants, setLoadingParticipants] = useState(false);

  // BE response
  const [response, setResponse] = useState(false);
  const [message, setMessage] = useState("");

  // Participant data
  const [originalParticipants, setOriginalParticipants] = useState<
    Participant[]
  >([]);

  // ==================================== FILTERS ====================================
  const [participantsTagsFilter, setParticipantsTagsFilter] = useState<
    string[]
  >([]);

  const [searchedMultiParticipantsIDs, setSearchedMultiParticipantsIDs] =
    useState<string[]>([]);

  const [participantsAddLanguagesFilter, setParticipantsAddLanguagesFilter] =
    useState<string[]>([]);

  const [participantsAddCountryFilter, setParticipantsAddCountryFilter] =
    useState<string[]>([]);

  // State variable to hold the filtered participants
  const [filteredAddParticipants, setFilteredAddParticipants] = useState<
    Participant[]
  >([]);
  // Input For searching participants
  const [participantsSearch, setParticipantsSearch] = useState<string>("");

  // store current state of filters
  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  // For selecting participants
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  // const participantsPerPage = 20;
  const [participantsPerPage, setParticipantsPerPage] = useState(20);

  const [participantsLanguages, setParticipantsLanguages] = useState<
    Language[]
  >([]);
  const [participantsCountries, setParticipantsCountries] = useState<Country[]>(
    []
  );
  const [participantsTags, setParticipantsTags] = useState<Tags[]>([]);

  // Select Single Participant
  const handleCheckboxChange = (participantID: string) => {
    setSelectedParticipants((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(participantID);

      if (isAlreadySelected) {
        // If the participantID is already selected, remove it from the array
        return prevSelected.filter((id) => id !== participantID);
      } else {
        // If the participantID is not selected, add it to the array
        return [...prevSelected, participantID];
      }
    });
  };

  const handleClearFilters = () => {
    // Clear the filter state
    setParticipantsSearch("");
    setFilterApplied(!filterApplied);
    setParticipantsTagsFilter([]);
    // setParticipantsStatusFilter([]);
    // setParticipantsUDIDFilter(false);
    setParticipantsAddLanguagesFilter([]);
    setParticipantsAddCountryFilter([]);
    setSearchedMultiParticipantsIDs([]);
  };

  // Select all participants
  const handleSelectAllCheckboxChange = () => {
    const allParticipantsSelected =
      selectedParticipants.length === filteredAddParticipants.length;

    if (allParticipantsSelected) {
      // If all participants are already selected, remove them from the array
      setSelectedParticipants([]);
    } else {
      // If not all participants are selected, add them to the array
      setSelectedParticipants(filteredAddParticipants.map((p) => p.id));
    }
  };

  const [currentParticipants, setCurrentParticipants] = useState<Participant[]>(
    []
  );

  // Adding participants to a study
  const handleAddParticipantToStudy = async () => {
    try {
      if (clientID && clientID !== "" && clientID !== "undefined" && studyID) {
        // console.log(clientID, studyID, selectedParticipants);

        // Adding participant to study
        const result = await addParticipantToStudy(
          clientID,
          selectedParticipants,
          studyID
        );

        // Result is a string if there was an error
        // console.log(result);

        // Set response to true, to display the response message
        setBusySubmitting(false);

        if (result.rStatus !== "success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        }
      } else {
        console.log("clientID is undefined");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSingleParticipantAddToStudy = async (participant: any[]) => {
  //   try {
  //     if (clientID && clientID !== "" && clientID !== "undefined" && studyID) {
  //       console.log(clientID, studyID, selectedParticipants);
  //       const result = await addParticipantToStudy(
  //         clientID,
  //         participant,
  //         studyID
  //       );
  //       console.log(result);
  //       if (result) {
  //         setResponse(true);
  //         console.log(result.message);
  //         setMessage(result.message);

  //         // Will fetch new data when modal is closed (displayParticipants.component)
  //         if (result.success === true) {
  //           setUserAdded(true);
  //         }

  //         // Clear selection and fetch new data
  //         setSelectedParticipants([]);
  //         fetchAllClientParticipantsNotInStudy();
  //       }
  //     } else {
  //       console.log("clientID is undefined");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // Adding participants to a study
  const handleAddParticipantToSurvey = async () => {
    // console.log("CLICKED");
    try {
      if (
        clientID &&
        clientID !== "" &&
        clientID !== "undefined" &&
        studyID &&
        surveyID
      ) {
        // console.log(clientID, studyID, surveyID, selectedParticipants);
        const result = await addParticipantToSurvey(
          clientID,
          studyID,
          surveyID,
          selectedParticipants
        );
        //   console.log(result);
        //   if (result) {
        //     setResponse(true);
        //     console.log(result.message);
        //     setMessage(result.message);
        //     setSelectedParticipants([]);
        //     fetchAllStudyParticipantsNotInSurvey();

        //     if (result.success === true) {
        //       setUserAdded(true);
        //     }
        //   }
        //
        setBusySubmitting(false);

        // console.log(result, "result");

        if (result.rStatus !== "success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        }
      } else {
        console.log("clientID is undefined");
        setMessage("Failed to Send");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSingleParticipantAddToSurvey = async (participant: any[]) => {
  //   try {
  //     if (
  //       clientID &&
  //       clientID !== "" &&
  //       clientID !== "undefined" &&
  //       studyID &&
  //       surveyID
  //     ) {
  //       console.log(clientID, studyID, selectedParticipants);
  //       const result = await addParticipantToSurvey(
  //         clientID,
  //         studyID,
  //         surveyID,
  //         participant
  //       );
  //       console.log(result);
  //       if (result) {
  //         setResponse(true);
  //         setMessage(result.message);

  //         // Will fetch new data when modal is closed (displayParticipants.component)
  //         if (result.success === true) {
  //           console.log("SUCCESS");
  //           setUserAdded(true);
  //         }

  //         // Clear selection and fetch new data
  //         setSelectedParticipants([]);
  //         fetchAllStudyParticipantsNotInSurvey();
  //       }
  //     } else {
  //       console.log("clientID is undefined");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleAddParticipant = async () => {
    if (selectedParticipants.length > 0) {
      const currectWorkerID = localStorage.getItem("workerID");
      if (
        currectWorkerID !== null &&
        currectWorkerID !== undefined &&
        currectWorkerID !== ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
      } else {
        if (tableType === "StudyParticipants") {
          handleAddParticipantToStudy();
        } else if (
          tableType === "SurveyParticipants" ||
          tableType === "DiaryParticipants"
        ) {
          handleAddParticipantToSurvey();
        } else {
          console.log(tableType);
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "Please contact support. <br/> Unknown table type.",
            confirmButtonColor: "#3085d6"
          });
        }
      }
    }
  };

  // const handleAddSingleParticipant = async (participantId: any[]) => {
  //   console.log("CLICKED");
  //   console.log(surveyID);
  //   if (surveyID) {
  //     console.log("WE HERE");
  //     handleSingleParticipantAddToSurvey(participantId);
  //   } else {
  //     handleSingleParticipantAddToStudy(participantId);
  //   }
  // };

  // const onCountryFilterChangeAddParticipants = (countries: string[]) => {
  //   setParticipantsAddCountryFilter(countries);
  // };

  // const onLanguageFilterChangeAddParticipants = (languages: string[]) => {
  //   setParticipantsAddLanguagesFilter(languages); // it should update languages filter, not country filter.
  // };

  useEffect(() => {
    // console.log("selectedParticipants", selectedParticipants);
  }, [selectedParticipants]);

  // Fetch all participants, not in study
  const fetchAllClientParticipantsNotInStudy = async () => {
    // Fetch participants data from database
    // console.log("Fetching participants data...");
    setLoadingParticipants(true);
    if (!clientID || clientID === "" || clientID === "undefined") {
      console.log("clientID is undefined");
      alert("clientID is undefined");
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please contact support. <br/> Unknown client ID.",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    try {
      // console.log("Trying to fetch participants data...");
      const jsonData = await fetchAllParticipantsByID(
        "StudyParticipants", // Type of participants
        clientID || "", // Which client ID
        true, // force new data?
        studyID,
        undefined, // No survey ID
        true // Must not be in study
      );
      // console.log(jsonData);
      if (isFetchResponseError(jsonData)) {
        console.log("error Status: " + jsonData.errorCode);
        console.log("error Message: " + jsonData.errorMessage);
        setError(jsonData);
      } else {
        setOriginalParticipants(jsonData.participants);
        setFilteredAddParticipants(jsonData.participants);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching participants data:",
        error
      );
    }
    setLoadingParticipants(false);
  };

  // Fetch all participants, not in survey
  const fetchAllStudyParticipantsNotInSurvey = async () => {
    // Fetch participants data from database
    // console.log("Fetching participants data...");
    setLoadingParticipants(true);
    if (!clientID || clientID === "" || clientID === "undefined") {
      console.log("clientID is undefined");
      alert("clientID is undefined");
    }

    try {
      // console.log("Trying to fetch participants data...");
      const jsonData = await fetchAllParticipantsByID(
        "SurveyParticipants", // Type of participants
        clientID || "", // Which client ID
        true, // Force new data
        studyID, // Which study?
        surveyID, // Which survey?
        true // Must not be in survey
      );
      // console.log(jsonData);
      if (isFetchResponseError(jsonData)) {
        console.log("error Status: " + jsonData.errorCode);
        console.log("error Message: " + jsonData.errorMessage);
        setError(jsonData);
      } else {
        setOriginalParticipants(jsonData.participants);
        setFilteredAddParticipants(jsonData.participants);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching participants data:",
        error
      );
    }
    setLoadingParticipants(false);
  };

  // Fetch depending on whether we are on the study or survey "page"
  useEffect(() => {
    // initial fetch of data
    // console.log("HIT");
    if (tableType === "StudyParticipants") {
      fetchAllClientParticipantsNotInStudy();
    } else if (
      tableType === "SurveyParticipants" ||
      tableType === "DiaryParticipants"
    ) {
      fetchAllStudyParticipantsNotInSurvey();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please contact support. <br/> Unknown table type.",
        confirmButtonColor: "#3085d6"
      });
    }
    setSelectedParticipants([]);
    /////////////////////////////////////////////////////////////////////////// PUT ME BACK
    // TODO: PUT EM BAAACK
  }, [tableType, clientID, studyID, surveyID]);

  // Use effect that applies filters to the participants, related to the mapping
  useEffect(() => {
    // Filter participants based on filters
    let filteredParticipants = originalParticipants;

    // Filter by tags
    if (participantsTagsFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        participantsTagsFilter.some((tag) =>
          participant.participant_tags?.includes(tag)
        )
      );
    }

    // Filter by languages
    if (participantsAddLanguagesFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        participantsAddLanguagesFilter.some((language) =>
          participant.participant_lang_iso.includes(language)
        )
      );
    }

    // Filter by countries
    if (participantsAddCountryFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        participantsAddCountryFilter.some((country) =>
          participant.participant_country_iso.includes(country)
        )
      );
    }

    // Filter by search
    if (participantsSearch !== "") {
      filteredParticipants = filteredParticipants.filter(
        (participant) =>
          participant.participant_name
            ?.toLowerCase()
            .includes(participantsSearch.toLowerCase()) ||
          participant.participant_surname
            ?.toLowerCase()
            .includes(participantsSearch.toLowerCase()) ||
          participant.participant_email
            ?.toLowerCase()
            .includes(participantsSearch.toLowerCase()) ||
          (participant.participant_name + " " + participant.participant_surname)
            ?.toLowerCase()
            .includes(participantsSearch.toLowerCase())
      );
    }

    // Filter by multiple participant IDs
    if (searchedMultiParticipantsIDs.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        searchedMultiParticipantsIDs.some((id) =>
          participant.id.toLowerCase().includes(id.toLowerCase())
        )
      );
    }

    // Set filtered participants
    setFilteredAddParticipants(filteredParticipants);
  }, [
    originalParticipants,
    participantsTagsFilter,
    participantsAddLanguagesFilter,
    participantsAddCountryFilter,
    participantsSearch,
    searchedMultiParticipantsIDs
  ]);

  // Close modal and reset states
  const handleClose = () => {
    closeModal();
    setSelectedParticipants([]);
    setMessage("");
    if (tableType === "StudyParticipants") {
      fetchAllClientParticipantsNotInStudy();
    } else if (
      tableType === "SurveyParticipants" ||
      tableType === "DiaryParticipants"
    ) {
      fetchAllStudyParticipantsNotInSurvey();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please contact support. <br/> Unknown table type.",
        confirmButtonColor: "#3085d6"
      });
    }
  };

  // const handleChangeRows = (rows: number) => {
  //   setParticipantsPerPage(rows);
  // };

  useEffect(() => {
    // console.log(currentParticipants, filteredParticipants);
    if (filteredAddParticipants && filteredAddParticipants.length > 0) {
      setCurrentParticipants(
        filteredAddParticipants.slice(
          (currentPage - 1) * participantsPerPage,
          currentPage * participantsPerPage
        )
      );
    }
  }, [currentPage, participantsPerPage, filteredAddParticipants]);

  useEffect(() => {
    // get all unique participants languages
    const pLanguages = [
      ...new Set(
        originalParticipants
          .map((p: Participant) => p.participant_lang_iso)
          .filter((lang): lang is string => Boolean(lang))
      )
    ].sort();
    setParticipantsLanguages(
      pLanguages.map((lang) => {
        const pLanguage = languages.find((l: Language) => l.iso_code === lang);
        if (pLanguage) {
          return pLanguage;
        }
        return { iso_code: lang, name: lang } as Language;
      })
    );
  }, [originalParticipants, languages]);

  useEffect(() => {
    // get all unique participants countries
    const pCountries = [
      ...new Set(
        originalParticipants
          .map((p: Participant) => p.participant_country_iso)
          .filter((country): country is string => Boolean(country))
      )
    ].sort();
    setParticipantsCountries(
      pCountries.map((country) => {
        const pCountry = countries.find((c: Country) => c.iso_code === country);
        if (pCountry) {
          return pCountry;
        }
        return {
          iso_code: country,
          name: country,
          country_code: country
        } as Country;
      })
    );
  }, [originalParticipants, countries]);

  useEffect(() => {
    // get all unique participants Tags
    const pTags: string[] = [
      ...new Set(
        originalParticipants
          .map((p) => p.participant_tags)
          .filter((tags): tags is string[] => Boolean(tags))
          .flat()
      )
    ].sort();
    setParticipantsTags(
      pTags.map((tag) => {
        return { id: tag, name: tag };
      })
    );
  }, [originalParticipants]);

  return (
    <div className={`modal add_participants_modal ${shown ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          {/* HEADER */}
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <h3 className="modal_page_header">
                  Add Participants to{" "}
                  {tableType === "StudyParticipants"
                    ? "Study"
                    : tableType === "SurveyParticipants" ||
                        tableType === "DiaryParticipants"
                      ? "Survey"
                      : `Unknown ${tableType}`}
                </h3>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container-fluid modal_body_content card">
              {/* DROP DOWN ROWS */}
              <div className="row card-header dropdown_container">
                <div className="col language_drop_container">
                  <LanguagesFilter
                    jsonlanguagesData={participantsLanguages}
                    onLanguagesFilterChange={setParticipantsAddLanguagesFilter}
                    filtersCleared={!filterApplied}
                    location={"modal"}
                  />
                </div>
                <div className="col country_drop_container">
                  <CountryFilter
                    jsonCountriesData={participantsCountries}
                    onCountryFilterChange={setParticipantsAddCountryFilter}
                    filtersCleared={!filterApplied}
                    location={"modal"}
                  />
                </div>

                {/* <div>Groups</div> */}

                <div className="tags_drop_container col">
                  <TagsFilter
                    jsonTagsData={participantsTags}
                    onTagsFilterChange={setParticipantsTagsFilter}
                    filtersCleared={!filterApplied}
                    location={"modal"}
                  />
                </div>

                <div className="tags_drop_container col">
                  <MultipleParticipantIDsFilter
                    jsonData={searchedMultiParticipantsIDs}
                    onFilterChange={setSearchedMultiParticipantsIDs}
                    filtersCleared={!filterApplied}
                    location={"modal"}
                    name="Participant IDs"
                  />
                </div>
                <div className="tags_keyword_container">
                  {/* Keyword Search, Clear Filters */}
                  <div className="keyword_clear_container col">
                    <Search
                      onSearchChange={setParticipantsSearch}
                      filtersCleared={!filterApplied}
                      // location={"modal"}
                    />
                    {/* Clear Filters */}
                    <button
                      className="btn btn-primary ms-2 btn_clear_filters"
                      disabled={false}
                      onClick={handleClearFilters}
                    >
                      <IconBroom className="icon icon_white" />
                    </button>
                  </div>
                </div>

                <div className="p-0">
                  <div className="row card-header amount_container justify-content-between">
                    <div className="col-1 d-flex align-items-center p-0">
                      <div className="checkbox_select">
                        {/* SELECT ALL */}
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          onChange={handleSelectAllCheckboxChange}
                          checked={
                            selectedParticipants.length ===
                              originalParticipants.length &&
                            originalParticipants.length > 0
                          }
                        />
                      </div>
                      <div className="ps-1">
                        {selectedParticipants.length > 0
                          ? selectedParticipants.length
                          : ""}
                      </div>
                    </div>
                    <div className="col-5 d-flex">
                      <div className="message">{message ? message : <></>}</div>
                    </div>
                    <div className="col-auto d-flex justify-content-end p-0">
                      {/* <TableRowsSelector
                        handleChangeRows={handleChangeRows}
                        rowsPerPage={participantsPerPage}
                        tableName="Leads"
                        arrayRowSelector={[10, 20, 50, 100]}
                      /> */}
                      {selectedParticipants.length !== 0 && (
                        <button
                          className={`add_button ${
                            selectedParticipants.length === 0 && "disabled"
                          }`}
                          onClick={() => handleAddParticipant()}
                        >
                          Add{" "}
                          {selectedParticipants.length ===
                          originalParticipants.length
                            ? "All "
                            : selectedParticipants.length ===
                                filteredAddParticipants.length
                              ? "All Filtered "
                              : "Selected "}
                          Participants to {surveyID ? "Survey" : "Study"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* PARTICIPANT CONTAINER */}
              {loadingParticipants ? (
                <div className="row d-flex justify-content-center align-items-center py-1">
                  <div className="d-flex justify-content-center custom_spinner_container">
                    <div className="spinner-border" role="status"></div>
                  </div>
                </div>
              ) : originalParticipants.length === 0 ? (
                <div className="row d-flex justify-content-center align-items-center py-1">
                  No participants found
                </div>
              ) : (
                <div className="row">
                  <div className="add_participants_container">
                    {/* Iterate through original participants, displaying names */}
                    {filteredAddParticipants.length === 0 ? (
                      <div className="row d-flex justify-content-center align-items-center py-1">
                        No results found. Please adjust your filters.
                      </div>
                    ) : (
                      <>
                        {currentParticipants.map((participant) => (
                          <div
                            key={participant.id.toString()}
                            className="row d-flex justify-content-between align-items-center add_participant_single"
                          >
                            <div className="col-5 d-flex">
                              <input
                                className="checkbox_select"
                                type="checkbox"
                                name=""
                                id=""
                                onChange={() => {
                                  handleCheckboxChange(participant.id);
                                }}
                                checked={selectedParticipants.includes(
                                  participant.id
                                )}
                              />
                              <div className="add_participant_name">
                                {participant.id}
                                {" | "}
                                {participant.participant_name}{" "}
                                {participant.participant_surname}
                              </div>
                            </div>
                            <div className="col-3 add_participant_name">
                              {participant.participant_email}
                            </div>
                            {/* COUNTRIES */}
                            <div className="col-2 d-flex ">
                              {
                                countries.find(
                                  (country) =>
                                    country.iso_code ===
                                    participant.participant_country_iso
                                )?.name
                              }{" "}
                            </div>
                            {/* LANGUAGES */}
                            <div className="col-2 d-flex">
                              {
                                languages.find(
                                  (language) =>
                                    language.iso_code ===
                                    participant.participant_lang_iso
                                )?.name
                              }
                            </div>
                          </div>
                        ))}
                        <PaginationNavigation
                          currentPage={currentPage}
                          totalResults={
                            filteredAddParticipants
                              ? filteredAddParticipants.length
                              : 0
                          }
                          resultsPerPage={participantsPerPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParticipantsModal;
