interface AddStudyWizardFooterProps {
  currentPage: number;
  totalPages: number;
  handleWizardBack: () => void;
  handleWizardForward: () => void;
  handleWizardSubmit: () => void;
  pageValid: boolean;
}

const AddStudyWizardFooter: React.FC<AddStudyWizardFooterProps> = ({
  currentPage,
  totalPages,
  handleWizardBack,
  handleWizardForward,
  handleWizardSubmit,
  pageValid
}) => {
  return (
    <div className="footer">
      {currentPage > 1 ? (
        <div className="mb-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleWizardBack()}
          >
            Back
          </button>
        </div>
      ) : (
        <div className="mb-3" style={{ visibility: "hidden" }}>
          <button className="btn btn-primary" type="button" disabled>
            Placeholder
          </button>
        </div>
      )}
      {pageValid !== false ? (
        <div className="mb-3">
          {currentPage < totalPages && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => handleWizardForward()}
            >
              {currentPage}/{totalPages} Next
            </button>
          )}
          {currentPage === totalPages && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => handleWizardSubmit()}
            >
              Submit
            </button>
          )}
        </div>
      ) : (
        <div className="mb-3">
          <button className="btn btn-primary" type="button" disabled>
            Check errors
          </button>
        </div>
      )}
    </div>
  );
};

export default AddStudyWizardFooter;
