// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#timezone_table_page .row_item {
  border-radius: 5px;
}
#timezone_table_page .row_item:hover {
  background-color: #f5f5f5;
}

#timezone_calculator input {
  padding: 0px;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: 1px rgba(128, 128, 128, 0.205) solid !important;
  border: none !important;
  box-shadow: none !important;
}
#timezone_calculator input,
#timezone_calculator select {
  border-radius: 5px;
  width: 100% !important;
  max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Timezones/timezones.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AACI;EACE,yBAAA;AACN;;AAKE;EACE,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,wDAAA;EACA,uBAAA;EACA,2BAAA;AAFJ;AAKE;;EAEE,kBAAA;EACA,sBAAA;EACA,gBAAA;AAHJ","sourcesContent":["#timezone_table_page {\n  .row_item {\n    border-radius: 5px;\n    &:hover {\n      background-color: #f5f5f5;\n    }\n  }\n}\n\n#timezone_calculator {\n  input {\n    padding: 0px;\n    padding-left: 10px;\n    margin-top: 5px;\n    margin-bottom: 5px;\n    outline: 1px rgba(128, 128, 128, 0.205) solid !important;\n    border: none !important;\n    box-shadow: none !important;\n  }\n\n  input,\n  select {\n    border-radius: 5px;\n    width: 100% !important;\n    max-width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
