// TODO: ELEVATE STATE, BUILD BIG OBJECT (CONDITION ACTION TIMING),

import React, { useEffect, useState } from "react";
import { AutomationRuleData, ChildConditionGroup } from "../../../../types";
import ConditionGroup from "./conditionGroup.component";
import "./conditions.scss";

export type ConditionProps = {
  childConditionGroups: ChildConditionGroup;
  setChildConditionGroups: React.Dispatch<
    React.SetStateAction<ChildConditionGroup>
  >;
  dropdownRuleData: AutomationRuleData;
  editable: boolean;

  viewEditRule: boolean;
};

const Condition: React.FC<ConditionProps> = ({
  childConditionGroups,
  setChildConditionGroups,
  dropdownRuleData,
  editable,
  viewEditRule
}) => {
  useEffect(() => {
    console.log(childConditionGroups);
    const childRules = childConditionGroups["0"].childRules;
    console.log("childRules:", childRules);
  }, [childConditionGroups]);

  // ================== GROUP HANDLERS ==================
  const [nextGroupId, setNextGroupId] = useState(1);

  const addGroupToId = (parentGroupId: string) => {
    console.log("Trying to add to Group ID:", parentGroupId);
    const newGroupId = nextGroupId.toString();
    const newRuleId = nextRuleId.toString(); // Prepare the rule ID outside of the state update

    setChildConditionGroups((prev) => {
      const groups = { ...prev }; // Shallow copy to avoid mutation
      const addChildToGroup = (
        currentGroup: ChildConditionGroup,
        parentId: string
      ) => {
        for (let groupId in currentGroup) {
          if (groupId === parentId) {
            // Add the new group and also a rule to it in the same step
            currentGroup[groupId as string].childConditionGroups[newGroupId] = {
              operator: "and",
              childRules: [
                {
                  id: newRuleId,
                  type: "",
                  variable: "",
                  selection: "",
                  options: "",
                  value: ""
                }
              ],
              childConditionGroups: {}
            };
            return;
          } else if (
            Object.keys(currentGroup[groupId].childConditionGroups).length > 0
          ) {
            console.log("nested");
            addChildToGroup(
              currentGroup[groupId as string].childConditionGroups,
              parentId
            );
          }
        }
      };
      addChildToGroup(groups, parentGroupId);
      return groups;
    });

    setNextGroupId((prev) => prev + 1);
    setNextRuleId((prev) => prev + 1);
  };

  const deleteGroup = (id: string) => {
    setChildConditionGroups((prev) => {
      const newState = JSON.parse(JSON.stringify(prev)); // Deep copy to avoid mutating state directly

      function recursiveDelete(groupId: string, groups: ChildConditionGroup) {
        if (groups[groupId]) {
          delete groups[groupId];
          return true;
        }
        for (let key in groups) {
          if (recursiveDelete(groupId, groups[key].childConditionGroups)) {
            return true;
          }
        }
        return false;
      }

      recursiveDelete(id, newState);
      return newState;
    });
  };

  // AND/OR OPERATOR HANDLERS (GROUP)
  const handleConditionOperator = (
    conditionGroupID: string,
    operator: string
  ) => {
    setChildConditionGroups((prev) => {
      const updateOperator = (
        groups: ChildConditionGroup,
        targetId: string
      ): ChildConditionGroup => {
        for (let groupId in groups) {
          if (groupId === targetId) {
            groups[groupId].operator = operator;
            return groups; // Exit early once you find the group
          } else if (
            Object.keys(groups[groupId].childConditionGroups).length > 0
          ) {
            groups[groupId].childConditionGroups = updateOperator(
              groups[groupId].childConditionGroups,
              targetId
            );
          }
        }
        return groups;
      };

      return updateOperator(JSON.parse(JSON.stringify(prev)), conditionGroupID);
    });
  };

  // ================== RULE HANDLERS ==================
  const [nextRuleId, setNextRuleId] = useState(1);
  const addRule = (conditionGroupID: string) => {
    console.log("clicked");
    const newRule = {
      id: nextRuleId.toString(),
      type: "",
      variable: "",
      selection: "",
      options: "",
      value: ""
    };

    setChildConditionGroups((prev) => {
      const addChildRule = (
        groups: ChildConditionGroup,
        targetId: string
      ): ChildConditionGroup => {
        for (let groupId in groups) {
          if (groupId === targetId) {
            groups[groupId].childRules.push(newRule);
            return groups; // Exit early once you find the group
          } else if (
            Object.keys(groups[groupId].childConditionGroups).length > 0
          ) {
            groups[groupId].childConditionGroups = addChildRule(
              groups[groupId].childConditionGroups,
              targetId
            );
          }
        }
        return groups;
      };

      return addChildRule(JSON.parse(JSON.stringify(prev)), conditionGroupID);
    });
    setNextRuleId((prev) => prev + 1);
  };

  const removeConditionRule = (conditionGroupID: string, ruleId: string) => {
    setChildConditionGroups((prev) => {
      const removeRule = (
        groups: ChildConditionGroup,
        targetId: string
      ): ChildConditionGroup => {
        for (let groupId in groups) {
          if (groupId === targetId) {
            groups[groupId].childRules = groups[groupId].childRules.filter(
              (rule) => rule.id !== ruleId
            );
            // If the group is empty, delete it. Unless it's the root group
            if (groups[groupId].childRules.length === 0 && groupId !== "0") {
              delete groups[groupId]; // remove the group
            }
            return groups;
          } else if (
            Object.keys(groups[groupId].childConditionGroups).length > 0
          ) {
            groups[groupId].childConditionGroups = removeRule(
              groups[groupId].childConditionGroups,
              targetId
            );
          }
        }
        return groups;
      };

      return removeRule(JSON.parse(JSON.stringify(prev)), conditionGroupID);
    });
  };

  /*Updates selection, variable, option, value, type*/
  const updateRuleProperty =
    (property: string) =>
    (conditionGroupID: string, ruleId: string, name: string) => {
      setChildConditionGroups((prev) => {
        const updateRuleProperty = (
          groups: ChildConditionGroup,
          targetId: string
        ): ChildConditionGroup => {
          for (let groupId in groups) {
            if (groupId === targetId) {
              groups[groupId].childRules = groups[groupId].childRules.map(
                (rule) => {
                  if (rule.id === ruleId) {
                    rule[property] = name;
                  }
                  return rule;
                }
              );
              return groups;
            } else if (
              Object.keys(groups[groupId].childConditionGroups).length > 0
            ) {
              groups[groupId].childConditionGroups = updateRuleProperty(
                groups[groupId].childConditionGroups,
                targetId
              );
            }
          }
          return groups;
        };

        return updateRuleProperty(
          JSON.parse(JSON.stringify(prev)),
          conditionGroupID
        );
      });
    };

  return (
    <div className="condition_container">
      {/* Render the child condition groups */}
      {Object.keys(childConditionGroups).map((id) => (
        <ConditionGroup
          key={id}
          conditionGroupID={id}
          childConditionGroups={childConditionGroups[id].childConditionGroups}
          setChildConditionGroups={setChildConditionGroups}
          deleteGroup={deleteGroup}
          addGroupToId={addGroupToId}
          // childRules={{ [id]: childConditionGroups[id].childRules }}
          operator={childConditionGroups[id].operator}
          removeConditionRule={removeConditionRule}
          handleConditionOperator={handleConditionOperator}
          disableOperator={
            // childRules length greater than 1
            Object.keys(childConditionGroups[id].childRules).length > 1
              ? true
              : false || // or childConditionGroups length greater than 1
                Object.keys(childConditionGroups[id].childConditionGroups)
                  .length > 1 || // child rules + child conditions less than 2
                Object.keys(childConditionGroups[id].childRules).length +
                  Object.keys(childConditionGroups[id].childConditionGroups)
                    .length >
                  1 || // or editable is false
                editable === false
          }
          addRule={addRule}
          childRules={childConditionGroups[id].childRules}
          // Rule handlers
          addRuleSelection={updateRuleProperty("selection")}
          addRuleOption={updateRuleProperty("options")}
          addRuleValue={updateRuleProperty("value")}
          addRuleType={updateRuleProperty("type")}
          addRuleVariable={updateRuleProperty("variable")}
          // Data for dropdowns
          dropdownRuleData={dropdownRuleData}
          //
          editable={editable}
          viewEditRule={viewEditRule}
        />
      ))}
    </div>
  );
};

export default Condition;
