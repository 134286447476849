import React, { useState } from "react";
import AutomationLogs from "./Monitor/automationLogs.component";
import AutomationRulesContainer from "./Rules/automationRulesContainer.component";
import "./automation.scss";

const Automation: React.FC = () => {
  const [tabControl, setTabControl] = useState("rules"); // Create state variable for loading status

  return (
    <>
      <div className={`container-fluid automations_container`}>
        <div className="row">
          {/* <RulesMonitorContainer
            tabControl={tabControl}
            setTabControl={setTabControl}
            setSelectedRuleID={setSelectedRuleID}
            selectedRuleID={selectedRuleID}
          /> */}
          {/* Creating our automation page, here we will have two  header Rules which will go to the rules component and monitor which will go to the monitor component*/}
          <div className="rulesMonitorContainer_container">
            {/* HEADER */}
            <div className="rulesMonitorContainer_header">
              <div className="rulesMonitorContainer_header_tabs">
                <div
                  className={`rulesMonitorContainer_header_tab  ${
                    tabControl === "rules" ? "selected" : ""
                  }`}
                  onClick={() => setTabControl("rules")}
                >
                  <h5 className="tab_title">Rules</h5>
                </div>
                <div
                  className={`rulesMonitorContainer_header_tab ms-2 ${
                    tabControl === "monitor" ? "selected" : ""
                  }`}
                  onClick={() => setTabControl("monitor")}
                >
                  <h5 className="tab_title">Monitor</h5>
                </div>
              </div>
            </div>
            {/* RULES */}
            {tabControl && tabControl === "rules" && (
              <div className="container-fluid rules_container">
                <div className="rules_row row">
                  <AutomationRulesContainer />
                </div>
              </div>
            )}
            {/* MONITOR */}
            {tabControl && tabControl === "monitor" && <AutomationLogs />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Automation;
