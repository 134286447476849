import React, { useEffect, useState } from "react";
import { Timezone } from "../../types";
import { convertTime, formatDate } from "../../utilities/utils";

type TimezoneCalculatorProps = {
  timezones: Timezone[];
};

const TimezoneCalculator: React.FC<TimezoneCalculatorProps> = ({
  timezones
}) => {
  // set time to the current time in the local timezone format: 2023-08-16T07:36, must be adjusted to local timezone
  const [time, setTime] = useState<string>(() => {
    const now = new Date();
    const localDateTime = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    );
    return localDateTime.toISOString().slice(0, 16);
  });
  // set the source timezone to the local timezone, if invalid set to first timezone in list
  const [sourceTimezoneId, setSourceTimezoneId] = useState<string>(() => {
    const source = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezone = timezones.find((tz) => tz.name === source);
    return timezone ? timezone.name : timezones[0].name;
  });

  // set the desired timezone to UTC
  const [desiredTimezoneId, setDesiredTimezoneId] = useState<string>(
    timezones.find((tz) => tz.name === "UTC")?.name || timezones[0].name
  );
  // call the conversion function

  const [sourceSearchTerm, setSourceSearchTerm] = useState<string>("");
  const [desiredSearchTerm, setDesiredSearchTerm] = useState<string>("");

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const getOffsetById = (id: string) => {
    const timezone = timezones.find((tz) => tz.name === id);
    return timezone ? timezone.offset : 0;
  };

  const processTimeConversion = () => {
    if (!time || !sourceTimezoneId || !desiredTimezoneId) {
      return "";
    }
    const sourceOffset = getOffsetById(sourceTimezoneId);
    const desiredOffset = getOffsetById(desiredTimezoneId);

    // Interpret the input time string as being in the local timezone
    const localDateObj = new Date(time);

    // Adjust by the negative of the local timezone offset
    localDateObj.setMinutes(
      localDateObj.getMinutes() - localDateObj.getTimezoneOffset()
    );

    // Adjust by the source offset to get the actual UTC time
    localDateObj.setHours(localDateObj.getHours() - sourceOffset);

    // Convert this UTC time to the desired timezone
    const desiredDateObj = convertTime(
      localDateObj.toISOString(),
      desiredOffset
    );
    if (!desiredDateObj) {
      return "Invalid Date";
    }

    // Format the desired date object to get the final result string
    const result = formatDate(desiredDateObj);

    return result;
  };
  const [convertedTime, setConvertedTime] = useState<string>(
    processTimeConversion()
  );

  const filteredSourceTimezones = timezones.filter((tz) =>
    tz.name.toLowerCase().includes(sourceSearchTerm.toLowerCase())
  );
  const filteredDesiredTimezones = timezones.filter((tz) =>
    tz.name.toLowerCase().includes(desiredSearchTerm.toLowerCase())
  );

  const handleSourceSearchTermChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSearchTerm = e.target.value;
    setSourceSearchTerm(newSearchTerm);

    const newFilteredTimezones = timezones.filter((tz) =>
      tz.name.toLowerCase().includes(newSearchTerm.toLowerCase())
    );

    // Always set the sourceTimezoneId to the first item in the filtered list if available
    setSourceTimezoneId(
      newFilteredTimezones.length > 0 ? newFilteredTimezones[0].name : ""
    );
  };

  const handleDesiredSearchTermChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSearchTerm = e.target.value;
    setDesiredSearchTerm(newSearchTerm);

    const newFilteredTimezones = timezones.filter((tz) =>
      tz.name.toLowerCase().includes(newSearchTerm.toLowerCase())
    );

    // Always set the desiredTimezoneId to the first item in the filtered list if available
    setDesiredTimezoneId(
      newFilteredTimezones.length > 0 ? newFilteredTimezones[0].name : ""
    );
  };

  // if any values are blank clear the converted time
  useEffect(() => {
    if (!time || !sourceTimezoneId || !desiredTimezoneId) {
      setConvertedTime("");
    } else {
      setConvertedTime(processTimeConversion());
    }
  }, [time, sourceTimezoneId, desiredTimezoneId]);

  return (
    <div id="timezone_calculator" className="container">
      <div className="row">
        <h1>Timezone Calculator</h1>
      </div>
      <br />
      <div className="row">
        <div className="col-2">Time:</div>
        <div className="col-auto">
          <input
            type="datetime-local"
            value={time}
            onChange={handleTimeChange}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">Source Timezone:</div>
        <div className="col-auto">
          <input
            placeholder="Search source timezone..."
            value={sourceSearchTerm}
            onChange={handleSourceSearchTermChange}
          />
          <br />
          <select
            value={sourceTimezoneId}
            onChange={(e) => setSourceTimezoneId(e.target.value)}
          >
            {filteredSourceTimezones.map((tz) => (
              <option key={tz.name} value={tz.name}>
                {tz.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <div>Desired Timezone:</div>
        </div>
        <div className="col-auto">
          <input
            placeholder="Search desired timezone..."
            value={desiredSearchTerm}
            onChange={handleDesiredSearchTermChange}
          />
          <br />
          <select
            value={desiredTimezoneId}
            onChange={(e) => setDesiredTimezoneId(e.target.value)}
          >
            {filteredDesiredTimezones.map((tz) => (
              <option key={tz.name} value={tz.name}>
                {tz.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-auto">
          <h5>
            Converted Time: {convertedTime ? convertedTime : "Enter a time"}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default TimezoneCalculator;
