import React from "react";
import * as Icons from "../../../assets/images/icons/icons.ts";
import { usePermissions } from "../../../contexts/UserContext.tsx";
import {
  Client,
  Country,
  Language,
  Researcher,
  ResearcherRole,
  Timezone
} from "../../../types.ts";
import { convertTime, formatDate } from "../../../utilities/utils.ts";
import ParticipantActions from "../ParticipantActions/participantAction";

interface ResearchersTableProps {
  currentResearchers: Researcher[];
  selectedResearchers: string[];
  filteredResearchersLength: number;
  researcherID: string;
  allTimezones: Timezone[];
  timezone: string;
  countries: Country[];
  languages: Language[];
  activeResearcherId: string | null;
  researcherTimezone: Timezone | null;
  allClients: Client[];
  handleSelectResearcher: (researcherID: string) => void;
  handleSelectAllResearchers: () => void;
  handleViewResearcher: (researcherID: string) => void;
  handleResearcherActionsDropdown: (researcherID: string) => void;
  allRoles: ResearcherRole[];
  setSelectedRole: (roleID: string) => void;
}

const ResearchersTable: React.FC<ResearchersTableProps> = ({
  currentResearchers,
  selectedResearchers,
  filteredResearchersLength,
  researcherID,
  timezone,
  allTimezones,
  countries,
  languages,
  activeResearcherId,
  researcherTimezone,
  allClients,
  allRoles,
  handleSelectResearcher,
  handleSelectAllResearchers,
  handleViewResearcher,
  handleResearcherActionsDropdown,
  setSelectedRole
}) => {
  const { hasPermission } = usePermissions();

  return (
    <div className="table-responsive">
      <table className="cust_table table">
        <thead>
          <tr>
            <th className="column_sticky">
              <input
                type="checkbox"
                checked={
                  selectedResearchers.length === filteredResearchersLength
                }
                className="clickable"
                onChange={handleSelectAllResearchers}
              />
            </th>
            <th className="column_sticky">Name</th>
            <th>ID</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Country</th>
            <th>Timezone</th>
            {/* <th>Language</th> */}
            <th>Role</th>
            <th>Created At</th>
            <th>Last Login</th>
            <th>Status</th>
            {hasPermission("researcher", "write") ||
              (hasPermission("researcher", "delete") && <th></th>)}
          </tr>
        </thead>
        <tbody>
          {currentResearchers.map((researcher: Researcher, index: number) => {
            const fullName =
              (researcher.first_name + " " + researcher.surname).trim() ||
              "N/A";
            // const name = fullName.trim() !== "" ? fullName : "N/A";
            const ID = researcher.id.toString() || "N/A";
            const email = researcher.email || "N/A";
            const mobile = researcher.full_mobile || "N/A";
            const countryName =
              countries.find(
                (country) =>
                  country.iso_code.toUpperCase() === researcher.country_iso
              )?.name || "N/A";

            // const languageName =
            //   languages.find(
            //     (language) =>
            //       language.iso_code.toUpperCase() === researcher.language_iso
            //   )?.name || "N/A";
            let timezoneOffset = 0;
            // get researcher's timezone offset based off of thier timezone, filter though allTimezones
            const researcherOffset = allTimezones.find(
              (timezone) => timezone.name === researcher.timezone
            )?.offset;
            if (timezone === "Participant's Timezone" && researcherOffset) {
              timezoneOffset = Number(researcherOffset);
            } else if (timezone === "UTC") {
              timezoneOffset = 0;
            } else if (timezone === "ResearcherTimezone") {
              timezoneOffset = researcherTimezone
                ? researcherTimezone.offset
                : 0;
            }

            const createdAt = researcher.created_at
              ? formatDate(convertTime(researcher.created_at, timezoneOffset))
              : "N/A";

            const lastLogin = researcher.last_login
              ? formatDate(convertTime(researcher.last_login, timezoneOffset))
              : "N/A";

            let displayRTimezone = "";
            const rTimezone =
              allTimezones.find(
                (timezone) =>
                  timezone.name.toUpperCase() ===
                  researcher.timezone.toUpperCase()
              )?.name || null;

            if (rTimezone) {
              const timezoneInfo = allTimezones.find(
                (timezone) =>
                  timezone.name.toUpperCase() === rTimezone.toUpperCase()
              );

              const rOffset =
                timezoneInfo?.offset !== undefined ? timezoneInfo.offset : null;
              if (rOffset !== null && rOffset !== undefined) {
                // add offset to researcher's timezone, if positive add +
                const offsetSign = rOffset > 0 ? "+" : "";
                displayRTimezone = `(${offsetSign}${rOffset}) ${rTimezone}`;
              } else {
                displayRTimezone = "N/A";
              }
            } else {
              displayRTimezone = "N/A";
            }

            const roleName =
              allRoles.find((role) => role.id === researcher.role_id)?.name ||
              "N/A";

            const blocked = researcher.blocked ? "Blocked" : "Active";

            return (
              <tr
                key={researcher.id.toString()}
                className={`${
                  selectedResearchers.includes(researcher.id.toString()) &&
                  "row_researcher_selected"
                }${
                  researcherID === researcher.id.toString() &&
                  " row_researcher_viewing"
                }`}
              >
                <td className="column_sticky">
                  <input
                    type="checkbox"
                    className="checkbox clickable"
                    checked={selectedResearchers.includes(
                      researcher.id.toString()
                    )}
                    onChange={() =>
                      handleSelectResearcher(researcher.id.toString())
                    }
                  />
                </td>
                <td className="column_sticky">
                  <span
                    className="researcher_name clickable"
                    onClick={() =>
                      handleViewResearcher(researcher.id.toString())
                    }
                  >
                    {fullName}
                  </span>
                </td>
                <td>{ID}</td>
                <td>{email}</td>
                <td>{mobile}</td>
                <td>{countryName}</td>
                <td>{displayRTimezone}</td>
                {/* <td>{languageName}</td> */}
                <td>
                  <span
                    className="researcher_name clickable"
                    onClick={() => setSelectedRole(researcher.role_id)}
                  >
                    {roleName}
                  </span>
                </td>
                <td>{createdAt}</td>
                <td>{lastLogin}</td>
                <td>{blocked}</td>
                {hasPermission("researcher", "write") ||
                  (hasPermission("researcher", "delete") && (
                    <td>
                      <button
                        className="button btn btn_settings"
                        onClick={() =>
                          handleResearcherActionsDropdown(
                            researcher.id.toString()
                          )
                        }
                        disabled={
                          activeResearcherId === researcher.id.toString()
                        }
                      >
                        <Icons.IconSettings className="icon" />
                      </button>
                      {activeResearcherId === researcher.id.toString() && (
                        <ParticipantActions
                          participantID={researcher.id.toString()}
                          closeDropdown={() =>
                            handleResearcherActionsDropdown("close")
                          }
                          outsideDropdown={() =>
                            handleResearcherActionsDropdown("outside")
                          }
                          shown={
                            activeResearcherId === researcher.id.toString()
                          }
                          tableType="researchersTable"
                          participantStatus={
                            researcher.blocked ? "blocked" : ""
                          }
                        />
                      )}
                    </td>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResearchersTable;
