import React from "react";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldNotification from "../../Fields/fieldNotification.component";
import FieldNumber from "../../Fields/fieldNumber.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
import FieldTime from "../../Fields/fieldTime.component";
import { AddSurveyWizardStepNotificationsProps } from "../addSurveyWizard.component";
import AddSurveyWizardFooter from "../addSurveyWizardFooter.component";

interface StepNotificationsProps
  extends AddSurveyWizardStepNotificationsProps {}

const StepNotifications: React.FC<StepNotificationsProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  surveyType,
  handleWizardBack,
  handleInputChange,
  handleWizardSubmit,
  // Fields
  welcomeNotification,
  dailyReminderNotification,
  dailyReminderTime,
  nonComplianceNotification,
  nonComplianceTime,
  pageValid,
  inputErrors,
  welcomeNotificationsData,
  dailyReminderNotificationsData,
  nonComplianceNotificationsData,
  newWelcomeNotification,
  newDailyReminderNotification,
  newNonComplianceNotification,
  singleNonComplianceNotificationStartDelay,
  singleNonComplianceNotificationInBetweenDelay,
  surveyName,
  includeNotifications
}) => {
  return (
    <div className="card card-default add_survey_form_page p-4">
      <h3 className="form_header">Survey Notifications: {surveyName}</h3>
      <div className="add_survey_form_page_inputs">
        <FieldCheckbox
          tooltip="Toggle setting to either enable or disable push notifications for this survey. Notifications can be crucial for participant engagement and compliance"
          tooltipPosition="bottom"
          label="Send out notifications for this survey?"
          inputName="includeNotifications"
          inputValue={includeNotifications}
          onInputChange={handleInputChange}
          error={
            inputErrors?.includeNotifications
              ? inputErrors.includeNotifications
              : null
          }
        />
        {includeNotifications && (
          <>
            <FieldNotification
              tooltip="The initial notification that participants receive once the survey is active, informing them about the survey's availability and their expected participation frequency"
              label="Welcome Notification"
              inputName="welcomeNotification"
              inputValue={welcomeNotification}
              inputOptions={welcomeNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.welcomeNotification
                  ? inputErrors.welcomeNotification
                  : null
              }
            />
            {/* if welcomeNotification = new */}

            {welcomeNotification === "new" && (
              <>
                <FieldTextArea
                  label="New Welcome Message"
                  inputName="newWelcomeNotification"
                  inputValue={
                    newWelcomeNotification ? newWelcomeNotification : ""
                  }
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.newWelcomeNotification
                      ? inputErrors.newWelcomeNotification
                      : null
                  }
                />
              </>
            )}

            {surveyType === "diary" && (
              <>
                <FieldNotification
                  tooltip="A daily push notification reminder, to be sent at a selected time - typically to remind participants to complete their diary / remember to consider their diary that day."
                  label="Daily Reminder Notification"
                  inputName="dailyReminderNotification"
                  inputValue={dailyReminderNotification}
                  inputOptions={dailyReminderNotificationsData}
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.dailyReminderNotification
                      ? inputErrors.dailyReminderNotification
                      : null
                  }
                />

                {
                  // if dailyReminderNotification = new
                  dailyReminderNotification === "new" && (
                    <>
                      <FieldTextArea
                        label="New Daily Reminder Message"
                        inputName="newDailyReminderNotification"
                        inputValue={
                          newDailyReminderNotification
                            ? newDailyReminderNotification
                            : ""
                        }
                        onInputChange={handleInputChange}
                        error={
                          inputErrors?.newDailyReminderNotification
                            ? inputErrors.newDailyReminderNotification
                            : null
                        }
                      />
                    </>
                  )
                }
              </>
            )}
            {surveyType === "diary" && (
              <FieldTime
                tooltip="The time that the daily reminder should be sent."
                label="Daily Reminder Time"
                inputName="dailyReminderTime"
                inputValue={dailyReminderTime ? dailyReminderTime : ""}
                onInputChange={handleInputChange}
                subLabel="User local time"
                error={
                  inputErrors?.dailyReminderTime
                    ? inputErrors.dailyReminderTime
                    : null
                }
              />
            )}

            <FieldNotification
              tooltip={
                surveyType === "diary"
                  ? "A daily push notification reminder, sent daily at a specified time, if the participant doesn't complete the minimum number of completes."
                  : "A push notification sent at a specified time if the participant hasn't yet completed this survey. "
              }
              label="Non-Compliance Notification"
              inputName="nonComplianceNotification"
              inputValue={nonComplianceNotification}
              inputOptions={nonComplianceNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.nonComplianceNotification
                  ? inputErrors.nonComplianceNotification
                  : null
              }
            />
            {
              // if nonComplianceNotification = new
              nonComplianceNotification === "new" && (
                <>
                  <FieldTextArea
                    label="New Non-Compliance Message"
                    inputName="newNonComplianceNotification"
                    inputValue={
                      newNonComplianceNotification
                        ? newNonComplianceNotification
                        : ""
                    }
                    onInputChange={handleInputChange}
                    error={
                      inputErrors?.newNonComplianceNotification
                        ? inputErrors.newNonComplianceNotification
                        : null
                    }
                  />
                </>
              )
            }

            <FieldTime
              tooltip="The time that the non-compliance reminder should be sent."
              label="Non-Compliance Time"
              inputName="nonComplianceTime"
              inputValue={nonComplianceTime ? nonComplianceTime : ""}
              onInputChange={handleInputChange}
              subLabel="User local time"
              error={
                inputErrors?.nonComplianceTime
                  ? inputErrors.nonComplianceTime
                  : null
              }
            />

            {surveyType === "single" && (
              <>
                <FieldNumber
                  tooltip="If you want to give participants some time to complete the survey before reminding them, you can set a number of days that they will not receive the non-compliance reminder"
                  label="Non-Compliance Notification Start Delay (Days)"
                  inputName={"singleNonComplianceNotificationStartDelay"}
                  inputValue={singleNonComplianceNotificationStartDelay}
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.singleNonComplianceNotificationStartDelay
                      ? inputErrors.singleNonComplianceNotificationStartDelay
                      : null
                  }
                />
                <FieldNumber
                  tooltip="If not set, participants will be reminded daily if they have not completed this survey. If you want to give them some additional time, you can set it to send a notification every few days - for instance if you set this to 1, they will be reminded every second day, etc. (1 day interval in between reminders)"
                  label="Non-Compliance Notification Interval Delay (Days)"
                  inputName={"singleNonComplianceNotificationInBetweenDelay"}
                  inputValue={singleNonComplianceNotificationInBetweenDelay}
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.singleNonComplianceNotificationInBetweenDelay
                      ? inputErrors.singleNonComplianceNotificationInBetweenDelay
                      : null
                  }
                />
              </>
            )}
          </>
        )}

        <AddSurveyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepNotifications;
