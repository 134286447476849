import React, { useEffect, useRef, useState } from "react";
import { Participant } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface BucketsFilterProps {
  buckets: Participant["participant_buckets"]; // Assuming this is an array of bucket objects with a 'name' property
  onBucketsFilterChange: (buckets: string[]) => void;
  filtersCleared: boolean;
  location: string;
}

const BucketsFilter: React.FC<BucketsFilterProps> = ({
  buckets,
  onBucketsFilterChange,
  filtersCleared,
  location
}) => {
  // console.log(buckets);
  const [bucketFilter, setBucketFilter] = useState<string>("");
  const [bucketDropdown, setBucketDropdown] = useState(false);
  const [selectedBuckets, setSelectedBuckets] = useState<
    Record<string, boolean>
  >({});

  const handleBucketDropdown = (show: boolean) => {
    setBucketDropdown(show);
  };

  const handleBucketFilter = (search: string) => {
    setBucketFilter(search);
  };

  const handleBucketSelection = (bucketName: string, checked: boolean) => {
    setSelectedBuckets((prevBuckets) => ({
      ...prevBuckets,
      [bucketName]: checked
    }));
  };

  useEffect(() => {
    onBucketsFilterChange(
      Object.keys(selectedBuckets).filter(
        (bucketName) => selectedBuckets[bucketName]
      )
    );
  }, [selectedBuckets, onBucketsFilterChange]);

  useEffect(() => {
    if (filtersCleared) {
      setBucketFilter("");
      setSelectedBuckets({});
      handleBucketFilter("");
    }
  }, [filtersCleared]);

  // useEffect to select default bucket from session storage called Bucket 1
  useEffect(() => {
    const storedData = sessionStorage.getItem("filterData");
    if (storedData) {
      const filterData = JSON.parse(storedData);

      const transformBucketName = (name: string) => {
        if (name === "non-compliant") return "non compliant";
        if (name === "compliant") return "Compliant";
        return name;
      };

      setSelectedBuckets((prevBuckets) => {
        const transformedBucketName = transformBucketName(
          filterData.bucketName
        );
        return {
          ...prevBuckets,
          [transformedBucketName]: true
        };
      });
    }
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    handleBucketDropdown(false);
  });

  return (
    <div className="dropdown dropdown_buckets" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleBucketDropdown(!bucketDropdown)}
      >
        Buckets
      </button>
      <div className={`dropdown-menu ${bucketDropdown ? "show" : "hide"}`}>
        {buckets && buckets.length === 0 ? (
          <span>No buckets found</span>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search Buckets"
              value={bucketFilter}
              onChange={(e) => handleBucketFilter(e.target.value)}
            />
            <div className="dropdown_menu_items">
              {buckets &&
                buckets.map((bucket, index) => {
                  if (
                    bucket &&
                    bucket.name
                      .toLowerCase()
                      .includes(bucketFilter.toLowerCase())
                  ) {
                    return (
                      <div
                        key={`bucket_${bucket.name}_${index}`}
                        className="dropdown_menu_item"
                      >
                        <label
                          htmlFor={`${location}_${bucket.name}`}
                          className="form_control"
                        >
                          <input
                            type="checkbox"
                            id={`${location}_${bucket.name}`}
                            name={bucket.name}
                            value={bucket.name}
                            checked={selectedBuckets[bucket.name] || false}
                            onChange={(e) =>
                              handleBucketSelection(
                                e.target.value,
                                e.target.checked
                              )
                            }
                          />
                          {/* {bucket.name+bucket.survey_name} */}
                          {bucket.survey_name
                            ? bucket.survey_name + " - " + bucket.name
                            : bucket.name}
                        </label>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BucketsFilter;
