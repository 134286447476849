import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchAllWSCMappings,
  fetchAllWSCMappingsOptions,
  sendAllWSCMappings
} from "../../../../models/wscMapping.model";

import Swal from "sweetalert2";
import WSCMappingPagination from "./WSCMapppingPagination";
import {
  ExistingWSCVariable,
  WSCVariable,
  WSCVariableOptions
} from "../../../../types";

type WSCMappingProps = {
  reloadComponent: () => void;
};

const WSCMapping: React.FC<WSCMappingProps> = ({ reloadComponent }) => {
  const { clientID, studyID, surveyID } = useParams<{
    clientID: string;
    studyID: string;
    surveyID: string;
  }>(); // Get from URL
  const [variablesToAdd, setVariablesToAdd] = useState<ExistingWSCVariable[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAttributes, setFilteredAttributes] = useState<WSCVariable[]>(
    []
  );
  const [attributes, setAttributes] = useState<WSCVariable[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setFilteredAttributes(attributes);
  }, [attributes]);

  useEffect(() => {
    if (clientID && studyID && surveyID) {
      const fetchQuestions = async () => {
        try {
          const jsonData = await fetchAllWSCMappingsOptions(
            clientID,
            studyID,
            surveyID
          );
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            setError(jsonData);
          } else {
            setAttributes(jsonData);
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
          setError("An error occurred while fetching data");
        }
      };

      // fetchAllWSCQuestionsRMSVariable
      const fetchQuestionsRMS = async () => {
        try {
          const jsonData = await fetchAllWSCMappings(
            clientID,
            studyID,
            surveyID
          );
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            setError(jsonData);
          } else {
            setVariablesToAdd(jsonData);
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
          setError("An error occurred while fetching data");
        }
      };

      const promises = [fetchQuestions(), fetchQuestionsRMS()];

      Promise.all(promises)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    } else {
      setError("Missing clientID, studyID, or surveyID");
      setLoading(false);
    }
  }, [clientID, studyID, surveyID]);

  // handle seach term changes
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredAttributes(attributes);
    } else {
      const filtered = attributes.filter((attr) =>
        attr.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAttributes(filtered);
    }
  }, [searchTerm, attributes]);

  // Function to handle changes in WSC Code for a variable
  const handleWSCCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    variableId: string
  ) => {
    setVariablesToAdd(
      variablesToAdd.map((variable) => {
        if (variable.id === variableId) {
          return { ...variable, wscCode: event.target.value };
        }
        return variable;
      })
    );
  };

  //  Function to handle changes to method for a variable
  const handleMethodChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    variableId: string
  ) => {
    setVariablesToAdd(
      variablesToAdd.map((variable) => {
        if (variable.id === variableId) {
          return {
            ...variable,
            method: event.target.value as "new" | "existing"
          };
        }
        return variable;
      })
    );
  };

  // Function to handle changes in options variables
  const handleSubVariableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    variableId: string,
    subVariableId: string
  ) => {
    console.log(variablesToAdd.find((variable) => variable.id === variableId));
    setVariablesToAdd(
      variablesToAdd.map((variable) => {
        if (variable.id === variableId) {
          return {
            ...variable,
            options: variable.options?.map((subVar) => {
              if (subVar.id === subVariableId) {
                return { ...subVar, wscVariable: event.target.value };
              }
              return subVar;
            })
          };
        }
        return variable;
      })
    );
  };

  // function to add a new variable to variablesToAdd
  const addVariable = (wsc: string) => {
    const variableToAdd = attributes.find((attr) => attr.id === wsc);
    if (variableToAdd) {
      const newVariable: ExistingWSCVariable = {
        id: variableToAdd.id,
        name: variableToAdd.name,
        method: "new",
        wscCode: "",
        type: variableToAdd.type,
        options:
          variableToAdd.options &&
          variableToAdd.options.map((subVar) => ({
            id: subVar.id,
            name: subVar.name,
            wscVariable: ""
          }))
      };
      setVariablesToAdd([...variablesToAdd, newVariable]);
    }

    // Clear search term and dropdown value
    setSearchTerm("");
    const dropdown = document.querySelector("select");
    if (dropdown) {
      dropdown.value = "";
    }
  };

  function validateVariables(): boolean {
    // validate vaiablesToAdd
    // validate that all variables which are custom have a wscCode
    // validate if system that at least one option has a wscCode or that the wscCode is not empty
    // if not, return false and display error
    let errorVariables: {
      variableName: string;
      errors: string[];
    }[] = [];

    for (let i = 0; i < variablesToAdd.length; i++) {
      const variable = variablesToAdd[i];
      // if (variable.type === "custom" && variable.wscCode === "") {
      //   errorVariables.push({
      //     variableName: variable.name,
      //     errors: ["WSC Code is required for custom variables"],
      //   });
      // }

      if (variable.type === "system" || variable.type === "custom") {
        if (variable.method === "existing") {
          if (variable.wscCode === "") {
            errorVariables.push({
              variableName: variable.name,
              errors: ["WSC Code is required for system variables"]
            });
          }
        } else if (variable.method === "new") {
          // check that at least one option has a wscCode
          if (
            variable.options?.some((subVar) => subVar.wscVariable !== "") ===
            false
          ) {
            errorVariables.push({
              variableName: variable.name,
              errors: ["At least one option must have a WSC Code"]
            });
          }
        }
      }
    }

    if (errorVariables.length > 0) {
      let errorHtml =
        "<strong>Please fix the following errors:</strong><br /><br />";
      errorVariables.forEach((errorVariable) => {
        errorHtml += `<strong>Variable: ${errorVariable.variableName}</strong>`;
        errorVariable.errors.forEach((error) => {
          errorHtml += `<p>Error: ${error}</p>`;
        });
      });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errorHtml
      });
      return false;
    }

    return true;
  }

  function handleSave() {
    if (!clientID || !surveyID || !studyID) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Missing clientID, studyID, or surveyID"
      });
      return;
    }

    if (!validateVariables()) {
      return false;
    }

    const saveVariables = async () => {
      try {
        const jsonData: {
          rStatus: "error" | "success";
          rMessage: string;
        } = await sendAllWSCMappings(
          clientID,
          studyID,
          surveyID,
          variablesToAdd,
          variablesToAdd.length === 0 ? "empty" : null
        );

        if (jsonData.rStatus === "error") {
          Swal.fire({
            icon: "error",
            title: "Error",
            html: jsonData.rMessage
          });
        } else {
          // ok button which will reload the page
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Variables saved successfully",
            showConfirmButton: true,
            confirmButtonText: "OK"
          }).then(() => {
            reloadComponent();
          });
        }
      } catch (error) {
        console.error("An error occurred while sending data:", error);
        setError("An error occurred while sending data");
      }
    };
    saveVariables();
  }

  // Reset state function
  function resetState() {
    //  reset the state to the original state
    reloadComponent();
  }

  return loading ? (
    <div className="d-flex justify-content-center custom_spinner_container flex-column align-items-center mt-5">
      <div className="spinner-border" role="status"></div>
      <h2 className="mt-3">Loading Mapping Variables for Survey...</h2>
    </div>
  ) : error ? (
    <div>
      <h2>{error}</h2>
    </div>
  ) : (
    <>
      <div>
        <div className="mapping_header">
          <h2>Map WSC Variables to Survey Variables</h2>
          <button className="btn btn-primary ms-3" onClick={handleSave}>
            Save
          </button>
          <button className="btn btn-primary ms-2" onClick={resetState}>
            Cancel
          </button>
        </div>
        <h6>Add Variable to be mapped to WSC</h6>
        <p>
          This mapping will only apply for this specific Survey. Select system
          attributes like Country or previously mapped WSC questions below.
        </p>
        {/* dropdown with search
      two secions: system Variables and custom Variables
      the system Variables only display name
      the custom Variables display name and above display the survey name
      */}
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Search Variables..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control mb-2 "
                />
              </div>
              <div className="col-6">
                <select
                  id="wscMappingDropdown"
                  className="form-select mb-2"
                  onChange={(e) => {
                    addVariable(e.target.value);
                  }}
                >
                  <optgroup label="System Variables">
                    {/* defualt value */}
                    <option
                      value={-1}
                      disabled
                      selected={true}
                      //  if no variables have been added
                    >
                      Select Variable
                    </option>
                    {filteredAttributes
                      .filter((attr) => attr.type === "system")
                      .map((attr) => (
                        <option
                          key={`system_var_${attr.name}_${attr.id}`}
                          value={attr.id}
                          disabled={
                            // if variablesToAdd contains the attribute, disable it
                            variablesToAdd.some(
                              (variable) =>
                                variable.name.toLowerCase() ===
                                attr.name.toLowerCase()
                            )
                          }
                        >
                          {attr.name}
                        </option>
                      ))}
                  </optgroup>
                  <optgroup label="Custom Variables">
                    {filteredAttributes
                      .filter((attr) => attr.type === "custom")
                      .map((attr) => (
                        <option
                          key={`custom_var_${attr.name}_${attr.id}`}
                          value={attr.id}
                          disabled={
                            // if variablesToAdd contains the attribute, disable it
                            variablesToAdd.some(
                              (variable) =>
                                variable.name.toLowerCase() ===
                                attr.name.toLowerCase()
                            )
                          }
                        >{`${attr.name}${
                          attr.surveyName ? " (" + attr.surveyName + ")" : ""
                        }`}</option>
                      ))}
                  </optgroup>
                </select>
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="row">
          {variablesToAdd.map((variable, id) => (
            <div
              className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
              key={`wsc_mapping_${variable.id}_${id}`}
            >
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">{variable.name}</h6>
                  <button
                    type="button"
                    className="btn remove_variable_button"
                    onClick={() =>
                      setVariablesToAdd(
                        variablesToAdd.filter(
                          (varToAdd) => varToAdd !== variable
                        )
                      )
                    }
                  >
                    &times;
                  </button>

                  <select
                    value={variable.method}
                    onChange={(e) => {
                      handleMethodChange(e, variable.id);
                    }}
                    className="form-select mb-2"
                  >
                    <option value="new">Map with new values</option>
                    <option value="existing">Map value as is</option>
                  </select>

                  {/* Inline logic to render custom input fields based on variable properties */}
                  {variable.method === "existing" ? (
                    <>
                      <label>WSC Code</label>
                      <input
                        key={`wsc_mapping_input_${variable.id}`}
                        type="text"
                        className="form-control mb-2"
                        value={variable.wscCode}
                        placeholder="Enter WSC Code"
                        onChange={(e) => handleWSCCodeChange(e, variable.id)}
                      />
                    </>
                  ) : variable.method === "new" ? (
                    <>
                      <label>WSC Code</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={variable.wscCode}
                        placeholder="Enter WSC Code"
                        onChange={(e) => handleWSCCodeChange(e, variable.id)}
                      />
                      <WSCMappingPagination
                        variableId={variable.id}
                        allOptions={variable.options || []}
                        handleSubVariableChange={handleSubVariableChange}
                      />
                      {/* {variable.options?.map((subVariable) => (
                        <div className="row">
                          <div className="col-12 col-lg-12 col-xl-5 text-break">
                            <label className="form-label">
                              {subVariable.name}
                            </label>
                          </div>
                          <div className="col-12 col-lg-12 col-xl-7">
                            <input
                              className="form-control mb-2"
                              type="text"
                              key={`wsc_mapping_${variable.id}_${subVariable.id}`}
                              value={subVariable.wscVariable}
                              placeholder="Enter WSC Code"
                              onChange={(e) =>
                                handleSubVariableChange(
                                  e,
                                  variable.id,
                                  subVariable.id
                                )
                              }
                            />
                          </div>
                        </div>
                      ))} */}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WSCMapping;
