import React from "react";
import { usePermissions } from "../../contexts/UserContext";
interface ViewLeadModalFooterProps {
  editing: boolean;
  viewHistory: boolean;
  handleSave: () => void;
  handleCancel: () => void;
  setEditing: (editing: boolean) => void;
  setViewHistory: (viewHistory: boolean) => void;
  viewingVariables: boolean;
  setViewParticipantVariables: (viewingVariables: boolean) => void;
}

const ViewLeadModalFooter: React.FC<ViewLeadModalFooterProps> = ({
  editing,
  viewHistory,
  handleSave,
  handleCancel,
  setEditing,
  setViewHistory,
  viewingVariables,
  setViewParticipantVariables
}) => {
  const { hasPermission } = usePermissions();
  return (
    <div className="modal-footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex justify-content-end">
            {viewHistory ? (
              <>
                <button
                  className="btn btn-primary me-2"
                  onClick={() => setViewHistory(false)}
                >
                  Back
                </button>
              </>
            ) : (
              <>
                {editing && (
                  <>
                    <button
                      className="btn btn-primary me-2"
                      onClick={() => handleSave()}
                    >
                      Save
                    </button>
                  </>
                )}
                {editing ? (
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                ) : (
                  <>
                    {viewingVariables ? (
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => setViewParticipantVariables(false)}
                      >
                        Back
                      </button>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary me-2"
                          onClick={() => setViewParticipantVariables(true)}
                        >
                          View Variables
                        </button>
                        <button
                          className="btn btn-primary me-2"
                          onClick={() => setViewHistory(true)}
                        >
                          View History
                        </button>
                        {(hasPermission("subject") ||
                          hasPermission("leads", "all") ||
                          hasPermission("leads", "write")) && (
                          <button
                            className="btn btn-primary"
                            onClick={() => setEditing(true)}
                          >
                            Edit
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLeadModalFooter;
