import React, { useEffect, useState } from "react";
import { usePermissions } from "../../../../contexts/UserContext";
import { ChannelMessages, ResourceData } from "../../../../types";
import { convertTime, formatDate } from "../../../../utilities/utils";
import ChatInput from "./chatInput.component";
import ChatWindow from "./chatWindow.component";
import "./conversation.scss";

export type Filter = {
  sender_name?: string;
  startDate?: Date;
  endDate?: Date;
};

export type ConversationContainerProps = {
  channelMessages: ChannelMessages[];
  setChannelMessages: React.Dispatch<React.SetStateAction<ChannelMessages[]>>;
  channelParticipants?: number;
  loading: boolean;
  selectedChannel: string;
  selectedChannelType: string;
  selectedParticipantId: string | null;
  setOpenChannelOptionsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreateChannel: React.Dispatch<React.SetStateAction<boolean>>;
  resources: ResourceData[] | string;
  // setDataRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConversationContainer: React.FC<ConversationContainerProps> = ({
  channelMessages,
  setChannelMessages,
  channelParticipants,
  loading,
  selectedChannel,
  selectedChannelType,
  selectedParticipantId,
  setOpenChannelOptionsModal,
  setIsCreateChannel,
  resources
  // setDataRefresh,
}) => {
  const [filteredMessages, setFilteredMessages] = useState<ChannelMessages[]>(
    []
  );
  const [filters, setFilters] = useState<Filter>({});
  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [replyMessageID, setReplyMessageID] = useState<string | null>(null);
  const [replyMessageContent, setReplyMessageContent] = useState<string>("");
  const [replyMessageAttachments, setReplyMessageAttachments] =
    useState<string>("");
  const [replySenderName, setReplySenderName] = useState<string>("");
  const { hasPermission } = usePermissions();
  const [replyMessageType, setReplyMessageType] = useState<string>("");

  useEffect(() => {
    const applyFilters = (messages: ChannelMessages[], filters: Filter) => {
      return messages?.filter((message) => {
        // const senderMatch = filters.sender_name
        // 	? message.sender_name === filters.sender_name
        // 	: true;

        let dateMatch = true;
        if (filters.startDate && filters.endDate) {
          const messageDate = new Date(message.createdAt);
          dateMatch =
            messageDate >= filters.startDate && messageDate <= filters.endDate;
        }

        // Search term match
        const searchMatch = searchTerm
          ? message.message.toLowerCase().includes(searchTerm.toLowerCase())
          : true;

        return dateMatch && searchMatch;
      });
    };

    setFilteredMessages(applyFilters(channelMessages, filters));
  }, [channelMessages, filters, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (newFilters: Filter) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      ...newFilters
    }));
  };

  function removeHTMLTags(str: string) {
    return str.replace(/<[^>]*>?/gm, "");
  }

  const handleChatExport = () => {
    // Export the messages from channelMessages to a CSV file
    // console.log(filteredMessages);
    // console.log(channelMessages);
    const csvRows = [
      ["Date", "Sender", "ParticipantID", "Message"],
      ...filteredMessages
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
        .map((message: ChannelMessages) => [
          formatDate(convertTime(message.createdAt, 0)) + " UTC",
          message.senderName,
          !message.researcherID ? message.participantID : "",
          message.attachmentUrl
            ? message.attachmentUrl
            : removeHTMLTags(message.message)
        ])
    ];

    const csvString = csvRows
      .map((row) => row.map((field) => `"${field}"`).join(","))
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");

    const a = document.createElement("a");
    a.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvString);
    a.target = "_blank";
    a.download = "export.csv";
    document.body.appendChild(a);
    a.click();

    console.log(csvString);

    // const csvContent = "data:text/csv;charset=utf-8," + csvString;
    // const encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);

    // const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.setAttribute("href", url);
    // link.setAttribute("download", "export.csv");
    // link.style.visibility = "hidden";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  const handleSenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleFilterChange({
      sender_name: event.target.value || undefined
    });
  };

  // Handle the date input changes
  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const startDate = event.target.value
      ? new Date(event.target.value)
      : undefined;
    handleFilterChange({ startDate });
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let endDate = event.target.value ? new Date(event.target.value) : undefined;

    if (endDate) {
      // Add one day
      endDate.setDate(endDate.getDate() + 1);

      // Optional: Adjust the time to the start of the day (00:00:00)
      endDate.setHours(0, 0, 0, 0);
    }
    handleFilterChange({ endDate });
  };

  const handleReplyToMessage = (
    messageID: string,
    messageContent: string | null,
    messageAttachments: string | null,
    senderName: string,
    messageType: string
  ) => {
    // console.log(messageID, messageContent, messageAttachments);
    if (messageID && messageContent && !messageAttachments) {
      setReplyMessageID(messageID);
      setReplyMessageContent(messageContent);
      setReplySenderName(senderName);
      setReplyMessageType(messageType);
    }

    if (messageID && !messageContent && messageAttachments) {
      setReplyMessageID(messageID);
      setReplyMessageAttachments(messageAttachments);
      setReplySenderName(senderName);
      setReplyMessageType(messageType);
    }
  };

  const clearReplyMessage = () => {
    setReplyMessageID(null);
    setReplyMessageContent("");
    setReplyMessageAttachments("");
    setReplySenderName("");
  };

  return (
    <>
      <div className="conversation card">
        <div className="top_bar card-header">
          <div className="top_w_filter_select">
            <div>Conversation</div>
            {selectedChannel !== "" && (
              <div>
                {(hasPermission("subject") ||
                  hasPermission("chat", "all") ||
                  hasPermission("chat", "write")) && (
                  <>
                    {filteredMessages && filteredMessages.length > 0 && (
                      <button
                        className="settings_button"
                        onClick={() => {
                          handleChatExport();
                        }}
                      >
                        Export
                      </button>
                    )}
                    <button
                      className="settings_button"
                      onClick={() => {
                        setOpenChannelOptionsModal(true);
                        setIsCreateChannel(false);
                      }}
                    >
                      Settings
                    </button>
                  </>
                )}
                <button
                  className="filter_select_button"
                  onClick={() => {
                    handleShowFilters();
                  }}
                >
                  Filter/Select
                </button>
              </div>
            )}
          </div>
          {showFilters && (
            <div className="chat_filter_container">
              <div className="chat_filter_row_1">
                <div className="left_side">
                  {/* <div className="icon_tags">
										<ChatTagsFilter filterName={"Tags"} />
									</div> */}
                  <div className="conversation_start_date_container">
                    <label>From</label>
                    <input
                      type="date"
                      className="conversation_start_date"
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div className="conversation_end_date_container">
                    <label htmlFor="">To</label>
                    <input
                      type="date"
                      className="conversation_end_date"
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
                <div className="right_side">
                  <input
                    className="conversation_keywords"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                  ></input>
                  {/* <div
										className="icon_export"
										onClick={handleChatExport}
									>
										<IconExport />
									</div> */}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-body chat_input_container">
          <div className="inner_container">
            <ChatWindow
              channelMessages={filteredMessages}
              setChannelMessages={setChannelMessages}
              selectedParticipantId={selectedParticipantId}
              selectedChannelType={selectedChannelType}
              selectedChannel={selectedChannel}
              selectReplyMessage={handleReplyToMessage}
              loading={loading}
            />
            <ChatInput
              allChatHistory={channelMessages}
              selectedChannel={selectedChannel}
              setChannelMessages={setChannelMessages}
              channelParticipants={channelParticipants}
              selectedParticipantId={selectedParticipantId}
              selectedChannelType={selectedChannelType}
              replyMessageID={replyMessageID}
              setReplyMessageID={setReplyMessageID}
              replyMessageContent={replyMessageContent}
              replyMessageAttachment={replyMessageAttachments}
              replySenderName={replySenderName}
              clearReply={clearReplyMessage}
              replyMessageType={replyMessageType}
              resources={resources}
              // setDataRefresh={setDataRefresh}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConversationContainer;
