import React, { useEffect } from "react";

type FilterDatePickerProps = {
  // TODO: add props here
  filtersCleared: boolean;
  location: string;
  fromDate: string;
  toDate: string;
  setFromDate: (fromDate: string) => void;
  setToDate: (toDate: string) => void;
};

const FilterDatePicker: React.FC<FilterDatePickerProps> = ({
  filtersCleared,
  location,
  fromDate,
  toDate,
  setFromDate,
  setToDate
}) => {
  useEffect(() => {
    // Clears date filters
    if (filtersCleared) {
      setToDate("");
      setFromDate("");
    }
  }, [filtersCleared]);

  return (
    <>
      <div className="date_picker_container ms-1">
        <div className="date_filter">
          <div className="date_filter_date_to_from">
            <label className="date_header" htmlFor="fromDate">
              From
            </label>
            <input
              className="date_input"
              type="date"
              id={`${location}_fromDate`}
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
        </div>
        <div className="date_filter_date_to_from">
          <label className="date_header" htmlFor="toDate">
            To{" "}
          </label>

          <input
            className="date_input"
            type="date"
            id={`${location}_toDate`}
            name="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default FilterDatePicker;
