//Page to display the clients in cards

import ProfileSettings from "../../components/ProfileSettings/profileSettings.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";
import "./profile.scss";
interface ProfilePageProps extends SidebarProps {}

const ProfilePage: React.FC<ProfilePageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  return (
    <div className="main_container">
      <SidebarComponent
        page="profile"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            {/* <NavbarComponent /> */}
            <div className="container-fluid" id="profile_page">
              <ProfileSettings />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
