//Page to display the clients in cards
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
// addClientWizard.component
import AddSurveyWizard from "../../components/Wizards/AddSurveyWizard/addSurveyWizard.component";
import { fetchSingleClientByID } from "../../models/client.model";
import { fetchStudyByClientID } from "../../models/study.model";
import { Client, SidebarProps, Study } from "../../types";
import ErrorPage from "../Error/error.page";
import "./addSurvey.scss";

interface AddSurveyPageProps extends SidebarProps {}

const AddSurveyPage: React.FC<AddSurveyPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  const { clientID, studyID } = useParams(); // Read values passed on link
  const [study, setStudy] = useState<Study | null>(null); // Set initial state to null
  const [client, setClient] = useState<Client | null>(null); // Set initial state to null
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  useEffect(() => {
    const fetchClientsData = async () => {
      try {
        const jsonData = await fetchSingleClientByID(clientID as string);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "13ca7dcb55fec46ff71bc47ad569ae88"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setClient(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "d5c013deb1be1d33a23f4209f9132716"
        ]);
      }
    };

    const fetchStudy = async () => {
      try {
        const jsonData = await fetchStudyByClientID(
          clientID as string,
          studyID as string
        );
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          console.log("jsonData is a string");
          console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "51476557bedfea4b2abe54aee57aa865"
          ]);
        } else {
          console.log("jsonData is an object");
          console.log("jsonData", jsonData);
          console.log(jsonData.id);
          console.log(typeof studyID); // before your jsonData loop
          console.log(typeof jsonData.id); // before your jsonData loop
          console.log(jsonData);

          if (jsonData.id === studyID) {
            setStudy(jsonData);
          }
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "9f73500082b2837478a50d50b64703f5"
        ]);
      }
    };

    const promises = [fetchClientsData(), fetchStudy()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "097170a98710114f541041dd0ad6f139"
        ]);
      });
  }, [studyID, clientID]);

  return (
    <div className="main_container custom_blue_background">
      <div className="col-sm p-3 min-vh-100">
        <div className="container" id="add_survey_page">
          {loading || (client && study) ? (
            // loading is handled in the child component to stop double loading
            <AddSurveyWizard />
          ) : loadingError ? (
            <ErrorPage
              errorCode="404"
              error="Client not found"
              debugCode={loadingErrorText}
            />
          ) : !client ? ( //check if client is valid
            <ErrorPage
              errorCode="404"
              error="Client not found"
              debugCode={loadingErrorText}
            />
          ) : (
            !study && ( //check if study is valid
              <ErrorPage
                errorCode="404"
                error="Study not found"
                debugCode={loadingErrorText}
              />
            )
          )}
        </div>
      </div>
      <SidebarComponent
        page="study"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={client?.id}
        currentStudy={study ? study.id : null}
        currentClientLogo={client?.icon_url}
        currentStudyPhoto={study?.thumbnail_url}
      />
    </div>
  );
};

export default AddSurveyPage;
