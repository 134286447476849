// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#view_all_clients_page .client_page_header {
  position: relative;
}
#view_all_clients_page .button_clients_page_back {
  position: relative;
  top: -10px;
  width: 25px;
  display: inline-block;
}
#view_all_clients_page .search_box button,
#view_all_clients_page .search_box input {
  display: inline-block;
  width: auto;
  vertical-align: baseline;
}
#view_all_clients_page .search_box button {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/DisplayClients/displayClients.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,qBAAA;AAHJ;AAMI;;EAEE,qBAAA;EACA,WAAA;EACA,wBAAA;AAJN;AAMI;EACE,kBAAA;AAJN","sourcesContent":["@use \"../../global.scss\" as *;\n\n#view_all_clients_page {\n  .client_page_header {\n    position: relative;\n  }\n\n  .button_clients_page_back {\n    position: relative;\n    top: -10px;\n    width: 25px;\n    display: inline-block;\n  }\n  .search_box {\n    button,\n    input {\n      display: inline-block;\n      width: auto;\n      vertical-align: baseline;\n    }\n    button {\n      margin-right: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
