import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { IconPin } from "../../../../assets/images/icons/icons";
import { useSocket } from "../../../../contexts/SocketContext";
import { ChannelMessages } from "../../../../types";
import ChatBubble from "./chatBubble.component";

export type ChatWindowProps = {
  channelMessages: ChannelMessages[];
  setChannelMessages: React.Dispatch<React.SetStateAction<ChannelMessages[]>>;
  selectedParticipantId: string | null;
  selectedChannelType: string;
  selectedChannel: string;
  selectReplyMessage: (
    messageID: string,
    messageContent: string,
    messageAttachment: string,
    senderName: string,
    messageType: string
  ) => void;
  loading: boolean;
};

const ChatWindow: React.FC<ChatWindowProps> = ({
  channelMessages,
  setChannelMessages,
  selectedParticipantId,
  selectedChannelType,
  selectedChannel,
  selectReplyMessage,
  loading
}) => {
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const messageRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const { clientID, studyID } = useParams();
  const { controller } = useSocket();
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, []);

  const handleDeleteMessage = (messageID: string) => {
    if (
      !clientID ||
      !studyID ||
      selectedChannel === null ||
      selectedParticipantId === null ||
      messageID === null
    ) {
      console.error("Invalid input parameters for deleting message");
      Swal.fire({
        icon: "error",
        title: "Failed to delete message",
        text: "Invalid input parameters for deleting message"
      });
      return;
    }

    if (
      clientID &&
      studyID &&
      selectedChannel &&
      selectedParticipantId !== null &&
      messageID
    ) {
      controller.deleteMessage(
        clientID,
        studyID,
        selectedChannel,
        selectedParticipantId,
        messageID
      );
    }
  };

  const handleEditMessage = (messageID: string, updatedContent: string) => {
    if (
      !clientID ||
      !studyID ||
      !selectedChannel ||
      selectedParticipantId === null ||
      !messageID ||
      !updatedContent
    ) {
      console.error("Invalid input parameters for editing message");
      Swal.fire({
        icon: "error",
        title: "Failed to edit message",
        text: "Invalid input parameters for editing message"
      });
      return;
    }

    if (
      clientID &&
      studyID &&
      selectedChannel &&
      selectedParticipantId !== null &&
      messageID &&
      updatedContent
    ) {
      controller.editMessage(
        clientID,
        studyID,
        selectedChannel,
        selectedParticipantId,
        messageID,
        updatedContent
      );
    }
  };

  const handlePinMessage = async (messageID: string) => {
    if (
      !clientID ||
      !studyID ||
      !selectedChannel ||
      selectedParticipantId === null ||
      !messageID
    ) {
      console.error("Invalid input parameters for pinning message");
      Swal.fire({
        icon: "error",
        title: "Failed to pin message",
        text: "Invalid input parameters for pinning message"
      });
      return;
    }

    if (
      clientID &&
      studyID &&
      selectedChannel &&
      selectedParticipantId !== null &&
      messageID
    ) {
      controller.pinMessage(
        clientID,
        studyID,
        selectedChannel,
        selectedParticipantId,
        messageID
      );
    }
  };

  const updatePinnedStatus = (pinnedMessageID: string) => {
    const updatedMessages = channelMessages.map((message) => {
      // If the message is the one being acted upon, toggle its pinned status
      if (message.id === pinnedMessageID) {
        return { ...message, pinned: message.pinned === false ? false : true };
      }
      // Optionally reset all other messages' pinned status to 0, if only one message can be pinned at a time
      return { ...message, pinned: true };
    });
    setChannelMessages(updatedMessages);
  };

  const pinnedMessage = channelMessages.find((msg) => msg.pinned === true);

  const scrollToPinnedMessage = () => {
    if (pinnedMessage) {
      const messageRef = messageRefs.current[pinnedMessage.id];
      if (messageRef) {
        messageRef.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }
  };

  const getParentMessage = (messageId: string) => {
    // Find the message with the given ID if the message exists but is deleted then we should return 'Message Deleted'
    return channelMessages.find((msg) => msg.id === messageId);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const renderPinnedMessage = (pinnedMessage: ChannelMessages) => {
    if (pinnedMessage && pinnedMessage?.deleted === true) {
      return "This message has been deleted";
    } else if (pinnedMessage?.type === "text") {
      return truncateText(pinnedMessage.message, 300);
    } else if (pinnedMessage?.type === "file") {
      return "File Attached";
    } else if (pinnedMessage?.type === "image") {
      return "Image Attached";
    } else if (pinnedMessage?.type === "video") {
      return "Video Attached";
    } else if (pinnedMessage?.type === "audio") {
      return "Audio Attached";
    } else {
      return "Message not found";
    }
  };

  return (
    <>
      {pinnedMessage && (
        <div
          className="pinned-message-container"
          onClick={scrollToPinnedMessage}
        >
          <div className="pinned-message">
            <small>
              <IconPin />
              <ReactQuill
                value={renderPinnedMessage(pinnedMessage)}
                readOnly={true}
                theme={"bubble"}
                className="pinned-message-quill"
              />
            </small>
          </div>
          <div
            className="unpin-message-button"
            onClick={() => handlePinMessage(pinnedMessage.id)}
          >
            &times;
          </div>
        </div>
      )}
      {/* TODO: Use flex to always scroll to bottom */}
      <div className="chat_window" ref={chatWindowRef}>
        {loading ? (
          <>
            <div className="d-flex justify-content-center align-items-center custom_spinner_container h-100">
              <div className="spinner-border" role="status"></div>
            </div>
          </>
        ) : (
          <>
            {/* Pinned Message will be displayed here , when clicking on the message it will take you to that message in channelMessages */}

            {channelMessages && channelMessages.length > 0 ? (
              channelMessages
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .map((message, index) => (
                  <div
                    key={index}
                    ref={(el) => (messageRefs.current[message.id] = el)}
                    style={{ marginBottom: "20px" }}
                  >
                    <ChatBubble
                      key={index} // Prefer unique message IDs over index, if available
                      messageInfo={message}
                      getParentMessage={getParentMessage}
                      selectedChannel={selectedChannel}
                      selectedChannelType={selectedChannelType}
                      onDeleteMessage={handleDeleteMessage}
                      onEditMessage={handleEditMessage}
                      onReplyMessage={selectReplyMessage}
                      onPinMessage={handlePinMessage}
                    />
                  </div>
                ))
            ) : (
              <div className="d-flex align-items-center justify-content-center h-100">
                <small>
                  {selectedChannelType === "Topic Chat" &&
                  selectedParticipantId === null
                    ? "Select a Participant and send a message"
                    : selectedChannelType === "Topic Chat" &&
                        selectedParticipantId !== null
                      ? "Send Participant A Message"
                      : "No Messages"}
                </small>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ChatWindow;
