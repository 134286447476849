import React from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteResource } from "../../../../models/resources.model";
import { ResourceData } from "../../../../types";
import { capitalize } from "../../../../utilities/utils";
import "./manageResourceModal.scss";

interface DeleteResourceModalProps {
  closeModal: () => void;
  shown: boolean;
  currentResource?: ResourceData | null;
}

const DeleteResourceModal: React.FC<DeleteResourceModalProps> = ({
  closeModal,
  shown,
  currentResource
}) => {
  const { clientID, studyID } = useParams();

  // Handle the delete confirmation
  const handleDelete = async () => {
    if (clientID && studyID && currentResource) {
      const deleteResponse = await deleteResource(
        clientID,
        studyID,
        currentResource.id
      );

      if (deleteResponse.rStatus === "success") {
        Swal.fire({
          icon: "success",
          title: "Resource deleted successfully",
          confirmButtonColor: "#3085d6",
          didClose: () => {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Resource deletion failed",
          text: `${deleteResponse.rMessage}`,
          confirmButtonColor: "#3085d6"
        });
      }
    } else {
      console.error("Client ID, Study ID, or Resource ID not found");
    }
  };

  return (
    <div
      className={`modal import_participants_modal resource_modal ${shown ? "show" : "hide"}`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <h3 className="modal_page_header">
                  {capitalize("delete")} Resource
                </h3>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="mb-3 mt-1">
                  <label className="form-label mb-1 mx-1">Name:</label>
                  <input
                    className="form-control login_input"
                    type="text"
                    value={currentResource?.name || ""}
                    name="name"
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label mb-1 mx-1">Description:</label>
                  <input
                    className="form-control login_input"
                    type="text"
                    value={currentResource?.description || ""}
                    name="description"
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label mb-1 mx-1">Languages:</label>
                  <input
                    className="form-control login_input"
                    type="text"
                    value={currentResource?.lang_iso.join(", ") || ""}
                    name="languages"
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label mb-1 mx-1">Status:</label>
                  <input
                    className="form-control login_input"
                    type="text"
                    value={currentResource?.status || ""}
                    name="status"
                    readOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className="buttons">
                  <button className="btn btn-danger" onClick={handleDelete}>
                    Delete Resource
                  </button>
                  <button
                    className="btn btn-primary btn_cancel"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteResourceModal;
