// UDIDFilter.tsx
// search and display duplicate UID's

import React, { useEffect, useState } from "react";
interface UDIDFilterProps {
  onUDIDFilterChange: (UIDstatus: boolean) => void;
  filtersCleared: boolean;
}

const UDIDFilter: React.FC<UDIDFilterProps> = ({
  onUDIDFilterChange,
  filtersCleared
}) => {
  const [participantsUDIDFilter, setParticipantsUDIDFilter] =
    useState<boolean>(false);

  const handleUDIDFilter = (checked: boolean) => {
    // set UID filter
    setParticipantsUDIDFilter(checked);
    // console.log(checked);
  };

  useEffect(() => {
    // send UID filter to parent component
    onUDIDFilterChange(participantsUDIDFilter);
  }, [participantsUDIDFilter, onUDIDFilterChange]);

  useEffect(() => {
    // clear UID filter when filters are cleared
    if (filtersCleared) {
      setParticipantsUDIDFilter(false);
    }
  }, [filtersCleared]);

  const checkboxRef = React.useRef(null);

  return (
    // on dropdown click change UID filter
    <div
      className="dropdown dropdown_uid ms-2 btn btn-primary clickable"
      onClick={(e) => {
        // If the click did not come from the checkbox or label, toggle the state
        if (e.target !== checkboxRef.current) {
          handleUDIDFilter(!participantsUDIDFilter);
        }
      }}
    >
      <label className="form_control" ref={checkboxRef}>
        <input
          ref={checkboxRef}
          type="checkbox"
          checked={participantsUDIDFilter}
          onChange={(e) => handleUDIDFilter(e.target.checked)}
        />
        Duplicate UDID
      </label>
    </div>
  );
};

export default UDIDFilter;
