import React, { useEffect, useState } from "react";
import { getPermissionsByRoleID } from "../../../models/roles.model";
import { ResearcherPermission, ResearcherRole } from "../../../types";

type RolePermissionsProps = {
  selectedRole: ResearcherRole;
};

const RolePermissions: React.FC<RolePermissionsProps> = ({ selectedRole }) => {
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(true);
  const [loadingPermissionsError, setLoadingPermissionsError] = useState<
    string | null
  >(null);

  const [permissions, setPermissions] = useState<ResearcherPermission[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoadingPermissions(true);
      setLoadingPermissionsError(null);
      try {
        const jsonData = await getPermissionsByRoleID(selectedRole.id);
        if (typeof jsonData === "string") {
          setLoadingPermissionsError(jsonData);
        } else {
          setPermissions(jsonData);
        }
      } catch (error) {
        console.error("An error occurred while fetching permissions:", error);
        setLoadingPermissionsError(
          "An error occurred while fetching permissions"
        );
      }
      setLoadingPermissions(false);
    };

    if (!selectedRole) {
      setLoadingPermissions(false);
      setLoadingPermissionsError("Role is required");
      return;
    }

    const promises = [fetchPermissions()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingPermissions(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingPermissions(false);
        setLoadingPermissionsError(
          "An error occurred while fetching permissions"
        );
      });
  }, [selectedRole]);

  return (
    <>
      <div className="col-12 preview_heading">
        <h4>{selectedRole.name}</h4>
      </div>
      {loadingPermissions ? (
        <div className="col-12">
          <p className="p-2">Loading permissions...</p>
        </div>
      ) : loadingPermissionsError ? (
        <>
          <div className="col-12">
            <p className="text-danger">{loadingPermissionsError}</p>
          </div>
        </>
      ) : permissions.length === 0 ? (
        <div className="col-12">
          <p className="p-2">No permissions</p>
        </div>
      ) : (
        <div className="col-12">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Permission</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              {permissions.map((permission) => (
                <tr key={permission.id}>
                  <td>{permission.permission}</td>
                  <td>{permission.permission_description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default RolePermissions;
