//  a generic fetch function that takes in a route, type and body and returns the response

import Swal from "sweetalert2";
import { FetchResponse } from "../types";
import {
  getTokensLocalStorage,
  isTokenExpired,
  requestAndSetTokens
} from "../utilities/tokenHandler.util";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

interface FetchOptions {
  body?: any;
  headers?: HeadersInit;
}

// #region Test API Fetcher
// API 'constructor' for models requiring auth
export async function apiFetcherTest<T>(
  route: string,
  method: RequestMethod,
  options?: FetchOptions
): Promise<FetchResponse<T>> {
  const defaultHeaders: HeadersInit = {
    "Content-Type": "application/json"
  };

  let fetchOptions: RequestInit = {
    method: method,
    headers: {
      ...defaultHeaders,
      ...options?.headers
    }
  };

  // get tokens if invalid, request new ones, if can't get new ones, return error
  const { authToken, refreshToken } = await getTokensLocalStorage();
  // Tokens exist, but are expired, request new ones
  if (authToken && refreshToken) {
    if (isTokenExpired(authToken)) {
      const newJwt = { refreshToken };
      // Try to get new tokens, if can't, redirect to login
      try {
        await requestAndSetTokens(newJwt);
      } catch (error) {
        return {
          status: 500,
          data: null,
          error: (error as Error).message
        };
      }
    }
  } else {
    // Tokens don't exist, redirect to login
    // window.location.href = "/login";
  }

  if (options?.body && (method === "POST" || method === "PUT")) {
    // Add authToken and refreshToken to the request body
    options.body.authToken = authToken;
    options.body.refreshToken = refreshToken;
    fetchOptions.body = JSON.stringify(options.body);
  }
  // console.log(options?.body);

  let initialStatus: number | null = null;
  const fullRoute = `${process.env.REACT_APP_API_LOCAL_URL}${route}`;
  try {
    const response = await fetch(fullRoute, fetchOptions);
    // console.log(response, "response");
    initialStatus = response.status; // Store the initial status code
    console.log(initialStatus, "initialStatus");
    if (initialStatus >= 500) {
      // console.log("Server error");
      throw new Error("Server error");
    } else if (initialStatus === 400) {
      // Frontend not sending correct data format
      // console.log("Data format error");
      throw new Error("Data format error");
    } else if (initialStatus === 401) {
      // console.log("Auth failed");
      throw new Error("Auth failed");
    } else if (initialStatus === 404) {
      // console.log("No data found");
      throw new Error("No data found");
    } else if (initialStatus === 409) {
      // console.log("Already exists");
      throw new Error("Already exists");
    } else if (initialStatus === 202) {
      // Accepted, no data returned
      return {
        status: 202,
        data: null
      };
    } else if (initialStatus === 206) {
      // Partially successful
      // Parse the response JSON
      try {
        const data: T = await response.json();
        return {
          status: 206,
          data: data
        };
      } catch {
        return {
          status: 206,
          data: null
        };
      }

      // console.log(data, "data");
      // console.log(response.status, "response.status");
    } else if (initialStatus === 200) {
      // console.log("Success");
      // Parse the response JSON
      const data: T = await response.json();
      // console.log(data, "data");
      // console.log(response.status, "response.status");
      return {
        status: initialStatus, // Use the initial status code
        data: data
      };
    } else {
      // console.log("Unknown error");
      throw new Error("Unknown error");
    }
  } catch (error) {
    type responseIssue = {
      statusCode: number;
      errorMessage: string;
      body: any;
    };
    // use resIssue for debugging
    const resIssue: responseIssue = {
      statusCode: initialStatus || 418,
      errorMessage: (error as Error).message,
      body: options?.body
    };
    console.log(resIssue);
    return {
      status: initialStatus || 418, // Use the initial status code, or 418 if it's null
      data: null,
      error: (error as Error).message
    };
  }
}
// #endregion

// #region API Fetcher
// API 'constructor' for models requiring auth
export async function apiFetcher<T>(
  route: string,
  method: RequestMethod,
  options?: FetchOptions,
  signal?: AbortSignal,
  excludeToken?: boolean
): Promise<FetchResponse<T>> {
  // console.log("---apiFetcher---");
  const defaultHeaders: HeadersInit = {
    "Content-Type": "application/json"
  };

  let fetchOptions: RequestInit = {
    method: method,
    headers: {
      ...defaultHeaders,
      ...options?.headers
    }
  };

  // get tokens if invalid, request new ones, if can't get new ones, return error
  const { authToken, refreshToken } = await getTokensLocalStorage();
  // Tokens exist, but are expired, request new ones
  if (!excludeToken) {
    if (authToken && refreshToken) {
      if (isTokenExpired(authToken)) {
        const newJwt = { refreshToken };
        // Try to get new tokens, if can't, redirect to login
        try {
          await requestAndSetTokens(newJwt);
        } catch (error) {
          return {
            status: 500,
            data: null,
            error: (error as Error).message
          };
        }
      }
    } else {
      // Tokens don't exist, redirect to login
      window.location.href = "/login";
    }
  }

  if (options?.body && (method === "POST" || method === "PUT")) {
    // Add authToken and refreshToken to the request body
    options.body.authToken = !excludeToken ? authToken : undefined;
    options.body.refreshToken = !excludeToken ? refreshToken : undefined;
    fetchOptions.body = JSON.stringify(options.body);
  }
  // console.log(options?.body);

  let initialStatus: number | null = null;
  const API_URL = process.env.REACT_APP_API_URL;
  if (!API_URL) {
    Swal.fire({
      icon: "error",
      title: "Error!",
      html: "<strong>Please contact support</strong><br/>API URL not found",
      showConfirmButton: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6"
    });
    console.error("API URL not found");
    throw new Error("API URL not found");
  }
  const fullRoute = `${API_URL}${route}`;

  // console.log(fullRoute, "fullRoute");
  try {
    const fetchOptionsWithSignal = { ...fetchOptions, signal };

    const response = await fetch(fullRoute, fetchOptionsWithSignal);
    // console.log(response, "response");
    initialStatus = response.status; // Store the initial status code
    // console.log(initialStatus, "initialStatus");
    if (initialStatus >= 500) {
      // console.log("Server error");
      throw new Error("Server error");
    } else if (initialStatus === 400) {
      // Frontend not sending correct data format
      // console.log("Data format error");
      throw new Error("Data format error");
    } else if (initialStatus === 401) {
      // Attempt to request and set one more time, if it fails, redirect to login
      console.log("HIT");
      const { authToken, refreshToken } = await getTokensLocalStorage();
      if (authToken && refreshToken) {
        if (isTokenExpired(authToken)) {
          const newJwt = { refreshToken };
          // Try to get new tokens, if can't, redirect to login
          try {
            await requestAndSetTokens(newJwt);
          } catch (error) {
            console.log(error);
            // window.location.href = "/login";
          }
        } else {
          // window.location.href = "/login";
        }
      } else {
        // Tokens don't exist, redirect to login
        // window.location.href = "/login";
      }
      // console.log("Auth failed");
      throw new Error("Auth failed");
    } else if (initialStatus === 403) {
      // console.log("No data found");
      throw new Error("No Permission");
    } else if (initialStatus === 404) {
      // console.log("No data found");
      throw new Error("No data found");
    } else if (initialStatus === 409) {
      // console.log("Already exists");
      throw new Error("Already exists");
    } else if (initialStatus === 406) {
      // console.log("Not acceptable");
      try {
        const data: T = await response.json();
        return {
          status: 406,
          data: data
        };
      } catch {
        return {
          status: 406,
          data: null
        };
      }
    } else if (initialStatus === 422) {
      throw new Error("Unprocessable Entity");
    } else if (initialStatus === 202) {
      // Accepted, no data returned
      return {
        status: 202,
        data: null
      };
    } else if (initialStatus === 206) {
      // Partially successful
      // Parse the response JSON
      try {
        const data: T = await response.json();
        return {
          status: 206,
          data: data
        };
      } catch {
        return {
          status: 206,
          data: null
        };
      }

      // console.log(data, "data");
      // console.log(response.status, "response.status");
    } else if (initialStatus === 204) {
      // Partially successful
      // Parse the response JSON
      try {
        const data: T = await response.json();
        return {
          status: 204,
          data: data
        };
      } catch {
        return {
          status: 204,
          data: null
        };
      }

      // console.log(data, "data");
      // console.log(response.status, "response.status");
    } else if (initialStatus === 200) {
      // console.log("Success");
      // Parse the response JSON
      const data: T = await response.json();
      // console.log(data, "data");
      // console.log(response.status, "response.status");
      return {
        status: initialStatus, // Use the initial status code
        data: data
      };
    } else {
      // console.log("Unknown error");
      throw new Error("Unknown error");
    }
  } catch (error) {
    console.log("catch error");
    console.log(error);
    // check if the error is an AbortError
    if (error instanceof DOMException && error.name === "AbortError") {
      console.log("Request aborted");
      return {
        status: 0,
        data: null,
        error: "request_aborted"
      };
    }

    // check if there is a network error
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.log("Network error");
      return {
        status: 0,
        data: null,
        error: "network_error"
      };
    }

    type responseIssue = {
      statusCode: number;
      errorMessage: string;
      body: any;
      fullRoute: string;
    };
    // use resIssue for debugging
    const resIssue: responseIssue = {
      fullRoute: fullRoute,
      statusCode: initialStatus || 418,
      errorMessage: (error as Error).message,
      body: options?.body
    };
    console.log(resIssue);
    const acceptableResponses = [
      500, 400, 401, 403, 404, 406, 409, 422, 202, 204, 206, 200
    ];
    if (initialStatus && !acceptableResponses.includes(initialStatus)) {
      Swal.fire({
        icon: "error",
        title: "Unknown response code: " + initialStatus + "!",
        text: "Please contact support",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3f51b5"
      });
    }

    if (initialStatus === 200 || initialStatus === 206) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        html: "<strong>Please contact support</strong><br/>Unable to parse data from server",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3085d6"
      });
    }

    return {
      status:
        initialStatus && acceptableResponses.includes(initialStatus)
          ? initialStatus
          : 418, // Use the initial status if in the acceptableResponses array, else 418
      data: null,
      error: (error as Error).message
    };
  }
}
// #endregion

// #region Token Fetcher
// 'Constructor' for models relating to login
export async function tokenFetcher<T>(
  route: string,
  method: RequestMethod,
  options?: FetchOptions
): Promise<FetchResponse<T>> {
  const defaultHeaders: HeadersInit = {
    "Content-Type": "application/json"
  };
  let fetchOptions: RequestInit = {
    method: method,
    headers: {
      ...defaultHeaders,
      ...options?.headers
    }
  };

  if (options?.body && (method === "POST" || method === "PUT")) {
    fetchOptions.body = JSON.stringify(options.body);
  }

  console.log(fetchOptions.body, "fetchOptions.body");

  let initialStatus: number | null = null;
  try {
    const API_URL = process.env.REACT_APP_API_URL;
    if (!API_URL) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        html: "<strong>Please contact support</strong><br/>API URL not found",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3085d6"
      });
      console.error("API URL not found");
      throw new Error("API URL not found");
    }
    const fullRoute = `${API_URL}${route}`;

    // console.log(fullRoute, "fullRoute");
    const response = await fetch(fullRoute, fetchOptions);
    initialStatus = response.status; // Store the initial status code

    if (!response.ok) {
      const errorData = await response.text();
      throw new Error(errorData);
    }

    if (initialStatus === 200 || initialStatus === 206) {
      const data: T = await response.json();
      return {
        status: response.status,
        data: data
      };
    } else {
      return {
        status: response.status,
        data: null
      };
    }
  } catch (error) {
    console.log(error, "error");

    if (initialStatus === 200) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        html: "<strong>Please contact support</strong><br/>Unable to parse data from server",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#3085d6"
      });
    }

    // check if there is a network error
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.log("Network error");
      return {
        status: 0,
        data: null,
        error: "network_error"
      };
    }

    return {
      // TODO: handle error codes, not just 500
      status: initialStatus || 418,
      data: null,
      error: (error as Error).message
    };
  }
}
// #endregion
