import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  getResearcherByID,
  sendEditedResearcher
} from "../../models/researcher.model";
import ErrorPage from "../../pages/Error/error.page";
import {
  Country,
  Language,
  Researcher,
  ResearcherRole,
  Timezone
} from "../../types";
import "./viewResearcherModal.scss";
import ViewResearcherModalFooter from "./viewResearcherModalFooter";
import ViewResearcherModalForm from "./viewResearcherModalForm";
import ViewResearcherModalHeader from "./viewResearcherModalHeader";
import ViewResearcherModalTable from "./viewResearcherModalTable";

interface ViewResearcherModalProps {
  researcherID: string;
  closeResearcherModal: () => void;
  jsonLanguagesData: Language[];
  jsonCountriesData: Country[];
  jsonTimezonesData: Timezone[];
  shown: boolean;
  tableType: string;
  allRoles: ResearcherRole[];
}

const ViewResearcherModal: React.FC<ViewResearcherModalProps> = ({
  researcherID,
  closeResearcherModal,
  jsonLanguagesData,
  jsonCountriesData,
  jsonTimezonesData,
  shown,
  allRoles
}) => {
  // find researcher by id
  const [researcher, setResearcher] = useState<Researcher | undefined>(
    undefined
  );
  // timezones
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const [editing, setEditing] = useState<boolean>(false);

  const [researcherName, setResearcherName] = useState<string>("");
  const [researcherSurName, setResearcherSurName] = useState<string>("");
  const [researcherMobile, setResearcherMobile] = useState<string>("");
  const [researcherCountryID, setResearcherCountryID] = useState<string>("");
  const [researcherLanguageID, setResearcherLanguageID] = useState<string>("");
  const [researcherTimezone, setResearcherTimezone] = useState<string>("");
  const [researcherRoleID, setResearcherRoleID] = useState<string>("");
  // password
  const [researcherPassword, setResearcherPassword] = useState<string | null>(
    null
  );

  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [canBeSaved, setCanBeSaved] = useState<boolean>(false);

  useEffect(() => {
    // Fetch researcher data from JSON
    // console.log("researcherID", researcherID);
    const fetchResearcherData = async () => {
      // setLoading(true);
      try {
        const jsonData = await getResearcherByID(researcherID);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "26d9e6a03c23f8cc007020e03cb49d8b"
          ]);
        } else {
          setResearcher(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching researcher data:",
          error
        );
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "9df296ff529ec382fcdbacb04120a62f"
        ]);
      }
    };

    const promises = [fetchResearcherData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setResearcher(undefined);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "2587b00d55584bd284d17a4fce630ab3"
        ]);
      });
  }, [researcherID]);

  const handleSave = () => {
    const sendingResearcherUpdate = async () => {
      const researcherIDs: string = researcherID;
      // if (researcherPassword === null || researcherPassword === "") {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error updating researcher",
      //     text: "Password cannot be empty"
      //   });
      //   return;
      // }

      // if (researcherName === null || researcherName === "") {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error updating researcher",
      //     text: "Name cannot be empty"
      //   });
      //   return;
      // }

      // if (researcherSurName === null || researcherSurName === "") {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error updating researcher",
      //     text: "Surname cannot be empty"
      //   });
      //   return;
      // }

      try {
        const response: string = await sendEditedResearcher(
          researcherIDs,
          researcherName,
          researcherSurName,
          researcherMobile,
          researcherCountryID,
          researcherLanguageID,
          researcherTimezone,
          researcherPassword || "",
          researcherRoleID
        );
        if (response !== "success") {
          console.log("response: ", response);
          Swal.fire({
            icon: "error",
            title: "Error updating researcher",
            text: response,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Researcher updated successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // add refresh page button
            showDenyButton: true,
            denyButtonColor: "#3085d6",
            denyButtonText: "Refresh Page"
          }).then((result) => {
            if (result.isDenied) {
              window.location.reload();
            }
          });
        }
      } catch (error) {
        setLoadingError(true);
        console.error(
          "An error occurred while sending researcher update:",
          error
        );
        Swal.fire({
          icon: "error",
          title: "Error updating researcher",
          text: `An error occurred while updating researcher: ${researcherIDs}`
        });
      }
    };
    const promises = [sendingResearcherUpdate()];
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setEditing(false);
        closeResearcherModal();
      })
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
      });
  };

  const handleCancel = () => {
    // rest researcher variables

    setEditing(false);
  };

  return (
    <div className={`modal view_researcher_modal ${shown ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span
            className="close"
            onClick={() => {
              setEditing(false);
              closeResearcherModal();
            }}
          >
            &times;
          </span>
          <div className="container-fluid">
            <h5 className="modal_page_header">Researcher Profile</h5>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : !loadingError && researcher ? (
            <>
              {/* Diplay top section */}
              <ViewResearcherModalHeader
                researcher={researcher}
                jsonCountriesData={jsonCountriesData}
              />
              {!editing ? (
                // If not editing display table with researcher data
                <ViewResearcherModalTable
                  researcher={researcher}
                  jsonCountriesData={jsonCountriesData}
                  jsonLanguagesData={jsonLanguagesData}
                  jsonTimezonesData={jsonTimezonesData}
                  allRoles={allRoles}
                />
              ) : (
                // If editing display form with researcher data
                <ViewResearcherModalForm
                  researcher={researcher}
                  editing={editing}
                  countries={jsonCountriesData}
                  languages={jsonLanguagesData}
                  timezones={jsonTimezonesData}
                  roles={allRoles}
                  // researcher fields
                  researcherName={researcherName}
                  setResearcherName={setResearcherName}
                  researcherSurName={researcherSurName}
                  setResearcherSurName={setResearcherSurName}
                  researcherMobile={researcherMobile}
                  setResearcherMobile={setResearcherMobile}
                  researcherCountryID={researcherCountryID}
                  setResearcherCountryID={setResearcherCountryID}
                  researcherLanguageID={researcherLanguageID}
                  setResearcherLanguageID={setResearcherLanguageID}
                  researcherTimezone={researcherTimezone}
                  setResearcherTimezone={setResearcherTimezone}
                  researcherPassword={researcherPassword}
                  setResearcherPassword={setResearcherPassword}
                  researcherRoleID={researcherRoleID}
                  setResearcherRoleID={setResearcherRoleID}
                  canBeSaved={canBeSaved}
                  setCanBeSaved={setCanBeSaved}
                />
              )}

              {/* Diplay bottom section buttons */}
              <ViewResearcherModalFooter
                editing={editing}
                handleSave={handleSave}
                handleCancel={handleCancel}
                setEditing={setEditing}
                canBeSaved={canBeSaved}
              />
            </>
          ) : (
            <ErrorPage
              errorCode="404"
              error="Researcher cannot be loaded"
              debugCode={loadingErrorText}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewResearcherModal;
