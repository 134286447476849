// If email is different then regex, then leave top function
export async function isEmailValid(email: any) {
  const regex = /^(?!.*\.{2})[\p{L}0-9.-]+@[\p{L}0-9.-]+\.[a-zA-Z]{2,}$/u;

  // try catch block for validating the email
  try {
    // ensure that email is of type string
    if (typeof email !== "string") return false;

    // ensure email is not empty
    if (email === undefined || email === null || email === "") return false;

    if (!email.match(regex)) return false;

    // check that email is more than 5 characters and less than 64 characters
    if (email.length <= 5 || email.length > 64) return false;

    // return true if email is valid
    return true;
  } catch (error) {
    return false;
  }
}

// If passwords are different then leave top function
export async function arePasswordsValid(password1: any, password2: any) {
  // try catch block for validating the password
  try {
    // ensure that password is of type string
    if (typeof password1 !== "string") return false;
    if (typeof password2 !== "string") return false;

    // ensure password is not empty
    if (
      password1 === undefined ||
      password1 === null ||
      password1 === "" ||
      password2 === undefined ||
      password2 === null ||
      password2 === ""
    )
      return false;

    // check that password is more than 5 characters and less than 64 characters
    if (password1.length <= 5 || password1.length > 64) return false;
    if (password2.length <= 5 || password2.length > 64) return false;

    // check if passwords are the same
    if (password1 !== password2) return false;

    // // check for at least one number, one lowercase and one uppercase letter
    // const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    // if (!password1.match(regex)) return false;

    // return true if password is valid
    return true;
  } catch (error) {
    return false;
  }
}

// function to check for empty field
export const isEmpty = (value: string) => {
  if (!value || value === "" || value === undefined || value === null) {
    return true;
  }
  return false;
};
