import { useEffect, useState } from "react";
import { Timezone } from "../../types";
import { getSystemConfigTimezones } from "../../utilities/config.util";
import TimezoneCalculator from "./timezoneCalculator";
import "./timezones.scss";

const TimezonesPage = () => {
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  useEffect(() => {
    // fetch timezones data on component mount

    const fetchTLCData = async () => {
      try {
        const jsonData = await getSystemConfigTimezones();
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching languages");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);

          setTimezones(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };

    const promises = [fetchTLCData()];
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingTimezones(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingTimezones(false);
        setLoadingError(true);
      });
  }, []);

  return (
    // if not loading
    !loadingTimezones ? (
      loadingError ? (
        <div id="timezone_table_page" className="container">
          <h1>Error loading data</h1>
        </div>
      ) : (
        <>
          <TimezoneCalculator timezones={timezones} />
          <div id="timezone_table_page" className="container">
            <h1>Timezones</h1>
            {/* map the times into 4 col */}
            <div className="row">
              {timezones.map((timezone: Timezone, index: number) => (
                <div className="col-12 col-md-6 col-xl-4 row_item" key={index}>
                  <div className="row">
                    <div className="col-2 text-end">
                      {timezone.offset > 0 ? "+" : ""}
                      {timezone.offset}
                    </div>
                    <div className="col-10">{timezone.name}</div>
                  </div>
                </div>
              ))}
            </div>
            <br />
          </div>
        </>
      )
    ) : (
      // if loading
      <div className="d-flex justify-content-center custom_spinner_container full_page">
        <div className="spinner-border" role="status"></div>
      </div>
    )
  );
};

export default TimezonesPage;
