import React, { useEffect, useState } from "react";
import CategoryContainer from "./Categories/categoriesContainer.component";
import EditTranslationModal from "./Modals/editTranslationModal.component";
import PhraseContainer from "./Phrases/phrasesContainer.component";
import TranslationContainer from "./TranslationDisplay/translationsContainer.component";
import "./languageLibrary.scss";
// import ChannelContainer from "./Channels/channelsContainer.component";
// import ParticipantsContainer from "./Participants/participantsContainer.component";
// import ConversationContainer from "./Conversation/conversationContainer.component";
// import { fetchAllLanguageLibraryCategoryTypes } from "../../models/translations.model";
import {
  ConfigPhraseCategory,
  Language,
  LanguageLibraryPhrase,
  LanguageLibraryTranslation
} from "../../types";
import { getSystemConfigLanguages } from "../../utilities/config.util";
import "../Tables/tableComponents.scss";
import AddCategoryModal from "./Modals/addCategoryModal.component";
import "./Modals/addModals.scss";
import AddPhraseModal from "./Modals/addPhraseModal.component";
import ImportPhraseModal from "./Modals/importPhraseModal.component";

export type LanguageLibraryProps = {
  // studyClicked: string;
};

const LanguageLibrary: React.FC<LanguageLibraryProps> = () => {
  const [selectedPhrase, setSelectedPhrase] =
    useState<LanguageLibraryPhrase | null>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<ConfigPhraseCategory | null>(null);
  const [loadingCategory, setLoadingCategory] = useState(true); // Set initial state to true
  const [loadingPhrase, setLoadingPhrase] = useState(true); // Set initial state to true
  const [loadingTranslation, setLoadingTranslation] = useState(true); // Set initial state to true
  const [showAddCategoryModal, setShowAddCategoryModal] =
    useState<boolean>(false);
  const [showAddPhraseModal, setShowAddPhraseModal] = useState<boolean>(false);
  const [showEditTranslationModal, setShowEditTranslationModal] =
    useState<boolean>(false);
  const [selectedEditingTranslation, setSelectedEditingTranslation] =
    useState<LanguageLibraryTranslation | null>(null);
  const [showImportPhraseModal, setShowImportPhraseModal] =
    useState<boolean>(false);

  const [allLanguages, setAllLanguages] = useState<Language[]>([]); // Set initial state to null

  const [loading, setLoading] = useState<boolean>(true); // Set initial state to true
  const [loadingError, setLoadingError] = useState<boolean>(true);

  // State to show if translation has been edited
  const [translationEdited, setTranslationEdited] = useState<boolean>(false);

  // useEffect(() => {
  //   console.log("selectedCategoryID", selectedCategoryID);
  //   console.log("selectedPhraseID", selectedPhraseID);
  // }, [selectedPhraseID, selectedCategoryID]);

  useEffect(() => {
    const fetchTLCData = async () => {
      try {
        const jsonData: false | Language[] = await getSystemConfigLanguages();
        // if jsonData is a false, then there was an error
        if (!jsonData) {
          // console.log("jsonData is false");
          throw new Error("Error fetching languages");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          // setAllLanguages(jsonData);
          // hide EN
          setAllLanguages(
            jsonData.filter((language) => language.iso_code !== "EN")
          );

          setLoadingError(false);
        }
      } catch (error) {
        console.error("An error occurred while fetching config data:", error);
        setLoadingError(true);
      }
    };

    const promises = [fetchTLCData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingError(true);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {/* display spinner if loading */}
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : // display error if error
      loadingError ? (
        <div className="error_container">
          <div className="error_text">
            An error occurred while fetching config data. Please log out and try
            again.
          </div>
        </div>
      ) : (
        <>
          <div className="row language_library_container">
            <div className="col-12 col-md-2 col_1">
              <CategoryContainer
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                loadingCategory={loadingCategory}
                setLoadingCategory={setLoadingCategory}
                handleAddPhraseClick={() => {
                  setShowImportPhraseModal(true);
                }}
              />
            </div>
            <div className="col-12 col-md-5 col_2">
              <PhraseContainer
                categoryID={selectedCategory?.id || ""}
                selectedPhrase={selectedPhrase}
                setSelectedPhrase={setSelectedPhrase}
                loadingCategory={loadingCategory}
                loadingPhrase={loadingPhrase}
                setLoadingPhrase={setLoadingPhrase}
                handleAddPhraseClick={() => {
                  // console.log("handleAddPhraseClick");
                  setShowAddPhraseModal(true);
                }}
              />
            </div>
            <div className="col-12 col-md-5 col_3">
              <TranslationContainer
                selectedPhrase={selectedPhrase}
                loadingPhrase={loadingPhrase}
                loadingTranslation={loadingTranslation}
                setLoadingTranslation={setLoadingTranslation}
                handleEditTranslationClick={(LanguageLibraryTranslation) => {
                  setShowEditTranslationModal(true);
                  setSelectedEditingTranslation(LanguageLibraryTranslation);
                }}
                allLanguages={allLanguages}
                translationEdited={translationEdited}
                setTranslationEdited={setTranslationEdited}
              />
            </div>
          </div>
          {showAddCategoryModal && (
            <AddCategoryModal
              show={showAddCategoryModal}
              handleClose={() => {
                setShowAddCategoryModal(false);
              }}
            />
          )}
          {showAddPhraseModal && selectedCategory && (
            <AddPhraseModal
              show={showAddPhraseModal}
              handleClose={() => {
                setShowAddPhraseModal(false);
              }}
              selectedCategoryID={selectedCategory.id}
              allLanguages={allLanguages}
            />
          )}
          {showEditTranslationModal && selectedEditingTranslation && (
            <EditTranslationModal
              show={showEditTranslationModal}
              handleClose={() => {
                setShowEditTranslationModal(false);
              }}
              selectedTranslation={selectedEditingTranslation}
              allLanguages={allLanguages}
              setTranslationEdited={setTranslationEdited}
            />
          )}
          {showImportPhraseModal && selectedCategory && (
            <ImportPhraseModal
              show={showImportPhraseModal}
              handleClose={() => {
                setShowImportPhraseModal(false);
              }}
              allLanguages={allLanguages}
            />
          )}
        </>
      )}
    </>
  );
};

export default LanguageLibrary;
