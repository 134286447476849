import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../utilities/utils";

interface MultipleParticipantIDsFilterProps {
  jsonData: string[];
  onFilterChange: (vals: string[]) => void;
  filtersCleared: boolean;
  location: string;
  name: string;
}

const MultipleParticipantIDsFilter: React.FC<
  MultipleParticipantIDsFilterProps
> = ({ jsonData, onFilterChange, filtersCleared, location, name }) => {
  const [search, setSearch] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>(""); // New state to hold the textarea value
  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = (show: boolean) => {
    setDropdown(show);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    // Split the inputValue into an array, separating by new lines, commas, and spaces
    const idsArray = inputValue.split(/[\r\n, ]+/).map((item) => item.trim());
    // Filter out any empty strings that might result from consecutive delimiters or spaces
    const filteredIdsArray = idsArray.filter((item) => item !== "");

    // Update the search state with the array of IDs and send to parent
    setSearch(filteredIdsArray);
    onFilterChange(filteredIdsArray);
  };

  useEffect(() => {
    setTimeout(() => {
      const storedData = sessionStorage.getItem("filterData");
      if (storedData) {
        const filterData = JSON.parse(storedData);

        // Assuming filterData.bucketParticipants is directly an array of participant IDs
        const participantIDs = filterData.bucketParticipants.join(", ");

        // Set the concatenated string of participant IDs as the input value,
        // then call handleSubmit to process this data.
        setInputValue(participantIDs);

        // Since setInputValue is asynchronous, you might want to directly work with the data
        // or ensure handleSubmit uses the latest state in another way.
        // Directly use participantIDs for immediate action
        const idsArray = participantIDs
          .split(/[\r\n, ]+/)
          .map((item: any) => item.trim());
        const filteredIdsArray = idsArray.filter((item: any) => item !== "");
        setSearch(filteredIdsArray);
        onFilterChange(filteredIdsArray);
      }
      sessionStorage.removeItem("filterData"); // Clear the data after use
    }, 500);
  }, []);

  useEffect(() => {
    if (filtersCleared) {
      setSearch([]);
      setInputValue(""); // Clear the input value as well
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    setDropdown(false);
  });

  return (
    <div className="dropdown dropdown_participant_ids" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleDropdown(!dropdown)}
      >
        {name}
      </button>
      <div className={`dropdown-menu ${dropdown ? "show" : "hide"}`}>
        <>
          <textarea
            id="participant_ids_input"
            placeholder={`Search ${name}`}
            value={inputValue}
            onChange={handleInputChange}
          />

          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={inputValue.length < 1}
          >
            Search
          </button>
        </>
      </div>
    </div>
  );
};

export default MultipleParticipantIDsFilter;
