import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { IconBroom, IconDelete } from "../../../../assets/images/icons/icons";
import { usePermissions } from "../../../../contexts/UserContext";
import {
  ParticipantTag,
  StudySurveysFilters,
  Tag,
  TypeTagDetails,
  addTagToParticipant,
  deleteTag,
  editTag,
  fetchStudyTagsDetails,
  fetchTagDetails,
  removeTagFromParticipant
} from "../../../../models/tag.model";
import { ParamsSurveyPage } from "../../../../types";
import Dropdown from "../../../Dropdown/dropdown.component";
import MultipleDropdown from "../../../Dropdown/multipleDropdown.component";
import LoadingPencil from "../../../Loaders/LoadingPencil";
import PaginationNavigation from "../../../Tables/PaginationNavigation/paginationNavigation";
import "./tagDetails.scss";

interface TagDetailsProps {
  currentTagID: string;
  tags: Tag[];
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

//const TagDetails: React.FC<{ tagID: string, tags: Tag[], refresh: boolean, setRefresh: (refresh: boolean) => void }> = ({ tagID, tags, refresh, setRefresh }) => { this is works but its not the best way to do it
const TagDetails: React.FC<TagDetailsProps> = ({
  currentTagID,
  tags,
  refresh,
  setRefresh
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { clientID, studyID, surveyID } = useParams<ParamsSurveyPage>();
  const [error, setError] = useState<String>("");
  //Tag details
  const [tagDetails, setTagDetails] = useState<TypeTagDetails>();
  const [filteredTags, setFilteredTags] = useState<StudySurveysFilters>();
  const [needsToBeTagged, setNeedsToBeTagged] = useState<ParticipantTag[]>([]);
  const [unidentifiedUsers, setUnidentifiedUsers] = useState<string[]>([]);
  const [alreadyTagged, setAlreadyTagged] = useState<ParticipantTag[]>([]);
  //Filtered participants
  //Tag filters
  const [nameFilter, setNameFilter] = useState<string[]>([]);
  const [progressFilter, setProgressFilter] = useState<string[]>([]);
  const statusFilter: string[] = [
    // Disused filters disabled
    // "Active",
    // "Pending Invitation",
    "Blocked",
    // "Declined",
    "In Progress"
    // "Completed",
    // "Invitation Not Sent",
    // "App Invitation Sent"
    // "Flagged",
  ];
  const [searchTag, setSearchTag] = useState<string>("");
  //Dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedProgress, setSelectedProgress] = useState<string[]>([]);
  //Pagination for tagged
  const [filteredTaggedParticipants, setFilteredTaggedParticipants] = useState<
    ParticipantTag[]
  >([]); //This is the array that will be filtered
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentTaggedResults = filteredTaggedParticipants?.slice(
    indexOfFirstResult,
    indexOfLastResult
  );
  //Pagination for non-tagged participants
  const [filteredNonTaggedParticipants, setFilteredNonTaggedParticipants] =
    useState<ParticipantTag[]>([]); //This is the array that will be filtered
  const [currentPageNonTagged, setCurrentPageNonTagged] = useState<number>(1);
  const [resultsPerPageNonTagged, setResultsPerPageNonTagged] =
    useState<number>(10);
  const indexOfLastResultNonTagged =
    currentPageNonTagged * resultsPerPageNonTagged;
  const indexOfFirstResultNonTagged =
    indexOfLastResultNonTagged - resultsPerPageNonTagged;
  const currentNonTaggedResults = filteredNonTaggedParticipants?.slice(
    indexOfFirstResultNonTagged,
    indexOfLastResultNonTagged
  );
  //Handle checked participants
  const [selectedTaggedParticipants, setSelectedTaggedParticipants] = useState<
    ParticipantTag[]
  >([]);
  const [selectedNonTaggedParticipants, setSelectedNonTaggedParticipants] =
    useState<ParticipantTag[]>([]);

  const { hasPermission } = usePermissions();

  /*----------------------------------------------------------------*/
  /*----------------------Loading tag filters ----------------------*/
  const getTagFilters = async () => {
    try {
      setIsLoading(true);
      if (clientID && studyID && currentTagID) {
        const data = await fetchStudyTagsDetails(clientID, studyID);
        if (typeof data === "string") {
          setError(data);
        } else {
          await SetTagFilters(data);
          setFilteredTags(data);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setError("Error fetching data");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTagFilters();
  }, []);
  //Set filters
  function SetTagFilters(data: StudySurveysFilters) {
    if (data) {
      //we want to pass the study name and the survey names  as well as there id to the dropdown
      const studyName = data.study?.study_name || "";
      //sort surveys by updated_at in descending order
      const surveyNames =
        data.surveys
          ?.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB.getTime() - dateA.getTime();
          })
          .map((survey) => survey.survey_name) || [];
      setNameFilter([studyName, ...surveyNames]);
    }
    //set progress filter from data?.study?.bucket which is an array
    setProgressFilter(data?.study?.bucket as string[]);
  }
  /*----------------------Loading tag details ----------------------*/
  const getTagDetails = async (tagID: string) => {
    try {
      setIsLoading(true);
      if (clientID && studyID && tagID) {
        const data = await fetchTagDetails(clientID, studyID, tagID);
        if (typeof data === "string") {
          setError(data);
        } else {
          setTagDetails(data);
          //Senpai is this the best way to do it? Should I use a function called setData where is set the data instead?
          setFilteredNonTaggedParticipants(
            data?.participantsWithoutTag ? data.participantsWithoutTag : []
          );
          setFilteredTaggedParticipants(
            data?.participantsWithTag ? data.participantsWithTag : []
          );
          setError("");
          //buildFilterCache(data);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setError("Error fetching data");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setSelectedNonTaggedParticipants([]);
    setSelectedTaggedParticipants([]);
    getTagDetails(currentTagID);
  }, [currentTagID]);

  /*----------------------------------------------------------------*/
  /*----------------------------------------------------------------*/
  /* -----------------Filtering tagged participants---------------- */
  //Dropdown
  //Handle filters and search
  const handleFilters = (filter: string) => {
    setSelectedName(filter);
    setShowDropdown(false);
  };
  //Dropdown for status
  const handleStatusToggled = (option: string) => {
    if (selectedStatus.includes(option)) {
      setSelectedStatus((prev) => prev.filter((item) => item !== option));
    } else {
      setSelectedStatus((prev) => [...prev, option]);
    }
  };
  const handleClearFilters = () => {
    setSelectedName("");
    setSelectedStatus([]);
    setSelectedProgress([]);
    setSearchTag("");
  };

  //Dropdown for progress
  const handleProgressToggled = (option: string) => {
    if (selectedProgress.includes(option)) {
      setSelectedProgress((prev) => prev.filter((item) => item !== option));
    } else {
      setSelectedProgress((prev) => [...prev, option]);
    }
  };
  //Filtering data
  useEffect(() => {
    if (!tagDetails) return;

    let filteredTaggedParticipants = tagDetails?.participantsWithTag;
    let filteredNonTaggedParticipants = tagDetails?.participantsWithoutTag;
    // Filter by name
    if (filteredTaggedParticipants && filteredTaggedParticipants.length > 0) {
      // Find the selected study or survey by name
      const selectedStudy =
        filteredTags?.study?.study_name === selectedName
          ? filteredTags?.study
          : null;
      const selectedSurvey = filteredTags?.surveys?.find(
        (survey) => survey.survey_name === selectedName
      );

      if (selectedStudy) {
        // If a matching study is found, filter participants by study_id
        filteredTaggedParticipants = filteredTaggedParticipants.filter(
          (participant) => participant.study_id === selectedStudy.id
        );
      } else if (selectedSurvey) {
        // If a matching survey is found, filter participants by participant_surveys
        filteredTaggedParticipants = filteredTaggedParticipants.filter(
          (participant) =>
            participant.participant_surveys.includes(selectedSurvey.id)
        );
      }
      //Filter by status
      if (selectedStatus.length > 0) {
        filteredTaggedParticipants = filteredTaggedParticipants.filter(
          (participant) =>
            selectedStatus
              .map((status) => status.toLowerCase())
              .includes(participant.study_status.toLowerCase())
        );
      }

      // Filter by progress
      if (selectedProgress.length > 0) {
        filteredTaggedParticipants = filteredTaggedParticipants.filter(
          (participant) =>
            participant?.participant_buckets?.some((bucket) =>
              selectedProgress
                .map((progress) => progress.toLowerCase())
                .includes(bucket.name.toLowerCase())
            )
        );
      }
      //Filter by search
      if (searchTag) {
        filteredTaggedParticipants = filteredTaggedParticipants.filter(
          (participant) =>
            participant.participant_full_name
              .toLowerCase()
              .includes(searchTag.toLowerCase()) ||
            participant.participant_full_mobile
              .toLowerCase()
              .includes(searchTag.toLowerCase())
          // participant.participant_email
          //   .toLowerCase()
          //   .includes(searchTag.toLowerCase())
        );
      }
      setFilteredTaggedParticipants(filteredTaggedParticipants);
    }

    //Filter for Non tagged table
    // Filter by name
    if (
      filteredNonTaggedParticipants &&
      filteredNonTaggedParticipants.length > 0
    ) {
      // Find the selected study or survey by name
      const selectedStudy =
        filteredTags?.study?.study_name === selectedName
          ? filteredTags.study
          : null;
      const selectedSurvey = filteredTags?.surveys?.find(
        (survey) => survey.survey_name === selectedName
      );

      if (selectedStudy) {
        // If a matching study is found, filter participants by study_id
        filteredNonTaggedParticipants = filteredNonTaggedParticipants.filter(
          (participant) => participant.study_id === selectedStudy.id
        );
      } else if (selectedSurvey) {
        // If a matching survey is found, filter participants by participant_surveys
        filteredNonTaggedParticipants = filteredNonTaggedParticipants.filter(
          (participant) =>
            participant.participant_surveys.includes(selectedSurvey.id)
        );
      }
      //Filter by status
      if (selectedStatus.length > 0) {
        filteredNonTaggedParticipants = filteredNonTaggedParticipants.filter(
          (participant) =>
            selectedStatus
              .map((status) => status.toLowerCase())
              .includes(participant.study_status.toLowerCase())
        );
      }

      // Filter by progress
      if (selectedProgress.length > 0) {
        filteredNonTaggedParticipants = filteredNonTaggedParticipants.filter(
          (participant) =>
            participant?.participant_buckets?.some((bucket) =>
              selectedProgress
                .map((progress) => progress.toLowerCase())
                .includes(bucket.name.toLowerCase())
            )
        );
      }
      //Filter by search
      if (searchTag) {
        filteredNonTaggedParticipants = filteredNonTaggedParticipants.filter(
          (participant) =>
            participant.participant_full_name
              .toLowerCase()
              .includes(searchTag.toLowerCase()) ||
            participant.participant_full_mobile
              .toLowerCase()
              .includes(searchTag.toLowerCase()) ||
            participant.participant_email
              .toLowerCase()
              .includes(searchTag.toLowerCase())
        );
      }
      setFilteredNonTaggedParticipants(filteredNonTaggedParticipants);
    }
  }, [tagDetails, selectedName, selectedStatus, selectedProgress, searchTag]);

  /* -----------------------Function handlers----------------------- */
  //Edit tag
  const handleEditTag = async (tagID: string) => {
    const tagRegex = /^[A-Za-z0-9]{2}[A-Za-z0-9\s-]{0,58}$/;
    try {
      const { value: tagName } = await Swal.fire({
        title: "Edit Tag Name",
        input: "text",
        inputValue: tagDetails?.tag.name,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        inputValidator: (value) => {
          if (!value) {
            return "Please enter a valid name";
          }
          if (!tagRegex.test(value)) {
            return "Tag name must be between 4 and 60 characters long and cannot contain any special characters";
          }
          if (value === tagDetails?.tag.name) {
            return "You need to write something different!";
          }
          if (
            tags
              .map((tag) => tag.name.toLowerCase())
              .includes(value.toLowerCase())
          ) {
            return "Tag name already exists";
          }
        }
      });
      if (tagName) {
        const data = await editTag(
          clientID as string,
          studyID as string,
          tagID,
          tagName
        );
        if (data === "success") {
          Swal.fire({
            icon: "success",
            title: "Tag edited successfully",
            text: `Tag ${tagName} has been edited`,
            confirmButtonColor: "#3085d6"
          });
          //update tags
          setRefresh(!refresh);
          getTagDetails(tagID);
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to add tag",
            text: data,
            confirmButtonColor: "#3085d6"
          });
        }
      }
    } catch (e) {
      console.log("Error editing tag");
    }
  };
  //Delete tag
  const handleDeleteTag = async (tagID: string) => {
    try {
      const { value: confirm } = await Swal.fire({
        title: "Delete Tag",
        text: "Are you sure you wish to delete this tags?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6"
      });
      if (confirm) {
        const data = await deleteTag(clientID as string, [tagID]);
        if (data === "success") {
          Swal.fire({
            icon: "success",
            title: "Tag deleted successfully",
            text: `Tag ${tagDetails?.tag.name} has been deleted`,
            confirmButtonColor: "#3085d6"
          });
          setRefresh(!refresh);
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to delete tag",
            text: data,
            confirmButtonColor: "#3085d6"
          });
        }
      }
    } catch (e) {
      console.log("Error deleting tag");
    }
  };
  //Add participant to tag
  const handleAddParticipant = async (participantID: string) => {
    try {
      const data = await addTagToParticipant(
        clientID as string,
        studyID as string,
        currentTagID,
        [participantID]
      );
      if (data === "success") {
        Swal.fire({
          icon: "success",
          title: "Participant added successfully",
          text: `Participant has been added to tag ${tagDetails?.tag.name}`,
          confirmButtonColor: "#3085d6"
        });
        //update tags
        getTagDetails(currentTagID);
        setCurrentPage(1);
        setCurrentPageNonTagged(1);
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to add participant",
          text: data,
          confirmButtonColor: "#3085d6"
        });
      }
    } catch (e) {
      console.log("Error adding participant");
    }
  };
  //Remove participant from tag
  const handleRemoveParticipant = async (participantID: string) => {
    try {
      const data = await removeTagFromParticipant(
        clientID as string,
        studyID as string,
        currentTagID,
        [participantID]
      );
      if (data === "success") {
        Swal.fire({
          icon: "success",
          title: "Participant removed successfully",
          text: `Participant has been removed from tag ${tagDetails?.tag.name}`,
          confirmButtonColor: "#3085d6"
        });
        //update tags
        getTagDetails(currentTagID);
        setCurrentPage(1);
        setCurrentPageNonTagged(1);
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to remove participant",
          text: data,
          confirmButtonColor: "#3085d6"
        });
      }
    } catch (e) {
      console.log("Error removing participant");
    }
  };
  //Clear advanced adding
  function clearAdvancedAdding() {
    setNeedsToBeTagged([]);
    setAlreadyTagged([]);
    setUnidentifiedUsers([]);
  }
  /*-----------------------Download CSV----------------------- */
  //Download CSV for tagged participants

  function downloadXlsx(type: string) {
    if (type === "tagged") {
      const taggedParticipants = tagDetails?.participantsWithTag;
      if (taggedParticipants && taggedParticipants.length > 0) {
        // Modify headers and order of columns
        const modifiedTaggedParticipants = taggedParticipants.map(
          (participant) => ({
            participant_id: participant.id,
            name: participant.participant_full_name,
            mobile: participant.participant_full_mobile,
            email: participant.participant_email,
            tags: participant.participant_tags.join(", "),
            bucket: participant.participant_buckets,
            study_progress: participant.study_status,
            participant_surveys: participant.participant_surveys.join(", "),
            study_id: participant.study_id
          })
        );

        const worksheet = XLSX.utils.json_to_sheet(modifiedTaggedParticipants);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "Tagged Participants"
        );
        const xlsxBuffer = XLSX.write(workbook, {
          type: "buffer",
          bookType: "xlsx"
        });
        const blob = new Blob([xlsxBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${tagDetails?.tag.name}-tagged-participants.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        Swal.fire({
          icon: "warning",
          title: "No tagged participants to download",
          text: `There are no tagged participants to download for tag ${tagDetails?.tag.name}`,
          confirmButtonColor: "#3085d6"
        });
      }
    } else if (type === "nonTagged") {
      const nonTaggedParticipants = tagDetails?.participantsWithoutTag;

      if (nonTaggedParticipants && nonTaggedParticipants.length > 0) {
        // Modify headers and order of columns
        const modifiedNonTaggedParticipants = nonTaggedParticipants.map(
          (participant) => ({
            participant_id: participant.id,
            name: participant.participant_full_name,
            mobile: participant.participant_full_mobile,
            email: participant.participant_email,
            tags: participant.participant_tags.join(", "),
            bucket: participant.participant_buckets,
            study_progress: participant.study_status,
            participant_surveys: participant.participant_surveys.join(", "),
            study_id: participant.study_id
          })
        );

        const worksheet = XLSX.utils.json_to_sheet(
          modifiedNonTaggedParticipants
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "Non-Tagged Participants"
        );
        const xlsxBuffer = XLSX.write(workbook, {
          type: "buffer",
          bookType: "xlsx"
        });
        const blob = new Blob([xlsxBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${tagDetails?.tag.name}-non-tagged-participants.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        Swal.fire({
          icon: "warning",
          title: "No non-tagged participants to download",
          text: `There are no non-tagged participants to download for tag ${tagDetails?.tag.name}`,
          confirmButtonColor: "#3085d6"
        });
      }
    } else {
      console.log("Error downloading xlsx");
    }
  }
  /*-----------------------Advanced adding----------------------- */
  const advancedAdding = async () => {
    //Check for worker first
    let workerID = localStorage.getItem("workerID");
    if (workerID && workerID !== null) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    try {
      await Swal.fire({
        title: "Advanced Adding",
        text: "Please enter a list of participant IDs separated by a comma, space or new line",
        input: "textarea",
        inputPlaceholder: "Participant IDs",
        inputAttributes: {
          "aria-label": "Participant IDs"
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        //This only runs on confirm
        inputValidator: (value) => {
          //Validating the input
          if (!value) {
            return "Please enter a list of participant IDs";
          }
          let idRegex = /^[0-9\s,]+$/;
          if (value && !idRegex.test(value)) {
            return "Participant IDs must be numbers";
          }
          //Splitting the data if it is valid
          if (value) {
            let addedParticipantsIDs = value.split(/[\s,]+/);
            //remove duplication
            addedParticipantsIDs = [...new Set(addedParticipantsIDs)];
            //Looping through the participants to split them into the 3 arrays needsToBeTagged which get the whole have the whole object, unidentifiedUsers which only gets the id and alreadyTagged which gets the whole object
            if (addedParticipantsIDs && addedParticipantsIDs.length > 0) {
              for (let i = 0; i < addedParticipantsIDs.length; i++) {
                if (tagDetails?.participantsWithTag) {
                  if (
                    tagDetails.participantsWithTag
                      .map((participant) => participant.id)
                      .includes(addedParticipantsIDs[i])
                  ) {
                    alreadyTagged.push(
                      tagDetails.participantsWithTag.filter(
                        (participantObj) =>
                          participantObj.id === addedParticipantsIDs[i]
                      )[0]
                    );
                  } else if (
                    tagDetails?.participantsWithoutTag
                      ?.map((participant) => participant.id)
                      .includes(addedParticipantsIDs[i])
                  ) {
                    needsToBeTagged.push(
                      tagDetails?.participantsWithoutTag.filter(
                        (participantObj) =>
                          participantObj.id === addedParticipantsIDs[i]
                      )[0]
                    );
                  } else {
                    unidentifiedUsers.push(addedParticipantsIDs[i]);
                  }
                } else {
                  unidentifiedUsers.push(addedParticipantsIDs[i]);
                }
              }
            } else {
              return "Failed to split participants";
            }
          }
        }
      }).then((result) => {
        //If the result is confirmed then we want to display them in three lists
        const needsToBeTaggedList =
          needsToBeTagged && needsToBeTagged.length > 0
            ? needsToBeTagged
                .map(
                  (participant) =>
                    participant.id + " " + participant.participant_email
                )
                .join("\n")
            : "No participants to add";
        const alreadyTaggedList =
          alreadyTagged && alreadyTagged.length > 0
            ? alreadyTagged
                .map(
                  (participant) =>
                    participant.id + " " + participant.participant_email
                )
                .join("\n")
            : "No duplicate participants";
        const unidentifiedUsersList =
          unidentifiedUsers && unidentifiedUsers.length > 0
            ? unidentifiedUsers.join("\n")
            : "No unidentified users";
        let extraInfo = `By selecting "Continue", you will proceed to add ${
          needsToBeTagged?.length ? needsToBeTagged.length : 0
        }/${
          tagDetails?.participantsWithoutTag?.length
            ? tagDetails.participantsWithoutTag.length
            : 0
        } participants to ${tagDetails?.tag.name}`;
        if (result.isConfirmed) {
          Swal.fire({
            title: "Advanced Adding",
            html:
              '<div class="advanced_adding_body">Below, you will find the details of participants to be included in the tagging process.</div>' +
              `<div class="advanced_adding_info">${extraInfo}` +
              '<div class="advanced_adding">' +
              '<div class="advanced_adding_column">' +
              '<div class="advanced_adding_title">Pending Tag Addition</div>' +
              '<div class="advanced_adding_content">' +
              '<textarea class="advanced_adding_textarea_success" id="needsToBeTagged" readonly>' +
              needsToBeTaggedList +
              "</textarea>" +
              "</div>" +
              "</div>" +
              '<div class="advanced_adding_column">' +
              '<div class="advanced_adding_title">Already Tagged</div>' +
              '<div class="advanced_adding_content">' +
              '<textarea class="advanced_adding_textarea_warning" id="alreadyTagged" readonly>' +
              alreadyTaggedList +
              "</textarea>" +
              "</div>" +
              "</div>" +
              '<div class="advanced_adding_column">' +
              '<div class="advanced_adding_title">Unidentified User IDs</div>' +
              '<div class="advanced_adding_content">' +
              '<textarea class="advanced_adding_textarea_failed" id="unidentifiedUsers" readonly>' +
              unidentifiedUsersList +
              "</textarea>" +
              "</div>" +
              "</div>" +
              "</div>",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
            cancelButtonText: "Cancel"
          }).then(async (result) => {
            if (result.isConfirmed) {
              //array of needsToBeTagged just the id
              if (needsToBeTagged && needsToBeTagged.length > 0) {
                let neededTagsID = needsToBeTagged.map(
                  (participant) => participant.id
                );
                const data = await addTagToParticipant(
                  clientID as string,
                  studyID as string,
                  currentTagID,
                  neededTagsID
                );
                Swal.fire({
                  title: "Sending request...",
                  text: "Please wait",
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  willOpen: () => {
                    Swal.showLoading();
                  }
                });
                if (data === "successfulBulk") {
                  Swal.fire({
                    icon: "success",
                    title: "Worker has been created",
                    html: "Please be patient, as it may take a few moments for the new data to be displayed",
                    confirmButtonColor: "#3085d6"
                  });
                } else if (data === "success") {
                  Swal.fire({
                    icon: "success",
                    title: "Participants ",
                    text: `Participants have been added to tag ${tagDetails?.tag.name}`,
                    confirmButtonColor: "#3085d6"
                  });
                  getTagDetails(currentTagID);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Failed to add participants",
                    confirmButtonColor: "#3085d6"
                  });
                }
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "No participants found",
                  text: "We detect no participants needing to be added",
                  confirmButtonColor: "#3085d6"
                });
              }
              clearAdvancedAdding();
            } else {
              clearAdvancedAdding();
            }
          });
        }
      });
    } catch (e) {
      console.log("Error adding participant");
    }
  };
  //Select all tagged participants
  // Function to select all participants
  const selectAllTaggedParticipants = () => {
    if (
      filteredTaggedParticipants.length !== selectedTaggedParticipants.length
    ) {
      setSelectedTaggedParticipants(filteredTaggedParticipants);
    } else {
      setSelectedTaggedParticipants([]);
    }
  };

  // Function to select a single participant
  const selectSingleParticipant = (participant: ParticipantTag) => {
    if (selectedTaggedParticipants.includes(participant)) {
      setSelectedTaggedParticipants(
        selectedTaggedParticipants.filter((p) => p !== participant)
      );
    } else {
      setSelectedTaggedParticipants([
        ...selectedTaggedParticipants,
        participant
      ]);
    }
  };
  //Select all non-tagged participants
  // Function to select all non-tagged participants
  const selectAllNonTaggedParticipants = () => {
    if (
      filteredNonTaggedParticipants.length !==
      selectedNonTaggedParticipants.length
    ) {
      setSelectedNonTaggedParticipants(filteredNonTaggedParticipants);
    } else {
      setSelectedNonTaggedParticipants([]);
    }
  };
  //Function to select a single non-tagged participant
  const selectSingleNonTaggedParticipant = (participant: ParticipantTag) => {
    if (selectedNonTaggedParticipants.includes(participant)) {
      setSelectedNonTaggedParticipants(
        selectedNonTaggedParticipants.filter((p) => p !== participant)
      );
    } else {
      setSelectedNonTaggedParticipants([
        ...selectedNonTaggedParticipants,
        participant
      ]);
    }
  };

  const bulkRemove = async () => {
    //Check for worker first
    if (selectedTaggedParticipants && selectedTaggedParticipants.length > 0) {
      let workerID = localStorage.getItem("workerID");
      if (workerID && workerID !== null) {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
        return;
      } else {
        let participantIDs = selectedTaggedParticipants.map(
          (participant) => participant.id
        );
        Swal.fire({
          title: "Are you sure?",
          text: `You are removing ${selectedTaggedParticipants.length} selected participants from this tag`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33"
        }).then(async (result) => {
          if (result.isConfirmed) {
            const data = await removeTagFromParticipant(
              clientID as string,
              studyID as string,
              currentTagID,
              participantIDs
            );
            Swal.fire({
              title: "Sending request...",
              text: "Please wait",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              }
            });
            if (data === "successfulBulk") {
              Swal.fire({
                icon: "success",
                title: "Worker has been created",
                html: "Please be patient, as it may take a few moments for the new data to be displayed",
                confirmButtonColor: "#3085d6"
              });
            } else if (data === "success") {
              Swal.fire({
                icon: "success",
                title: "Participants ",
                text: `Participants have been removed from tag ${tagDetails?.tag.name}`,
                confirmButtonColor: "#3085d6"
              });
              getTagDetails(currentTagID);
              setSelectedTaggedParticipants([]);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to remove participants",
                confirmButtonColor: "#3085d6"
              });
            }
          }
        });
      }
    } else
      Swal.fire({
        icon: "warning",
        title: "No participants selected",
        text: "Please select participants to remove",
        confirmButtonColor: "#3085d6"
      });
  };
  const bulkAdd = async () => {
    //Check for worker first
    if (selectedNonTaggedParticipants?.length > 0) {
      let workerID = localStorage.getItem("workerID");
      if (workerID && workerID !== null) {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
        return;
      } else {
        let participantIDs = selectedNonTaggedParticipants.map(
          (participant) => participant.id
        );
        Swal.fire({
          title: "Are you sure?",
          text: "You will add all selected participants to this tag",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33"
        }).then(async (result) => {
          if (result.isConfirmed) {
            const data = await addTagToParticipant(
              clientID as string,
              studyID as string,
              currentTagID,
              participantIDs
            );
            Swal.fire({
              title: "Sending request...",
              text: "Please wait",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              }
            });
            if (data === "successfulBulk") {
              Swal.fire({
                icon: "success",
                title: "Worker has been created",
                html: "Please be patient, as it may take a few moments for the new data to be displayed",
                confirmButtonColor: "#3085d6"
              });
            } else if (data === "success") {
              Swal.fire({
                icon: "success",
                title: "Participants ",
                text: `Participants have been added to tag ${tagDetails?.tag.name}`,
                confirmButtonColor: "#3085d6"
              });
              getTagDetails(currentTagID);
              setSelectedNonTaggedParticipants([]);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to add participants",
                confirmButtonColor: "#3085d6"
              });
            }
          }
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "No participants selected",
        text: "Please select participants to add",
        confirmButtonColor: "#3085d6"
      });
    }
  };
  /*-----------------------Return----------------------- */
  return (
    <div className="tag-details">
      {isLoading ? (
        <>
          <LoadingPencil isVisible={isLoading} title={"Tag Details"} />
        </>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <>
          {/* Headers */}
          <div className="tag-header">
            <div className="tag-name">{tagDetails?.tag.name}</div>
            <div className="buttons_container">
              {/* EDIT */}
              {/* <button
                className="btn_edit"
                onClick={() => {
                  handleEditTag(tagDetails?.tag.id as string);
                }}
              >
                <IconEdit />
              </button> */}
              {/* DELETE */}
              {(hasPermission("subject") ||
                hasPermission("tag", "all") ||
                hasPermission("tag", "delete")) && (
                <button
                  className="btn_delete"
                  onClick={() => {
                    handleDeleteTag(tagDetails?.tag.id as string);
                  }}
                >
                  <IconDelete />
                </button>
              )}
            </div>
          </div>
          <div className="tag-tools">
            {/* Filters */}
            <div className="filter-options">
              <Dropdown
                placeholder="Select Survey/Study"
                options={nameFilter}
                selected={selectedName}
                onOptionSelected={handleFilters}
              />
              <MultipleDropdown
                placeholder="Status"
                options={statusFilter}
                selectedOptions={selectedStatus}
                onOptionToggled={handleStatusToggled}
              />
              <MultipleDropdown
                placeholder="Bucket"
                options={progressFilter}
                selectedOptions={selectedProgress}
                onOptionToggled={handleProgressToggled}
              />
              <div className="search-container">
                <input
                  className="search-input"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search"
                  value={searchTag}
                  onChange={(e) => setSearchTag(e.target.value)}
                />
                <button
                  className="btn btn-primary btn-clear"
                  disabled={false}
                  onClick={handleClearFilters}
                >
                  <IconBroom className="icon icon_white" />
                </button>
              </div>
              {(hasPermission("subject") ||
                hasPermission("tag", "all") ||
                hasPermission("tag", "write")) && (
                <div className="import">
                  <button
                    className="btn btn-primary btn-import"
                    onClick={advancedAdding}
                  >
                    Advanced Add
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="tables">
            <div className="tagged-participants">
              <div className="table-title-container ">
                <h2 className="title">Tagged Participants</h2>
                <button
                  className="csv-download"
                  onClick={(e) => downloadXlsx("tagged")}
                >
                  <span className="csv-count">
                    {tagDetails && tagDetails.participantsWithTag
                      ? tagDetails.participantsWithTag.length
                      : 0}
                  </span>
                  <span className="csv-arrow" />
                </button>
              </div>
              <div className="table-responsive">
                <table className="table tagged_table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={selectAllTaggedParticipants}
                          checked={
                            filteredTaggedParticipants?.length
                              ? filteredTaggedParticipants.length ===
                                selectedTaggedParticipants.length
                              : false
                          }
                        />
                      </th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      {(hasPermission("subject") ||
                        hasPermission("tag", "all") ||
                        hasPermission("tag", "delete")) && (
                        <th className="btn_bulk_container">
                          <button
                            className="btn_remove_bulk"
                            onClick={bulkRemove}
                          >
                            Remove {selectedTaggedParticipants?.length || 0}
                          </button>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTaggedResults && currentTaggedResults.length > 0 ? (
                      currentTaggedResults.map((participant, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              onChange={() =>
                                selectSingleParticipant(participant)
                              }
                              checked={selectedTaggedParticipants.includes(
                                participant
                              )}
                            />
                          </td>
                          <td>{participant?.participant_full_name}</td>
                          <td>{participant.participant_full_mobile}</td>
                          <td>{participant.participant_email}</td>
                          {(hasPermission("subject") ||
                            hasPermission("tag", "all") ||
                            hasPermission("tag", "delete")) && (
                            <td className="btn_container">
                              <button
                                className="btn_remove"
                                onClick={() =>
                                  handleRemoveParticipant(participant?.id)
                                }
                              >
                                -
                              </button>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2}>Not found in Study</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                {filteredTaggedParticipants &&
                  filteredTaggedParticipants?.length > 0 && (
                    <PaginationNavigation
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      resultsPerPage={resultsPerPage}
                      totalResults={
                        filteredTaggedParticipants?.length as number
                      }
                    />
                  )}
              </div>
            </div>
            <div className="non-tagged-participants">
              <div className="table-title-container ">
                <h2 className="title">Non-Tagged Participants</h2>
                <button
                  className="csv-download"
                  onClick={(e) => downloadXlsx("nonTagged")}
                >
                  <span className="csv-count">
                    {tagDetails && tagDetails.participantsWithoutTag
                      ? tagDetails.participantsWithoutTag.length
                      : 0}
                  </span>
                  <span className="csv-arrow" />
                </button>
              </div>
              <div className="table-responsive">
                <table className="table non_tagged_table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={selectAllNonTaggedParticipants}
                          checked={
                            filteredNonTaggedParticipants?.length
                              ? filteredNonTaggedParticipants.length ===
                                selectedNonTaggedParticipants.length
                              : false
                          }
                        />
                      </th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      {(hasPermission("subject") ||
                        hasPermission("tag", "all") ||
                        hasPermission("tag", "write")) && (
                        <th className="btn_bulk_container">
                          <button className="btn_add_bulk" onClick={bulkAdd}>
                            Add {selectedNonTaggedParticipants?.length || 0}
                          </button>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentNonTaggedResults &&
                    currentNonTaggedResults.length > 0 ? (
                      currentNonTaggedResults?.map((participant, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  selectSingleNonTaggedParticipant(participant)
                                }
                                checked={selectedNonTaggedParticipants.includes(
                                  participant
                                )}
                              />
                            </td>
                            <td>{participant?.participant_full_name}</td>
                            <td>{participant.participant_full_mobile}</td>
                            <td>{participant.participant_email}</td>
                            {(hasPermission("subject") ||
                              hasPermission("tag", "all") ||
                              hasPermission("tag", "write")) && (
                              <td className="btn_container">
                                <button
                                  className="btn_add"
                                  onClick={() =>
                                    handleAddParticipant(participant?.id)
                                  }
                                >
                                  +
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}>Not found in Study</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                {filteredNonTaggedParticipants &&
                  filteredNonTaggedParticipants.length > 0 && (
                    <PaginationNavigation
                      currentPage={currentPageNonTagged}
                      setCurrentPage={setCurrentPageNonTagged}
                      resultsPerPage={resultsPerPageNonTagged}
                      totalResults={
                        filteredNonTaggedParticipants?.length as number
                      }
                    />
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TagDetails;
