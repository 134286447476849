import React, { useMemo, useRef } from "react";
import { ConfigMobileAppStyles } from "../../../../../../types";
import {
  getAllFontAwesomeIconNames,
  useOutsideClick
} from "../../../../../../utilities/utils";

type IconDropdownProps = {
  settingName: keyof ConfigMobileAppStyles;
  fieldID: string;
  fieldKey: string;
  fieldValue: string;
  handleChange: (
    settingName: keyof ConfigMobileAppStyles,
    id: string,
    dbField: string,
    value: string
  ) => void;
};

const IconDropdown: React.FC<IconDropdownProps> = ({
  settingName,
  fieldID,
  fieldKey,
  fieldValue,
  handleChange
}) => {
  const iconNames = useMemo(() => getAllFontAwesomeIconNames(), []);

  const [search, setSearch] = React.useState<string>("");

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

  const [filteredIcons, setFilteredIcons] = React.useState<string[]>(iconNames);

  React.useEffect(() => {
    if (search === "") {
      setFilteredIcons(iconNames);
      return;
    }
    setFilteredIcons(iconNames.filter((icon) => icon.includes(search)));
  }, [search, iconNames]);

  function changeIcon(icon: string) {
    handleChange(settingName, fieldID, fieldKey, icon);
    setShowDropdown(false);
    setSearch("");
  }

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div className="row">
      <div className="col">
        <div className="dropdown" ref={ref}>
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id={`dropdown${fieldID}`}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {fieldValue}
          </button>
          <div
            className={`dropdown-menu ${showDropdown ? "show" : ""}`}
            aria-labelledby={`dropdown${fieldID}`}
          >
            <input
              className="form-control"
              type="text"
              placeholder="Search icons"
              value={search}
              onChange={handleSearch}
            />
            <div style={{ maxHeight: "87px", overflowY: "auto" }}>
              {filteredIcons.map((icon, index) => (
                <button
                  key={index}
                  className="dropdown-item"
                  onClick={() => {
                    changeIcon(icon);
                  }}
                >
                  {icon}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconDropdown;
