import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  ExistingWSCVariable,
  WSCQuestionOption,
  WSCQuestionRMS,
  WSCQuestionRMSVariable,
  WSCVariable
} from "../types";

function isWSCQuestionOption(obj: any): obj is WSCQuestionOption {
  // console.log(obj, "obj");
  const validID: boolean = typeof obj.id === "string";
  const validText: boolean = typeof obj.text === "string";

  const AllValid = validID && validText;
  if (!AllValid) {
    let errors: string[] = [];

    console.log(obj);
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }

      if (!validText) {
        if (!obj.hasOwnProperty("text")) {
          errors.push("missing text");
        } else {
          errors.push(`Invalid type for text`);
        }
      }

      const displayMessage = `<strong>Please contact support</strong><br /><br />`;

      Swal.fire({
        icon: "error",
        title: `Invalid WSC Question Option`,
        html: displayMessage + errors.join("<br />"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }

  return AllValid;
}

function isWSCQuestionRMS(obj: any): obj is WSCQuestionRMS {
  // export type WSCQuestionRMS = {
  //   id: string;
  //   active: boolean;
  //   name: string;
  //   method: "new" | "existing";
  //   mapped_field: string;
  //   code: string;
  // };

  const validID: boolean = typeof obj.id === "string";
  const validActive: boolean = typeof obj.active === "boolean";
  const validName: boolean = typeof obj.name === "string";
  const validMethod: boolean =
    obj.method === "new" || obj.method === "existing";
  const validMappedField: boolean = typeof obj.mapped_field === "string";
  const validCode: boolean = typeof obj.code === "string";

  const AllValid =
    validID &&
    validActive &&
    validName &&
    validMethod &&
    validMappedField &&
    validCode;

  if (!AllValid) {
    let errors: string[] = [];

    console.log(obj);
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validActive) {
      if (!obj.hasOwnProperty("active")) {
        errors.push("missing active");
      } else {
        errors.push(`Invalid type for active`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Invalid type for name`);
      }
    }

    if (!validMethod) {
      if (!obj.hasOwnProperty("method")) {
        errors.push("missing method");
      } else {
        errors.push(`Invalid type for method`);
      }
    }

    if (!validMappedField) {
      if (!obj.hasOwnProperty("mapped_field")) {
        errors.push("missing mapped_field");
      } else {
        errors.push(`Invalid type for mapped_field`);
      }
    }

    if (!validCode) {
      if (!obj.hasOwnProperty("code")) {
        errors.push("missing code");
      } else {
        errors.push(`Invalid type for code`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid WSC Question`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}

function isWSCQuestionRMSVariable(obj: any): obj is WSCQuestionRMSVariable {
  // id: string;
  // question_rms_variable: string;

  const validID: boolean = typeof obj.id === "string";
  const validQuestionRMSVariable: boolean =
    typeof obj.mapped_field === "string";

  const AllValid = validID && validQuestionRMSVariable;

  if (!AllValid) {
    let errors: string[] = [];

    console.log(obj);
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validQuestionRMSVariable) {
      if (!obj.hasOwnProperty("mapped_field")) {
        errors.push("missing mapped_field");
      } else {
        errors.push(`Invalid type for mapped_field`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid WSC Question RMS Variable`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return AllValid;
}

function isWSCVariable(obj: any): obj is WSCVariable {
  const validID: boolean = typeof obj.id === "string";
  const validName: boolean = typeof obj.name === "string";
  const validType: boolean = obj.type === "system" || obj.type === "custom";
  // survey name only required if type is custom
  const validSurveyName: boolean =
    obj.type === "system" ||
    typeof obj.surveyName === "string" ||
    obj.surveyName === null ||
    obj.surveyName === undefined;
  // options only required if type is system
  const validSub: boolean =
    obj.type === "custom" ||
    (Array.isArray(obj.options) &&
      obj.options.every(
        (options: any) =>
          typeof options.id === "string" && typeof options.name === "string"
      ));

  const AllValid =
    validID && validName && validType && validSurveyName && validSub;

  if (!AllValid) {
    let errors: string[] = [];

    console.log(obj);
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Invalid type for name`);
      }
    }

    if (!validType) {
      if (!obj.hasOwnProperty("type")) {
        errors.push("missing type");
      } else {
        errors.push(`Invalid type for type`);
      }
    }

    if (!validSurveyName) {
      if (!obj.hasOwnProperty("surveyName")) {
        errors.push("missing surveyName");
      } else {
        errors.push(`Invalid type for surveyName`);
      }
    }

    if (!validSub) {
      if (!obj.hasOwnProperty("options")) {
        errors.push("missing options");
      } else {
        errors.push(`Invalid type for options`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid WSC Variable`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}

function isExistingWSCVariable(obj: any): obj is ExistingWSCVariable {
  const validID: boolean = typeof obj.id === "string";
  const validName: boolean = typeof obj.name === "string";
  const validMethod: boolean =
    obj.method === "new" || obj.method === "existing";
  const validWSCCode: boolean = typeof obj.wscCode === "string";
  const validType: boolean = obj.type === "system" || obj.type === "custom";
  const validSub: boolean =
    obj.type === "custom" ||
    (Array.isArray(obj.options) &&
      obj.options.every(
        (options: any) =>
          typeof options.id === "string" &&
          typeof options.name === "string" &&
          typeof options.wscVariable === "string"
      ));

  const AllValid =
    validID &&
    validName &&
    validMethod &&
    validWSCCode &&
    validType &&
    validSub;

  if (!AllValid) {
    let errors: string[] = [];

    console.log(obj);
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Invalid type for name`);
      }
    }

    if (!validMethod) {
      if (!obj.hasOwnProperty("method")) {
        errors.push("missing method");
      } else {
        errors.push(`Invalid type for method`);
      }
    }

    if (!validWSCCode) {
      if (!obj.hasOwnProperty("wscCode")) {
        errors.push("missing wscCode");
      } else {
        errors.push(`Invalid type for wscCode`);
      }
    }

    if (!validType) {
      if (!obj.hasOwnProperty("type")) {
        errors.push("missing type");
      } else {
        errors.push(`Invalid type for type`);
      }
    }

    if (!validSub) {
      if (!obj.hasOwnProperty("options")) {
        errors.push("missing options");
      } else {
        errors.push(`Invalid type for options`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Existing WSC Variable`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}

export async function fetchAllWSCQuestionsForMDISurvey(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<WSCQuestionRMS[] | string> {
  const response = await apiFetcher<any>("/surveys/wsc/get/questions", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID,
      surveyID: surveyID
    }
  });

  // const response = {
  //   status: 200,
  //   data: [
  //     {
  //       id: "89fc7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: 'To start with, we want you to drop pins on up to 5 aspects of this concept that you <strong>LIKE, or find APPEALING</strong>. \n\nTap the screen to drop the pins and leave a short comment to explain why you like the area. Remember to press the "save" button after each comment to save it.\n\n\n\n',
  //       code: "CON1_LIKE",
  //       active: true,
  //       method: "existing",
  //       mapped_field: "sssss2",
  //     },
  //     {
  //       id: "95fc7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: 'Thanks for that!  Now we want to understand which aspects of this concept you <strong>DON’T LIKE</strong>, or perhaps find <strong>CONFUSING</strong>.\n\nPlease drop pins on up to 5 areas of the concept that you DON’T LIKE or find CONFUSING.  You\'ll then be prompted to explain how you feel this aspect of the concept could be changed to make it more appealing for yourself. Remember to press the "save" button to save your comment. Remember to press the "save" button after each comment to save it.\n\n\n\n',
  //       code: "CON1_DISLIKE",
  //       active: true,
  //       method: "existing",
  //       mapped_field: "55",
  //     },
  //     {
  //       id: "fcfc7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: 'We are now going to show you the second concept.\n\nTo start with, we want you to drop pins on up to 5 aspects of this concept that you <strong>LIKE, or find APPEALING</strong>. \n\nTap the screen to drop the pins and leave a short comment to explain why you like the area. Remember to press the "save" button after each comment to save it.\n\n\n\n\n',
  //       code: "CON5_LIKE",
  //       active: false,
  //       method: "new",
  //       mapped_field: "",
  //     },
  //     {
  //       id: "08fd7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: 'Now we want to understand which aspects of this concept you <strong>DON’T LIKE</strong>, or perhaps find <strong>CONFUSING</strong>.\n\nPlease drop pins on up to 5 areas of the concept that you DON’T LIKE or find CONFUSING.  You\'ll then be prompted to explain how you feel this aspect of the concept could be changed to make it more appealing for yourself. Remember to press the "save" button after each comment to save it.\n\n\n\n',
  //       code: "CON5_DISLIKE",
  //       active: false,
  //       method: "new",
  //       mapped_field: "",
  //     },
  //     {
  //       id: "6ffd7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: 'Okay - last one.\n\nTo start with, we want you to drop pins on up to 5 aspects of this concept that you <strong>LIKE, or find APPEALING</strong>. \n\nTap the screen to drop the pins and leave a short comment to explain why you like the area. Remember to press the "save" button after each comment to save it.\n\n\n\n',
  //       code: "CON6_LIKE",
  //       active: false,
  //       method: "new",
  //       mapped_field: "",
  //     },
  //     {
  //       id: "7bfd7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: 'Now we want to understand which aspects of this concept you <strong>DON’T LIKE</strong>, or perhaps find <strong>CONFUSING</strong>.\n\nPlease drop pins on up to 5 areas of the concept that you DON’T LIKE or find CONFUSING.  You\'ll then be prompted to explain how you feel this aspect of the concept could be changed to make it more appealing for yourself. Remember to press the "save" button after each comment to save it.\n\n\n\n',
  //       code: "CON6_DISLIKE",
  //       active: false,
  //       method: "new",
  //       mapped_field: "",
  //     },
  //   ],
  // };

  if (response.status === 200) {
    // nField, has defualt mapping
    if (
      response.data.survey_type &&
      typeof response.data.survey_type === "string"
    ) {
      if (response.data.survey_type.toLowerCase() === "nfield") {
        return "Survey is nField and has default mapping";
      }

      if (response.data.survey_type.toLowerCase() === "custom") {
        return "Survey is custom and has default mapping";
      }
    }

    if (!Array.isArray(response.data)) {
      Swal.fire({
        icon: "error",
        title: `Invalid WSC Question`,
        html: `<strong>Please contact support</strong><br /><br />Data is not an array`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return "Invalid response from server";
    }

    if (response.data.every(isWSCQuestionRMS)) {
      return response.data;
    }

    return "Invalid response from server";
  }

  if (response.status === 204) {
    return [];
  }

  if (response.status === 404) {
    return "Route not found";
  }

  if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Invalid WSC Question`,
      html: `<strong>Please contact support</strong><br /><br />500 Internal Server Error`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Internal Server Error";
  }

  Swal.fire({
    icon: "error",
    title: `Invalid WSC Question`,
    html: `<strong>Please contact support</strong><br /><br />Invalid response from server`,
    showConfirmButton: true,
    confirmButtonColor: "#3085d6"
  });

  return "Invalid response from server";
}

//fetch all WSCQuestionRMSVariable
export async function fetchAllWSCQuestionsRMSVariable(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<WSCQuestionRMSVariable[] | string> {
  const response = await apiFetcher<any>(
    "/surveys/wsc/mapping/get/all",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID,
        surveyID: surveyID
      }
    }
  );

  if (response.status === 200) {
    if (!Array.isArray(response.data)) {
      Swal.fire({
        icon: "error",
        title: `Invalid WSC Question`,
        html: `<strong>Please contact support</strong><br /><br />Data is not an array`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return "Invalid response from server";
    }

    if (response.data.every(isWSCQuestionRMSVariable)) {
      return response.data;
    }

    return "Invalid response from server";
  }

  if (response.status === 204) {
    return [];
  }

  if (response.status === 404) {
    return "Route not found";
  }

  if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Invalid WSC Question`,
      html: `<strong>Please contact support</strong><br /><br />500 Internal Server Error`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Internal Server Error";
  }

  Swal.fire({
    icon: "error",
    title: `Invalid WSC Question`,
    html: `<strong>Please contact support</strong><br /><br />Invalid response from server`,
    showConfirmButton: true,
    confirmButtonColor: "#3085d6"
  });

  return "Invalid response from server";
}

export async function sendAllWSCQuestionsForMDISurvey(
  clientID: string,
  studyID: string,
  surveyID: string,
  questions: WSCQuestionRMS[]
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>(
      "/surveys/wsc/mapping/imports/create",
      "POST",
      {
        body: {
          clientID: clientID,
          studyID: studyID,
          surveyID: surveyID,
          questions: questions
        }
      }
    );

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Questions saved"
      };
    }

    if (response.status === 204) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br /><br />204 Not found"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage: "<strong>Route not found</strong><br /><br />404 Not found"
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br /><br />500 Internal Server Error"
      };
    }

    return {
      rStatus: "error",
      rMessage: `<strong>Please contact support</strong><br /><br />Unknown error: ${response.status}`
    };
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Unknown error: " + error
    };
  }
}

export async function fetchAllWSCMappingsOptions(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<WSCVariable[] | string> {
  const response = await apiFetcher<any>(
    "/surveys/wsc/mapping/imports/get/active",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID,
        surveyID: surveyID
      }
    }
  );

  console.log(response.data);

  // dummy data for testing
  // const response = {
  //   status: 200,
  //   data: [
  //     {
  //       id: "89fc7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: "CON1_LIKE",
  //       type: "system",
  //       surveyName: "CON1_LIKE",
  //       options: [
  //         {
  //           id: "89fc7ba1-171f-eb11-82ba-d2b4456e96f6",
  //           name: "CON1_LIKE",
  //         },
  //       ],
  //     },
  //     {
  //       id: "95fc7ba1-171f-eb11-82ba-d2b4456e96f6",
  //       name: "CON1_DISLIKE",
  //       type: "system",
  //       surveyName: "CON1_DISLIKE",
  //       options: [
  //         {
  //           id: "95fc7ba1-171f-eb11-82ba-d2b4456e96f6",
  //           name: "CON1_DISLIKE",
  //         },
  //       ],
  //     },
  //   ],
  // };

  if (response.status === 200) {
    if (!Array.isArray(response.data)) {
      Swal.fire({
        icon: "error",
        title: `Invalid WSCVariable`,
        html: `<strong>Please contact support</strong><br /><br />Data is not an array`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return "Invalid response from server";
    }

    if (response.data.every(isWSCVariable)) {
      return response.data as WSCVariable[];
    } else {
      return "Invalid response from server";
    }
  }

  if (response.status === 204) {
    return [];
  }

  if (response.status === 404) {
    Swal.fire({
      icon: "error",
      title: `Invalid Route for WSCVariable`,
      html: `<strong>Please contact support</strong><br /><br />404 Route Not found`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Route not found";
  }

  if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Invalid WSCVariable`,
      html: `<strong>Please contact support</strong><br /><br />500 Internal Server Error`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Internal Server Error";
  }

  Swal.fire({
    icon: "error",
    title: `Invalid WSCVariable`,
    html: `<strong>Please contact support</strong><br /><br />Invalid response from server`,
    showConfirmButton: true,
    confirmButtonColor: "#3085d6"
  });

  return "Invalid response from server";
}

export async function fetchAllWSCMappings(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<ExistingWSCVariable[] | string> {
  const response = await apiFetcher<any>(
    "/surveys/wsc/mapping/variable/get",
    "POST",
    {
      body: {
        clientID: clientID,
        studyID: studyID,
        surveyID: surveyID
      }
    }
  );

  // dummy data for testing
  // const response = {
  //   status: 200,
  //   data: [],
  // };

  if (response.status === 200) {
    if (!Array.isArray(response.data)) {
      Swal.fire({
        icon: "error",
        title: `Invalid ExistingWSCVariable`,
        html: `<strong>Please contact support</strong><br /><br />Data is not an array`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return "Invalid response from server";
    }

    if (response.data.every(isExistingWSCVariable)) {
      return response.data as ExistingWSCVariable[];
    } else {
      return "Invalid response from server";
    }
  }

  if (response.status === 204) {
    return [];
  }

  if (response.status === 404) {
    Swal.fire({
      icon: "error",
      title: `Invalid Route for ExistingWSCVariable`,
      html: `<strong>Please contact support</strong><br /><br />404 Route Not found`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Route not found";
  }

  if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Invalid ExistingWSCVariable`,
      html: `<strong>Please contact support</strong><br /><br />500 Internal Server Error`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Internal Server Error";
  }

  Swal.fire({
    icon: "error",
    title: `Invalid ExistingWSCVariable`,
    html: `<strong>Please contact support</strong><br /><br />Invalid response from server`,
    showConfirmButton: true,
    confirmButtonColor: "#3085d6"
  });

  return "Invalid response from server";
}

export async function sendAllWSCMappings(
  clientID: string,
  studyID: string,
  surveyID: string,
  variables: ExistingWSCVariable[],
  deleteAll: string | null = null
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>(
      "/surveys/wsc/mapping/variable/create",
      "POST",
      {
        body: {
          clientID: clientID,
          studyID: studyID,
          surveyID: surveyID,
          variables: variables,
          deleteAll: deleteAll
        }
      }
    );

    // dummy data for testing
    // const response = {
    //   status: 200,
    //   data: [],
    // };

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Variables saved"
      };
    }

    if (response.status === 204) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br /><br />204 Not found"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage: "<strong>Route not found</strong><br /><br />404 Not found"
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        rMessage:
          "<strong>Please contact support</strong><br /><br />500 Internal Server Error"
      };
    }

    return {
      rStatus: "error",
      rMessage: `<strong>Please contact support</strong><br /><br />Unknown error: ${response.status}`
    };
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Unknown error: " + error
    };
  }
}
