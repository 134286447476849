import React from "react";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldNotification from "../../Fields/fieldNotification.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
import FieldTime from "../../Fields/fieldTime.component";
import { AddStudyWizardStepDiaryNotificationsProps } from "../addStudyWizard.component";
import AddStudyWizardFooter from "../addStudyWizardFooter.component";

interface StepDiaryNotificationsProps
  extends AddStudyWizardStepDiaryNotificationsProps {}

const StepDiaryNotifications: React.FC<StepDiaryNotificationsProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleInputChange,
  handleWizardSubmit,
  // Fields
  diaryWelcomeNotification,
  diaryDailyReminderNotification,
  diaryDailyReminderTime,
  diaryNonComplianceNotification,
  diaryNonComplianceTime,
  pageValid,
  inputErrors,
  diaryWelcomeNotificationsData,
  diaryDailyReminderNotificationsData,
  diaryNonComplianceNotificationsData,
  diaryNewWelcomeNotification,
  diaryNewDailyReminderNotification,
  diaryNewNonComplianceNotification,
  diaryName,
  diaryIncludeNotifications
}) => {
  return (
    <div className="card card-default add_study_form_page p-4">
      <h3 className="form_header">Diary Notifications: {diaryName}</h3>
      <div className="add_study_form_page_inputs">
        {/* checkbox  */}
        <FieldCheckbox
          tooltip="This will allow you to include notifications for this diary"
          tooltipPosition="bottom"
          label="Send out notifications for this diary?"
          inputName="diaryIncludeNotifications"
          inputValue={diaryIncludeNotifications}
          onInputChange={handleInputChange}
          error={
            inputErrors?.diaryIncludeNotifications
              ? inputErrors.diaryIncludeNotifications
              : null
          }
        />
        {diaryIncludeNotifications && (
          <>
            <FieldNotification
              tooltip="This is the notification participants receive when they join the bucket - By default, this is only for the first bucket. You can add welcome notifications to the other buckets on the Study Overview page if needed. "
              label="Welcome Notification"
              inputName="diaryWelcomeNotification"
              inputValue={diaryWelcomeNotification}
              inputOptions={diaryWelcomeNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryWelcomeNotification
                  ? inputErrors.diaryWelcomeNotification
                  : null
              }
            />
            {/* if diaryWelcomeNotification = new */}

            {diaryWelcomeNotification === "new" && (
              <>
                <FieldTextArea
                  tooltip="This will be where you select the new welcome message for the study"
                  label="New Welcome Message"
                  inputName="diaryNewWelcomeNotificationPhrase"
                  inputValue={
                    diaryNewWelcomeNotification
                      ? diaryNewWelcomeNotification
                      : ""
                  }
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.diaryNewWelcomeNotificationPhrase
                      ? inputErrors.diaryNewWelcomeNotificationPhrase
                      : null
                  }
                />
              </>
            )}

            <FieldNotification
              tooltip="This is a reminder that will be sent out daily at your selected time. This will run user time. "
              label="Daily Reminder Notification"
              inputName="diaryDailyReminderNotification"
              inputValue={diaryDailyReminderNotification}
              inputOptions={diaryDailyReminderNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryDailyReminderNotification
                  ? inputErrors.diaryDailyReminderNotification
                  : null
              }
            />

            {
              // if diaryDailyReminderNotification = new
              diaryDailyReminderNotification === "new" && (
                <>
                  <FieldTextArea
                    tooltip="This will be where you select the new daily reminder message for the study"
                    label="New Daily Reminder Message"
                    inputName="diaryNewDailyReminderNotificationPhrase"
                    inputValue={
                      diaryNewDailyReminderNotification
                        ? diaryNewDailyReminderNotification
                        : ""
                    }
                    onInputChange={handleInputChange}
                    error={
                      inputErrors?.diaryNewDailyReminderNotificationPhrase
                        ? inputErrors.diaryNewDailyReminderNotificationPhrase
                        : null
                    }
                  />
                </>
              )
            }
            <FieldTime
              tooltip="Set the time here"
              label="Daily Reminder Time"
              inputName="diaryDailyReminderTime"
              inputValue={diaryDailyReminderTime ? diaryDailyReminderTime : ""}
              onInputChange={handleInputChange}
              subLabel="User local time"
              error={
                inputErrors?.diaryDailyReminderTime
                  ? inputErrors.diaryDailyReminderTime
                  : null
              }
            />
            <FieldNotification
              tooltip="This is a notification that will be sent out daily at your specified time if they do not have the minimum amount of completes for the day. This will run user time. "
              label="Non-Compliance Notification"
              inputName="diaryNonComplianceNotification"
              inputValue={diaryNonComplianceNotification}
              inputOptions={diaryNonComplianceNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryNonComplianceNotification
                  ? inputErrors.diaryNonComplianceNotification
                  : null
              }
            />
            {
              // if diaryNonComplianceNotification = new
              diaryNonComplianceNotification === "new" && (
                <>
                  <FieldTextArea
                    tooltip="This will be where you select the new non-compliance message for the study"
                    label="New Non-Compliance Message"
                    inputName="diaryNewNonComplianceNotificationPhrase"
                    inputValue={
                      diaryNewNonComplianceNotification
                        ? diaryNewNonComplianceNotification
                        : ""
                    }
                    onInputChange={handleInputChange}
                    error={
                      inputErrors?.diaryNewNonComplianceNotificationPhrase
                        ? inputErrors.diaryNewNonComplianceNotificationPhrase
                        : null
                    }
                  />
                </>
              )
            }
            <FieldTime
              tooltip="Please note this is the time the notification will be sent out on the following day."
              label="Non-Compliance Time"
              inputName="diaryNonComplianceTime"
              inputValue={diaryNonComplianceTime ? diaryNonComplianceTime : ""}
              onInputChange={handleInputChange}
              subLabel="User local time"
              error={
                inputErrors?.diaryNonComplianceTime
                  ? inputErrors.diaryNonComplianceTime
                  : null
              }
            />
          </>
        )}

        <AddStudyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepDiaryNotifications;
