// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm_modal_container .show_modal {
  display: block !important;
  opacity: 1;
}
.confirm_modal_container a {
  text-decoration: none;
}
.confirm_modal_container .password_container {
  position: relative;
}
.confirm_modal_container .password_container .btn_password {
  position: absolute;
  right: 10px;
  top: -2px;
  background: transparent;
  height: 100%;
}
.confirm_modal_container .password_container .btn_password .icon {
  height: 1rem;
  width: 1rem;
  fill: #8f8f8f !important;
  transition: fill 0.2s ease-in-out !important;
}
.confirm_modal_container .password_container .btn_password .icon:hover {
  fill: #141414 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmDetailsModal/confirmDetailsModal.scss"],"names":[],"mappings":"AAGE;EACE,yBAAA;EACA,UAAA;AAFJ;AAKE;EACE,qBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;AAKI;EACE,kBAAA;EAEA,WAAA;EACA,SAAA;EACA,uBAAA;EACA,YAAA;AAJN;AAMM;EACE,YAAA;EACA,WAAA;EACA,wBAAA;EACA,4CAAA;AAJR;AAMQ;EACE,wBAAA;AAJV","sourcesContent":["@use \"../../global.scss\" as *;\n\n.confirm_modal_container {\n  .show_modal {\n    display: block !important;\n    opacity: 1;\n  }\n\n  a {\n    text-decoration: none;\n  }\n\n  .password_container {\n    position: relative;\n    .btn_password {\n      position: absolute;\n\n      right: 10px;\n      top: -2px;\n      background: transparent;\n      height: 100%;\n\n      .icon {\n        height: 1rem;\n        width: 1rem;\n        fill: #8f8f8f !important;\n        transition: fill 0.2s ease-in-out !important;\n\n        &:hover {\n          fill: #141414 !important;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
