import React, { useCallback, useEffect, useState } from "react";
import "./InfoInputs.scss";
// the circle info svg needs to be imported here
import circleInfo from "../../assets/images/icons/info-tooltip-icon.svg";

export type InformationTooltipProps = {
  tooltipText: string;
  position: "left" | "right" | "bottom" | "top";
  marginTop?: string; //THIS IS NOT REQUIRED!!
  marginLeft?: string; //THIS IS NOT REQUIRED!!
  marginRight?: string; //THIS IS NOT REQUIRED!!
  widthSize?: string; //THIS IS NOT REQUIRED!!
};

const InformationTooltip: React.FC<InformationTooltipProps> = ({
  tooltipText,
  position,
  marginTop,
  marginLeft,
  marginRight,
  widthSize
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const checkIfTooltipShouldFlip = useCallback(() => {
    if (isHovered) {
      // Checking the aligning for the tooltip
      const tooltipElement = document.querySelector(
        position === "left"
          ? ".tooltipInfo_left"
          : position === "right"
            ? ".tooltipInfo_right"
            : position === "bottom"
              ? ".tooltipInfo_bottom"
              : ".tooltipInfo_top"
      );
      // console.log(tooltipElement, "TOOLTIP ELEMENT");
      if (tooltipElement) {
        const rect = tooltipElement.getBoundingClientRect();
        if (position === "left" && rect.left < 0) {
          setIsFlipped(true);
        } else if (position === "right" && rect.right > window.innerWidth) {
          setIsFlipped(true);
        } else if (position === "bottom") {
          setIsFlipped(true);
        } else if (position === "top" && rect.top < 0) {
          setIsFlipped(true);
        } else {
          setIsFlipped(false);
        }
      }
    }
  }, [position, isHovered]);

  useEffect(() => {
    // Listen for window width changes
    window.addEventListener("resize", checkIfTooltipShouldFlip);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIfTooltipShouldFlip);
    };
  }, [isHovered, checkIfTooltipShouldFlip]);

  const tooltipClassName = () => {
    return `tooltipInfo_${position} ${isFlipped ? "flipped" : ""}`;
  };

  //For adding optional margin to the left side
  const imageStyle = {
    marginTop: marginTop || "0px", // Apply marginTop prop or default to "0px"
    marginLeft: marginLeft || "0px", // Apply marginLeft prop or default to "0px"
    marginRight: marginRight || "0px", // Apply marginRight prop or default to "0px"
    width: widthSize || "14px" // Apply widthSize prop or default to "auto"
  };

  return (
    <div className="information_tooltip">
      <img
        src={circleInfo}
        alt="info"
        className="info_icon"
        style={imageStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <div className={tooltipClassName()}>{tooltipText}</div>
    </div>
  );
};
export default InformationTooltip;
