import jwtDecode from "jwt-decode";
import { FetchResponse, JWT } from "../types";

import { MfaTokens, Tokens, fetchJwt, mfaLogin } from "../models/auth.model";

// Check if the jwt is not expired
export function isTokenExpired(token: string) {
  try {
    const decoded: any = jwtDecode(token);

    // log formatted date
    // console.log(
    //   new Date(decoded.exp * 1000).toLocaleString("UTC", {
    //     timeZone: "UTC",
    //   })
    // );
    // remainingTimeHours, remainingTimeMinutes, remainingTimeSeconds
    const remainingTimeHours = Math.floor(
      (decoded.exp * 1000 - Date.now()) / (1000 * 60 * 60)
    );
    const remainingTimeMinutes = Math.floor(
      ((decoded.exp * 1000 - Date.now()) / (1000 * 60)) % 60
    );
    const remainingTimeSeconds = Math.floor(
      ((decoded.exp * 1000 - Date.now()) / 1000) % 60
    );
    // totalTimeRemaining : 00:00:00
    const totalTimeRemaining = `${remainingTimeHours}:${remainingTimeMinutes}:${remainingTimeSeconds}`;
    if (decoded.exp < Date.now() / 1000) {
      console.log("Token expired " + totalTimeRemaining);

      return true;
    } else {
      // console.log("Token valid " + totalTimeRemaining);
      return false;
    }
  } catch (err) {
    return true;
  }
}

// Check if the jwt is about to expire (Tentative Timing: 30 seconds)
export function isTokenAboutToExpire(token: string) {
  try {
    const decoded: any = jwtDecode(token);

    // log formatted date
    // console.log(
    //   new Date(decoded.exp * 1000).toLocaleString("UTC", {
    //     timeZone: "UTC",
    //   })
    // );

    // convert remaining time to second from epoch
    const remainingTimeSeconds = Math.floor(
      (decoded.exp * 1000 - Date.now()) / 1000
    );

    // console.log("remainingTimeSeconds: " + remainingTimeSeconds);

    // if remaining timeseconds is less than or equal to 30 seconds, then return true
    if (remainingTimeSeconds <= 30) {
      // console.log(
      //   "Token is about to expire in " + remainingTimeSeconds + " seconds"
      // );
      return true;
    }

    // totalTimeRemaining : 00:00:00
    const totalTimeRemaining = `${remainingTimeSeconds}`;
    if (decoded.exp < Date.now() / 1000) {
      console.log("Token expired " + totalTimeRemaining);

      return true;
    } else {
      // console.log("Token valid " + totalTimeRemaining);
      return false;
    }
  } catch (err) {
    return true;
  }
}

// Checks if the refresh token exists in local storage, redirects accordingly
export const refreshTokenCheckRedirect = () => {
  var storedRefreshToken = localStorage.getItem("refresh-token");

  // Check if the refresh token exists in local storage
  if (storedRefreshToken) {
    // console.log("Refresh token exists in local storage:", storedRefreshToken);

    // Check if the refresh token is expired
    if (isTokenExpired(storedRefreshToken)) {
      console.log("Refresh token expired");
      // Redirect to login page after 5 seconds
      window.location.href = "/login";
    } else {
      // console.log("Refresh token is not expired");
      // Proceed with further actions if needed
      window.location.href = "/clients";
    }
  } else {
    console.log("Refresh token does not exist in local storage");
    // Redirect to login page or handle the absence of token

    window.location.href = "/login";
  }
};

// Set refresh and auth tokens in local storage, from response
export async function setTokensLocalStorage(res: FetchResponse<Tokens>) {
  try {
    if (res && res.data && res.data.refreshTokenResponse) {
      const authToken = res.data.refreshTokenResponse.authToken || "";
      const refreshToken = res.data.refreshTokenResponse.refreshToken || "";

      // console.log(`authToken: ${authToken}`);
      // console.log(`refreshToken: ${refreshToken}`);

      localStorage.setItem("auth-token", authToken);
      localStorage.setItem("refresh-token", refreshToken);
    } else {
      console.warn("setTokensLocalStorage: Invalid response object");
    }
  } catch (error) {
    console.error(`setTokensLocalStorage: ${error}`);
  }
}

// Set refresh and auth tokens in local storage, from response
export async function setMfaTokensLocalStorage(res: FetchResponse<MfaTokens>) {
  try {
    if (res && res.data && res.data.mfaConfirmResponse) {
      const authToken = res.data.mfaConfirmResponse.authToken || "";
      const refreshToken = res.data.mfaConfirmResponse.refreshToken || "";

      // console.log(`authToken: ${authToken}`);
      // console.log(`refreshToken: ${refreshToken}`);

      localStorage.setItem("auth-token", authToken);
      localStorage.setItem("refresh-token", refreshToken);
    } else {
      console.warn("setTokensLocalStorage: Invalid response object");
    }
  } catch (error) {
    console.error(`setTokensLocalStorage: ${error}`);
  }
}

// Get refresh and auth tokens from local storage
export async function getTokensLocalStorage() {
  const authToken = localStorage.getItem("auth-token");
  const refreshToken = localStorage.getItem("refresh-token");
  return { authToken, refreshToken };
}

// Request and Set the JWTs in local storage if the request was successful and is available
// USE CASE:
// 1) user got directed from email,
// 2) user needs a new refresh and auth token
export async function requestAndSetTokens(newJwt: JWT) {
  try {
    const res = await fetchJwt(newJwt);
    if (!res.error) {
      // console.log(res.data);
      // Set the JWTs in local storage if the request was successful and is available
      if (res.data) {
        await setTokensLocalStorage(res);
      }
    } else {
      console.log(res.error);
      // direct to login
      window.location.href = "/login";
    }
  } catch (error) {
    console.error(error);
    window.location.href = "/login";
  }
}

// Request and Set the JWTs in local storage if the request was successful and is available
// USE CASE:
// User went through MFA, encoded string (Email Pw code verified)
export async function requestAndSetMfaTokens(encodedMFAConfirmString: string) {
  console.log("HIT REQUEST CONFIRM MFA");
  try {
    const res = await mfaLogin(encodedMFAConfirmString);
    console.log(res);
    if (!res.error && res.status === 200 && res.data) {
      console.log(res.data);
      // Set the JWTs in local storage if the request was successful and is available
      await setMfaTokensLocalStorage(res);
      if (res.data) {
        console.log(res.data);
        return res.status.toString();
      }
      // Server Error
    } else if (res.status === 500) {
      console.log(res.error);
      return "An error occurred on the server. Please try again later.";
      // Client Error
    } else {
      return res.error;
    }
  } catch (error) {
    console.error(error);
  }
}
