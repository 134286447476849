import React, { useState } from "react";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { bulkRewardImport } from "../../models/rewards.model";
import { RewardsImportData } from "../../types";
import "./ImportModal.component.scss";

export type ImportModalProps = {
  source: string;
  clientID: string;
  studyID: string;
  show: boolean;
  handleClose: () => void;
};
const ImportModal: React.FC<ImportModalProps> = ({
  source,
  clientID,
  studyID,
  show,
  handleClose
}) => {
  const [busySubmitting, setBusySubmitting] = useState<boolean | null>(false);
  const [hasUploaded, setHasUploaded] = useState<boolean>(false);
  const [importData, setImportData] = useState<RewardsImportData>([]);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);

  const [fileName, setFileName] = useState<string>("");

  /*=============================Functions=====================*/
  const handleExampleExcel = (src: string) => {
    switch (src) {
      case "rewards":
        window.open("/assets/data/sample_rewards_import.xlsx", "_blank");
        break;
      default:
        break;
    }
  };

  const onDropdown = (acceptedFiles: any) => {
    setHasUploaded(true);
    const file = acceptedFiles[0]; // Assuming only one file is accepted

    const fileExtension = file.name.split(".").pop() || "";
    if (
      !["xlsx", "xls"].includes(fileExtension) &&
      ![
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel"
      ].includes(file.type)
    ) {
      setUploadErrors(["Please upload a valid Excel file"]);
      return;
    }
    setFileName(file.name);
    let errors: string[] = [];

    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) {
        setUploadErrors(["Error reading file"]);
        return;
      }
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the data is in the first sheet
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert sheet to JSON
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (json.length < 1) {
        errors.push("Excel file does not contain enough rows");
      }

      // Check headers
      const headers = json[0] as string[];
      switch (source) {
        case "rewards":
          if (!headers.includes("Participant ID")) {
            errors.push("Participant ID is required");
          }
          if (!headers.includes("Points")) {
            errors.push("Points is required");
          }
          if (!headers.includes("Rewards Status")) {
            errors.push("Rewards Status is required");
          }
          break;
        default:
          break;
      }

      if (errors.length > 0) {
        setUploadErrors(errors);
        return;
      }

      const dataRows = json.slice(1);

      // Map the JSON data to your Rewards structure
      const rewardsData = dataRows.map((row: any) => {
        console.log(typeof row);
        // Assuming the order of the columns is fixed and matches your Rewards type
        return {
          participantID: row[0], // Adjust the index based on actual column
          points: row[1], // Adjust the index based on actual column
          rewardStatus: row[2] // Adjust the index based on actual column
        };
      });

      // Set the importData with the formatted rewardsData
      setImportData(rewardsData);
      setUploadErrors([]); // Clear any previous errors
    };

    reader.readAsArrayBuffer(file);
  };

  const onHandleSubmit = async () => {
    const workerID = localStorage.getItem("workerID");
    if (workerID && workerID !== null) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    let sendUpload;
    switch (source) {
      case "rewards":
        sendUpload = await bulkRewardImport(
          importData,
          clientID,
          studyID,
          source
        );
        break;
      default:
        break;
    }

    if (sendUpload === "success") {
      Swal.fire({
        title: "Import Successful",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6"
      });
    } else {
      Swal.fire({
        title: "Import Failed",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6"
      });
    }
  };

  const Specs = () => {
    switch (source) {
      case "rewards":
        return (
          <p>
            <strong>Excel Requirements:</strong> Participant IDs and Points are
            required.
            <br />
          </p>
        );
      default:
        return <></>;
    }
  };

  const clearFile = () => {
    setHasUploaded(false);
    setFileName("");
    setUploadErrors([]);
  };

  return (
    <>
      <div className={`modal import_phrase_modal ${show ? "show" : "hide"}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="container-fluid">
                <div className="row">
                  <h4 className="modal-title">
                    Import {source.replace(/^\w/, (c) => c.toUpperCase())}
                  </h4>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <p>
                    <strong>Import Guidelines:</strong> Please upload the
                    correct Excel file.
                    <br />
                    For reference, you can download a sample{" "}
                    <span
                      className="link clickable"
                      onClick={() => handleExampleExcel(source)}
                    >
                      Excel file
                    </span>
                  </p>
                  <Specs />
                </div>
                <div className="row">
                  <Dropzone onDrop={onDropdown}>
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        {hasUploaded && (
                          <span
                            className="clear_file"
                            onClick={() => {
                              clearFile();
                            }}
                          >
                            ×
                          </span>
                        )}
                        <div className="drop_zone_upload" {...getRootProps()}>
                          <input {...getInputProps()} />
                          {hasUploaded ? (
                            <p>{fileName}</p>
                          ) : (
                            <p>Drop file or click here to upload...</p>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <div className="col-12">
                    {hasUploaded &&
                      !importData &&
                      (!uploadErrors || uploadErrors.length < 1 ? (
                        <div className="alert alert-danger mt-2" role="alert">
                          Unknown error occurred. Please try again.
                        </div>
                      ) : (
                        <div className="alert alert-danger  mt-2" role="alert">
                          {uploadErrors.map((error, index) => (
                            <div key={`importPhraseError_${index}`}>
                              {error}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="container-fluid">
                <div className="row w-100">
                  <div className="col d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary me-2"
                      disabled={busySubmitting || !importData}
                      onClick={() => {
                        onHandleSubmit();
                      }}
                    >
                      Import
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportModal;
