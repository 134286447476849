// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_navigation {
  margin: auto;
  display: block;
  text-align: center;
}
.pagination_navigation .btn {
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
  transition: background-color 0.2s ease-in-out;
  border-radius: 0px;
  min-width: 42px;
}
@media screen and (max-width: 915px) {
  .pagination_navigation .btn {
    padding: 0px 5% !important;
    min-width: auto !important;
  }
}
.pagination_navigation .btn:hover {
  background-color: #f5f5f5;
}
.pagination_navigation .btn.active {
  color: #eee;
  background-color: #23527c;
  border-color: #ddd;
}
.pagination_navigation .btn:first-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.pagination_navigation .btn:last-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/PaginationNavigation/paginationNavigation.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,6CAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EARF;IASI,0BAAA;IACA,0BAAA;EAEJ;AACF;AAEI;EACE,yBAAA;AAAN;AAGI;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;AADN;AAII;EACE,uCAAA;EACA,0CAAA;EACA,sCAAA;EACA,yCAAA;AAFN;AAKI;EACE,sCAAA;EACA,yCAAA;EACA,uCAAA;EACA,0CAAA;AAHN","sourcesContent":[".pagination_navigation {\n  margin: auto;\n  display: block;\n  text-align: center;\n\n  .btn {\n    color: #23527c;\n    background-color: #eee;\n    border-color: #ddd;\n    transition: background-color 0.2s ease-in-out;\n    border-radius: 0px;\n    min-width: 42px;\n\n    @media screen and (max-width: 915px) {\n      padding: 0px 5% !important;\n      min-width: auto !important;\n    }\n\n    $pagination_navigation_buttons-border-radius: 3px;\n\n    &:hover {\n      background-color: #f5f5f5;\n    }\n\n    &.active {\n      color: #eee;\n      background-color: #23527c;\n      border-color: #ddd;\n    }\n\n    &:first-child {\n      border-top-right-radius: 0px !important;\n      border-bottom-right-radius: 0px !important;\n      border-top-left-radius: $pagination_navigation_buttons-border-radius !important;\n      border-bottom-left-radius: $pagination_navigation_buttons-border-radius !important;\n    }\n\n    &:last-child {\n      border-top-left-radius: 0px !important;\n      border-bottom-left-radius: 0px !important;\n      border-top-right-radius: $pagination_navigation_buttons-border-radius !important;\n      border-bottom-right-radius: $pagination_navigation_buttons-border-radius !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
