import React from "react";

interface AddCategoryModalProps {
  show: boolean;
  handleClose: () => void;
}

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({
  show,
  handleClose
}) => {
  return (
    <>
      <div className={`modal add_category_modal ${show ? "show" : "hide"}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h4 className="modal-title">Add Category</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="categoryName">Category Name</label>
                    </div>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      id="categoryName"
                      placeholder="Enter category name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Add Category
                  </button>
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategoryModal;
