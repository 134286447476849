import React from "react";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldDate from "../../Fields/fieldDate.component";
import FieldImage from "../../Fields/fieldImage.component";
import FieldStatus from "../../Fields/fieldStatus.component";
import FieldText from "../../Fields/fieldText.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
// language dropdown
import FieldDropdownLanguages from "../../Fields/fieldDropdownLanguages.component";
import FieldFile from "../../Fields/fieldFile.component";
import { AddStudyWizardStepInformationProps } from "../addStudyWizard.component";
import AddStudyWizardFooter from "../addStudyWizardFooter.component";

interface StepAddStudyInformationProps
  extends AddStudyWizardStepInformationProps {}

const StepAddStudyInformation: React.FC<StepAddStudyInformationProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleWizardSubmit,
  handleInputChange,
  // Fields
  studyStatus,
  studyName,
  studyDescription,
  studyInformation,
  studyIcon,
  // studyStartDate,
  // studyAutoStart,
  // studyDeadline,
  // studyBudget,
  diaryIncluded,
  diaryName,
  diaryDescription,
  // diaryAppDisplayName,
  diaryIcon,
  diaryAutoStart,
  diaryStartDate,
  singleIncludedFirst,
  singleIncludedSecond,
  singleNameFirst,
  singleDescriptionFirst,
  // singleAppDisplayNameFirst,
  singleIconFirst,
  singleAutoStartFirst,
  singleStartDateFirst,
  singleNameSecond,
  singleDescriptionSecond,
  // singleAppDisplayNameSecond,
  singleIconSecond,
  singleAutoStartSecond,
  singleStartDateSecond,
  // Single Resource
  resourceIncluded,
  resourceName,
  resourceDescription,
  resourceThumbnail,
  resourceFile,
  resourceFileName,
  resourceLanguages,
  // Second,
  resourceIncludedSecond,
  resourceNameSecond,
  resourceDescriptionSecond,
  resourceThumbnailSecond,
  resourceFileSecond,
  resourceFileNameSecond,
  resourceLanguagesSecond,

  inputErrors,
  pageValid,
  allLanguages,
  selectedLanguages
}) => {
  return (
    <div className="card card-default add_study_form_page p-4">
      <h3 className="form_header mb-3">Study Information</h3>
      <div className="add_study_form_page_inputs">
        <FieldText
          tooltip="The project name that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
          tooltipPosition="bottom"
          label="Study Name"
          inputName="studyName"
          inputValue={studyName ? studyName : ""}
          onInputChange={handleInputChange}
          error={inputErrors?.studyName ? inputErrors.studyName : null}
        />
        <FieldTextArea
          tooltip="The project description that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
          label="Study Description"
          inputName="studyDescription"
          inputValue={studyDescription ? studyDescription : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyDescription ? inputErrors.studyDescription : null
          }
        />
        <FieldTextArea
          tooltip="Additional info that will be accessible to respondents in the app, when they click on the Information symbol on the home screen."
          label="Study Information"
          inputName="studyInformation"
          inputValue={studyInformation ? studyInformation : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyInformation ? inputErrors.studyInformation : null
          }
        />
        {/* Disabled Language Field */}
        {/* <FieldDropdownLanguages
          tooltip="This will be linked to Resources (same functionality as V1)"
          label="Languages"
          inputName="studyLanguages"
          selectedLanguages={selectedLanguages}
          allLanguages={allLanguages}
          onInputChange={handleInputChange}
          error={
            inputErrors?.selectedLanguages
              ? inputErrors.selectedLanguages
              : null
          }
        /> */}
        <FieldImage
          tooltip="The project's icon which will display in both the RMS and also in the app as the main study image. "
          label="Study Icon"
          inputName="studyIcon"
          inputImage={studyIcon}
          onInputChange={handleInputChange}
          error={inputErrors?.studyIcon ? inputErrors.studyIcon : null}
        />
        <FieldStatus
          tooltip="Control as to whether the study functions are active or inactive (not turned on).  This can be edited at a later stage. "
          label="Study Status"
          inputName="studyStatus"
          inputValue={studyStatus}
          onInputChange={handleInputChange}
        />
        {/* <FieldCheckbox
          label="Study Auto Start"
          subLabel="This will move all Participants in the Pending Bucket to Day 1"
          inputName="studyAutoStart"
          inputValue={studyAutoStart ? studyAutoStart : false}
          onInputChange={handleInputChange}
        />
        <FieldDate
          label="Study Start Date"
          inputName="studyStartDate"
          inputValue={studyStartDate ? studyStartDate : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyStartDate ? inputErrors.studyStartDate : null
          } 
        />*/}
        {/* <FieldDate
          label="Study Deadline"
          inputName="studyDeadline"
          inputValue={studyDeadline ? studyDeadline : ""}
          onInputChange={handleInputChange}
          error={inputErrors?.studyDeadline ? inputErrors.studyDeadline : null}
        />
        <FieldText
          label="Study Budget"
          inputName="studyBudget"
          inputValue={studyBudget ? studyBudget : ""}
          onInputChange={handleInputChange}
          error={inputErrors?.studyBudget ? inputErrors.studyBudget : null}
        /> */}

        <FieldCheckbox
          tooltip="If selected, you can set the resource settings at the same time as the study settings. If not, you can create a resource at a later stage"
          label="Resource Included"
          inputName="resourceIncluded"
          inputValue={resourceIncluded ? resourceIncluded : false}
          onInputChange={handleInputChange}
        />
        {/* if resourceIncluded */}
        {resourceIncluded && (
          <>
            <FieldText
              tooltip="This is the resource's name which will be used for displaying in RMS and the app"
              label="Resource Name"
              inputName="resourceName"
              inputValue={resourceName ? resourceName : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceName ? inputErrors.resourceName : null
              }
            />
            <FieldTextArea
              tooltip="This is the resource's description which will be used for displaying in RMS and the app"
              label="Resource Description"
              inputName="resourceDescription"
              inputValue={resourceDescription ? resourceDescription : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceDescription
                  ? inputErrors.resourceDescription
                  : null
              }
            />
            <FieldImage
              tooltip="This is the resource's thumbnail which will be used for displaying in RMS and the app"
              label="Resource Thumbnail"
              inputName="resourceThumbnail"
              inputImage={resourceThumbnail}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceThumbnail
                  ? inputErrors.resourceThumbnail
                  : null
              }
            />
            <FieldFile
              tooltip="This is the resource's file which will be used for displaying in RMS and the app"
              label="Resource File"
              inputName="resourceFile"
              file={resourceFile}
              fileName={resourceFileName}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceFile ? inputErrors.resourceFile : null
              }
            />
            <FieldDropdownLanguages
              tooltip="This will be linked to Resources (same functionality as V1)"
              label="Resource Languages"
              inputName="resourceLanguages"
              selectedLanguages={resourceLanguages}
              allLanguages={allLanguages}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceLanguages
                  ? inputErrors.resourceLanguages
                  : null
              }
            />

            {/* 2nd Resource */}
            <FieldCheckbox
              tooltip="If selected, you can set the resource settings at the same time as the study settings. If not, you can create a resource at a later stage"
              label="Second Resource Included"
              inputName="resourceIncludedSecond"
              inputValue={
                resourceIncludedSecond ? resourceIncludedSecond : false
              }
              onInputChange={handleInputChange}
            />
          </>
        )}

        {/* if resourceIncluded */}
        {resourceIncludedSecond && (
          <>
            <FieldText
              tooltip="This is the resource's name which will be used for displaying in RMS and the app"
              label="Resource Name"
              inputName="resourceNameSecond"
              inputValue={resourceNameSecond ? resourceNameSecond : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceNameSecond
                  ? inputErrors.resourceNameSecond
                  : null
              }
            />
            <FieldTextArea
              tooltip="This is the resource's description which will be used for displaying in RMS and the app"
              label="Resource Description"
              inputName="resourceDescriptionSecond"
              inputValue={
                resourceDescriptionSecond ? resourceDescriptionSecond : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceDescriptionSecond
                  ? inputErrors.resourceDescriptionSecond
                  : null
              }
            />
            <FieldImage
              tooltip="This is the resource's thumbnail which will be used for displaying in RMS and the app"
              label="Resource Thumbnail"
              inputName="resourceThumbnailSecond"
              inputImage={resourceThumbnailSecond}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceThumbnailSecond
                  ? inputErrors.resourceThumbnailSecond
                  : null
              }
            />
            <FieldFile
              tooltip="This is the resource's file which will be used for displaying in RMS and the app"
              label="Resource File"
              inputName="resourceFileSecond"
              file={resourceFileSecond}
              fileName={resourceFileNameSecond}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceFileSecond
                  ? inputErrors.resourceFileSecond
                  : null
              }
            />
            <FieldDropdownLanguages
              tooltip="This will be linked to Resources (same functionality as V1)"
              label="Resource Languages"
              inputName="resourceLanguagesSecond"
              selectedLanguages={resourceLanguagesSecond}
              allLanguages={allLanguages}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceLanguagesSecond
                  ? inputErrors.resourceLanguagesSecond
                  : null
              }
            />
          </>
        )}

        <FieldCheckbox
          tooltip="If selected, you can set the diary settings now using the wizard functionality. If not, you can still add a diary manually at a later stage."
          label="Diary Included"
          inputName="diaryIncluded"
          inputValue={diaryIncluded ? diaryIncluded : false}
          onInputChange={handleInputChange}
        />
        {/* if diaryIncluded */}
        {diaryIncluded && (
          <>
            <FieldText
              tooltip="The name of the diary that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
              label="Diary Name"
              inputName="diaryName"
              inputValue={diaryName ? diaryName : ""}
              onInputChange={handleInputChange}
              error={inputErrors?.diaryName ? inputErrors.diaryName : null}
            />
            <FieldTextArea
              tooltip="A description of the diary that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
              label="Diary Description"
              inputName="diaryDescription"
              inputValue={diaryDescription ? diaryDescription : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryDescription
                  ? inputErrors.diaryDescription
                  : null
              }
            />
            {/* <FieldText
              tooltip="It was used before, but due to phrase library upgrades, the normal Diary name is being used for app displaying"
              label="Diary App Display Name"
              inputName="diaryAppDisplayName"
              inputValue={diaryAppDisplayName ? diaryAppDisplayName : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryAppDisplayName
                  ? inputErrors.diaryAppDisplayName
                  : null
              }
            /> */}
            {/* <FieldCheckbox
          label="Study Auto Start"
          subLabel="This will move all Participants in the Pending Bucket to Day 1"
          inputName="studyAutoStart"
          inputValue={studyAutoStart ? studyAutoStart : false}
          onInputChange={handleInputChange}
        />
        <FieldDate
          label="Study Start Date"
          inputName="studyStartDate"
          inputValue={studyStartDate ? studyStartDate : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyStartDate ? inputErrors.studyStartDate : null
          } */}
            <FieldCheckbox
              label="Diary Auto Start"
              subLabel={`Automatically moves participants with the "auto start" tag to the diary's first bucket on the Start Date`}
              inputName="diaryAutoStart"
              inputValue={diaryAutoStart ? diaryAutoStart : false}
              onInputChange={handleInputChange}
            />
            <FieldDate
              tooltip="This will be where you select the start date of the diary for the study"
              label="Diary Start Date"
              inputName="diaryStartDate"
              inputValue={diaryStartDate ? diaryStartDate : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryStartDate ? inputErrors.diaryStartDate : null
              }
            />
            <FieldImage
              tooltip="This will be where you select the icon of the diary for the study"
              label="Diary Icon"
              inputName="diaryIcon"
              inputImage={diaryIcon}
              onInputChange={handleInputChange}
              error={inputErrors?.diaryIcon ? inputErrors.diaryIcon : null}
            />
          </>
        )}
        <FieldCheckbox
          tooltip="If selected, you can set the settings for a single (standalone) survey now using the wizard functionality. If not, you can still add an additional survey manually at a later stage."
          label="Single Included"
          inputName="singleIncludedFirst"
          inputValue={singleIncludedFirst ? singleIncludedFirst : false}
          onInputChange={handleInputChange}
        />
        {/* if singleIncluded */}
        {singleIncludedFirst && (
          <>
            <FieldText
              tooltip="The name of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
              label="Single Name"
              inputName="singleNameFirst"
              inputValue={singleNameFirst ? singleNameFirst : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleNameFirst
                  ? inputErrors.singleNameFirst
                  : null
              }
            />
            <FieldTextArea
              tooltip="A description of the single, stand along survey that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
              label="Single Description"
              inputName="singleDescriptionFirst"
              inputValue={singleDescriptionFirst ? singleDescriptionFirst : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleDescriptionFirst
                  ? inputErrors.singleDescriptionFirst
                  : null
              }
            />
            {/* <FieldText
              tooltip="It was used before, but due to phrase library upgrades, the normal Single survey name is being used for app displaying"
              label="Single App Display Name"
              inputName="singleAppDisplayNameFirst"
              inputValue={
                singleAppDisplayNameFirst ? singleAppDisplayNameFirst : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleAppDisplayNameFirst
                  ? inputErrors.singleAppDisplayNameFirst
                  : null
              }
            /> */}
            <FieldCheckbox
              label="Single Auto Start"
              subLabel={`Automatically moves participants with the "auto start" tag to the single's first day on the Start Date`}
              inputName="singleAutoStartFirst"
              inputValue={singleAutoStartFirst ? singleAutoStartFirst : false}
              onInputChange={handleInputChange}
            />
            <FieldDate
              tooltip="This will be where you select the start date of the single for the study"
              label="Single Start Date"
              inputName="singleStartDateFirst"
              inputValue={singleStartDateFirst ? singleStartDateFirst : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleStartDateFirst
                  ? inputErrors.singleStartDateFirst
                  : null
              }
            />
            <FieldImage
              tooltip="This will be where you select the icon of the single for the study"
              label="Single Icon"
              inputName="singleIconFirst"
              inputImage={singleIconFirst}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleIconFirst
                  ? inputErrors.singleIconFirst
                  : null
              }
            />
            <FieldCheckbox
              tooltip="This will be where you select the a second singles option for the study"
              label="Second Single Included"
              inputName="singleIncludedSecond"
              inputValue={singleIncludedSecond ? singleIncludedSecond : false}
              onInputChange={handleInputChange}
            />
          </>
        )}

        {singleIncludedSecond && (
          <>
            <FieldText
              tooltip="The name of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
              label="Single Name"
              inputName="singleNameSecond"
              inputValue={singleNameSecond ? singleNameSecond : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleNameSecond
                  ? inputErrors.singleNameSecond
                  : null
              }
            />
            <FieldTextArea
              tooltip="A description of the single, stand along survey that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
              label="Single Description"
              inputName="singleDescriptionSecond"
              inputValue={
                singleDescriptionSecond ? singleDescriptionSecond : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleDescriptionSecond
                  ? inputErrors.singleDescriptionSecond
                  : null
              }
            />
            {/* <FieldText
              tooltip="This will be where you select the app display name of the single for the study"
              label="Single App Display Name"
              inputName="singleAppDisplayNameSecond"
              inputValue={
                singleAppDisplayNameSecond ? singleAppDisplayNameSecond : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleAppDisplayNameSecond
                  ? inputErrors.singleAppDisplayNameSecond
                  : null
              }
            /> */}
            <FieldCheckbox
              label="Single Auto Start"
              subLabel={`Automatically moves participants with the "auto start" tag to the single's first day on the Start Date`}
              inputName="singleAutoStartSecond"
              inputValue={singleAutoStartSecond ? singleAutoStartSecond : false}
              onInputChange={handleInputChange}
            />
            <FieldDate
              tooltip="This will be where you select the start date of the single for the study"
              label="Single Start Date"
              inputName="singleStartDateSecond"
              inputValue={singleStartDateSecond ? singleStartDateSecond : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleStartDateSecond
                  ? inputErrors.singleStartDateSecond
                  : null
              }
            />
            <FieldImage
              tooltip="This will be where you select the icon of the single for the study"
              label="Single Icon"
              inputName="singleIconSecond"
              inputImage={singleIconSecond}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleIconSecond
                  ? inputErrors.singleIconSecond
                  : null
              }
            />
          </>
        )}
        {/* submit */}
        <AddStudyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          pageValid={pageValid}
          handleWizardSubmit={handleWizardSubmit}
        />
      </div>
    </div>
  );
};

export default StepAddStudyInformation;
