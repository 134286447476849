// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add_client_form .add_client_form_page .image_preview img {
  max-width: 300px;
  max-height: 300px;
}
#add_client_form .add_client_form_page .footer {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/Wizards/AddClientWizard/addClientWizard.scss"],"names":[],"mappings":"AAEI;EACE,gBAAA;EACA,iBAAA;AADN;AAGI;EACE,aAAA;EACA,8BAAA;AADN","sourcesContent":["#add_client_form {\n  .add_client_form_page {\n    .image_preview img {\n      max-width: 300px;\n      max-height: 300px;\n    }\n    .footer {\n      display: flex;\n      justify-content: space-between;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
