import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fetchMobileAppByID,
  sendDeleteAppByID,
  sendNewAppConfig,
  sendUpdatedAppConfig
} from "../../../../models/appsConfig.model";
import { fetchAllClientsNames } from "../../../../models/client.model";
import {
  Client,
  ConfigAppSetting,
  ConfigMobileAppStyles,
  ConfigMobileAppType
} from "../../../../types";
import {
  capitalize,
  isValidHexColor,
  useOutsideClick
} from "../../../../utilities/utils";
import "./singleApp.scss";
import SingleAppData from "./SingleAppData/SingleAppData.component";

type SingleAppProps = {
  appEditType: "update" | "copy" | "new";
};

const SingleApp: React.FC<SingleAppProps> = ({ appEditType }) => {
  const { appID } = useParams();
  const navigate = useNavigate();

  // ================== STATUS  ==================
  const [statusDropdown, setStatusDropdown] = useState(false);

  const [mobileApp, setMobileApp] = useState<ConfigMobileAppType | null>(null);
  const [loadingApp, setLoadingApp] = useState<boolean>(true);
  const [errorsFetchingData, setErrorsFetchingData] = useState<string[]>([]);

  const [newMobileApp, setNewMobileApp] = useState<ConfigMobileAppType>({
    unique_app_id: "",
    app_name: "",
    status: "inactive",
    client_id: "",
    ios_link: "",
    android_link: "",
    settings: {
      appLogo: {
        _id: { $oid: "new_app_logo" },
        key: "app_logo",
        name: "App Logo",
        value: "",
        screen: "Intro Screen, Login Screen",
        _field_type: "file"
      },
      appIcon: {
        _id: { $oid: "new_app_icon" },
        key: "app_icon",
        name: "App Icon",
        value: "",
        screen: "RMS Screen Only",
        _field_type: "file"
      },
      primaryColor01: {
        _id: { $oid: "new_primary_color_01" },
        key: "primary_color_01",
        name: "Background Text Color 01",
        value: "#000000",
        screen: "Intro Screen, Login Screen",
        _field_type: "color"
      },
      primaryColor02: {
        _id: { $oid: "new_primary_color_02" },
        key: "primary_color_02",
        name: "Background Text Color 02",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      primaryColor03: {
        _id: { $oid: "new_primary_color_03" },
        key: "primary_color_03",
        name: "Background Text Color 03",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      highlightColor: {
        _id: { $oid: "new_highlight_color" },
        key: "highlight_color",
        name: "Highlight Color",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      highlightTextColor: {
        _id: { $oid: "new_highlight_text_color" },
        key: "highlight_text_color",
        name: "Highlight Text Color",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      bgImg01: {
        _id: { $oid: "new_bg_img_01" },
        key: "bg_img_01",
        name: "Background Image 01",
        value: "",
        screen: "Select Language Screen",
        _field_type: "file"
      },
      // bgImg02: {
      //   _id: { $oid: "" },
      //   key: "",
      //   name: "",
      //   value: "",
      //   screen: "",
      //   _field_type: ""
      // },
      // bgImg03: {
      //   _id: { $oid: "" },
      //   key: "",
      //   name: "",
      //   value: "",
      //   screen: "",
      //   _field_type: ""
      // },
      bgColor01: {
        _id: { $oid: "new_bg_color_01" },
        key: "bg_color_01",
        name: "Background Color 01",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      bgColor02: {
        _id: { $oid: "new_bg_color_02" },
        key: "bg_color_02",
        name: "Background Color 02",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      bgColor03: {
        _id: { $oid: "new_bg_color_03" },
        key: "bg_color_03",
        name: "Background Color 03",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnColor01: {
        _id: { $oid: "new_btn_color_01" },
        key: "btn_color_01",
        name: "Button Color 01",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnText01: {
        _id: { $oid: "new_btn_text_01" },
        key: "btn_text_01",
        name: "Button Text 01",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnBorder01: {
        _id: { $oid: "new_btn_border_01" },
        key: "btn_border_01",
        name: "Button Border 01",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnColor02: {
        _id: { $oid: "new_btn_color_02" },
        key: "btn_color_02",
        name: "Button Color 02",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnText02: {
        _id: { $oid: "new_btn_text_02" },
        key: "btn_text_02",
        name: "Button Text 02",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnBorder02: {
        _id: { $oid: "new_btn_border_02" },
        key: "btn_border_02",
        name: "Button Border 02",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnColor03: {
        _id: { $oid: "new_btn_color_03" },
        key: "btn_color_03",
        name: "Button Color 03",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnText03: {
        _id: { $oid: "new_btn_text_03" },
        key: "btn_text_03",
        name: "Button Text 03",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      btnBorder03: {
        _id: { $oid: "new_btn_border_03" },
        key: "btn_border_03",
        name: "Button Border 03",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      inputColor01: {
        _id: { $oid: "new_input_color_01" },
        key: "input_color_01",
        name: "Input Background Color",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      inputBorder01: {
        _id: { $oid: "new_input_border_01" },
        key: "input_border_01",
        name: "Input Border Color",
        value: "#000000",
        screen: "",
        _field_type: "color"
      },
      // bgImgBtnChat: {
      //   _id: { $oid: "" },
      //   key: "",
      //   name: "",
      //   value: "",
      //   screen: "",
      //   _field_type: ""
      // },
      // bgImgBtnInfo: {
      //   _id: { $oid: "" },
      //   key: "",
      //   name: "",
      //   value: "",
      //   screen: "",
      //   _field_type: ""
      // },
      // bgImgBtnRewards: {
      //   _id: { $oid: "" },
      //   key: "",
      //   name: "",
      //   value: "",
      //   screen: "",
      //   _field_type: ""
      // },
      firebaseKeyIOS: {
        _id: { $oid: "new_firebase_key_ios" },
        key: "firebase_key_ios",
        name: "Firebase Key (iOS) *",
        value: "no value",
        screen: "",
        _field_type: "file"
      },
      taskIcon: {
        _id: { $oid: "new_task_icon" },
        key: "task_icon",
        name: "Task Icon",
        value: "briefcase",
        screen: "",
        _field_type: "string"
      },
      resourceIcon: {
        _id: { $oid: "new_resource_icon" },
        key: "resources_icon",
        name: "Resources Icon",
        value: "book-alt",
        screen: "",
        _field_type: "string"
      },
      rewardIcon: {
        _id: { $oid: "new_reward_icon" },
        key: "rewards_icon",
        name: "Rewards Icon",
        value: "trophy",
        screen: "",
        _field_type: "string"
      },
      alertIcon: {
        _id: { $oid: "new_alert_icon" },
        key: "alert_icon",
        name: "Alert Icon",
        value: "megaphone",
        screen: "",
        _field_type: "string"
      },
      chatIcon: {
        _id: { $oid: "new_chat_icon" },
        key: "chat_icon",
        name: "Chat Icon",
        value: "comment-alt",
        screen: "",
        _field_type: "string"
      },
      infoIcon: {
        _id: { $oid: "new_info_icon" },
        key: "info_icon",
        name: "Info Icon",
        value: "info-square",
        screen: "",
        _field_type: "string"
      },
      inputTextColor: {
        _id: { $oid: "new_input_text_color" },
        key: "input_text_color",
        name: "Input Text Color",
        value: "#000000",
        screen: "",
        _field_type: "color"
      }
    }
  });
  const [allClients, setAllClients] = useState<Client[]>([]);

  // const [selectedStatus, setSelectedStatus] = useState<
  //   "active" | "inactive" | null
  // >(null);

  const statusRef = useRef<HTMLDivElement>(null);
  useOutsideClick(statusRef, () => {
    // Action to take on outside click
    handleStatusDropdown(false);
  });

  const handleStatusDropdown = (show: boolean) => {
    // show/hide dropdown
    setStatusDropdown(show);
  };

  const handleSelectStatus = (status: "active" | "inactive") => {
    handleFieldChange("status", status);
    handleStatusDropdown(false);
  };

  // #region handleFieldChange
  function handleFieldChange(
    field: string,
    value: string | number | boolean | null
  ) {
    if (!newMobileApp) {
      return;
    }
    setNewMobileApp((prevApp) => {
      return {
        ...prevApp,
        [field]: value
      };
    });
  }
  // #endregion handleFieldChange

  // #region handleSettingChange
  function handleSettingChange(
    settingName: keyof ConfigMobileAppStyles,
    id: string,
    dbField: string,
    value: string
  ) {
    if (!newMobileApp) {
      return;
    }

    console.log("handleSettingChange", id, settingName, dbField, value);

    setNewMobileApp((prevApp) => {
      return {
        ...prevApp,
        settings: {
          ...prevApp.settings,
          [settingName]: {
            ...prevApp.settings[settingName],
            value: value
          }
        }
      };
    });
  }
  // #endregion handleSettingChange

  // fetch mobile app using appID
  useEffect(() => {
    setLoadingApp(true);
    setErrorsFetchingData([]);
    // #region fetchMobileApp
    const fetchMobileApp = async () => {
      if (appEditType === "new") {
        // setLoadingApp(false);
        return;
      }

      if (!appID) {
        return;
      }
      try {
        const response = await fetchMobileAppByID(appID);
        console.log("response", response);
        // if jsonData is a string, then there was an error
        if (response.rStatus === "error") {
          console.error("error: ", response.rMessage);
          setErrorsFetchingData([response.rMessage]);
          setLoadingApp(false);
        } else {
          console.log(response.rData);
          setMobileApp(response.rData);
          setNewMobileApp(response.rData);
        }
      } catch (error) {
        console.error("An error occurred while fetching mobile app:", error);
        setErrorsFetchingData((prevErrors) => [...prevErrors, error as string]);
      }
    };
    // #endregion fetchMobileApp

    // #region fetchAllClientNames
    const fetchAllClientNames = async () => {
      try {
        const jsonData = await fetchAllClientsNames();
        if (!jsonData) {
          setErrorsFetchingData((prevErrors) => [
            ...prevErrors,
            "Error fetching clients"
          ]);
          return;
        }

        setAllClients(jsonData);
      } catch {
        console.error("Error fetching clients");
        setErrorsFetchingData((prevErrors) => [
          ...prevErrors,
          "Error fetching clients"
        ]);
      }
    };
    // #endregion fetchAllClientNames

    const promises = [fetchMobileApp(), fetchAllClientNames()];

    Promise.all(promises).then(() => {
      // All promises completed successfully
      setLoadingApp(false);
    });
  }, [appID, appEditType]);

  // #region handleDelete
  async function handleDelete() {
    console.log("handleDelete");

    if (!appID) {
      console.error("appID is null");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: "appID is null"
      });
      return;
    }

    //   Swal alert, requiring confirmation. User needs to type the uniqueAppID and press confirm to confirm
    //   if confirmed, delete app
    //   if not confirmed, do nothing
    const result = await Swal.fire({
      title: "Are you sure?",
      html: `You won't be able to revert this!<br/><br/>Type <b class="noHightlight">${newMobileApp.unique_app_id}</b> to confirm:`,
      icon: "warning",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: (inputValue) => {
        if (inputValue.trim() !== newMobileApp.unique_app_id) {
          Swal.showValidationMessage(
            `Request failed: You need to type "${newMobileApp.unique_app_id}"`
          );
          return false;
        }
        return true;
      }
    });

    if (!result.isConfirmed) {
      return;
    }
    console.log("Deletion confirmed");
    // sendDeleteAppByID
    const resultDelete: {
      rStatus: "success" | "error";
      rMessage: string;
    } = await sendDeleteAppByID(appID);

    if (resultDelete.rStatus === "success") {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Mobile app deleted successfully"
      });
      navigate("/config");
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: resultDelete.rMessage
      });
    }
  }
  // #endregion handleDelete

  // #region validateFields
  function validateFields(): true | { field: string; message: string }[] {
    // if any field is empty, return false

    let errors: {
      field: keyof ConfigMobileAppType;
      message: string;
    }[] = [];

    if (!newMobileApp.app_name || newMobileApp.app_name.trim() === "") {
      errors.push({
        field: "app_name",
        message: "App Name is required"
      });
    }

    if (!newMobileApp.client_id || newMobileApp.client_id.trim() === "") {
      errors.push({
        field: "client_id",
        message: "Client ID is required"
      });
    }

    if (!newMobileApp.status || newMobileApp.status.trim() === "") {
      errors.push({
        field: "status",
        message: "Status is required"
      });
    }

    if (!newMobileApp.ios_link || newMobileApp.ios_link.trim() === "") {
      errors.push({
        field: "ios_link",
        message: "IOS Link is required"
      });
    }

    if (!newMobileApp.android_link || newMobileApp.android_link.trim() === "") {
      errors.push({
        field: "android_link",
        message: "Android Link is required"
      });
    }

    if (errors.length > 0) {
      return errors;
    }

    return true;
  }
  // #endregion validateFields

  // #region validateSettings
  function validateSettings(): true | { field: string; message: string }[] {
    // if any setting is empty, return false
    let errors: {
      field: keyof ConfigMobileAppStyles;
      message: string;
    }[] = [];
    for (const key in newMobileApp["settings"]) {
      if (newMobileApp["settings"].hasOwnProperty(key)) {
        const element =
          newMobileApp["settings"][key as keyof ConfigMobileAppStyles];
        if (!element.value || element.value.trim() === "") {
          errors.push({
            field: key as keyof ConfigMobileAppStyles,
            message: `${element.name} is required`
          });
        } else if (
          element._field_type === "color" &&
          !isValidHexColor(element.value)
        ) {
          errors.push({
            field: key as keyof ConfigMobileAppStyles,
            message: `${element.name} must be a valid hex color`
          });
        }
      }
    }

    if (errors.length > 0) {
      return errors;
    }

    return true;
  }
  // #endregion validateSettings

  // #region handleSave
  async function handleSave() {
    console.log("handleSave");

    let errors: { field: string; message: string }[] = [];
    // validate fields
    const resValidateFields = validateFields();
    if (resValidateFields !== true) {
      errors = [...errors, ...resValidateFields];
    }

    // validate settings
    const resValidateSettings = validateSettings();
    if (resValidateSettings !== true) {
      errors = [...errors, ...resValidateSettings];
    }

    if (errors.length > 0) {
      console.log("errors", errors);
      const errorMessages = errors.map((error) => error.message);
      Swal.fire({
        icon: "error",
        title: "Please fill in all required fields",
        html: errorMessages.join("<br/>")
      });
      return;
    }

    if (appEditType === "new" || appEditType === "copy") {
      // send new app
      await sendNewApp();
    } else {
      // send updated app
      await sendAppUpdated();
    }
  }
  // #endregion handleSave

  // #region normalizeSettings
  function normalizeSettings(
    settings: ConfigMobileAppStyles
  ): ConfigAppSetting[] {
    // convert settings object to array of ConfigAppSetting objects as it will be sent to the BE
    const settingsFields: ConfigAppSetting[] = [];
    for (const key in settings) {
      if (settings.hasOwnProperty(key)) {
        const element = settings[key as keyof ConfigMobileAppStyles];
        settingsFields.push(element);
      }
    }
    return settingsFields;
  }
  // #endregion normalizeSettings

  // #region sendAppUpdated
  async function sendAppUpdated() {
    const settingsFields = normalizeSettings(newMobileApp.settings);

    Swal.fire({
      title: "Updating Mobile App",
      text: "Please wait...",
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await sendUpdatedAppConfig({
      unique_app_id: newMobileApp.unique_app_id,
      app_name: newMobileApp.app_name,
      status: newMobileApp.status,
      client_id: newMobileApp.client_id,
      ios_link: newMobileApp.ios_link,
      android_link: newMobileApp.android_link,
      settings: settingsFields
    });

    if (response.rStatus === "success") {
      // redirect to /config
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: "Mobile app updated successfully",
        didClose: () => {
          navigate("/config");
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: response.rMessage
      });
    }
  }
  // #endregion sendAppUpdated

  // #region sendNewApp
  async function sendNewApp() {
    const settingsFields = normalizeSettings(newMobileApp.settings);

    Swal.fire({
      title: "Creating Mobile App",
      text: "Please wait...",
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await sendNewAppConfig({
      unique_app_id: "",
      app_name: newMobileApp.app_name,
      status: newMobileApp.status,
      client_id: newMobileApp.client_id,
      ios_link: newMobileApp.ios_link,
      android_link: newMobileApp.android_link,
      settings: settingsFields
    });

    if (response.rStatus === "success") {
      // redirect to /config
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: "Mobile app created successfully",
        didClose: () => {
          navigate("/config");
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: response.rMessage
      });
    }
  }
  // #endregion sendNewApp

  return (
    <div className="single_app_container position-relative">
      {loadingApp ? (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="mobile_apps_page" className="container-fluid">
                <div className="d-flex justify-content-center custom_spinner_container full_page">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : errorsFetchingData.length > 0 ? (
        <div className="error_container">
          <h3>Errors</h3>
          {errorsFetchingData.map((error) => (
            <p key={error}>{error}</p>
          ))}
        </div>
      ) : (
        <>
          <div className=" position-sticky sticky-top pt-2 bg-white">
            <div className="row top_row_container">
              {/* Left side top */}
              <div className="col-12 col-md-12 app_title">
                {appEditType === "new" ? (
                  <h3>New Mobile App</h3>
                ) : appEditType === "copy" ? (
                  <h3>Copy Mobile App: {mobileApp?.app_name}</h3>
                ) : (
                  <h3>Update Mobile App: {mobileApp?.app_name}</h3>
                )}
              </div>
            </div>
            <hr className="m-0" />
          </div>
          <div className="row">
            <div>
              <div className="container mt-3">
                <div className="card m-auto col-12 col-lg-8 mobile_app_card p-4">
                  {/* App Meta */}
                  <div className="meta_details_container">
                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">App Name</div>
                      <div className="col-12 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          value={newMobileApp.app_name}
                          onChange={(e) =>
                            handleFieldChange("app_name", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">App Status</div>
                      {/* Active */}
                      <div className="col-12 col-lg-8">
                        <div
                          className="dropdown dropdown_status"
                          ref={statusRef}
                        >
                          <button
                            className="btn btn-primary dropdown-toggle"
                            onClick={() =>
                              handleStatusDropdown(!statusDropdown)
                            }
                          >
                            {newMobileApp.status
                              ? capitalize(newMobileApp.status)
                              : "Status"}
                          </button>
                          <div
                            className={`dropdown-menu ${statusDropdown ? "show" : "hide"}`}
                          >
                            <div className="dropdown-item status_item">
                              <li onClick={() => handleSelectStatus("active")}>
                                <span>Active</span>
                              </li>
                            </div>
                            <div className="dropdown-item status_item">
                              <li
                                onClick={() => handleSelectStatus("inactive")}
                              >
                                <span>Inactive</span>
                              </li>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">Client</div>
                      <div className="col-12 col-lg-8">
                        {/* dropdown with client names */}
                        <select
                          className="form-select"
                          value={newMobileApp.client_id}
                          disabled={appEditType === "update"}
                          onChange={(e) =>
                            appEditType !== "update" &&
                            handleFieldChange("client_id", e.target.value)
                          }
                        >
                          <option value="">Select Client</option>
                          {allClients.map((client) => (
                            <option key={client.id} value={client.id}>
                              {client.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">IOS Link</div>
                      <div className="col-12 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          value={newMobileApp.ios_link}
                          onChange={(e) =>
                            handleFieldChange("ios_link", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">Android Link</div>
                      <div className="col-12 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          value={newMobileApp.android_link}
                          onChange={(e) =>
                            handleFieldChange("android_link", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <SingleAppData
                    data={newMobileApp.settings}
                    handleChange={handleSettingChange}
                  />
                  <div className="row">
                    <div className="action_button_container mb-0">
                      <button
                        className="btn btn_cancel"
                        onClick={() => navigate("/config")}
                      >
                        Cancel
                      </button>
                      {appEditType === "update" && (
                        <button
                          className="btn btn_delete"
                          onClick={() => handleDelete()}
                        >
                          Delete
                        </button>
                      )}

                      <button
                        className="btn btn_save"
                        onClick={() => handleSave()}
                      >
                        {appEditType === "update" ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleApp;
