import React from "react";
import Swal from "sweetalert2";
import { ResourceData } from "../../../../types";
import { capitalize } from "../../../../utilities/utils";
import "./shareResourceModal.scss";

interface ShareResourceModalProps {
  closeModal: () => void;
  shown: boolean;
  currentResource: ResourceData;
  resourceUrl: string;
}

const ShareResourceModal: React.FC<ShareResourceModalProps> = ({
  closeModal,
  shown,
  currentResource,
  resourceUrl
}) => {
  // Function to copy resource URL to clipboard
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        Swal.fire({
          icon: "success",
          title: "Copied to clipboard",
          text: "Resource URL has been copied to your clipboard",
          confirmButtonColor: "#3085d6"
        });
        closeModal(); // Close modal after successful copy
      },
      (err) => {
        Swal.fire({
          icon: "error",
          title: "Copy failed",
          text: "Failed to copy the resource URL",
          confirmButtonColor: "#3085d6"
        });
      }
    );
  };

  // Function to get a trimmed version of the URL for display
  const getTrimmedUrl = (url: string) => {
    try {
      const urlObject = new URL(url);
      return `${urlObject.origin}/.../${urlObject.pathname.split("/").pop()}`;
    } catch (error) {
      return url;
    }
  };

  return (
    <div className={`modal share_resource_modal ${shown ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <h3 className="modal_page_header">
                  {capitalize("Share")} Resource
                </h3>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="mb-3">
                  <img
                    src={currentResource.thumbnail_url}
                    alt={currentResource.name}
                    className="resource_thumbnail"
                  />
                </div>
                <div className="mb-3">
                  <h5>{currentResource.name}</h5>
                  <label
                    className="form-label link-share-label"
                    onClick={() => copyToClipboard(resourceUrl)}
                  >
                    The link is valid for 12 hours.
                  </label>
                </div>

                {/* <div className="mb-3">
                  <label htmlFor="resourceUrl" className="form-label">
                    Resource URL:
                  </label>
                  <textarea
                    id="resourceUrl"
                    className="form-control"
                    value="The link is valid 12 hours from creation"
                    readOnly
                  />
                </div> */}
                <div className="buttons">
                  <button
                    className="btn btn-primary"
                    onClick={() => copyToClipboard(resourceUrl)}
                  >
                    Share
                  </button>
                  <button
                    className="btn btn-primary btn_cancel"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareResourceModal;
