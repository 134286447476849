// Actions dropdown component for participant
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Icons from "../../../assets/images/icons/icons";
import { sendSingleLeadActions } from "../../../models/leads.model";
import { sendSingleParticipantActions } from "../../../models/participant.model";
import { sendSingleResearcherActions } from "../../../models/researcher.model";
import { useOutsideClick } from "../../../utilities/utils";
import "./participantAction.scss";

import { usePermissions } from "../../../contexts/UserContext";

interface ParticipantActionsProps {
  participantID: string;
  closeDropdown: () => void;
  shown: boolean;
  tableType: string;
  outsideDropdown: () => void;
  participantStatus?: string;
  participantFlagged?: number;
}

const ParticipantActions: React.FC<ParticipantActionsProps> = ({
  participantID,
  closeDropdown,
  shown,
  tableType,
  outsideDropdown,
  participantStatus,
  participantFlagged
}) => {
  const { clientID, surveyID, studyID } = useParams(); // Get from URL
  const [fields, setFields] = useState<string[]>([]);
  const [showRewards, setShowRewards] = useState(false);

  const { hasPermission } = usePermissions();

  // console.log(hasPermission("write"));

  useEffect(() => {
    // based on the passed though tableType, set the fields to be displayed
    // console.log("tableType: " + tableType);
    switch (tableType) {
      case "ClientParticipants": {
        let fields = [
          participantStatus?.toLowerCase() === "blocked"
            ? "ParticipantUnblock"
            : "ParticipantBlock",
          "ParticipantRemove"
          // Disabled Participant Invitation
          // "ParticipantInvitation"
        ];

        if (participantFlagged === 0) {
          fields.push("ParticipantFlag");
        } else if (participantFlagged === 1) {
          fields.push("ParticipantUnflag");
        }

        setFields(fields);
        break;
      }

      case "StudyParticipants": {
        let fields = [
          participantStatus?.toLowerCase() === "blocked"
            ? "ParticipantUnblock"
            : "ParticipantBlock",
          "ParticipantRemove",
          // Disabled Participant Invitation
          // "ParticipantInvitation",
          "ParticipantReward"
        ];

        if (participantFlagged === 0) {
          fields.push("ParticipantFlag");
        } else if (participantFlagged === 1) {
          fields.push("ParticipantUnflag");
        }

        setFields(fields);
        break;
      }
      case "SurveyParticipants": {
        let fields = [
          participantStatus?.toLowerCase() === "blocked"
            ? "ParticipantUnblock"
            : "ParticipantBlock",
          "ParticipantRemove"
        ];

        if (participantFlagged === 0) {
          fields.push("ParticipantFlag");
        } else if (participantFlagged === 1) {
          fields.push("ParticipantUnflag");
        }

        setFields(fields);
        break;
      }
      case "DiaryParticipants": {
        let fields = [
          participantStatus?.toLowerCase() === "blocked"
            ? "ParticipantUnblock"
            : "ParticipantBlock",
          "ParticipantRemove"
        ];

        if (participantFlagged === 0) {
          fields.push("ParticipantFlag");
        } else if (participantFlagged === 1) {
          fields.push("ParticipantUnflag");
        }

        setFields(fields);
        break;
      }

      case "leadsTable":
        setFields([
          "LeadRemove"
          // "LeadBlock", "LeadUnBlock"
        ]);
        break;
      case "researchersTable":
        setFields([
          participantStatus?.toLowerCase() === "blocked"
            ? "ResearcherUnBlock"
            : "ResearcherBlock"
        ]);
        break;
      case "StudyRewards":
        setFields(["RewardApprove", "RewardDone", "RewardRejected"]);
        break;
      case "ClientRewards":
        setFields(["RewardApprove", "RewardDone", "RewardRejected"]);
        break;
      default:
        setFields(["UnknownTableType"]);
    }
  }, [tableType]);

  const handleOnParticipantRewards = (action: string) => {
    Swal.fire({
      title: "Add points",
      text: "Enter the number of points to add",
      input: "number",
      showCancelButton: true,
      confirmButtonText: "Add",
      confirmButtonColor: "#3085d6",
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      showCloseButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
        //Regex to check if the input is a number
        if (!/^\d+$/.test(value)) {
          return "Please enter a valid number";
        }
      },
      inputPlaceholder: "Enter points"
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        handleOnParticipantClick(action, result.value);
      }
    });
  };

  const handleOnParticipantClick = (action: string, value?: string) => {
    // console.log(action + " for participant: " + participantID);
    // send participantID and actions to backend
    const sendingParticipantsActions = async () => {
      try {
        const response = await sendSingleParticipantActions(
          action.toLowerCase(),
          participantID,
          tableType === "ClientParticipants"
            ? "client"
            : tableType === "StudyParticipants"
              ? "study"
              : tableType === "SurveyParticipants"
                ? "survey"
                : tableType === "DiaryParticipants"
                  ? "survey"
                  : tableType === "ClientRewards"
                    ? "client"
                    : tableType === "StudyRewards"
                      ? "study"
                      : "",
          clientID as string,
          studyID,
          surveyID,
          action === "addReward" ? value : ""
        );
        if (response !== "Success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Participant action sent successfully",
            showConfirmButton: false,
            timer: 1500
          });
        }
      } catch (error) {
        console.error(
          "An error occurred while sending participant actions:",
          error
        );
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while sending participant actions",
          confirmButtonColor: "#3085d6"
        });
      }
    };

    const promises = [sendingParticipantsActions()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        closeDropdown();
      })
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
      });
  };

  const handleOnLeadClick = (action: string) => {
    // console.log(action + " for participant: " + participantID);
    // send participantID and actions to backend
    const sendingLeadsActions = async () => {
      try {
        const response = await sendSingleLeadActions(
          action.toLowerCase(),
          participantID
        );
        if (response !== "Success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Lead action sent successfully",
            showConfirmButton: false,
            timer: 1500
          });
        }
      } catch (error) {
        console.error("An error occurred while sending Lead actions:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while sending Lead actions",
          confirmButtonColor: "#3085d6"
        });
      }
    };

    const promises = [sendingLeadsActions()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        closeDropdown();
      })
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
      });
  };

  const handleOnResearcherClick = (action: string) => {
    // console.log(action + " for participant: " + participantID);
    // send participantID and actions to backend
    const sendingLeadsActions = async () => {
      try {
        const response = await sendSingleResearcherActions(
          action.toLowerCase(),
          participantID
        );
        if (response !== "Success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Researcher action sent successfully",
            showConfirmButton: false,
            timer: 1500
          });
        }
      } catch (error) {
        console.error(
          "An error occurred while sending Researcher actions:",
          error
        );
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while sending Researcher actions",
          confirmButtonColor: "#3085d6"
        });
      }
    };

    const promises = [sendingLeadsActions()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        closeDropdown();
      })
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
      });
  };

  const refSettings = useRef<HTMLDivElement>(null);

  useOutsideClick(refSettings, () => {
    // Action to take on outside click
    if (refSettings) {
      outsideDropdown();
    }
  });

  return (
    <div
      className={`dropdown participant_actions_dropdown ${
        shown ? "show" : "hide"
      }`}
      ref={refSettings}
    >
      <div className="dropdown-content">
        {fields.map((field: string, index: number) => {
          switch (field) {
            case "ParticipantRemove":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "delete")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Remove");
                    }}
                  >
                    <Icons.IconX className="icon" />
                    Remove participant
                  </button>
                )
              );
            case "ParticipantBlock":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Block");
                    }}
                  >
                    <Icons.IconBan className="icon" />
                    Block access to apps & studies
                  </button>
                )
              );
            case "ParticipantUnblock":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Unblock");
                    }}
                  >
                    <Icons.IconCheck className="icon" />
                    Unblock access to apps & studies
                  </button>
                )
              );
            case "ParticipantFlag":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Flag");
                    }}
                  >
                    <Icons.IconFlag className="icon" />
                    Flag this participant
                  </button>
                )
              );
            case "ParticipantUnflag":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Unflag");
                    }}
                  >
                    <Icons.IconUnflag className="icon" />
                    Unflag this participant
                  </button>
                )
              );
            // Disabled Send App Invitation
            // case "ParticipantInvitation":
            //   return (
            //     <button
            //       key={field + index}
            //       className="dropdown-item"
            //       onClick={() => {
            //         handleOnParticipantClick("Send invitation");
            //       }}
            //     >
            //       <Icons.IconEmail className="icon" />
            //       Send app invitation
            //     </button>
            //   );
            case "ParticipantReward":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantRewards("addReward");
                    }}
                  >
                    <Icons.IconRewards className="icon" />
                    Add points
                  </button>
                )
              );
            case "RewardApprove":
              return (
                (hasPermission("subject") ||
                  hasPermission("rewards", "all") ||
                  hasPermission("rewards", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Approved");
                    }}
                  >
                    <Icons.IconRewards className="icon" />
                    Approve reward
                  </button>
                )
              );
            case "RewardDone":
              return (
                (hasPermission("subject") ||
                  hasPermission("rewards", "all") ||
                  hasPermission("rewards", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Done");
                    }}
                  >
                    <Icons.IconCheck className="icon" />
                    Mark as done
                  </button>
                )
              );
            case "RewardRejected":
              return (
                (hasPermission("subject") ||
                  hasPermission("rewards", "all") ||
                  hasPermission("rewards", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnParticipantClick("Rejected");
                    }}
                  >
                    <Icons.IconBan className="icon" />
                    Reject reward
                  </button>
                )
              );
            case "LeadRemove":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "delete")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnLeadClick("Remove");
                    }}
                  >
                    <Icons.IconX className="icon" />
                    Remove Lead
                  </button>
                )
              );
            case "LeadBlock":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnLeadClick("Block");
                    }}
                  >
                    <Icons.IconBan className="icon" />
                    Block access to apps & studies
                  </button>
                )
              );
            case "LeadUnBlock":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnLeadClick("UnBlock");
                    }}
                  >
                    <Icons.IconCheck className="icon" />
                    Unblock access to apps & studies
                  </button>
                )
              );
            case "ResearcherBlock":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnResearcherClick("Block");
                    }}
                  >
                    <Icons.IconBan className="icon" />
                    Block access
                  </button>
                )
              );
            case "ResearcherUnBlock":
              return (
                (hasPermission("subject") ||
                  hasPermission("participant", "all") ||
                  hasPermission("participant", "write")) && (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnResearcherClick("UnBlock");
                    }}
                  >
                    <Icons.IconCheck className="icon" />
                    Unblock access
                  </button>
                )
              );
            case "UnknownTableType":
              return <p key={field + index}>Unknown table type</p>;
            default:
              return <p key={"unknown" + index}>Unknown case {field}</p>;
          }
        })}
      </div>
      {showRewards && (
        <div className="col">
          <label htmlFor="reward">Reward Type</label>
          {/* points number or voucher dropdown based on radio selection */}
          <div id="reward_type"></div>
          {/* if rewardType = points, show number input */}
          <div id="reward_points">
            <label htmlFor="reward_points">Points</label>
            <input
              type="number"
              className="form-control"
              id="reward_points"
              placeholder="Enter points"
              // value={rewardPoints}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, ""); // This regex will allow 1 to 9
                //setRewardPoints(Number(numericValue) || 1);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ParticipantActions;
