// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templates-container {
  width: 100%;
  min-height: 70;
}
.templates-container .image-placeholder {
  width: 350px;
}`, "",{"version":3,"sources":["webpack://./src/components/StudySurveyTabSingle/Messages/Templates/templates.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".templates-container {\n  width: 100%;\n  min-height: 70;\n\n  .image-placeholder {\n    width: 350px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
