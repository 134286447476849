import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Icons from "../../../assets/images/icons/icons.ts";
import { IconBroom } from "../../../assets/images/icons/icons.ts";
import { usePermissions } from "../../../contexts/UserContext";
import {
  fetchAllIDs,
  fetchAllRewardsData,
  fetchRewardsData
} from "../../../models/rewards.model.ts";
import { Country, Language, Reward, Timezone } from "../../../types.ts";
import AdvancedAdding from "../../../utilities/advanceAdding.util.ts";
import {
  getResearcherConfigTimezone,
  getSystemConfigCountries,
  getSystemConfigLanguages,
  getSystemConfigTimezones
} from "../../../utilities/config.util.ts";
import { exportRewards } from "../../../utilities/exportTable.util.ts";
import { convertTime, formatDate } from "../../../utilities/utils.ts";
import MultipleDropdown from "../../Dropdown/multipleDropdown.component.tsx";
import ImportModal from "../../Imports/ImportModal.component.tsx";
import LoadingPencil from "../../Loaders/LoadingPencil.tsx";
import "../../Tables/BulkActions/bulkActions.scss";
import BulkActions from "../../Tables/BulkActions/bulkActions.tsx";
import PaginationNavigation from "../../Tables/PaginationNavigation/paginationNavigation.tsx";
import ParticipantActions from "../../Tables/ParticipantActions/participantAction.tsx";
import TableRowsSelector from "../../Tables/TableRowsSelector/tableRowsSelector.tsx";
import TimezoneSwitcher from "../../Tables/TimeZoneSwitcher/timezoneSwitcher.tsx";
import ViewParticipantModal from "../../ViewParticipantModal/viewParticipantModal.tsx";
import "./earned.component.scss";

interface EarnedProp {
  source: string;
}
export const Earned: React.FC<EarnedProp> = ({ source }) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [rewardsData, setRewardsData] = useState<Reward[] | string>();
  const [filterData, setFilterData] = useState<Reward[]>();
  const [displayData, setDisplayData] = useState<Reward[]>();
  const [totalResults, setTotalResults] = useState<number>(0);
  const [activeParticipant, setActiveParticipant] = useState<string | null>();
  const [AllValidIDs, setAllValidIDs] = useState<string[]>([]);
  //System Config
  const [countries, setCountries] = useState<Country[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [allTimezones, setAllTimezones] = useState<Timezone[]>([]);
  //----Filters ----
  const [resultsPerPage, setResultPerPage] = useState<number>(20);
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();
  const { studyID, clientID } = useParams();
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedCounty, setSelectedCountry] = useState<string[]>([]);
  const handleChangeRows = (rows: number) => {
    setResultPerPage(rows);
  };
  const [searchInput, setSearchInput] = useState<string>("");
  const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set());
  //Modal
  const [showModal, setShowModal] = useState(false);
  const [currentParticipantID, setCurrentParticipantID] = useState<string>();

  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  //Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //Timezone
  const [timezone, setTimezone] = useState<string>("UTC");
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone | null>(
    null
  );

  //Imports
  const [showImport, setShowImport] = useState<boolean>();
  const { hasPermission } = usePermissions();
  /*====================Pull data for tables=================*/
  useEffect(() => {
    getRewards();
    getCountries();
    getLanguages();
    getTimezones();
    getParticipantsID();
  }, []);

  const getRewards = async () => {
    setIsLoading(true);
    if (clientID && studyID) {
      const response = await fetchRewardsData(clientID, studyID);
      setIsLoading(false);
      if (response && typeof response !== "string") {
        setRewardsData(response);
        setFilterData(response);
        setTotalResults(response.length);
      }
    } else if (clientID && source === "client") {
      const response = await fetchAllRewardsData(clientID);
      setIsLoading(false);
      if (response && typeof response !== "string") {
        setRewardsData(response);
        setFilterData(response);
        setTotalResults(response.length);
      }
    } else {
      setIsLoading(false);
    }
  };
  const getStatusCount = (value: string) => {
    if (Array.isArray(rewardsData)) {
      return rewardsData.filter(
        (item) => item.status.toLowerCase() === value.toLowerCase()
      ).length;
    }
    return 0;
  };
  const getCountries = async () => {
    const response = await getSystemConfigCountries();
    if (response) {
      setCountries(response);
    }
  };
  const translateCountryCode = (countryCode: string) => {
    const country = countries.find(
      (country) => country.iso_code === countryCode
    );
    return country?.name;
  };
  const getLanguages = async () => {
    const response = await getSystemConfigLanguages();
    if (response) {
      setLanguages(response);
    }
  };
  const getTimezones = async () => {
    const response = await getSystemConfigTimezones();
    if (response) {
      setAllTimezones(response);
    }
  };
  const getBalance = (email: string) => {
    if (!Array.isArray(rewardsData)) {
      return "0";
    }

    const totalPoints = rewardsData.reduce((total, reward) => {
      if (
        reward.participant_email === email &&
        reward.status.toLowerCase() === "approved"
      ) {
        return total + reward.points;
      }
      return total;
    }, 0);

    return totalPoints.toString();
  };
  const getParticipantsID = async () => {
    let tempIDList: string[] | string = [];
    if (!clientID) return;

    if (source === "study" && studyID) {
      tempIDList = await fetchAllIDs("study", clientID, studyID);
    } else {
      tempIDList = await fetchAllIDs("client", clientID);
    }
    if (Array.isArray(tempIDList)) {
      setAllValidIDs(tempIDList);
    }
  };
  /*========================Functions============================*/
  const advancedAdding = () => {
    if (!Array.isArray(AllValidIDs) || AllValidIDs.length === 0 || !clientID) {
      Swal.fire({
        icon: "error",
        title: "Missing data",
        text: "There was an error fetching the data, please try again later."
      });
      return;
    }
    // let allIDs rewardsData.map((item) => item.participant_id);
    AdvancedAdding("rewards", AllValidIDs, clientID, studyID ? studyID : "");
  };

  const handleStatusChange = (option: string) => {
    setSelectedStatus((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const handleCountryChange = (option: string) => {
    setSelectedCountry((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const handleClearFilters = () => {
    setFromDate("");
    setToDate("");
    setSelectedStatus([]);
    setSelectedCountry([]);
    setSearchInput("");
  };
  /*========================Update functions============================*/
  const handleActionDropdown = (
    participantID: string | "close" | "outside"
  ) => {
    console.log("participantID", participantID);
    if (participantID === "close") {
      setActiveParticipant(null);

      getRewards();
    } else {
      if (participantID === activeParticipant) {
        setActiveParticipant(null);
      } else {
        setActiveParticipant(participantID);
      }
    }
  };
  /*========================Filters============================*/
  useEffect(() => {
    if (!Array.isArray(rewardsData)) return;

    let filteredData = rewardsData;
    if (fromDate && fromDate !== undefined) {
      filteredData = filteredData?.filter(
        (item) =>
          item.earned_at && new Date(item.earned_at) >= new Date(fromDate)
      );
    }

    if (toDate && toDate !== undefined) {
      filteredData = filteredData?.filter(
        (item) => item.earned_at && new Date(item.earned_at) <= new Date(toDate)
      );
    }

    if (selectedStatus && selectedStatus.length > 0) {
      filteredData = filteredData?.filter(
        (item) =>
          item.status &&
          selectedStatus.some((status) =>
            item.status.toLowerCase().includes(status.toLowerCase())
          )
      );
    }

    if (selectedCounty && selectedCounty.length > 0) {
      const selectedCountryCodes = selectedCounty.map(
        (countryName) =>
          countries.find((country) => country.name === countryName)?.iso_code
      );

      filteredData = filteredData?.filter((item) =>
        selectedCountryCodes.includes(item.country)
      );
    }

    if (searchInput && searchInput.length > 0) {
      filteredData = filteredData?.filter(
        (item) =>
          item.username &&
          item.username.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    const indexOfLastResult = currentPage * resultsPerPage;
    const indexOfFirstResult = indexOfLastResult - resultsPerPage;

    setDisplayData(filteredData?.slice(indexOfFirstResult, indexOfLastResult));
    setFilterData(filteredData);
    setTotalResults(filteredData?.length);
  }, [
    fromDate,
    toDate,
    selectedStatus,
    selectedCounty,
    searchInput,
    rewardsData,
    resultsPerPage,
    currentPage
  ]);

  /*========================Modal============================*/
  const showParticipantModal = (participantID: string) => {
    setCurrentParticipantID(participantID);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  /*========================Handle Checkbox============================*/
  const handleCheckboxChange = (id: string) => {
    const newCheckedItems = new Set(checkedItems);
    if (checkedItems.has(id)) {
      newCheckedItems.delete(id);
    } else {
      newCheckedItems.add(id);
    }
    setCheckedItems(newCheckedItems);
  };

  //For selecting all
  const handleCheckAll = () => {
    const newCheckedItems = new Set(checkedItems);
    if (filterData && filterData.length > 0) {
      if (checkedItems.size === filterData.length) {
        filterData.forEach((item) => {
          newCheckedItems.delete(item.id);
        });
      } else {
        filterData.forEach((item) => {
          newCheckedItems.add(item.id);
        });
      }
    }
    setCheckedItems(newCheckedItems);
  };
  //For Exporting all rewards
  const handleExportRewards = () => {
    if (rewardsData && Array.isArray(rewardsData) && rewardsData.length > 0) {
      exportRewards(rewardsData, source);
    } else {
      Swal.fire({
        icon: "error",
        title: "No rewards to export",
        text: "There are no rewards to export"
      });
    }
  };

  const handleImportRewards = () => {
    setShowImport(true);
  };

  const onHandleClose = () => {
    setShowImport(false);
  };

  // Disabled Single-Set Filters (Status filters now set only by status dropdown)
  // Single-Set buttons act as secondary display of selected filters
  // const handleSetFilter = (status: string) => () => {
  //   setSelectedStatus([status]);
  // };
  /*========================TimeZone============================*/
  useEffect(() => {
    getResearcherConfigTimezone().then((result) => {
      if (result) {
        setResearcherTimezone(result);
      } else {
        setResearcherTimezone(null);
      }
    });
  }, []);
  const handleChangeTimezone = (timezone: string) => {
    setTimezone(timezone);
  };

  const setTimeEarnedAt = (earnedAt: string, participant_timezone: string) => {
    const participant_offset = allTimezones.find(
      (timezone) => timezone.name === participant_timezone
    )?.offset;
    let timezoneOffset = 0;

    if (timezone === "Participant's Timezone" && participant_offset) {
      timezoneOffset = Number(participant_offset);
    } else if (timezone === "UTC") {
      timezoneOffset = 0;
    } else if (timezone === "ResearcherTimezone") {
      timezoneOffset = researcherTimezone ? researcherTimezone.offset : 0;
    }

    return formatDate(convertTime(earnedAt, timezoneOffset));
  };

  return (
    <div className="rewards-container">
      {isLoading === true ? (
        <LoadingPencil isVisible={isLoading} title="rewards" />
      ) : (
        <>
          <div className="rewards-title-container">
            <div className="rewards-title">
              <h5>Earned Rewards</h5>
              <div className="buttons-container">
                {" "}
                {/* New container for buttons */}
                <div className="advance-adding">
                  {(hasPermission("subject") ||
                    hasPermission("rewards", "all") ||
                    hasPermission("rewards", "write")) &&
                    source !== "client" && (
                      <button
                        className="btn btn-primary"
                        onClick={advancedAdding}
                        title="Used to add rewards to many participants by using their ID's."
                      >
                        Advance Adding
                      </button>
                    )}
                </div>
                <div className="advance-adding">
                  {(hasPermission("subject") ||
                    hasPermission("rewards", "all") ||
                    hasPermission("rewards", "write")) &&
                    source !== "client" && (
                      <button
                        className="btn btn-primary"
                        onClick={handleImportRewards}
                      >
                        <Icons.IconUpload className="icon icon_white btn-icon" />
                        Import Rewards
                      </button>
                    )}
                </div>
                <div className="export-rewards">
                  <button
                    className="btn btn-primary"
                    onClick={handleExportRewards}
                  >
                    <Icons.IconUpload
                      className="icon icon_white btn-icon"
                      style={{
                        transform: "rotate(180deg)"
                      }}
                    />
                    Export Rewards
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="rewards-status-container">
              <div className="status-item">
                <button
                  className="btn pending"
                  onClick={handleSetFilter("achieved")}
                >
                  {getStatusCount("achieved")} Achieved
                </button>
              </div>
              <div className="status-item">
                <button
                  className="btn done"
                  onClick={handleSetFilter("approved")}
                >
                  {getStatusCount("approved")} Approved
                </button>
              </div>
              <div className="status-item">
                <button className="btn done" onClick={handleSetFilter("done")}>
                  {getStatusCount("done")} Done
                </button>
              </div>
              <div className="status-item">
                <button
                  className="btn rejected"
                  onClick={handleSetFilter("rejected")}
                >
                  {getStatusCount("rejected")} Rejected
                </button>
              </div>
            </div> */}
          </div>
          <div className="rewards-info-container">
            <div className="rewards-filter">
              <div className="rewards-filter-date">
                <label className="date-header" htmlFor="fromDate">
                  From
                </label>
                <input
                  className="date-input"
                  type="date"
                  id="fromDate"
                  name="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="rewards-filter-date">
                <label className="date-header" htmlFor="toDate">
                  To{" "}
                </label>
                <input
                  className="date-input"
                  type="date"
                  id="toDate"
                  name="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="rewards-filter-dropdown">
                <MultipleDropdown
                  placeholder="Status"
                  options={["Achieved", "Approved", "Done", "Rejected"]}
                  onOptionToggled={handleStatusChange}
                  selectedOptions={selectedStatus}
                />
              </div>
              <div className="rewards-filter-dropdown">
                <MultipleDropdown
                  placeholder="Country"
                  options={countries.map((country) => country.name) || []}
                  onOptionToggled={handleCountryChange}
                  selectedOptions={selectedCounty}
                />
              </div>
              <div className="rewards-filter-search-container">
                <input
                  className="search-input"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  className="btn btn-primary btn-clear"
                  disabled={false}
                  onClick={handleClearFilters}
                  title="Clear filters"
                >
                  <IconBroom className="icon icon_white" />
                </button>
              </div>
              {/* Bulk action button */}
            </div>
            <div className="rewards-info">
              <div className="col-auto btn-bulk">
                <div className="rewards-status-container">
                  <div className="status-item">
                    <button
                      className="btn disabled_btns_visible achieved"
                      // onClick={handleSetFilter("achieved")}
                      style={{
                        backgroundColor: selectedStatus.includes("Achieved")
                          ? "#e7a42fc5"
                          : ""
                      }}
                      disabled
                    >
                      {getStatusCount("achieved")} Achieved
                    </button>
                  </div>
                  <div className="status-item">
                    <button
                      className="btn disabled_btns_visible approved"
                      // onClick={handleSetFilter("approved")}
                      style={{
                        backgroundColor: selectedStatus.includes("Approved")
                          ? "#79e07e"
                          : ""
                      }}
                    >
                      {getStatusCount("approved")} Approved
                    </button>
                  </div>
                  <div className="status-item">
                    <button
                      className="btn disabled_btns_visible done"
                      // onClick={handleSetFilter("done")}
                      style={{
                        backgroundColor: selectedStatus.includes("Done")
                          ? "#79e07e"
                          : ""
                      }}
                    >
                      {getStatusCount("done")} Done
                    </button>
                  </div>
                  <div className="status-item">
                    <button
                      className="btn disabled_btns_visible rejected"
                      // onClick={handleSetFilter("rejected")}
                      style={{
                        backgroundColor: selectedStatus.includes("Rejected")
                          ? "#f96565"
                          : ""
                      }}
                    >
                      {getStatusCount("rejected")} Rejected
                    </button>
                  </div>
                </div>
              </div>

              <div className="rewards-info">
                <TimezoneSwitcher
                  timezone={timezone}
                  handleChangeTimezone={handleChangeTimezone}
                  researcherTimezone={
                    researcherTimezone ? researcherTimezone : null
                  }
                  tableType={"StudyRewards"}
                />
              </div>
              <div className="rewards-info">
                <TableRowsSelector
                  handleChangeRows={handleChangeRows}
                  rowsPerPage={resultsPerPage}
                  tableName="StudyRewards"
                  arrayRowSelector={[10, 20, 50, 100]}
                />
              </div>
            </div>
            {checkedItems.size > 0 &&
              (hasPermission("subject") ||
                hasPermission("rewards", "all") ||
                hasPermission("rewards", "write")) && (
                <div className="col-auto btn-bulk">
                  <BulkActions
                    selectedParticipants={Array.from(checkedItems)}
                    tableType={
                      source === "client" ? "ClientRewards" : "StudyRewards"
                    }
                    allSelected={checkedItems.size === rewardsData?.length}
                  />
                </div>
              )}
            <div className="table-responsive no-overflow">
              <table className="table table-container">
                <thead>
                  <tr>
                    <th className="checkbox">
                      {" "}
                      <input
                        type="checkbox"
                        checked={
                          filterData && filterData.length > 0
                            ? checkedItems.size === filterData?.length
                            : false
                        }
                        onChange={handleCheckAll}
                      />
                    </th>
                    <th className="small-col">User ID</th>
                    <th className="medium-col">Username</th>
                    <th className="small-col">Email</th>
                    <th className="small-col">Mobile</th>
                    <th className="small-col">Country</th>
                    <th className="small-col">Earned At</th>
                    <th className="small-col">Points</th>
                    <th className="small-col">Status</th>
                    <th className="small-col">Balance</th>
                    {source === "client" && (
                      <th className="small-col">Source</th>
                    )}
                    <th className="tiny-col"></th>
                  </tr>
                </thead>
                <tbody>
                  {filterData !== null &&
                  typeof filterData !== "string" &&
                  filterData !== undefined &&
                  filterData.length > 0 ? (
                    displayData &&
                    displayData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="checkbox">
                            <input
                              type="checkbox"
                              className="base"
                              checked={checkedItems.has(item.id)}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                          </td>
                          <td>{item.participant_id}</td>
                          <td
                            className="view_participant clickable"
                            onClick={() =>
                              showParticipantModal(item.participant_id)
                            }
                          >
                            {item.username}
                          </td>
                          <td>{item.participant_email}</td>
                          <td>{item.participant_mobile}</td>
                          {/* We get the country code so we want to get the country names */}
                          <td>{translateCountryCode(item.country)}</td>
                          {/* Timezone cover */}
                          <td>
                            {setTimeEarnedAt(
                              item.earned_at,
                              item.participant_timezone
                            )}
                          </td>
                          <td>{item.points}</td>
                          <td>{item.status.toUpperCase()}</td>
                          <td>{getBalance(item.participant_email)}</td>
                          {source === "client" && <td>{item.study_name}</td>}
                          <td>
                            <button
                              className="button btn btn-settings"
                              onClick={() => {
                                handleActionDropdown(item.id);
                              }}
                              disabled={
                                activeParticipant === item.id.toString()
                              }
                            >
                              <Icons.IconSettings className="icon" />
                            </button>
                            {activeParticipant === item.id &&
                              (hasPermission("subject") ||
                                hasPermission("rewards", "all") ||
                                hasPermission("rewards", "write")) && (
                                <ParticipantActions
                                  participantID={item.id}
                                  shown={
                                    activeParticipant === item.id.toString()
                                  }
                                  closeDropdown={() =>
                                    handleActionDropdown("close")
                                  }
                                  tableType={
                                    source === "client"
                                      ? "ClientRewards"
                                      : "StudyRewards"
                                  }
                                  outsideDropdown={() =>
                                    handleActionDropdown("outside")
                                  }
                                />
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={9}>No rewards</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="page-nation">
              <div className="col-auto">
                <PaginationNavigation
                  currentPage={currentPage}
                  totalResults={totalResults}
                  resultsPerPage={resultsPerPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && currentParticipantID && clientID && (
        <ViewParticipantModal
          participantID={currentParticipantID}
          closeParticipantModal={handleCloseModal}
          shown={showModal}
          jsonLanguagesData={languages}
          jsonCountriesData={countries}
          jsonTimezonesData={allTimezones}
          tableType={"rewards"}
          clientID={clientID}
          surveyID={""}
          studyID={source === "client" ? "" : studyID}
        />
      )}
      {showImport && clientID && studyID && (
        <ImportModal
          source={"rewards"}
          clientID={clientID}
          studyID={studyID}
          show={showImport}
          handleClose={onHandleClose}
        />
      )}
    </div>
  );
};
