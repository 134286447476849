// ChatResourcesGrid.jsx
import React from "react";
import { ResourceData } from "../../../../../types";

type ChatResourcesGridProps = {
  resources: ResourceData[];
  onResourceClick: (resource: ResourceData) => void;
};

const ChatResourcesGrid: React.FC<ChatResourcesGridProps> = ({
  resources,
  onResourceClick
}) => {
  if (!Array.isArray(resources) || resources.length === 0) {
    return <div>No resources available</div>;
  }

  return (
    <div
      className="chat-resources"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
        gap: "10px"
      }}
    >
      {resources.map((resource: ResourceData) => (
        <div
          className="resource-item"
          key={resource.id}
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => onResourceClick(resource)}
        >
          <img
            src={resource.thumbnail_url}
            alt={resource.name}
            className="resource-thumbnail"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "100px",
              objectFit: "cover",
              borderRadius: "4px"
            }}
          />
          <div
            className="resource-name"
            style={{ marginTop: "5px", fontSize: "0.9rem" }}
          >
            {resource.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatResourcesGrid;
