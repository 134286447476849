import React, { useState } from "react";
import {
  IconAdd,
  IconBroom,
  IconWand
} from "../../../assets/images/icons/icons";
import { Condition, SelectedValues } from "../../../types";
import { FilterCondition } from "./filter-conditions.component"; // Adjust the import path as necessary

import "./bucket-filter.styles.scss";

interface BucketFilterProps {
  gsiData: any; // Replace with the correct type
  applyFilters: (filterConditions: any, useAndLogic: boolean) => void;
  clearFilters: () => void;
}

export const BucketFilter: React.FC<BucketFilterProps> = ({
  gsiData,
  applyFilters,
  clearFilters
}) => {
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [selectedValues, setSelectedValues] = useState<SelectedValues>({});
  const [useAndLogic, setUseAndLogic] = useState(true); // Default to AND logic

  const handleRadioChange = (e: any) => {
    setUseAndLogic(e.target.value === "AND");
  };

  const addCondition = () => {
    const newId =
      conditions.length > 0 ? Math.max(...conditions.map((c) => c.id)) + 1 : 1;
    setConditions([...conditions, { id: newId, type: "", value: "" }]);
  };

  const updateCondition = (
    id: number,
    type: string,
    value: string | { min: number; max: number }
  ) => {
    // Convert range object to a string if value is an object, assuming it has min and max properties
    const valueStr =
      typeof value === "object" ? `${value.min}-${value.max}` : value;

    const newConditions = conditions.map((condition) =>
      condition.id === id ? { ...condition, type, value: valueStr } : condition
    );
    setConditions(newConditions);

    // Update selectedValues for type change
    const newSelectedValues = { ...selectedValues };

    // If the type has changed and value is reset, remove the previous value from selectedValues
    if (valueStr === "") {
      Object.keys(newSelectedValues).forEach((key) => {
        newSelectedValues[key] = newSelectedValues[key].filter(
          (entry) => entry.conditionId !== id
        );
      });
    } else {
      // Ensure the type exists in selectedValues, if not, initialize it
      if (!newSelectedValues[type]) newSelectedValues[type] = [];
      const entryIndex = newSelectedValues[type].findIndex(
        (entry) => entry.conditionId === id
      );
      if (entryIndex > -1) {
        // Update existing entry
        newSelectedValues[type][entryIndex] = {
          value: valueStr,
          conditionId: id
        };
      } else {
        // Add new entry
        newSelectedValues[type].push({ value: valueStr, conditionId: id });
      }
    }

    setSelectedValues(newSelectedValues);
  };

  const removeCondition = (id: number) => {
    const newConditions = conditions.filter((condition) => condition.id !== id);
    setConditions(newConditions);

    // Update selected values upon condition removal
    const updatedSelectedValues: SelectedValues = { ...selectedValues };
    // Check if the condition to remove has a type and value
    const conditionToRemove = conditions.find(
      (condition) => condition.id === id
    );
    if (conditionToRemove && conditionToRemove.value) {
      // Filter out the entry for the removed condition by conditionId, not by value
      updatedSelectedValues[conditionToRemove.type] = updatedSelectedValues[
        conditionToRemove.type
      ].filter((entry) => entry.conditionId !== id);
    }

    setSelectedValues(updatedSelectedValues);
  };

  const handleGenerateClick = () => {
    // console.log(conditions);
    // Assume conditions is the state that holds the current filter conditions
    applyFilters(conditions, useAndLogic); // Invoke the filtering function passed as prop
  };

  const handleClearFiltersClick = () => {
    setConditions([]);
    clearFilters();
  };

  // useEffect(() => {
  // 	console.log(conditions);
  // 	console.log(selectedValues);
  // 	console.log(gsiData);
  // }, [conditions, selectedValues]);

  return (
    <div className="bucket-filter">
      <h6 className="text-uppercase">
        Bucket Filter{" "}
        {conditions.length > 0 && (
          <div>
            <button
              className="btn btn-sm btn-success generate-filtered-buckets"
              onClick={handleGenerateClick}
            >
              <IconWand
                style={{
                  height: "15px",
                  fill: "white",
                  lineHeight: "0",
                  marginBottom: "2px"
                }}
              />
            </button>

            <button
              className="btn btn-sm btn-primary"
              onClick={handleClearFiltersClick}
            >
              <IconBroom
                style={{
                  height: "15px",
                  fill: "white",
                  lineHeight: "0",
                  marginBottom: "2px"
                }}
              />
            </button>
          </div>
        )}
      </h6>
      {conditions.length > 0 && (
        <div
          className="btn-group mb-3 w-25"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio1"
            autoComplete="off"
            checked={useAndLogic}
            onChange={handleRadioChange}
            value="AND"
          />
          <label
            className="btn btn-sm btn-outline-secondary"
            htmlFor="btnradio1"
          >
            AND
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio2"
            autoComplete="off"
            checked={!useAndLogic}
            onChange={handleRadioChange}
            value="OR"
          />
          <label
            className="btn btn-sm btn-outline-secondary"
            htmlFor="btnradio2"
          >
            OR
          </label>
        </div>
      )}
      {conditions.map((condition) => (
        <FilterCondition
          key={condition.id}
          condition={condition}
          allTypes={gsiData}
          selectedValues={selectedValues}
          onUpdate={updateCondition}
          onRemove={removeCondition}
        />
      ))}
      <div className="condition-add-btn" onClick={addCondition}>
        <IconAdd />
      </div>
    </div>
  );
};
