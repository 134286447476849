import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconBroom, IconDelete } from "../../assets/images/icons/icons";
import "../../components/Tables/tableComponents.scss"; // shared styles for tables
import {
  addTag,
  deleteTag,
  fetchAllClientTagsAndParticipants
} from "../../models/tag.model";
import LoadingPencilAnimation from "../Loaders/LoadingPencil.tsx";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation.tsx";
import TableRowsSelector from "../Tables/TableRowsSelector/tableRowsSelector.tsx";
import "./clientTagsTable.scss";

type ClientTagsTableProps = {
  clientID?: string;
};

type TagDataItem = {
  id: string;
  name: string;
  participant_count: number;
};

function ClientTagsTable({ clientID }: ClientTagsTableProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //----Search-----
  const [searchInput, setSearchInput] = useState<string>("");
  const [resultsPerPage, setResultPerPage] = useState<number>(20);
  //----Data-----
  const [tagData, setTagData] = useState<TagDataItem[]>([]);
  const [filteredTagData, setFilteredTagData] = useState<TagDataItem[] | null>(
    null
  );
  const [displayData, setDisplayData] = useState<TagDataItem[] | null>(null);
  //Sorting by column
  // const [sortField, setSortField] = useState<keyof TagDataItem | null>(null);
  // const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const handleChangeRows = (rows: number) => {
    setResultPerPage(rows);
  };
  //----Management-----
  const [error, setError] = useState<string | null>(null);
  //Check participants
  const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());
  const [checkedFilteredTags, setCheckedFilteredTags] = useState<Set<string>>(
    new Set()
  );
  //----Pagination------
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(tagData.length);

  const { studyID } = useParams<{ studyID: string }>();

  /*----------Fetch Data----------*/
  const fetchTags = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAllClientTagsAndParticipants(clientID as string);
      if (typeof data === "string") {
        setError(data);
      } else {
        setTagData(data);
        setTotalResults(data.length);
        setSelectedTags(new Set());
      }

      setIsLoading(false);
    } catch (e) {
      setError("Error fetching data");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchTags();
  }, []);

  /*----------Sorting----------*/
  // const sortData = (data: TagDataItem[]) => {
  //   if (!sortField) return data;
  //   return [...data].sort((a, b) => {
  //     if (sortDirection === "asc") {
  //       return a[sortField] > b[sortField] ? 1 : -1;
  //     } else {
  //       return a[sortField] < b[sortField] ? 1 : -1;
  //     }
  //   });
  // };
  // const handleSort = (field: keyof TagDataItem) => {
  //   if (sortField === field) {
  //     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortField(field);
  //     setSortDirection("asc");
  //   }
  // };

  /*----------Search Tags----------*/
  useEffect(() => {
    let filteredData = tagData;

    if (searchInput && searchInput.length > 0) {
      filteredData = tagData.filter((tag) =>
        tag.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setCurrentPage(1);
    }

    setFilteredTagData(filteredData);

    const indexOfLastResult = currentPage * resultsPerPage;
    const indexOfFirstResult = indexOfLastResult - resultsPerPage;
    setDisplayData(filteredData.slice(indexOfFirstResult, indexOfLastResult));

    setTotalResults(filteredData.length);
  }, [tagData, currentPage, resultsPerPage, searchInput]);

  //Clear Filters
  const handleClearFilters = () => {
    setSearchInput("");
    setCurrentPage(1);
  };
  // const selectAllTags = () => {
  //   //Cool feature but unsure if we want it for this table
  //   //const newSelectedTags = new Set<number>(selectedTags);
  //   //This saves the filtered tags that are checked

  //   const areAllFilteredTagsSelected = filteredTagData?.every(tag => newSelectedTags.has(tag.id));

  //   if (areAllFilteredTagsSelected) {
  //     filteredTagData?.forEach(tag => newSelectedTags.delete(tag.id));
  //   } else {
  //     filteredTagData?.forEach(tag => newSelectedTags.add(tag.id));
  //   }

  //   setSelectedTags(newSelectedTags);
  // };
  const selectAllTags = () => {
    const newSelectedTags = new Set<string>();

    if (filteredTagData) {
      if (selectedTags.size !== filteredTagData.length) {
        // Add all tag IDs to selectedTags
        filteredTagData.forEach((tag) => newSelectedTags.add(tag.id));
      } else {
        // Remove all tag IDs from selectedTags
        filteredTagData.forEach((tag) => newSelectedTags.delete(tag.id));
      }
      setSelectedTags(newSelectedTags);
    }
  };

  const selectTag = (tagID: string) => {
    const newSelectedTags = new Set<string>(selectedTags);
    if (newSelectedTags.has(tagID)) {
      newSelectedTags.delete(tagID);
    } else {
      newSelectedTags.add(tagID);
    }
    setSelectedTags(newSelectedTags);
  };

  /* ---------Loading Animation--------- */
  if (isLoading)
    return <LoadingPencilAnimation isVisible={isLoading} title={"tags..."} />;

  //Create tag function
  const createTag = async () => {
    //Regex for the tag name, no special characters except for "-" and"_"", from 4 to 60 characters
    //const tagRegex = /^[^\/\:\;'",\.<>@\!\$\%\^\&\`\*\)\(\}\{\]\[]{4,60}$/;
    const tagRegex = /^[a-zA-Z0-9_-]{2,60}$/;

    const { value: tag } = await Swal.fire({
      title: "Create a new tag",
      input: "text",
      inputLabel: "Enter your new tag for this client",
      inputPlaceholder: "New Tag Name",
      confirmButtonColor: "#3085d6",
      preConfirm: (inputValue) => {
        if (!tagRegex.test(inputValue)) {
          Swal.showValidationMessage(
            "Please enter a valid tag name from 2 to 60 characters with no special characters except for '-' or '_'"
          );
        }
      }
    });
    if (tag) {
      try {
        Swal.fire({
          title: "Creating new tag",
          html: "Please wait while we create your new tag",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        const result = await addTag(clientID as string, studyID as string, tag);
        if (result === "success") {
          Swal.fire({
            icon: "success",
            title: "Your tag has been created!",
            confirmButtonColor: "#3085d6"
          });
          fetchTags();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!"
          });
        }
      } catch (error) {
        console.log("Error creating", tag);
      }
    }
  };
  const removeTag = async (tagID: string, tagName: string) => {
    Swal.fire({
      title: "Are you sure?",
      html: `Please confirm you want to delete this tag: <b>${tagName}</b>`,
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
      cancelButtonColor: "#ff0000"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deletionResult = await deleteTag(clientID as string, [tagID]);
          if (deletionResult && deletionResult === "success") {
            Swal.fire({
              icon: "success",
              title: "Tag deleted successfully",
              text: `Your tag ${tagName} has been deleted.`,
              confirmButtonColor: "#3085d6"
            });
            fetchTags();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "There was an error deleting your tag, please try again later!",
              confirmButtonColor: "#3085d6"
            });
          }
        } catch (error) {
          console.log("Error deleting", tagID);
        }
      }
    });
  };
  //Bulk delete are the same as single action deletes but with multiple tags
  const bulkRemoveTags = async () => {
    const workerID = localStorage.getItem("workerID");
    const tagIDs: string[] = Array.from(selectedTags);
    if (workerID && workerID !== null) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
    } else if (tagIDs.length <= 0) {
      Swal.fire({
        icon: "warning",
        title: "Please select at least one tag",
        confirmButtonColor: "#3085d6"
      });
    } else {
      Swal.fire({
        title: `Are you sure you want to delete ${tagIDs.length} tags?`,
        html: `
        <div>         
          <label>Please enter "${tagIDs.length}" to confirm your action.</label>
          <input id="swal-input1" class="swal2-input" style="margin-left: 10px; height:2.5rem; width:10rem;" placeholder="Enter amount">
        </div>`,
        preConfirm: () => {
          const inputValue = (
            document.getElementById("swal-input1") as HTMLInputElement
          ).value;
          if (inputValue !== tagIDs.length.toString()) {
            Swal.showValidationMessage("Please type the correct number");
          }
        },
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deletionResult = await deleteTag(clientID as string, tagIDs);
            console.log(deletionResult, "deletionResult");
            if (deletionResult && deletionResult === "success") {
              Swal.fire({
                icon: "success",
                title: "Tag deleted successfully",
                text: `Tag has been deleted`,
                confirmButtonColor: "#3085d6"
              });
              fetchTags();
            } else if (deletionResult && deletionResult === "successfulBulk") {
              Swal.fire({
                icon: "success",
                title: "Worker has been created",
                confirmButtonColor: "#3085d6"
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "There was an error deleting your tags, please try again later!"
              });
            }
          } catch (error) {
            console.log("Error deleting", tagIDs);
          }
        }
      });
    }
  };

  return (
    <div className="tag_container">
      <div className="search-container">
        <div className="search-types">
          <input
            type="text"
            name="search"
            className="text_search"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
          <div className="action-buttons">
            <button
              className="btn btn-primary btn-clear"
              disabled={false}
              onClick={handleClearFilters}
            >
              <IconBroom className="icon icon_white" />
            </button>
            <button className="add-btn" onClick={createTag}>
              Add Tag
            </button>
          </div>
        </div>
        <div className="col-auto d-flex">
          <TableRowsSelector
            handleChangeRows={handleChangeRows}
            rowsPerPage={resultsPerPage}
            tableName="Reports"
            arrayRowSelector={[10, 20, 50, 100]}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table tag-table">
          <thead>
            <tr>
              <th className="checkbox">
                <input
                  type="checkbox"
                  checked={
                    filteredTagData && filteredTagData.length > 0
                      ? selectedTags.size === filteredTagData?.length
                      : false
                  }
                  onChange={selectAllTags}
                />
              </th>
              <th>Tag</th>
              <th> Participants</th>
              <th>
                <button className="remove-btn" onClick={bulkRemoveTags}>
                  <span className="icon-container">
                    <IconDelete className="icon icon_white" />
                  </span>
                  Bulk Delete {selectedTags.size} / {tagData.length}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan={4}>No data found, Please try again later.</td>
              </tr>
            ) : filteredTagData && filteredTagData.length > 0 ? (
              displayData &&
              displayData.map((tag, i) => (
                <tr key={i}>
                  <td className="checkbox">
                    <input
                      type="checkbox"
                      checked={selectedTags.has(tag.id)}
                      onChange={() => selectTag(tag.id)}
                    />
                  </td>
                  <td>{tag?.name}</td>
                  <td>{tag?.participant_count}</td>
                  <td>
                    <button
                      className="remove-btn"
                      onClick={() => removeTag(tag.id, tag.name)}
                    >
                      {/* <IconDelete className="icon icon_white" /> */}
                      Delete Tag
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No results found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-auto">
          <PaginationNavigation
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalResults={totalResults}
            resultsPerPage={resultsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default ClientTagsTable;
