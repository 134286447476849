import React, { useState } from "react";
import WSCImports from "./wscImports.component";
import WSCMapping from "./wscMapping.component";

type SurveyWSCProps = {
  // TODO: add props here
};

const SurveyWSC: React.FC<SurveyWSCProps> = () => {
  const [page, setPage] = useState<"import" | "mapping">("import");

  const [reloadKey, setReloadKey] = useState(0);

  const reloadComponent = () => {
    setReloadKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="row survey_wsc_container">
      <div className="col-12">
        <div className="btn-group btn-sm survey_wsc_container_switcher">
          <button
            type="button"
            className={`btn btn-sm btn-outline-secondary ${
              page === "import" ? "active" : ""
            }`}
            onClick={() => setPage("import")}
          >
            Imports
          </button>
          <button
            type="button"
            className={`btn btn-sm btn-outline-secondary ${
              page === "mapping" ? "active" : ""
            }`}
            onClick={() => setPage("mapping")}
          >
            Mapping
          </button>
        </div>
        <div>
          {page === "import" && (
            <WSCImports key={reloadKey} reloadComponent={reloadComponent} />
          )}
          {page === "mapping" && (
            <WSCMapping key={reloadKey} reloadComponent={reloadComponent} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SurveyWSC;
