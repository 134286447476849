import React from "react";

import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldNotification from "../../Fields/fieldNotification.component";
import FieldNumber from "../../Fields/fieldNumber.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
import FieldTime from "../../Fields/fieldTime.component";
import { AddStudyWizardStepSingleNotificationsProps } from "../addStudyWizard.component";
import AddStudyWizardFooter from "../addStudyWizardFooter.component";

interface StepSingleNotificationsProps
  extends AddStudyWizardStepSingleNotificationsProps {}

const StepSingleNotifications: React.FC<StepSingleNotificationsProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleInputChange,
  handleWizardSubmit,
  pageValid,
  // Fields
  singleWelcomeNotification,
  singleNonComplianceNotification,
  singleWelcomeNotificationsData,
  singleNonComplianceNotificationsData,
  singleNewWelcomeNotification,
  singleNewNonComplianceNotification,
  // singleDailyReminderNotification,
  // singleDailyReminderNotificationsData,
  // singleNewDailyReminderNotification,
  inputErrors,
  singleSurveyFirst,
  singleNonComplianceNotificationStartDelay,
  singleNonComplianceNotificationInBetweenDelay,
  singleNonComplianceTime,
  surveyName,
  singleIncludeNotifications
}) => {
  return (
    <div className="card card-default add_study_form_page p-4">
      <h3 className="form_header">Single Notifications: {surveyName}</h3>
      <div className="add_study_form_page_inputs">
        {/* checkbox  */}
        <FieldCheckbox
          label="Send out notifications for this survey?"
          tooltip="This will allow you to include notifications for this survey"
          tooltipPosition="bottom"
          inputName={
            singleSurveyFirst
              ? "singleFirstIncludeNotifications"
              : "singleSecondIncludeNotifications"
          }
          inputValue={singleIncludeNotifications}
          onInputChange={handleInputChange}
          error={
            singleSurveyFirst
              ? inputErrors?.singleFirstIncludeNotifications
                ? inputErrors.singleFirstIncludeNotifications
                : null
              : inputErrors?.singleSecondIncludeNotifications
                ? inputErrors.singleSecondIncludeNotifications
                : null
          }
        />
        {singleIncludeNotifications && (
          <>
            <FieldNotification
              tooltip="This will be where you select the single welcome notification for the study"
              label="Welcome Notification"
              inputName={
                singleSurveyFirst
                  ? "singleFirstWelcomeNotification"
                  : "singleSecondWelcomeNotification"
              }
              inputValue={singleWelcomeNotification}
              inputOptions={singleWelcomeNotificationsData}
              onInputChange={handleInputChange}
              error={
                singleSurveyFirst
                  ? inputErrors?.singleFirstWelcomeNotification
                    ? inputErrors.singleFirstWelcomeNotification
                    : null
                  : inputErrors?.singleSecondWelcomeNotification
                    ? inputErrors.singleSecondWelcomeNotification
                    : null
              }
            />
            {singleWelcomeNotification === "new" && (
              <>
                <FieldTextArea
                  tooltip="This will be where you select the new welcome message for the study"
                  label="New Welcome Message"
                  inputName={
                    singleSurveyFirst
                      ? "singleFirstNewWelcomeNotificationPhrase"
                      : "singleSecondNewWelcomeNotificationPhrase"
                  }
                  inputValue={
                    singleNewWelcomeNotification
                      ? singleNewWelcomeNotification
                      : ""
                  }
                  onInputChange={handleInputChange}
                  error={
                    singleSurveyFirst
                      ? inputErrors?.singleFirstNewWelcomeNotificationPhrase
                        ? inputErrors.singleFirstNewWelcomeNotificationPhrase
                        : null
                      : inputErrors?.singleSecondNewWelcomeNotificationPhrase
                        ? inputErrors.singleSecondNewWelcomeNotificationPhrase
                        : null
                  }
                />
              </>
            )}
            {/* <FieldNotification
          label="Daily Reminder Notification"
          inputName={
            singleSurveyFirst
              ? "singleFirstDailyReminderNotification"
              : "singleSecondDailyReminderNotification"
          }
          inputValue={singleDailyReminderNotification}
          inputOptions={singleDailyReminderNotificationsData}
          onInputChange={handleInputChange}
          error={
            singleSurveyFirst
              ? inputErrors?.singleFirstDailyReminderNotification
                ? inputErrors.singleFirstDailyReminderNotification
                : null
              : inputErrors?.singleSecondDailyReminderNotification
              ? inputErrors.singleSecondDailyReminderNotification
              : null
          }
        />
        {
          // if singleDailyReminderNotification = new
          singleDailyReminderNotification === "new" && (
            <>
              <FieldTextArea
                label="New Daily Reminder Message"
                inputName={
                  singleSurveyFirst
                    ? "singleFirstNewDailyReminderNotificationPhrase"
                    : "singleSecondNewDailyReminderNotificationPhrase"
                }
                inputValue={
                  singleNewDailyReminderNotification
                    ? singleNewDailyReminderNotification
                    : ""
                }
                onInputChange={handleInputChange}
                error={
                  singleSurveyFirst
                    ? inputErrors?.singleFirstNewDailyReminderNotificationPhrase
                      ? inputErrors.singleFirstNewDailyReminderNotificationPhrase
                      : null
                    : inputErrors?.singleSecondNewDailyReminderNotificationPhrase
                    ? inputErrors.singleSecondNewDailyReminderNotificationPhrase
                    : null
                }
              />
            </>
          )
        } */}
            <FieldNotification
              tooltip="This will be where you select the non-compliance notification for the study"
              label="Non-Compliance Notification"
              inputName={
                singleSurveyFirst
                  ? "singleFirstNonComplianceNotification"
                  : "singleSecondNonComplianceNotification"
              }
              inputValue={singleNonComplianceNotification}
              inputOptions={singleNonComplianceNotificationsData}
              onInputChange={handleInputChange}
              error={
                singleSurveyFirst
                  ? inputErrors?.singleFirstNonComplianceNotification
                    ? inputErrors.singleFirstNonComplianceNotification
                    : null
                  : inputErrors?.singleSecondNonComplianceNotification
                    ? inputErrors.singleSecondNonComplianceNotification
                    : null
              }
            />
            {
              // if singleNonComplianceNotification = new
              singleNonComplianceNotification === "new" && (
                <>
                  <FieldTextArea
                    tooltip="This will be where you select the new non-compliance message for the study"
                    label="New Non-Compliance Message"
                    inputName={
                      singleSurveyFirst
                        ? "singleFirstNewNonComplianceNotificationPhrase"
                        : "singleSecondNewNonComplianceNotificationPhrase"
                    }
                    inputValue={
                      singleNewNonComplianceNotification
                        ? singleNewNonComplianceNotification
                        : ""
                    }
                    onInputChange={handleInputChange}
                    error={
                      singleSurveyFirst
                        ? inputErrors?.singleFirstNewNonComplianceNotificationPhrase
                          ? inputErrors.singleFirstNewNonComplianceNotificationPhrase
                          : null
                        : inputErrors?.singleSecondNewNonComplianceNotificationPhrase
                          ? inputErrors.singleSecondNewNonComplianceNotificationPhrase
                          : null
                    }
                  />
                </>
              )
            }

            <FieldTime
              tooltip="This will be where you select the non-compliance time for the study"
              label="Non-Compliance Time"
              inputName={
                singleSurveyFirst
                  ? "singleFirstNonComplianceTime"
                  : "singleSecondNonComplianceTime"
              }
              inputValue={
                singleNonComplianceTime ? singleNonComplianceTime : ""
              }
              onInputChange={handleInputChange}
              subLabel="User local time"
              error={
                singleSurveyFirst
                  ? inputErrors?.singleFirstNonComplianceTime
                    ? inputErrors.singleFirstNonComplianceTime
                    : null
                  : inputErrors?.singleSecondNonComplianceTime
                    ? inputErrors.singleSecondNonComplianceTime
                    : null
              }
            />

            <FieldNumber
              tooltip="This will be where you select the non-compliance start delay in days for the study"
              label="Non-Compliance Notification Start Delay (Days)"
              inputName={
                singleSurveyFirst
                  ? "singleFirstNonComplianceNotificationStartDelay"
                  : "singleSecondNonComplianceNotificationStartDelay"
              }
              inputValue={singleNonComplianceNotificationStartDelay}
              onInputChange={handleInputChange}
              error={
                singleSurveyFirst
                  ? inputErrors?.singleFirstNonComplianceNotificationStartDelay
                    ? inputErrors.singleFirstNonComplianceNotificationStartDelay
                    : null
                  : inputErrors?.singleSecondNonComplianceNotificationStartDelay
                    ? inputErrors.singleSecondNonComplianceNotificationStartDelay
                    : null
              }
            />
            <FieldNumber
              tooltip="This will be where you select the non-compliance notification interval delay in days for the study"
              label="Non-Compliance Notification Interval Delay (Days)"
              inputName={
                singleSurveyFirst
                  ? "singleFirstNonComplianceNotificationInBetweenDelay"
                  : "singleSecondNonComplianceNotificationInBetweenDelay"
              }
              inputValue={singleNonComplianceNotificationInBetweenDelay}
              onInputChange={handleInputChange}
              error={
                singleSurveyFirst
                  ? inputErrors?.singleFirstNonComplianceNotificationInBetweenDelay
                    ? inputErrors.singleFirstNonComplianceNotificationInBetweenDelay
                    : null
                  : inputErrors?.singleSecondNonComplianceNotificationInBetweenDelay
                    ? inputErrors.singleSecondNonComplianceNotificationInBetweenDelay
                    : null
              }
            />
          </>
        )}

        <AddStudyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepSingleNotifications;
