import Swal from "sweetalert2";
import { fetchSidebarData } from "../models/sidebar.model";
import { SidebarData } from "../types";

export async function getSidebarData(): Promise<SidebarData[] | false> {
  // check if config exists in local storage and return it
  const sidebarData: string | null = localStorage.getItem("sidebarData");
  if (sidebarData) {
    try {
      return JSON.parse(sidebarData);
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export async function setSidebarData(): Promise<boolean> {
  // set systemConfig in local storage, fetch from server
  try {
    const sideBarData: false | SidebarData[] = await fetchSidebarData();

    if (!sideBarData) {
      console.log("Failed to fetch all sidebarData");
      return false;
    } else {
      // console.log(sideBarData);
      console.log("Successfully fetched all sidebarData");
      localStorage.setItem("sidebarData", JSON.stringify(sideBarData));
      console.log("sidebarData set in local storage");
      return true;
    }
  } catch {
    console.log("Failed to fetch all sidebarData");
    Swal.fire({
      title: "Error",
      html: "<strong>Please contact support</strong><br/>Failed to set sidebar data to local storage",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}
