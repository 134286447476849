// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formParticipantResetPassword .btn-primary {
  background-color: #1a2345;
  border-color: #1a2345;
}
.formParticipantResetPassword .btn-primary:hover {
  background-color: #1a2345;
  border-color: #1a2345;
}
.formParticipantResetPassword .btn-primary:active {
  background-color: #253365;
  border-color: #253365;
}`, "",{"version":3,"sources":["webpack://./src/components/ParticipantResetPassword/participantResetPassword.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,qBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,qBAAA;AADJ;AAIE;EACE,yBAAA;EACA,qBAAA;AAFJ","sourcesContent":[".formParticipantResetPassword {\n  .btn-primary {\n    background-color: #1a2345;\n    border-color: #1a2345;\n  }\n\n  .btn-primary:hover {\n    background-color: #1a2345;\n    border-color: #1a2345;\n  }\n\n  .btn-primary:active {\n    background-color: #253365;\n    border-color: #253365;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
