// ChatTagsFilter.tsx

import React, { useEffect, useRef, useState } from "react";
// import { Tags } from "../../../types";
import { useOutsideClick } from "../../../../../utilities/utils";

interface ChatTagsFilterProps {
  // jsonTagsData: any;
  onChatStatusFilterChange: (tags: string[]) => void;
  filtersCleared: boolean;
  filterName: string;
}

const ChatStatusFilter: React.FC<ChatTagsFilterProps> = ({
  // jsonTagsData,
  onChatStatusFilterChange,
  filtersCleared,
  filterName
}) => {
  const [ChatStatusFilter, setChatStatusFilter] = useState<string>("");
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [participantsChatStatusFilter, setParticipantsChatStatusFilter] =
    useState<Record<string, boolean>>({});

  const handleStatusDropdown = (show: boolean) => {
    // show/hide dropdown
    setStatusDropdown(show);
  };

  const handleStatusSearchFilter = (search: string) => {
    // set search value
    setChatStatusFilter(search);
  };

  const handleChatStatusFilter = (tag: string, checked: boolean) => {
    // set tags filter
    setParticipantsChatStatusFilter((prevStatus) => ({
      ...prevStatus,
      [tag]: checked // set checked status for this tag
    }));
  };

  useEffect(() => {
    // send tags filter to parent component
    onChatStatusFilterChange(
      Object.keys(participantsChatStatusFilter).filter(
        (status) => participantsChatStatusFilter[status] // filter tags that are true
      )
    );
  }, [participantsChatStatusFilter, onChatStatusFilterChange]);

  useEffect(() => {
    // clear tags filter when filters are cleared
    if (filtersCleared) {
      setChatStatusFilter("");
      setParticipantsChatStatusFilter({});
      handleStatusSearchFilter("");
      // handleTagsDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handleStatusDropdown(false);
  });

  return (
    <div className="dropdown dropdown_status" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleStatusDropdown(!statusDropdown)}
      >
        {filterName}
      </button>
      <div className={`dropdown-menu ${statusDropdown ? "show" : "hide"}`}>
        <input
          type="text"
          placeholder="Search Status"
          value={ChatStatusFilter}
          onChange={(e) => handleStatusSearchFilter(e.target.value)}
        />

        <div className="dropdown_menu_item">
          <label htmlFor="unread" className="form_control">
            <input
              type="checkbox"
              id="unread"
              name="unread"
              value="unread"
              checked={participantsChatStatusFilter["unread"] || false} // use checked property here
              onChange={(e) =>
                handleChatStatusFilter(e.target.value, e.target.checked)
              }
            />
            Unread
          </label>
        </div>
        {/* <div className="dropdown_menu_item">
					<label htmlFor="unanswered" className="form_control">
						<input
							type="checkbox"
							id="unanswered"
							name="unanswered"
							value="unanswered"
							checked={
								participantsChatStatusFilter["unanswered"] || false
							} // use checked property here
							onChange={(e) =>
								handleChatStatusFilter(
									e.target.value,
									e.target.checked
								)
							}
						/>
						Unanswered
					</label>
				</div> */}
        <div className="dropdown_menu_item">
          <label htmlFor="flagged" className="form_control">
            <input
              type="checkbox"
              id="flagged"
              name="flagged"
              value="flagged"
              checked={participantsChatStatusFilter["flagged"] || false} // use checked property here
              onChange={(e) =>
                handleChatStatusFilter(e.target.value, e.target.checked)
              }
            />
            Flagged
          </label>
        </div>
      </div>
    </div>
  );
};

export default ChatStatusFilter;
