import React, { useEffect, useState } from "react";
import { getAllPermissions, getAllRoles } from "../../models/roles.model";
import ErrorPage from "../../pages/Error/error.page";
import { ResearcherPermission, ResearcherRole } from "../../types";
import CreateResearcherContainer from "./CreateResearcher/createReseacher.component";
import ResearcherTableContainer from "./ResearcherTable/reseacherTableContainer.component";
import RolesContainer from "./Roles/roles.component";
import "./admin.scss";

const Admin: React.FC = () => {
  const [tabControl, setTabControl] = useState("researcherTable"); // Create state variable for loading status

  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
  // Create state variable for error status
  const [errorFetchingData, setErrorFetchingData] = useState<{
    errorCode: string;
    errorMessage: string;
  } | null>(null);
  // Create state variable for error text
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  // Create state variable for all roles
  const [allRoles, setAllRoles] = useState<ResearcherRole[]>([]);
  const [allPermissions, setAllPermissions] = useState<ResearcherPermission[]>(
    []
  );

  const fetchRoles = async () => {
    // Fetch researchers data from database
    setLoadingRoles(true);

    try {
      const jsonData: string | ResearcherRole[] = await getAllRoles();

      if (typeof jsonData === "string") {
        setErrorFetchingData({
          errorCode: "500",
          errorMessage: jsonData
        });
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "067f178a46aa376bf83069d315b626a3"
        ]);
      } else {
        setErrorFetchingData(null);
        setAllRoles(jsonData);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching researchers data:",
        error
      );
      setErrorFetchingData({
        errorCode: "500",
        errorMessage: "Error fetching data"
      });
      setLoadingErrorText((prevErrors) => [
        ...prevErrors,
        "54b60f7a5dad6daf1510357ec608a0fb"
      ]);
    }

    setLoadingRoles(false);
  };

  const fetchPermissions = async () => {
    setLoadingPermissions(true);

    try {
      const jsonData: string | { [key: string]: ResearcherPermission[] } =
        await getAllPermissions();

      if (typeof jsonData === "string") {
        // Handle the error string case
        setErrorFetchingData({
          errorCode: "500",
          errorMessage: jsonData
        });
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "067f178a46aa376bf83069d315b626a3"
        ]);
      } else {
        // jsonData is the object with permissions
        setErrorFetchingData(null);

        // Example: Flatten all permissions into a single array, if needed
        const allPermissionsFlat = Object.values(jsonData).flat();
        setAllPermissions(allPermissionsFlat);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching permissions data:",
        error
      );
      setErrorFetchingData({
        errorCode: "500",
        errorMessage: "Error fetching data"
      });
      setLoadingErrorText((prevErrors) => [
        ...prevErrors,
        "54b60f7a5dad6daf1510357ec608a0fb"
      ]);
    }

    setLoadingPermissions(false);
  };

  useEffect(() => {
    // Fetch researchers data from database
    fetchRoles();
    fetchPermissions();
  }, []);

  return (
    <>
      {loadingRoles || loadingPermissions ? (
        <div className="d-flex justify-content-center custom_spinner_container full_page">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : /* ERROR MESSAGE */
      errorFetchingData ? (
        <ErrorPage
          errorCode="500"
          error={errorFetchingData.errorMessage}
          goHome={true}
        />
      ) : !(allRoles.length > 0) ? (
        <ErrorPage
          errorCode="404"
          error="No roles found or error fetching roles"
          goHome={true}
        />
      ) : !(allPermissions.length > 0) ? (
        <ErrorPage
          errorCode="404"
          error="No permissions found or error fetching permissions"
          goHome={true}
        />
      ) : (
        <>
          <div className="admin_container container-fluid">
            {/* HEADER */}
            <div className="admin_header">
              <div className="admin_header_tabs">
                <div
                  className={`admin_header_tab  ${
                    tabControl === "researcherTable" ? "selected" : ""
                  }`}
                  onClick={() => setTabControl("researcherTable")}
                >
                  <h5 className="tab_title">Researchers</h5>
                </div>
                <div
                  className={`admin_header_tab ms-3 ${
                    tabControl === "roles" ? "selected" : ""
                  }`}
                  onClick={() => setTabControl("roles")}
                >
                  <h5 className="tab_title">Roles</h5>
                </div>
                <div
                  className={`admin_header_tab ms-3 ${
                    tabControl === "createResearcher" ? "selected" : ""
                  }`}
                  onClick={() => setTabControl("createResearcher")}
                >
                  <h5 className="tab_title">Create</h5>
                </div>
              </div>
            </div>

            {/* TABS */}
            <div className="admin_tab_content">
              {tabControl && tabControl === "researcherTable" && (
                <ResearcherTableContainer allRoles={allRoles} />
              )}
              {tabControl && tabControl === "roles" && (
                <RolesContainer
                  allRoles={allRoles}
                  allPermissions={allPermissions}
                />
              )}
              {tabControl && tabControl === "createResearcher" && (
                <CreateResearcherContainer
                  allRoles={allRoles}
                  setTabControl={setTabControl}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Admin;
