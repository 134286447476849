// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language_library_container {
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: inherit;
}
.language_library_container .col_1 {
  padding: 0;
  height: inherit;
}
.language_library_container .col_2 {
  padding: 0px 1px;
  height: inherit;
}
.language_library_container .col_3 {
  padding: 0;
  padding-left: 0px;
  height: inherit;
}

#languages_page {
  height: calc(100svh - 20px);
  margin-bottom: -15px;
}

@media screen and (max-width: 768px) {
  #languages_page {
    height: auto;
  }
  #languages_page .card {
    margin-bottom: 1rem;
    height: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageLibrary/languageLibrary.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EAEA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;AAAF;AAEE;EACE,UAAA;EACA,eAAA;AAAJ;AAGE;EACE,gBAAA;EACA,eAAA;AADJ;AAKE;EACE,UAAA;EACA,iBAAA;EACA,eAAA;AAHJ;;AAOA;EACE,2BAAA;EACA,oBAAA;AAJF;;AAOA;EACE;IACE,YAAA;EAJF;EAME;IACE,mBAAA;IACA,YAAA;EAJJ;AACF","sourcesContent":[".language_library_container {\n  height: inherit;\n\n  display: flex;\n  flex-direction: row;\n  justify-content: start;\n  height: inherit;\n\n  .col_1 {\n    padding: 0;\n    height: inherit;\n  }\n\n  .col_2 {\n    padding: 0px 1px;\n    height: inherit;\n    // z-index: 2;\n  }\n\n  .col_3 {\n    padding: 0;\n    padding-left: 0px;\n    height: inherit;\n  }\n}\n\n#languages_page {\n  height: calc(100svh - 20px);\n  margin-bottom: -15px;\n}\n\n@media screen and (max-width: 768px) {\n  #languages_page {\n    height: auto;\n\n    .card {\n      margin-bottom: 1rem;\n      height: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
