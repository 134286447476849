import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { encodedMFAConfirm } from "../../utilities/encoding.util";
import { requestAndSetMfaTokens } from "../../utilities/tokenHandler.util";

export type TwoFactorProps = {
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

const TwoFactor: React.FC<TwoFactorProps> = ({
  handleEmailChange,
  email,
  setEmail
}) => {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State to indicate loading
  const [tfUnformattedCode, setTfUnformattedCode] = useState(""); // This will check for unformatted mfa code
  const [loginFailed, setLoginFailed] = useState(false); // State to indicate failed login attempt
  const [tfFailed, setTfFailed] = useState(false); // State to indicate failed MFA attempt

  const [errorMessage, setErrorMessage] = useState("");

  // For resending MFA
  const [resendCounter, setCounter] = useState(20); // Initial counter value

  // const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
  //     setEmail(e.target.value);
  // };

  const setError = (message: string) => {
    setErrorMessage(message);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  //Changes on password change
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  //Changes on code change
  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^0-9 ]/g, "");
    setTfUnformattedCode(filteredValue);
  };

  // Formats the code
  const formatCode = (unformattedCode: string) => {
    // Use a regular expression to match and extract only the digits
    const formattedCode = unformattedCode.match(/\d+/g);

    // Join the extracted digits into a single string
    return formattedCode ? formattedCode.join("") : "";
  };

  // const handleAuthCheck = () => {
  //   setAuthCheck(true);
  // };

  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);

    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // TODO: Add API call to send magic link to email

      //Validate for empty fields and email format
      if (!emailRegex.test(email)) {
        setLoginFailed(true);
        setError("Please enter a valid email address.");
        return;
      }
      if (password === "") {
        setLoginFailed(true);
        setError("Please enter a password.");
        return;
      }
      if (tfUnformattedCode === "") {
        setLoginFailed(true);
        setError("Please enter a valid MFA code.");
        return;
      }

      //Validate email by regex

      //Convert mfa code to correct format

      const formattedCode = formatCode(tfUnformattedCode);
      const tfCode = formattedCode.toString();
      // the if statement to check empty string is redundant since its used at the start of function.
      // Extra checks are not bad though;]
      if (tfUnformattedCode !== "") {
        // This will call the formatCode function which will format the unformatted code that was entered
        console.log("This is the pre formatted code", tfUnformattedCode);
        console.log("This is the formatted code", tfCode);
      } else {
        //It should never hit this but just in case
        setLoginFailed(true);
        console.log("no code entered");
        setError("Please enter a valid code.");
        return;
      }

      const encodedMFAConfirmString = await encodedMFAConfirm(
        email,
        password,
        tfCode
      );

      console.log(encodedMFAConfirmString);
      // If the encodedMFAConfirmString is null, then the login failed
      if (encodedMFAConfirmString === null) {
        setError("Invalid credentials.");
        setLoginFailed(true);
      } else {
        // pass the mfaResponse string to /auth as state
        const requestingMFATokens = await requestAndSetMfaTokens(
          encodedMFAConfirmString
        );

        console.log(requestingMFATokens);

        if (requestingMFATokens === "200") {
          console.log(requestingMFATokens);
          navigate("/clients");
        } else {
          if (requestingMFATokens) {
            setErrorMessage(requestingMFATokens);
            setLoginFailed(true);
          }
        }
      }
    } finally {
      setLoading(false);
    }

    // Flush the cache
    if ("caches" in window) {
      console.log(caches);
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    // Unregister the service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div>
      {/* EMAIL + PASS */}
      <div className="mb-3 login_input_section">
        <div className="mb-3 d-flex align-items-center justify-content-center">
          {/* <label className="form-label mb-0" htmlFor="form-stacked-text">
              Email:
            </label> */}
          <input
            className="form-control login_input"
            type="text"
            value={email}
            onChange={(e) => handleEmailChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder="Email"
            required
          />
        </div>
        <div className="mb-3 d-flex align-items-center justify-content-center">
          {/* <label className="form-label" htmlFor="form-stacked-text">
              Password:
            </label> */}
          <input
            className="form-control login_input"
            type="password"
            value={password}
            onChange={(e) => handlePasswordChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder="Password"
          />
        </div>
      </div>

      <div className="mb-3 login_input_section text-center">
        <div className="mb-3 d-flex align-items-center justify-content-center">
          {/* <label className="form-label" htmlFor="form-stacked-text">
              Verification Code:
            </label> */}
          <input
            className="form-control login_input"
            type="text"
            value={tfUnformattedCode}
            onChange={(e) => handleCodeChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            //   placeholder="12345"
            placeholder="Verification Code"
          />
        </div>
      </div>

      {/* Login button, invalid errors */}
      <div className="mt-3 text-center">
        <button
          className="btn login_btn"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Logging in..." : "Login"}
        </button>
        <span className={`text-danger ${loginFailed ? "d-inline" : "d-none"}`}>
          {errorMessage}
        </span>
      </div>
    </div>
  );
};

export default TwoFactor;
