import React, { useState } from "react";

type RoleSingleProps = {
  isSelected?: boolean;
  roleID: string;
  roleSelection: string;
  selectedRoles: string[];
  handleCheckboxChange: (roleID: string) => void;
  handleSelectSingleRole: (roleID: string) => void;
};

const RoleSingle: React.FC<RoleSingleProps> = ({
  isSelected,
  roleID,
  roleSelection,
  selectedRoles,
  handleCheckboxChange,
  handleSelectSingleRole
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div
        key={roleID}
        className={`single_role_container ${
          isSelected || isHovered ? "container_selected" : ""
        }`}
        // on hover display right side
        onClick={() => {
          handleSelectSingleRole(roleID);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={`roles hovered`}>
          <div className="role_single_checkbox">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={() => {
                handleCheckboxChange(roleID);
              }}
              checked={selectedRoles.includes(roleID)}
            />
          </div>
          <div className="role_title">{roleSelection}</div>
        </div>
      </div>
    </>
  );
};

export default RoleSingle;
