import React from "react";
import { FieldDropdown } from "../../../types";
import InformationTooltip from "../../InputInformation/InfoInputs.component";

interface FieldDropDownProps {
  onInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  inputValue: string;
  tooltip?: string;
  label: string;
  inputName: string;
  inputOptions: FieldDropdown[];
  subLabel?: string;
  error?: string | null;
  tooltipPosition?: "left" | "right" | "bottom" | "top";
}

// export type FieldDropdown = {
//   id: string;
//   name: string;
// };

const FieldDropDown: React.FC<FieldDropDownProps> = ({
  onInputChange,
  inputValue,
  tooltip,
  label,
  inputName,
  inputOptions,
  subLabel,
  error,
  tooltipPosition
}) => {
  return (
    <div className="mb-3 row">
      <div className="col-12 col-lg-4">
        <label htmlFor={inputName} className="form-label mb-1 mx-1">
          {tooltip && (
            <InformationTooltip
              marginTop="-12px"
              position={tooltipPosition ? tooltipPosition : "top"}
              tooltipText={tooltip}
            />
          )}
          {label}
          {subLabel && <span className="sub_label">{subLabel}</span>}
        </label>
      </div>
      <div className="col-12 col-lg-8">
        {error && <div className="wizard_input_error">{error}</div>}
        <select
          name={inputName}
          id={inputName}
          className="form-select"
          value={inputValue}
          onChange={(e) => onInputChange(inputName, e)}
        >
          {
            // display the options
            inputOptions.map((option) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              );
            })
          }
        </select>
      </div>
    </div>
  );
};

export default FieldDropDown;
