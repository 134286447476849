//Page to display the clients in cards

import { useParams } from "react-router-dom";
import ClientTagsTable from "../../../components/DisplayTags/clientTagsTable";
import SidebarComponent from "../../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../../types";
import "./tags.scss";

interface TagsPageProps extends SidebarProps {}

const TagsPage: React.FC<TagsPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page,
  currentClientLogo,
  currentClientName
}) => {
  const { clientID } = useParams<string>();

  if (!currentClientName) {
    return (
      <div className="main_container">
        <div className="col-sm p-3 min-vh-100">
          <div className="container-fluid">
            <div className="row">
              <div id="tags_page" className="container-fluid">
                <h1>No Client Found</h1>
              </div>
            </div>
          </div>
        </div>
        <SidebarComponent
          page="tags"
          sidebarPinned={sidebarPinned}
          onSidebarPinChange={onSidebarPinChange}
          currentClient={clientID}
          currentClientLogo={currentClientLogo}
        />
      </div>
    );
  }
  //Lets fetch data from the backend and pass it into the table

  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            <div id="tags_page" className="container-fluid">
              <h1>
                {currentClientName ? currentClientName : "No Client Found"}:
                Client Tags
              </h1>
            </div>
            <ClientTagsTable clientID={clientID} />
          </div>
        </div>
      </div>
      <SidebarComponent
        page="tags"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={clientID}
        currentClientLogo={currentClientLogo}
      />
    </div>
  );
};

export default TagsPage;
