import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  IconArrowDown,
  IconArrowUp,
  IconBroom
} from "../../../assets/images/icons/icons";
import { fetchEQData } from "../../../models/viewEQ.model";
import { EQType } from "../../../types";
import { formatDate } from "../../../utilities/utils";
import LoadingPencil from "../../Loaders/LoadingPencil";
import PaginationNavigation from "../../Tables/PaginationNavigation/paginationNavigation";
import TableRowsSelector from "../../Tables/TableRowsSelector/tableRowsSelector";
import "./ViewEQ.scss";

type ViewEQProps = {
  studyClicked: string;
};

//This component will be used to display a table that will show the following information: as well as have a search bar and filter options for the data as well as pagination
// An extra tab to be added on survey view:  "ViewEQ"
// Participant ID
// Completion Date
// Results

// Date/Time
// url links
// 784784383
// 2024/07/04 23:34
// vieweq.com/njvwj/videoid
// ALSO
// Filter - sort by date
// search parti ID

const ViewEQ: React.FC<ViewEQProps> = ({ studyClicked }) => {
  const { clientID, studyID, surveyID } = useParams<{
    clientID: string;
    studyID: string;
    surveyID: string;
  }>();
  const [EQ, setEQ] = useState<EQType[]>([]);
  const [displayEQ, setDisplayEQ] = useState<EQType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Search and filter states
  const [searchInput, setSearchInput] = useState<string>("");
  //Pagination states
  const [totalResults, setTotalResults] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resultsPerPage, setResultPerPage] = useState<number>(20);
  const indexOfLastItem = currentPage * resultsPerPage;
  const indexOfFirstItem = indexOfLastItem - resultsPerPage;
  const handleChangeRows = (rows: number) => {
    setResultPerPage(rows);
  };
  const [encodedSourceLink, setEncodedSourceLink] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("asc");
  //Fetch data from the server
  useEffect(() => {
    const getViewEQData = async () => {
      setIsLoading(true);
      if (!clientID || !studyID || !surveyID) return;
      const viewEQData = await fetchEQData(clientID, studyID, surveyID);

      if (typeof viewEQData === "string") {
        Swal.fire({
          icon: "error",
          title: "Error fetching data",
          text: viewEQData
        });
        setIsLoading(false);
        return;
      } else {
        if (viewEQData.statusCode === 200) {
          setEQ(viewEQData.ViewEQCompletes);
          setTotalResults(viewEQData.ViewEQCompletes.length);
          setDisplayEQ(
            viewEQData.ViewEQCompletes.slice(indexOfFirstItem, indexOfLastItem)
          );
          setEncodedSourceLink(encodeURIComponent(viewEQData.ViewEQLink));
        } else {
          setEQ([]);
        }
        setIsLoading(false);
        return;
      }
    };
    getViewEQData();
  }, []);

  useEffect(() => {
    // Step 1: Filter based on searchInput
    const searchFilteredEQ = EQ.filter((eqItem) => {
      return eqItem.participant_id
        .toLowerCase()
        .includes(searchInput.toLowerCase());
    });

    const sortedAndFilteredEQ = searchFilteredEQ.sort((a, b) => {
      const dateA = new Date(a.event_at).getTime();
      const dateB = new Date(b.event_at).getTime();
      return sortBy === "asc" ? dateA - dateB : dateB - dateA;
    });

    // Step 3: Update totalResults and displayEQ
    setTotalResults(sortedAndFilteredEQ.length);
    setDisplayEQ(
      sortedAndFilteredEQ.slice(
        (currentPage - 1) * resultsPerPage,
        currentPage * resultsPerPage
      )
    );
  }, [EQ, searchInput, currentPage, resultsPerPage, sortBy]);

  const handleClearFilters = () => {
    setSortBy("asc");
    setSearchInput("");
  };

  const setTimeEarnedAt = (viewAt: string) => {
    if (viewAt === "") return "N/A";
    let date = new Date(viewAt);

    return formatDate(date);
  };
  const handleSortByDate = () => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
  };

  // TODO: put this url in an ENV
  const handleViewEQ = (refKey: string) => {
    let url = `https://vieweq.com/videos/viewVideo?ref_number=${refKey}&original_link=${encodedSourceLink}`;
    window.open(url, "_blank");
  };

  return (
    <div className="tab-container">
      <div className="filters-container">
        <div className="eq-filter-search-container">
          <input
            className="search-input"
            type="text"
            id="search"
            name="search"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button
            className="btn btn-primary btn-sort"
            onClick={() => handleSortByDate()}
          >
            <span>
              Sort by Date
              {sortBy === "asc" ? (
                <IconArrowDown className="icon icon_white" />
              ) : (
                <IconArrowUp className="icon icon_white" />
              )}
            </span>
          </button>
          <button
            className="btn btn-primary btn-clear"
            disabled={false}
            onClick={handleClearFilters}
            title="Clear filters"
          >
            <IconBroom className="icon icon_white" />
          </button>
        </div>
        <div className="row-selector">
          <TableRowsSelector
            handleChangeRows={handleChangeRows}
            rowsPerPage={resultsPerPage}
            tableName="Entries"
            arrayRowSelector={[10, 20, 50, 100]}
          />
        </div>
      </div>
      <div className="tab-header">
        <div className="tab-header-item tab-header-active">
          <h5 className="tab-title">View EQ</h5>
        </div>
      </div>
      <div className="tab-content">
        {isLoading ? (
          <LoadingPencil isVisible={isLoading} title="View EQ" />
        ) : (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Participant ID</th>
                    <th>Completion Date</th>
                    <th>View EQ</th>
                  </tr>
                </thead>
                <tbody>
                  {EQ.length > 0 ? (
                    displayEQ.map((item, index) => (
                      <tr key={index}>
                        <td>{item.participant_id}</td>
                        <td>{setTimeEarnedAt(item.event_at)}</td>
                        <td
                          className="follow-link"
                          onClick={() => handleViewEQ(item.view_eq_link)}
                        >
                          <span className="follow-link">
                            {item.view_eq_link}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="page-nation">
              <div className="col-auto">
                <PaginationNavigation
                  currentPage={currentPage}
                  totalResults={totalResults}
                  resultsPerPage={resultsPerPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewEQ;
