// TagsFilter.tsx

import React, { useEffect, useRef, useState } from "react";
import { Tags } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface TagsFilterProps {
  jsonTagsData: Tags[];
  onTagsFilterChange: (tags: string[]) => void;
  filtersCleared: boolean;
  location: string;
}

const TagsFilter: React.FC<TagsFilterProps> = ({
  jsonTagsData,
  onTagsFilterChange,
  filtersCleared,
  location
}) => {
  const [tagsFilter, setTagsFilter] = useState<string>("");
  const [tagsDropdown, setTagsDropdown] = useState(false);
  const [participantsTagsFilter, setParticipantsTagsFilter] = useState<
    Record<string, boolean>
  >({});

  const handleTagsDropdown = (show: boolean) => {
    // show/hide dropdown
    setTagsDropdown(show);
  };

  const handleTagsSearchFilter = (search: string) => {
    // set search value
    setTagsFilter(search);
  };

  const handleTagsFilter = (tag: string, checked: boolean) => {
    // set tags filter
    setParticipantsTagsFilter((prevTags) => ({
      ...prevTags,
      [tag]: checked // set checked status for this tag
    }));
  };

  useEffect(() => {
    // send tags filter to parent component
    onTagsFilterChange(
      Object.keys(participantsTagsFilter).filter(
        (tag) => participantsTagsFilter[tag] // filter tags that are true
      )
    );
  }, [participantsTagsFilter, onTagsFilterChange]);

  useEffect(() => {
    // clear tags filter when filters are cleared
    if (filtersCleared) {
      setTagsFilter("");
      setParticipantsTagsFilter({});
      handleTagsSearchFilter("");
      // handleTagsDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handleTagsDropdown(false);
  });

  return (
    <div className="dropdown dropdown_tags" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handleTagsDropdown(!tagsDropdown)}
      >
        Tags
      </button>
      <div className={`dropdown-menu ${tagsDropdown ? "show" : "hide"}`}>
        {jsonTagsData && jsonTagsData.length === 0 ? (
          <span>No tags found</span>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search Tags"
              value={tagsFilter}
              onChange={(e) => handleTagsSearchFilter(e.target.value)}
            />
            <div className="dropdown_menu_items">
              {
                /* filter by tagsfilter set by input */
                /* loop through all tags */
                jsonTagsData.map((tag: Tags, index: number) => {
                  if (
                    tag.name.toLowerCase().includes(tagsFilter.toLowerCase())
                  ) {
                    return (
                      <div
                        key={`searchTags_${tag}_${index}`}
                        className="dropdown_menu_item"
                      >
                        <label
                          htmlFor={`${location}_${tag.name}`}
                          className="form_control"
                        >
                          <input
                            type="checkbox"
                            id={`${location}_${tag.name}`}
                            name={tag.name}
                            value={tag.name}
                            checked={participantsTagsFilter[tag.name] || false} // use checked property here
                            onChange={(e) =>
                              handleTagsFilter(e.target.value, e.target.checked)
                            }
                          />
                          {tag.name}
                        </label>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TagsFilter;
