import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconAdd } from "../../../assets/images/icons/icons";
import { ResearcherPermission, ResearcherRole } from "../../../types";
import Search from "../../Tables/Filters/filterSearch.component";
import CreateRole from "./createRole.component";
import RolePermissions from "./rolePermissions.component";
import RoleSingle from "./roleSingle.component";
import "./roles.scss";
import "./rolesPreview.scss";

type RolesContainerProps = {
  allRoles: ResearcherRole[];
  allPermissions: ResearcherPermission[];
};

const RolesContainer: React.FC<RolesContainerProps> = ({
  allRoles,
  allPermissions
}) => {
  const [roleSearch, setRoleSearch] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [roleSelection, setRoleSelection] = useState("");
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<ResearcherRole | null>(null);
  const [filteredSelectedRoles, setFilteredSelectedRoles] = useState<string[]>(
    []
  );

  // ==================================== VALIDATION ====================================

  const [validActionData, setValidActionData] = useState(false);
  const [validConditionData, setValidConditionData] = useState(false);
  const [validTimingData, setValidTimingData] = useState(false);

  const [viewCreateRole, setViewCreateRole] = useState(false);
  const [viewEditRole, setViewEditRole] = useState(false);

  const [loading, setLoading] = useState(true);

  const [saveConfirmed, setSaveConfirmed] = useState(false);

  // ==================================== DATA ====================================
  // DB data
  // const [automationConditionDataDB, setAutomationConditionDataDB] =
  //   useState<any>([]);

  // const [fetchedDB, setFetchedDB] = useState(false);

  useEffect(() => {
    setSelectedRole(allRoles[0] ? allRoles[0] : null);
  }, [allRoles]);

  // Clear selection and validation when switching between rules
  const clearValidation = async () => {
    setValidActionData(false);
    setValidConditionData(false);
    setValidTimingData(false);
  };

  // get single role
  const handleSelectSingleRole = async (roleID: string) => {
    // clear childConditionGroups and actions
    await clearValidation();

    const selectedRole = allRoles.find((role) => {
      return role.id === roleID;
    });

    setSelectedRole(selectedRole ? selectedRole : null);

    // if (roleID) {
    //   console.log("FOUND");
    //   await getAutomation(clientID, studyID, roleID).then((data) => {
    //     if (typeof data !== "string") {
    //       // Set single automation data
    //       setChildConditionGroups(data.conditions);
    //       setActions(data.actions);
    //       setTiming(data.timing);
    //       setSelectedStatus(data.status);
    //       setWorkflowRuleName(data.name);

    //       // Set validation
    //       setRoleSelection(data.name);
    //       setFoundAutomation(true);
    //     }
    //   });
    // }
  };

  // useEffect that selects the rule after cancel was pressed on the edit rule view
  useEffect(() => {
    // Going to edit a rule, fetch the same rule on cancel
    if (viewEditRole === false && viewCreateRole === false && selectedRole) {
      console.log("FIRING");
      try {
        // handleSelectSingleRole(selectedRoleID);
      } catch {
        console.log("No Roles");
      }
    }
  }, [viewEditRole, viewCreateRole]);

  // Select Single rule
  const handleCheckboxChange = (roleID: string) => {
    setSelectedRoles((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(roleID);

      if (isAlreadySelected) {
        // If the roleID is already selected, remove it from the array
        return prevSelected.filter((id) => id !== roleID);
      } else {
        // If the roleID is not selected, add it to the array
        return [...prevSelected, roleID];
      }
    });
  };

  const handleSelectAllCheckboxChange = () => {
    const allRulesSelected = selectedRoles.length === allRoles.length;
    if (allRulesSelected) {
      // If all rules are already selected, remove them from the array
      setSelectedRoles([]);
    } else {
      // If not all rules are selected, add them to the array
      setSelectedRoles(allRoles.map((role) => role.id));
    }
  };

  // Switch to create rule
  const handleCreateRoleView = () => {
    if (viewCreateRole || viewEditRole) {
      setViewCreateRole(false);
      setViewEditRole(false);
    } else {
      setViewCreateRole(!viewCreateRole);
    }
  };

  const handleEditRuleView = (roleID: string) => {
    console.log("clicked");

    if (roleID === "") {
      Swal.fire({
        icon: "error",
        title: "No automations selected",
        text: "Please select automations to edit"
      });
      return;
    }
    setViewEditRole(!viewEditRole);
    setViewCreateRole(false);

    console.log(viewEditRole);
  };

  // Delete automations
  // const handleDeleteRoles = async (roleIDs: string[]) => {
  //   try {
  //     if (roleIDs.length === 0 || roleIDs === undefined || roleIDs[0] === "") {
  //       Swal.fire({
  //         icon: "error",
  //         title: "No automations selected",
  //         text: "Please select automations to delete",
  //       });
  //       return;
  //     }
  //     const { value: confirm } = await Swal.fire({
  //       title: "Delete Automation",
  //       text: "Are you sure you wish to delete these automations?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //     });
  //     if (confirm) {
  //       if (clientID && studyID) {
  //         deleteAutomations(clientID, studyID, roleIDs).then((data) => {
  //           if (data === "202") {
  //             // If the rules were deleted successfully, reset the selected rules
  //             Swal.fire({
  //               icon: "success",
  //               title: "Success",
  //               text: `Automations has been deleted successfully`,
  //             });
  //             setSelectedRoles([]);
  //             getAllAutomations(clientID, studyID).then((data) => {
  //               if (typeof data !== "string") {
  //                 setAllAutomations(data);
  //                 setSelectedRoleID("");
  //                 setRoleSelection("");
  //                 clearValidation();
  //                 handleSelectSingleRole(data[0].id);
  //               } else {
  //                 setAllAutomations([]);
  //                 setSelectedRoleID("");
  //                 setRoleSelection("");
  //                 clearValidation();
  //               }
  //             });
  //           } else {
  //             Swal.fire({
  //               icon: "error",
  //               title: "Failed to delete automation",
  //               text: data,
  //             });
  //           }
  //         });
  //       } else {
  //         console.log("ERROR, invalid clientID or studyID");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, "Error deleting tag");
  //   }
  // };

  return (
    <div className="roles_main_container">
      {viewCreateRole ? (
        <>
          {/* CREATE RULE */}
          <div className="roles_create_rule_container col-12">
            <CreateRole
              handleCreateRoleView={handleCreateRoleView}
              setSaveConfirmed={setSaveConfirmed}
              allRoles={allRoles}
              allPermissions={allPermissions}
            />
          </div>
        </>
      ) : (
        <div className="container-fluid">
          <div className="row" style={{ border: " 0.5px #cecece solid" }}>
            {/* LEFT SIDE */}
            <div className={`role_container col-3`}>
              <div className="role_filters">
                <div className="checkbox_drop_search_container">
                  {/* CHECKBOX */}
                  <div className="checkbox_select d-flex align-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="select_all_checkbox"
                      onChange={handleSelectAllCheckboxChange}
                      checked={
                        selectedRoles.length === allRoles.length &&
                        allRoles.length !== 0
                      }
                    />
                  </div>
                  {/* DROPDOWN */}
                  {/* <div className="dropdown dropdown_studies">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    onClick={() => handleStudiesDropdown(!studiesDropdown)}
                  >
                    {studyFilter ? studyFilter : "Copy to"}
                  </button>
                  <div
                    className={`dropdown-menu ${
                      studiesDropdown ? "show" : "hide"
                    }`}
                  >
                    <input
                      type="text"
                      // placeholder="Search Tags" x
                      value={studyFilter}
                      onChange={(e) => handleStudyFilter(e.target.value)}
                    />
                  </div>
                </div> */}
                  {/* SEARCH */}
                  <div className="search_container">
                    <Search
                      onSearchChange={setRoleSearch}
                      filtersCleared={!filterApplied}
                    />
                  </div>
                </div>

                <div className="d-flex align-center icons_container">
                  <div className="icon_add" onClick={handleCreateRoleView}>
                    <IconAdd />
                  </div>
                </div>
              </div>
              {/* Map roles singles based on roleData */}
              <div className="all_roles_container">
                {allRoles.map((role, index) => {
                  if (
                    role.name.toLowerCase().includes(roleSearch.toLowerCase())
                  ) {
                    return (
                      <RoleSingle
                        key={`role_${index}`}
                        isSelected={selectedRole?.id === role.id}
                        roleID={role.id}
                        handleSelectSingleRole={handleSelectSingleRole}
                        roleSelection={role.name}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedRoles={selectedRoles}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                {allRoles.filter((role) =>
                  role.name.toLowerCase().includes(roleSearch.toLowerCase())
                ).length === 0 && (
                  <div className="text-center">No results found</div>
                )}
              </div>
            </div>

            {/* RIGHT SIDE */}
            <div className="roles_previews_container col-9">
              {!selectedRole ? (
                <p>Please select a role</p>
              ) : (
                <>
                  <RolePermissions selectedRole={selectedRole} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RolesContainer;
