import React, { useEffect, useState } from "react";
import { IconLocationPin } from "../../assets/images/icons/icons";
import "./rangeSlider.scss";

interface RangeSliderProps {
  min: number;
  max: number;
  value: { min: number; max: number };
  step: number;
  onChange: (value: { min: number; max: number }) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  value,
  step,
  onChange
}) => {
  const minValue = 0;
  const [maxValue, setMaxValue] = useState(value ? value.max : max);

  useEffect(() => {
    if (value) {
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value, minValue);
    if (!value) setMaxValue(newMaxVal);
    onChange({ min: minValue, max: newMaxVal });
  };

  const minPos = 0;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <div className="wrapper custom_slider">
      <div className="input-wrapper">
        <input
          className="input"
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
        />
      </div>

      <div className="control-wrapper">
        <div className="rail">
          <div
            className="inner-rail"
            style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
          />
        </div>
        <div className="control" style={{ left: `${maxPos}%` }}>
          <label className="control_label">
            <IconLocationPin className="icon" />{" "}
            <span
              className="percent_text"
              style={
                {
                  // left is 10% if maxPos is less than 10, if less than 100% then 15% else 0%
                  // left: `${maxPos < 10 ? 30 : 15}%`,
                  // left: `${maxPos < 10 ? 30 : maxPos < 100 ? 15 : 0}%`,
                }
              }
            >
              {maxValue}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
