import {
  Channel,
  ChannelMessages,
  ChannelParticipants,
  NewMessageResponse,
  SocketAction
} from "../types";

export interface SocketState {
  connected: boolean;
  authenticated: boolean;
  allChannels: Channel[] | string;
  channelParticipants: ChannelParticipants[] | string;
  allMessages: ChannelMessages[] | string;
  newMessage: NewMessageResponse | string;
  timestamp: string;
  // Add other state variables as needed
}

function socketReducer(state: SocketState, action: SocketAction): SocketState {
  const timestamp = new Date().toLocaleTimeString();
  switch (action.type) {
    case "SET_CONNECTED":
      return { ...state, connected: action.payload };
    case "SET_AUTHENTICATED":
      return { ...state, authenticated: action.payload };
    case "SET_ALL_CHANNELS":
      return { ...state, allChannels: action.payload };
    case "SET_CHANNEL_PARTICIPANTS":
      return { ...state, channelParticipants: action.payload };
    case "SET_ALL_MESSAGES":
      return { ...state, allMessages: action.payload };
    case "SET_NEW_MESSAGE":
      return { ...state, newMessage: action.payload };
    // Handle other actions
    // case "disconnect":
    //   return { ...state, authenticated: false, allChannels: [], allMessages: [], newMessage: null };
    //Make -err case for each action
    default:
      return state;
  }
}

export default socketReducer;
