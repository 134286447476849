// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_container {
  position: relative;
  display: inline-block;
}

.tooltip_texts {
  visibility: hidden;
  width: 120px;
  background-color: rgb(26, 35, 69);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip_container:hover .tooltip_texts {
  visibility: visible;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/StudySurveyTabSingle/Chat/Conversation/chatTabs.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,iCAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA,EAAA,wCAAA;EACA,SAAA;EACA,kBAAA,EAAA,uBAAA;EACA,UAAA;EACA,wBAAA;AACF;;AAEA;EACE,mBAAA;EACA,UAAA;AACF","sourcesContent":[".tooltip_container {\n  position: relative;\n  display: inline-block;\n}\n\n.tooltip_texts {\n  visibility: hidden;\n  width: 120px;\n  background-color: rgb(26, 35, 69);\n  color: #fff;\n  text-align: center;\n  border-radius: 6px;\n  padding: 5px 0;\n  position: absolute;\n  z-index: 1;\n  bottom: 125%; /* Position the tooltip above the icon */\n  left: 50%;\n  margin-left: -60px; /* Center the tooltip */\n  opacity: 0;\n  transition: opacity 0.3s;\n}\n\n.tooltip_container:hover .tooltip_texts {\n  visibility: visible;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
