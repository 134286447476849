// SocketController.ts
import { io, Socket } from "socket.io-client";
import Swal from "sweetalert2";
import {
  Channel,
  ChannelMessages,
  ChannelParticipants,
  MessageData,
  NewMessageResponse,
  SocketAction
} from "../types";
//Socket Controller Class
class SocketController {
  private socket: Socket | undefined;
  private readonly url: string = process.env.REACT_APP_SOCKET_URL || "";
  public socketStatus: "connected" | "connecting" | "disconnected" | "failed" =
    "disconnected";
  private readonly MAX_RECONNECT_ATTEMPTS = 4;
  private readonly RECONNECT_INTERVAL = 1000; // 1 second
  public connectionAttempts: number = 0;
  public clientID: string = "";
  public studyID: string = "";
  public initialLoadCompleted: boolean = false;

  constructor(private dispatch: React.Dispatch<SocketAction>) {
    if (!this.url) {
      Swal.fire({
        title: "Error",
        text: "Socket URL not provided.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return; // Stop further execution if URL is not provided.
    }

    this.socket = io(this.url, {
      reconnection: true,
      reconnectionAttempts: this.MAX_RECONNECT_ATTEMPTS,
      reconnectionDelay: this.RECONNECT_INTERVAL,
      transports: ["websocket", "polling"]
    });

    this.initializeListeners();
  }

  private async initializeListeners() {
    if (!this.socket) return;

    this.socket.on("connect", () => {
      this.socketStatus = "connected";
      this.connectionAuthenticated(); // Authenticate upon connection
    });

    this.socket.on("message", (data) => {
      //console.log("Incoming message:", thi?.id);
      this.handleIncomingMessage(data);
    });

    //IF we cant connect to the socket
    this.socket.on("connect_error", () => {
      this.socketStatus = "failed";
      this.connectionAttempts += 1;

      if (this.connectionAttempts === this.MAX_RECONNECT_ATTEMPTS) {
        if (!this.socket) return;
        console.log(
          "Maximum reconnection attempts reached. Unable to connect to the server."
        );
        // Directly show the Swal alert here
        Swal.fire({
          title: "Error",
          text: "Cannot connect to the server at this time. Please try again later.",
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
        this.socket.disconnect(); // Manually disconnect the socket
      }
    });
  }

  /*-------------------------------------------------------------------------------------------------*/
  // Additional method to automatically authenticate upon successful connection
  private connectionAuthenticated() {
    const refreshToken = localStorage.getItem("refresh-token");
    if (refreshToken) {
      //console.log("Socket status:4", this.socketStatus);
      this.authenticateConnection(refreshToken);
    } else {
      console.error("No refresh token available for authentication.");
    }
  }
  // Method to authenticate the socket connection
  public authenticateConnection(refreshToken: string) {
    if (!this.socket) return;
    this.socket.emit("message", { command: "auth", data: { refreshToken } });
  }

  // Simplifies message handling by delegating to specific methods based on command
  private async handleIncomingMessage(data: any) {
    switch (data.command) {
      case "success":
        console.log("Authenticated successfully.");
        this.handleAuthenticationSuccess();
        break;
      case "all-channels":
        let valid = await this.isValidChannelsData(data.data.channels);
        if (valid === true) {
          this.updateChannels(data.data.channels);
        } else {
          this.updateChannels("Failed to validate channels" + Date.now());
          console.log("validation failed");
        }
        break;
      case "channel-participants":
        //This can come back as an array of participants or it can come back as a empty array if there are no participants
        if (data?.data?.participants.length === 0) {
          this.updateParticipants([]);
        } else {
          let validChannelParticipants = await this.isValidChannelParticipant(
            data.data.participants
          );
          if (validChannelParticipants === true) {
            this.updateParticipants(data.data.participants);
          } else {
            this.updateParticipants(
              "Failed to validate channel participants" + Date.now()
            );
            console.log("Channel participants validation failed");
          }
        }
        break;
      case "all-messages":
        //This can come back as a array of messages or it can come back as a empty array if there are no messages
        if (data?.data?.allMessages.length === 0) {
          this.updateMessages([]);
        } else {
          let validChannelMessages = await this.isValidChannelMessages(
            data.data.allMessages
          );
          if (validChannelMessages === true) {
            this.updateMessages(data.data.allMessages);
          } else {
            this.updateMessages(
              "Failed to validate channel messages" + Date.now()
            );
            console.log("Channel messages validation failed");
          }
        }
        this.initialLoadCompleted = true;
        break;
      case "new-message":
        //No there is a strutter the data just back like this , we should make it more readable
        if (data?.data?.message && data?.data?.message?.message) {
          console.log("Adding to new messages");
          let validNewMessage = await this.isValidNewMessage(data.data);
          if (validNewMessage === true) {
            this.updateNewMessages(data.data);
          }
        } else {
          //We should get all messages again
          console.log("Getting all messages");
          this.updateNewMessages("Refresh all messages" + Date.now());
        }
        break;
      case "update-last-opened":
        console.log("Last opened updated:", data);
        break;
      //Error handling all the case have a -err after command eg new-message-err so we want a case to look for -err
      case `${data.command}-err`:
        Swal.fire({
          title: "Error",
          text: data?.data,
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
        break;
      default:
        console.log("Unknown command:", data.command);
        Swal.fire({
          title: "Error",
          text: "Unknown command received from the server.",
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
    }
  }

  private handleAuthenticationSuccess() {
    this.dispatch({ type: "SET_AUTHENTICATED", payload: true });
  }

  private updateChannels(channels: Channel[] | string) {
    this.dispatch({ type: "SET_ALL_CHANNELS", payload: channels });
  }

  private updateParticipants(participants: ChannelParticipants[] | string) {
    this.dispatch({ type: "SET_CHANNEL_PARTICIPANTS", payload: participants });
  }

  private updateMessages(messages: ChannelMessages[] | string) {
    this.dispatch({ type: "SET_ALL_MESSAGES", payload: messages });
  }

  private updateNewMessages(newMessage: NewMessageResponse | string) {
    this.dispatch({ type: "SET_NEW_MESSAGE", payload: newMessage });
  }

  // Method to send any command with required data
  public sendCommand(command: string, data: any) {
    let authToken = localStorage.getItem("auth-token");
    let refreshToken = localStorage.getItem("refresh-token");

    data = { ...data, authToken, refreshToken };

    if (!this.socket) return;
    this.socket.emit("message", { command, data });
  }

  public getAllChannels(
    clientID: string,
    studyID: string,
    participantID: null
  ) {
    if (this.socketStatus === "connected") {
      this.sendCommand("all-channels", { clientID, studyID, participantID });
    }
  }

  public getChannelParticipants(
    clientID: string,
    studyID: string,
    channelID: string
  ) {
    if (this.socketStatus === "connected") {
      console.log(
        "Sending channel participants command",
        clientID,
        studyID,
        channelID
      );
      this.sendCommand("channel-participants", {
        clientID,
        studyID,
        channelID
      });
    }
  }

  public getParticipantsMessages(
    clientID: string,
    studyID: string,
    channelID: string,
    participantID: string
  ) {
    if (this.socketStatus === "connected") {
      console.log("Sending all participants messages command");
      this.sendCommand("all-messages", {
        clientID,
        studyID,
        channelID,
        participantID
      });
    }
  }

  //sending a new message
  public sendNewMessage(messageData: MessageData) {
    if (!this.socket) {
      console.log("No socket found");
      return;
    }

    if (this.socketStatus === "connected") {
      this.socket.emit("message", {
        command: "create-message",
        data: messageData
      });
    }
  }

  public uploadFile({
    authToken,
    refreshToken,
    clientID,
    studyID,
    fileName,
    fileType,
    fileBlob,
    channelID,
    participantID,
    message,
    replyID,
    currentIndex
  }: {
    authToken: string;
    refreshToken: string;
    clientID: string;
    studyID: string;
    fileName: string;
    fileType: string;
    fileBlob: Blob;
    channelID: string;
    participantID: string;
    message: string | null;
    replyID: string | null;
    currentIndex: number;
  }) {
    if (!this.socket) {
      console.log("No socket found");
      return;
    }

    if (this.socketStatus === "connected") {
      this.socket.emit(
        "message",
        {
          command: "upload-key",
          data: {
            authToken,
            refreshToken,
            clientID,
            studyID,
            fileName,
            fileType
          }
        },
        async (response: any) => {
          console.log("Response from upload key", response);
          //Cool so this will return the key and the url to upload the file to we need to make a put request and depending on those results we can send the message
          let validUploadKey = await this.isValidUploadKey(response[0]);
          if (validUploadKey === true) {
            console.log("Response from upload key", response);
            const uploadingToS3 = await fetch(response[0].url, {
              method: "PUT",
              body: fileBlob
            });
            console.log("Uploading to S3", uploadingToS3);
            let newUploadMessage = {
              currentIndex: currentIndex,
              message: {
                clientID: clientID,
                studyID: studyID,
                channelID: channelID,
                participantID: participantID,
                message: null,
                attachmentKey: response[0].key,
                replyMessageID: replyID,
                type: fileType as "image" | "video" | "audio" | "file",
                authToken: authToken,
                refreshToken: refreshToken
              }
            };
            if (uploadingToS3.status === 200) {
              this.sendNewMessage(newUploadMessage);
            } else {
              console.log("Failed to upload to S3");
            }
          } else {
            console.log("Upload key validation failed");
          }
        }
      );
    }
  }

  public deleteMessage(
    clientID: string,
    studyID: string,
    channelID: string,
    participantID: string,
    messageID: string
  ) {
    if (this.socketStatus === "connected") {
      this.sendCommand("del-message", {
        clientID,
        studyID,
        channelID,
        participantID,
        messageID
      });
    }
  }

  public editMessage(
    clientID: string,
    studyID: string,
    channelID: string,
    participantID: string,
    messageID: string,
    message: string
  ) {
    if (this.socketStatus === "connected") {
      this.sendCommand("edit-message", {
        clientID,
        studyID,
        channelID,
        participantID,
        messageID,
        message
      });
    }
  }

  public pinMessage(
    clientID: string,
    studyID: string,
    channelID: string,
    participantID: string,
    messageID: string
  ) {
    if (this.socketStatus === "connected") {
      this.sendCommand("pin-message", {
        clientID,
        studyID,
        channelID,
        participantID,
        messageID
      });
    }
  }

  public updateUnreadMessages(
    clientID: string,
    studyID: string,
    channelID: string,
    participantID: string,
    lastOpened: string
  ) {
    if (this.socketStatus === "connected") {
      this.sendCommand("update-last-opened", {
        clientID,
        studyID,
        channelID,
        participantID,
        lastOpened
      });
    }
  }

  //Validation for data
  private async isValidChannelsData(channelData: any): Promise<boolean> {
    let errors = [];
    if (Array.isArray(channelData)) {
      for (let i = 0; i < channelData.length; i++) {
        if (typeof channelData[i].id !== "string") {
          errors.push("Channel ID is not a string");
        }
        if (typeof channelData[i].channelName !== "string") {
          errors.push("Channel Name is not a string");
        }
        if (typeof channelData[i].channelType !== "string") {
          errors.push("Channel Type is not a string");
        }
        if (typeof channelData[i].thumbnailUrl !== "string") {
          errors.push("Channel Thumbnail is not a string");
        }
        if (typeof channelData[i].unread !== "number") {
          errors.push("Channel Unread is not a number");
        }
      }
    } else {
      errors.push("Data is not an array");
    }

    if (errors.length > 0) {
      console.error("Validation errors:", errors);
      return false;
    }
    return true;
  }

  private async isValidChannelParticipant(
    participantData: any
  ): Promise<boolean> {
    let errors = [];
    if (Array.isArray(participantData)) {
      for (let i = 0; i < participantData.length; i++) {
        if (typeof participantData[i].id !== "string") {
          errors.push("Participant ID is not a string");
        }
        if (typeof participantData[i].sender_name !== "string") {
          errors.push("Participant sender name is not a string");
        }
        if (typeof participantData[i].participant_email !== "string") {
          errors.push("Participant email is not a string");
        }
        if (typeof participantData[i].participant_country_iso !== "string") {
          errors.push("Participant country code is not a string");
        }
        if (typeof participantData[i].participant_lang_iso !== "string") {
          errors.push("Participant language code is not a string");
        }
        //Wait for the backend to fix the data types
        // if (typeof participantData[i].last_opened !== "string"){
        //   errors.push("Participant last open is not a string");
        // }
        // if (typeof participantData[i].last_seen !== "string"){
        //   errors.push("Participant last seen is not a string");
        // }
        // if (typeof participantData[i].unread!== "number"){
        //   errors.push("Participant unread is not a number");
        // }
        if (typeof participantData[i].flagged !== "boolean") {
          errors.push("Participant flagged is not a boolean");
        }
        if (Array.isArray(participantData[i].participant_tags) === false) {
          errors.push("Participant tags is not an array");
        }
      }
    } else {
      errors.push("Data is not an array");
    }
    if (errors.length > 0) {
      console.error("Validation errors:", errors);
      return false;
    }
    return true;
  }

  private async isValidChannelMessages(messagesData: any): Promise<boolean> {
    let errors = [];
    if (Array.isArray(messagesData)) {
      for (let i = 0; i < messagesData.length; i++) {
        if (typeof messagesData[i].id !== "string") {
          errors.push("Message ID is not a string");
        }
        if (typeof messagesData[i].message !== "string") {
          errors.push("Message content is not a string");
        }
        // if (typeof messagesData[i].attachment_key !== "string"){
        //   errors.push("Attachment key is not a string");
        // }
        // if (typeof messagesData[i].parent_id !== "string"){
        //   errors.push("Parent ID is not a string");
        // }
        if (typeof messagesData[i].participantID !== "string") {
          errors.push("Participant ID is not a string");
        }
        if (typeof messagesData[i].sender !== "string") {
          errors.push("Researcher ID is not a string");
        }
        // if (typeof messagesData[i].senderName !== "string"){
        //   errors.push("Sender name is not a string");
        // }
        if (typeof messagesData[i].createdAt !== "number") {
          errors.push("Created at is not a number");
        }
        if (typeof messagesData[i].seen !== "string") {
          errors.push("Seen is not a string");
        }
        if (typeof messagesData[i].flagged !== "boolean") {
          errors.push("Flagged is not a boolean");
        }
        if (typeof messagesData[i].pinned !== "boolean") {
          errors.push("Pinned is not a boolean");
        }
        if (typeof messagesData[i].type !== "string") {
          errors.push("Type ID is not a string");
        }
        if (typeof messagesData[i].edited !== "boolean") {
          errors.push("Edited is not a boolean");
        }
        if (typeof messagesData[i].deleted !== "boolean") {
          errors.push("Deleted at is not a boolean");
        }
      }
    } else {
      errors.push("Data is not an array");
    }

    if (errors.length > 0) {
      console.error("Validation errors:", errors);
      return false;
    }

    return true;
  }

  private async isValidNewMessage(newMessageData: any): Promise<boolean> {
    let errors = [];

    if (typeof newMessageData.channelID !== "string") {
      errors.push("Channel ID is not a string");
    }

    let validMessage = await this.isValidChannelMessages([
      newMessageData.message
    ]);
    if (validMessage === false) {
      errors.push("Message is not valid");
    }

    if (typeof newMessageData.newMessageIndex !== "string") {
      errors.push("New message index is not a string");
    }

    if (errors.length > 0) {
      console.error("Validation errors:", errors);
      return false;
    }

    return true;
  }

  private async isValidUploadKey(uploadKeyData: any): Promise<boolean> {
    let errors = [];

    if (typeof uploadKeyData.key !== "string") {
      errors.push("Key is not a string");
    }
    if (typeof uploadKeyData.url !== "string") {
      errors.push("URL is not a string");
    }

    if (errors.length > 0) {
      console.error("Validation errors:", errors);
      return false;
    }

    return true;
  }

  //If it
  disconnect() {
    if (!this.socket) return;
    console.log("Disconnecting socket", this.socket);
    this.socket.disconnect();
    //we want to disconnect the socket and all the instance of it
  }
}

export default SocketController;
