// Form page 1 to add client to the database
/* Fields {
  Client Name (required)
  Client ID (required) - auto generated from name if blank. All lowercase, no spaces, no special characters
  Client Description (optional)
  Client Logo (required) -image
}
*/

import React, { useEffect, useState } from "react";
import AddClientWizardFooter from "./addClientWizardFooter.component";

interface addClientWizardClientInformationProps {
  validateWizard: () => void;
  currentPage: number;
  totalPages: number;
  handleWizardBack: () => void;
}

const AddClientWizardClientInformation: React.FC<
  addClientWizardClientInformationProps
> = ({ validateWizard, currentPage, totalPages, handleWizardBack }) => {
  const [clientName, setClientName] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientDescription, setClientDescription] = useState("");
  const [clientLogo, setClientLogo] = useState<string | null>(null);

  // regexAll no special characters, allows quotes and apostrophes and commas and new lines
  // regexClientName no special characters or numbers, allows spaces
  // regexClientID no special characters or numbers or spaces, allows dashes

  const regexAll = /[^a-zA-Z0-9" ".,\n']/g;
  const regexClientName = /[^a-zA-Z" "']/g;

  useEffect(() => {
    const regexClientID = /[^a-zA-Z-]/g;
    // removes special characters from clientID
    let formattedClientID = clientID.replace(/ /g, "-");
    setClientID(formattedClientID.replace(regexClientID, "").toLowerCase());
  }, [clientID]);

  function ValidateForm() {
    // This function will validate the form
    if (clientID === "") {
      setClientID(clientName.trim());
    }
  }

  const handleInputChange = (inputName: string, e: React.ChangeEvent<any>) => {
    if (inputName === "clientName") {
      let formattedClientName = e.target.value.replace(regexClientName, "");
      // double spaces to single space
      formattedClientName = formattedClientName.replace(/  +/g, " ");
      setClientName(formattedClientName);
    } else if (inputName === "clientID") {
      let formattedClientID = e.target.value;
      // double dashes to single dash
      formattedClientID = formattedClientID.replace(/--+/g, "-");
      // remove dashes from beginning and end
      formattedClientID = formattedClientID.replace(/^-/, "");
      setClientID(formattedClientID);
    } else if (inputName === "clientDescription") {
      const formattedClientDescription = e.target.value.replace(regexAll, "");
      setClientDescription(formattedClientDescription);
    } else if (inputName === "clientLogo") {
      // image upload
      const file = e.target.files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result as string;
          setClientLogo(imageUrl);
        };
        reader.readAsDataURL(file);
      }
    } else {
      // console.log("Error: inputName not found");
    }
  };

  const handleSubmit = () => {
    // This function will submit the form
    // console.log("Form Submitted");
    validateWizard();
  };
  const handleBack = () => {
    handleWizardBack();
  };

  return (
    <div className="card card-default add_client_form_page p-3">
      <h1>Client Information</h1>
      <div className="add_client_form_page_inputs">
        <div className="mb-3">
          <label htmlFor="clientName" className="form-label mb-1 mx-1">
            Client Name
          </label>
          <input
            type="text"
            name="clientName"
            id="clientName"
            className="form-control"
            value={clientName}
            onChange={(e) => handleInputChange("clientName", e)}
            onBlur={(e) => {
              setClientName(clientName.trim());
              ValidateForm();
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="clientID" className="form-label mb-1 mx-1">
            Client ID
          </label>
          <input
            type="text"
            name="clientID"
            id="clientID"
            className="form-control"
            value={clientID}
            onChange={(e) => handleInputChange("clientID", e)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="clientDescription" className="form-label mb-1 mx-1">
            Client Description
          </label>
          <textarea
            name="clientDescription"
            id="clientDescription"
            className="form-control"
            value={clientDescription}
            onChange={(e) => handleInputChange("clientDescription", e)}
          ></textarea>
        </div>
        {/* Image preview */}
        <div className="image_preview">
          {clientLogo && <img src={clientLogo} alt="" />}
        </div>
        <div className="mb-3">
          {/* image upload */}
          <label htmlFor="clientLogo" className="form-label mb-1 mx-1">
            Client Logo
          </label>
          <input
            type="file"
            name="clientLogo"
            id="clientLogo"
            className="form-control"
            // value={clientLogo || ""}
            onChange={(e) => handleInputChange("clientLogo", e)}
          />
        </div>

        {/* submit */}
        <AddClientWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddClientWizardClientInformation;

// const handleUpload = (
//   type: string,
//   e: React.ChangeEvent<HTMLInputElement>
// ) => {
//   if (e.target.files !== null) {
//     const files = Array.from(e.target.files);
//     const imagePromises: Promise<ImageType>[] = files.map((file, index) => {
//       return new Promise((resolve, reject) => {
//         const tempImage = new window.Image();
//         tempImage.src = URL.createObjectURL(file);

//         tempImage.onload = () => {
//           const newImage: ImageType = {
//             id: index.toString(),
//             title: "",
//             description: "",
//             keywords: [],
//             ocr: "",
//             width: tempImage.width,
//             height: tempImage.height,
//             source: URL.createObjectURL(file),
//           };
//           resolve(newImage);
//         };

//         tempImage.onerror = () => {
//           reject(new Error(`Failed to load image at index ${index}`));
//         };
//       });
//     });

//     Promise.all(imagePromises)
//       .then((loadedImages) => {
//         setImages(loadedImages);
//         setUploadType(type);
//         if (type === "Single") {
//           setCurrentImage(0);
//           setWindowToDisplay("edit");
//         } else if (type === "Multiple") {
//           setCurrentImage(null);
//           setWindowToDisplay("multiple");
//         }
//       })
//       .catch((error) => {
//         console.error("Failed to upload all images:", error);
//         // Display an error message to the user
//         // e.g., setErrorMessage("Failed to upload all images. Please try again.");
//       });
//   }
// };
