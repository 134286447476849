// languagesFilter.tsx

import React, { useEffect, useRef, useState } from "react";
import { Language } from "../../../types";
import { useOutsideClick } from "../../../utilities/utils";

interface LanguagesFilterProps {
  jsonlanguagesData: Language[];
  onLanguagesFilterChange: (languages: string[]) => void;
  filtersCleared: boolean;
  location: string;
  defaultSelectedLanguages?: string[];
}

const LanguagesFilter: React.FC<LanguagesFilterProps> = ({
  jsonlanguagesData,
  onLanguagesFilterChange,
  filtersCleared,
  location,
  defaultSelectedLanguages
}) => {
  const [languagesFilter, setlanguagesFilter] = useState<string>("");
  const [languagesDropdown, setlanguagesDropdown] = useState(false);
  const [participantslanguagesFilter, setParticipantslanguagesFilter] =
    useState<Record<string, boolean>>({});

  const handlelanguagesDropdown = (show: boolean) => {
    // show/hide dropdown
    setlanguagesDropdown(show);
  };

  const handlelanguagesSearchFilter = (search: string) => {
    // set search value
    setlanguagesFilter(search);
  };

  const handlelanguagesFilter = (language: string, checked: boolean) => {
    // set languages filter
    setParticipantslanguagesFilter((prevlanguages) => ({
      ...prevlanguages,
      [language]: checked // set checked status for this language
    }));
  };

  const [lastSentLanguages, setLastSentLanguages] = useState<string[]>([]);

  useEffect(() => {
    // if default languages, set them as checked
    if (defaultSelectedLanguages) {
      const defaultLanguagesFilter: Record<string, boolean> = {};
      defaultSelectedLanguages.forEach((language) => {
        defaultLanguagesFilter[language] = true;
      });
      setParticipantslanguagesFilter(defaultLanguagesFilter);
    }
  }, [defaultSelectedLanguages]);
  useEffect(() => {
    // send languages filter to parent component
    const languagesToChange = Object.keys(participantslanguagesFilter).filter(
      (language) => participantslanguagesFilter[language]
    );

    if (
      JSON.stringify(languagesToChange.sort()) !==
      JSON.stringify(lastSentLanguages.sort())
    ) {
      onLanguagesFilterChange(languagesToChange);
      setLastSentLanguages(languagesToChange);
    }
  }, [participantslanguagesFilter, onLanguagesFilterChange]);

  useEffect(() => {
    // clear languages filter when filters are cleared
    if (filtersCleared) {
      setlanguagesFilter("");
      setParticipantslanguagesFilter({});
      handlelanguagesSearchFilter("");
      // handlelanguagesDropdown(false);
    }
  }, [filtersCleared]);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    // Action to take on outside click
    handlelanguagesDropdown(false);
  });

  return (
    <div className="dropdown dropdown_languages" ref={ref}>
      <button
        className="btn btn-primary dropdown-toggle"
        onClick={() => handlelanguagesDropdown(!languagesDropdown)}
      >
        Languages
      </button>
      <div className={`dropdown-menu ${languagesDropdown ? "show" : "hide"}`}>
        {jsonlanguagesData && jsonlanguagesData.length === 0 ? (
          <div className="dropdown_menu_item">
            <span>No languages</span>
          </div>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search languages"
              value={languagesFilter}
              onChange={(e) => handlelanguagesSearchFilter(e.target.value)}
            />
            <div className="dropdown_menu_items">
              {
                /* filter by languagesfilter set by input */
                /* loop through all languages */
                jsonlanguagesData.map((language: Language, index: number) => {
                  if (
                    language.name
                      .toLowerCase()
                      .includes(languagesFilter.toLowerCase())
                  ) {
                    return (
                      <div key={index} className="dropdown_menu_item">
                        <label
                          htmlFor={`${location}_language_${language.name}_${language.iso_code}`}
                          className="form_control"
                        >
                          <input
                            type="checkbox"
                            id={`${location}_language_${language.name}_${language.iso_code}`}
                            name={language.name}
                            value={language.iso_code}
                            checked={
                              participantslanguagesFilter[language.iso_code] ||
                              false
                            } // use checked property here
                            onChange={(e) =>
                              handlelanguagesFilter(
                                e.target.value,
                                e.target.checked
                              )
                            }
                          />
                          {language.iso_code} - {language.name}
                        </label>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LanguagesFilter;
