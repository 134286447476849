import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  IconDelete,
  IconEdit,
  IconSend
} from "../../../assets/images/icons/icons";
import { usePermissions } from "../../../contexts/UserContext";
import { getResources } from "../../../models/resources.model";
import { Language, ResourceData } from "../../../types";
import { getSystemConfigLanguages } from "../../../utilities/config.util";
import FiltersContainer from "../../Tables/Filters/filtersContainer.component";
import PaginationNavigation from "../../Tables/PaginationNavigation/paginationNavigation";
import TableRowsSelector from "../../Tables/TableRowsSelector/tableRowsSelector";
import DeleteResourceModal from "./ResourceModal/deleteResourceModal"; // Import the DeleteResourceModal
import ResourceManagerModal from "./ResourceModal/manageResourceModal";
import ShareResourceModal from "./ResourceModal/shareResourceModal";
import "./resources.scss";

export type ResourcesProps = {
  studyClicked: string;
};

const Resources: React.FC<ResourcesProps> = ({ studyClicked }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingResources, setLoadingResources] = useState(true);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [filteredResources, setFilteredResources] = useState<ResourceData[]>(
    []
  );
  const [originalResources, setOriginalResources] = useState<ResourceData[]>(
    []
  );
  const [currentResources, setCurrentResources] = useState<ResourceData[]>([]);
  const [resourcesPerPage, setResourcesPerPage] = useState(10);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for Delete Modal
  const [actionType, setActionType] = useState<
    "edit" | "create" | "delete" | ""
  >("");
  const [editResource, setEditResource] = useState<ResourceData | null>(null);
  const [deleteResource, setDeleteResource] = useState<ResourceData | null>(
    null
  ); // State for resource to delete
  const [shareResource, setShareResource] = useState<ResourceData | null>(null);

  // Languages
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState(true);
  const [uniqueResourceLanguages, setUniqueResourceLanguages] = useState<
    Language[]
  >([]);

  const { clientID, studyID } = useParams<string>();
  const { hasPermission } = usePermissions();

  const handleChangeRows = (rows: number) => {
    setResourcesPerPage(rows);
  };

  // Checkbox change
  const handleSelectAllCheckboxChange = () => {
    const allResourcesSelected =
      selectedResources.length === filteredResources.length;
    if (allResourcesSelected) {
      setSelectedResources([]);
    } else {
      const allResourceIDs = filteredResources.map((resource) => resource.id);
      setSelectedResources(allResourceIDs);
    }
  };

  const handleCheckboxChange = (id: string) => {
    if (selectedResources.includes(id)) {
      setSelectedResources(selectedResources.filter((item) => item !== id));
    } else {
      const resource = filteredResources.find((resource) => resource.id === id);
      if (resource) {
        setSelectedResources([...selectedResources, id]);
      }
    }
  };

  const handleCloseImportModal = () => {
    setShowResourceModal(false);
    setEditResource(null);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteResource(null);
  };

  const handleCloseShareModal = () => {
    setShowResourceModal(false);
    setShareResource(null);
  };

  // Edit / Create / Delete Resource
  const handleShowModal = (
    type: "share" | "edit" | "create" | "delete",
    resourceData?: ResourceData
  ) => {
    if (type === "edit") {
      setEditResource(resourceData || null);
      setActionType(type);
      setShowResourceModal(true);
    } else if (type === "delete") {
      setDeleteResource(resourceData || null);
      setShowDeleteModal(true);
    } else if (type === "share") {
      setShareResource(resourceData || null);
      setShowShareModal(true);
    } else {
      setEditResource(null);
      setActionType(type);
      setShowResourceModal(true);
    }
  };

  // Fetch Resources
  useEffect(() => {
    // ==================== RESOURCES ====================
    if (clientID && studyID) {
      const fetchAllResources = async () => {
        try {
          const jsonData: ResourceData[] | string = await getResources(
            clientID,
            studyID
          );
          if (!jsonData) {
            setLoadingErrorText((prevErrors) => [
              ...prevErrors,
              "8425c691234g44zb9940a24447d89h0"
            ]);
          } else {
            // Check that it is not a string
            try {
              if (typeof jsonData === "string") {
                // error handled in model
                return;
              }
              // Is an object
              else {
                console.log(jsonData);
                const resourceData: ResourceData[] = jsonData;

                setOriginalResources(resourceData);
                setFilteredResources(resourceData);
              }
            } catch {
              console.error("Error setting resources");
              throw new Error("Error setting resources");
            }
          }
        } catch (e) {
          console.error("Error fetching resources", e);
        }
      };

      const promises = [fetchAllResources()];

      Promise.all(promises)
        .then(() => {
          setLoadingResources(false);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingResources(false);
          setLoadingData(false);
        });
    }
  }, [clientID, studyID]);

  // Fetch languages
  useEffect(() => {
    // get languages from local storage using getConfigLanguages
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425cj7g59p02642b9940a2cda9e5dad7"
          ]);
          Swal.fire({
            icon: "error",
            title: "Failed to fetch languages",
            html: `Please contact support <br>${loadingErrorText.join("<br>")}`,
            confirmButtonColor: "#3085d6"
          });
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425cj7g59p02642b9940a2cda9e5dad7"
        ]);
        Swal.fire({
          icon: "error",
          title: "Failed to fetch languages",
          html: `Please contact support <br>${loadingErrorText.join("<br>")}`,
          confirmButtonColor: "#3085d6"
        });
      }
    };

    const promises = [fetchAllConfigLanguages()];

    Promise.all(promises)
      .then(() => {
        setLoadingLanguages(false);
      })
      .catch(() => {
        setLoadingLanguages(false);
      });
  }, [loadingErrorText]);

  // Get all unique resource languages for filtering
  useEffect(() => {
    const rLanguages = [
      ...new Set(
        originalResources
          .flatMap((r) => r.lang_iso)
          .filter((lang): lang is string => Boolean(lang))
      )
    ].sort();
    setUniqueResourceLanguages(
      rLanguages.map((lang) => {
        const rLanguage = languages.find((l: Language) => l.iso_code === lang);
        if (rLanguage) {
          return rLanguage;
        }
        return { iso_code: lang, name: lang };
      })
    );
  }, [originalResources, languages]);

  // What is displayed
  useEffect(() => {
    setCurrentResources(
      filteredResources.slice(
        (currentPage - 1) * resourcesPerPage,
        currentPage * resourcesPerPage
      )
    );
  }, [filteredResources, currentPage, resourcesPerPage]);

  // ====================================== Filters ======================================
  const [resourcesSearch, setResourcesSearch] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>(["active", "inactive"]);
  const [languagesFilter, setLanguagesFilter] = useState<string[]>([]);

  // Update the filtered resource list
  useEffect(() => {
    if (!originalResources) {
      return;
    }
    // Filter the resources
    let preliminaryFiltered = originalResources.filter(
      (resource: ResourceData) => {
        // Apply search filter if resource search is not empty
        if (
          resourcesSearch !== "" &&
          !(
            resource.name
              ?.toLowerCase()
              .includes(resourcesSearch.toLowerCase()) ||
            resource.resource_file_url
              ?.toLowerCase()
              .includes(resourcesSearch.toLowerCase())
          )
        ) {
          return false;
        }

        // Apply languages filter if LanguagesFilter is not empty
        if (
          languagesFilter.length > 0 &&
          resource.lang_iso &&
          !languagesFilter.some((lang) => resource.lang_iso.includes(lang))
        ) {
          return false;
        }

        // Apply status filter if StatusFilter is not empty
        if (
          statusFilter.length > 0 &&
          resource.status &&
          !statusFilter.includes(resource.status)
        ) {
          return false;
        }

        return true;
      }
    );

    const result = preliminaryFiltered;

    setFilteredResources(result);
    setCurrentPage(1);
  }, [
    originalResources,
    resourcesSearch,
    statusFilter,
    languagesFilter,
    resourcesPerPage
  ]);

  // Update filtersApplied state
  useEffect(() => {
    if (
      resourcesSearch !== "" ||
      statusFilter.length > 0 ||
      languagesFilter.length > 0
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [resourcesSearch, statusFilter, languagesFilter]);

  // Clear the filter state
  const handleClearFilters = () => {
    setResourcesSearch("");
    setStatusFilter([]);
    setLanguagesFilter([]);
  };

  return loadingData && loadingResources ? (
    <div className="d-flex justify-content-center custom_spinner_container full_page">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : (
    <div className="resources_container">
      <div className="row resources_top_heading">
        <div className="heading_left col-7">
          <h1 className="">Resources</h1>
        </div>
        {/* Add Resource Button */}
        {(hasPermission("subject") ||
          hasPermission("resources_all") ||
          hasPermission("resources", "write")) && (
          <button
            className={`btn btn-primary add_resources_button `}
            onClick={() => {
              handleShowModal("create");
            }}
          >
            Add Resource
          </button>
        )}
      </div>
      <div className="row">
        <div className="col d-flex resources_per_page">
          <TableRowsSelector
            handleChangeRows={handleChangeRows}
            rowsPerPage={resourcesPerPage}
            tableName="Resources"
            arrayRowSelector={[1, 5, 10, 50]}
          />
        </div>
      </div>
      <div className="row d-flex card-header resources_card_heading justify-content-between">
        <div className="col-12 p-0">
          <div className="resources_filter_container">
            <div className="filter_component">
              <FiltersContainer
                tableType="resources"
                setSearch={setResourcesSearch}
                setStatusFilter={setStatusFilter}
                statuses={statuses}
                setLanguagesFilter={setLanguagesFilter}
                languages={uniqueResourceLanguages}
                setFilterApplied={setFilterApplied}
                filterApplied={filterApplied}
                handleClearFilters={handleClearFilters}
              />
            </div>
          </div>
        </div>
      </div>
      {originalResources.length === 0 || currentResources.length === 0 ? (
        <div className="row d-flex justify-content-center align-items-center py-1">
          No resources found{" "}
          {currentResources.length === 0 &&
          filterApplied &&
          originalResources.length > 0
            ? "Please try adjusting your filters."
            : ""}
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <table className="resource_rows_container table">
              <thead>
                <tr className="resource_single_headings">
                  <th className="resource_header_name">Resource Name</th>
                  <th className="resource_header_date">Languages</th>
                  <th className="resource_header_date">Resource URL</th>
                  <th className="resource_header_date">Thumbnail URL</th>
                  <th className="resource_header_date">Created At</th>
                  <th className="resource_header_date">Updated At</th>
                  <th className="resource_header_date">Status</th>
                  <th className="resource_header_date">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentResources.map((resource) => (
                  <tr
                    key={resource.id}
                    className="align-items-stretch resource_single"
                  >
                    <td className="resource_name">{resource.name}</td>
                    <td className="resource_name">
                      {resource.lang_iso.join(", ")}
                    </td>
                    <td className="resource_name">
                      <a
                        href={`${resource.resource_file_url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {resource.file_name}
                      </a>
                    </td>
                    <td className="resource_name">
                      <a
                        href={`${resource.thumbnail_url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {resource.name} thumbnail
                      </a>
                    </td>
                    <td className="resource_name">
                      {
                        new Date(resource.created_at)
                          .toISOString()
                          .split("T")[0]
                      }
                    </td>
                    <td className="resource_name">
                      {
                        new Date(resource.updated_at)
                          .toISOString()
                          .split("T")[0]
                      }
                    </td>
                    <td className="resource_name">{resource.status}</td>
                    <td className="resource_name text-center">
                      <IconSend
                        className="resource_action_icon"
                        onClick={() => handleShowModal("share", resource)}
                      />
                      &nbsp;&nbsp;
                      <IconEdit
                        className="resource_action_icon"
                        onClick={() => handleShowModal("edit", resource)}
                      />
                      &nbsp;
                      <IconDelete
                        className="resource_action_icon"
                        onClick={() => handleShowModal("delete", resource)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row py-1">
            <div className="col-auto">
              <PaginationNavigation
                currentPage={currentPage}
                totalResults={filteredResources ? filteredResources.length : 0}
                resultsPerPage={resourcesPerPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className="col-auto ms-auto"></div>
          </div>
        </>
      )}

      {showShareModal && shareResource && (
        <ShareResourceModal
          closeModal={handleCloseShareModal}
          shown={showShareModal}
          currentResource={shareResource}
          resourceUrl={shareResource.resource_file_url}
        />
      )}

      {showResourceModal && (
        <ResourceManagerModal
          closeModal={handleCloseImportModal}
          shown={showResourceModal}
          type={actionType}
          currentResource={editResource ? editResource : null}
          languages={languages}
        />
      )}

      {showDeleteModal && deleteResource && (
        <DeleteResourceModal
          closeModal={handleCloseDeleteModal}
          shown={showDeleteModal}
          currentResource={deleteResource}
        />
      )}
    </div>
  );
};

export default Resources;
