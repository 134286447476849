//Page to display the clients in cards
import SingleApp from "../../components/Config/MobileApps/SingleAppConfig/singleApp.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";

interface SingleAppConfigPageProps extends SidebarProps {
  appEditType: "update" | "copy" | "new";
}

const SingleAppConfigPage: React.FC<SingleAppConfigPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  appEditType
}) => {
  return (
    <div className="main_container">
      <div className="col-sm p-3 pt-0 min-vh-100">
        <div className="container-fluid" id="app_config_page">
          <SingleApp appEditType={appEditType} />
        </div>
      </div>
      <SidebarComponent
        page="config"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
    </div>
  );
};

export default SingleAppConfigPage;
