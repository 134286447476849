import React, { useEffect, useState } from "react";
import DisplayParticipants from "../../../components/DisplayParticipants/displayParticipants.component";

type ParticipantsProps = {
  studyClicked: "study" | "survey" | "diary";
  clientID: string;
  studyID: string;
  tableLevelName: string;
};

const Participants: React.FC<ParticipantsProps> = ({
  studyClicked,
  clientID,
  studyID,
  tableLevelName
}) => {
  const [tableType, setTableType] = useState<
    "StudyParticipants" | "SurveyParticipants" | "DiaryParticipants" | "Error"
  >("Error");

  useEffect(() => {
    // console.log("TableType", tableType);
    // console.log("studyClicked", studyClicked);
    switch (studyClicked) {
      case "study":
        // console.log("HIT");
        setTableType("StudyParticipants");
        break;
      case "survey":
        // console.log("HIT");
        setTableType("SurveyParticipants");
        break;
      case "diary":
        // console.log("HIT");
        setTableType("DiaryParticipants");
        break;
      default:
        console.log("HIT");

        setTableType("Error");
    }
  }, [studyClicked]);
  return (
    <>
      {tableType && tableType !== "Error" ? (
        <DisplayParticipants
          tableType={tableType}
          tableDisplayName={tableLevelName}
          clientID={clientID}
          studyID={studyID}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Participants;
