import React, { useEffect, useState } from "react";
import { Country, Language, LeadsParticipant, Timezone } from "../../types";
import { capitalize, convertTime, formatDate } from "../../utilities/utils";

interface ViewLeadModalTableProps {
  participant: LeadsParticipant;
  jsonLanguagesData: Language[];
  jsonCountriesData: Country[];
  jsonTimezonesData: Timezone[];
}

const ViewLeadModalTable: React.FC<ViewLeadModalTableProps> = ({
  participant,
  jsonLanguagesData,
  jsonCountriesData,
  jsonTimezonesData
}) => {
  const [participantParticipationClass, setLeadParticipationClass] =
    useState<string>("");
  const [participantParticipationLabel, setLeadParticipationLabel] =
    useState<number>(0);

  const [participantCheaterClass, setLeadCheaterClass] = useState<string>("");
  const [participantCheaterLabel, setLeadCheaterLabel] = useState<number>(0);

  const [participantTimezone, setLeadTimezone] = useState<Timezone>();
  useEffect(() => {
    // get the timezone name from the jsonTimezonesData
    setLeadTimezone(
      jsonTimezonesData.find(
        (timezone) => timezone.name === participant.timezone
      )
    );
  }, [participant, jsonTimezonesData]);

  useEffect(() => {
    let classValue;
    let labelValue;
    // set the Participant score colors
    // less than 25% = terrible
    // less than 50% = bad
    // less than 75% = warning
    // 75% or more = good
    const participationScoreGood = 75;
    const participationScorewarning = 50;
    const participationScoreBad = 25;

    // score equals edited_rating_score if it exists, otherwise it equals rating_score
    const score = participant.participation_score;
    if (score && score > participationScoreGood) {
      classValue = "score_bar_good";
    } else if (score && score > participationScorewarning) {
      classValue = "score_bar_warning";
    } else if (score && score > participationScoreBad) {
      classValue = "score_bar_bad";
    } else {
      classValue = "score_bar_terrible";
    }

    labelValue = score || 0;

    setLeadParticipationClass(classValue);
    setLeadParticipationLabel(labelValue);
  }, [participant]);

  useEffect(() => {
    let classValue;
    let labelValue;
    // set the score bar colors
    // less than 25% = good
    // less than 50% = warning
    // less than 75% = bad
    // 75% or more = terrible
    const cheaterScoreGood = 25;
    const cheaterScoreWarning = 50;
    const cheaterScoreBad = 75;

    // score equals edited_cheater_score if it exists, otherwise it equals cheater_score
    const score = participant.cheater_score;
    if (score && score < cheaterScoreGood) {
      classValue = "score_bar_good";
    } else if (score && score < cheaterScoreWarning) {
      classValue = "score_bar_warning";
    } else if (score && score < cheaterScoreBad) {
      classValue = "score_bar_bad";
    } else {
      classValue = "score_bar_terrible";
    }

    labelValue = score || 0;
    setLeadCheaterClass(classValue);
    setLeadCheaterLabel(labelValue);
  }, [participant]);

  return (
    <>
      <div className="modal-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">ID</div>
            <div className="col-8">{participant.id.toString()}</div>
          </div>
          <div className="row">
            <div className="col-4">Country</div>
            <div className="col-8">
              {
                // get the country name from the jsonCountriesData
                jsonCountriesData.find(
                  (country) => country.iso_code === participant.country_iso
                )?.name
              }
            </div>
          </div>
          <div className="row">
            <div className="col-4">Language</div>
            <div className="col-8">
              {
                // get the language name from the jsonLanguagesData
                jsonLanguagesData.find(
                  (language) => language.iso_code === participant.language_iso
                )?.name
              }
            </div>
          </div>
          <div className="row">
            <div className="col-4">Timezone</div>
            <div className="col-8">
              {participantTimezone
                ? `(${
                    participantTimezone.offset > 0
                      ? "+" + participantTimezone.offset
                      : participantTimezone.offset
                  }) ${
                    // replace _ with space
                    participantTimezone.name.replace(/_/g, " ")
                  }`
                : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-4">Email</div>
            <div className="col-8">{participant.email}</div>
          </div>
          <div className="row">
            <div className="col-4">Age</div>
            <div className="col-8">{participant.age || "N/A"}</div>
          </div>
          <div className="row">
            <div className="col-4">Gender</div>
            <div className="col-8">
              {participant.gender ? capitalize(participant.gender) : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-4">Participant score</div>
            <div className="col-8">
              <div className="progress">
                <span className="percent_text">
                  {participantParticipationLabel}%
                </span>
                <div
                  className={`progress-bar1 score_bar ${participantParticipationClass}`}
                  role="progressbar"
                  aria-label="Example with label"
                  style={{ width: `${participantParticipationLabel}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">Cheater score</div>
            <div className="col-8">
              <div className="progress">
                <span className="percent_text">{participantCheaterLabel}%</span>
                <div
                  className={`progress-bar1 score_bar ${participantCheaterClass}`}
                  role="progressbar"
                  aria-label="Example with label"
                  style={{ width: `${participantCheaterLabel}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">Date Created</div>
            <div className="col-8">
              {participant.last_used
                ? formatDate(convertTime(participant.created_at, 0)) + " UTC"
                : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-4">Last Used</div>
            <div className="col-8">
              {participant.last_used
                ? formatDate(convertTime(participant.last_used, 0)) + " UTC"
                : "N/A"}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-4">Tags</div>
            <div className="col-8">
              {participant.participant_tags?.map((tag) => (
                <span key={tag} className="tag">
                  {tag}
                </span>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewLeadModalTable;
