import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Icons from "../../../../assets/images/icons/icons";
import { usePermissions } from "../../../../contexts/UserContext";
import {
  fetchStudyByClientID,
  sendUpdatedStudy
} from "../../../../models/study.model";
import ErrorPage from "../../../../pages/Error/error.page";
import { Language, Study } from "../../../../types";
import { getSystemConfigLanguages } from "../../../../utilities/config.util";
import { handleExportPhrase } from "../../../../utilities/languageLibrary.util";
import { copyToClipboard } from "../../../../utilities/utils";
import InformationTooltip from "../../../InputInformation/InfoInputs.component";
import LanguagesFilter from "../../../Tables/Filters/filterLanguages.component";
import ToggleSwitch from "../../../ToggleSwitch/toggleSwitch.component";
import "./studySurveySettings.scss";

export type StudySettingsProps = {
  studyClicked: string;
};

const StudySettings: React.FC<StudySettingsProps> = ({ studyClicked }) => {
  const { studyID, clientID } = useParams(); // Get from URL
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [study, setStudy] = useState<Study | null>(null); // Set initial state to null
  //Update study object
  const [studyStatus, setStudyStatus] = useState<string>(""); // Set initial state to null
  const [studyName, setStudyName] = useState<string>(""); // Set initial state to null
  const [studyDescription, setStudyDescription] = useState<string>(""); // Set initial state to null
  const [studyInfo, setStudyInfo] = useState<string>(""); // Set initial state to null
  const [studyAIResponse, setStudyAIResponse] = useState<boolean>(false); // Set initial state to null
  const [studyLanguages, setStudyLanguages] = useState<string[]>([]); // Set initial state to null

  const [languages, setLanguages] = useState<Language[]>([]);

  const { hasPermission } = usePermissions();

  // Fetch Data
  useEffect(() => {
    const fetchStudy = async () => {
      try {
        const jsonData: Study | string = await fetchStudyByClientID(
          clientID as string,
          studyID as string
        );
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "15da67569eb508328bf12abdaf5ec73d"
          ]);
        } else {
          setStudy(jsonData);
          setStudyStatus(jsonData.study_status);
          setStudyName(jsonData.study_name);
          setStudyDescription(jsonData.study_desc ? jsonData.study_desc : "");
          setStudyInfo(jsonData.study_info ? jsonData.study_info : "");
          setStudyAIResponse(jsonData.ai_response || false);
          setStudyLanguages(jsonData.study_languages || []);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "22291bd700ba74125e96deb47b3a5459"
        ]);
      }
    };

    // get languages from local storage using getConfigLanguages
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const promises = [fetchStudy(), fetchAllConfigLanguages()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "097170a98710114f541041dd0ad6f139"
        ]);
      });
  }, [studyID, clientID]);

  // Disable Save Check
  useEffect(() => {
    // Check if any of the fields have been changed only after loading is complete
    if (loading === false) {
      if (
        study?.study_status !== studyStatus ||
        study?.study_name !== studyName ||
        study?.study_desc !== studyDescription ||
        study?.ai_response !== studyAIResponse ||
        study?.study_languages?.sort().join(",") !==
          studyLanguages.sort().join(",") ||
        (study?.study_info !== studyInfo &&
          (hasPermission("study", "write") ||
            hasPermission("study", "all") ||
            hasPermission("subject")))
      ) {
        setDisableSave(false);
      } else {
        setDisableSave(true);
      }
    }
  }, [
    studyStatus,
    studyName,
    studyDescription,
    studyInfo,
    studyAIResponse,
    studyLanguages,
    loading
  ]);

  const handleNewInputs = (
    name: string,
    value: React.SetStateAction<string>
  ) => {
    console.log("name:", name);
    console.log("value:", value);
    switch (name) {
      case "studyStatus":
        setStudyStatus(value);
        break;
      case "name":
        setStudyName(value);
        break;
      case "description":
        setStudyDescription(value);
        break;
      case "info":
        setStudyInfo(value);
        break;
      case "ai_response":
        setStudyAIResponse(value === "true" ? true : false);
        break;
      default:
        break;
    }
  };

  function validateStudy(): boolean {
    const stringShortMax = 255;
    const stringLongMax = 1500;
    let errors: string[] = [];
    // validate study name- cannot be blank, must be between 4 and stringShortMax
    if (studyName === "") {
      errors.push("Please enter a study name");
    } else if (studyName.length < 4) {
      errors.push("Study name must be at least 4 characters");
    } else if (studyName.length > stringShortMax) {
      errors.push(
        "Study name must be less than " + stringShortMax + " characters"
      );
    }

    // validate study description- canot be blank, must be less than stringLongMax
    if (studyDescription === "") {
      errors.push("Please enter a study description");
    } else if (studyDescription.length > stringLongMax) {
      errors.push(
        "Study description must be less than " + stringLongMax + " characters"
      );
    }

    // validate study info- canot be blank, must be less than stringLongMax
    if (studyInfo === "") {
      errors.push("Please enter study information");
    } else if (studyInfo.length > stringLongMax) {
      errors.push(
        "Study information must be less than " + stringLongMax + " characters"
      );
    }

    // validate study languages, must have at least one language
    if (studyLanguages.length === 0) {
      errors.push("Please select at least one language");
    }

    if (errors.length > 0) {
      let errorHtml =
        "<strong>Please fix the following errors:</strong><br /><br />";
      errors.forEach((error) => {
        errorHtml += `<p>${error}</p>`;
      });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errorHtml
      });
      return false;
    }

    return true;
  }

  const updateStudy = () => {
    // console.log("study:", study);
    if (!clientID || !studyID) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Missing clientID or studyID"
      });
      return;
    }

    if (!validateStudy()) {
      return;
    }

    const saveStudy = async () => {
      try {
        const jsonData: {
          rStatus: "error" | "success";
          rMessage: string;
        } = await sendUpdatedStudy(
          clientID,
          studyID,
          studyStatus.toLowerCase(),
          studyName,
          studyDescription,
          studyInfo,
          studyAIResponse,
          studyLanguages
        );

        if (jsonData.rStatus === "error") {
          Swal.fire({
            icon: "error",
            title: "Error",
            html: jsonData.rMessage
          });
        } else {
          // ok button which will reload the page
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Study updated successfully",
            showConfirmButton: true,
            confirmButtonText: "OK"
          }).then(() => {
            // reload page
            window.location.reload();
          });
        }
      } catch (error) {
        console.error("An error occurred while sending data:", error);
        // setError("An error occurred while sending data");
        Swal.fire({
          icon: "error",
          title: "Error",
          html: "An error occurred while sending data"
        });
      }
    };
    saveStudy();
  };

  //------------Export phrases----------------
  const handleExportButton = () => {
    if (!clientID || !studyID) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Missing clientID or studyID"
      });
      return;
    }
    handleExportPhrase(true, studyID, "Study", clientID);
  };
  return loading ? (
    <div className="d-flex justify-content-center custom_spinner_container full_page">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : loadingError ? (
    <ErrorPage
      errorCode="404"
      error="Error loading study"
      debugCode={loadingErrorText}
    />
  ) : !study ? ( //check if study is valid
    <ErrorPage
      errorCode="404"
      error="Study not found"
      debugCode={loadingErrorText}
    />
  ) : (
    <div className="container m-auto col-12 col-md-8 col-lg-8">
      <div className="row align-items-start justify-content-start">
        <div className="col card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="card-title">Study Settings</h5>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <p className="m-0">Study ID</p>
              </div>
              <div className="col-8">
                <div className="input-group mb-3 edit_input">
                  <input
                    type="text"
                    className="form-control"
                    value={studyID ? studyID : ""}
                    disabled={true}
                  />
                  <button
                    className="btn btn-primary "
                    type="button"
                    onClick={() =>
                      copyToClipboard("Study ID ", studyID ? studyID : "")
                    }
                  >
                    <Icons.IconCopy
                      className="icon icon_white"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <p className="m-0">Study Status</p>
              </div>
              <div className="col-8">
                <select
                  className="form-control not_edit_input"
                  value={studyStatus || "N/A"}
                  name="studyStatus"
                  placeholder="Status"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("study", "write")
                      ? false
                      : hasPermission("study", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label htmlFor="studyName">Name</label>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="text"
                  defaultValue={study.study_name || "N/A"}
                  name="name"
                  placeholder="Name"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("study", "write")
                      ? false
                      : hasPermission("study", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label htmlFor="studyDescription">Description</label>
              </div>
              <div className="col-8">
                <textarea
                  className="form-control edit_input text_input"
                  defaultValue={study.study_desc || "N/A"}
                  name="description"
                  placeholder="Description"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("study", "write")
                      ? false
                      : hasPermission("study", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            {/* Information */}
            <div className="row align-items-center">
              <div className="col-4">
                <label htmlFor="studyInfo">Information</label>
              </div>
              <div className="col-8">
                <textarea
                  className="form-control edit_input text_input"
                  defaultValue={study.study_info || "N/A"}
                  name="info"
                  placeholder="Information"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("study", "write")
                      ? false
                      : hasPermission("study", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            {/* Languages */}
            <div className="row align-items-center">
              <div className="col-4">
                <InformationTooltip
                  marginTop="-12px"
                  position="top"
                  tooltipText={
                    "Selected languages are displayed below. Multiple languages can be selected or unselected. Select to add languages, unselect to remove languages."
                  }
                />
                <label htmlFor="studyLanguages">Languages</label>
              </div>
              <div className="col-8">
                {/* map though study.languages and find the corresponding language in config, display the name */}

                <div className="other_input">
                  <LanguagesFilter
                    jsonlanguagesData={languages}
                    onLanguagesFilterChange={setStudyLanguages}
                    filtersCleared={false}
                    location={"resource-modal"}
                    defaultSelectedLanguages={studyLanguages}
                  />
                </div>
              </div>
            </div>
            {/* Selected Languages */}
            <div className="row align-items-center">
              <div className="col-4">
                <InformationTooltip
                  marginTop="-12px"
                  position="top"
                  tooltipText={"Selected languages are sorted alphabetically"}
                />
                <label htmlFor="studyLanguages">Selected Languages</label>
              </div>
              <div className="col-8">
                {/* map though study.languages and find the corresponding language in config, display the name */}

                {!studyLanguages || studyLanguages.length === 0 ? (
                  <>
                    <input
                      className="form-control edit_input"
                      type="text"
                      defaultValue="No language selected"
                      name="languages"
                      placeholder="Languages"
                      disabled={true}
                    />
                  </>
                ) : (
                  <textarea
                    className="form-control edit_input text_input"
                    value={studyLanguages.sort().join(", ")}
                    name="languages"
                    placeholder="Languages"
                    disabled={true}
                  />
                )}
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label htmlFor="studyThumbnailURL">Icon</label>
              </div>
              <div className="col-8">
                {!study.thumbnail_url || study.thumbnail_url === "" ? (
                  <p>N/A</p>
                ) : (
                  <p className="image_container">
                    <img
                      src={study.thumbnail_url}
                      alt="study thumbnail"
                      className="study_thumbnail"
                    />
                  </p>
                )}
              </div>
            </div>
            {/* ai_response toggle button */}
            <div className="row align-items-center">
              <div className="col-4">
                <label htmlFor="studyAIResponse">Kevin Response</label>
              </div>
              <div className="col-8">
                {/* buttons */}
                <div className="other_input">
                  <ToggleSwitch
                    id="studyAIResponse"
                    checked={studyAIResponse}
                    onChange={(checked) => {
                      handleNewInputs(
                        "ai_response",
                        checked ? "true" : "false"
                      );
                    }}
                    optionLabels={["On", "Off"]}
                    disabled={
                      hasPermission("study", "write")
                        ? false
                        : hasPermission("study", "all")
                          ? false
                          : hasPermission("subject")
                            ? false
                            : true
                    }
                    afterPadding={true}
                  />
                </div>
              </div>
            </div>
            {/* Export button for phrases */}
            <div className="row align-items-center">
              <div className="col-4">
                <label htmlFor="studyLanguages">Phrase Library</label>
              </div>
              <div className="col btn_save_study d-flex justify-content-end">
                <button
                  className="col-3 btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={handleExportButton}
                >
                  <Icons.IconUpload
                    className="icon icon_white"
                    style={{
                      transform: "rotate(180deg)",
                      width: "20px",
                      height: "20px",
                      marginRight: "5px"
                    }}
                  />
                  Export
                </button>
              </div>
            </div>
            {/* Save button */}
            <div className="row align-items-center">
              <div className="col btn_save_study">
                <button
                  className="col-2 btn btn-primary"
                  onClick={updateStudy}
                  disabled={disableSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudySettings;
