import { apiFetcher } from "../services/API.service";
/* ------------------------------------------------- */
/*-------------------Type validation-----------------*/
/* ------------------------------------------------- */
export type MessagesTags = {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
};

export type MessagesParticipants = {
  id: string;
  participant_full_name: string;
  participant_lang_iso: string;
  participant_country_iso: string;
  participant_flagged: number;
  participant_tags: MessagesTags[];
  study_status: string;
};
export type MessagesPhrases = {
  id: string;
  phrase: string;
  phrase_category_id: string;
};

export type MessagesResource = {
  id: string;
  resource: string;
  created_at: Date;
};

export type MessagesDetails = {
  tags: MessagesTags[];
  participants: MessagesParticipants[];
  phrases: MessagesPhrases[];
  resources: MessagesResource[];
};

//how we get the data {"message":"Fetched all communications data","success":true,"data":[{"id":5,"type":"Push Notification","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":265,"message":"2023 NEW PHRASE"}},{"id":6,"type":"Email","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":278,"message":"fdgdfg"}},{"id":7,"type":"SMS","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":279,"message":"fdgdfg"}},{"id":8,"type":"Push Notification","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":280,"message":"fdgdfg"}}]}

export type RecipientsList = RecipientsData[];
/*-------------------/Type validation/---------------*/

/* -------------------------------------------------- */
/*-----------------Validation functions---------------*/
/* -------------------------------------------------- */
function isTags(data: any): data is MessagesTags {
  return (
    typeof data.id === "string" &&
    typeof data.name === "string" &&
    typeof data.created_at === "string" &&
    typeof data.updated_at === "string"
  );
}
function isParticipants(data: any): data is MessagesParticipants {
  return (
    typeof data.id === "string" &&
    typeof data.participant_full_name === "string" &&
    typeof data.participant_lang_iso === "string" &&
    typeof data.participant_country_iso === "string" &&
    typeof data.participant_flagged === "number" &&
    Array.isArray(data.participant_tags) &&
    typeof data.study_status === "string"
  );
}
function isPhrases(data: any): data is MessagesPhrases {
  return (
    typeof data.id === "string" &&
    typeof data.phrase === "string" &&
    typeof data.phrase_category_id === "string"
  );
}
function isResources(data: any): data is MessagesResource {
  return (
    typeof data.id === "string" &&
    typeof data.resource === "string" &&
    typeof data.created_at === "string"
  );
}

function isMessagesDetails(data: any): data is MessagesDetails {
  const validTags = Array.isArray(data.tags) && data.tags.every(isTags);
  const validParticipants =
    Array.isArray(data.participants) && data.participants.every(isParticipants);
  const validPhrases =
    Array.isArray(data.phrases) && data.phrases.every(isPhrases);
  const validResources =
    Array.isArray(data.resources) && data.resources.every(isResources);

  const AllValid = validTags && validParticipants && validPhrases;

  if (!AllValid) {
    console.log("Invalid messages data");
    if (!validTags) {
      console.log("Invalid tags data");
    }
    if (!validParticipants) {
      console.log("Invalid participants data");
    }
    if (!validPhrases) {
      console.log("Invalid phrases data");
    }
    if (!validResources) {
      console.log("Invalid resources data");
    }
  } else {
    console.log("Valid messages data");
  }
  return AllValid;
}
//-----Messages-History-----

export type MessagesSent = {
  id: string;
  type: string;
  amount: number;
  sent: number;
  delivered: number;
  date: string | Date;
  phraseLibrary: {
    id: string;
    message: string;
  };
};

export type MessagesHistory = MessagesSent[];

export type RecipientsData = {
  userID: string;
  recipientsFullName: string;
  country: string;
  status: string;
};

function isMessagesSent(data: any): data is MessagesSent {
  return (
    typeof data.id === "string" &&
    typeof data.type === "string" &&
    typeof data.amount === "number" &&
    typeof data.sent === "number" &&
    typeof data.delivered === "number"
    //typeof data.date === "string" &&
    // typeof data.phraseLibrary === "object" &&
    // typeof data.phraseLibrary.id === "string" &&
    // typeof data.phraseLibrary.message === "string"
  );
}

function isMessageHistory(data: any): data is MessagesHistory {
  const valid = Array.isArray(data) && data.every(isMessagesSent);
  if (!valid) {
    console.log("Invalid messages data");
  } else {
    console.log("Valid messages data");
  }
  return valid;
}

//-----Recipients-----
function isRecipientsData(data: any): data is RecipientsData {
  return (
    typeof data.userID === "string" &&
    typeof data.recipientsFullName === "string" &&
    typeof data.country === "string" &&
    typeof data.status === "string"
  );
}

function isRecipientsList(data: any): data is RecipientsList {
  return Array.isArray(data) && data.every(isRecipientsData);
}

function isValidRecipients(data: any): data is RecipientsList {
  const valid = isRecipientsList(data);

  if (!valid) {
    console.log("Invalid recipients data");
  } else {
    console.log("Valid recipients data");
  }
  return valid;
}

/*----------------/Validation functions/--------------*/
/* -------------------------------------------------- */
/*-------------------------Post-----------------------*/
/* -------------------------------------------------- */
export async function fetchAllSendMessages(
  clientID: string,
  studyID: string
): Promise<MessagesDetails | string> {
  //mock data for testing

  try {
    const response = await apiFetcher<any>("/getMessageData", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID
      }
    });
    //return mockData;
    if (response && response.status === 200 && response.data) {
      if (!isMessagesDetails(response.data)) {
        throw new Error("Valid to validate messages data");
      }
      return response.data;
    } else {
      return "Invalid response";
    }
  } catch (error) {
    console.error(error);
    return "Failed to fetch messages data";
  }
}

//Create new message
export async function createNewMessage(
  clientID: string,
  studyID: string,
  participantID: string[],
  categoryID: string,
  type: string,
  phrase: string
): Promise<string> {
  //Case statement for different routes for different types
  //route to hit
  let route = "";
  switch (type) {
    case "Push Notification":
      route = "/messages/send/notifications";
      break;
    case "Email":
      route = "/messages/sendEmail";
      break;
    case "SMS":
      route = "/messages/send/sms";
      break;
    default:
      route = "";
  }
  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        // categoryID: categoryID,
        participantIDs: participantID,
        // type: type,
        phrase: phrase
      }
    });

    if (response && response.status === 200 && response.data !== undefined) {
      localStorage.setItem("workerID", response.data.jobID);

      return response.data;
    } else {
      return "Invalid response";
    }
  } catch (error) {
    console.error(error);
    return "Failed to create message";
  }
}

//create test communication
export async function createTestCommunication(
  type: string,
  phrase: string
): Promise<string> {
  let route = "";
  switch (type) {
    // case "Push Notification":
    //   route = ""
    //   break;
    case "Email":
      route = "/messages/sendTestEmail";
      break;
    case "SMS":
      route = "/messages/send/test/sms";
      break;
    default:
      route = "";
  }
  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: {
        type: type,
        phrase: phrase
      }
    });
    if (response && response.status === 202) {
      return response.data;
    } else {
      return "Invalid response";
    }
  } catch (error) {
    console.error(error);
    return "Failed to create message";
  }
}
//-----ResendCommunication-----
export async function ResendCommunication(
  clientID: string,
  studyID: string,
  communicationID: string,
  participantID: string[]
): Promise<string> {
  try {
    const response = await apiFetcher<any>("/resendCommunication", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        communicationID: communicationID,
        participantIDs: participantID
      }
    });
    if (response && response.status === 200 && response.data !== undefined) {
      //set local storage
      localStorage.setItem("workerID", response.data.data);
      return response.data.data;
    } else {
      return "Invalid response";
    }
  } catch (error) {
    console.error(error);
    return "Failed to create message";
  }
}

//-----Recipients-----
export async function fetchRecipients(
  clientID: string,
  studyID: string,
  messageId: string
): Promise<RecipientsList | string> {
  try {
    const response = await apiFetcher<any>("/getRecipients", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        id: messageId
      }
    });
    //return mockData;
    if (response && response.status === 200 && response.data) {
      if (!isValidRecipients(response.data)) {
        throw new Error("invalid recipients data");
      }
      return response.data;
    } else if (response && response.status === 204) {
      return response.data;
    } else {
      return "Invalid response";
    }
  } catch (error) {
    console.error(error);
    return "Failed to fetch messages data";
  }
}
//-----Messages-History-----
//how we get the data {"message":"Fetched all communications data","success":true,"data":[{"id":5,"type":"Push Notification","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":265,"message":"2023 NEW PHRASE"}},{"id":6,"type":"Email","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":278,"message":"fdgdfg"}},{"id":7,"type":"SMS","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":279,"message":"fdgdfg"}},{"id":8,"type":"Push Notification","amount":1,"sent":0,"delivered":0,"date":null,"phraseLibrary":{"id":280,"message":"fdgdfg"}}]}
export async function fetchAllReceivedMessages(
  clientID: string,
  studyID: string
): Promise<MessagesHistory | string> {
  try {
    const response = await apiFetcher<any>(
      "/messages/history/get/all ",
      "POST",
      {
        body: {
          clientID: clientID,
          studyID: studyID
        }
      }
    );
    //return mockData;
    // console.log(response, "response")
    // console.log(response.data.data, "response.data")\
    //data is is data.data
    if (response && response.status === 200 && response.data) {
      if (!isMessageHistory(response.data)) {
        //throw new Error("Valid to validate messages data")
        return "Invalid messages data";
      }
      return response.data;
    } else if (response && response.status === 204) {
      return response.data;
    } else {
      return "Invalid response";
    }
  } catch (error) {
    console.error(error);
    return "Failed to fetch messages data";
  }
}
