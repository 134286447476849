import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import automationActionsData from "../../../../assets/data/automationActions.json";
import { fetchAllLanguageLibraryPhrasesByCatID } from "../../../../models/translations.model";
import {
  ActionTypes,
  Actions,
  AutomationRuleData,
  SimpleDataAutomation
} from "../../../../types";
import { convertIDToName } from "../../../../utilities/automation.util";
import { useOutsideClick } from "../../../../utilities/utils";
import "./action.scss";
import NewPhraseSelection from "./newPhraseSelection.component";

export type ActionProps = {
  actionID: string;
  removeAction: (id: string) => void;
  setActions: React.Dispatch<React.SetStateAction<Actions[]>>;
  dropdownRuleData: AutomationRuleData;
  editable: boolean;
  // for displaying static
  actionType: string;
  actionVariable: string;
  actionSelection: string;
  actionValue: string;

  viewEditRule: boolean;
  //Checking if the new phrase is selected
  newPhraseFlag?: string;
  setNewPhraseFlag?: React.Dispatch<React.SetStateAction<string>>;
};

const Action: React.FC<ActionProps> = ({
  actionID,
  removeAction,
  setActions,
  dropdownRuleData,
  editable,

  actionType,
  actionVariable,
  actionSelection,
  actionValue,
  viewEditRule,
  newPhraseFlag,
  setNewPhraseFlag
}) => {
  const { clientID, surveyID, studyID } = useParams(); // Get from URL

  const [actionDropdown, setActionDropdown] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [actionData, setActionData] = useState<ActionTypes[]>([]);

  const [selectedVariable, setSelectedVariable] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");

  useEffect(() => {
    // fetch tags from API

    setActionData(automationActionsData.actions.map((action) => action));
  }, []);

  // ====================================  ACTIONS ====================================
  const actionRef = useRef<HTMLDivElement>(null);
  useOutsideClick(actionRef, () => {
    // Action to take on outside click
    handleActionDropdown(false);
  });

  const handleActionDropdown = (show: boolean) => {
    setActionDropdown(show);
  };

  const handleSelectAction = (
    actionID: string,
    action: string,
    type: string
  ) => {
    // reset the variable identifier
    setSelectedValue("");
    setSelectedVariable("");
    setNewPhraseSelection("existing");
    setNewPhraseFlag && setNewPhraseFlag("existing");

    // set the action and type
    setSelectedType(type);
    setSelectedAction(action);

    // set the variable identifier
    if (type === "study") {
      console.log("PROC'ED");
      if (studyID) {
        console.log(studyID);
        setSelectedVariable(studyID);

        setActions((prevActions) => {
          return prevActions.map((prevAction) => {
            return prevAction.id === actionID
              ? {
                  ...prevAction,
                  type: type,
                  selection: action,
                  variable: studyID,
                  value: ""
                }
              : prevAction;
          });
        });
      }
    } else {
      setActions((prevActions) => {
        return prevActions.map((prevAction) => {
          return prevAction.id === actionID
            ? {
                ...prevAction,
                type: type,
                selection: action,
                variable: "",
                value: ""
              }
            : prevAction;
        });
      });
    }

    setActionDropdown(false);
  };

  // =================================== VARIABLE IDENTIFIER ====================================

  const variableRef = useRef<HTMLDivElement>(null);
  useOutsideClick(variableRef, () => {
    // Action to take on outside click
    handleVariableDropdown(false);
  });

  const [variableDropdown, setVariableDropdown] = useState(false);

  const handleVariableDropdown = (show: boolean) => {
    setVariableDropdown(show);
  };

  const handleVariable = (varId: string, variableDisplay: string) => {
    console.log("Fire");
    console.log(varId, variableDisplay);

    setSelectedVariable(variableDisplay);
    setActions((prevActions) => {
      return prevActions.map((prevAction) => {
        return prevAction.id === actionID
          ? {
              ...prevAction,
              variable: varId
            }
          : prevAction;
      });
    });
    setVariableDropdown(false);
  };

  // ====================================  VALUE  ====================================
  const [selectedValue, setSelectedValue] = useState<string>("");
  const valueRef = useRef<HTMLDivElement>(null);
  useOutsideClick(valueRef, () => {
    // Action to take on outside click
    handleValueDropdown(false);
  });

  const [valueDropdown, setValueDropdown] = useState(false);

  const handleValueDropdown = (show: boolean) => {
    setValueDropdown(show);
  };

  const handleValue = (actionID: string, value: string) => {
    console.log("fire");
    setSelectedValue(value);
    setActions((prevActions) => {
      return prevActions.map((prevAction) => {
        return prevAction.id === actionID
          ? {
              ...prevAction,
              value: value
            }
          : prevAction;
      });
    });

    handleValueDropdown(false);
  };

  // Logging
  useEffect(() => {
    console.log(actionType, actionSelection, actionValue, actionVariable);
    console.log(viewEditRule);
  }, [actionType, actionSelection, actionValue, actionVariable]);

  // if viewEditRule is true, then set the selectedAction, selectedVariable, selectedValue
  useEffect(() => {
    console.log("CHANGED TO EDIT");
    if (viewEditRule) {
      // changing to edit
      console.log("CHANGED TO EDIT");
      setSelectedVariable(actionVariable);
      setSelectedAction(actionSelection);
      setSelectedValue(actionValue);
    }
  }, [viewEditRule]);

  // ========================================== Language Library ==========================================
  const [newPhraseSelection, setNewPhraseSelection] =
    useState<string>("existing");
  const [categoryID, setCategoryID] = useState<string>();
  const [phraseDropdownData, setPhraseDropdownData] = useState<
    SimpleDataAutomation[]
  >([]);

  //  Changing radio buttons
  const handleSetNewPhrase = (phrase: string) => {
    console.log(phrase);
    setNewPhraseSelection(phrase);
    //
    setNewPhraseFlag && setNewPhraseFlag("New phrase");
    // Reset the value and new phrase
    if (phrase === "new") {
      setActions((prevActions) => {
        return prevActions.map((prevAction) => {
          return prevAction.id === actionID
            ? {
                ...prevAction,
                value: ""
              }
            : prevAction;
        });
      });
    }
  };

  // If actionType is notification, email, or sms, fetch the language library
  useEffect(() => {
    // fetch language library
    if (actionType === "notification") {
      console.log("FETCHING");
      setCategoryID("14");
      // set action variable
      setActions((prevActions) => {
        return prevActions.map((prevAction) => {
          return prevAction.id === actionID
            ? {
                ...prevAction,
                variable: "14"
              }
            : prevAction;
        });
      });
      try {
        fetchAllLanguageLibraryPhrasesByCatID("14").then((res) => {
          const data = res as SimpleDataAutomation[];
          if (typeof res !== "string") {
            setPhraseDropdownData(data);
          } else {
            setPhraseDropdownData([]);
          }
        });
      } catch (error) {
        console.log(error);
        setPhraseDropdownData([]);
      }
    }

    if (actionType === "email") {
      console.log("FETCHING");
      setCategoryID("13");

      // set action variable
      setActions((prevActions) => {
        return prevActions.map((prevAction) => {
          return prevAction.id === actionID
            ? {
                ...prevAction,
                variable: "13"
              }
            : prevAction;
        });
      });
      try {
        fetchAllLanguageLibraryPhrasesByCatID("13").then((res) => {
          if (typeof res !== "string") {
            const data = res as SimpleDataAutomation[];
            setPhraseDropdownData(data);
          } else {
            setPhraseDropdownData([]);
          }
        });
      } catch (error) {
        console.log(error);
        setPhraseDropdownData([]);
      }
    }

    if (actionType === "sms") {
      console.log("FETCHING");

      setCategoryID("15");
      // set action variable

      setActions((prevActions) => {
        return prevActions.map((prevAction) => {
          return prevAction.id === actionID
            ? {
                ...prevAction,
                variable: "15"
              }
            : prevAction;
        });
      });

      try {
        fetchAllLanguageLibraryPhrasesByCatID("15").then((res) => {
          if (typeof res !== "string") {
            const data = res as SimpleDataAutomation[];
            setPhraseDropdownData(data);
          } else {
            setPhraseDropdownData([]);
          }
        });
      } catch (error) {
        console.log(error);
        setPhraseDropdownData([]);
      }
    }
  }, [actionType]);

  // ========================================== FILTERS ==============================================

  // buckets
  const [bucketsFilter, setBucketsFilter] = useState<string>("");
  const handleBucketsSearchFilter = (search: string) => {
    setBucketsFilter(search);
  };

  const filteredBuckets =
    dropdownRuleData.automationConditionDataDB.buckets.filter((bucket) =>
      bucket.name.toLowerCase().includes(bucketsFilter.toLowerCase())
    );

  // surveys
  const [surveysFilter, setSurveysFilter] = useState<string>("");
  const handleSurveysSearchFilter = (search: string) => {
    setSurveysFilter(search);
  };

  const filteredSurveys =
    dropdownRuleData.automationConditionDataDB.surveys.filter((survey) =>
      survey.name.toLowerCase().includes(surveysFilter.toLowerCase())
    );

  // Phrases
  const [phrasesFilter, setPhrasesFilter] = useState<string>("");
  const handlePhrasesSearchFilter = (search: string) => {
    setPhrasesFilter(search);
  };

  const filteredPhrases = phraseDropdownData.filter((phrase) =>
    phrase.name.toLowerCase().includes(phrasesFilter.toLowerCase())
  );

  return (
    <div className="d-flex single_action_container">
      {/*========================================  Selection Section  ======================================== */}
      <div className="dropdown dropdown_action" ref={actionRef}>
        {editable ? (
          <>
            <button
              className="btn btn-primary dropdown-toggle action_dropdown_button"
              onClick={() => handleActionDropdown(!actionDropdown)}
            >
              {/* {Id} */}
              {selectedAction ? selectedAction : "Action Type"}
            </button>
            <div
              className={`dropdown-menu ${actionDropdown ? "show" : "hide"}`}
            >
              {actionData.map((action) => (
                <button
                  className="dropdown-item"
                  key={action.id}
                  onClick={() => {
                    console.log(action);
                    handleSelectAction(actionID, action.selection, action.type);
                  }}
                >
                  {action.selection}
                </button>
              ))}
            </div>
          </>
        ) : (
          <>
            <button className="btn btn-primary action_dropdown_button">
              {/* {Id} */}
              {actionSelection}
            </button>
          </>
        )}
      </div>

      {/*========================================  Variable Section  ======================================== */}
      {/* SURVEY PROGRESS */}
      {/* Dropdown with surveys from dropdownRuleData */}
      {actionType === "survey" ? (
        <>
          <div className="dropdown dropdown_action" ref={variableRef}>
            {editable ? (
              <>
                <button
                  className="btn btn-primary dropdown-toggle action_dropdown_button"
                  onClick={() => handleVariableDropdown(!variableDropdown)}
                >
                  {viewEditRule ? (
                    <>
                      {actionVariable
                        ? convertIDToName(
                            "survey",
                            actionVariable,
                            dropdownRuleData.automationConditionDataDB.surveys
                          )
                        : "Select Survey"}
                    </>
                  ) : (
                    <>{selectedVariable ? selectedVariable : "Select Survey"}</>
                  )}
                </button>
                <div
                  className={`dropdown-menu ${
                    variableDropdown ? "show" : "hide"
                  }`}
                >
                  <input
                    type="text"
                    className="filter_rules_input"
                    placeholder="Search Surveys"
                    value={surveysFilter}
                    onChange={(e) => handleSurveysSearchFilter(e.target.value)}
                  />
                  <div className="dropdown-items">
                    {filteredSurveys.length > 0 ? (
                      filteredSurveys.map((survey) => (
                        <button
                          className="dropdown-item"
                          key={survey.id}
                          onClick={() => {
                            console.log(survey);
                            handleVariable(survey.id, survey.name);
                          }}
                        >
                          {survey.name}
                        </button>
                      ))
                    ) : (
                      <div className="text-center">No Surveys Found</div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              // ON PREVIEW
              <>
                {dropdownRuleData.automationConditionDataDB.surveys?.map(
                  (survey) =>
                    survey.id === actionVariable ? (
                      <button
                        key={survey.id}
                        className="btn btn-primary action_dropdown_button"
                      >
                        {/* {Id} */}
                        {survey.name}
                      </button>
                    ) : null
                )}
              </>
            )}
          </div>

          {/* Map survey values */}
        </>
      ) : null}

      {/* CHANGE BUCKET PROGRESS */}
      {actionType === "bucket" ? (
        <>
          <div className="dropdown dropdown_action" ref={variableRef}>
            {editable ? (
              <>
                <button
                  className="btn btn-primary dropdown-toggle action_dropdown_button"
                  onClick={() => handleVariableDropdown(!variableDropdown)}
                >
                  {viewEditRule ? (
                    <>
                      {actionVariable
                        ? convertIDToName(
                            "bucket",
                            actionVariable,
                            dropdownRuleData.automationConditionDataDB.buckets
                          )
                        : "Select Bucket"}
                    </>
                  ) : (
                    <>{selectedVariable ? selectedVariable : "Select Bucket"}</>
                  )}
                </button>
                <div
                  className={`dropdown-menu ${
                    variableDropdown ? "show" : "hide"
                  }`}
                >
                  <input
                    type="text"
                    className="filter_rules_input"
                    placeholder="Search Buckets"
                    value={bucketsFilter}
                    onChange={(e) => handleBucketsSearchFilter(e.target.value)}
                  />
                  <div className="dropdown-items">
                    {filteredBuckets.length > 0 ? (
                      filteredBuckets.map((bucket) => (
                        <button
                          className="dropdown-item"
                          key={bucket.id}
                          onClick={() => {
                            console.log(bucket);
                            handleVariable(bucket.id, bucket.name);
                            handleValue(actionID, bucket.name);
                          }}
                        >
                          {bucket.name}
                        </button>
                      ))
                    ) : (
                      <div className="text-center">No Buckets Found</div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {dropdownRuleData.automationConditionDataDB.buckets?.map(
                  (bucket) =>
                    bucket.id === actionVariable ? (
                      <button
                        key={bucket.id}
                        className="btn btn-primary action_dropdown_button"
                      >
                        {/* {Id} */}
                        {bucket.name}
                      </button>
                    ) : null
                )}
              </>
            )}
          </div>
        </>
      ) : null}

      {/*========================================  Value Section  ======================================== */}
      {/* SMS EMAIL AND PUSH NOTIFICATION */}
      {actionType === "notification" ||
      actionType == "email" ||
      actionType == "sms" ? (
        // Text area for app and mobile, changes value
        editable ? (
          <>
            {/* Radio Buttons for notifications/sms/email */}

            <div className="radio_add_phrase_container">
              <NewPhraseSelection
                type={actionType}
                newPhraseSelection={newPhraseSelection}
                handleSetNewPhrase={handleSetNewPhrase}
                actionID={actionID}
              />
            </div>

            {/* EXISTING */}
            {newPhraseSelection === "existing" ? (
              <>
                <div className="dropdown dropdown_action" ref={valueRef}>
                  {editable ? (
                    <>
                      {/* Dropdown button */}
                      <button
                        className="btn btn-primary dropdown-toggle action_dropdown_button value phrase_button"
                        onClick={() => handleValueDropdown(!valueDropdown)}
                      >
                        {isNaN(parseInt(actionValue)) ||
                        phraseDropdownData.length === 0
                          ? "Select a Phrase"
                          : (
                              phraseDropdownData.find(
                                (phrase) => phrase.id === actionValue.toString()
                              ) || { name: "Select a Phrase" }
                            ).name}
                      </button>
                      {/* Dropdown menu */}
                      <div
                        className={`dropdown-menu ${
                          valueDropdown ? "show" : "hide"
                        } phrase_menu`}
                      >
                        <input
                          type="text"
                          className="filter_rules_input"
                          placeholder="Search Phrases"
                          value={phrasesFilter}
                          onChange={(e) =>
                            handlePhrasesSearchFilter(e.target.value)
                          }
                        />
                        <div className="dropdown-items">
                          {/*compare selectedAction with automationActionsData selection if match, map the values  */}
                          {filteredPhrases.length > 0 ? (
                            filteredPhrases.map((phrase) => (
                              <button
                                className="dropdown-item phrase_item"
                                key={phrase.id}
                                onClick={() => {
                                  console.log(phrase);
                                  handleValue(actionID, phrase.id);
                                  handleVariable(
                                    categoryID ? categoryID : "categoryID",
                                    ""
                                  );
                                }}
                              >
                                {phrase.name}
                              </button>
                            ))
                          ) : (
                            <span className="ps-2 text-center">
                              No Phrases Found
                            </span>
                          )}
                        </div>
                      </div>
                      <textarea
                        className="action_value_textarea phrase_display"
                        placeholder={
                          actionValue && phraseDropdownData.length > 0
                            ? phraseDropdownData.find(
                                (phrase) => phrase.id === actionValue
                              )?.name
                            : ""
                        }
                        disabled
                      ></textarea>
                    </>
                  ) : (
                    <button className="btn btn-primary action_dropdown_button value">
                      {/* loop through phraseDropdownData, get name based on id */}
                      {phraseDropdownData &&
                        typeof phraseDropdownData !== "string" &&
                        phraseDropdownData.find(
                          (phrase) => phrase.id === actionValue
                        )?.name}
                    </button>
                  )}
                </div>
              </>
            ) : (
              // New Phrase Input
              <textarea
                className="action_value_textarea phrase_display"
                value={actionValue}
                onChange={(e) => handleValue(actionID, e.target.value)}
                placeholder="Please enter a new phrase"
                maxLength={1500}
              ></textarea>
            )}
          </>
        ) : (
          // ON PREVIEW
          <>
            <textarea
              className="action_value_textarea"
              // Iterate through phraseDropdownData, get name based on id
              placeholder={
                actionValue && phraseDropdownData.length > 0
                  ? (
                      phraseDropdownData.find(
                        (phrase) => phrase.id === actionValue.toString()
                      ) || { name: "Phrase Creation Pending..." }
                    ).name
                  : !isNaN(parseInt(actionValue))
                    ? actionValue
                    : "Phrase Creation Pending..."
              }
              disabled
            ></textarea>
          </>
        )
      ) : null}

      {/* CHANGE STUDY STATUS */}
      {actionType === "study" ? (
        <>
          <div className="dropdown dropdown_action" ref={valueRef}>
            {editable ? (
              <>
                <button
                  className="btn btn-primary dropdown-toggle action_dropdown_button"
                  onClick={() => handleValueDropdown(!valueDropdown)}
                >
                  {selectedValue ? selectedValue : "Select Study Status"}
                </button>
                <div
                  className={`dropdown-menu ${valueDropdown ? "show" : "hide"}`}
                >
                  {automationActionsData.actions
                    .filter((action) => action.selection === selectedAction)
                    .map((action) =>
                      action.values.map((value) => (
                        <button
                          className="dropdown-item"
                          key={value}
                          onClick={() => {
                            console.log(value);
                            handleValue(actionID, value);
                          }}
                        >
                          {value}
                        </button>
                      ))
                    )}
                </div>
              </>
            ) : (
              <button className="btn btn-primary action_dropdown_button">
                {actionValue}
              </button>
            )}
          </div>
        </>
      ) : null}

      {/* if survey, compare selectedAction with automationActionsData selection if match, map the values*/}
      {actionType === "survey" ? (
        <>
          {/* // one button, dropdown, list the values in the dropdown */}
          <div className="dropdown dropdown_action" ref={valueRef}>
            {editable ? (
              <>
                <button
                  className="btn btn-primary dropdown-toggle action_dropdown_button value"
                  onClick={() => handleValueDropdown(!valueDropdown)}
                >
                  {selectedValue ? selectedValue : "Action Value"}
                </button>
                <div
                  className={`dropdown-menu ${valueDropdown ? "show" : "hide"}`}
                >
                  {/*compare selectedAction with automationActionsData selection if match, map the values  */}
                  {automationActionsData.actions
                    .filter((action) => action.selection === selectedAction)
                    .map((action) =>
                      action.values.map((value) => (
                        <button
                          className="dropdown-item"
                          key={value}
                          onClick={() => {
                            console.log(value);
                            handleValue(actionID, value);
                          }}
                        >
                          {value}
                        </button>
                      ))
                    )}
                </div>
              </>
            ) : (
              <button className="btn btn-primary action_dropdown_button value">
                {actionValue}
              </button>
            )}
          </div>
        </>
      ) : null}

      {/* ADD POINTS */}
      {actionType === "reward" ? (
        <>
          <input
            type="text"
            className="action_value_input"
            value={actionValue}
            min={0}
            max={100000}
            pattern="\d*" // only numbers
            onChange={(e) => {
              const value = e.target.value;
              if (
                /^\d*$/.test(value) &&
                Number(value) >= 0 &&
                Number(value) <= 100000
              ) {
                handleValue(actionID, value);
              }
            }}
          />
        </>
      ) : null}

      {/*========================================  Edit Section  ======================================== */}

      {/*========================================  Delete Section  ======================================== */}
      {editable && (
        <button
          className="action_delete_button btn btn-danger"
          onClick={() => removeAction(actionID)}
        >
          Delete
        </button>
      )}
    </div>
  );
};

export default Action;
