// component to select the number of rows in the table

import React, { useEffect, useState } from "react";
import "./tableRowsSelector.scss";

interface TableRowsSelectorProps {
  rowsPerPage: number;
  handleChangeRows: (rowsPerPage: number) => void;
  arrayRowSelector?: number[];
  tableName?: string;
}

const TableRowsSelector: React.FC<TableRowsSelectorProps> = ({
  rowsPerPage,
  handleChangeRows,
  arrayRowSelector,
  tableName
}) => {
  const [arrRowSelector, setArrRowSelector] = useState<number[]>([]);

  useEffect(() => {
    if (arrayRowSelector) {
      setArrRowSelector(arrayRowSelector);
    }
  }, [arrayRowSelector]);

  return (
    <div id="table_rows_selector" className="compact_buttons_switcher">
      <span>{tableName ? tableName : "Rows"} per page: </span>
      {/* <button className="btn btn-primary" onClick={() => handleChangeRows(10)}>
        10
      </button> */}
      {arrRowSelector.map((row) => (
        <button
          key={row}
          className={`btn btn-primary ${
            row === rowsPerPage ? "active" : "inactive"
          }`}
          onClick={() => handleChangeRows(row)}
        >
          {row}
        </button>
      ))}
    </div>
  );
};

export default TableRowsSelector;
