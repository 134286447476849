// SocketContext.tsx
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";
import SocketController from "../controller/socket.controller";
import { NewMessageResponse, SocketAction } from "../types";
import socketReducer, { SocketState } from "./SocketReducer";

//Socket context
// Create a context for the socket
// Assuming SocketController is adapted to accept a callback to update authenticated status
const SocketContext = createContext<{
  state: SocketState;
  dispatch: React.Dispatch<SocketAction>;
  controller: SocketController;
} | null>(null);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [state, dispatch] = useReducer(socketReducer, {
    connected: false,
    authenticated: false,
    allChannels: [],
    channelParticipants: [],
    allMessages: [],
    newMessage: {} as NewMessageResponse,
    timestamp: ""
  });

  // Instantiate the SocketController outside useEffect
  // This ensures that `controller` is created once and reused
  const controller = useMemo(() => new SocketController(dispatch), [dispatch]);

  useEffect(() => {
    // Any other initialization logic can go here
    console.log("SocketProvider mounting");
    // Cleanup function to run on component unmount
    return () => {
      console.log("SocketProvider unmounting");
      controller.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ state, dispatch, controller }}>
      {children}
    </SocketContext.Provider>
  );
};

// Use this custom hook in your components to access the socket and its state
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
