import React, { useState } from "react";
import { IconAdd } from "../../../../assets/images/icons/icons";
import { usePermissions } from "../../../../contexts/UserContext";
import { Channel } from "../../../../types";
import SingleChannel from "./singleChannel.component";

import "./channels.scss";

export type ChannelContainerProps = {
  channels: Channel[];
  selectedChannel: string;
  setSelectedChannel: React.Dispatch<React.SetStateAction<string>>;
  setSelectedChannelType: React.Dispatch<React.SetStateAction<string>>;
  setOpenChannelOptionsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreateChannel: React.Dispatch<React.SetStateAction<boolean>>;
  loadingChannels: boolean;
};

const ChannelContainer: React.FC<ChannelContainerProps> = ({
  channels,
  selectedChannel,
  setSelectedChannel,
  setSelectedChannelType,
  setOpenChannelOptionsModal,
  setIsCreateChannel,
  loadingChannels
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { hasPermission } = usePermissions();
  const handleShowCreateChannelModal = () => {
    setOpenChannelOptionsModal(true);
    setIsCreateChannel(true);
  };

  const handleChannelClick = (channelId: string, channelType: string) => {
    setSelectedChannel((prevChannelId) => {
      return channelId; // Return the new value
    });

    setSelectedChannelType((prevChannelType) => {
      return channelType; // Return the new channel type
    });
  };

  return (
    <>
      <div
        className="channel_container card"
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <div className="top_bar card-header">
          <div>Channels</div>
          {
            (hasPermission("subject") ||
              hasPermission("chat", "all") ||
              hasPermission("chat", "write")) && (
              //Why would this be
              // channels.length > 0 && (
              <div
                className={`create_channel ${isHovered ? "show_create" : ""}`}
              >
                <button
                  className="add_button"
                  onClick={handleShowCreateChannelModal}
                >
                  CREATE
                </button>
              </div>
            )
            // )}
          }
        </div>
        <div className="main_channel_singles_container">
          {loadingChannels ? (
            <div className="d-flex justify-content-center custom_spinner_container pt-2">
              <div
                className="spinner-border"
                style={{ width: "20px", height: "20px" }}
                role="status"
              ></div>
            </div>
          ) : (
            <>
              {channels && channels.length > 0 ? (
                channels.map((channel) => (
                  <SingleChannel
                    key={channel.id}
                    channelId={channel.id}
                    channelName={channel.channelName}
                    channelType={channel.channelType}
                    channelUnread={channel.unread}
                    imageSrc={`${channel.thumbnailUrl}`}
                    isHovered={isHovered}
                    isSelected={selectedChannel === channel.id}
                    handleChannelClick={handleChannelClick}
                  />
                ))
              ) : (
                <div className="d-flex justify-content-center pt-2">
                  {(hasPermission("subject") ||
                    hasPermission("chat", "all") ||
                    hasPermission("chat", "write")) && (
                    <div
                      className="create-channel-btn"
                      onClick={handleShowCreateChannelModal}
                    >
                      <IconAdd />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChannelContainer;
