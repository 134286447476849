import React, { useEffect, useState } from "react";
import { GSI } from "../../types";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation";

type ViewParticipantModalVariablesProps = {
  variables: GSI | undefined | null;
};

const ViewParticipantModalVariables: React.FC<
  ViewParticipantModalVariablesProps
> = ({ variables }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [variablesPerPage, setVariablesPerPage] = useState(10);
  const [currentVariables, setCurrentVariables] = useState<any[]>([]);

  useEffect(() => {
    // console.log(currentParticipants, filteredParticipants);
    if (variables && variables.length > 0) {
      setCurrentVariables(
        variables.slice(
          (currentPage - 1) * variablesPerPage,
          currentPage * variablesPerPage
        )
      );
    }
  }, [currentPage, variablesPerPage, variables]);
  return (
    <>
      <div className="modal-body">
        {variables === undefined || variables === null ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <p className="text-center">No variables found</p>
              </div>
            </div>
          </div>
        ) : (
          // table with two columns
          <div className="table-responsive mb-2">
            <table className="cust_table table">
              <thead>
                <tr>
                  <th>Variable Name</th>
                  <th>Variable Value</th>
                </tr>
              </thead>
              <tbody>
                {currentVariables.map((variable, index) => (
                  <tr key={index}>
                    <td>{variable.variable_name}</td>
                    <td>{variable.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <PaginationNavigation
          currentPage={currentPage}
          totalResults={variables ? variables.length : 0}
          resultsPerPage={variablesPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default ViewParticipantModalVariables;
