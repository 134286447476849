import { useEffect, useState } from "react";
import { ConfigPhraseCategory } from "../../types";
import { getSystemConfigPhraseCategories } from "../../utilities/config.util";
import "./categoriesDisplay.scss";

export default function CategoriesDisplayPage() {
  const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
  const [categories, setCategories] = useState<ConfigPhraseCategory[]>([]);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  useEffect(() => {
    // fetch categories data on component mount

    const fetchTLCData = async () => {
      try {
        const jsonData = await getSystemConfigPhraseCategories();
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching categories");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);

          setCategories(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };

    const promises = [fetchTLCData()];
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingCategories(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingCategories(false);
        setLoadingError(true);
      });
  }, []);

  return (
    // if not loading
    !loadingCategories ? (
      loadingError ? (
        <div id="language_table_page" className="container">
          <h1>Error loading data</h1>
        </div>
      ) : (
        <>
          <div id="language_table_page" className="container">
            <h1>Categories</h1>
            <div className="row">
              {categories.length === 0 ? (
                <p>No categories found in config. Please contact support</p>
              ) : (
                <>
                  {categories.map(
                    (category: ConfigPhraseCategory, index: number) => (
                      <div
                        className="col-12 col-md-6 col-xl-4 row_item"
                        key={index}
                      >
                        <div className="row">
                          <div className="col-3 text-end">{category.id}</div>
                          <div className="col-9">
                            {category.phrase_category_name}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )
    ) : (
      <div id="language_table_page" className="container">
        <h1>Loading...</h1>
      </div>
    )
  );
}
{
  /* Create a table the same as countriesDisplay page , we want it to have the id of the category as well as the name */
}
