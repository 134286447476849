import React, { useEffect, useState } from "react";
import { WSCVariableOptions } from "../../../../types";
import "../../../../components/Tables/PaginationNavigation/paginationNavigation.scss";

interface WSCMappingPaginationProps {
  variableId: string;
  allOptions: WSCVariableOptions[];
  handleSubVariableChange: any;
}

const WSCMappingPagination: React.FC<WSCMappingPaginationProps> = ({
  variableId,
  allOptions,
  handleSubVariableChange
}) => {
  const totalResults = allOptions.length;
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  const totalPages = Math.ceil(totalResults / resultsPerPage);

  useEffect(() => {
    console.log(allOptions);
  }, []);

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  // Calculate the items to display on the current page
  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentItems = allOptions.slice(startIndex, endIndex);

  return (
    <div>
      <div className="pagination_navigation my-2">
        <label className="d-block d-sm-inline-block mx-1">
          Results Per Page:
        </label>
        <div className="d-block d-sm-inline-block">
          <button
            className={`btn btn-primary ${resultsPerPage === 10 ? "active" : ""} mx-1`}
            onClick={() => {
              setResultsPerPage(10);
            }}
          >
            10
          </button>
          <button
            className={`btn btn-primary ${resultsPerPage === 20 ? "active" : ""} mx-1`}
            onClick={() => {
              setResultsPerPage(20);
            }}
          >
            20
          </button>
          <button
            className={`btn btn-primary ${resultsPerPage === 50 ? "active" : ""} mx-1`}
            onClick={() => {
              setResultsPerPage(50);
            }}
          >
            50
          </button>
          <button
            className={`btn btn-primary ${resultsPerPage === 100 ? "active" : ""} mx-1`}
            onClick={() => {
              setResultsPerPage(100);
            }}
          >
            100
          </button>
        </div>
      </div>
      {currentItems.map((subVariable) => (
        <div className="row" key={`row_${subVariable.id}`}>
          <div className="col-12 col-lg-12 col-xl-5 text-break">
            <label className="form-label">{subVariable.name}</label>
          </div>
          <div className="col-12 col-lg-12 col-xl-7">
            <input
              className="form-control mb-2"
              type="text"
              key={`wsc_mapping_${variableId}_${subVariable.id}`}
              value={subVariable.wscVariable}
              placeholder="Enter WSC Code"
              onChange={(e) =>
                handleSubVariableChange(e, variableId, subVariable.id)
              }
            />
          </div>
        </div>
      ))}
      <div className="pagination_navigation d-flex justify-content-center align-items-center my-3">
        {currentPage > 1 && (
          <>
            <button className="btn btn-primary" onClick={handleFirstPage}>
              First
            </button>
          </>
        )}
        {pageNumbers.map((page) => (
          <button
            key={page}
            className={`btn btn-primary ${page === currentPage ? "active" : ""}`}
            onClick={() => handlePageClick(page)}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <>
            <button
              className="btn btn-primary"
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              Last
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default WSCMappingPagination;
