import React, { MouseEvent, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Bucket, BucketsNotifications } from "../../../types";
import { capitalizeFirstLetterOfEachWord } from "../../../utilities/utils";
import {
  CustomModal as ComplianceModal,
  CustomModal as NotificationModal
} from "../../Modal/custom-modal.component";

import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconBell, IconCheck } from "../../../assets/images/icons/icons";
import { usePermissions } from "../../../contexts/UserContext";
import {
  updateBucketCompliance,
  updateBucketNotification
} from "../../../models/bucket.model";
import { getSystemConfigPhraseCategoryIDByNameID } from "../../../utilities/config.util";
import SearchInput from "../../Inputs/search-input.component";
import "./histogram.styles.scss";

interface HoveredBucketInfo {
  bucket: Bucket;
  x: number;
  y: number;
}

type CategoryMap = {
  welcome: string;
  daily_reminder: string;
  non_compliance: string;
};

type BucketData = {
  name: string;
  actualBucketName: string;
  compliant: number;
  nonCompliant: number;
  bucketParticipants: string[];
  nonCompliantBucketParticipants: string[];
  compliantBucketParticipants: string[];
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
};

type StackedData = {
  "name": BucketData["name"];
  "actualBucketName": BucketData["actualBucketName"];
  "compliant": BucketData["compliant"] | undefined;
  "non-compliant": BucketData["nonCompliant"] | null | undefined;
  "nonCompliantBucketParticipants":
    | BucketData["nonCompliantBucketParticipants"]
    | null
    | undefined;
  "compliantBucketParticipants":
    | BucketData["compliantBucketParticipants"]
    | null
    | undefined;
};

interface TickPayload {
  value: string; // Assuming the payload contains a 'value' property of type string
  // Add other properties as needed based on your data structure
}

interface HistogramProps {
  buckets: Bucket[]; // Ensure this is an array of Bucket type
  notifications: BucketsNotifications | null;
  totalParticipants: number;
  setTabClicked?: (tab: string) => void;
}

const Histogram: React.FC<HistogramProps> = ({
  buckets,
  notifications,
  totalParticipants,
  setTabClicked
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [bucketData, setBucketData] = useState<Bucket | null>(null);
  const [hoveredBucketInfo, setHoveredBucketInfo] =
    useState<HoveredBucketInfo | null>(null); // Use for hovered buttons
  const [hoveredBucketName, setHoveredBucketName] = useState<string>(""); // Use for hovered buttons
  const [showComplianceModal, setShowComplianceModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [completes, setCompletes] = useState<number>(0);
  const [initialCompletes, setInitialCompletes] = useState<number>(0);
  const [minCompletes, setMinCompletes] = useState<number>(0);
  const [maxCompletes, setMaxCompletes] = useState<number>(0);
  const [completesError, setCompletesError] = useState<string>("");
  const [graceDays, setGraceDays] = useState<number>(0);
  const [initialGraceDays, setInitialGraceDays] = useState<number>(0);
  // const [notifications, setNotifications] = useState<any>({});
  const [tempWelcomePhrase, setTempWelcomePhrase] = useState("");
  const [tempReminderPhrase, setTempReminderPhrase] = useState("");
  const [tempNonCompliancePhrase, setTempNonCompliancePhrase] = useState("");
  const [welcomePhraseChanged, setWelcomePhraseChanged] =
    useState<boolean>(false);
  const [reminderPhraseChanged, setReminderPhraseChanged] =
    useState<boolean>(false);
  const [nonCompliantPhraseChanged, setNonCompliantPhraseChangedPhraseChanged] =
    useState<boolean>(false);
  // console.log(buckets);
  const [welcomeNotificationID, setWelcomeNotificationID] = useState<
    string | undefined | null
  >();
  const [reminderNotificationID, setReminderNotificationID] = useState<
    string | undefined | null
  >();
  const [NonComplianceNotificationID, setNonComplianceNotificationID] =
    useState<string | undefined | null>();

  const [removedWelcomeNotificationID, setRemovedWelcomeNotificationID] =
    useState(false);
  const [removedReminderNotificationID, setRemovedReminderNotificationID] =
    useState(false);
  const [
    removedNonComplianceNotificationID,
    setRemovedNonComplianceNotificationID
  ] = useState(false);

  const [initialWelcomeNotificationID, setInitialWelcomeNotificationID] =
    useState<string | undefined>();
  const [initialReminderNotificationID, setInitialReminderNotificationID] =
    useState<string | undefined>();
  const [
    initialNonComplianceNotificationID,
    setInitialNonComplianceNotificationID
  ] = useState<string | undefined>();
  const [leaveTimeoutId, setLeaveTimeoutId] = useState<number | null>(null);
  const [addPhraseCount, setAddPhraseCount] = useState(0);
  const [iconDisplayStates, setIconDisplayStates] = useState({
    searchInput1: false,
    searchInput2: false,
    searchInput3: false
  });

  const { clientID, studyID } = useParams();

  const { hasPermission } = usePermissions();

  const handleAddIconDisplay = (
    isDisplayed: boolean,
    searchInputId: string
  ) => {
    setIconDisplayStates((prevStates) => ({
      ...prevStates,
      [searchInputId]: isDisplayed
    }));
  };

  useEffect(() => {
    const count = Object.values(iconDisplayStates).filter(Boolean).length;
    setAddPhraseCount(count);
  }, [iconDisplayStates]);

  const getDisplayName = (bucketName: string) => {
    // Example mapping, modify as needed
    if (bucketName === "Completes") {
      return "Completed Total";
    }
    // Add more conditions for other specific names or transformations
    return capitalizeFirstLetterOfEachWord(bucketName);
  };

  const handleLabelMouseOver = (bucketName: string, event: MouseEvent) => {
    // Debug: Log to check if this function is called
    // console.log("Hovered over:", bucketName);

    // Clear any existing timeout
    if (leaveTimeoutId) {
      clearTimeout(leaveTimeoutId);
      setLeaveTimeoutId(null);
    }

    // console.log(bucketName);

    const hoveredBucket = buckets.find(
      (b: Bucket) =>
        (b.type === "single" ? b.survey_name : b.bucket)?.toLowerCase() ===
        bucketName.toLowerCase()
    );

    // Debug: Log to check if the bucket is found
    // console.log("Found bucket:", hoveredBucket);

    if (hoveredBucket && event.currentTarget) {
      const displayName = getDisplayName(bucketName);
      setHoveredBucketName(displayName); // Store the display name

      const labelRect = event.currentTarget.getBoundingClientRect();

      // Correctly calculate the Y position by adding the current scroll position
      let buttonX = labelRect.left - 50; // Adjust as needed
      let buttonY = labelRect.top + window.scrollY;

      setHoveredBucketInfo({
        bucket: hoveredBucket,
        x: buttonX,
        y: buttonY
      });
      console.log(hoveredBucket);
    } else {
      setHoveredBucketInfo(null);
    }
  };

  const renderCustomAxisTick = ({
    payload,
    x,
    y
  }: {
    payload: TickPayload;
    x: number;
    y: number;
  }) => {
    // Function to split text into lines
    const displayName = getDisplayName(payload.value);

    // Truncate the display name if it's too long
    const MAX_LABEL_LENGTH = 15;
    const displayText =
      displayName.length > MAX_LABEL_LENGTH
        ? `${displayName.substring(0, MAX_LABEL_LENGTH)}...`
        : displayName;

    return (
      <text
        onMouseOver={(e) => handleLabelMouseOver(payload.value, e)}
        onMouseLeave={handleLabelMouseLeave}
        x={x}
        y={y}
        dy={5}
        textAnchor="end"
        fill="#666"
      >
        {displayText}
      </text>
    );
  };

  // This is used to handle when the hovered buttons for each bar in the chart, will be hidden after being hovered over
  const handleLabelMouseLeave = () => {
    // Set a timeout to delay hiding the buttons
    const timeoutId = setTimeout(() => {
      setHoveredBucketInfo(null);
    }, 1500) as unknown as number; // 500ms delay, adjust as needed

    setLeaveTimeoutId(timeoutId);
  };

  //The handleCompliantBarClick and handleNonCompliantBarClick function calls the
  //saveToSessionStorageAndRedirect function and passes the bucket name and compliance status to the session storage.
  // CHANGE MADE - All bucket data passed in with default filter to either 'compliant' and 'non-compliant'
  const handleComplianceBarClick = (data: BucketData, compliance: string) => {
    // data contains type BucketData along with bar data
    saveToSessionStorageAndRedirect(
      data.actualBucketName,
      compliance,
      data.compliantBucketParticipants.concat(
        data.nonCompliantBucketParticipants
      )
    );
  };

  // const handleNonCompliantBarClick = (data: BucketData, index: number) => {
  //   // data contains type BucketData along with bar data
  //   console.log("Non Compliant Bar Clicked");
  //   console.log(data);
  //   saveToSessionStorageAndRedirect(
  //     data.actualBucketName,
  //     "non-compliant",
  //     data.nonCompliantBucketParticipants
  //   );
  // };

  const handleBarClick = (data: unknown, index: number) => {
    if (!data || typeof data !== "object") {
      console.log("Data is not an object");
      return;
    }
    console.log("Bar Clicked");
    console.log(data);

    if (!("name" in data) || typeof data.name !== "string") {
      console.log("Data does not contain 'name' property");
      return;
    }

    const bucket = data.name;
    let bucketName =
      bucket === "Non Compliant"
        ? "non-compliant"
        : bucket === "Completes"
          ? "completes"
          : bucket.toLowerCase();

    console.log(bucketName);

    let bucketParts: string[] = [];

    if (
      "bucketParticipants" in data &&
      Array.isArray(data.bucketParticipants) &&
      data.bucketParticipants.length > 0
    ) {
      bucketParts = data.bucketParticipants;
    }

    saveToSessionStorageAndRedirect(bucketName, bucketName, bucketParts);
  };

  // The saveToSessionStorageAndRedirect function saves the bucket name and compliance status to the session storage and redirects to the participants page
  const saveToSessionStorageAndRedirect = (
    bucket: string,
    complianceStatus: string,
    bucketParticipants?: string[]
  ) => {
    console.log("Save to session storage and redirect");
    console.log(bucket, complianceStatus, bucketParticipants);

    const filterData = {
      bucketName: bucket,
      complianceStatus: complianceStatus,
      bucketParticipants: bucketParticipants
    };

    // Check if the user has permission to view the participants page
    if (hasPermission("subject") || hasPermission("participant")) {
      sessionStorage.setItem("filterData", JSON.stringify(filterData));
      setTabClicked && setTabClicked("participants"); // Replace with your target page URL
    } else {
      Swal.fire({
        icon: "warning",
        title: "No Permission",
        text: `You do not have permission to view participants.`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  };

  // The handleNotificationClick function sets the bucket data and shows the notification modal
  const handleNotificationClick = (bucket: Bucket) => {
    setBucketData(bucket);
    setShowNotificationModal(true);

    // Directly access the 'id' property of 'welcome_notification' if it exists
    let localInitialWelcomeID =
      bucket.welcome_notification?.id?.toString() || null;

    if (localInitialWelcomeID) {
      setInitialWelcomeNotificationID(localInitialWelcomeID);
      setWelcomeNotificationID(localInitialWelcomeID);
    }

    // Assuming 'reminder_notifications' is similar to 'welcome_notification' and 'non_compliant_notification'
    // If 'reminder_notifications' does not exist in your data structure, this part should be adjusted or removed accordingly
    let localInitialReminderID =
      bucket.daily_notification?.id?.toString() || null;
    if (localInitialReminderID) {
      setInitialReminderNotificationID(localInitialReminderID);
      setReminderNotificationID(localInitialReminderID);
    }

    // Directly access the 'id' property of 'non_compliant_notification' if it exists
    let localInitialNonComplianceID =
      bucket.non_compliant_notification?.id?.toString() || null;
    if (localInitialNonComplianceID) {
      setInitialNonComplianceNotificationID(localInitialNonComplianceID);
      setNonComplianceNotificationID(localInitialNonComplianceID);
    }
  };

  // The handleCompletesChange function sets the completes value and checks if the value is within the min and max limits
  const handleCompletesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? +e.target.value : 0;
    if (value < minCompletes || value > maxCompletes) {
      // Replace minLimit and maxLimit with your actual limits
      setCompletesError(
        `Value must be between ${minCompletes} and ${maxCompletes}`
      );
    } else {
      setCompletesError("");
    }
    setCompletes(value);
  };

  // The handleComplianceClick function sets the bucket data and shows the compliance modal
  const handleComplianceClick = (bucket: Bucket) => {
    setBucketData(bucket);
    setShowComplianceModal(true);
  };

  // The updateCompliance function updates the compliance for the bucket
  const updateCompliance = async () => {
    if (
      clientID &&
      studyID &&
      bucketData &&
      completes >= minCompletes &&
      completes <= maxCompletes
    ) {
      const results = await updateBucketCompliance(
        clientID,
        bucketData.id,
        completes !== initialCompletes ? completes : undefined,
        graceDays !== initialGraceDays ? graceDays : undefined
      );
      if (results.rStatus === "success") {
        const success = await Swal.fire({
          icon: "success",
          title: "Success",
          text: "Bucket compliance has updated successfully.",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        setInitialCompletes(completes);
        setInitialGraceDays(graceDays);

        if (success.isConfirmed) {
          setShowComplianceModal(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Failed to update compliance. ${results?.rError || ""}`,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
      }
      setShowComplianceModal(false);
      setHoveredBucketName("");
    }
  };

  async function checkAndAddTimes(): Promise<{
    timeReminder: string | undefined;
    timeNonCompliance: string | undefined;
  }> {
    console.log("Check and add times");
    let timeReminder = undefined;
    let timeNonCompliance = undefined;

    const validateTime = (value: string) => {
      // Simple validation for HH:MM format
      const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
      if (!value) {
        return "You need to add a time!";
      } else if (!timePattern.test(value)) {
        return "Please enter a valid time in HH:MM format";
      }
    };

    if (
      !initialReminderNotificationID &&
      reminderNotificationID !== initialReminderNotificationID
    ) {
      console.log("Add time for reminder notification");
      const { value: time } = await Swal.fire({
        title: "Add time for Daily Reminder Notification",
        input: "text",
        inputLabel: "Time (24hr format)",
        inputPlaceholder: "Enter time (HH:MM)",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Add",
        inputValidator: validateTime
      });

      timeReminder = time;
    }

    if (
      !initialNonComplianceNotificationID &&
      NonComplianceNotificationID !== initialNonComplianceNotificationID
    ) {
      console.log("Add time for non compliance notification");
      const { value: time } = await Swal.fire({
        title: "Add time for Non Compliance Notification",
        input: "text",
        inputLabel: "Time (24hr format)",
        inputPlaceholder: "Enter time (HH:MM)",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Add",
        inputValidator: validateTime
      });

      timeNonCompliance = time;
    }

    return { timeReminder, timeNonCompliance };
  }

  // Warn client of removal of notifications
  const notificationRemovalCheck = async (phrases: string[]) => {
    let textString = "";

    if (phrases.length === 1) {
      textString = phrases[0];
    } else if (phrases.length === 2) {
      textString = phrases.join(" and ");
    } else if (phrases.length > 2) {
      textString =
        phrases.slice(0, -1).join(", ") + ", and " + phrases.slice(-1);
    }

    const result = await Swal.fire({
      icon: "warning",
      title: "Notification Removal",
      html: `${textString} will be removed.<br><b>Do you want to proceed?</b></br><br>If not, please add in notifications</br>`,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#3085d6",
      showDenyButton: true,
      denyButtonText: "No",
      denyButtonColor: "#d33",
      allowOutsideClick: false
    });

    return result.isDenied ? false : true;
  };

  // The updateNotification function updates the notification for the bucket
  const updateNotification = async () => {
    if (addPhraseCount > 0) {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: `New notification phrase(s) have not been added.`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });

      return;
    }

    let removeWelcome = false;
    let removeReminder = false;
    let removeNonCompliant = false;

    //Check for notifications that had input field changes, but were not added on
    const phrases = [];
    if (welcomePhraseChanged) {
      phrases.push("Welcome Notifications");
    }
    if (reminderPhraseChanged) {
      phrases.push("Reminder Notifications");
    }
    if (nonCompliantPhraseChanged) {
      phrases.push("Non-Compliant Notifications");
    }
    // Display Alert for user to confirm / deny notifications removal
    const continueExecution =
      phrases.length > 0 ? await notificationRemovalCheck(phrases) : true;
    if (!continueExecution) {
      return;
    } else {
      if (welcomePhraseChanged) removeWelcome = true;
      if (reminderPhraseChanged) removeReminder = true;
      if (nonCompliantPhraseChanged) removeNonCompliant = true;
    }

    if (phrases.length === 0) {
      if (removedWelcomeNotificationID) {
        setWelcomeNotificationID(null);
      }

      if (removedReminderNotificationID) {
        setReminderNotificationID(null);
      }

      if (removedNonComplianceNotificationID) {
        setNonComplianceNotificationID(null);
      }
    }

    if (clientID && studyID && bucketData) {
      const times = await checkAndAddTimes();
      console.log(times);

      const results = await updateBucketNotification(
        clientID,
        bucketData.id,
        removeWelcome
          ? "none"
          : welcomeNotificationID !== initialWelcomeNotificationID
            ? welcomeNotificationID
            : undefined,
        removeReminder
          ? "none"
          : reminderNotificationID !== initialReminderNotificationID
            ? reminderNotificationID
            : undefined,
        removeNonCompliant
          ? "none"
          : NonComplianceNotificationID !== initialNonComplianceNotificationID
            ? NonComplianceNotificationID
            : undefined,
        times.timeReminder,
        times.timeNonCompliance
      );
      if (results.rStatus === "success") {
        const success = await Swal.fire({
          icon: "success",
          title: "Success",
          text: "Notifications updated successfully.",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });

        if (success.isConfirmed) {
          setShowNotificationModal(false);
          setHoveredBucketName("");
          setAddPhraseCount(0);
          setInitialWelcomeNotificationID(
            welcomeNotificationID ? welcomeNotificationID : undefined
          );
          setInitialReminderNotificationID(
            reminderNotificationID ? reminderNotificationID : undefined
          );
          setInitialNonComplianceNotificationID(
            NonComplianceNotificationID
              ? NonComplianceNotificationID
              : undefined
          );

          setTempWelcomePhrase("");
          setTempReminderPhrase("");
          setTempNonCompliancePhrase("");

          Swal.fire({
            icon: "info",
            text: "New Data Loaded. Reload Page?",
            showConfirmButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#3085d6",
            showDenyButton: true,
            denyButtonText: "No",
            denyButtonColor: "#d33",
            // toast: true,
            // position: "top-right",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${results.rError || "Failed to update notifications."}`,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update notifications. Data is missing.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }

    setWelcomePhraseChanged(false);
    setReminderPhraseChanged(false);
    setNonCompliantPhraseChangedPhraseChanged(false);
  };

  const stackedTypes = ["bucket", "bonus", "single"];
  const standaloneTypes = ["compliant", "non-compliant", "completes"];

  // Sorting function based on the order defined in stackedTypes
  const sortBuckets = (a: Bucket, b: Bucket) => {
    const orderA = stackedTypes.indexOf(a.type);
    const orderB = stackedTypes.indexOf(b.type);
    return orderA - orderB;
  };

  const stackedData: StackedData[] = buckets
    .sort(sortBuckets)
    .filter(
      (bucket) =>
        stackedTypes.includes(bucket.type) && bucket.type !== "pending"
    )
    .map((bucket: Bucket) => ({
      "name":
        bucket.type === "single"
          ? capitalizeFirstLetterOfEachWord(bucket?.survey_name || "")
          : capitalizeFirstLetterOfEachWord(bucket.bucket),
      "actualBucketName": bucket.bucket,
      "compliant": bucket.compliant_participants_count,
      "non-compliant": bucket.non_compliant_participants_count,
      "compliantBucketParticipants": bucket.bucket_participants_compliant,
      "nonCompliantBucketParticipants": bucket.bucket_participants_non_compliant
    }));

  const standaloneData = buckets
    .filter(
      (bucket) =>
        standaloneTypes.includes(bucket.type) && bucket.type !== "pending"
    )
    .map((bucket) => ({
      name: capitalizeFirstLetterOfEachWord(bucket.bucket),
      total: bucket.total_participants_count,
      bucketParticipants: bucket.bucket_participants_non_compliant
    }));

  const getMaxCount = (data: StackedData[]) => {
    return Math.max(
      ...data.map(
        (item: StackedData) =>
          (item.compliant || 0) + (item["non-compliant"] || 0)
      )
    );
  };

  const maxCountStacked = getMaxCount(stackedData);

  const categoryMap: CategoryMap = {
    welcome:
      bucketData?.type === "single"
        ? "notify-welcome-single"
        : "notify-welcome-diary",
    daily_reminder:
      bucketData?.type === "single"
        ? "notify-daily-single"
        : "notify-daily-diary",
    non_compliance:
      bucketData?.type === "single"
        ? "notify-non_compliant-single"
        : "notify-non_compliant-diary"
  };

  type PhraseCategoryID = {
    welcome_category_id: string;
    daily_category_id: string;
    non_compliance_category_id: string;
  };

  const [phraseCategoryID, setPhraseCategoryID] = useState<PhraseCategoryID>({
    welcome_category_id: "",
    daily_category_id: "",
    non_compliance_category_id: ""
  });
  const [loadingPhraseCategoryID, setLoadingPhraseCategoryID] =
    useState<boolean>(true);

  useEffect(() => {
    setLoadingPhraseCategoryID(true);
    async function getPhraseCategoryID() {
      if (bucketData) {
        const phraseCategoryID = {
          welcome_category_id:
            bucketData.type === "single"
              ? (await getSystemConfigPhraseCategoryIDByNameID(
                  "notify-welcome-single"
                )) || ""
              : (await getSystemConfigPhraseCategoryIDByNameID(
                  "notify-welcome-diary"
                )) || "",
          daily_category_id:
            bucketData.type === "single"
              ? (await getSystemConfigPhraseCategoryIDByNameID(
                  "notify-daily-single"
                )) || ""
              : (await getSystemConfigPhraseCategoryIDByNameID(
                  "notify-daily-diary"
                )) || "",
          non_compliance_category_id:
            bucketData.type === "single"
              ? (await getSystemConfigPhraseCategoryIDByNameID(
                  "notify-non_compliant-single"
                )) || ""
              : (await getSystemConfigPhraseCategoryIDByNameID(
                  "notify-non_compliant-diary"
                )) || ""
        };
        // console.log(phraseCategoryID);
        // if welcome_category_id, daily_category_id or non_compliance_category_id is undefined or null, return out of the function
        if (
          !phraseCategoryID.welcome_category_id ||
          phraseCategoryID.welcome_category_id === "" ||
          phraseCategoryID.welcome_category_id === "notFound" ||
          !phraseCategoryID.daily_category_id ||
          phraseCategoryID.daily_category_id === "" ||
          phraseCategoryID.daily_category_id === "notFound" ||
          !phraseCategoryID.non_compliance_category_id ||
          phraseCategoryID.non_compliance_category_id === "" ||
          phraseCategoryID.non_compliance_category_id === "notFound"
        ) {
          // console.log("phraseCategoryID: ", phraseCategoryID);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to update notifications. Please make sure that the phrase category IDs are set up correctly in the system config.`,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6"
          });
        }
        return phraseCategoryID;
      }
    }

    async function fetchAndSetPhraseCategoryID() {
      const phraseID = await getPhraseCategoryID();
      if (phraseID) {
        setPhraseCategoryID(phraseID);
      }
    }

    fetchAndSetPhraseCategoryID();
    setLoadingPhraseCategoryID(false);
  }, [bucketData && bucketData.type]); // Add any dependencies if necessary

  useEffect(() => {
    if (!bucketData) return;
    setCompletes(bucketData.compliant_completes);
    setInitialCompletes(bucketData.compliant_completes);
    setGraceDays(bucketData.compliant_grace_days);
    setInitialGraceDays(bucketData.compliant_grace_days);
    setMinCompletes(bucketData.min_completes);
    setMaxCompletes(bucketData.max_completes);
  }, [bucketData]);

  // const MAX_CONTAINER_HEIGHT = 600; // Maximum height of the chart container

  const BAR_HEIGHT = 50; // Static height for each bar
  const GAP_BETWEEN_BARS = 10; // Static gap between bars
  const MARGIN_TOP_BOTTOM = 40; // Total additional margin for the top and bottom of the chart

  const MIN_CHART_HEIGHT = 200; // Minimum height for the chart
  const MAX_CHART_HEIGHT = 1000; // Maximum height for the chart

  // Calculate the total chart height
  let chartHeight =
    stackedData.length > 0
      ? stackedData.length * (BAR_HEIGHT + GAP_BETWEEN_BARS) + MARGIN_TOP_BOTTOM
      : MIN_CHART_HEIGHT; // Use minimum height for empty data

  let standaloneChartHeight =
    standaloneData.length > 0
      ? standaloneData.length * (BAR_HEIGHT + GAP_BETWEEN_BARS) +
        MARGIN_TOP_BOTTOM
      : MIN_CHART_HEIGHT; // Use minimum height for empty data
  // Constrain the chart height to be within min and max bounds
  chartHeight = Math.min(chartHeight, MAX_CHART_HEIGHT);

  // Constrain the chart height to be within min and max bounds for standalone bars
  standaloneChartHeight = Math.min(standaloneChartHeight, MAX_CHART_HEIGHT);

  return (
    <div className="histogram-container">
      <ResponsiveContainer width="100%" height={chartHeight}>
        <BarChart
          layout="vertical"
          data={stackedData}
          barCategoryGap={GAP_BETWEEN_BARS}
          margin={{ top: 20, right: 100, left: 20, bottom: 5 }}
        >
          <CartesianGrid stroke="none" />
          <XAxis type="number" domain={[0, maxCountStacked]} hide={true} />
          <YAxis
            type="category"
            dataKey="name"
            width={140}
            tick={renderCustomAxisTick}
          />
          <Tooltip />
          <Bar
            dataKey="compliant"
            fill="#82ca9d"
            stackId="a"
            style={{ cursor: "pointer" }}
            onClick={(data, index) =>
              handleComplianceBarClick(data, "compliant")
            } // Use for bar click settings
          />
          <Bar
            dataKey="non-compliant"
            fill="#d88484"
            stackId="a"
            style={{ cursor: "pointer" }}
            onClick={(data, index) =>
              handleComplianceBarClick(data, "non-compliant")
            } // Use for bar click settings
          />
        </BarChart>
      </ResponsiveContainer>
      {/* Standalone Bar Chart */}
      <ResponsiveContainer width="100%" height={standaloneChartHeight}>
        <BarChart
          layout="vertical"
          data={standaloneData}
          barCategoryGap={GAP_BETWEEN_BARS}
          margin={{ top: 10, right: 100, left: 20, bottom: 5 }}
        >
          <CartesianGrid stroke="none" />
          <XAxis type="number" domain={[0, totalParticipants]} />
          <YAxis
            type="category"
            dataKey="name"
            width={140}
            tick={renderCustomAxisTick}
          />
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="total"
            fill="#5892ff"
            style={{ cursor: "pointer" }}
            onClick={(data, index) => {
              handleBarClick(data, index);
            }}
          />
        </BarChart>
      </ResponsiveContainer>

      {(hasPermission("subject") || hasPermission("bucket", "write")) &&
        hoveredBucketInfo && (
          <div
            className="bar-hover-buttons"
            style={{
              left: `${hoveredBucketInfo.x}px`,
              top: `${hoveredBucketInfo.y}px`
            }}
          >
            {hoveredBucketInfo.bucket.type !== "single" &&
            !standaloneTypes.includes(hoveredBucketInfo.bucket.type) ? (
              <>
                {/* If IconCheck is displayed, render both icons */}
                <div
                  onClick={() =>
                    handleNotificationClick(hoveredBucketInfo.bucket)
                  }
                >
                  <IconBell style={{ height: "25px" }} />
                </div>
                <div
                  onClick={() =>
                    handleComplianceClick(hoveredBucketInfo.bucket)
                  }
                >
                  <IconCheck style={{ height: "25px" }} />
                </div>
              </>
            ) : (
              !standaloneTypes.includes(hoveredBucketInfo.bucket.type) && (
                <>
                  {/* If IconCheck is not displayed, render only IconBell */}
                  <div
                    onClick={() =>
                      handleNotificationClick(hoveredBucketInfo.bucket)
                    }
                  >
                    <IconBell style={{ height: "25px", marginTop: "10px" }} />
                  </div>
                </>
              )
            )}
          </div>
        )}
      {showComplianceModal && (
        <ComplianceModal
          shown={showComplianceModal}
          closeModal={() => setShowComplianceModal(false)}
          title={`Update Compliance for ${hoveredBucketName}`}
          modalSize=""
          tooltipText="Update the number of completes and grace days for this bucket."
        >
          <div className="d-flex mb-3 flex-column">
            <div className="col notification-col">
              <label htmlFor="completeDays" className="form-label">
                Completes
              </label>
              <input
                type="text"
                className="form-control text-center"
                id="completeDays"
                value={completes ? completes : 0}
                onChange={handleCompletesChange}
              />
              {completesError && (
                <small className="mt-2 text-danger">{completesError}</small>
              )}
            </div>
            <div className="col notification-col">
              <label htmlFor="graceDays" className="form-label">
                Grace Days
              </label>
              <input
                type="text"
                className="form-control text-center"
                id="graceDays"
                value={graceDays ? graceDays : 0}
                onChange={(e) =>
                  setGraceDays(e.target.value ? +e.target.value : 0)
                }
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={updateCompliance}
              disabled={
                loadingPhraseCategoryID ||
                (completes === initialCompletes &&
                  graceDays === initialGraceDays)
              }
            >
              Update Compliance
            </button>
          </div>
        </ComplianceModal>
      )}

      {showNotificationModal && (
        <>
          <NotificationModal
            shown={showNotificationModal}
            closeModal={() => setShowNotificationModal(false)}
            title={`Update Notifications for ${hoveredBucketName}`}
            modalSize=""
            tooltipText="Update the notifications for this bucket."
          >
            <div className="d-flex mb-3 flex-column">
              {bucketData &&
                bucketData.bucket?.toUpperCase() !== "NON COMPLIANT" && (
                  <div className="col notification-col">
                    <SearchInput
                      label="Welcome Notifications"
                      initialOptions={
                        tempWelcomePhrase ||
                        (bucketData && bucketData.welcome_notification?.phrase)
                      }
                      category={phraseCategoryID.welcome_category_id}
                      allOptions={
                        notifications && categoryMap["welcome"] in notifications
                          ? notifications[
                              categoryMap[
                                "welcome"
                              ] as keyof BucketsNotifications
                            ]
                          : []
                      }
                      onSelectOption={(
                        phraseID: string | null,
                        phrase: string
                      ) => {
                        // Here, handle the selected option's notification_id
                        if (phraseID === null && phrase === "") {
                          setWelcomeNotificationID(null);
                          setTempWelcomePhrase("");
                          setRemovedWelcomeNotificationID(true);
                        } else {
                          setWelcomeNotificationID(phraseID);
                          setTempWelcomePhrase(phrase);
                          setWelcomePhraseChanged(false);
                        }
                      }}
                      onAddIconDisplay={handleAddIconDisplay}
                      searchInputId="input1"
                      placeholder="Search Welcome Notifications"
                      setValueChanged={setWelcomePhraseChanged}
                    />
                  </div>
                )}
              {bucketData &&
                bucketData.type?.toUpperCase() !== "SINGLE" &&
                bucketData.bucket?.toUpperCase() !== "COMPLETES" &&
                bucketData.bucket?.toUpperCase() !== "NON COMPLIANT" &&
                bucketData.bucket?.toUpperCase() !== "COMPLIANT" && (
                  <div className="col notification-col">
                    <SearchInput
                      label="Reminder Notifications"
                      initialOptions={
                        tempReminderPhrase ||
                        bucketData.daily_notification?.phrase
                      }
                      category={phraseCategoryID.daily_category_id}
                      allOptions={
                        notifications &&
                        categoryMap["daily_reminder"] in notifications
                          ? notifications[
                              categoryMap[
                                "daily_reminder"
                              ] as keyof BucketsNotifications
                            ]
                          : []
                      }
                      onSelectOption={(
                        phraseID: string | null,
                        phrase: string
                      ) => {
                        // Here, handle the selected option's notification_id
                        if (phraseID === null && phrase === "") {
                          setReminderNotificationID(null);
                          setTempReminderPhrase("");
                          setRemovedReminderNotificationID(true);
                        } else {
                          setReminderNotificationID(phraseID);
                          setTempReminderPhrase(phrase);
                          setReminderPhraseChanged(false);
                        }
                      }}
                      onAddIconDisplay={handleAddIconDisplay}
                      searchInputId="input2"
                      placeholder="Search Daily Reminders"
                      setValueChanged={setReminderPhraseChanged}
                    />
                  </div>
                )}
              {bucketData &&
                bucketData.bucket?.toUpperCase() !== "COMPLETES" &&
                bucketData.bucket?.toUpperCase() !== "COMPLIANT" && (
                  <div className="col notification-col">
                    <SearchInput
                      label="Non Compliant Notification"
                      initialOptions={
                        tempNonCompliancePhrase ||
                        bucketData.non_compliant_notification?.phrase
                      }
                      category={phraseCategoryID.non_compliance_category_id}
                      allOptions={
                        notifications &&
                        categoryMap["non_compliance"] in notifications
                          ? notifications[
                              categoryMap[
                                "non_compliance"
                              ] as keyof BucketsNotifications
                            ]
                          : []
                      }
                      onSelectOption={(
                        phraseID: string | null,
                        phrase: string
                      ) => {
                        // Here, handle the selected option's notification_id
                        if (phraseID === null && phrase === "") {
                          setNonComplianceNotificationID(null);
                          setTempNonCompliancePhrase("");
                          setRemovedNonComplianceNotificationID(true);
                        } else {
                          setNonComplianceNotificationID(phraseID);
                          setTempNonCompliancePhrase(phrase);
                          setNonCompliantPhraseChangedPhraseChanged(false);
                        }
                      }}
                      onAddIconDisplay={handleAddIconDisplay}
                      searchInputId="input3"
                      placeholder="Search Non Compliance Notifications"
                      setValueChanged={
                        setNonCompliantPhraseChangedPhraseChanged
                      }
                    />
                  </div>
                )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={updateNotification}
                disabled={
                  (!welcomePhraseChanged ||
                    !reminderPhraseChanged ||
                    !nonCompliantPhraseChanged) &&
                  (loadingPhraseCategoryID ||
                    (welcomeNotificationID === initialWelcomeNotificationID &&
                      reminderNotificationID ===
                        initialReminderNotificationID &&
                      NonComplianceNotificationID ===
                        initialNonComplianceNotificationID))
                }
              >
                Update Notifications
              </button>
            </div>
          </NotificationModal>
        </>
      )}
    </div>
  );
};

export default Histogram;
