// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#timezone_switcher {
  display: inline-block;
}
@media screen and (max-width: 915px) {
  #timezone_switcher {
    display: block;
    margin-top: 10px;
  }
  #timezone_switcher .btn {
    font-size: 0.9rem !important;
    padding: 0.2rem 0.5rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/TimeZoneSwitcher/timezoneSwitcher.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;AADF;AAGE;EAHF;IAII,cAAA;IACA,gBAAA;EAAF;EACE;IACE,4BAAA;IACA,iCAAA;EACJ;AACF","sourcesContent":["@use \"../../../global.scss\" as *;\n\n#timezone_switcher {\n  display: inline-block;\n\n  @media screen and (max-width: 915px) {\n    display: block;\n    margin-top: 10px;\n    .btn {\n      font-size: 0.9rem !important;\n      padding: 0.2rem 0.5rem !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
