// This Component will be For Message Templates , We will have a Title Message Templates then a underline  which will only be 90 width in the middle , Then we will have a row for our filters which Will have a Search Input field and a create new template button which will open a modal with a form to create a new template.
//The the table will be under the filters and will have the following columns: Template Id , Template Name . Priview , Available langues.And a Action row which should have a delete or edit template button.
import React from "react";
import "./templates.scss";

interface templatesProps {
  studyClicked: string;
}

const Templates: React.FC<templatesProps> = () => {
  return (
    <div className="template-container">
      <div>Under Construction</div>
      <img
        src="/images/placeholders/No_Data_Found.png"
        alt="Under Construction"
      />
    </div>
  );
};

export default Templates;
