import React from "react";
import { useNavigate } from "react-router-dom";
import { SidebarDataSurvey } from "../../types.ts";

interface SidebarLevelFourProps {
  sidebarDisplayData: SidebarDataSurvey[];
  hoveredClientID: string;
  showLevel: boolean;
  hoveredStudyID: string;
  currentSurveyID: string;
}

const SidebarLevelFour: React.FC<SidebarLevelFourProps> = ({
  sidebarDisplayData,
  showLevel,
  hoveredClientID,
  hoveredStudyID,
  currentSurveyID
}) => {
  const navigate = useNavigate();

  function handleClientIconClick(ID: string) {
    console.log("handleClientIconClick");
    navigate(`/clients/${hoveredClientID}/${hoveredStudyID}/${ID}`);
  }

  return (
    <div
      id="sidebarLevelFour"
      className={`sidebarLevel ${showLevel ? "show" : "hide"}`}
    >
      {sidebarDisplayData.length === 0 ? (
        <p>No Survey</p>
      ) : (
        /* map though all sidebarDisplayData and display all clientNames */
        sidebarDisplayData.map((survey: SidebarDataSurvey) => (
          <button
            key={survey.surveyID}
            className={`sidebar_button clickable button_client_logo${
              currentSurveyID === survey.surveyID ? " selected" : ""
            }`}
            onClick={() =>
              handleClientIconClick(survey.surveyID ? survey.surveyID : "")
            }
            data-toggle="tooltip"
            title={survey.surveyName ? survey.surveyName : ""}
            style={{
              backgroundImage: `url(${
                survey.surveyPicture
                  ? survey.surveyPicture
                  : "/images/placeholders/no-image.png"
              })`
            }}
          ></button>
        ))
      )}
    </div>
  );
};

export default SidebarLevelFour;
