interface AddClientWizardFooterProps {
  currentPage: number;
  totalPages: number;
  handleBack: () => void;
  handleSubmit: () => void;
}

const AddClientWizardFooter: React.FC<AddClientWizardFooterProps> = ({
  currentPage,
  totalPages,
  handleBack,
  handleSubmit
}) => {
  return (
    <div className="footer">
      {currentPage > 1 ? (
        <div className="mb-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleBack()}
          >
            Back
          </button>
        </div>
      ) : (
        <div className="mb-3" style={{ visibility: "hidden" }}>
          <button className="btn btn-primary" type="button" disabled>
            Placeholder
          </button>
        </div>
      )}
      <div className="mb-3">
        {currentPage < totalPages && (
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleSubmit()}
          >
            {currentPage}/{totalPages} Next
          </button>
        )}
        {currentPage === totalPages && (
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default AddClientWizardFooter;
