import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import "./App.scss";
import Automation from "./components/StudySurveyTabSingle/Automation/automation.component";
import AddClientPage from "./pages/AddClient/addClient.page";
import AddStudyPage from "./pages/AddStudy/addStudy.page";
import AddSurveyPage from "./pages/AddSurvey/addSurvey.page";
import AdminPage from "./pages/Admin/admin.page";
import ViewAllClientsPage from "./pages/Clients/viewAllClients.page";
import ConfigPage from "./pages/Config/config.page";
import ErrorPage from "./pages/Error/error.page";
import LanguagesPage from "./pages/Languages/languages.page";
import LeadsPage from "./pages/Leads/leads.page";
import LoggingInPage from "./pages/LoggingIn/loggingIn.page";
import LoginPage from "./pages/Login/login.page";
import ParticipantResetPasswordPage from "./pages/ParticipantResetPassword/participantResetPassword.page";
import ProfilePage from "./pages/Profile/profile.page";
import ReportsPage from "./pages/Reports/reports.page";
import ViewAllStudiesPage from "./pages/Studies/viewAllStudies.page";
import StudyPage from "./pages/Study/study.page";
import SurveyPage from "./pages/Survey/survey.page";
import TimezonesPage from "./pages/Timezones/timezones.page";
import IconPageHandler from "./routes/IconPageHandler/iconPageHandler";

import { ProtectedRoute } from "./utilities/ProtectedRoute";
// LanguagesDisplayPage
import SingleAppConfigPage from "./pages/AppConfig/singleAppConfig.page";
import CategoriesDisplayPage from "./pages/CategoriesDisplay/categoriesDisplay.page";
import CountriesDisplayPage from "./pages/CountriesDisplay/countriesDisplay.page";
import LanguagesDisplayPage from "./pages/LanguagesDisplay/languagesDisplay.page";

const TitleUpdater = ({ title }: { title?: string }) => {
  // Updates the page title based on the current route
  useEffect(() => {
    if (title) {
      document.title = title + " | MDI RMS";
    } else {
      document.title = "MDI RMS";
    }
  }, [title]);
  return null;
};

function App() {
  // handle the pinning of the sidebar
  // store the pinned state of the sidebar in local storage
  // initially set the sidebar to be pinned
  // if the sidebar is pinned, it will be displayed
  // if the sidebar is unpinned, it will be hidden
  // if the sidebar is unpinned, the sidebar will be displayed when the mouse hovers over the sidebar
  // if the sidebar is unpinned, the sidebar will be hidden when the mouse leaves the sidebar

  const sidebarPinnedLocalStorage = localStorage.getItem("sidebarPinned");
  const initialSidebarPinned =
    sidebarPinnedLocalStorage !== null
      ? sidebarPinnedLocalStorage === "true"
      : true;

  const [sidebarPinned, setSidebarPinned] = useState(initialSidebarPinned);

  const toggleSidebarPinned = () => {
    const newSidebarPinnedState = !sidebarPinned;
    setSidebarPinned(newSidebarPinnedState);
    localStorage.setItem("sidebarPinned", String(newSidebarPinnedState));
  };

  return (
    <div
      className={`App app_${
        sidebarPinned ? "sidebar_pinned" : "sidebar_not_pinned"
      }`}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/clients" replace />} />
          <Route
            path="/login"
            element={
              <>
                <TitleUpdater title="Login" />
                <LoginPage />
              </>
            }
          />
          <Route
            path="/auth/:jwt?"
            element={
              <>
                <TitleUpdater title="Logging in" />
                <LoggingInPage />
              </>
            }
          />

          <Route
            path="/test"
            element={
              <>
                <TitleUpdater title="Test" />
                <Automation />
              </>
            }
          />
          <Route
            path="/reset-pass/:token?"
            element={
              <>
                <TitleUpdater title="Reset Password" />
                <ParticipantResetPasswordPage />
              </>
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/profile"
              element={
                <>
                  <TitleUpdater title="Profile Settings" />
                  <ProfilePage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="profile"
                  />
                </>
              }
            />
            <Route
              path="/clients"
              element={
                <>
                  <TitleUpdater title="Clients" />
                  <ViewAllClientsPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="clients"
                  />
                </>
              }
            />
            <Route
              path="/reports"
              element={
                <>
                  <TitleUpdater title="Global Reports" />
                  <ReportsPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="reports"
                  />
                </>
              }
            />
            <Route
              path="/clientAdd"
              element={
                <>
                  <TitleUpdater title="Clients" />
                  <AddClientPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="clients"
                  />
                </>
              }
            />
            <Route
              path="/clients/:clientID"
              element={
                <>
                  <TitleUpdater title="Client" />
                  <ViewAllStudiesPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="client"
                  />
                </>
              }
            />
            <Route
              path="/clients/:clientID/reports"
              element={
                <>
                  <TitleUpdater title="Reports" />
                  <ReportsPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="client"
                  />
                </>
              }
            />
            <Route
              path="/phraseLibrary"
              element={
                <>
                  <TitleUpdater title="Phrase Library" />
                  <LanguagesPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="phraseLibrary"
                  />
                </>
              }
            />
            <Route
              path="/leads"
              element={
                <>
                  <TitleUpdater title="leads" />
                  <LeadsPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="leads"
                  />
                </>
              }
            />
            <Route
              path="/admin"
              element={
                <>
                  <TitleUpdater title="Admin" />
                  <AdminPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="admin"
                  />
                </>
              }
            />
            {/* AddStudyPage */}
            <Route
              path="/clients/:clientID/create-study"
              element={
                <>
                  <TitleUpdater title="Create Study" />
                  <AddStudyPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="client"
                  />
                </>
              }
            />
            <Route
              path="/clients/:clientID/:studyID"
              element={
                <>
                  <TitleUpdater title="Study" />
                  <StudyPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="study"
                  />
                </>
              }
            />
            <Route
              path="/clients/:clientID/:studyID/create-survey"
              element={
                <>
                  <TitleUpdater title="Create Survey" />
                  <AddSurveyPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="study"
                  />
                </>
              }
            />
            <Route
              path="/clients/:clientID/:studyID/:surveyID"
              element={
                <>
                  <TitleUpdater title="Survey" />
                  <SurveyPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="survey"
                  />
                </>
              }
            />
            <Route
              path="/client/:pageHandler/:clientID"
              element={
                <>
                  <TitleUpdater title={`Client`} />
                  <IconPageHandler
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="client"
                  />
                </>
              }
            />
            <Route
              path="/study/:pageHandler/:studyID"
              element={
                <>
                  <TitleUpdater title={`Study`} />
                  <IconPageHandler
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="study"
                  />
                </>
              }
            />
            <Route
              path="/survey/:pageHandler/:surveyID"
              element={
                <>
                  <TitleUpdater title={`Survey`} />
                  <IconPageHandler
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="survey"
                  />
                </>
              }
            />
            <Route
              path="/timezones"
              element={
                <>
                  <TitleUpdater title={`Timezones`} />
                  <TimezonesPage />
                </>
              }
            />
            <Route
              path="/languages"
              element={
                <>
                  <TitleUpdater title={`Languages`} />
                  <LanguagesDisplayPage />
                </>
              }
            />
            <Route
              path="/categories"
              element={
                <>
                  <TitleUpdater title={`Categories`} />
                  <CategoriesDisplayPage />
                </>
              }
            />
            <Route
              path="/countries"
              element={
                <>
                  <TitleUpdater title={`Countries`} />
                  <CountriesDisplayPage />
                </>
              }
            />
            <Route
              path="/config"
              element={
                <>
                  <TitleUpdater title={`Config`} />
                  <ConfigPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="config"
                  />
                </>
              }
            />
            <Route
              path="/config/new"
              element={
                <>
                  <TitleUpdater title={`Config`} />
                  <SingleAppConfigPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="config"
                    appEditType="new"
                  />
                </>
              }
            />
            <Route
              path="/config/new/:appID"
              element={
                <>
                  <TitleUpdater title={`Config`} />
                  <SingleAppConfigPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="config"
                    appEditType="copy"
                  />
                </>
              }
            />
            <Route
              path="/config/:appID"
              element={
                <>
                  <TitleUpdater title={`App`} />
                  <SingleAppConfigPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="config"
                    appEditType="update"
                  />
                </>
              }
            />
            {/* <Route
              path="/bulk-action/:job_ID/:action"
              element={
                <>
                  <TitleUpdater title={`Bulk Confirmation`} />
                  <BulkActionPage
                    sidebarPinned={sidebarPinned}
                    onSidebarPinChange={toggleSidebarPinned}
                    page="bulkAction"
                  />
                </>
              }
            /> */}
            <Route
              path="/404"
              element={
                <>
                  <TitleUpdater title="Error" />
                  <ErrorPage />
                </>
              }
            />
            <Route
              path="/404/:error"
              element={
                <>
                  <TitleUpdater title="Error" />
                  <ErrorPage />
                </>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <TitleUpdater title="Error" />
                  <ErrorPage />
                </>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
