// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: inherit;
  padding: 0;
  overflow-y: hidden;
}
.tab-container .tab-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #7c7c7c;
  font-weight: 600;
  border-bottom: 2px solid rgba(31, 143, 199, 0.4431372549);
  cursor: pointer;
}
.tab-container .tab-header .tab-header-item {
  position: relative;
  margin-right: 10px;
}
.tab-container .tab-header .tab-header-item .tab-title {
  font-size: 18px;
}
.tab-container .tab-header .tab-header-active::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  right: 0px;
  height: 2px;
  background-color: #1f8ec7;
}
.tab-container .tab-content {
  width: 100%;
  min-height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/StudySurveyTabSingle/Messages/messages.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,yDAAA;EACA,eAAA;AACJ;AACI;EACE,kBAAA;EACA,kBAAA;AACN;AACM;EACE,eAAA;AACR;AAGI;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,yBAAA;AADN;AAKE;EACE,WAAA;EACA,iBAAA;AAHJ","sourcesContent":[".tab-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  min-height: inherit;\n  padding: 0;\n  overflow-y: hidden;\n\n  .tab-header {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    font-size: 14px;\n    color: #7c7c7c;\n    font-weight: 600;\n    border-bottom: 2px solid rgba(31, 143, 199, 0.4431372549);\n    cursor: pointer;\n\n    .tab-header-item {\n      position: relative;\n      margin-right: 10px;\n\n      .tab-title {\n        font-size: 18px;\n      }\n    }\n\n    .tab-header-active::before {\n      content: \"\";\n      position: absolute;\n      bottom: -1px;\n      left: 0px;\n      right: 0px;\n      height: 2px;\n      background-color: #1f8ec7;\n    }\n  }\n\n  .tab-content {\n    width: 100%;\n    min-height: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
