//Page to display the clients in cards

import { useParams } from "react-router-dom";
import SidebarComponent from "../../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../../types";
import "./import.scss";

interface ImportPageProps extends SidebarProps {}

const ImportPage: React.FC<ImportPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page,
  currentClientLogo
}) => {
  const { clientID } = useParams();

  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            <div id="import_page" className="container-fluid">
              Import page for {page} with ID: {clientID}
            </div>
          </div>
        </div>
      </div>
      <SidebarComponent
        page="import"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
        currentClient={clientID}
        currentClientLogo={currentClientLogo}
      />
    </div>
  );
};

export default ImportPage;
