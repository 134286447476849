//Page to display the clients in cards

import LanguageLibrary from "../../components/LanguageLibrary/languageLibrary.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";
import "./languages.scss";
interface LanguagesPageProps extends SidebarProps {}

const LanguagesPage: React.FC<LanguagesPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page
}) => {
  return (
    <div className="main_container">
      <SidebarComponent
        page={page}
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            {/* <NavbarComponent /> */}
            <div className="container-fluid" id="languages_page">
              <LanguageLibrary />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguagesPage;
