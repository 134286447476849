import React from "react";
import "./studyNavSingle.scss";

import { IconAdd } from "../../assets/images/icons/icons";
import { capitalize } from "../../utilities/utils";

export type StudyNavbarSingleProps = {
  id?: number;
  name?: string;
  type: string;
  isActive?: boolean;
  onClick?: () => void;
};

const StudyNavbarSingle: React.FC<StudyNavbarSingleProps> = ({
  id,
  name,
  type,
  isActive,
  onClick
}) => {
  return (
    <div className="study_btn_container ">
      <button
        type="button"
        className={`btn btn_study d-flex justify-content-around ${
          type === "add" ? "add_survey_btn" : ""
        } ${isActive ? "is_active" : ""}`}
        onClick={onClick}
      >
        {/* Logic for add survey button */}
        {type === "add" ? (
          <span className="icon_container">
            <IconAdd className="icon icon_white" />
          </span>
        ) : (
          <span className={`type ${type}`}>{capitalize(type)}</span>
        )}

        <span
          className={`btn_text${type === "study" ? "_study" : ""} ${
            type === "add" ? "add_survey_text" : ""
          }`}
        >
          {name}
        </span>
      </button>
    </div>
  );
};

export default StudyNavbarSingle;
