import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fetchAllWSCQuestionsForMDISurvey,
  fetchAllWSCQuestionsRMSVariable,
  sendAllWSCQuestionsForMDISurvey
} from "../../../../models/wscMapping.model";
import { WSCQuestionRMS, WSCQuestionRMSVariable } from "../../../../types";

type WSCImportsProps = {
  reloadComponent: () => void;
};

const WSCImports: React.FC<WSCImportsProps> = ({ reloadComponent }) => {
  const { clientID, studyID, surveyID } = useParams<{
    clientID: string;
    studyID: string;
    surveyID: string;
  }>(); // Get from URL

  const [surveyQuestions, setSurveyQuestions] = useState<WSCQuestionRMS[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [rmsVariables, setRMSVariables] = useState<WSCQuestionRMSVariable[]>(
    []
  );
  const [dataChanged, setDataChanged] = useState<boolean>(false);

  useEffect(() => {
    if (clientID && studyID && surveyID) {
      const fetchQuestions = async () => {
        try {
          const jsonData = await fetchAllWSCQuestionsForMDISurvey(
            clientID,
            studyID,
            surveyID
          );
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            setError(jsonData);
          } else {
            setSurveyQuestions(jsonData);
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
          setError("An error occurred while fetching data");
        }
      };

      // fetchAllWSCQuestionsRMSVariable
      const fetchQuestionsRMS = async () => {
        try {
          const jsonData = await fetchAllWSCQuestionsRMSVariable(
            clientID,
            studyID,
            surveyID
          );
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            setError(jsonData);
          } else {
            setRMSVariables(jsonData);
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
          setError("An error occurred while fetching data");
        }
      };

      const promises = [fetchQuestions(), fetchQuestionsRMS()];

      Promise.all(promises)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    } else {
      setError("Missing clientID, studyID, or surveyID");
      setLoading(false);
    }
  }, [clientID, studyID, surveyID]);

  function handleQuestionUpdate(
    questionId: string,
    field: keyof WSCQuestionRMS,
    value: string
  ) {
    console.log(questionId, field, value);

    const updatedQuestions = surveyQuestions.map((question) => {
      if (question.id === questionId) {
        const updatedQuestion = { ...question };

        try {
          switch (field) {
            case "active":
              updatedQuestion.active = value === "true";
              break;
            case "method":
              if (value === "new" || value === "existing") {
                updatedQuestion.method = value;
                if (value === "new") {
                  updatedQuestion.mapped_field = "";
                }
              } else {
                throw new Error("Invalid value for mapped_method");
              }
              break;
            // case "question_type":
            //   if (
            //     value === "categorical" ||
            //     value === "numerical" ||
            //     value === "date"
            //   ) {
            //     updatedQuestion.question_type = value;
            //   }
            //   break;
            default:
              // For other fields, you may need additional checks
              // depending on their types in WSCQuestionRMS
              updatedQuestion[field] = value;
          }
        } catch (error: any) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error
          });
          return question;
        }
        setDataChanged(true);
        return updatedQuestion;
      }
      return question;
    });

    console.log(updatedQuestions);

    // set the state
    setSurveyQuestions(updatedQuestions);
  }

  // Sanitizes HTML content by allowing only specific tags.
  // function sanitizeHtml(html: string) {
  //   // Define a list of allowed tags
  //   const allowedTags = [
  //     "br",
  //     "span",
  //     "hr",
  //     "blockquote",
  //     "pre",
  //     "address",
  //     "ol",
  //     "ul",
  //     "li",
  //     "dl",
  //     "dt",
  //     "dd",
  //     "abbr",
  //     "b",
  //     "i",
  //     "small",
  //     "strong",
  //     "sub",
  //     "sup",
  //     "ins",
  //     "del",
  //     "mark",
  //     "q",
  //     "cite",
  //     "s",
  //     "u",
  //     "code",
  //     "em",
  //     "samp",
  //     "time",
  //   ];

  //   // Create a dummy div element to parse the HTML
  //   const div = document.createElement("div");
  //   div.innerHTML = html;

  //   // Recursively remove disallowed tags
  //   const removeDisallowedTags = (node: HTMLElement) => {
  //     [...node.childNodes].forEach((child) => {
  //       if (child.nodeType === Node.TEXT_NODE) {
  //         // Text node
  //         return; // Keep text nodes
  //       }
  //       if (child.nodeType === Node.ELEMENT_NODE) {
  //         const element = child as HTMLElement; // Cast to HTMLElement
  //         const tagName = element.tagName.toLowerCase();
  //         if (!allowedTags.includes(tagName) || tagName === "color") {
  //           // Replace the disallowed tag with its content
  //           while (element.firstChild) {
  //             node.insertBefore(element.firstChild, element);
  //           }
  //           node.removeChild(element); // Remove disallowed tag
  //         } else {
  //           removeDisallowedTags(element); // Recurse into allowed tags
  //         }
  //       }
  //     });
  //   };

  //   removeDisallowedTags(div);

  //   // Return the sanitized HTML
  //   return div.innerHTML;
  // }
  //Extract all the html styling and tags from the question name and return just the text
  function extractTextFromHtml(html: string) {
    // Create a dummy div element to parse the HTML
    const div = document.createElement("div");
    div.innerHTML = html;

    // Return the text content of the div
    return div.textContent || "";
  }

  function validateQuestions(): boolean {
    // validate the surveyQuestions
    // if any of the questions are invalid, show an error message and return false
    //  Only active questions need to be validated
    // question must have a method
    // question must have a mapped_field

    let errorQuestions: {
      questionName: string;
      errors: string[];
    }[] = [];

    for (let i = 0; i < surveyQuestions.length; i++) {
      const question = surveyQuestions[i];
      let errors: string[] = [];
      if (question.active) {
        if (!question.method) {
          errors.push("Missing method");
        }
        if (!question.mapped_field || question.mapped_field.trim() === "") {
          errors.push("Missing mapped field");
        }
      }
      if (errors.length > 0) {
        // trim the question name to the first 50 characters and add ellipsis if needed
        const questionName =
          question.name.length > 50
            ? question.name.substring(0, 50) + "..."
            : question.name;

        errorQuestions.push({
          questionName: questionName,
          errors: errors
        });
      }
    }

    if (errorQuestions.length > 0) {
      let errorHtml =
        "<strong>Please fix the following errors:</strong><br /><br />";
      errorQuestions.forEach((errorQuestion) => {
        errorHtml += `<strong>Question: ${errorQuestion.questionName}</strong>`;
        errorQuestion.errors.forEach((error) => {
          errorHtml += `<p>Error: ${error}</p>`;
        });
      });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errorHtml
      });
      return false;
    }

    return true;
  }

  function handleSave() {
    // sendAllWSCQuestionsForMDISurvey
    //  send the surveyQuestions to the backend
    //  if successful, show a success message

    if (!clientID || !surveyID || !studyID) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Missing clientID, studyID, or surveyID"
      });
      return;
    }

    if (!validateQuestions()) {
      return false;
    }

    const saveQuestions = async () => {
      try {
        const jsonData: {
          rStatus: "error" | "success";
          rMessage: string;
        } = await sendAllWSCQuestionsForMDISurvey(
          clientID,
          studyID,
          surveyID,
          surveyQuestions
        );

        if (jsonData.rStatus === "error") {
          Swal.fire({
            icon: "error",
            title: "Error",
            html: jsonData.rMessage
          });
        } else {
          // ok button which will reload the page
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Questions saved successfully",
            showConfirmButton: true,
            confirmButtonText: "OK"
          }).then(() => {
            reloadComponent();
          });
        }
      } catch (error) {
        console.error("An error occurred while sending data:", error);
        setError("An error occurred while sending data");
      }
    };

    saveQuestions();
  }

  // Reset state function
  function resetState() {
    //  reset the state to the original state
    reloadComponent();
  }

  return loading ? (
    <div className="d-flex justify-content-center custom_spinner_container flex-column align-items-center mt-5">
      <div className="spinner-border" role="status"></div>
      <h2 className="mt-3">Loading Survey Questions from WSC...</h2>
    </div>
  ) : error ? (
    <h2>{error}</h2>
  ) : !surveyQuestions || surveyQuestions.length <= 0 ? (
    <h2>No Questions found</h2>
  ) : (
    <>
      <div className="wscSurvey">
        <div className="wscSurvey_header">
          <h2 className="wscSurvey_header_title">Import from WSC Survey</h2>
          {dataChanged && (
            <>
              <button className="btn btn-primary ms-3" onClick={handleSave}>
                Save
              </button>
              <button className="btn btn-primary ms-2" onClick={resetState}>
                Cancel
              </button>
            </>
          )}
        </div>
        <div className="wscSurvey_body">
          <div className="row">
            {surveyQuestions.map((question) => {
              const safeHtml = extractTextFromHtml(question.name);
              return (
                <div
                  className="col-12 col-sm-6 col-md-6 col-lg-3"
                  key={question.id}
                >
                  <div className="wscSurvey_body_question card p-2">
                    <div className="wscSurvey_body_question_header">
                      {/* checkbox for active state */}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={question.active ? "true" : "false"}
                          id="flexCheckDefault"
                          checked={question.active}
                          onChange={(e) =>
                            handleQuestionUpdate(
                              question.id,
                              "active",
                              e.target.checked.toString()
                            )
                          }
                        />
                      </div>
                      <h6
                        className="wscSurvey_body_question_header_title"
                        dangerouslySetInnerHTML={{ __html: safeHtml }}
                      ></h6>
                    </div>
                    <div className="wscSurvey_body_question_options">
                      Map Method
                      {/* radio buttons */}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`radio_method_new_${question.id}`}
                          name={`radio_method_new_${question.id}`}
                          checked={question.method === "new"}
                          value="new"
                          onChange={(e) =>
                            handleQuestionUpdate(
                              question.id,
                              "method",
                              e.target.value
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`radio_method_new_${question.id}`}
                        >
                          New
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`radio_method_existing_${question.id}`}
                          id={`radio_method_existing_${question.id}`}
                          checked={question.method === "existing"}
                          value={"existing"}
                          onChange={(e) =>
                            handleQuestionUpdate(
                              question.id,
                              "method",
                              e.target.value
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`radio_method_existing_${question.id}`}
                        >
                          Existing
                        </label>
                      </div>
                    </div>
                    {question.method === "new" ? (
                      <div className="wscSurvey_body_question_options">
                        Map to New Field
                        {/* text */}
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Field Name"
                          value={question.mapped_field}
                          onChange={(e) =>
                            handleQuestionUpdate(
                              question.id,
                              "mapped_field",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div className="wscSurvey_body_question_options">
                        Map to Field
                        {/* dropdown using rmsVariables */}
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={question.mapped_field}
                          onChange={(e) =>
                            handleQuestionUpdate(
                              question.id,
                              "mapped_field",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Field</option>
                          {rmsVariables.map((rmsVariable) => {
                            return (
                              <option
                                key={rmsVariable.id}
                                value={rmsVariable.id}
                              >
                                {rmsVariable.mapped_field}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}

                    {/* {question.method === "new" && (
                      <div className="wscSurvey_body_question_options">
                        // Question in RMS
                        // textarea
                        <input
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          value={question.question_rms}
                          placeholder="Question code in RMS"
                          onChange={(e) =>
                            handleQuestionUpdate(
                              question.id,
                              "question_rms",
                              e.target.value
                            )
                          }
                        ></input>
                      </div>
                    )} */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default WSCImports;
