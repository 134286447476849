import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Icons from "../../../assets/images/icons/icons";
import {
  addParticipantToClient,
  sendBulkLeadsActions
} from "../../../models/leads.model";
import { sendBulkParticipantsActions } from "../../../models/participant.model";
import { sendBulkResearcherActions } from "../../../models/researcher.model";
import { Tag, fetchAllTags } from "../../../models/tag.model";
import { Client } from "../../../types";
import "./bulkActions.scss";

interface BulkActionsProps {
  selectedParticipants: string[];
  // handleClearSelectParticipants: () => void;
  tableType:
    | "leadsTable"
    | "researchersTable"
    | "ClientParticipants"
    | "StudyParticipants"
    | "SurveyParticipants"
    | "DiaryParticipants"
    | "ClientRewards"
    | "StudyRewards";
  allSelected: boolean;
  allClients?: Client[];
}

const BulkActions: React.FC<BulkActionsProps> = ({
  selectedParticipants,
  tableType,
  allSelected,
  allClients
  // handleClearSelectParticipants,
}) => {
  const { clientID, surveyID, studyID } = useParams(); // Get from URL
  const [bulkActionsDropdown, setBulkActionsDropdown] = useState(false);
  const [bulkActionsConfirmationPopup, setBulkActionsConfirmationPopup] =
    useState(false);
  const [bulkType, setbulkType] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [confirmString, setConfirmString] = useState("");
  const [fields, setFields] = useState<string[]>([]); // Set initial state to []
  const [bulkLabelRemove, setBulkLabelRemove] = useState("Remove");
  const [bulkLabelBlock, setBulkLabelBlock] = useState("Block");
  const [bulkLabelUnblock, setBulkLabelUnblock] = useState("Unblock");
  const [bulkLabelFlag, setBulkLabelFlag] = useState("Flag");
  const [bulkLabelUnflag, setBulkLabelUnflag] = useState("Unflag");
  const [bulkLabelInvitation, setBulkLabelInvitation] = useState("Invitation");
  const [bulkLabelReward, setBulkLabelReward] = useState("Reward");
  const [bulkLabelTag, setBulkLabelTag] = useState("Tag");

  //for rewards
  const [bulkLabelRewardsApproved, setLabelBulkRewardsApproved] =
    useState<string>("Rewards Approve");
  const [bulkLabelRewardsDone, setLabelBulkRewardsDone] =
    useState<string>("Rewards Done");
  const [bulkLabelRewardsRejected, setLabelBulkRewardsRejected] =
    useState<string>("Rewards Rejected");

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<Tag[]>([]);

  /* --------------FETCHING DATA----------------- */
  const getTagData = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAllTags(clientID as string);
      if (typeof data === "string") {
        setTags([]);
      } else {
        // Sort tags updated_at in descending order
        // data.sort((a, b) => {
        //   const dateA = new Date(a.updated_at);
        //   const dateB = new Date(b.updated_at);
        //   return dateB.getTime() - dateA.getTime();
        // });
        setTags(data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTagData();
  }, []);

  //This is for the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && (event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  //Handler for adding tags from dropdown
  const handleSelect = (tag: string) => {
    if (tag && !participantAddedTags.includes(tag)) {
      // Check if tag is unique
      (
        setParticipantAddedTags as React.Dispatch<
          React.SetStateAction<string[]>
        >
      )((prevTags: string[]) => [...prevTags, tag]);
      setShowDropdown(false);
    } else {
      setShowDropdown(false);
    }
    //inputRef.current.value = "";
  };

  const handleBulkActionsDropdown = () => {
    // show/hide dropdown
    setBulkActionsDropdown(!bulkActionsDropdown);
  };

  const handleBulkAction = (type: string) => {
    // handle bulk action
    if (!selectedParticipants || selectedParticipants.length === 0) {
      console.log("No participants selected");
    } else {
      handleBulkActionsConfirmationPopup(true); // show confirmation popup
      setbulkType(type); // set bulk action type state to be used in confirmation popup
    }
  };

  const handleBulkActionsConfirmationPopup = (show: boolean) => {
    const currectWorkerID = localStorage.getItem("workerID");
    if (
      currectWorkerID !== null &&
      currectWorkerID !== undefined &&
      currectWorkerID !== ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
    } else {
      // show/hide confirmation
      setBulkActionsConfirmationPopup(show);
    }
  };

  const handleBulkActionsConfirmationParticipant = (confirm: boolean) => {
    // handle confirmation
    if (confirm) {
      const sendingParticipantsActions = async () => {
        const participantIDs: string[] = selectedParticipants;
        try {
          let participantsActionValue: {
            key: string;
            value: string | string[];
          } | null = null;

          if (bulkType === "reward") {
            participantsActionValue = {
              key: rewardType === "points" ? "points" : "voucher",
              value:
                rewardType === "points"
                  ? rewardPoints.toString()
                  : rewardVoucher.toString()
            };
          }

          if (bulkType === "tag") {
            participantsActionValue = {
              key: "tags",
              value: participantAddedTags
            };
          }

          if (bulkType === "addToClient") {
            return;
          }

          const jsonData: string = await sendBulkParticipantsActions(
            bulkType.toLowerCase(),
            participantIDs,
            tableType === "ClientParticipants"
              ? "client"
              : tableType === "StudyParticipants"
                ? "study"
                : tableType === "SurveyParticipants"
                  ? "survey"
                  : tableType === "DiaryParticipants"
                    ? "survey"
                    : tableType === "ClientRewards"
                      ? "client"
                      : tableType === "StudyRewards"
                        ? "study"
                        : "",
            clientID as string,
            studyID,
            surveyID,
            participantsActionValue?.value
          );

          if (jsonData !== "Success") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: jsonData,
              confirmButtonColor: "#3085d6"
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Worker has been created",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } catch (error) {
          console.error(
            "An error occurred while sending participants actions:",
            error
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while sending participants actions",
            confirmButtonColor: "#3085d6"
          });
        }
      };

      const promises = [sendingParticipantsActions()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
        })
        .catch(() => {
          // At least one promise failed
        });
    }
    setBulkActionsConfirmationPopup(false); // hide confirmation popup
    setBulkActionsDropdown(false); // hide dropdown
    // handleClearSelectParticipants(); // clear selected participants

    // clear values
    handleClearAll();
  };

  const handleBulkActionsConfirmationLead = (confirm: boolean) => {
    // handle confirmation
    if (confirm) {
      const sendingParticipantsActions = async () => {
        const participantIDs: string[] = selectedParticipants;
        try {
          let participantsActionValue: {
            key: string;
            value: string | string[];
          } | null = null;

          if (bulkType === "reward") {
            participantsActionValue = {
              key: rewardType === "points" ? "points" : "voucher",
              value:
                rewardType === "points"
                  ? rewardPoints.toString()
                  : rewardVoucher.toString()
            };
          }

          if (bulkType === "tag") {
            participantsActionValue = {
              key: "tags",
              value: participantAddedTags
            };
          }

          if (bulkType === "addToClient") {
            participantsActionValue = {
              key: "clientID",
              value: selectedClientID
            };
          }

          let jsonData: {
            rStatus: "error" | "success";
            rMessage: string;
          };
          if (bulkType === "addToClient") {
            //           addParticipantToClient
            jsonData = await addParticipantToClient(
              selectedClientID,
              participantIDs
            );
          } else {
            jsonData = await sendBulkLeadsActions(
              bulkType.toLowerCase(),
              participantIDs,
              participantsActionValue
            );
          }

          if (jsonData.rStatus !== "success") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: jsonData.rMessage,
              confirmButtonColor: "#3085d6"
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Worker has been created",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } catch (error) {
          console.error(
            "An error occurred while sending leads actions:",
            error
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while sending leads actions",
            confirmButtonColor: "#3085d6"
          });
        }
      };

      const promises = [sendingParticipantsActions()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
        })
        .catch(() => {
          // At least one promise failed
        });
    }
    setBulkActionsConfirmationPopup(false); // hide confirmation popup
    setBulkActionsDropdown(false); // hide dropdown
    // handleClearSelectParticipants(); // clear selected participants

    // clear values
    handleClearAll();
  };

  const handleBulkActionsConfirmationResearcher = (confirm: boolean) => {
    // handle confirmation
    if (confirm) {
      const sendingParticipantsActions = async () => {
        const participantIDs: string[] = selectedParticipants;
        try {
          const jsonData: string = await sendBulkResearcherActions(
            bulkType.toLowerCase(),
            participantIDs
          );

          if (jsonData !== "Success") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: jsonData,
              confirmButtonColor: "#3085d6"
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Worker has been created",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } catch (error) {
          console.error(
            "An error occurred while sending researcher actions:",
            error
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while sending researcher actions",
            confirmButtonColor: "#3085d6"
          });
        }
      };

      const promises = [sendingParticipantsActions()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
        })
        .catch(() => {
          // At least one promise failed
        });
    }
    setBulkActionsConfirmationPopup(false); // hide confirmation popup
    setBulkActionsDropdown(false); // hide dropdown
    // handleClearSelectParticipants(); // clear selected participants

    // clear values
    handleClearAll();
  };

  // Rewards
  const [rewardType, setRewardType] = useState("points");
  const [rewardPoints, setRewardPoints] = useState(0);
  const [rewardVoucher, setRewardVoucher] = useState("");
  const handleRewardType = (type: string) => {
    setRewardType(type);
  };

  // tags
  const [participantAddedTags, setParticipantAddedTags] = useState<string[]>(
    []
  );

  // clients
  const [selectedClientID, setSelectedClientID] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //Regex for the tag name, no special characters except for "-", from 4 to 60 characters
    event.target.value = event.target.value.replace(/[^a-zA-Z0-9-]/g, "");
    // Add tags on comma
    if (event.target.value.includes(",")) {
      const newTags = event.target.value
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag && !participantAddedTags.includes(tag));
      setParticipantAddedTags((prevTags) => [...prevTags, ...newTags]);
      event.target.value = ""; // Clear the input
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Add tag on enter key

    setShowDropdown(true);
    setFilteredTags(
      tags.filter((tag) =>
        tag.name.toLowerCase().includes(event?.currentTarget?.value)
      )
    );
    if (event.key === "Enter" && inputRef.current?.value) {
      //Regex for the tag name, no special characters except for "-", from 4 to 60 characters

      const newTag = inputRef.current.value
        .trim()
        .replace(/[^a-zA-Z0-9-]/g, "");
      if (
        newTag &&
        !participantAddedTags.includes(newTag) &&
        newTag.length >= 4 &&
        newTag.length <= 60
      ) {
        // Check if tag is unique
        setParticipantAddedTags((prevTags) => [...prevTags, newTag]);
      }
      inputRef.current.value = ""; // Clear the input
      event.preventDefault(); // Prevents form submission if this is inside a form
    }
  };

  const removeTag = (tagToRemove: string) => {
    setParticipantAddedTags((prevTags) =>
      prevTags.filter((tag) => tag !== tagToRemove)
    );
  };

  const handleClearAll = () => {
    // clear values
    setRewardType("points");
    setRewardPoints(0);
    setRewardVoucher("");
    setParticipantAddedTags([]);
    setConfirmString("");
  };

  const validateData = useCallback(() => {
    if (confirmString !== "BULK") {
      return false;
    }

    if (bulkType === "tag" && participantAddedTags.length <= 0) {
      return false;
    }

    if (bulkType === "reward" && rewardType === "points" && rewardPoints <= 0) {
      return false;
    }

    if (
      bulkType === "reward" &&
      rewardType === "voucher" &&
      rewardVoucher === ""
    ) {
      return false;
    }

    if (bulkType === "addToClient" && selectedClientID === "") {
      return false;
    }

    return true;
  }, [
    bulkType,
    participantAddedTags,
    rewardPoints,
    rewardVoucher,
    rewardType,
    selectedClientID,
    confirmString
  ]);

  useEffect(() => {
    handleClearAll();
  }, [selectedParticipants, bulkType]);

  useEffect(() => {
    // check if can submit
    setCanSubmit(validateData());
  }, [validateData]);

  useEffect(() => {
    // based on the passed though tableType, set the fields to be displayed
    console.log("tableType", tableType);
    switch (tableType) {
      case "ClientParticipants":
        setFields([
          // "reward",
          "tag",
          "remove",
          "unblock",
          "block",
          "unflag",
          "flag"
          // "invitation",
        ]);
        setBulkLabelRemove("Remove from client database");
        setBulkLabelBlock("Block access to apps & studies");
        setBulkLabelUnblock("Unblock access to apps & studies");
        setBulkLabelFlag("Flag participants");
        setBulkLabelUnflag("Unflag participants");
        setBulkLabelInvitation("Send App Invitation");
        setBulkLabelReward("Add points");
        setBulkLabelTag("Tag participants");
        break;
      case "StudyParticipants":
        setFields([
          "reward",
          "tag",
          "remove",
          "unblock",
          "block",
          "unflag",
          "flag"
        ]);
        setBulkLabelRemove("Remove from study");
        setBulkLabelBlock("Block access to this study & surveys");
        setBulkLabelUnblock("Unblock access to this study & surveys");
        setBulkLabelFlag("Flag participants");
        setBulkLabelUnflag("Unflag participants");
        setBulkLabelInvitation("Send Invitation for this app");
        setBulkLabelReward("Add points");
        setBulkLabelTag("Tag participants");
        break;
      case "SurveyParticipants":
        setFields(["tag", "remove", "unblock", "block", "unflag", "flag"]);
        setBulkLabelRemove("Remove from survey");
        setBulkLabelBlock("Block access to this  survey");
        setBulkLabelUnblock("Unblock access to this survey");
        setBulkLabelFlag("Flag participants");
        setBulkLabelUnflag("Unflag participants");
        setBulkLabelTag("Tag participants");
        break;
      case "DiaryParticipants":
        setFields(["tag", "remove", "unblock", "block", "unflag", "flag"]);
        setBulkLabelRemove("Remove from survey");
        setBulkLabelBlock("Block access to this  survey");
        setBulkLabelUnblock("Unblock access to this survey");
        setBulkLabelFlag("Flag participants");
        setBulkLabelUnflag("Unflag participants");
        setBulkLabelTag("Tag participants");
        break;
      case "leadsTable":
        setFields([
          "remove",
          //  "block", "unblock"
          "addToClient"
        ]);
        setBulkLabelRemove("Remove lead");
        setBulkLabelBlock("Block access to all clients");
        setBulkLabelUnblock("Unblock access to all clients");
        break;
      case "researchersTable":
        setFields(["block", "unblock"]);
        setBulkLabelBlock("Block access");
        setBulkLabelUnblock("Unblock access");
        break;
      case "StudyRewards":
        setFields(["RewardApprove", "RewardDone", "RewardRejected"]);
        setLabelBulkRewardsApproved("Approve");
        setLabelBulkRewardsDone("Done");
        setLabelBulkRewardsRejected("Reject");
        break;
      case "ClientRewards":
        setFields(["RewardApprove", "RewardDone", "RewardRejected"]);
        setLabelBulkRewardsApproved("Approve");
        setLabelBulkRewardsDone("Done");
        setLabelBulkRewardsRejected("Reject");
        break;
      default:
        setFields(["UnknownTableType"]);
    }
  }, [tableType]);

  //Confirmation Modal for remove and block
  const confirmationModal = (
    actionType: string,
    count: number,
    tableType: BulkActionsProps["tableType"]
  ) => {
    Swal.fire({
      html: `<div class="confirmation-popup">
        <h5 class="title">Are you sure you want to ${actionType} ${count} participant(s)?</h5>
        <div class="content">
          <label>Please enter "${count}" to confirm your action.</label>
          <input id="swal-input1" class="swal2-input" style="margin-left: 10px;" placeholder="Enter amount">
        </div>
      </div>`,
      preConfirm: () => {
        const inputValue = (
          document.getElementById("swal-input1") as HTMLInputElement
        ).value;
        if (inputValue !== count.toString()) {
          Swal.showValidationMessage("Please type the correct number");
        }
      },
      customClass: {
        popup: "swalConfirmationModal"
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    }).then((result) => {
      if (result.isConfirmed) {
        if (tableType === "researchersTable") {
          handleBulkActionsConfirmationResearcher(true);
        } else {
          handleBulkActionsConfirmationParticipant(true);
        }
      }
    });
  };

  return (
    <div
      style={{
        position: "relative"
      }}
    >
      <button
        id="bulk_actions_button"
        className="btn btn-primary"
        onClick={() => handleBulkActionsDropdown()}
      >
        {/* if selectedParticipants > 0 show number */}
        {selectedParticipants && selectedParticipants.length > 0 ? (
          <span className="badge">{selectedParticipants.length}</span>
        ) : null}{" "}
        Bulk Actions <Icons.IconSettings className="icon icon_white" />
      </button>
      <div
        id="bulk_actions_dropdown"
        className={`dropdown-menu ${bulkActionsDropdown ? "show" : "hidden"}`}
      >
        {fields.map((field: string, index: number) => {
          switch (field) {
            case "reward":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("reward")}
                >
                  <Icons.IconRewards className="icon" /> {bulkLabelReward}
                </div>
              );
            case "tag":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("tag")}
                >
                  <Icons.IconTags className="icon" /> {bulkLabelTag}
                </div>
              );
            case "remove":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("remove")}
                >
                  <Icons.IconX className="icon" /> {bulkLabelRemove}
                </div>
              );
            case "unblock":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("unblock")}
                >
                  <Icons.IconCheck className="icon" /> {bulkLabelUnblock}
                </div>
              );
            case "block":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("block")}
                >
                  <Icons.IconBan className="icon" /> {bulkLabelBlock}
                </div>
              );
            case "unflag":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("unflag")}
                >
                  <Icons.IconUnflag className="icon" /> {bulkLabelUnflag}
                </div>
              );
            case "flag":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("flag")}
                >
                  <Icons.IconFlag className="icon" /> {bulkLabelFlag}
                </div>
              );
            case "invitation":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("invitation")}
                >
                  <Icons.IconEmail className="icon" /> {bulkLabelInvitation}
                </div>
              );
            case "addToClient":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("addToClient")}
                >
                  <Icons.IconClient className="icon" /> Add to client
                </div>
              );
            case "RewardApprove":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("Approved")}
                >
                  <Icons.IconRewards className="icon" />
                  Approve rewards
                </div>
              );
            case "RewardDone":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("Done")}
                >
                  <Icons.IconCheck className="icon" /> Mark as Done
                </div>
              );
            case "RewardRejected":
              return (
                <div
                  key={field + index}
                  className="dropdown_menu_item clickable"
                  onClick={() => handleBulkAction("Rejected")}
                >
                  <Icons.IconBan className="icon" /> Reject rewards
                </div>
              );
            case "UnknownTableType":
              return <p key={field + index}>Unknown table type</p>;
            default:
              return <p key={"unknown" + index}>Unknown case</p>;
          }
        })}
      </div>
      <div
        className={`modal bulk_actions_confirmation_modal ${
          bulkActionsConfirmationPopup ? "show" : "hide"
        }`}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <h5>
                      Confirm <span>{bulkType}</span> for{" "}
                      {selectedParticipants.length}
                      {allSelected && "(ALL)"}{" "}
                      {tableType !== "leadsTable" ? "participants" : "leads"}?
                    </h5>
                    {allSelected && (
                      <span>
                        Please note this applies to all participants, not just
                        those on the page
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* if bulkType = tag or reward show input fields for them  */}
              {(bulkType === "tag" ||
                bulkType === "reward" ||
                bulkType === "addToClient") && (
                <div id="container_bulk_type" className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      {/* if bulkType === "tag" */}
                      {bulkType === "tag" && (
                        <div className="col">
                          <div className="input-wrapper">
                            <label htmlFor="tags">
                              Press "ENTER" or "," to add tag
                            </label>
                            <input
                              ref={inputRef}
                              type="text"
                              className="form-control"
                              onChange={handleTagsChange}
                              onKeyDown={handleKeyDown}
                              placeholder="Enter tags"
                            />
                            {showDropdown &&
                              !isLoading &&
                              filteredTags.length > 0 && (
                                <div className="tag-dropdown" ref={dropdownRef}>
                                  {filteredTags.map((tag) => (
                                    <div
                                      key={tag.id}
                                      className="tag-dropdown-item"
                                      onClick={() => handleSelect(tag?.name)}
                                    >
                                      {tag.name}
                                    </div>
                                  ))}
                                </div>
                              )}
                            {participantAddedTags.map((tag) => (
                              <span key={tag} className="tag">
                                {tag}
                                <button
                                  type="button"
                                  className="closeTag"
                                  onClick={() => removeTag(tag)}
                                >
                                  X
                                </button>
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* if bulkType === "reward" */}
                      {bulkType === "reward" && (
                        <div className="col">
                          <label htmlFor="reward">Reward Type</label>
                          {/* points number or voucher dropdown based on radio selection */}
                          <div id="reward_type">
                            {/* <input
                                // className="form-check-input"
                                type="radio"
                                name="reward_type"
                                id="reward_type_points"
                                value="points"
                                checked={rewardType === "points"}
                                onChange={() => handleRewardType("points")}
                              /> */}
                            {/* <label
                                className="form-check-label ps-1 me-3"
                                htmlFor="reward_type_points"
                              >
                                Points
                              </label> */}

                            {/* <input
                                // className="form-check-input"
                                type="radio"
                                name="reward_type"
                                id="reward_type_voucher"
                                value="voucher"
                                checked={rewardType === "voucher"}
                                onChange={() => handleRewardType("voucher")}
                              />
                              <label
                                className="form-check-label ps-1"
                                htmlFor="reward_type_voucher"
                              >
                                Voucher
                              </label> */}
                          </div>
                          {/* if rewardType = points, show number input */}
                          {rewardType === "points" && (
                            <div id="reward_points">
                              <label htmlFor="reward_points">Points</label>
                              <input
                                type="number"
                                className="form-control"
                                id="reward_points"
                                placeholder="Enter points"
                                value={rewardPoints}
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // This regex will allow 1 to 9
                                  setRewardPoints(Number(numericValue) || 1);
                                }}
                              />
                            </div>
                          )}
                          {/* if rewardType = voucher, show dropdown */}
                          {rewardType === "voucher" && (
                            <div id="reward_voucher">
                              <label htmlFor="reward_voucher">Voucher</label>
                              <select
                                className="form-select"
                                id="reward_voucher"
                                value={rewardVoucher}
                                onChange={(e) =>
                                  setRewardVoucher(e.target.value)
                                }
                              >
                                <option value="">Select voucher</option>
                                <option value="voucher1">Voucher 1</option>
                                <option value="voucher2">Voucher 2</option>
                                <option value="voucher3">Voucher 3</option>
                              </select>
                            </div>
                          )}
                        </div>
                      )}
                      {bulkType === "addToClient" &&
                        (allClients && allClients?.length > 0 ? (
                          // select client dropdown
                          <div className="col">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={selectedClientID}
                              onChange={(e) =>
                                setSelectedClientID(e.target.value)
                              }
                            >
                              <option value="">Select client</option>
                              {allClients.map((client) => (
                                <option key={client.id} value={client.id}>
                                  {client.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div>No clients</div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="modal-body">
                <div className="container-fluid">
                  <div className="row"></div>
                </div>
              </div> */}
              <div className="modal-footer">
                <div className="container-fluid">
                  <div className="row">
                    {/* Type BULK to confirm */}
                    <div className="col-6">
                      <label htmlFor="confirm_string">
                        Type "BULK" to confirm
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Type BULK to confirm"
                        onChange={(e) => setConfirmString(e.target.value)}
                        value={confirmString}
                        style={{
                          // vertical-align: -webkit-baseline-middle;
                          verticalAlign: "middle"
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-end">
                      <button
                        className={`btn btn-danger me-2${
                          canSubmit ? "" : " disabled"
                        }`}
                        onClick={() =>
                          //if bulkType is remove or block we want to confirm that action
                          bulkType === "remove" || bulkType === "block"
                            ? confirmationModal(
                                bulkType,
                                selectedParticipants.length,
                                tableType
                              )
                            : tableType === "leadsTable"
                              ? handleBulkActionsConfirmationLead(true)
                              : tableType === "researchersTable"
                                ? handleBulkActionsConfirmationResearcher(true)
                                : handleBulkActionsConfirmationParticipant(true)
                        }
                      >
                        Confirm
                      </button>
                      <button
                        className={`btn btn-secondary`}
                        onClick={() =>
                          tableType === "leadsTable"
                            ? handleBulkActionsConfirmationLead(false)
                            : tableType === "researchersTable"
                              ? handleBulkActionsConfirmationResearcher(false)
                              : handleBulkActionsConfirmationParticipant(false)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkActions;
