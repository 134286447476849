import React from "react";
import { capitalize } from "../../../utilities/utils";
import InformationTooltip from "../../InputInformation/InfoInputs.component";

interface FieldRadioProps {
  onInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  inputValue: string;
  label: string;
  inputName: string;
  options: string[];
  subLabel?: string;
  error?: string | null;
  tooltip?: string;
  tooltipPosition?: "left" | "right" | "bottom" | "top";
}

const FieldRadio: React.FC<FieldRadioProps> = ({
  onInputChange,
  inputValue,
  label,
  inputName,
  subLabel,
  error,
  options,
  tooltip,
  tooltipPosition
}) => {
  return (
    <div className="mb-3 row">
      <div className="col-12 col-lg-4">
        <label htmlFor={inputName} className="form-label mb-1 mx-1">
          {tooltip && (
            <InformationTooltip
              marginTop="-12px"
              position={tooltipPosition ? tooltipPosition : "top"}
              tooltipText={tooltip}
            />
          )}
          {label}
          {subLabel && <span className="sub_label">{subLabel}</span>}
        </label>
      </div>
      <div className="col-12 col-lg-8">
        {error && <div className="wizard_input_error">{error}</div>}
        {/* map through options and create radio buttons */}
        {options.map((option, index) => {
          return (
            <div className="form-check radio_buttons" key={index}>
              <label className="form-check-label" htmlFor={option}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={inputName}
                  id={option}
                  value={option}
                  checked={inputValue === option}
                  onChange={(e) => onInputChange(inputName, e)}
                  style={{
                    display: "inline-block"
                  }}
                />

                {capitalize(option)}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FieldRadio;
