import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  IconCirclePause,
  IconDelete,
  IconEdit,
  IconPlay
} from "../../../../assets/images/icons/icons";
import {
  runAutomation,
  updateAutomationStatus
} from "../../../../models/automations.model";
import {
  Actions,
  AutomationRuleData,
  ChildConditionGroup,
  Rules,
  Timings
} from "../../../../types";
import Action from "../Actions/action.component";
import Condition from "../Conditions/conditionsContainer.component";
import TimingDisplaySimple from "../Timing/timingSimpleDisplay";

import { usePermissions } from "../../../../contexts/UserContext";
import "./automationsPreview.scss";

export type AutomationsPreviewProps = {
  selectedRuleID: string;
  ruleSelection: string;
  dropdownRuleData: AutomationRuleData;
  childConditionGroups: ChildConditionGroup;
  setChildConditionGroups: React.Dispatch<
    React.SetStateAction<ChildConditionGroup>
  >;
  foundAutomation: boolean;
  actions: Actions[];
  timing: Timings;
  status: string;
  validConditionData: boolean;
  validActionData: boolean;
  validTimingData: boolean;
  setValidConditionData: React.Dispatch<React.SetStateAction<boolean>>;
  setValidActionData: React.Dispatch<React.SetStateAction<boolean>>;
  setValidTimingData: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditRuleView: (ruleID: string) => void;
  handleSelectSingleAutomation: (ruleID: string) => void;
  handleDeleteAutomations: (ruleIDs: string[]) => void;

  // states for tracking if action, condition, timing has right data
  // validActionData: boolean;

  allAutomations: Rules[];
};

const AutomationsPreview: React.FC<AutomationsPreviewProps> = ({
  selectedRuleID,
  ruleSelection,
  dropdownRuleData,
  childConditionGroups,
  setChildConditionGroups,
  foundAutomation,
  actions,
  timing,
  status,
  validConditionData,
  validActionData,
  validTimingData,
  setValidConditionData,
  setValidActionData,
  setValidTimingData,
  allAutomations,
  handleEditRuleView,
  handleSelectSingleAutomation,
  handleDeleteAutomations
}) => {
  const { clientID, studyID, surveyID } = useParams(); // Read values passed on state
  const [getData, setData] = useState([]); // Create state variable to store data
  const [loading, setLoading] = useState(true); // Create state variable for loading status
  const [tabControl, setTabControl] = useState("rules"); // Create state variable for loading status

  const removeAction = () => {
    console.log("removeAction");
  };
  const [action, setActions] = useState<Actions[]>([]); // Create state variable for loading status

  const { hasPermission } = usePermissions();

  // Logging
  useEffect(() => {
    console.log("actions:", actions);
    console.log("conditions:", childConditionGroups);
    console.log("timing:", timing);
  }, [actions, childConditionGroups, timing]);

  //  Valid Data Checker (Conditions, Actions, Timing)
  useEffect(() => {
    // Action Valid
    if (actions.length > 0) {
      if (actions[0].selection !== "") {
        setValidActionData(true);
        console.log("VALID ACTIONS");
      } else {
        setValidActionData(false);
      }
    }

    // Condition Valid
    if (
      childConditionGroups["0"] &&
      (childConditionGroups["0"].childRules[0]?.selection !== "" ||
        Object.keys(childConditionGroups["0"].childConditionGroups).length !==
          0)
    ) {
      setValidConditionData(true);
      console.log("VALID CONDITIONS");
    } else {
      console.log("INVALID CONDITIONS");
      console.log(childConditionGroups["0"]);
      console.log(childConditionGroups["0"]?.childRules[0]?.selection);
      console.log(
        Object.keys(childConditionGroups["0"]?.childConditionGroups || {})
          .length !== 0
      );
      setValidConditionData(false);
    }

    // Timing Valid
    if (timing.type !== "") {
      setValidTimingData(true);
      console.log("VALID TIMING");
    } else {
      setValidTimingData(false);
    }
  }, [actions, childConditionGroups, timing]);

  // Status Update (Active/Inactive)
  const handleUpdateAutomationStatus = async (
    selectedRuleID: string,
    status: string
  ) => {
    console.log(selectedRuleID);
    if (selectedRuleID === "") {
      Swal.fire({
        title: "Error!",
        text: "Please select an automation",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    try {
      const { value: confirm } = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to update the status of this automation",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      });

      if (confirm) {
        if (clientID && studyID) {
          var newStatus = "";
          if (status === "active") {
            newStatus = "inactive";
          } else if (status === "inactive") {
            newStatus = "active";
          } else {
            console.log("DEFAULT");
            newStatus = "inactive";
          }
          const data = await updateAutomationStatus(
            clientID,
            studyID,
            selectedRuleID,
            newStatus
          );
          if (data === "202") {
            Swal.fire({
              title: "Success!",
              text: "Automation status updated",
              icon: "success",
              confirmButtonColor: "#3085d6"
            });
            handleSelectSingleAutomation(selectedRuleID);
          } else {
            Swal.fire({
              title: "Error!",
              text: data ? data : "Automation status not updated",
              icon: "error",
              confirmButtonColor: "#3085d6"
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Run Automation
  const handleRunAutomation = async (selectedRuleID: string) => {
    console.log(selectedRuleID);
    if (selectedRuleID === "") {
      Swal.fire({
        title: "Error!",
        text: "Please select an automation",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    try {
      const { value: confirm } = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to run this automation",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      });

      if (confirm) {
        if (clientID && studyID) {
          const data = await runAutomation(clientID, studyID, selectedRuleID);
          if (data.rStatus === "success") {
            Swal.fire({
              title: "Success!",
              text: "Automation ran successfully",
              icon: "success",
              confirmButtonColor: "#3085d6"
            });
            handleSelectSingleAutomation(selectedRuleID);
          } else {
            Swal.fire({
              title: "Error!",
              text: "Automation not ran",
              icon: "error",
              confirmButtonColor: "#3085d6"
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    // Container in parent, css for container in current folder
    <>
      <div className="col-12 preview_heading">
        <div className="preview_header">
          <h3>{ruleSelection}</h3>

          <div className="preview_header_buttons">
            {/* COPY */}
            {/* {(hasPermission("subject") ||
              hasPermission("automation", "all") ||
              hasPermission("automation", "write")) && (
              <button
                className="btn btn-primary preview_button edit_button"
                onClick={() => {
                  console.log("Directly within onClick:");
                }}
              >
                <IconCopy />
              </button>
            )} */}
            {/* EDIT */}
            {(hasPermission("subject") ||
              hasPermission("automation", "all") ||
              hasPermission("automation", "write")) && (
              <button
                className="btn btn-primary preview_button edit_button"
                onClick={() => {
                  handleEditRuleView(selectedRuleID);
                  console.log("Directly within onClick:");
                }}
              >
                <IconEdit />
              </button>
            )}
            {/* ACTIVE */}
            {(hasPermission("subject") ||
              hasPermission("automation", "all") ||
              hasPermission("automation", "write")) && (
              <button
                className={`btn btn-primary preview_button run_button ${
                  status === "active" ? "active_status" : "inactive_status"
                }`}
                onClick={() => {
                  handleUpdateAutomationStatus(selectedRuleID, status);
                }}
              >
                <span
                  className={`icon_pause ${
                    status === "active" ? "active_status" : "inactive_status"
                  }`}
                >
                  <IconCirclePause />
                </span>
                {status === "active" ? "Active" : "Inactive"}
              </button>
            )}
            {/* RUN NOW */}
            {(hasPermission("subject") ||
              hasPermission("automation", "all") ||
              hasPermission("automation", "write")) && (
              <button
                className="btn btn-primary preview_button run_button"
                onClick={() => {
                  handleRunAutomation(selectedRuleID);
                }}
              >
                <span className="icon_play">
                  <IconPlay />
                </span>
                Run Now
              </button>
            )}
            {/* DELETE */}
            {(hasPermission("subject") ||
              hasPermission("automation", "all") ||
              hasPermission("automation", "delete")) && (
              <button
                className="btn btn-primary preview_button delete_button"
                onClick={() => {
                  handleDeleteAutomations([selectedRuleID]);
                }}
              >
                <span>
                  <IconDelete />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="conditions_actions">
        <div className="col-6">
          <h5>Condition</h5>

          {foundAutomation && validConditionData ? (
            <>
              {
                <Condition
                  dropdownRuleData={dropdownRuleData}
                  childConditionGroups={childConditionGroups}
                  setChildConditionGroups={setChildConditionGroups}
                  editable={false}
                  viewEditRule={false}
                />
              }
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="col-6">
          <div className="action_rule_container">
            <h5>Action</h5>

            {foundAutomation && validTimingData ? (
              <TimingDisplaySimple
                timing={timing}
                allAutomations={allAutomations}
              />
            ) : (
              <></>
            )}
          </div>

          {/* // iterate through actions display action components */}
          {foundAutomation && validActionData ? (
            <>
              {Array.isArray(actions) &&
                actions.length > 0 &&
                actions.map((index) => (
                  <Action
                    key={index.id}
                    actionID={index.id}
                    removeAction={removeAction}
                    setActions={setActions}
                    dropdownRuleData={dropdownRuleData}
                    editable={false}
                    actionType={index.type}
                    actionVariable={index.variable}
                    actionSelection={index.selection}
                    actionValue={index.value}
                    viewEditRule={false}
                  />
                ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default AutomationsPreview;
