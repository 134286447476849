import { SocketProvider } from "../../../contexts/SocketContext.tsx";
import Chat from "./chat.component.tsx";

interface ChatContainerProps {
  studyClicked: string;
}
//In the ChatContainer component, we are using the SocketController to create a new instance of the socket controller. We are then passing this instance to the SocketProvider component, which wraps the Chat component. This allows the Chat component to access the socket controller instance using the useSocket hook.
const ChatContainer: React.FC<ChatContainerProps> = ({ studyClicked }) => {
  //const [socketController] = useState(() => new SocketController());
  return (
    <SocketProvider>
      <Chat studyClicked={studyClicked} />
    </SocketProvider>
  );
};

export default ChatContainer;
