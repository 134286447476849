import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchAllAutomationData,
  fetchAllAutomationLogs
} from "../../../../models/automations.model";
import {
  AutomationDataDB,
  AutomationLog,
  Country,
  Language,
  Timezone
} from "../../../../types";
import {
  getResearcherConfigTimezone,
  getSystemConfigCountries,
  getSystemConfigLanguages,
  getSystemConfigTimezones
} from "../../../../utilities/config.util";
import FiltersContainer from "../../../Tables/Filters/filtersContainer.component";
import PaginationNavigation from "../../../Tables/PaginationNavigation/paginationNavigation";
import TableRowsSelector from "../../../Tables/TableRowsSelector/tableRowsSelector";
import TimezoneSwitcher from "../../../Tables/TimeZoneSwitcher/timezoneSwitcher";
import ViewParticipantModal from "../../../ViewParticipantModal/viewParticipantModal";
import "./automationLogs.scss";
import AutomationLogsTable from "./automationLogsTable";

interface AutomationLogsProps {}

const AutomationLogs: React.FC<AutomationLogsProps> = ({}) => {
  const { clientID, studyID } = useParams(); // Read values passed on state

  // For pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(15);

  const [filteredLogs, setFilteredLogs] = useState<AutomationLog[]>([]);
  const [currentLogs, setCurrentLogs] = useState<AutomationLog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [originalLogs, setOriginalLogs] = useState<AutomationLog[]>([]);

  const [logsSearchFilter, setLogsSearchFilter] = useState<string>("");
  const [logsStatusFilter, setLogsStatusFilter] = useState<string[]>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [logsStatuses, setLogsStatuses] = useState<string[]>([]);
  const [logsRulesFilter, setLogsRulesFilter] = useState<string[]>([]);
  const [logsRules, setLogsRules] = useState<string[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState("");

  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);

  const [fetchedDB, setFetchedDB] = useState(false);
  const [fetchedLocal, setFetchedLocal] = useState(false);

  const [automationConditionDataDB, setAutomationConditionDataDB] =
    useState<AutomationDataDB>({
      tags: [],
      buckets: [],
      surveys: []
    });

  const handleViewParticipant = (participantID: string) => {
    setSelectedParticipant(participantID);
    // console.log("View participant: " + participantID);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // delay of 0.5 seconds
    setTimeout(() => {
      setSelectedParticipant("");
    }, 500);
  };

  useEffect(() => {
    const fetchLanguagesData = async () => {
      const jsonData = await getSystemConfigLanguages();
      try {
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching languages");
        } else {
          setLanguages(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };

    const fetchCountriesData = async () => {
      const jsonData = await getSystemConfigCountries();
      try {
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching countries");
        } else {
          setCountries(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };

    const fetchTimezonesData = async () => {
      const jsonData = await getSystemConfigTimezones();
      try {
        if (!jsonData) {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          throw new Error("Error fetching timezones");
        } else {
          setTimezones(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };

    const promises = [
      fetchLanguagesData(),
      fetchCountriesData(),
      fetchTimezonesData()
    ];
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      });
  }, []);

  const handleChangeRows = (rows: number) => {
    setLogsPerPage(rows);
  };

  useEffect(() => {
    // console.log(currentLogs, filteredLogs);
    if (filteredLogs) {
      setCurrentLogs(
        filteredLogs.slice(
          (currentPage - 1) * logsPerPage,
          currentPage * logsPerPage
        )
      );
    }
  }, [currentPage, logsPerPage, filteredLogs]);

  // fetchAllAutomationLogs
  useEffect(() => {
    const fetchAutomationsData = async () => {
      try {
        const jsonData = await fetchAllAutomationLogs(
          clientID as string,
          studyID as string
        );
        if (typeof jsonData === "string") {
          console.log("jsonData is a string");
          console.log("jsonData", jsonData);
          // setLoadingError(true); // Set loadingError to true in case of an error
          // setLoadingErrorText((prevErrors) => [
          //   ...prevErrors,
          //   "0344b47e1d8f2ae868f7c8a1fff9c677",
          // ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          const sortedData = jsonData.sort((a, b) => {
            const dateA = new Date(a.automation_run_time).getTime();
            const dateB = new Date(b.automation_run_time).getTime();
            return dateB - dateA; // Descending: newest first
          });
          setOriginalLogs(sortedData);
          setFilteredLogs(sortedData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        // setLoadingError(true); // Set loadingError to true in case of an error
      }
    };

    const promises = [fetchAutomationsData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
      });
  }, []);

  // Fetching data for automation conditions (Surveys, Buckets, Tags, etc.)
  useEffect(() => {
    console.log("HIT ============================");

    // Only makes the call if the data hasn't been fetched yet
    if (
      automationConditionDataDB.surveys === undefined ||
      automationConditionDataDB.surveys.length === 0 ||
      automationConditionDataDB.surveys === null
    ) {
      const fetchAutomationsData = async () => {
        try {
          console.log("HIT");
          const jsonData = await fetchAllAutomationData(
            clientID as string,
            studyID as string
          );
          if (typeof jsonData === "string") {
            console.log("jsonData is a string");
            console.log("jsonData", jsonData);
            // setLoadingError(true); // Set loadingError to true in case of an error
            // setLoadingErrorText((prevErrors) => [
            //   ...prevErrors,
            //   "0344b47e1d8f2ae868f7c8a1fff9c677",
            // ]);
          } else {
            // console.log("jsonData is an object");
            // console.log("jsonData", jsonData);
            console.log("jsonData", jsonData);
            setAutomationConditionDataDB(jsonData);
          }
        } catch (error) {
          console.error(
            "An error occurred while fetching participant data:",
            error
          );
          // setLoadingError(true); // Set loadingError to true in case of an error
        }
      };
      console.log("HIT");

      const promises = [fetchAutomationsData()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
          setLoading(false);
          setFetchedDB(true);
        })
        .catch(() => {
          // At least one promise failed
          setLoading(false);
          setFetchedDB(false);
        });
    } else {
      console.log("already set");
      setLoading(false);
    }
  }, [automationConditionDataDB.surveys, clientID, studyID]);

  useEffect(() => {
    if (filteredLogs) {
      setCurrentLogs(
        filteredLogs.slice(
          (currentPage - 1) * logsPerPage,
          currentPage * logsPerPage
        )
      );
    }
  }, [currentPage, logsPerPage, filteredLogs]);

  const handleClearFilters = () => {
    //   // Clear the filter state
    setLogsSearchFilter("");
    setLogsStatusFilter([]);
    setLogsRulesFilter([]);
  };

  useEffect(() => {
    if (
      logsSearchFilter !== "" ||
      logsStatusFilter.length > 0 ||
      logsRulesFilter.length > 0
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [logsSearchFilter, logsStatusFilter, logsRulesFilter]);

  useEffect(() => {
    const statuses = [
      ...new Set(originalLogs.map((log) => log.automation_status))
    ].sort();
    setLogsStatuses(statuses);

    const rules = [
      ...new Set(originalLogs.map((log) => log.automation_name))
    ].sort();
    setLogsRules(rules);
  }, [originalLogs]);

  useEffect(() => {
    // filter by searchfilter set by input
    // loop through all logs
    const filteredLogs = originalLogs.filter((log: AutomationLog) => {
      // if searchfilter is not empty, check if log matches searchfilter
      if (logsSearchFilter !== "") {
        if (
          log.automation_name
            .toLowerCase()
            .includes(logsSearchFilter.toLowerCase()) ||
          // log.participant_surname
          //   .toLowerCase()
          //   .includes(logsSearchFilter.toLowerCase()) ||
          // (log.participant_name + " " + log.participant_surname)
          //   .toLowerCase()
          //   .includes(logsSearchFilter.toLowerCase()) ||
          log.participant_id
            .toLowerCase()
            .includes(logsSearchFilter.toLowerCase()) ||
          log.participant_email
            .toLowerCase()
            .includes(logsSearchFilter.toLowerCase()) ||
          log.participant_mobile_number
            .toLowerCase()
            .includes(logsSearchFilter.toLowerCase()) ||
          log.automation_run_time
            .toLowerCase()
            .includes(logsSearchFilter.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    // filter by statusfilter set by input
    // loop through all logs
    const filteredLogsByStatus = filteredLogs.filter((log: AutomationLog) => {
      // if statusfilter is not empty, check if log matches statusfilter
      if (logsStatusFilter.length > 0) {
        if (logsStatusFilter.includes(log.automation_status)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    // filter by rulesfilter set by input
    // loop through all logs
    const filteredLogsByRules = filteredLogsByStatus.filter(
      (log: AutomationLog) => {
        // if rulesfilter is not empty, check if log matches rulesfilter
        if (logsRulesFilter.length > 0) {
          if (logsRulesFilter.includes(log.automation_name)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    );

    // set filtered logs to be displayed
    setFilteredLogs(filteredLogsByRules);
  }, [originalLogs, logsStatusFilter, logsSearchFilter, logsRulesFilter]);

  const dropdownRuleData = useMemo(() => {
    if (!fetchedDB) {
      console.log("NOT BOTH FETCHED!");
      console.log(fetchedDB, " fetchedDB status");
      console.log(fetchedLocal, " fetchedLocal status");

      return {
        languages: [],
        countries: [],
        automationConditionDataDB: {
          tags: [],
          buckets: [],
          surveys: []
        }
      };
    }

    return {
      languages: languages,
      countries: countries,
      automationConditionDataDB: automationConditionDataDB
    };
  }, [
    fetchedDB,
    fetchedLocal,
    languages,
    countries,
    automationConditionDataDB
  ]);

  // Timezones
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone | null>(
    null
  );
  const [timezones, setTimezones] = useState<Timezone[]>([]);

  // User can switch between UTC, User timezone, and participant's timezone
  const [timezone, setTimezone] = useState<string>("ResearcherTimezone");

  const handleChangeTimezone = (timezone: string) => {
    setTimezone(timezone);
  };

  // Get researcher timezone
  useEffect(() => {
    getResearcherConfigTimezone().then((result) => {
      // console.log("result", result);
      if (result) {
        setResearcherTimezone(result);
      } else {
        setResearcherTimezone(null);
      }
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center custom_spinner_container full_page">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          <div className="container-fluid automation_logs_container">
            {/* FILTERS */}
            <div className="filters_container d-flex justify-content-between">
              <FiltersContainer
                tableType="AutomationLogs"
                statuses={logsStatuses}
                rules={logsRules}
                filterApplied={filterApplied}
                setFilterApplied={setFilterApplied}
                handleClearFilters={handleClearFilters}
                setSearch={setLogsSearchFilter}
                setStatusFilter={setLogsStatusFilter}
                setRulesFilter={setLogsRulesFilter}
              />
              <TimezoneSwitcher
                timezone={timezone}
                researcherTimezone={researcherTimezone}
                handleChangeTimezone={handleChangeTimezone}
                tableType={"AutomationLogs"}
              />
              <TableRowsSelector
                handleChangeRows={handleChangeRows}
                rowsPerPage={logsPerPage}
                tableName="Automation Logs"
                arrayRowSelector={[10, 15, 25, 100]}
              />
            </div>
            {/* TABLE */}
            <AutomationLogsTable
              currentLogs={currentLogs}
              handleViewParticipant={handleViewParticipant}
              dropdownRuleData={dropdownRuleData}
              // Timezones
              timezone={timezone}
              researcherTimezone={
                researcherTimezone ? researcherTimezone : null
              }
              allTimezones={timezones}
            />
            {/* PAGINATION */}
            <div className="d-flex justify-content-between align-items-center pt-2 pb-2">
              <PaginationNavigation
                currentPage={currentPage}
                totalResults={filteredLogs ? filteredLogs.length : 0}
                resultsPerPage={logsPerPage}
                setCurrentPage={setCurrentPage}
              />
              <small>
                <strong>Number of logs: {filteredLogs.length}</strong>
              </small>
            </div>
          </div>

          {clientID && selectedParticipant !== "" && (
            <ViewParticipantModal
              participantID={selectedParticipant}
              closeParticipantModal={() => handleCloseModal()}
              shown={showModal}
              jsonLanguagesData={languages}
              jsonCountriesData={countries}
              jsonTimezonesData={timezones}
              tableType={"AutomationLogs"}
              clientID={clientID}
              studyID={studyID}
              surveyID={undefined}
            />
          )}
        </>
      )}
    </>
  );
};

export default AutomationLogs;
