import React, { useEffect, useRef, useState } from "react";
import { Condition, SelectedValues } from "../../../types";
import MultiRangeSlider from "../../RangeSlider/multiRangeSlider.component";

interface FilterConditionProps {
  condition: Condition;
  allTypes: { [key: string]: string[] };
  selectedValues: SelectedValues;
  onUpdate: (
    id: number,
    type: string,
    value: string | { min: number; max: number }
  ) => void;
  onRemove: (id: number) => void;
}

export const FilterCondition: React.FC<FilterConditionProps> = ({
  condition,
  allTypes,
  selectedValues,
  onUpdate,
  onRemove
}) => {
  const { id, type, value } = condition;

  // State to manage dropdown toggle
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [isValueDropdownOpen, setIsValueDropdownOpen] = useState(false);
  const [typeSearch, setTypeSearch] = useState("");
  const [valueSearch, setValueSearch] = useState("");

  const handleTypeSelect = (selectedType: string) => {
    onUpdate(id, selectedType, "");
    setIsTypeDropdownOpen(false); // Close dropdown after selection
  };

  const handleValueSelect = (selectedValue: string) => {
    onUpdate(id, type, selectedValue);
    setIsValueDropdownOpen(false); // Close dropdown after selection
  };

  const handleRangeSelect = (range: { min: number; max: number }) => {
    onUpdate(id, type, range);
  };

  const availableValues = type
    ? allTypes[type].filter(
        (val) =>
          !selectedValues[type]?.some(
            (entry) => entry.value === val && entry.conditionId !== id
          )
      )
    : [];

  // Refs for the dropdowns
  const typeDropdownRef = useRef<HTMLDivElement | null>(null);
  const valueDropdownRef = useRef<HTMLDivElement | null>(null);

  // Effect for handling clicks outside the dropdowns
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        typeDropdownRef.current &&
        !typeDropdownRef.current.contains(event.target as Node)
      ) {
        setIsTypeDropdownOpen(false);
      }
      if (
        valueDropdownRef.current &&
        !valueDropdownRef.current.contains(event.target as Node)
      ) {
        setIsValueDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="filter-condition">
      <div ref={typeDropdownRef} className="condition-btn-group dropdown">
        <button
          type="button"
          className="btn btn-sm btn-primary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded={isTypeDropdownOpen ? "true" : "false"}
          onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
        >
          {type || "Select Type"}
        </button>
        <ul className={`dropdown-menu${isTypeDropdownOpen ? " show" : ""}`}>
          <input
            type="text"
            className="form-control"
            placeholder="Search Type"
            value={typeSearch}
            onChange={(e) => setTypeSearch(e.target.value)}
          />
          <div className="dropdown-items">
            {Object.keys(allTypes)
              .filter((key) =>
                key.toLowerCase().includes(typeSearch.toLowerCase())
              )
              .map((typeKey) => (
                <li key={typeKey} onClick={() => handleTypeSelect(typeKey)}>
                  <a className="dropdown-item" href="#">
                    {typeKey}
                  </a>
                </li>
              ))}
          </div>
        </ul>
      </div>
      {type && (type === "birthdate" || type === "currentage") && (
        <MultiRangeSlider min={16} max={99} onChange={handleRangeSelect} />
      )}
      {type && type !== "birthdate" && type !== "currentage" && (
        <div ref={valueDropdownRef} className="condition-btn-group dropdown">
          <button
            type="button"
            className="btn btn-sm btn-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded={isValueDropdownOpen ? "true" : "false"}
            onClick={() => setIsValueDropdownOpen(!isValueDropdownOpen)}
          >
            {value || "Select Value"}
          </button>

          <ul className={`dropdown-menu${isValueDropdownOpen ? " show" : ""}`}>
            <input
              type="text"
              className="form-control"
              placeholder="Search Value"
              value={valueSearch}
              onChange={(e) => setValueSearch(e.target.value)}
            />
            <div className="dropdown-items">
              {availableValues.length === 0 ? (
                <li className="dropdown-item disabled">No available values</li>
              ) : (
                availableValues
                  .filter((val) =>
                    val.toLowerCase().includes(valueSearch.toLowerCase())
                  )
                  .map((val) => (
                    <li key={val} onClick={() => handleValueSelect(val)}>
                      <a className="dropdown-item" href="#">
                        {val}
                      </a>
                    </li>
                  ))
              )}
            </div>
          </ul>
        </div>
      )}
      <div className="condition-remove-btn" onClick={() => onRemove(id)}>
        <p>&times;</p>
      </div>
    </div>
  );
};
