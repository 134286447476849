//Page to display the clients in cards
//explicitly set the types for the clients array

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAllClients } from "../../models/client.model.ts";
import { Client } from "../../types.ts";
import ClientCard from "./clientCard.component";
import "./displayClients.scss";
// error page
import ErrorPage from "../../pages/Error/error.page";

const DisplayClients = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  useEffect(() => {
    const fetchClientsData = async () => {
      try {
        const jsonData = await fetchAllClients();
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "dc8facb7b900ede0668fbec2d4eb9b13"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setClients(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
      }
      setLoading(false);
    };

    const promises = [fetchClientsData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
      });
  }, []);

  const handleClientCardClick = (client: Client) => {
    // console.log(`You clicked on ${client.name} (${client.id})`);
    navigate("/clients/" + client.id);
  };

  const [searchFilter, setSearchFilter] = useState<string>("");

  return (
    <>
      {!loading ? (
        !loadingError ? (
          <>
            <div className="row">
              <div
                id="clients_page_header_component"
                className="col-12 client_page_header"
              >
                <div className="row">
                  <div className="col">
                    <h1 className="">Clients</h1>
                  </div>

                  {/* Search filter */}
                  <div className="col d-flex justify-content-end search_box">
                    <div className="mb-3">
                      {/* <button
                        className="btn btn-primary"
                        // TODO: Add back in
                        disabled={true}
                        onClick={() => navigate("/clientAdd")}
                      >
                        Add Client
                      </button> */}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={searchFilter}
                        onChange={(e) => setSearchFilter(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* order alphabetically by the  client.name */}
            {/* map the clients array to a ClientCard component */}
            {/* If a client is selected only show that client card */}
            <div className="row">
              {/* Diplay the filtered clients if the filter is set */}
              {clients.length === 0 ? (
                <div className="col-12">
                  <h3 className="text-center">No clients found</h3>
                </div>
              ) : (
                clients
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .filter((client) => {
                    if (searchFilter === "") {
                      return true;
                    } else if (
                      client.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    ) {
                      return true;
                    } else if (
                      client.id
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .map((client, index) => (
                    <ClientCard
                      key={client.id}
                      client={client}
                      index={index}
                      onClick={handleClientCardClick}
                    />
                  ))
              )}
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col">
              <ErrorPage
                errorCode="404"
                error="Cannot load clients"
                goHome={false}
                debugCode={["ouyoiuhwbpidnpoiuphiufiu"]}
              />
            </div>
          </div>
        )
      ) : (
        <div className="d-flex justify-content-center custom_spinner_container full_page">
          <div className="spinner-border" role="status"></div>
        </div>
      )}
    </>
  );
};

export default DisplayClients;
