import React, { useEffect, useRef, useState } from "react";
import { fetchAllLanguageLibraryPhrasesByCatID } from "../../../models/translations.model";
import { LanguageLibraryPhrase } from "../../../types";
import { handleExportPhrase } from "../../../utilities/languageLibrary.util";
import "./phrases.scss";
import SinglePhrase from "./singlePhrase.component";

export type PhraseContainerProps = {
  categoryID: string;
  selectedPhrase: LanguageLibraryPhrase | null;
  setSelectedPhrase: (phrase: LanguageLibraryPhrase | null) => void;
  loadingCategory: boolean;
  loadingPhrase: boolean;
  setLoadingPhrase: (loading: boolean) => void;
  handleAddPhraseClick: () => void;
};

const PhraseContainer: React.FC<PhraseContainerProps> = ({
  categoryID,
  loadingCategory,
  loadingPhrase,
  setLoadingPhrase,
  handleAddPhraseClick,
  setSelectedPhrase,
  selectedPhrase
}) => {
  const controllerRef = useRef(new AbortController());
  const [languageLibraryPhrases, setLanguageLibraryPhrases] = useState<
    LanguageLibraryPhrase[] | null
  >(null); // Set initial state to null
  const [loadingError, setLoadingError] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [filteredPhrases, setFilteredPhrases] = useState<
    LanguageLibraryPhrase[] | null
  >(null);

  /*------------------------------------------------------------------------------*/
  /*-----------------------------Functions for all--------------------------------*/
  /*------------------------------------------------------------------------------*/
  //---------Function to handle category click
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleExportButton = () => {
    if (selectedPhrase === null) {
      //This will display a warning message because there are no phrases to export
      handleExportPhrase(false, categoryID, "Category");
    } else {
      handleExportPhrase(true, categoryID, "Category");
    }
  };

  useEffect(() => {
    // console.log("search", search);
    if (languageLibraryPhrases) {
      // console.log(
      //   // languageLibraryPhrases.name.toLowerCase().includes(search.toLowerCase())
      //   languageLibraryPhrases.filter((phrase) =>
      //     phrase.name.toLowerCase().includes(search.toLowerCase())
      //   )
      // );
      if (search === "") {
        setFilteredPhrases(languageLibraryPhrases);
        setSelectedPhrase(
          languageLibraryPhrases[0] ? languageLibraryPhrases[0] : null
        );
      } else if (languageLibraryPhrases) {
        const filteredPhrases = languageLibraryPhrases.filter((phrase) =>
          phrase.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredPhrases(filteredPhrases);
        setSelectedPhrase(filteredPhrases[0] ? filteredPhrases[0] : null);
      }
    }
  }, [search]);

  const handlePhraseClick = (selectedPhraseID: string) => {
    const selectedPhrase = languageLibraryPhrases?.find(
      (phrase) => phrase.id === selectedPhraseID
    );
    setSelectedPhrase(selectedPhrase ? selectedPhrase : null);
  };

  useEffect(() => {
    setSearch("");
    setLoadingPhrase(true);
    if ((categoryID && categoryID !== "") || !loadingCategory) {
      // console.log("categoryID AA", categoryID);
      // abort previous fetch
      controllerRef.current.abort();
      // Create a new AbortController
      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;

      const fetchAllLanguageLibraryPhrases = async () => {
        try {
          const jsonData: string | LanguageLibraryPhrase[] =
            await fetchAllLanguageLibraryPhrasesByCatID(categoryID, signal);
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            // console.log("jsonData is a string");
            // console.log("jsonData", jsonData);
            setLanguageLibraryPhrases(null);
            setFilteredPhrases(null);
            setLoadingError(jsonData);
            setSelectedPhrase(null);
            if (!signal || !signal.aborted) {
              setLoadingPhrase(false);
            }
          } else {
            if (jsonData.length > 0) {
              // order phrases alphabetically
              const sortedPhrases = jsonData.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              setLanguageLibraryPhrases(sortedPhrases);
              setFilteredPhrases(sortedPhrases);
              setLoadingError("");
              setSelectedPhrase(sortedPhrases[0]);
            } else {
              // console.log("no phrases");

              setLoadingError(
                "This phrase library is currently empty. Add new phrases to see them here!"
              );
              setSelectedPhrase(null);
            }
            setLoadingPhrase(false);
          }
        } catch (error) {
          console.error(
            "An error occurred while fetching participant data:",
            error
          );
          setLanguageLibraryPhrases(null);
          setLoadingPhrase(false);
          setSelectedPhrase(null);
          setLoadingError("An error occurred while fetching participant data");
        }
      };

      const promises = [fetchAllLanguageLibraryPhrases()];

      Promise.all(promises).catch(() => {
        // At least one promise failed
        setLoadingPhrase(false);
        setSelectedPhrase(null);
        setLoadingError("An error occurred while fetching participant data");
      });
    } else {
      setLanguageLibraryPhrases(null);
      setFilteredPhrases(null);
      setLoadingPhrase(false);
      setLoadingError("");
    }
  }, [categoryID]);

  return (
    <>
      <div className="phrase_container card">
        {!categoryID || categoryID === "" ? (
          <>
            <div className="top_bar card-header">
              <div>Phrases</div>

              <div className={`create_phrase`}></div>
            </div>
            <div className="main_phrase_singles_container">
              Please select a category.
            </div>
          </>
        ) : (
          <>
            <div className="top_bar card-header">
              <div>
                Phrases
                <button
                  id="btn_phrases_import"
                  className="btn btn-primary ms-2"
                  onClick={handleExportButton}
                >
                  Export All
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                {/* search */}
                <input
                  type="text"
                  className="form-control search_phases me-2"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={handleSearchChange}
                />

                <span
                  className={`create_phrase`}
                  onClick={handleAddPhraseClick}
                  title="Click here to add a new phrase"
                  style={{
                    display: "flex"
                  }}
                >
                  +
                </span>
              </div>
            </div>
            <div className="main_phrase_singles_container">
              {loadingPhrase || loadingCategory ? (
                <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : loadingError !== "" ? (
                <div className="error_container">
                  <div className="error_text p-2">{loadingError}</div>
                </div>
              ) : !languageLibraryPhrases ||
                languageLibraryPhrases.length < 0 ? (
                <div>
                  <p>
                    This phrase library is currently empty. Add new phrases to
                    see them here!
                  </p>
                </div>
              ) : !filteredPhrases || filteredPhrases.length < 0 ? (
                <div>
                  <p>No phrases found please adjust your search.</p>
                </div>
              ) : (
                filteredPhrases.map((phrase) => (
                  <SinglePhrase
                    key={phrase.id}
                    phraseID={phrase.id}
                    phraseName={phrase.name}
                    isSelected={selectedPhrase?.id === phrase.id}
                    handlePhraseClick={handlePhraseClick}
                  />
                ))
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PhraseContainer;
