import jwtDecode from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";

// Define the shape of your JWT payload
interface PermissionsPayload {
  permissions: string[];
}

interface PermissionsContextType {
  hasPermission: (area: string, right?: string) => boolean;
  getPermission: (area: string) => string | undefined;
  isPermissionsLoaded: boolean; // Indicator for permissions loading completion
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined
);

export const PermissionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false); // Track if permissions are loaded

  useEffect(() => {
    const loadPermissions = async () => {
      // Use an async function to handle permissions loading
      try {
        // Retrieve the JWT token. Replace this with your actual retrieval logic.
        const token = localStorage.getItem("auth-token");

        if (token) {
          // Decode the token
          const decoded: PermissionsPayload =
            jwtDecode<PermissionsPayload>(token);

          // Set the permissions from the decoded token
          setPermissions(decoded.permissions);
        }
      } catch (error) {
        console.error("Error decoding JWT token", error);
        // Handle error cases, such as invalid token format
      } finally {
        setIsPermissionsLoaded(true); // Ensure we mark permissions as loaded
      }
    };

    loadPermissions();
  }, []);

  const hasPermission = (area: string, right?: string): boolean => {
    if (!isPermissionsLoaded) {
      // Ideally, this condition should never be true when called due to the guard at the consumer level
      console.log(
        "Permissions are being checked before they have been fully loaded."
      );
      return false;
    }
    if (right) {
      const permissionToCheck = `${area}_${right}`;
      return permissions.includes(permissionToCheck);
    } else {
      return permissions.some((permission) => permission.startsWith(area));
    }
  };

  const getPermission = (area: string): string | undefined => {
    if (!isPermissionsLoaded) {
      console.log(
        "Attempting to get a permission before permissions have been fully loaded."
      );
      return undefined;
    }
    return permissions.find((permission) => permission.startsWith(area));
  };

  return (
    <PermissionsContext.Provider
      value={{ hasPermission, getPermission, isPermissionsLoaded }}
    >
      {isPermissionsLoaded ? children : null}{" "}
      {/* Only render children if permissions are loaded */}
    </PermissionsContext.Provider>
  );
};

// Custom hook for using permissions context
export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};
