import React, { useState } from "react";
import ReactSlider from "react-slider";
import "./rangeSlider.scss"; // Make sure this path is correct

interface MultiRangeSliderProps {
  min: number;
  max: number;
  onChange: (range: { min: number; max: number }) => void;
}

const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({
  min,
  max,
  onChange
}) => {
  const [value, setValue] = useState<[number, number]>([min, max]);
  const [isDragging, setIsDragging] = useState(false); // State to track if dragging

  // Handles change on slider and updates the value state
  const handleChange = (newValue: number | number[] | null | undefined) => {
    if (Array.isArray(newValue)) {
      setValue([newValue[0], newValue[1]]);
      onChange({ min: newValue[0], max: newValue[1] });
    }
  };

  // Custom thumb renderer with tooltip based on dragging state
  const renderThumb = (
    props: any,
    state: { index: number; value: number | number[] }
  ) => {
    const currentValue = Array.isArray(state.value)
      ? state.value[state.index]
      : state.value;

    // Adjust tooltip visibility based on isDragging state
    const tooltipStyle = isDragging
      ? {
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#333",
          color: "#fff",
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "12px",
          whiteSpace: "nowrap",
          zIndex: 2
        }
      : {};

    return (
      <div {...props}>
        {/* Conditional rendering based on isDragging */}
        {isDragging && (
          <div style={tooltipStyle as React.CSSProperties}>{currentValue}</div>
        )}
        <div className="example-thumb">{currentValue}</div>
      </div>
    );
  };

  return (
    <ReactSlider
      className="horizontal-slider"
      thumbClassName="example-thumb"
      trackClassName="example-track"
      value={value}
      ariaLabel={["Lower thumb", "Upper thumb"]}
      ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
      onChange={handleChange}
      onBeforeChange={() => setIsDragging(true)} // Set dragging true when drag starts
      onAfterChange={() => setIsDragging(false)} // Reset dragging false when drag ends
      renderThumb={renderThumb}
      min={min}
      max={max}
      pearling={true}
      minDistance={1}
    />
  );
};

export default MultiRangeSlider;
