import { Study } from "../../types.ts";

// client card component, props will be the client object
const StudyCard = ({
  study,
  onClick
}: {
  study: Study;
  onClick: (study: Study) => void;
}) => {
  const handleStudyClick = () => {
    // Call the onClick callback and pass the client object
    onClick(study);
  };

  return (
    <div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-cust-7 col-xxl-cust-8">
      <div
        className="card study_card custom_card clickable mb-4 card_background"
        key={study.study_name}
        onClick={() => handleStudyClick()}
        style={{
          backgroundImage: `url(${
            study.thumbnail_url
              ? study.thumbnail_url
              : `/images/logos/logos_clients/default.webp`
          })`
        }}
      >
        <div className="card-body">
          <h6 className="card-title">{study.study_name}</h6>
        </div>
      </div>
    </div>
  );
};

export default StudyCard;
