import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  CreateStudyNotification,
  ExistingStudyNotification,
  ExistingStudyNotifications,
  FieldDropdown,
  Study
} from "../types";

import { getSystemConfigPhraseCategoryIDByNameID } from "../utilities/config.util";

// Type guard to check if an object is a FieldDropdown
function isFieldDropdown(obj: any): obj is FieldDropdown {
  const validID = typeof obj.id === "string";
  const validName = typeof obj.name === "string";
  // if (!validID) {
  //   console.log("Invalid ID");
  // }
  // if (!validName) {
  //   console.log("Invalid name");
  // }
  const AllValid = validID && validName;
  return AllValid;
}

// Type guard to check if an object is a Study
// export type Study = {
//   id: string;
//   name: string;
//   description?: string;
//   photo?: string;
//   _status: string;
// };
function isStudy(obj: any): obj is Study {
  const validID = typeof obj.id === "string";
  const validName = typeof obj.study_name === "string";
  const validStatus = typeof obj.study_status === "string";
  const validDescription =
    typeof obj.study_desc === "string" || obj.study_desc === undefined;
  const validPhoto =
    typeof obj.thumbnail_url === "string" ||
    obj.thumbnail_url === null ||
    obj.thumbnail_url === undefined;
  const validInfo =
    typeof obj.study_info === "string" ||
    obj.study_info === null ||
    obj.study_info === undefined;
  const validStartDate =
    typeof obj.study_start_date === "string" ||
    obj.study_start_date === null ||
    obj.study_start_date === undefined;
  const validDeadline =
    typeof obj.study_deadline === "string" ||
    obj.study_deadline === null ||
    obj.study_deadline === undefined;
  const validBudget =
    typeof obj.study_budget === "string" ||
    obj.study_budget === null ||
    obj.study_budget === undefined;
  const validLanguages =
    Array.isArray(obj.study_languages) ||
    obj.study_language === null ||
    obj.study_languages === undefined;

  const validAIResponse =
    typeof obj.ai_response === "boolean" || obj.ai_response === undefined;

  const AllValid =
    validID &&
    validName &&
    validStatus &&
    validDescription &&
    validPhoto &&
    validInfo &&
    validStartDate &&
    validDeadline &&
    validBudget &&
    validLanguages &&
    validAIResponse;

  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }
    if (!validName) {
      if (!obj.hasOwnProperty("study_name")) {
        errors.push("missing study_name");
      } else {
        errors.push(`Invalid type for study_name`);
      }
    }
    if (!validStatus) {
      if (!obj.hasOwnProperty("study_status")) {
        errors.push("missing study_status");
      } else {
        errors.push(`Invalid type for study_status`);
      }
    }
    if (!validDescription) {
      if (!obj.hasOwnProperty("study_desc")) {
        errors.push("missing study_desc");
      } else {
        errors.push(`Invalid type for study_desc`);
      }
    }
    if (!validPhoto) {
      if (!obj.hasOwnProperty("thumbnail_url")) {
        errors.push("missing thumbnail_url");
      } else {
        errors.push(`Invalid type for thumbnail_url`);
      }
    }
    if (!validInfo) {
      if (!obj.hasOwnProperty("study_info")) {
        errors.push("missing study_info");
      } else {
        errors.push(`Invalid type for study_info`);
      }
    }
    if (!validStartDate) {
      if (!obj.hasOwnProperty("study_start_date")) {
        errors.push("missing study_start_date");
      } else {
        errors.push(`Invalid type for study_start_date`);
      }
    }
    if (!validDeadline) {
      if (!obj.hasOwnProperty("study_deadline")) {
        errors.push("missing study_deadline");
      } else {
        errors.push(`Invalid type for study_deadline`);
      }
    }
    if (!validBudget) {
      if (!obj.hasOwnProperty("study_budget")) {
        errors.push("missing study_budget");
      } else {
        errors.push(`Invalid type for study_budget`);
      }
    }
    if (!validLanguages) {
      if (!obj.hasOwnProperty("study_languages")) {
        errors.push("missing study_languages");
      } else {
        errors.push(`Invalid type for study_languages`);
      }
    }
    if (!validAIResponse) {
      if (!obj.hasOwnProperty("ai_response")) {
        errors.push("missing ai_response");
      } else {
        errors.push(`Invalid type for ai_response`);
      }
    }

    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `StudyID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Study`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}

function isCreateStudyNotification(obj: any): obj is CreateStudyNotification {
  // console.log(obj, "obj");
  const validID = typeof obj.id === "string";
  const validPhrase = typeof obj.notification_phrase === "string";
  const validTranslation = typeof obj.translations === "string";
  const AllValid = validID && validPhrase && validTranslation;
  // console.log("AllValid", AllValid);
  return AllValid;
}

// type ExistingStudyNotification = {
//   phraseID: string;
//   categoryID: string;
//   phrase: string;
// }

function isExistingStudyNotification(
  obj: any
): obj is ExistingStudyNotification {
  // console.log(obj, "obj");
  const validID = typeof obj.phraseID === "string";
  const validCategoryID = typeof obj.categoryID === "string";
  const validPhrase = typeof obj.phrase === "string";
  const AllValid = validID && validCategoryID && validPhrase;
  // console.log("AllValid", AllValid);
  if (!AllValid) {
    console.log(obj);
    if (!validID) {
      console.log("Invalid ID");
    }
    if (!validCategoryID) {
      console.log("Invalid category ID");
    }
    if (!validPhrase) {
      console.log("Invalid phrase");
    }
  }
  return AllValid;
}

function isExistingStudyNotifications(
  obj: any
): obj is ExistingStudyNotifications {
  const validDiaryWelcomeNotifications =
    Array.isArray(obj.diaryWelcomeNotifications) &&
    obj.diaryWelcomeNotifications.every(isExistingStudyNotification);
  const validDiaryDailyReminderNotifications =
    Array.isArray(obj.diaryDailyReminderNotifications) &&
    obj.diaryDailyReminderNotifications.every(isExistingStudyNotification);
  const validDiaryNonComplianceNotifications =
    Array.isArray(obj.diaryNonComplianceNotifications) &&
    obj.diaryNonComplianceNotifications.every(isExistingStudyNotification);
  const validSingleWelcomeNotifications =
    Array.isArray(obj.singleWelcomeNotifications) &&
    obj.singleWelcomeNotifications.every(isExistingStudyNotification);
  const validSingleDailyReminderNotifications =
    Array.isArray(obj.singleDailyReminderNotifications) &&
    obj.singleDailyReminderNotifications.every(isExistingStudyNotification);
  const validSingleNonComplianceNotifications =
    Array.isArray(obj.singleNonComplianceNotifications) &&
    obj.singleNonComplianceNotifications.every(isExistingStudyNotification);

  const AllValid =
    validDiaryWelcomeNotifications &&
    validDiaryDailyReminderNotifications &&
    validDiaryNonComplianceNotifications &&
    validSingleWelcomeNotifications &&
    validSingleDailyReminderNotifications &&
    validSingleNonComplianceNotifications;
  // console.log("AllValid", AllValid);
  if (!AllValid) {
    console.log(obj);
    if (!validDiaryWelcomeNotifications) {
      console.log("Invalid diary welcome notifications");
    }
    if (!validDiaryDailyReminderNotifications) {
      console.log("Invalid diary daily reminder notifications");
    }
    if (!validDiaryNonComplianceNotifications) {
      console.log("Invalid diary non compliance notifications");
    }
    if (!validSingleWelcomeNotifications) {
      console.log("Invalid single welcome notifications");
    }
    if (!validSingleDailyReminderNotifications) {
      console.log("Invalid single daily reminder notifications");
    }
    if (!validSingleNonComplianceNotifications) {
      console.log("Invalid single non compliance notifications");
    }
  }
  return AllValid;
}

// A post request to fetch all studies by client id
export async function fetchAllStudiesByClientID(
  clientID: string
): Promise<Study[] | string> {
  const response = await apiFetcher<any>("/getAllStudies", "POST", {
    body: {
      clientID: clientID
    }
  });

  // console.log(clientID, "clientID");
  // console.log(response, "response");

  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    if (Array.isArray(response.data) && response.data.every(isStudy)) {
      // console.log("Successfully fetched all studies");
      const studies: Study[] = response.data;
      return studies;
    } else {
      console.log("Invalid data received");
      return "Invalid data received";
    }
  } else if (response.status === 204) {
    return [];
  } else if (response.status === 404) {
    Swal.fire({
      title: "Error",
      text: "Please contact support, Route not found",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6"
    });
    return "contact support";
  } else if (response.status === 400) {
    Swal.fire({
      title: "Error",
      text: "Please contact support",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6"
    });
    return "contact support";
  } else {
    console.log(response);
    console.log("Failed to fetch all studies");
    return "Failed to fetch all studies";
  }
}

// A post request to fetch a single study by client id and study id
export async function fetchStudyByClientID(
  clientID: string,
  studyid: string
): Promise<Study | string> {
  const response = await apiFetcher<any>("/getStudy", "POST", {
    body: {
      clientID: clientID,
      studyID: studyid
    }
  });

  // console.log(clientID, "clientID");
  // console.log(studyid, "studyid");
  console.log(response, "response");

  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    console.log(response.data, "response.data");
    if (isStudy(response.data)) {
      // console.log("Successfully fetched all studies");
      const study: Study = response.data;
      return study;
    } else {
      console.log("Invalid data received");
      return "Invalid data received";
    }
  } else {
    console.log(response);
    console.log("Failed to fetch all studies");
    return "Failed to fetch all studies";
  }
}

// A post request to fetch all the surveys
export async function fetchAllWSCSurveys(): Promise<FieldDropdown[] | string> {
  const response = await apiFetcher<any>("/getAllWSCSurveys", "POST", {
    body: {}
  });
  // console.log(response.data, "response");
  if (response.status === 200 && response.data !== null) {
    if (Array.isArray(response.data) && response.data.every(isFieldDropdown)) {
      // console.log("Successfully fetched all wsc surveys");
      return response.data;
    } else {
      console.log("Invalid data received");
      throw new Error(
        "Received data does not conform to the FieldDropdown type"
      );
    }
  } else {
    console.log("Failed to fetch all wsc surveys");
    return response.error ? response.error : "failed";
  }
}

// A post request to send the study data
export async function sendStudyData({
  clientID,
  studyStatus,
  studyName,
  studyDescription,
  studyInformation,
  studyIcon,
  // studyStartDate,
  // studyAutoStart,
  // studyDeadline,
  // studyBudget,
  selectedLanguages,
  // Resources
  resourceIncluded,
  resourceName,
  resourceDescription,
  resourceFileName,
  resourceFile,
  resourceThumbnail,
  resourceLanguages,
  resourceIncludedSecond,
  resourceNameSecond,
  resourceDescriptionSecond,
  resourceFileSecond,
  resourceFileNameSecond,
  resourceLanguagesSecond,
  resourceThumbnailSecond,

  diaryIncluded,
  singleFirstIncluded,
  singleSecondIncluded,
  // Diary optional
  diaryName,
  diaryDescription,
  // diaryAppDisplayName,
  diaryIcon,
  dairyStartDate,
  diaryAutoStart,
  diaryActiveDays,
  diaryGraceDays,
  diaryBonusDays,
  diaryMinimumDailyCompletes,
  diaryMaximumDailyCompletes,
  diarySurveySystem,
  diarySurveySystemDetails,
  diaryIncludeNotifications,
  diaryWelcomeNotification,
  diaryDailyReminderNotification,
  diaryDailyReminderTime,
  diaryNonComplianceNotification,
  diaryNonComplianceTime,
  diaryNewWelcomeNotification,
  diaryNewDailyReminderNotification,
  diaryNewNonComplianceNotification,
  // Single first optional
  singleFirstName,
  singleFirstDescription,
  // singleFirstAppDisplayName,
  singleFirstIcon,
  singleFirstStartDate,
  singleFirstAutoStart,
  singleFirstSurveySystem,
  singleFirstSurveySystemDetails,
  singleFirstIncludeNotifications,
  singleFirstWelcomeNotification,
  // singleFirstDailyReminderNotification,
  singleFirstNonComplianceTime,
  singleFirstNonComplianceNotification,
  singleFirstNewWelcomeNotification,
  // singleFirstNewDailyReminderNotification,
  singleFirstNewNonComplianceNotification,
  singleFirstNonComplianceNotificationStartDelay,
  singleFirstNonComplianceNotificationInterval,
  // Single second optional
  singleSecondName,
  singleSecondDescription,
  // singleSecondAppDisplayName,
  singleSecondIcon,
  singleSecondStartDate,
  singleSecondAutoStart,
  singleSecondSurveySystem,
  singleSecondSurveySystemDetails,
  singleSecondIncludeNotifications,
  singleSecondWelcomeNotification,
  // singleSecondDailyReminderNotification,
  singleSecondNonComplianceTime,
  singleSecondNonComplianceNotification,
  singleSecondNewWelcomeNotification,
  // singleSecondNewDailyReminderNotification,
  singleSecondNewNonComplianceNotification,
  singleSecondNonComplianceNotificationStartDelay,
  singleSecondNonComplianceNotificationInterval
}: {
  clientID: string;
  studyStatus: boolean;
  studyName: string | null;
  studyDescription: string | null;
  studyInformation: string | null;
  studyIcon: string | null;
  // studyStartDate: string | null;
  // studyAutoStart: boolean | null;
  // studyDeadline: string | null;
  // studyBudget: string | null;
  selectedLanguages: string[];
  // Resources optional
  resourceIncluded: boolean | null;

  resourceName: string | null;
  resourceDescription: string | null;
  resourceFileName: string | null;
  resourceFile: string | null;
  resourceThumbnail: string | null;
  resourceLanguages: string[];

  // Second resource optional
  resourceIncludedSecond: boolean | null;
  resourceNameSecond: string | null;
  resourceDescriptionSecond: string | null;
  resourceFileSecond: string | null;
  resourceFileNameSecond: string | null;
  resourceLanguagesSecond: string[];
  resourceThumbnailSecond: string | null;

  diaryIncluded: boolean | null;
  singleFirstIncluded: boolean | null;
  singleSecondIncluded: boolean | null;
  // Diary optional
  diaryName: string | null;
  diaryDescription: string | null;
  // diaryAppDisplayName: string | null;
  diaryIcon: string | null;
  dairyStartDate: string | null;
  diaryAutoStart: boolean | null;
  diaryActiveDays: number | null;
  diaryGraceDays: number | null;
  diaryBonusDays: number | null;
  diaryMinimumDailyCompletes: number | null;
  diaryMaximumDailyCompletes: number | null;
  diarySurveySystem: string | null;
  diarySurveySystemDetails: string | null;
  diaryIncludeNotifications: boolean;
  diaryWelcomeNotification: string;
  diaryDailyReminderNotification: string;
  diaryDailyReminderTime: string | null;
  diaryNonComplianceNotification: string;
  diaryNonComplianceTime: string | null;
  diaryNewWelcomeNotification: string | null;
  diaryNewDailyReminderNotification: string | null;
  diaryNewNonComplianceNotification: string | null;
  // Single first optional
  singleFirstName: string | null;
  singleFirstDescription: string | null;
  // singleFirstAppDisplayName: string | null;
  singleFirstIcon: string | null;
  singleFirstStartDate: string | null;
  singleFirstAutoStart: boolean | null;
  singleFirstSurveySystem: string;
  singleFirstSurveySystemDetails: string;
  singleFirstIncludeNotifications: boolean;
  singleFirstWelcomeNotification: string;
  // singleFirstDailyReminderNotification: string;
  singleFirstNonComplianceTime: string | null;
  singleFirstNonComplianceNotification: string;
  singleFirstNewWelcomeNotification: string | null;
  // singleFirstNewDailyReminderNotification: string | null;
  singleFirstNewNonComplianceNotification: string | null;
  singleFirstNonComplianceNotificationStartDelay: number;
  singleFirstNonComplianceNotificationInterval: number;
  // Single second optional
  singleSecondName: string | null;
  singleSecondDescription: string | null;
  // singleSecondAppDisplayName: string | null;
  singleSecondIcon: string | null;
  singleSecondStartDate: string | null;
  singleSecondAutoStart: boolean | null;
  singleSecondSurveySystem: string;
  singleSecondSurveySystemDetails: string;
  singleSecondIncludeNotifications: boolean;
  singleSecondWelcomeNotification: string;
  // singleSecondDailyReminderNotification: string;
  singleSecondNonComplianceTime: string | null;
  singleSecondNonComplianceNotification: string;
  singleSecondNewWelcomeNotification: string | null;
  // singleSecondNewDailyReminderNotification: string | null;
  singleSecondNewNonComplianceNotification: string | null;
  singleSecondNonComplianceNotificationStartDelay: number;
  singleSecondNonComplianceNotificationInterval: number;
}): Promise<string> {
  type DiaryNotification = {
    category: string;
    phrase: string;
  };

  let diaryPhrases: DiaryNotification[] | null = [];
  let singleFirstPhrases: DiaryNotification[] | null = [];
  let singleSecondPhrases: DiaryNotification[] | null = [];
  try {
    const diaryWelcomeNotificationCategoryID =
      await getSystemConfigPhraseCategoryIDByNameID("notify-welcome-diary");
    const diaryDailyReminderNotificationCategoryID =
      await getSystemConfigPhraseCategoryIDByNameID("notify-daily-diary");

    const diaryNonComplianceNotificationCategoryID =
      await getSystemConfigPhraseCategoryIDByNameID(
        "notify-non_compliant-diary"
      );

    const singleWelcomeNotificationCategoryID =
      await getSystemConfigPhraseCategoryIDByNameID("notify-welcome-single");

    const singleDailyReminderNotificationCategoryID =
      await getSystemConfigPhraseCategoryIDByNameID("notify-daily-single");

    const singleNonComplianceNotificationCategoryID =
      await getSystemConfigPhraseCategoryIDByNameID(
        "notify-non_compliant-single"
      );

    if (
      diaryIncludeNotifications &&
      (!diaryWelcomeNotificationCategoryID ||
        diaryWelcomeNotificationCategoryID === "notFound" ||
        !diaryDailyReminderNotificationCategoryID ||
        diaryDailyReminderNotificationCategoryID === "notFound" ||
        !diaryNonComplianceNotificationCategoryID ||
        diaryNonComplianceNotificationCategoryID === "notFound")
    ) {
      console.log(
        "diaryWelcomeNotificationCategory: ",
        diaryWelcomeNotificationCategoryID
      );
      console.log(
        "diaryDailyReminderNotificationCategory: ",
        diaryDailyReminderNotificationCategoryID
      );
      console.log(
        "diaryNonComplianceNotificationCategory: ",
        diaryNonComplianceNotificationCategoryID
      );

      Swal.fire({
        title: "Error, please logout and try again",
        text: "Failed to fetch diary notification categories from local storage",
        icon: "error",
        confirmButtonText: "Ok"
      });

      throw new Error("Failed to fetch diary notification categories");
    } else {
      console.log(
        "Fetched all diary notification categories from local storage"
      );
    }

    if (
      singleFirstIncludeNotifications &&
      (!singleWelcomeNotificationCategoryID ||
        singleWelcomeNotificationCategoryID === "notFound" ||
        !singleDailyReminderNotificationCategoryID ||
        singleDailyReminderNotificationCategoryID === "notFound" ||
        !singleNonComplianceNotificationCategoryID ||
        singleNonComplianceNotificationCategoryID === "notFound")
    ) {
      console.log(
        "singleWelcomeNotificationCategory: ",
        singleWelcomeNotificationCategoryID
      );
      console.log(
        "singleDailyReminderNotificationCategory: ",
        singleDailyReminderNotificationCategoryID
      );
      console.log(
        "singleNonComplianceNotificationCategory: ",
        singleNonComplianceNotificationCategoryID
      );
      Swal.fire({
        title: "Error, please logout and try again",
        text: "Failed to fetch single notification categories from local storage",
        icon: "error",
        confirmButtonText: "Ok"
      });
      throw new Error(
        "Failed to fetch single notification categories from local storage"
      );
    } else {
      console.log(
        "Fetched all single notification categories from local storage"
      );
    }

    // if diaryWelcomeNotification not equal to new, add to diaryPhrases
    // else add to diaryNewWelcomeNotification

    if (diaryIncluded && diaryIncludeNotifications) {
      if (!diaryWelcomeNotification || diaryWelcomeNotification === "") {
        throw new Error("diaryWelcomeNotification is empty");
      }

      if (
        diaryWelcomeNotification === "new" &&
        (!diaryNewWelcomeNotification || diaryNewWelcomeNotification === "")
      ) {
        throw new Error("diaryNewWelcomeNotification is empty");
      }

      if (
        !diaryDailyReminderNotification ||
        diaryDailyReminderNotification === ""
      ) {
        throw new Error("diaryDailyReminderNotification is empty");
      }

      if (
        diaryDailyReminderNotification === "new" &&
        (!diaryNewDailyReminderNotification ||
          diaryNewDailyReminderNotification === "")
      ) {
        throw new Error("diaryNewDailyReminderNotification is empty");
      }

      if (
        !diaryNonComplianceNotification ||
        diaryNonComplianceNotification === ""
      ) {
        throw new Error("diaryNonComplianceNotification is empty");
      }

      if (
        diaryNonComplianceNotification === "new" &&
        (!diaryNewNonComplianceNotification ||
          diaryNewNonComplianceNotification === "")
      ) {
        throw new Error("diaryNewNonComplianceNotification is empty");
      }
    }

    if (!diaryIncluded || !diaryIncludeNotifications) {
      diaryPhrases = null;
    } else {
      if (
        !diaryWelcomeNotificationCategoryID ||
        !diaryDailyReminderNotificationCategoryID ||
        !diaryNonComplianceNotificationCategoryID
      ) {
        throw new Error("Failed to fetch diary notification categories");
      }

      diaryPhrases.push({
        category: diaryWelcomeNotificationCategoryID,
        phrase:
          diaryWelcomeNotification !== "new"
            ? diaryWelcomeNotification
            : diaryNewWelcomeNotification || "DEFUALT_ERROR"
      });
      diaryPhrases.push({
        category: diaryDailyReminderNotificationCategoryID,
        phrase:
          diaryDailyReminderNotification &&
          diaryDailyReminderNotification !== "new"
            ? diaryDailyReminderNotification
            : diaryNewDailyReminderNotification || "DEFUALT_ERROR"
      });

      diaryPhrases.push({
        category: diaryNonComplianceNotificationCategoryID,
        phrase:
          diaryNonComplianceNotification &&
          diaryNonComplianceNotification !== "new"
            ? diaryNonComplianceNotification
            : diaryNewNonComplianceNotification || "DEFUALT_ERROR"
      });
      if (
        diaryPhrases &&
        diaryPhrases.some((p) => p.phrase === "DEFAULT_ERROR")
      ) {
        throw new Error("One or more phrases have missing values.");
      }
    }

    if (singleFirstIncluded && singleFirstIncludeNotifications) {
      if (
        !singleFirstWelcomeNotification ||
        singleFirstWelcomeNotification === ""
      ) {
        throw new Error("singleWelcomeNotification is empty");
      }

      if (
        singleFirstWelcomeNotification === "new" &&
        (!singleFirstNewWelcomeNotification ||
          singleFirstNewWelcomeNotification === "")
      ) {
        throw new Error("singleNewWelcomeNotification is empty");
      }

      // if (
      //   !singleFirstDailyReminderNotification ||
      //   singleFirstDailyReminderNotification === ""
      // ) {
      //   throw new Error("singleDailyReminderNotification is empty");
      // }

      // if (
      //   singleFirstDailyReminderNotification === "new" &&
      //   (!singleFirstNewDailyReminderNotification ||
      //     singleFirstNewDailyReminderNotification === "")
      // ) {
      //   throw new Error("singleNewDailyReminderNotification is empty");
      // }

      if (
        !singleFirstNonComplianceNotification ||
        singleFirstNonComplianceNotification === ""
      ) {
        throw new Error("singleNonComplianceNotification is empty");
      }

      if (
        singleFirstNonComplianceNotification === "new" &&
        (!singleFirstNewNonComplianceNotification ||
          singleFirstNewNonComplianceNotification === "")
      ) {
        throw new Error("singleNewNonComplianceNotification is empty");
      }
    }

    if (singleSecondIncluded) {
      if (
        !singleSecondWelcomeNotification ||
        singleSecondWelcomeNotification === ""
      ) {
        throw new Error("singleWelcomeNotification is empty");
      }

      if (
        singleSecondWelcomeNotification === "new" &&
        (!singleSecondNewWelcomeNotification ||
          singleSecondNewWelcomeNotification === "")
      ) {
        throw new Error("singleNewWelcomeNotification is empty");
      }

      // if (
      //   !singleSecondDailyReminderNotification ||
      //   singleSecondDailyReminderNotification === ""
      // ) {
      //   throw new Error("singleDailyReminderNotification is empty");
      // }

      // if (
      //   singleSecondDailyReminderNotification === "new" &&
      //   (!singleSecondNewDailyReminderNotification ||
      //     singleSecondNewDailyReminderNotification === "")
      // ) {
      //   throw new Error("singleNewDailyReminderNotification is empty");
      // }

      if (
        !singleSecondNonComplianceNotification ||
        singleSecondNonComplianceNotification === ""
      ) {
        throw new Error("singleNonComplianceNotification is empty");
      }

      if (
        singleSecondNonComplianceNotification === "new" &&
        (!singleSecondNewNonComplianceNotification ||
          singleSecondNewNonComplianceNotification === "")
      ) {
        throw new Error("singleNewNonComplianceNotification is empty");
      }
    }

    if (!singleFirstIncluded || !singleFirstIncludeNotifications) {
      singleFirstPhrases = null;
    } else {
      if (
        !singleWelcomeNotificationCategoryID ||
        !singleDailyReminderNotificationCategoryID ||
        !singleNonComplianceNotificationCategoryID
      ) {
        throw new Error("Failed to fetch single notification categories");
      }
      singleFirstPhrases.push({
        category: singleWelcomeNotificationCategoryID,
        phrase:
          singleFirstWelcomeNotification &&
          singleFirstWelcomeNotification !== "new"
            ? singleFirstWelcomeNotification
            : singleFirstNewWelcomeNotification || "DEFAULT_ERROR"
      });
      // singleFirstPhrases.push({
      //   category: singleDailyReminderNotificationCategoryID,
      //   phrase:
      //     singleFirstDailyReminderNotification &&
      //     singleFirstDailyReminderNotification !== "new"
      //       ? singleFirstDailyReminderNotification
      //       : singleFirstNewDailyReminderNotification || "DEFAULT_ERROR",
      // });

      singleFirstPhrases.push({
        category: singleNonComplianceNotificationCategoryID,
        phrase:
          singleFirstNonComplianceNotification &&
          singleFirstNonComplianceNotification !== "new"
            ? singleFirstNonComplianceNotification
            : singleFirstNewNonComplianceNotification || "DEFAULT_ERROR"
      });

      if (
        singleFirstPhrases &&
        singleFirstPhrases.some((p) => p.phrase === "DEFAULT_ERROR")
      ) {
        throw new Error("One or more phrases have missing values.");
      }
    }

    if (!singleSecondIncluded || !singleSecondIncludeNotifications) {
      singleSecondPhrases = null;
    } else {
      if (
        !singleWelcomeNotificationCategoryID ||
        !singleDailyReminderNotificationCategoryID ||
        !singleNonComplianceNotificationCategoryID
      ) {
        throw new Error("Failed to fetch single notification categories");
      }
      singleSecondPhrases.push({
        category: singleWelcomeNotificationCategoryID,
        phrase:
          singleSecondWelcomeNotification &&
          singleSecondWelcomeNotification !== "new"
            ? singleSecondWelcomeNotification
            : singleSecondNewWelcomeNotification || "DEFAULT_ERROR"
      });
      // singleSecondPhrases.push({
      //   category: singleDailyReminderNotificationCategoryID,
      //   phrase:
      //     singleSecondDailyReminderNotification &&
      //     singleSecondDailyReminderNotification !== "new"
      //       ? singleSecondDailyReminderNotification
      //       : singleSecondNewDailyReminderNotification || "DEFAULT_ERROR",
      // });

      singleSecondPhrases.push({
        category: singleNonComplianceNotificationCategoryID,
        phrase:
          singleSecondNonComplianceNotification &&
          singleSecondNonComplianceNotification !== "new"
            ? singleSecondNonComplianceNotification
            : singleSecondNewNonComplianceNotification || "DEFAULT_ERROR"
      });

      if (
        singleSecondPhrases &&
        singleSecondPhrases.some((p) => p.phrase === "DEFAULT_ERROR")
      ) {
        throw new Error("One or more phrases have missing values.");
      }
    }
  } catch (error: any) {
    console.log(error);
    Swal.fire({
      title: "Error",
      html: "Please contact support <br />" + error.message,
      icon: "error",
      confirmButtonText: "Ok"
    });
    return "failed";
  }

  const firstResourceData = resourceIncluded
    ? {
        name: resourceName,
        description: resourceDescription,
        langISOs: resourceLanguages,
        file: resourceFile,
        fileName: resourceFileName,
        thumbnail: resourceThumbnail
      }
    : null;

  const secondResourceData = resourceIncludedSecond
    ? {
        name: resourceNameSecond,
        description: resourceDescriptionSecond,
        langISOs: resourceLanguagesSecond,
        file: resourceFileSecond,
        fileName: resourceFileNameSecond,
        thumbnail: resourceThumbnailSecond
      }
    : null;

  const diaryData = diaryIncluded
    ? {
        survey_type: "diary",
        survey_name: diaryName,
        survey_desc: diaryDescription,
        survey_start_date: dairyStartDate,
        survey_auto_start: diaryAutoStart,
        // survey_app_display_name: diaryAppDisplayName,
        thumbnail_url: diaryIcon,
        survey_active_days: diaryActiveDays,
        survey_grace_days: diaryGraceDays,
        bonus_days: diaryBonusDays,
        survey_min_completes: diaryMinimumDailyCompletes,
        survey_max_completes: diaryMaximumDailyCompletes,
        survey_system: diarySurveySystem,
        survey_system_details: diarySurveySystemDetails,
        survey_daily_reminder_time: diaryIncludeNotifications
          ? diaryDailyReminderTime
          : null,
        survey_non_compliance_time: diaryIncludeNotifications
          ? diaryNonComplianceTime
          : null,
        phrases: diaryIncludeNotifications ? diaryPhrases : []
      }
    : null;

  const singleFirstData = singleFirstIncluded
    ? {
        survey_type: "single",
        survey_name: singleFirstName,
        survey_desc: singleFirstDescription,
        survey_start_date: singleFirstStartDate,
        survey_auto_start: singleFirstAutoStart,
        // survey_app_display_name: singleFirstAppDisplayName,
        thumbnail_url: singleFirstIcon,
        survey_system: singleFirstSurveySystem,
        survey_system_details: singleFirstSurveySystemDetails,
        phrases: singleFirstIncludeNotifications ? singleFirstPhrases : [],
        survey_non_compliance_notification_start_delay:
          singleFirstIncludeNotifications
            ? singleFirstNonComplianceNotificationStartDelay
            : null,
        survey_non_compliance_notification_interval:
          singleFirstIncludeNotifications
            ? singleFirstNonComplianceNotificationInterval
            : null,
        survey_non_compliance_time: singleFirstIncludeNotifications
          ? singleFirstNonComplianceTime
          : null
      }
    : null;

  const singleSecondData =
    singleFirstIncluded && singleSecondIncluded
      ? {
          survey_type: "single",
          survey_name: singleSecondName,
          survey_desc: singleSecondDescription,
          survey_start_date: singleSecondStartDate,
          survey_auto_start: singleSecondAutoStart,
          // survey_app_display_name: singleSecondAppDisplayName,
          thumbnail_url: singleSecondIcon,
          survey_system: singleSecondSurveySystem,
          survey_system_details: singleSecondSurveySystemDetails,
          phrases: singleSecondIncludeNotifications ? singleSecondPhrases : [],
          survey_non_compliance_notification_start_delay:
            singleSecondIncludeNotifications
              ? singleSecondNonComplianceNotificationStartDelay
              : null,
          survey_non_compliance_notification_interval:
            singleSecondIncludeNotifications
              ? singleSecondNonComplianceNotificationInterval
              : null,
          survey_non_compliance_time: singleSecondIncludeNotifications
            ? singleSecondNonComplianceTime
            : null
        }
      : null;

  // console.log(studyData, "studyData");
  // console.log(diaryData, "diaryData");
  // console.log(singleData, "singleData");

  let body: {
    clientID: string;
    study: any;
    resources: any[];
    surveys?: {
      diary?: any;
      singleFirst?: any;
      singleSecond?: any;
    };
  };

  const studyData = {
    study_status: studyStatus ? "active" : "inactive",
    study_name: studyName,
    study_desc: studyDescription,
    study_info: studyInformation,
    thumbnail_url: studyIcon,
    // study_deadline: studyDeadline,
    // study_budget: studyBudget,
    study_languages: selectedLanguages
  };

  body = {
    resources: [],
    clientID: clientID,
    study: studyData
  };

  if (firstResourceData) {
    body.resources.push(firstResourceData);
  }

  if (secondResourceData) {
    body.resources.push(secondResourceData);
  }

  if (diaryData || singleFirstData) {
    body.surveys = {};

    if (diaryData) {
      body.surveys = {
        ...body.surveys,
        diary: diaryData
      };
    }

    if (singleFirstData) {
      body.surveys = {
        ...body.surveys,
        singleFirst: singleFirstData
      };
    }

    if (singleSecondData) {
      body.surveys = {
        ...body.surveys,
        singleSecond: singleSecondData
      };
    }
  }

  console.log(body);

  const response = await apiFetcher<any>("/study/create", "POST", {
    body: body
  });

  // const response = {
  //   status: 200,
  //   data: null,
  //   error: null
  // };

  if (response.status === 200) {
    return "success";
  } else {
    console.log(response.status, response?.error);
    console.log("Failed to send study data");
    return "failed";
  }
}

export async function fetchAllExistingStudyNotifications(): Promise<
  ExistingStudyNotifications | string
> {
  const response = await apiFetcher<any>("/getWizardNotifications", "POST", {
    body: {}
  });
  // console.log(response.data, "response");
  if (response.status === 200 && response.data !== null) {
    if (isExistingStudyNotifications(response.data)) {
      return response.data;
    } else {
      throw new Error(
        "Received data does not conform to the CreateStudyNotifications type"
      );
    }
  } else {
    console.log(`Failed to fetch all notifications`);
    return response.error ? response.error : "failed";
  }
}

// export async function sendAllWSCMappings(
//   clientID: string,
//   studyID: string,
//   surveyID: string,
//   variables: ExistingWSCVariable[]
// ): Promise<{
//   rStatus: "success" | "error";
//   rMessage: string;
// }> {

export async function sendUpdatedStudy(
  clientID: string,
  studyID: string,
  studyStatus: string,
  studyName: string,
  studyDescription: string,
  studyInformation: string,
  studyAIResponse: boolean,
  studyLanguages: string[]
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  const studyObj = {
    id: studyID,
    study_status: studyStatus,
    study_name: studyName,
    study_desc: studyDescription,
    study_info: studyInformation,
    ai_response: studyAIResponse,
    study_languages: studyLanguages
  };

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const response = await apiFetcher<any>(
    "/studies/settings/update/id",
    "POST",
    {
      body: {
        clientID: clientID,
        study: studyObj
      }
    }
  );

  if (response.status === 200 || response.status === 202) {
    return {
      rStatus: "success",
      rMessage: "Study updated successfully"
    };
  }

  if (response.status === 204) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Study not found"
    };
  }

  if (response.status === 400) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Server expecting different data"
    };
  }

  if (response.status === 404) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Route not found"
    };
  }

  if (response.status === 406) {
    return {
      rStatus: "error",
      rMessage: "No changes detected in the study data"
    };
  }

  if (response.status === 500) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Internal server error"
    };
  }

  console.log(response.status, response.error);
  console.log("Failed to update study");
  return {
    rStatus: "error",
    rMessage: "Failed to update study"
  };
}
