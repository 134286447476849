import InformationTooltip from "../../InputInformation/InfoInputs.component";

type FieldFileProps = {
  onInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  // inputImage: string | null;
  file: string | null;
  fileName: string | null;
  tooltip?: string;
  label: string;
  inputName: string;
  subLabel?: string;
  error?: string | null;
  tooltipPosition?: "left" | "right" | "bottom" | "top";
};

const FieldFile: React.FC<FieldFileProps> = ({
  onInputChange,
  // inputImage,
  file,
  fileName,
  tooltip,
  label,
  inputName,
  subLabel,
  error,
  tooltipPosition
}) => {
  return (
    <>
      {/* {inputImage && (
        <div className="image_preview m-auto mb-3 text-center">
          <img src={inputImage} alt="" />
        </div>
      )} */}
      {/* mt-4 for overlapping error */}
      <div className="mb-3 mt-4 row">
        <div className="col-12 col-lg-4">
          <label htmlFor={inputName} className="form-label mb-1 mx-1">
            {tooltip && (
              <InformationTooltip
                marginTop="-12px"
                position={tooltipPosition ? tooltipPosition : "top"}
                tooltipText={tooltip}
              />
            )}
            {label}
            {subLabel && <span className="sub_label">{subLabel}</span>}
          </label>
        </div>
        <div className="col-12 col-lg-8">
          {error && <div className="wizard_input_error">{error}</div>}
          <input
            type="file"
            accept=".pdf"
            name={inputName}
            id={inputName}
            className="form-control"
            onChange={(e) => onInputChange(inputName, e)}
          />
        </div>
      </div>
    </>
  );
};

export default FieldFile;
